import React, { useEffect, useState, Fragment } from 'react';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Menu, Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, AppBar, Toolbar, IconButton, InputBase, Badge, Tooltip, Avatar
} from '@mui/material';
import { connect } from 'react-redux';
import { spacing } from '@mui/system';
// import { toggleSidebar } from '../commonFunctions/actions';
import Request from '../customUtils/Http/Request';
import { styled, alpha } from '@mui/material/styles';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {sideBar} from '../redux/cartSlice'


const cookies = new Cookies();

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#727272',
  fontFamily: 'Open Sans, sans-serif !important',
  fontSize: "14px",
  borderRadius: "10px",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
      borderRadius: '10px'
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  // headerBox: {
  //   width: "calc(100% - 260px)",
  //   position: "fixed",
  //   right: "0px",
  //   zIndex: "9"
  // },
  // header: {
  //   backgroundColor: "gray !important"
  // },
  // dashHeaderBtn: {
  //   backgroundColor: "#033D1E !important",
  //   color: "#fff !important",
  //   fontSize: "14px !important",
  //   padding: "0px 25px !important",
  //   height: "40px",
  //   borderRadius: "5px !important",
  //   marginLeft: "10px !important"
  // },
  // userText: {
  //   color: "#000",
  //   fontSize: "14px !important",
  //   fontWeight: "700 !important"
  // },
  // userPosition: {
  //   color: "#727272",
  //   fontSize: "12px !important",
  // },
  //   toolbar: theme.mixins.toolbar,
}));

const dashboardSetting = [
  { id: 1, name: 'Logout' },
];


function DashboardHeader() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userType, setUserType] = useState(0);
  const [userPhoto, setUserPhoto] = useState(null);
  const [driverProfile, setDriverProfile] = useState(null);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const minimized = useSelector(state => state.cart.minimized);


  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);



  useEffect(() => {
    let userType = cookies.get('userType', { path: '/' });
    const userTypeAsNumber = parseInt(userType, 10);
    setUserType(userTypeAsNumber)

  }, [])

  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const userLogout = () => {
    cookies.remove("accessToken", {path: "/"});
    cookies.remove("refreshToken", {path: "/"});
    cookies.remove("userType", {path: "/"});
    navigate('/login')
  };

  const toggleSidebar = () =>{
    dispatch(sideBar());
  }

  useEffect(() => {
    fetchUserData()
  }, []);


  useEffect(() => {
    (async () => {
      let response = await Request.get("user/", [], true);
      {
         
        if (response) {
          setUserName(response.firstName + ' ' + response.lastName)
          setUserPhoto(response.img)
        }
        else {
          console.log("error");
          return
        }
      }
    })();
  }, []);

  const fetchUserData = async () => {
		try {
      const response = await Request.get("driver/details/", [], true);
      if (response.length !== 0) {
          setDriverProfile(response.user?.img)

            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return error;
        }
	}

  const openSidebar = () => {
    document.getElementById('drawer').style.display = "block"
  }


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const profileHandle = (id) => {
    switch (id) {
      case 1:
        cookies.remove("accessToken", {path: "/"});
        cookies.remove("refreshToken", {path: "/"});
        cookies.remove("userType", {path: "/"});
        navigate('/login')
        break;
      default:
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className='flexBox'>
        <Avatar alt="Profile Photo" src="/assets/img/list_img1.png" />
        <Box className="userText">{userName}</Box>
      </MenuItem>
      {userType === 3 ? (
            <MenuItem >
              <Link to='/' className='emailLink'>
                <IconButton size="large"  color="inherit" className='flexBox' sx={{ p: 0, pl: 1, pr: 1 }}>
                  {/* {/* <Badge badgeContent={4} color="error"> */}
                  <img src='/assets/img/home_icon.svg' className='home-icon-img' alt='home_icon'></img>
                  {/* </Badge> */}
                  <Box className="userText">Home</Box>
                </IconButton>
              </Link>
          </MenuItem>
        ):(
          <></>
        )}
          
       <MenuItem align="left">
        <Button className="dashHeaderBtn" onClick={() => userLogout()}>Log out</Button>
      </MenuItem> 
    </Menu>
  );

  return (
    <Box className={`orderContainer ${minimized ? 'headerBox' : 'headerBoxExpand'}`}>
      <AppBar position="static" className="header">
        <Toolbar className='HeaderShadow'>
          <IconButton
            size="large"
            edge="start"
            color="#033D1E"
            aria-label="open drawer"
            className='sideberOpenIcon'
            onClick={toggleSidebar}
            sx={{ ml: 2}}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            MUI
          </Typography> */}
          {/* <Search sx={{ backgroundColor: "#fff", boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)" }}>

            <SearchIcon sx={{ color: "#000", ml: 1 }} />
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              sx={{ color: "#000", borderRadius: "10px !important" }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {userType === 3 ? (
            <Link to='/' className='emailLink'>
              <IconButton size="large"  color="inherit" className="" sx={{ p: 0, pl: 1, pr: 1 }}>
                {/* {/* <Badge badgeContent={4} color="error"> */}
                <img src='/assets/img/home_icon.svg' className='home-icon-img' alt='home_icon'></img>
                {/* </Badge> */}
              </IconButton>
            </Link>
          ):(<></>)}
          <Link to='mailto:support@isleoffreight.com' className='emailLink'>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit" className="" sx={{ p: 0, pl: 1, pr: 1 }}>
              {/* {/* <Badge badgeContent={4} color="error"> */}
              <img src='/assets/img/email_icon.svg' className='' alt='email_icon'></img>
              {/* </Badge> */}
            </IconButton>
          </Link>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              sx={{ p: 0, pl: 1, pr: 1 }}
            >
              {/* <Badge badgeContent={17} color="error"> */}
              {/* <img src='/assets/img/notification_icon.svg' className='' alt='notification_icon'></img> */}
              {/* </Badge> */}
            </IconButton>
            {/* <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
            {/* <Tooltip>
              <IconButton sx={{ p: 0, ml: 1 }}>
                <Avatar alt="Profile Photo" src="/assets/img/list_img1.png" />
              </IconButton>
            </Tooltip> */}
            <Box sx={{ pl: 2, pr: 1 }} className="username-text">
              <Box className="userText">{userName}</Box>
              {/* <Box className="userPosition">Manager</Box> */}
            </Box>
            {/* <Button className="dashHeaderBtn" onClick={() => userLogout()}>Log out</Button> */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} style={{border:"1px solid lightgray"}}>
                {(userType === 1 || userType === 2) && (
                      <Avatar alt="Profile Photo" src="/assets/img/list_img1.png" />
                    )}
                    
                    {userType === 3 && (
                      <Avatar
                        alt="Profile Photo"
                        src={userPhoto === null || userPhoto === undefined ? "/assets/img/list_img1.png" : `${process.env.REACT_APP_DatabaseStatic}${userPhoto}`}
                      />
                    )}
                    
                    {userType === 4 && (
                      <Avatar
                        alt="Profile Photo"
                        src={driverProfile === null || driverProfile === undefined ? "/assets/img/list_img1.png" : `${process.env.REACT_APP_DatabaseStatic}${driverProfile}`}
                      />
                    )}
                    
                    {userType === 0 && (
                      <Avatar alt="Default Profile Photo" src="/assets/img/default_profile.png" />
                    )}
                  
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {dashboardSetting.map((setting,index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    <Typography key={setting.id} onClick={() => profileHandle(setting.id)} textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              className='mobileMenu'
            >
              <Avatar alt="Profile Photo" src="/assets/img/list_img1.png" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

// const mapDispatchToProps = {
//   toggleSidebar
// }

// const mapStateToProps = (state) => {
//   return {
//     minimized: state.sidebar.minimized,
//   };
// };

export default DashboardHeader;
