import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    approveReview: [],
    pendingReview: [],
    rejectedReview: [],
    orderHistory: [],
    loading: false,
    error: null,
    message: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchApproveReview = createAsyncThunk(
    "driver/approveReview",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/reviews/approved", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

    // Async thunk action to fetch customers
    export const fetchPendingReview = createAsyncThunk(
      "driver/pendingReview",
      async (_, { rejectWithValue }) => {
          try {
              const response = await Request.get("admin/reviews/pending", [], true);
              if (response.length !== 0) {
                   
                  return response
              } else {
                  console.error("Failed to fetch data from API");
              }
              } catch (error) {
                  return rejectWithValue(error);
              }
      }
    );

        // Async thunk action to fetch customers
        export const fetchRejectedReview = createAsyncThunk(
          "driver/rejectedReview",
          async (_, { rejectWithValue }) => {
              try {
                  const response = await Request.get("admin/reviews/rejected", [], true);
                  if (response.length !== 0) {
                       
                      return response
                  } else {
                      console.error("Failed to fetch data from API");
                  }
                  } catch (error) {
                      return rejectWithValue(error);
                  }
          }
        );

    export const updateReviewStatus = createAsyncThunk(
        "customer/updateReviewStatus",
        async ({id, status}) => {
            
            try {
                const response = await Request.put(`admin/review/${id}`, {"status": status}, true);
                if (response && response.status === 200) {
                      return { status: response.status };
                    }
                    else{
                      throw new Error('Update failed or invalid response.');
                    }
              } catch (error) {
                console.error('Error updating row from API:', error);
                throw error; 
              }
        }
      );

      export const fetchOrderHistory = createAsyncThunk(
        "customer/orderHistory",
        async (_, { rejectWithValue }) => {
            try {
                
                const response = await Request.get("order/order_history", [], true);
                if (response && response !== undefined) {
                      return response;
                }
              } catch (error) {
                console.error('Error updating row from API:', error);
                throw error; 
              }
        }
      );

    // Async thunk action to delete a customer
    // export const updateCustomer = createAsyncThunk(
    //     "customer/updateCustomer",
    //     async ({id, item}) => {
    //         try {
    //               
    //             const response = await Request.put(`admin/user/${id}`, item, true);
    //             if(response) {
    //                 if (response.status === 200) {
    //                     // Update the state to remove the deleted user
    //                     return response.status
    //                   }
    //                 }
    //           } catch (error) {
    //             console.error('Error deleting row from API:', error);
    //           }
    //     }
    //   );

  const reviewSlice = createSlice({
    name: "review",
    initialState, 
    loading: false,
    error: null,
    message: null,
    status: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchApproveReview.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchApproveReview.fulfilled, (state, action) => {
            state.loading = false;
            state.approveReview = action.payload;
          })
          .addCase(fetchApproveReview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchPendingReview.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPendingReview.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingReview = action.payload;
          })
          .addCase(fetchPendingReview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchRejectedReview.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchRejectedReview.fulfilled, (state, action) => {
            state.loading = false;
            state.rejectedReview = action.payload;
          })
          .addCase(fetchRejectedReview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchOrderHistory.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchOrderHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.orderHistory = action.payload;
          })
          .addCase(fetchOrderHistory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(updateReviewStatus.pending, (state) => {
            state.loading = true;
          })
          .addCase(updateReviewStatus.fulfilled, (state, action) => {
              
            state.loading = false;
            state.status = action.payload.status;
          })
          .addCase(updateReviewStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
          // .addCase(deleteCustomer.fulfilled, (state, action) => {
          //   state.loading = false;
          //   const deletedCustomerId = action.payload;
          //   state.customers = state.customers.filter(
          //     (customer) => customer.id !== deletedCustomerId
          //   );
          // })
          // .addCase(deleteCustomer.rejected, (state, action) => {
          //   state.loading = false;
          //   state.error = action.payload;
          // })
      },
  });
  
  export default reviewSlice.reducer;