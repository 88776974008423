import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import { Col, Row } from 'react-bootstrap';
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchHelpCategories, fetchPopularQuestion } from '../../redux/helpCenterSlice';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: "#fff",
        borderRadius: "25px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "50px 15px",
        textAlign: "center",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },
    listItem: {
        paddingLeft: "0px !important",
        paddingBottom: "0px !important"
    },
    listIcon: {
        minWidth: "40px !important",
    },
    listText:{
        "& span" : {
        fontSize: "18px !important",
        }
    }
}));

const DatabaseResponseComponent = ({ htmlContent }) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    );
};
  
// export default DatabaseResponseComponent;

const HelpCenterCategory = ({category}) => {
    const location = useLocation();
    const classes = useStyles();
    const helpCategoryList = useSelector((state) => state.helpCenter.helpCategoryList);
    const dispatch = useDispatch();

    useEffect(() => {
		dispatch(fetchHelpCategories());
        dispatch(fetchPopularQuestion());
	}, []);

	useEffect(() => {
		dispatch(fetchHelpCategories());
        dispatch(fetchPopularQuestion());
	}, [dispatch]);

    return (
        <>
            <Grid item xs={12} md={3} lg={2}>
                <Typography className='font-24 font-bold color2 pb-2'>Categories</Typography>
                <ul style={{listStyleType: "none", padding: "0px"}}>
                        {(helpCategoryList && helpCategoryList.length>0) && helpCategoryList.map((item) => (
                            // <Link to={`/help-center/question?category=${item.category}`} className={(item.category === category) || (item.category) === answerCategory ? "font-600 color4" : "color2"}>
                            //     <li className="pt-2 pb-2" key={item.id}>{item.category}</li>
                            // </Link>
                            <Link to={`/help-center/question?category=${item.category}`} className={(item.category === category) ? "font-600 color4" : "color2"}>
                              <li className="pt-2 pb-2" key={item.id}>{item.category}</li>
                            </Link>
                        ))}
                    </ul>   
            </Grid>
        </>
    );
}

export default HelpCenterCategory;