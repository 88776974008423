import { useEffect, useState } from 'react';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import OrderInfoBox from './orderInfoBox';

const cookies = new Cookies();
const countryOptions = ["India", "Canada", "Jamaica"];

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}));



function IslanderAddress() {
    const classes = useStyles();
    const [version1Data, setVersion1Data] = useState({
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      });
    
      const [version2Data, setVersion2Data] = useState({
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      });

      const [copyText, setCopyText] = useState('');

      useEffect(() => {
          
        fetchAddresses();
    }, []);
    
    const fetchAddresses = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/getWarehouse/", [] , true);
            
            if (response) {
                const responseVersion1Data = {
                    name: response.fullName,
                    address1: response.address_line_1+','+response.address_line_2,
                    address2: response.isl,
                    city: response.city,
                    state: response.state,
                    country: response.country,
                    zip: response.zip,
                  };
    
                  // Set the state for version 1 with the response data
                  setVersion1Data(responseVersion1Data);
    
                    const responseVersion2Data = {
                        name: response.fullName + ' ' + response.isl,
                        address1: response.address_line_1,
                        address2: response.address_line_2,
                        city: response.city,
                        state: response.state,
                        country: response.country,
                        zip: response.zip,
                    };
    
                    // Set the state for version 2 with the response data
                    setVersion2Data(responseVersion2Data);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const handleCopyClick = (version) => {
        let copiedText
        if(version == 1) {
            copiedText = `\n${version1Data.name},\n${version1Data.address1},\n${version1Data.address2},\n${version1Data.city},\n${version1Data.state},\n${version1Data.country},\n${version1Data.zip}`;
        } else {
            copiedText = `\n${version2Data.name},\n${version2Data.address1},\n${version2Data.address2},\n${version2Data.city},\n${version2Data.state},\n${version2Data.country},\n${version2Data.zip}`;
        }

        setCopyText(copiedText);

        // Copy text to clipboard
        const textArea = document.createElement('textarea');
        textArea.value = copiedText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };

    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Islander Box</Typography>
                <Box className={classes.headerFlexBox}>
                    <Typography className={classes.pathName}>Islander Box</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>My Islander Address</Typography>
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Grid item  xs={12} sm={12} md={12} lg={11.7} xl={11.7} className='cartColLeft'>
                            <Box className="gridInnerBlockLeft">
                                <form className='checkoutForm'>
                                    <Box className="">
                                        <Typography align="left" className='font-22 color2 font-bold'>Address Format Version 1</Typography>
                                        <Form.Group className={classes.formPadding}>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Name" name="name" readOnly value={version1Data.name}/>
                                        </Form.Group>
                                        <Form.Group className={classes.formPadding}>
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Address Line 1</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address1" value={version1Data.address1} readOnly />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address2" value={version1Data.address2}readOnly/>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className={classes.formPadding}>
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>City/Town</Form.Label>
                                                    <Form.Control type="text" placeholder="City" name="city" value={version1Data.city} readOnly />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Province</Form.Label>
                                                    <Form.Control type="text" placeholder="Province" name="state" value={version1Data.state} readOnly />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className={classes.formPadding}>
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control type="text" placeholder="Country" name="country" value={version1Data.country} readOnly />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Zip Code</Form.Label>
                                                    <Form.Control type="text" placeholder="Zip Code" name="zip" value={version1Data.zip} readOnly />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Box sx={{ pt: 5 }}>
                                            <Button type="submit" className='b1' onClick={() => handleCopyClick(1)}>Copy</Button>
                                        </Box>
                                    </Box>
                                    <Box sx={{pt: 5 }} className='addressBox2'>
                                        <Typography align="left" className='font-22 color2 font-bold'>Address Format Version 2</Typography>
                                        <Form.Group className={classes.formPadding}>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Name" name="name" value={version2Data.name} readOnly />
                                        </Form.Group>
                                        <Form.Group className={classes.formPadding}>
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Address Line 1</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address1"value={version2Data.address1}  readOnly />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address2" value={version2Data.address2} readOnly />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className={classes.formPadding}>
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>City/Town</Form.Label>
                                                    <Form.Control type="text" placeholder="City" name="city" readOnly value={version2Data.city} />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Province</Form.Label>
                                                    <Form.Control type="text" placeholder="Province" name="state" readOnly value={version2Data.state} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className={classes.formPadding}>
                                            <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control type="text" placeholder="Country" name="country" readOnly value={version2Data.country} />
                                                </Col>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Label>Zip Code</Form.Label>
                                                    <Form.Control type="text" placeholder="Zip Code" name="zip" readOnly value={version2Data.zip} />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Box sx={{ pt: 5 }}>
                                            <Button type="submit" className='b1' onClick={() => handleCopyClick(2)}>Copy</Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                    <OrderInfoBox change={4}/>
                </Grid>
            </Box>
        </Box>
    );
}

export default IslanderAddress;
