import { useEffect, useState, useLocation } from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/system';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Radio, RadioGroup, FormControlLabel, TableCell, TableRow, TableContainer, TableBody, Table } from '@mui/material';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { checkLogin } from '../commonFunctions/commonFunctions';
import { loadStripe } from '@stripe/stripe-js';

import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import Login from '../user/login/login';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../checkout/paymentForm';
import PaymentAddress from '../commonFunctions/paymentAddress';
import Paper from '@mui/material/Paper';
import IslanderOrderInfo from './islanderOrderInfo';
import ProgressBar from '../commonComponents/progressbar';
import { FetchStripePublicKeyFromAPI } from '../commonFunctions/loadStripe';

// const stripePromise = loadStripe('pk_test_51NIltgSHZUnKCsvrZGDFY49bs9HsyUBNiJ6nr1pIbWxlpweEHBdvSnoja71H1Yh1GFSjVQzvMr3Q4gFl0SfxVg2I00tTeDJSHL');


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px",
        flexWrap: "wrap",
        marginTop: "20px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}));

// const CustomRadio = styled(Radio)(({ theme }) => ({
//     '&.Mui-checked': {
//       color: 'green', // Change the color of the checked radio button
//     },
//     '&.MuiRadio-colorSecondary': {
//       color: 'red', // Change the color of the unchecked radio button
//     },
//   }));


function IslanderPayment() {
    const classes = useStyles();
    const [stripeKey, setStripeKey] = useState(null);
    // const [selectedValue, setSelectedValue] = useState('a');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState('Stripe');
    const [orderID, setOrderID] = useState(null);
    const [options, setOptions] = useState({
        clientSecret: null
    });
    const [orderIdElements, setOrderIdElement] = useState({
        clientSecret: null
    });
    const [cartInfo, setCartInfo] = useState([]);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [shippingPrice, setShippingPrice] = useState(0);
    const [totalBoxAccessoriesValue,setTotalBoxAccessoriesValue] = useState(0);
    const [address1, setAddress1] = useState({});
    const [address2, setAddress2] = useState({});
    const [address3, setAddress3] = useState({});
    const [address4, setAddress4] = useState({});
    const [productTotalPrice,setProductTotalPrice] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState(null);

    const [boxDetail,setBoxDetail] = useState([]);

    const [orderType, setOrderType] = useState(null);
    const [type, setType] = useState('islander');
    const [insurance, setInsurance] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(false);


    const fillPercentage = [100, 100, 100, 75];

    let login = checkLogin()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderId = urlParams.get('orderId');

    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);

    // // Get the value of a specific parameter
    // const orderId = searchParams.get('orderId');
    // const accessToken = searchParams.get('accessToken');
    useEffect(() => {
        const initializeStripe = async () => {
          try {
            const stripePublicKey = await FetchStripePublicKeyFromAPI();
            const stripeInstance = await loadStripe(stripePublicKey);
            setStripeKey(stripeInstance);
          } catch (error) {
            console.error('Error loading Stripe:', error);
          }
        };
    
        initializeStripe();
      }, []);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const orderId = urlParams.get('orderId');

        if(orderId){
        setOrderID(orderId)
        // const accessToken = urlParams.get('accessToken');
        // const orderId = parameterList.get(orderId);
        // const accessToken = parameterList.get(accessToken);
        const accessToken = cookies.get('accessToken', {path: "/"});

        fetchClientSecret();

        async function fetchClientSecret() {

            // let response = await Request.get("cart", [], true);
            let response = await Request.get("checkout/stripePaymentIntent", [{ orderId }]);
            const orderIdElements = [];

            setOrderIdElement({ orderId: response.orderId })
            setOrderType(response.client_secret.metadata.orderType)
            setOptions({
                clientSecret: response.client_secret.client_secret
            })
            setIsLoading(false);
        }
    }
    }, []);

    useEffect(() => {
        getPaypalStatus()
      }, []);


      const getPaypalStatus = async () => {
        try {
            // Fetch data from API
            const data = await Request.get(`utils/checkPaypalStatus`, []);
            if (Object.keys(data).length !== 0){
               if(data.paypalStatus){
                    if(data.paypalStatus === true){
                        setPaymentStatus(true)
                    }else{
                        setPaymentStatus(false)
                    }
                }
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    useEffect(() => {
        (async () => {
                try {
                    const data = await Request.get(`order/order_detail/${orderId}`, []);
                    if (data) {
                        // setCartInfo(data.products);
                        // setCartTotalPrice(data.totalPrice);
                        setInsurance(data.insuranceValue);
                        setAddress1(data.shippingAddress);
                        setAddress2(data.billingAddress);
                        setAddress3(data.user);
                        setAddress4(data.senderAddress);
                        setShippingPrice(data.shippingCharge);
                        setTotalBoxAccessoriesValue(data.products.totalBoxAccessoriesValue);
                        setCartTotalPrice(data.totalPrice);
                        setProductTotalPrice(data.productPrice);
                        setBoxDetail(data.shipmentDetail.shipmentBox);
                       
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error fetching data from API:', error);
                }
        })();
    }, []);


    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
        // setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
          }
    }, [redirectUrl]);

    const paymentPaypal = async () => {
        setIsLoading2(true);
        try {
            let response = await Request.get("checkout/paypalPaymentIntent", [{ orderId }]);
            if (response && response.redirect_url) {
                setRedirectUrl(response.redirect_url)
            }
          } catch (error) {
            // Handle errors here
            console.error("Error fetching data:", error);
          } finally {
            setIsLoading2(false);
          }
    }

    const StripePaymentBox = () => {
        return (
            <>
                {isLoading ? (
                    <div className="spinner-container">
                        <div className="loading-spinner">
                        </div>
                    </div>
                ) : (

                    <Elements stripe={stripeKey} options={options} className="">
                        <PaymentForm setOrderIdElement={orderIdElements} orderType={orderType} />
                    </Elements>
                )}
            </>
        );
    };

    const PayPalPaymentBox = () => {
        return (
            // <Box className="paypalInnerBox"></Box>
            <Box>
                {isLoading2 ? (
                   <div className="loader2-container4">
                        <div className="loader2"></div>
                    </div>
                ) : (
                    <Box sx={{pt: 4}}>
                        <Button className='b1 b4' onClick={paymentPaypal}>Pay Now</Button>
                    </Box>
                )} 
            </Box>
        );
    };

    return (

        <Box className={classes.dashboardInner}>
            <Grid container>
                <Grid item xs={12} sm={12} md={16} lg={8} xl={6}>
                    <Typography variant='h5' className='color1 font-bold'>Payment</Typography>
                    {/* <Box className={classes.headerFlexBox}>
                        <Typography className={classes.pathName}>Step 1</Typography>
                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                        <Typography className={classes.pathName}>Step 2</Typography>
                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                        <Typography className={classes.pathName}>Step 3</Typography>
                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                        <Typography className={`${classes.pathName} ${classes.activePath}`}>Step 4</Typography>
                    </Box> */}
                    <Box>
                        <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                    </Box>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={16} lg={8} xl={6} align="right">
                    <Button className='b1 b4'>Edit Order Details</Button> 
                </Grid> */}
            </Grid>
           
            <Box className='cartMainContainer'>
                <Grid container className='paymentBox'>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='cartColLeft paymentCol paymentMainBox'>
                        <Grid item xs={12} sm={12} md={12} lg={11.7} xl={11.7} className='cartColLeft paymentCol'>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="Stripe"
                                // name="radio-buttons-group"
                                name="payment" value={selectedPayment} onChange={handlePaymentChange}

                            >
                                <Box className={selectedPayment === 'Stripe' ? "paymentOption active" : "paymentOption"}>
                                    <Box className="paymentLogoBox">
                                        <img src="/assets/img/stripe.png" alt='Stripe' className='paymentLogo'></img>
                                    </Box>
                                    <FormControlLabel value="Stripe" control={<Radio
                                        // checked={selectedValue === 'a'}
                                        // onChange={handleChange}
                                        // value="a"
                                        name="radio-buttons"
                                        // inputProps={{ 'aria-label': 'A' }}
                                        sx={{
                                            color: "lightgray",
                                            '&.Mui-checked': {
                                                color: "#FFC72C",
                                            },
                                        }}
                                    />} label="Stripe" />

                                    {selectedPayment === 'Stripe' && <StripePaymentBox />}

                                </Box>

                                {paymentStatus && (
                                     <Box className={selectedPayment === 'Paypal' ? "paymentOption active" : "paymentOption"}>
                                    <Box className="paymentLogoBox paymentLogoBox2">
                                        <img src="/assets/img/paypal.png" alt='PayPal' className='paymentLogo'></img>
                                    </Box>
                                    <FormControlLabel value="Paypal" control={<Radio
                                        // checked={selectedValue === 'b'}
                                        // onChange={handleChange}
                                        // value="b"
                                        name="radio-buttons"
                                        // inputProps={{ 'aria-label': 'B' }}
                                        sx={{
                                            color: "lightgray",
                                            '&.Mui-checked': {
                                                color: "#FFC72C",
                                            },
                                        }}
                                    />} label="Paypal" />

                                    {selectedPayment === 'Paypal' && <PayPalPaymentBox />}
                                </Box>)}
                            </RadioGroup>
                        </Grid>
                        <PaymentAddress address1={address1} address2={address2} address3={address3} address4={address4} type={type}/>
                    </Grid>
                    <IslanderOrderInfo boxDetail={boxDetail} productTotalPrice={productTotalPrice} shippingPrice={shippingPrice} insuranceValue={insurance} totalBoxAccessoriesValue={totalBoxAccessoriesValue} />
                </Grid>
            </Box>
        </Box>

    );




}

export default IslanderPayment;
