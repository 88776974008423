import * as React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Container, Box, Typography, List, ListItem, ListItemIcon, ListItemText, Grid, Link, Input, Button } from '@mui/material';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import { ChevronRight } from "@mui/icons-material";
import EastIcon from '@mui/icons-material/East';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
    flexBox: {
        display: "flex",
        alignItems: "center",
        gap: "15px",

        '& .flexChild': {
            color: "#FFC72C",
            fontFamily: "Roboto, sans-serif !important",
            fontWeight: "700 !important",
            fontSize: "20px !important"
        },

        '& svg': {
            color: "#FFC72C",
        },
    },
    footerMenu: {
        '& span': {
            fontFamily: "Roboto, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "16px !important"
        },
    },
    footer_header_border: {
        width: "50px",
        height: "2px",
        background: "#FFC72C !important",
        borderColor: "#FFC72C !important",
        margin: "5px 0px",
        opacity: "1",
    }

}));

function CMSFooter() {
    const classes = useStyles();

    return (
        <div className='CMSfooter'> 
            <Container maxWidth="xl">
                <div>
                    <Box className='' align="center">
                        <Typography className='font-45 font-bold font-roboto line-50'>Ready to start?<br />Sign up for free below.</Typography>
                    </Box>
                    <Box align="center" sx={{mt: 4}}>
                                <Link href='/signup' >
                                    <Button className='cmsfooterBtn'>Get Started</Button>
                                </Link>
                            </Box>
                </div>
            </Container>
            {/* <hr className='footer_divider'></hr>
            <Container maxWidth="xl">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='footer_block2'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography>2023©cbd Farmacy. All rights reserved.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography align='right'>Cookie Policy - Privacy Policy - Terms & Conditions</Typography>
                    </Grid>
                </Grid>
            </Container> */}
        </div>
    );
}

export default CMSFooter;