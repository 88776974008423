import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Card } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import ProgressBar from '../commonComponents/progressbar';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
import { CheckNumeric } from '../commonFunctions/checkNumeric';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "var(--primary-color2)",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "var(--primary-color8) !important"
    },
    activePath: {
        color: "var(--primary-color6) !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderStatusTableBody: {
        '& td': {
            fontSize: "16px !important",
            color: "var(--primary-color1)",
        }
    },
    tableHeader: {
        backgroundColor: 'var(--primary-color14)',
        '& th': {
            border: "none !important"
        }
    },
    tableContainer: {
        boxShadow: "none !important",
        marginTop: "20px !important",
        '& td': {
            borderBottom: "1px solid var(--primary-color14) !important",
        }
    },
    summeryBorder:{
        padding: "15px 0px",
        borderBottom: "1px solid var(--primary-color14) !important",
    },
    summeryBorderBottom:{
        padding: "15px 0px",
        marginBottom: "20px",
        borderTop: "1px solid var(--primary-color1) !important",
    },
    addressInner:{
        position: "relative",
        width: "250px"
    },
    userAddressBox:{
        paddingTop: "15px"
    },
    editAddress:{
        position: "absolute",
        right: "10px",
        top: "0px"
    },
    dialogInner:{
        padding: "60px 20px"
    },
    dialogTitle:{
        padding: "10px 0px !important",
        lineHeight: "25px !important"
    },
    dialogContent:{
        padding: "10px 35px !important",
    },
    dialogBtnBox:{
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "5px"
    },
    dialogClose:{
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "var(--primary-color5)",
        cursor: "pointer"
    },
    dialogBtn:{
        minWidth: "190px !important",
        padding: "10px 0px !important"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },

}));


function IslanderShipment() {
    const classes = useStyles();
    const [shipmentDetails, setShipmentDetails] = useState([]);
    const [boxDetails, setBoxDetails] = useState([]);
    const [boxTotal, setBoxTotal] = useState();
    const [totalBoxAccessoriesValue, setTotalBoxAccessoriesValue] = useState();
    const [priorityItemTotal, setPriorityItemTotal] = useState();
    const [priorityProducts, setPriorityProducts] = useState([]);
    const [orderId, setOrderId] = useState('');
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [recipientAddress, setRecipientAddress] = useState({});
    const [senderAddress, setSenderAddress] = useState({});
    const [open, setOpen] = useState(false);
    const [invoiceNo, setInvoiceNo] = useState(null); 
    const fillPercentage = [100, 100, 100, 25];

    useEffect(() => {
        fetchData();
        fetchAddresses();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const fetchData = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                 ;
                setBoxDetails(response.shipmentBox)
                setBoxTotal(response.totalBoxValue)
                setPriorityItemTotal(response.totalPriorityProductValue)
                setTotalBoxAccessoriesValue(response.totalBoxAccessoriesValue)
                setPriorityProducts(response.PriorityProducts)
                setOrderId(response.id)
                setInvoiceNo(response.invoiceNo)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchAddresses = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/getAddresses/", [], true);
            if (response) {
                 ;
                const recipientAddressData = response.find(address=> address.address_type === 1);
                const senderAddressData = response.find(address=> address.address_type === 2);
                setRecipientAddress(recipientAddressData || {});
                setSenderAddress(senderAddressData || {});
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
    
    const handleDelete = async (id) => {
        await deleteRow(id);
        // const updatedRows = priorityProducts;
        // setPriorityProducts(updatedRows)
    };

    const deleteRow = async (id) => {
        try {
          const response = await Request.delete(`islander/iDeletePriorityProduct/${id}`, [], true);
          if (response.ok) {
            console.log('Row deleted from API');
            setDeleteMessage(true)
            setTimeout(() => {
                setDeleteMessage(false)
            }, 3000);
            fetchData()
          } else {
            console.error('Failed to delete row from API');
          }
        } catch (error) {
          console.error('Error deleting row from API:', error);
        }
      };

    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Ready To Ship</Typography>
                <Box>
                    <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                </Box>
            </Box>

            <Box className='cartMainContainer'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Box className="gridInnerBlockLeft">
                            {/* <Box>
                                {orderId && (
                                    <p className='font-20 color2 font-bold'>Order: <span className='font-20 color3 font-medium'>{invoiceNo}</span></p>
                                )}
                            </Box> */}
                            <Box className={classes.userAddressBox} sx={{pb:4}}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box className={classes.addressInner}>
                                            <Typography className='font-18 font-600 color1'>Recipient Address:</Typography>
                                            <Box className='font-16 color1 shipmentAddress'>
                                                {recipientAddress.address_line_1}, {recipientAddress.address_line_2}, {recipientAddress.city}-{recipientAddress.zip}, {recipientAddress.country}
                                            </Box>
                                            {/* <img src="/assets/img/edit_address.png" className={classes.editAddress}></img> */}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/* <Box className={classes.addressInner} sx={{"margin": "0 0 0 auto"}}>
                                            <Typography className='font-20 font-600 color1'>Sender Address:</Typography>
                                            <Box className='font-16 color1 shipmentAddress'>
                                                {senderAddress.address_line_1}, {senderAddress.address_line_2}, {senderAddress.city}-{senderAddress.zip}, {senderAddress.state}, {senderAddress.country}
                                            </Box>
                                            <img src="/assets/img/edit_address.png" className={classes.editAddress}></img>
                                        </Box> */}
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* <Box sx={{ pt: 4, pb: 4 }}>
                                <Typography className='font-16 font-bold color1 text-underline'>Summary of the items in box</Typography>
                            </Box> */}
                            {boxDetails.length > 0 && (
                                <Box>
                                    <Typography className='font-20 font-bold color2'>Box/Barrel Details</Typography>
                                    <Box>
                                        <TableContainer component={Paper} className={classes.tableContainer}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow className={classes.tableHeader}>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>Size</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>Weight(lbs)</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>QTY</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>Price($)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className={classes.orderStatusTableBody}>
                                                    {(boxDetails && boxDetails.length>0) && boxDetails.map((row) => (
                                                        <TableRow
                                                            key={row.id}
                                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align='center'>{CheckNumeric(row.size)}</TableCell>
                                                            <TableCell align="center">{row.weight}</TableCell>
                                                            <TableCell align='center'>{row.quantity}</TableCell>
                                                            <TableCell align="center" className='color4'>${row.price}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>

                                        </TableContainer>
                                        <Box className="flexBox priceTotalBox" justifyContent='right'>
                                            <Typography className='color1 font-18 font-bold'>Total Amount:</Typography>
                                            <Typography className='color4 font-18 font-bold'>${FormatPrice(boxTotal)}</Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            )}
                            {priorityProducts.length > 0 && (
                                <Box>
                                    <Typography className='font-20 font-bold color2'>Items added to fill up the box</Typography>
                                    <Box>
                                        <TableContainer component={Paper} className={classes.tableContainer}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow className={classes.tableHeader}>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>Item</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>Price($)Unit</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>QTY</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold'>Sub Total($)</TableCell>
                                                        <TableCell align="center" className='color1 font-16 font-bold' sx={{ width: 30 }}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className={classes.orderStatusTableBody}>
                                                    {(priorityProducts && priorityProducts.length>0) && priorityProducts.map((row) => (
                                                        <TableRow
                                                            key={row.id}
                                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align='center'>{row.islander_product.name}</TableCell>
                                                            <TableCell align="center">{FormatPrice(row.price)}</TableCell>
                                                            <TableCell align='center'>{row.quantityCharged}</TableCell>
                                                            <TableCell align="center" className='color4'>${FormatPrice(row.priorityProductValue)}</TableCell>
                                                            <TableCell align="center">
                                                                <img src='/assets/img/delete_icon.png' id={row.id} onClick={() => handleDelete(row.id)}></img>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box className="flexBox priceTotalBox" justifyContent='right'>
                                            <Typography className='color1 font-18 font-bold'>Total Amount:</Typography>
                                            <Typography className='color4 font-18 font-bold'>${FormatPrice(priorityItemTotal)}</Typography>
                                        </Box>

                                        {deleteMessage && (
                                            <Box sx={{mt: -4}} align="center">
                                                <Typography className='font-16 color4 font-600'>Quantity successfully updated</Typography>
                                            </Box>
                                        )}
                                        


                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Box className="gridInnerBlockRight">
                            <Typography className='font-20 color2 font-bold summeryTitle'>Order Summary</Typography>
                            <Box className={classes.summeryBorder}>
                                <Grid container>
                                    <Grid item xs={8} sm={6} md={6} lg={6}>
                                        <Typography className='color3 font-16 font-600'>Shipping Cost</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={6} md={6} lg={6}>
                                        <Typography className='color3 font-16 font-600' align='right'>${FormatPrice(boxTotal)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8} sm={6} md={6} lg={6}>
                                        <Typography className='color3 font-16 font-600'>Packaging & Material cost</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={6} md={6} lg={6}>
                                        <Typography className='color3 font-16 font-600' align='right'>${FormatPrice(totalBoxAccessoriesValue)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{minHeight: "100px"}}>

                            </Box>
                            <Box className={classes.summeryBorder}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className='color3 font-16 font-600'>Product Cost</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className='color3 font-16 font-600' align='right'>${FormatPrice(priorityItemTotal)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.summeryBorderBottom}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className='color1 font-19 font-bold'>Total Amount</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className='color1 font-19 font-bold' align='right'>${FormatPrice(priorityItemTotal + boxTotal + totalBoxAccessoriesValue)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box align="center">
                                {/* <Button className='b1 b4' onClick={handleClickOpen}>Proceed To Checkout</Button> */}
                                <Link to="/user/islander/islander-checkout">
                                    <Button className='b1 b4'>Proceed To Checkout</Button>
                                </Link>
                            </Box>

                        </Box>
                        
                        {/* confirm Dialog */}
                        {/* <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                align="center"
                                className={classes.alertDialog}
                            >

                                <Box className={classes.dialogInner}>
                                    <DialogTitle className={`${classes.dialogTitle} font-25 font-bold`} id="alert-dialog-title">
                                    {"Are you sure to proceed?"}
                                    </DialogTitle>
                                    <DialogContent className={classes.dialogContent}>
                                    <DialogContentText className="font-16 color3" id="alert-dialog-description">
                                        You will not be able to add more invoices in the islander box later.
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Box className={classes.dialogBtnBox}>
                                            <Button className={`${classes.dialogBtn} b1`} onClick={handleClose}>Back</Button>
                                            <Button className={`${classes.dialogBtn} b1 b4`} onClick={createOrder} autoFocus>Yes, Proceed</Button>
                                        </Box>
                                    </DialogActions>
                                </Box>
                               <CloseIcon className={classes.dialogClose} onClick={handleClose}/>
                            </Dialog> */}
                         {/* confirm Dialog end*/}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default IslanderShipment;
