import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    customers: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchCustomers = createAsyncThunk(
    "customer/fetchCustomers",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/users/", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  
  // Async thunk action to delete a customer
  export const deleteCustomer = createAsyncThunk(
    "customer/deleteCustomer",
    async (id) => {
        try {
			const response = await Request.delete(`admin/user/${id}`, [], true);
			if(response) {
				if (response.status === 204) {
					// Update the state to remove the deleted user
					return id
				  }
				}
		  } catch (error) {
			console.error('Error deleting row from API:', error);
		  }
    }
  );
  

    // Async thunk action to delete a customer
    export const updateCustomer = createAsyncThunk(
        "customer/updateCustomer",
        async ({id, item}) => {
            try {
                  
                const response = await Request.put(`admin/user/${id}`, item, true);
                if(response) {
                    if (response.status === 200) {
                        // Update the state to remove the deleted user
                        return response.status
                      }
                    }
              } catch (error) {
                console.error('Error update row from API:', error);
              }
        }
      );

  const customerSlice = createSlice({
    name: "customer",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchCustomers.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchCustomers.fulfilled, (state, action) => {
            state.loading = false;
            state.customers = action.payload;
          })
          .addCase(fetchCustomers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(deleteCustomer.pending, (state) => {
            state.loading = true;
          })
          .addCase(deleteCustomer.fulfilled, (state, action) => {
            state.loading = false;
            const deletedCustomerId = action.payload;
            state.customers = state.customers.filter(
              (customer) => customer.id !== deletedCustomerId
            );
          })
          .addCase(deleteCustomer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(updateCustomer.pending, (state) => {
            state.loading = true;
          })
          .addCase(updateCustomer.fulfilled, (state, action) => {
            state.loading = false;
            // Update the customer data in the state (if needed)
            const updatedCustomerData = action.payload;
            state.customers = state.customers.map((customer) =>
              customer.id === updatedCustomerData.id
                ? updatedCustomerData
                : customer
            );
          })
          .addCase(updateCustomer.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
      },
  });
  
  export default customerSlice.reducer;