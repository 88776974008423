import React, { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Menu,
	MenuItem,
	Box,
    Typography,
	FormHelperText
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxList } from "../redux/taxSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Request from '../customUtils/Http/Request';
import { Form } from "react-bootstrap";
import { FormatPrice } from "../commonFunctions/commonPriceFunction";

function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
}

// function FormatPrice2(price) {
// 	const numericPrice = parseFloat(price);

// 	// if (!isNaN(numericPrice)) {
// 	// 	if(numericPrice)
// 	//   return numericPrice.toFixed(2);
// 	// } else {
// 	//   return "N/A";
// 	// }
// 	if (numericPrice % 1 !== 0) {
// 		return numericPrice.toFixed(2);
// 	} else {
// 		return numericPrice.toString();
// 	}
// }

const TaxListTable = ({list}) => {
	const [selectedPrice, setSelectedPrice] = useState(null);
	const dispatch = useDispatch();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	const [isValid, setIsValid] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState('');
	// const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({
        province: "",
        gst: 0,
        hst: 0,
        pst: 0,
	});

	const handleCloseDialog = () => {
		setSelectedPrice(null);
		setIsDialogOpen(false);
	};

	const openDeleteModal = (id) => {
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};


	 const handleFieldChange = (field, value) => {
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}));	
	};

	 const productDelete = async (id) => {
		try {
				const response = await Request.delete(`admin/rate/tax/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchTaxList())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
		}
		
	};

	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		// setIsDialogOpen(true);
			try {
				const response = await Request.get(`admin/rate/tax/${id}`, [], true);
				if (response.length !== 0) {
					// if (response) {
						setIsDialogOpen(true);
						setFormData({
							province: response.province,
							gst: response.GST,
							hst: response.HST,
                            pst: response.PST,
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(formData.province !== '' && formData.gst !== '' && formData.hst !== '' && formData.pst !== '')
		{
			try {
				const response = await Request.put(`admin/rate/tax/${selectedProductEdit}`, formData, true);
				if (response.status === 200) {
					handleCloseDialog();
					dispatch(fetchTaxList())
					setFormData({
						province: "",
						gst: 0,
						hst: 0,
						pst: 0,
					});
				} else if(response.status === 404){
					setErrorMessage("Please Enter valid Email or Phone.")
				}else if(response.status === 400){
					setErrorMessage("Email already registered.")
				}else{
					console.error('Failed to fetch data from API');
				}
			} catch (error) {
				console.error('Error fetching data from API:', error);
			}
		}else{
			alert("Please fill all the fields");
		}	
	};

	return (
		<Box className="order-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Province</TableCell>
							<TableCell>PST(%)</TableCell>
							<TableCell>GST(%)</TableCell>
							<TableCell>HST(%)</TableCell>
							<TableCell>Total Tax(%)</TableCell>
                            <TableCell>Last Updated On</TableCell>
                            <TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(list && list.length>0) && list.map((item) => (
							<TableRow key={item.id}>
								<TableCell>{item.province}</TableCell>
								<TableCell>{FormatPrice(item.PST)}</TableCell>
								<TableCell>{FormatPrice(item.GST)}</TableCell>
                                <TableCell>{FormatPrice(item.HST)}</TableCell>
								<TableCell>{FormatPrice(item.totalTax)}</TableCell>
                                <TableCell>{formatDate(item.updatedAt)}</TableCell>
								<TableCell>
									<IconButton
											aria-label="Edit"
											onClick={() => openEditModal(item.id)} 
										>
											<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
										<IconButton
											aria-label="Delete"
											onClick={() => openDeleteModal(item.id)}
										>
											<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* delete dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this tax?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			{/* edit dialog */}
			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit = {handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Edit Tax
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
                                <Form.Group
                                        controlId="province"
                                        className="col-md-6 add-category-form__field userField select-field"
                                    >
                                        <Form.Label>Province</Form.Label>
										<Form.Select
											name="province"
											value={formData.province}
											onChange={(e) =>
                                                handleFieldChange(
                                                    "province",
                                                    e.target.value
                                                )
                                            }
											required
										>
											<option value="">Select Province</option>
											<option value="Alberta">Alberta</option>
											<option value="British Columbia">British Columbia</option>
											<option value="Manitoba">Manitoba</option>
											<option value="New Brunswick">New Brunswick</option>
											<option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
											<option value="Northwest Territories">Northwest Territories</option>
											<option value="Nova Scotia">Nova Scotia</option>
											<option value="Nunavut">Nunavut</option>
											<option value="Ontario">Ontario</option>
											<option value="Prince Edward Island">Prince Edward Island</option>
											<option value="Quebec">Quebec</option>
											<option value="Saskatchewan">Saskatchewan</option>
											<option value="Yukon">Yukon</option>
										</Form.Select>
                                 </Form.Group>
                                 <Form.Group
                                        controlId="gst"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>GST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="GST"
                                            min={0}
                                            value={formData.gst}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "gst",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="hst"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>HST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="HST"
                                            min={0}
                                            value={formData.hst}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "hst",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="pst"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>PST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="PST"
                                            min={0}
                                            value={formData.pst}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "pst",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
								
							</div>
                            <Typography className="color4 font-15 font-600 pt-3">{errorMessage}</Typography>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Edit
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</Box>
	);
};

export default TaxListTable;
