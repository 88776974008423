import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import BaseIslanderAddressForm from "./baseIslanderAddressForm";
import Request from '../customUtils/Http/Request';

function BaseIslanderAddress() {
	return (
		<Box className="adminInnerPage baseIslanderAddressFormPage">
			<Typography variant="h2" className="adminInnerPage__title">
				Base Islander Address
			</Typography>

			<BaseIslanderAddressForm />
		</Box>
	);
}

export default BaseIslanderAddress;
