import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Form, Dropdown } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import OrderInfoBox from './orderInfoBox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Opacity } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ProgressBar from '../commonComponents/progressbar';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    deleteIcon: {
        color: "#FF2D2D",
        fontSize: "25px !important",
    },
    deleteIconBox: {
        height: "40px",
        width: "40px",
        backgroundColor: "#FFF3F3",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    flexOuter: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        marginBottom: "10px",
        overflowX: "auto"
    },
    flexInner: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid var(--primary-color13)",
        borderRadius: "8px !important",
    },
    flex2: {
        flex: "2",
        borderRight: "1px solid var(--primary-color13)",
        margin: "10px 15px",
    },
    flex1: {
        flex: "1",
        borderRight: "1px solid var(--primary-color13)",
        margin: "10px 15px",
        '&:last-child': {
            border: "none !important",
            textAlign: "center"
        }
    },
    invoiceBtn: {
        borderRadius: "5px",
        backgroundColor: "#f6f6f6 !important",
        color: "#727272 !important",
        fontSize: "13px !important",
        fontWeight: "600 !important",
        padding: "5px 8px !important"
    },
    dialogInner: {
        padding: "60px 20px"
    },
    dialogTitle: {
        padding: "10px 0px !important",
        lineHeight: "25px !important"
    },
    dialogContent: {
        padding: "10px 35px !important",
    },
    dialogBtnBox: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "5px"
    },
    dialogClose: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "var(--primary-color5)",
        cursor: "pointer"
    },
    dialogBtn: {
        minWidth: "190px !important",
        padding: "10px 0px !important"
    },
    websiteDropdown: {
        border: "1px solid var(--primary-color13)",
        fontSize: "13px !important",
        padding: "5px 10px !important",
        color: "var(--primary-color1) !important",
        '& option': {
            color: "var(--primary-color8) !important",
        }
    },
    flexHeader: {
        display: "flex",
        gap: "20px",
        alignItems: "center"
    },
     '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
        flexOuter: {
            display: "block"
        },
        flexHeader: {
            display: "block"
        },
    },
    '@media (max-width: 767px)': {
       
        websiteDropdown:{
            width: "90%"
        },
        flexInner: {
            flexDirection: 'column', // Stack elements vertically
            textAlign: 'center', // Center text content
          },
          flex2: {
            flex: 'none', // Reset flex property
            width: '100%', // Expand to full width
            borderRight: 'none', // Remove right border
            margin: '10px 0', // Adjust margin for smaller screens
          },
          flex1: {
            borderRight: 'none', // Remove right border
          },
          deleteIconBox: {
            marginTop: "10px"
          },
          dialogBtnBox:{
            display: "block",
            '& button' :{
                marginTop: "10px"
            }
          }
    }
}));

function IslanderOrder() {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const fileInputRef = useRef(null);
    const buttonRef = useRef(null);
    const orderBtnRef = useRef(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [inputVisible, setInputVisible] = useState(false);
    const [recipientAddress, setRecipientAddress] = useState({});
    const [messageVisible, setMessageVisible] = useState(false);
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    // const [fillPercentage, setFillPercentage] = useState(50);

    const predefinedOptions = ['Shein', 'Bluenotes', 'Urban Planet', 'Ardene', 'Old Navy', 'Real Canadian Superstore',
        'Canadian Tire', 'No Frills', 'Bath & Body Works', 'Sephora', 'Walmart', 'Amazon', 'Carters Oshkosh'];

    const fillPercentage = [66.66, 0, 0, 0];

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData2();
    }, []);

    const fetchData2 = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                 
                // if(editParam === 'true' && response.recipientAddress !== null){
                //     navigate('/user/islander/islander-send?edit=true')
                // }
           
                    if(response.message === "Not found" && response.recipientAddress === null){
                        navigate('/user/islander/islander-send')
                    }
                    else{
                        if(response.recipientAddress !== null && response.message === "Not found"){
                            navigate('/user/islander/islander-order')
                        }
                        else{
                            switch (response.status) {
                                case 0:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 1:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 2:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 3:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 4:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 5:
                                    return navigate('/user/islander/islander-order-status');
                                break;
                                case 6:
                                    return navigate('/user/islander/islander-shipment');
                                    break;
                                case 7:
                                    return navigate('/user/islander/islander-order-status');  
                                    break;
                                case 8:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                default:
                                    return;
                              }       
                        }             
                    }
              
              
                // setRows(response.items.map((row) => ({ ...row, fromAPI: true, invoiceName: '' })));
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    useEffect(() => {
        fetchAddresses();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addressEdit = () => {
        // navigate('/user/islander/islander-send?edit=true')
        const queryParams = new URLSearchParams();
        queryParams.append('edit', 'true');

        const queryString = queryParams.toString();
        const destinationUrl = `/user/islander/islander-send?${queryString}`;
        window.location.href = destinationUrl;
    }

    const createOrder = async () => {
        let response = await Request.post("islander/createInvoiceOrder/", {}, true)
        {
            if (response.message === "Success") {
                navigate('/user/islander/islander-order-status')
            }
            else {
                console.log('something went wrong');
            }
        }
        if (!response) {
            console.log('something went wrong');
        }
        setOpen(false);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const fetchData = async () => {
        try {
            const response = await Request.get("islander/getItemInvoices/", [], true);
            if (response) {
                // const data = await response.json();
                const data = response.items;
                // if(data[0].iShipmentDetailId != null){
                //     window.location.href = '/user/islander/islander-order-status'
                //     navigate('/user/islander/islander-order-status');
                // }
                // if(response.message == "Not found"){
                //     setRows([{ id: Date.now(), url: '', trackingNumber: '', orderedQuantity: '', invoice: null, editable: true }]);
                //     orderBtnRef.current.disabled = true;
                // }
                if (data === undefined) {
                    setRows([{ id: Date.now(), url: '', trackingNumber: '', orderedQuantity: '', invoice: null, editable: true }]);
                    orderBtnRef.current.disabled = true;
                }
                if (data !== undefined) {
                    if (data.length < 10) {
                        setRows([...data, { id: Date.now(), url: '', trackingNumber: '', orderedQuantity: '', invoice: null, editable: true }]);
                        orderBtnRef.current.disabled = false;
                    }
                    else {
                        setRows([...data])
                        buttonRef.current.disabled = true;
                    }
                }

                // setRows(response.items.map((row) => ({ ...row, fromAPI: true, invoiceName: '' })));
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchAddresses = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/getAddresses/", [], true);
            if (response) {
                 ;
                const recipientAddressData = response.find(address=> address.address_type === 1);
                setRecipientAddress(recipientAddressData || {});

            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const addRow = async () => {
        const lastRow = rows[rows.length - 1];
        if (lastRow.url && lastRow.trackingNumber && lastRow.orderedQuantity && lastRow.editable && lastRow.invoice) {
            // Save last row data to the API
            await saveRowData(lastRow);
            setRows([...rows.slice(0, -1), { id: Date.now(), url: '', trackingNumber: '', orderedQuantity: '', invoice: null, editable: true }]);
        }
        else{
            setErrorMessageVisible('Fill all fields and select invoice')
            setTimeout(() => {
                setErrorMessageVisible('');
            }, 3000);
        }
    };

    const handleDeleteRow = async (id) => {
        await deleteRow(id);
        const updatedRows = rows.filter((row) => row.id !== id);
        buttonRef.current.disabled = false;
        fetchData();
    };

    const deleteRow = async (id) => {
        try {
            const response = await Request.delete(`islander/deleteItemInvoice/${id}`, [], true);
            if (response.ok) {
                console.log('Row deleted from API');
            } else {
                console.error('Failed to delete row from API');
            }
        } catch (error) {
            console.error('Error deleting row from API:', error);
        }
    };

    //   const handleDropdownChange = (event) => {
    //     const selectedOption = event.target.value;
    //     setSelectedValue(selectedOption);

    //     // Show the input box if the user selects a specific option
    //     setInputVisible(selectedOption === 'Other');
    //   };

    const handleInputChange = (id, event) => {
        const value = event.target.value;
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === id ? { ...row, url: value } : row))
        );
    };

    const handleDataChange = (id, field, value) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id && row.editable) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(updatedRows);
    };


    const handleDropdownChange = (id, e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (selectedOption === 'Other') {
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? { ...row, url: '' } : row))
            );
        } else {
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? { ...row, url: selectedValue } : row))
            );
        }
        // If the selected value is 'Other', enable the input field, else disable it
        // if (value === 'Other') {
        //   handleDataChange(id, 'url', '');
        // } else {
        //   handleDataChange(id, 'url', value);
        // }
    };

    const handleInvoiceUpload = (id, file) => {
        const fileName = file.name;
        setSelectedFileName(fileName)
        // setSelectedFileName(file.name)
        const updatedRows = rows.map((row) => {
            if (row.id === id && row.editable) {
                return { ...row, invoice: file };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const saveRowData = async (lastRowData) => {
        // try {
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('quantity', lastRowData.orderedQuantity);
        formdata.append('tracking', lastRowData.trackingNumber);
        formdata.append('url', lastRowData.url);
        formdata.append('invoice', lastRowData.invoice);
        formdata.append('saveForLater', lastRowData.editable);

        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch("https://phpstack-1065403-3744116.cloudwaysapps.com/api/islander/addItemInvoices/", requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}islander/addItemInvoices/`, requestOptions) 
            .then(response => {
                setMessageVisible(true);
                setSelectedFileName(null)
                setTimeout(() => {
                    setMessageVisible(false);
                }, 3000);
                return response.text();
            })
            .then(
                result => 
                fetchData()
            )
            .catch(error => console.log('error', error));
    };

    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Send Islander Box</Typography>
                {/* <Box className={classes.headerFlexBox}>
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Step1</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                </Box> */}
                <Box>
                    <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Box className="gridInnerBlockLeft islanderOrderBox">
                            <Box className="">
                                <Box className={classes.flexHeader}>
                                    <Typography className='font-22 color2 font-bold'>Create Order :</Typography>
                                    <Typography className='font22 color3'>Enter your order details and upload the relevant invoice.</Typography>
                                </Box>

                            </Box>
                            <Box>
                                <form>
                                    <Box sx={{ maxWidth: "100%" }} className='orderTable'>
                                        {(rows && rows.length>0) && rows.map((row) => (
                                            <Box className={classes.flexOuter} key={row.id}>
                                                <Box className={classes.flexInner}>
                                                    <Box className={classes.flex2}>
                                                        {row.editable ? (
                                                            <div key={row.id}>
                                                                {selectedOption === 'Other' ? (
                                                                    <input
                                                                        type="text"
                                                                        value={row.url}
                                                                        onChange={(e) => handleInputChange(row.id, e)}
                                                                        placeholder='Enter Website Name'
                                                                        required
                                                                    />
                                                                )
                                                                    : (
                                                                        <select
                                                                            className={classes.websiteDropdown}
                                                                            value={selectedOption}
                                                                            required
                                                                            onChange={(e) => handleDropdownChange(row.id, e)}
                                                                        >
                                                                            <option value="">Select Website Name</option>
                                                                            {predefinedOptions.map((option) => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            <Typography className='font-16 font-600 color1'>{row.url}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box className={classes.flex2}>
                                                        {row.editable ? (
                                                            <input
                                                                type="text"
                                                                value={row.trackingNumber}
                                                                required
                                                                onChange={(e) => handleDataChange(row.id, 'trackingNumber', e.target.value)}
                                                                placeholder='Enter Tracking No'
                                                            />
                                                        ) : (
                                                            <Typography className='font-16 font-600 color1'>{row.trackingNumber}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box className={classes.flex2}>
                                                        {row.editable ? (
                                                            <input
                                                                type="number"
                                                                value={row.orderedQuantity}
                                                                required
                                                                onChange={(e) => handleDataChange(row.id, 'orderedQuantity', e.target.value)}
                                                                placeholder='Number of packages'
                                                            />
                                                        ) : (
                                                            <Typography className='font-16 font-600 color1'>{row.orderedQuantity}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box className={classes.flex1}>
                                                        {row.editable ? (
                                                            <Box>
                                                                <Typography onClick={handleButtonClick} className='font-14 text-underline font-600 color4 cursor-pointer'>Select Invoice</Typography>
                                                                <input
                                                                    type="file"
                                                                    required
                                                                    readOnly={row.fromAPI}
                                                                    ref={fileInputRef}
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => handleInvoiceUpload(row.id, e.target.files[0])}
                                                                />
                                                                {/* {selectedFileName && (
                                                                    <span className='font-14 color2 font-600'>{selectedFileName}</span>
                                                                )} */}
                                                                   {selectedFileName && (
                                                                        <span className="file-name font-14 color2 font-600" title={selectedFileName}>
                                                                        {selectedFileName.length > 20 ? (
                                                                            <>
                                                                            <span className="truncated-name font-14 color2 font-600">{selectedFileName.slice(0, 20)}...</span>
                                                                            </>
                                                                        ) : (
                                                                            selectedFileName
                                                                        )}
                                                                        </span>
                                                                    )}
                                                            </Box>
                                                        ) : (
                                                            <a href={`${process.env.REACT_APP_DatabaseStatic}${row.invoice}`} target="_blank">
                                                                    <Typography className='color4 font-16 text-underline font-bold invoiceName cursor-pointer'>View Invoice
                                                                    </Typography>
                                                            </a>
                                                        )}
                                                    </Box>

                                                </Box>
                                                <Box>
                                                    {row.editable ? (
                                                        <Box>
                                                              <Button type="submit" ref={buttonRef} id="addRow" onClick={addRow} className='b3 addRow'>Upload</Button>
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {/* <Box className={classes.deleteIconBox} onClick={() => handleDeleteRow(row.id)}> */}
                                                            <Box className={classes.deleteIconBox} onClick={() => handleDeleteRow(row.id)}>
                                                                <DeleteForeverIcon className={classes.deleteIcon} />
                                                            </Box>
                                                            {/* </Box> */}
                                                        </>
                                                    )}
                                                </Box>

                                            </Box>
                                        ))}
                                    </Box>

                                </form>

                                <Box sx={{ mt: 4 }}>
                                    <Typography className='font-16 color4 font-bold' sx={{pb: 2}}>{errorMessageVisible}</Typography>
                                    {messageVisible && <Typography className='font-16 color4 font-bold' sx={{pb: 2}}>Invoice Saved for Later</Typography>}
                                    <Button className="b1 orderBtn" onClick={handleClickOpen} ref={orderBtnRef}>Create Order</Button>
                                </Box>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    align="center"
                                    className={classes.alertDialog}
                                >

                                    <Box className={classes.dialogInner}>
                                        <DialogTitle className={`${classes.dialogTitle} font-25 font-bold`} id="alert-dialog-title">
                                            {"Are you sure to proceed?"}
                                        </DialogTitle>
                                        <DialogContent className={classes.dialogContent}>
                                            <DialogContentText className="font-16 color3" id="alert-dialog-description">
                                                You will not be able to add more invoices in the islander box later.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Box className={classes.dialogBtnBox}>
                                                <Button className={`${classes.dialogBtn} b1`} onClick={handleClose}>Back</Button>
                                                <Button className={`${classes.dialogBtn} b1 b4`} onClick={createOrder} autoFocus>Yes, Proceed</Button>
                                            </Box>
                                        </DialogActions>
                                    </Box>
                                    <CloseIcon className={classes.dialogClose} onClick={handleClose} />
                                </Dialog>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <OrderInfoBox change={12}/>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{mt: 3}}>
                            {recipientAddress && (
                                <Box className="gridInnerBlockRight">
                                    <Box className="boxFlex">
                                        <Typography align="left" className='font-20 color2 font-bold'>Recipient Address</Typography>
                                        <img src="/assets/img/edit_address.png" className={classes.editAddress} onClick={addressEdit}></img>
                                      
                                    </Box>
                                    
                                    <Box className='font-16 color1' sx={{pt:2}}>
                                        <Typography className='font-16 color2'>
                                            <span className='font-16 color2 font-bold'>Name:</span>
                                            <span className='font-16 color3 pl'>{recipientAddress.fullName}</span>
                                        </Typography>
                                        <Typography className='font-16 color2'>
                                            <span className='font-16 color2 font-bold'>Address:</span>
                                            <span className='font-16 color3 pl'>{recipientAddress.address_line_1}, {recipientAddress.address_line_2}</span>
                                        </Typography>
                                        <Typography className='font-16 color2'>
                                            <span className='font-16 color2 font-bold'>Phone:</span>
                                            <span className='font-16 color3 pl'>{recipientAddress.phone}</span>
                                        </Typography>
                                        <Typography className='font-16 color2'>
                                            <span className='font-16 color2 font-bold'>Email:</span>
                                            <span className='font-16 color3 pl'>{recipientAddress.email}</span>
                                        </Typography>
                                        <Typography className='font-16 color2'>
                                            <span className='font-16 color2 font-bold'>City:</span>
                                            <span className='font-16 color3 pl'>{recipientAddress.city}-{recipientAddress.zip}</span>
                                        </Typography>       
                                        <Typography className='font-16 color2'>
                                            <span className='font-16 color2 font-bold'>Country:</span>
                                            <span className='font-16 color3 pl'>{recipientAddress.country}</span>
                                        </Typography>
                                        
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default IslanderOrder;
