import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Paper } from '@mui/material';
import { connect } from 'react-redux';
import Request from '../customUtils/Http/Request';
// import Masonry from '@mui/lab/Masonry';
// import MasonryItem from '@mui/lab/MasonryItem';
import Masonry from 'react-layout-masonry';
import { Col, Row, Form } from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import Header from '../header/header';
import Footer from '../footer/footer';


const useStyles = makeStyles((theme) => ({
   reviewBox: {
    backgroundColor: "var(--primary-color)",
    border: "1px solid var(--primary-color13)",
    borderRadius: "8px",
    padding: "25px"
   },
    activePath: {
        color: "var(--primary-color6) !important"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}))

const CustomerReview = () => {
    const classes = useStyles();
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
      fetchReview()
    }, []);

    const fetchReview = async () => {
          
		try {
			const response = await Request.get(`review/reviews`, []);
			if (response) {
				 
				setReviews(response);
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
	};

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                {/* <Box className='middle_block middle_coming_block' textAlign="center" sx={{pb: 12}}>
                    <Box>
                        <img src='/assets/img/comingSoon.svg' className='comingSoon_img'></img>
                    </Box>
                </Box> */}
                 <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Customer Reviews</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5, pb: 7 }}>
                        {reviews && reviews.length>0 ? (
                                <Masonry columns={{ 640: 1, 768: 2, 1024: 3, 1280: 2 }}>
                                    {reviews.map((item) => (
                                        <Box key={item.id} textAlign="left" className={classes.reviewBox} style={{
                                            marginBottom: '20px',
                                            marginRight: '20px',
                                            '@media (max-width: 768px)': {
                                            marginBottom: '10px',
                                            marginRight: '10px',
                                            },
                                        }}>
                                            <Box>
                                                <Rating
                                                    name="simple-controlled"
                                                    value={item.rate}
                                                    size="large"
                                                    readOnly 
                                                    className="rating c-rating d-flex"
                                                />
                                            </Box>
                                            <Box sx={{mt: 1,mb: 3}}>
                                                <Typography className='font-14 color1'>{item.review}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography className='font-16 font-bold color2'>{item.user?.fullName}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Masonry>
                           ):(
                            <Box textAlign="left" sx={{mt: 3, minHeight: "176px"}}>
                                <Typography className='font20 font-600 color4'>
                                    No Review Available
                                </Typography>
                            </Box>
                        )}
                        
                    </Box>
                 </Box>
            </Container>
        <Footer />
        </>
    );
}

export default CustomerReview;