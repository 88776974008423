import React, { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Menu,
	MenuItem,
	Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeliveryTimeList, fetchPurchaseDeliveryTimeList } from "../redux/deliveryTimeSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Request from '../customUtils/Http/Request';
import { Form } from "react-bootstrap";
import { fetchPickupAdminList } from "../redux/pickupLocationSlice";

const DeliveryTimeListTable = ({list, toCountryList, type}) => {
	const [selectedPrice, setSelectedPrice] = useState(null);
	const dispatch = useDispatch();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	// const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({
        from: "",
		to: "",
		estDeliveryTime: 0,
	});
	const pickupLocationList = useSelector(
		(state) => state.pickupList.pickupListAdminLocation
	);
	useEffect(() => { dispatch(fetchPickupAdminList()); }, []);
	useEffect(() => {
		dispatch(fetchPickupAdminList());
	  }, [dispatch]);
	const handleCloseDialog = () => {
		setSelectedPrice(null);
		setIsDialogOpen(false);
	};

	const openDeleteModal = (id) => {
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

	 const handleFieldChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};
	const filterProvince = (pickupLocations) => {
		
		var provincesList = [];
		pickupLocations.forEach((element) => {
		  if (!provincesList.includes(element.province)) {
			provincesList.push(element.province);
		  }
		});
		return provincesList;
	  };
	 const productDelete = async (id) => {
		if(type === "purchase"){
			try {
				const response = await Request.delete(`admin/rate/purchaseEstimate/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchPurchaseDeliveryTimeList())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
		}
		}
		else{
			try {
				const response = await Request.delete(`admin/rate/country/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchDeliveryTimeList())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
		}
		}
	};

	const openEditModal = async (id) => {
		setSelectedProductEdit(id);
		// setIsDialogOpen(true);
		if(type === "purchase"){
			try {
				const response = await Request.get(`admin/rate/purchaseEstimate/${id}`, [], true);
				if (response.length !== 0) {
					// if (response) {
						setIsDialogOpen(true);
						setFormData({
							from: response.from,
							to: response.to,
							estDeliveryTime: response.estDeliveryTime,
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
		else{
			try {
				const response = await Request.get(`admin/rate/country/${id}`, [], true);
				if (response.length !== 0) {
					// if (response) {
						setIsDialogOpen(true);
						setFormData({
							from: response.from,
							to: response.to,
							estDeliveryTime: response.estDeliveryTime,
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(type === "purchase"){
			const data = {
				from: formData.from,
				to: formData.to,
				estDeliveryTime: formData.estDeliveryTime,
				status: true
			}
			try {
				// Fetch data from API
				const response = await Request.put(`admin/rate/purchaseEstimate/${selectedProductEdit}`, data, true);
				if (response.status === 200) {
					handleCloseDialog();
					dispatch(fetchPurchaseDeliveryTimeList())
					setFormData({
						from: "",
						to: "",
						estDeliveryTime: "",
					  });
				} else {
					console.error('Failed to fetch data from API');
				}
			} catch (error) {
				console.error('Error fetching data from API:', error);
			}
		}else{
			
	
		try {
			// Fetch data from API
			const response = await Request.put(`admin/rate/country/${selectedProductEdit}`, formData, true);
			if (response.status === 200) {
				handleCloseDialog();
				dispatch(fetchDeliveryTimeList())
				setFormData({
                    from: "",
                    to: "",
                    estDeliveryTime: "",
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
		}	
	};

	return (
		<Box className="order-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>From</TableCell>
							<TableCell>To</TableCell>
							<TableCell>Estimated Delivery Time (Days)</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(list && list.length>0) && list.map((item) => (
							<TableRow key={item.id}>
								<TableCell>{item.from}</TableCell>
								<TableCell>{item.to}</TableCell>
								<TableCell>{item.estDeliveryTime}</TableCell>
								<TableCell>
									<IconButton
											aria-label="Edit"
											onClick={() => openEditModal(item.id)} 
										>
											<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
										<IconButton
											aria-label="Delete"
											onClick={() => openDeleteModal(item.id)}
										>
											<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* delete dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this Estimated Time?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			{/* edit dialog */}
			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit = {handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Edit Delivery Time
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
								<Form.Group
									controlId="from"
									className="shipping-price-form__field"
								>
									<Form.Label>From</Form.Label>
									<Form.Control
										as="select"
										value={formData.from}
                                        defaultValue="calgary" 
										onChange={(e) =>
											handleFieldChange(
												"from",
												e.target.value
											)
										}
									>
										{/* <option value="">Select From</option> */}
										{(type === "send")?
											filterProvince(pickupLocationList).map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											)) : <option value="calgary">Calgary</option>
										}
										
									</Form.Control>
								</Form.Group>

								{type === "purchase" ? (
									<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
                                            defaultValue="calgary" 
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
										>
											{/* <option value="" disabled>Select an option</option> */}
											{(toCountryList && toCountryList.length>0) && toCountryList.map(option => (
												<option key={option} value={option.city}>
													{option.city}
												</option>
											))}
											</Form.Select>
								</Form.Group>
								):(
									<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
                                            defaultValue="calgary" 
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
										>
											{/* <option value="" disabled>Select an option</option> */}
											{(toCountryList && toCountryList.length>0) && toCountryList.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
											</Form.Select>
								</Form.Group>
								)}
								

								<Form.Group
									controlId="estDeliveryTime"
									className="shipping-price-form__field"
								>	
									<Form.Label>Enter Delivery Time (Days)</Form.Label>
									<Form.Control
										type="number"
										value={formData.estDeliveryTime}
										placeholder="Enter Estimated Delivery Days"
										onChange={(e) =>
											handleFieldChange(
												"estDeliveryTime",
												e.target.value
											)
										}
										required
									/>
								</Form.Group>
							</div>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Edit
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</Box>
	);
};

export default DeliveryTimeListTable;
