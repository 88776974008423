import { useEffect, useState  } from 'react';
import { Routes, Route, Switch } from 'react-router-dom';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import Cookies from 'universal-cookie';
import { checkLogin, checkUserType } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import Header from '../header/header';
import Footer from '../footer/footer';
import Cart from '../checkout/cart';
import Checkout from '../checkout/checkout';
import BuyProduct from '../product/buyProduct';
import OrderDetail from '../checkout/orderDetail';
import Payment from '../checkout/payment';
import DashboardHeader from '../header/dashboardHeader';
import IslanderAddress from '../islanderBox/islanderAddress';
import Support from '../commonFunctions/support';
import IslanderSendBox from '../islanderBox/islanderSendBox';
import IslanderOrder from '../islanderBox/islanderOrder';
import IslanderOrderStatus from '../islanderBox/islanderOrderStatus';
import IslanderFillBox from '../islanderBox/islanderFillBox';
import IslanderShipment from '../islanderBox/islanderShipment';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import IslanderPayment from '../islanderBox/islanderPayment';
import IslanderCheckout from '../islanderBox/islanderCheckout';
import IslanderOrderDetail from '../islanderBox/islanderOrderDetail';
import Login from '../user/login/login';
import PageNotFound from './pageNotFound';


const cookies = new Cookies();
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginLeft: 'auto',
    boxSizing: 'border-box',
    marginRight: 'auto',
    display: 'block',
    padding: '16px', // Default padding for larger screens
    '@media (max-width: 600px)': {
        paddingLeft: '5px !important',
        paddingRight: '5px !important'
  },
  },
}));

function IslanderStructure() {
  // const [userType, setUserType] = useState(null);
  const classes = useStyles();
  let login = checkLogin();
  let userType = checkUserType();
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }

  // useEffect(() => {
  //   let userType = cookies.get('userType', { path: '/' });
  //   const userTypeAsNumber = parseInt(userType, 10);
  //   setUserType(userTypeAsNumber)
  // }, []);


  return (
    <>
     {(login === true && userType && userType === 3) ? ( 
        <Box className="main">
            <Sidebar />
            <Box className={`orderContainer ${minimized ? 'dashContainer' : 'containerExpand'}`}>
                <DashboardHeader />
                <Container maxWidth="l" className={classes.container}>
                    <Routes>
                        <Route path="/*" element={<PageNotFound />} />
                        <Route path="/islander-address" element={<IslanderAddress />}></Route>
                        <Route path="/islander-send" element={<IslanderSendBox />}></Route>
                        <Route path='/islander-order' element={<IslanderOrder />}></Route>
                        <Route path='/islander-order-status' element={<IslanderOrderStatus />}></Route>
                        <Route path='/islander-fill-item/:id' element={<IslanderFillBox />}></Route>
                        <Route path='/islander-shipment' element={<IslanderShipment />}></Route>
                        <Route path='/islander-checkout' element={<IslanderCheckout />}></Route>
                        <Route path='/islander-payment' element={<IslanderPayment />}></Route>
                        <Route path='/islander-order-detail' element={<IslanderOrderDetail />}></Route>
                    </Routes>
                </Container>
            </Box>
            <Support />
        </Box>
     ) : (
     <>
        <Login />
     </>
     )}
    </>
  )
}


export default IslanderStructure;

