import React, { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Menu,
	MenuItem,
	Box,
    Typography,
	FormHelperText
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../redux/userSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Request from '../customUtils/Http/Request';
import { Form, Modal } from "react-bootstrap";
import Cookies from 'universal-cookie';


const cookies = new Cookies()
function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
  }

const UsersListTable = ({list}) => {
	const [selectedPrice, setSelectedPrice] = useState(null);
	const dispatch = useDispatch();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [confirmationCallback, setConfirmationCallback] = useState(null);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [isValid, setIsValid] = useState(true);
	const [changeUser, setChangeUser] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	// const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({
        firstName: "",
		lastName: "",
		email: "",
        phone: "",
        userType: ""
	});
	const [userNewType, setUserNewType] = useState(2);

	useEffect(() => {
		let userType = cookies.get('userType', { path: '/' });
		const userTypeAsNumber = parseInt(userType, 10);
		setCurrentUser(userTypeAsNumber)
	  }, []);


	const handleCloseDialog = () => {
		setSelectedPrice(null);
		setIsDialogOpen(false);
	};

	const openDeleteModal = (id) => {
		  
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

	 const handleFieldTypeChange = (field, value) => {
		if(field === "userType" && value === 1){
			setChangeUser(true)
			setUserNewType(1)
		}else{
			setUserNewType(2)
			setChangeUser(false)
		}
	 }

	 const handleFieldChange = (field, value) => {
		if(field === "phone"){
			if (value.length === 10) {
				setPhoneNumber(value);
				setIsValid(true);
				setFormData((prevData) => ({
					...prevData,
					[field]: value,
				}));
			} else {
				setIsValid(false);
				setPhoneNumber('');
				setFormData((prevData) => ({
					...prevData,
					[field]: value,
				}));
			}
		}
		// else if(field === "userType" && value === '1'){
		// 	setChangeUser(true)
		// 	setFormData((prevData) => ({
		// 		...prevData,
		// 		[field]: value,
		// 	}));
		// }
		else{
			// setChangeUser(false)
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}));
		}
		
	};

	 const productDelete = async (id) => {
		try {
				const response = await Request.delete(`admin/adminUser/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchUserList())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
		}
		
	};

	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		// setIsDialogOpen(true);
			try {
				const response = await Request.get(`admin/adminUser/${id}`, [], true);
				if (Object.keys(response).length > 0) {
					  
					// if (response) {
					
						setIsDialogOpen(true);
						setFormData({
							firstName: response.firstName,
							lastName: response.lastName,
							email: response.email,
                            phone: response.phone,
                            userType: response.user_type
						})
					// }
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(changeUser && userNewType === 1){
			if (formData.phone.length === 10 && /^[0-9]+$/.test(formData.phone)) {
				
				setShowConfirmation(true);
		
			}else{
				setIsValid(false);
			}
		} else {
			submitFormData();
		}
	};


	

	const submitFormData = async () => {
		try {

			let data={
				firstName: formData.firstName,
				lastName: formData.lastName,
				email: formData.email,
				phone: formData.phone,
				userType: userNewType
			}
			// Fetch data from API
			const response = await Request.put(`admin/adminUser/${selectedProductEdit}`, data, true);
			if (response.status === 200) {
				handleCloseDialog();
				dispatch(fetchUserList())
				setFormData({
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
				});
			} else if(response.status === 404){
				setErrorMessage("Please Enter valid Email or Phone.")
			}else if(response.status === 400){
				setErrorMessage("Email already registered.")
			}else{
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	  };

	  const handleCloseAlertDialog = () => {
		setShowConfirmation(false);
		setConfirmationCallback(null);
	  };
	
	  const handleConfirm = () => {
		submitFormData();
		cookies.remove("accessToken", {path: "/"});
		cookies.remove("refreshToken", {path: "/"});
		cookies.remove("userType", {path: "/"});
		handleCloseAlertDialog();
		
	  };
	
	  const handleCancel = () => {
		handleCloseAlertDialog();
	  }

	return (
		<Box className="order-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Phone</TableCell>
							<TableCell>Role</TableCell>
                            <TableCell>Account Created On</TableCell>
                            <TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(list && list.length>0) && list.map((item) => (
							<TableRow key={item.id}>
								<TableCell>{item.fullName}</TableCell>
								<TableCell>{item.email}</TableCell>
								<TableCell>{item.phone ? item.phone : "—"}</TableCell>
                                <TableCell>{item.user_type === 1 ? "Super Admin" : "Admin"}</TableCell>
                                <TableCell>{formatDate(item.createdAt)}</TableCell>
								<TableCell>
									<IconButton
											aria-label="Edit"
											onClick={() => openEditModal(item.id)} 
										>
											<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
										{item.user_type === 2 && (
										<IconButton
											aria-label="Delete"
											onClick={() => openDeleteModal(item.id)}
										>
											<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
										)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* delete dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this User?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			{/* edit dialog */}
			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Edit User
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
                                <Form.Group
                                        controlId="firstName"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            value={formData.firstName}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "firstName",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>

                                 <Form.Group
                                        controlId="lastName"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            value={formData.lastName}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "lastName",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="email"
                                        className="col-md-6 add-category-form__field userField disableControl"
                                    >
                                        <Form.Label>Email</Form.Label>
										{formData.userType !== 1 ? (
											<>
												{currentUser === 2 ? (
													<Form.Control
														type="email"
														placeholder="email"
														value={formData.email}
														disabled
														readOnly
														required
													/>):(
														<Form.Control
														type="email"
														placeholder="email"
														value={formData.email}
														onChange={(e) =>
															handleFieldChange(
																"email",
																e.target.value
															)
														}
														required
													/>
												)}
											</>
										):(
											<Form.Control
												type="email"
												placeholder="email"
												value={formData.email}
												disabled
												readOnly
												required
											/>
										)}
                                 </Form.Group>
                                 <Form.Group
                                        controlId="phone"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            placeholder="Phone"
											maxLength="10"
                                            value={formData.phone}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "phone",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
										
                                 </Form.Group>
                                 <Form.Group
									controlId="userType"
									className="shipping-price-form__field disableControl"
								>
									<Form.Label>User Type</Form.Label>
									{formData.userType !== 1 ? (
										<>
										{currentUser === 2 ? (
											
											<Form.Control
												type="text"
												value="Admin"
												readOnly
												disabled
												required
											/>):(
											<div className="user_type_radio_box">
												<Form.Check
												type="radio"
												label="Super Admin"
												id="superAdminRadio"
												checked={userNewType === 1}
												onChange={() => handleFieldTypeChange('userType', 1)}
												inline
												/>
												<Form.Check
												type="radio"
												label="Admin"
												id="adminRadio"
												checked={userNewType === 2}
												onChange={() => handleFieldTypeChange('userType', 2)}
												inline
												/>
											{/* Add other radio buttons as needed */}
											</div>
										)}
										</>
									):(
										<Form.Control
										type="text"
										value="Super Admin"
										readOnly
										disabled
										required
									/>
									)}
									
								</Form.Group>
								
							</div>
							{!isValid && <Typography className="errorMsg pt-4">Please enter a valid 10-digit phone number</Typography>}
                            <Typography className="color4 font-15 font-600 pt-3">{errorMessage}</Typography>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Save
						</Button>
					</DialogActions>
				</form>
			</Dialog>

		{/* Confirmation Modal */}
		<Dialog open={showConfirmation}  onClose={handleCancel}>
		<DialogTitle>
          Confirmation
        </DialogTitle>
		<DialogContent>
			You are switching to the Super Admin role. Are you certain you wish to proceed with this modification? Please note that you may be logged out after making this change.
       </DialogContent>
	   <DialogActions>
      
          <Button color="primary" onClick={handleConfirm}>
            Yes
          </Button>
		  <Button color="primary" onClick={handleCancel}>
            No
          </Button>
		  </DialogActions>
      </Dialog>

		</Box>
	);
};

export default UsersListTable;
