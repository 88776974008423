import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, Dialog,
	DialogTitle,
	DialogContent,} from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Col, Row, Form } from 'react-bootstrap';
import Request from '../customUtils/Http/Request';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageToggle, setSuccessMessageToggle] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage2, setSuccessMessage2] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [fullName2, setFullName2] = useState('');
    const [emailAddress2, setEmailAddress2] = useState('');
    const [phone2, setPhone2] = useState('');
    const [message2, setMessage2] = useState('');
    const [phoneError1, setPhoneError1] = useState(false);
    const [phoneError2, setPhoneError2] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [recaptchaValue2, setRecaptchaValue2] = useState('');
    const [recaptchaValue3, setRecaptchaValue3] = useState('');
    const [data3, setData3] = useState({
        "fullName" : '',
        "email" : '',
        "phone" : '',
        "message": '',
    });
    const [showNotification2, setShowNotification2] = useState(false);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const recaptchaRef1= useRef();
    const recaptchaRef2 = useRef();
    const recaptchaRef3 = useRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [recaptchaSize, setRecaptchaSize] = useState(windowWidth > 500 ? 'normal' : 'compact');

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          setRecaptchaSize(window.innerWidth > 500 ? 'normal' : 'compact');
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const handleNameChange = event => {
        setFullName(event.target.value);
      };
    
      const handleEmailChange = event => {
        setEmailAddress(event.target.value);
      };
  
      const handleMessageChange = event => {
        setMessage(event.target.value);
      };

      const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

    
    const handleOpenDialog2 = () => {
		setOpen2(true);
	};

	const handleCloseDialog2 = () => {
		setOpen2(false);
	};

    const handleNameChange2 = event => {
        setFullName2(event.target.value);
      };
    
      const handleEmailChange2 = event => {
        setEmailAddress2(event.target.value);
      };
    
      const handlePhoneChange2 = event => {
        let value = event.target.value;
        value = value.replace(/[^0-9]/g, '');
        if(value.length === 10){
            setPhoneError1(false)
        }
        else{
            setPhoneError1(true)
        }
        setPhone2(value);
      };
  
      const handleMessageChange2 = event => {
        setMessage2(event.target.value);
      };

      const handleChange3 = (e) => {
        let { name, value } = e.target;

        if(name === "phone"){
            value = value.replace(/[^0-9]/g, '');
            if(value.length === 10){
                setPhoneError2(false)
            }
            else{
                setPhoneError2(true)
            }
        }
        setData3({ ...data3, [name]: value });
      };

      const handleRecaptchaChange3 = (value) => {
        setRecaptchaValue3(value);
      }

      const handleRecaptchaChange2 = (value) => {
        setRecaptchaValue2(value);
      }
    
      
      const handleSubmit = async (e) => {
        e.preventDefault()
        // Send updated user data to API
        const updatedUserData = {
          name: fullName,
          email: emailAddress,
          message: message,
          captchaValue: recaptchaValue
        };
        try {
          let response = await Request.post("utils/sendSupportNotificationBulk", updatedUserData);
          if (response.message === "Success") {
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
              }, 5000); 
            setEmailAddress('');
            setFullName('');
            setMessage('');
            setRecaptchaValue('')
            if (recaptchaRef1.current) {
                recaptchaRef1.current.reset();
            }
             ;
              //   setIslanderDetail(response);
          } else {
            if (recaptchaRef1.current) {
                recaptchaRef1.current.reset();
            }
           alert("something went wrong.")
          }
      } catch (error) {
        if (recaptchaRef1.current) {
            recaptchaRef1.current.reset();
        }
          console.error('Error fetching data from API:', error);
      }
        
      };

      const handleSubmit2 = async (e) => {
          
        e.preventDefault()

        if( phone2.length !== 10){
            alert("Please enter 10 digit contact number.")
        }else{
            const updatedUserData = {
                "fullName": fullName2,
                "email": emailAddress2,
                "phone": phone2,
                "message": message2,
                "captchaValue": recaptchaValue2
              };
              try {
                let response = await Request.post("utils/sendSupportNotification2", updatedUserData);
                if (response.message === "Success") {
                   
                  setSuccessMessage2("message");
                  setErrorMessage2('')
                  setTimeout(() => {
                      setSuccessMessage2('');
                      setErrorMessage2('')
                  }, 3000); 
                  setOpen(false)
                  setFullName2('');
                  setEmailAddress2('');
                  setPhone2('');
                  setMessage2('');
                  setRecaptchaValue2('')
                  if (recaptchaRef3.current) {
                    recaptchaRef3.current.reset();
                }
                } else {
                  setErrorMessage2("message");
                  setSuccessMessage2('');
                  setTimeout(() => {
                      setErrorMessage2('');
                      setSuccessMessage2('');
                  }, 3000); 
                  setOpen(true)
                  if (recaptchaRef3.current) {
                    recaptchaRef3.current.reset();
                }
                }
            } catch (error) {
                if (recaptchaRef3.current) {
                    recaptchaRef3.current.reset();
                }
                console.error('Error fetching data from API:', error);
            }
        }
        // Send updated user data to API
       
        
      };

      const handleSubmit3 = async (e) => {
        e.preventDefault()
        if( data3.phone.length !== 10){
            alert("Please enter 10 digit contact number.")
        }else{
            try {
            let postData = {
                "fullName" : data3.fullName,
                "email" : data3.email,
                "phone" : data3.phone,
                "message": data3.message,
                "captchaValue": recaptchaValue3
            }
            let response = await Request.post("utils/sendBulkOrderSupportNotification", postData);
             
            if (response.message === "Success") {
                  
                setSuccessMessage("message");
                setErrorMessage('')
                setTimeout(() => {
                    setSuccessMessage('');
                    setErrorMessage('')
                }, 3000); 
                setOpen2(false)
                setData3({
                    "fullName" : '',
                    "email" : '',
                    "phone" : '',
                    "message": ''
                })
                setRecaptchaValue3('')
                if (recaptchaRef2.current) {
                    recaptchaRef2.current.reset();
                }
            }else{
                setErrorMessage("message");
                setSuccessMessage('');
                setTimeout(() => {
                    setErrorMessage('');
                    setSuccessMessage('');
                }, 3000); 
                setOpen2(true)
                if (recaptchaRef2.current) {
                    recaptchaRef2.current.reset();
                }
            }
            } catch (error) {
                if (recaptchaRef2.current) {
                    recaptchaRef2.current.reset();
                }
                console.error('Error fetching data from API:', error);
            }
        }
        
      };

      const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
      };

    //   useEffect(() => {
    //     (async () => {
    //       let response = await Request.get("user/", []);
    //       {
    //         if (response) {
    //           setFullName2(response.firstName + ' ' + response.lastName)
    //           setEmailAddress2(response.email)
    //           setPhone2(response.phone)
    //         }
    //         else {
    //           console.log("error");
    //           return
    //         }
    //       }
    //     })();
    //   }, []);

    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className='middle_block middle_coming_block contact_box' textAlign="center">
                    <Row>
                        <Col lg={5} md={6} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45'>Contact Us</Typography>
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12} className='d-flex align-items-center justify-content-center'>
                            <img src='/assets/img/contact_img.svg' alt='contact_img' className='contact_img' />
                        </Col>
                    </Row>
                    <Box sx={{pt: 5}}>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={12} xs={12} className='' align="left">
                                <a href='mailto:support@isleoffreight.com'>
                                    <Grid item lg={11.6} md={11.6} sm={12} xs={12} className='cartContactLeft mb-4'>
                                        <Box>
                                            <Typography className='color4 font-26 font-bold'>Connect with us via email</Typography>
                                            <Typography className='color1 font-15 pt-2'>We are delighted to assist you! Please note that one of our representative will get back in touch with you in 24–48 hours. Please read our  <Link href='/help-center/question?category=FAQs'><span className='faq-highlight'>FAQ</span></Link>  before submitting a message.</Typography>
                                        </Box>
                                    </Grid>
                                </a>
                                <Grid item lg={11.6} md={11.6} sm={12} xs={12} className='cartContactLeft'>
                                    <Box>
                                    <form onSubmit={handleSubmit}>
                                        <Form.Group className='mb-3'>
                                            <Form.Control type="text" className="font-14 color1 pt-2 pb-2 pl-15 pr-15" value={fullName} onChange={handleNameChange} name="fullName" placeholder='Enter Name' required></Form.Control>
                                        </Form.Group>
                                        <Form.Group className='mb-3'>
                                            <Form.Control type="text" className="font-14 color1 pt-2 pb-2 pl-15 pr-15" value={emailAddress} onChange={handleEmailChange} name="email" placeholder='Enter Email Id' required></Form.Control>
                                        </Form.Group>
                                        <Form.Group className='mb-3' controlId="exampleForm.ControlTextarea1">
                                            <Form.Control as="textarea" className="font-14 color1 pt-2 pb-2 pl-15 pr-15" style={{resize: "none"}} value={message} placeholder='Enter Message' rows={3}  onChange={handleMessageChange} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <div style={{ display: 'flex', gap: "30px", alignItems: "center"}}>
                                            {windowWidth > 500 ? (
                                                 <ReCAPTCHA
                                                 ref={recaptchaRef1}
                                                 sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                                 onChange={handleRecaptchaChange}
                                                 className="recaptcha_block"
                                                 size="normal"
                                             />
                                            ):(
                                                <ReCAPTCHA
                                                    ref={recaptchaRef1}
                                                    sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                                    onChange={handleRecaptchaChange}
                                                    className="recaptcha_block"
                                                    size="compact"
                                                />
                                            )}
                                            <Button type="submit" className='b1 b3 font-bold' disabled={!recaptchaValue}>Send Message</Button>
                                            </div>
                                        
                                        </Form.Group>
                                       
                                        
                                        
                                        {showNotification === true && (
                                            <Box className="notification notification font-15 color2 font-bold" sx={{pt:1}}>
                                                 Thank you for submitting the message, and we will get back to you soon.
                                            </Box>
                                         )}
                                            {/* <input type="text">{fullName}</input> */}
                                        </form>
                                    </Box>
                                </Grid>
                                <Button className='b1 b4 font-20 font-600 mt-4 contact-btn' onClick={handleOpenDialog2}>For bulk orders click here</Button>
                             
                                {successMessage !== '' && <Typography className="notification notification font-15 color2 font-bold">Thank you for submitting the message, and we will get back to you soon</Typography>}
                                {errorMessage !== '' && <Typography className="notification notification font-15 color2 font-bold" style={{color: "red"}}>Request Failed, Please Try Again.</Typography>}

                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} className='' align="left">
                                <a href='/help-center'>
                                    <Grid item lg={11.6} md={11.6} sm={12} xs={12} className='cartContactLeft mb-4'>
                                        <Box>
                                            <Typography className='color2 font-30 font-bold'>Help Center</Typography>
                                            <Typography className='color1 font-15 pt-2'>Visit our help center page to learn more about our services, features, guides, and other helpful resources.</Typography>
                                        </Box>
                                    </Grid>
                                </a>
                           
                                <Grid item lg={11.6} md={11.6} sm={12} xs={12} className='cartContactLeft'>
                                    <Box>
                                        <Box className="flexBox" sx={{paddingBottom: "44px"}}>
                                            <img src='/assets/img/contact_email.svg' alt='contact_email' className='' />
                                            <a href='mailto:support@isleoffreight.com'>
                                                <Typography className='color2 font-20 font-600 email-text-contact'>support@isleoffreight.com</Typography>
                                            </a>
                                        </Box>
                                        <Box className="flexBox" sx={{paddingBottom: "44px"}}>
                                            <img src='/assets/img/contact_call.svg' alt='contact_call' className='' />
                                            <a href="tel:+1825-901-3955">
                                                <Typography className='color2 font-20 font-600'>+1 (825) 901-3955</Typography>
                                            </a>
                                        </Box>
                                        <Box className="flexBox" sx={{paddingBottom: "46px"}}>
                                            <img src='/assets/img/contact_whatsapp.svg' alt='contact_whatsapp' className='' />
                                            <a href="https://wa.me/+18259013955">
                                                <Typography className='color2 font-20 font-600'>WhatsApp</Typography>
                                            </a>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className='color4 font-20 font-600 pt-2'>You can also connect with us via WhatsApp.</Typography>
                                    </Box>
                                </Grid>
                                <Button className='b1 b3 font-20 font-600 mt-4 contact-btn' onClick={handleOpenDialog}>Where would you like to see us next ?</Button>
                                
                                {successMessage2 !== '' && <Typography className="notification notification font-15 color2 font-bold">Thank you for submitting the message, and we will get back to you soon</Typography>}
                                {errorMessage2 !== '' && <Typography className="notification notification font-15 color2 font-bold" style={{color: "red"}}>Request Failed, Please Try Again.</Typography>}
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
                <Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal"
				>
					{/* <form onSubmit={handleSubmit}> */}
					<DialogTitle className="admin-modal__title-container">
                        <Typography className='font-22 font-bold color2'>Where would you like to see us next?</Typography>
					</DialogTitle>
					<DialogContent sx={{ padding: "20px" }}>
                    <Box align="left" className="supportFormBox">
                        <form onSubmit={handleSubmit2}>
                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" name="fullName" value={fullName2} onChange={handleNameChange2} required></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" name="email" value={emailAddress2} onChange={handleEmailChange2} required></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="tel" name="phone" value={phone2} onChange={handlePhoneChange2} required></Form.Control>
                                {phoneError1 && <Typography className="errorMsg" sx={{pt : 3, mb: 0, pb: "0px !important", mt: 0}}>Please enter 10 digit contact number</Typography>}
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={7}  required onChange={handleMessageChange2}/>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                            {windowWidth > 500 ? (
                                <ReCAPTCHA
                                ref={recaptchaRef3}
                                    sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                    onChange={handleRecaptchaChange2}
                                    className="recaptcha"
                                    size="normal"
                                />
                            ):(
                                <ReCAPTCHA
                                ref={recaptchaRef3}
                                    sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                    onChange={handleRecaptchaChange2}
                                    className="recaptcha"
                                    size="compact"
                                />
                            )}
                            </Form.Group>
                            <Button sx={{mt: 1}} type="submit" className='b1' disabled={!recaptchaValue2}>Submit</Button>
                                {/* <input type="text">{fullName}</input> */}
                        </form>
                    </Box>                  
					</DialogContent>
				</Dialog>

                <Dialog
					open={open2}
					onClose={handleCloseDialog2}
					className="admin-modal"
				>
					{/* <form onSubmit={handleSubmit}> */}
					<DialogTitle className="admin-modal__title-container">
                        <Typography className='font-22 font-bold color2'>
                            Bulk Order Inquiry</Typography>
					</DialogTitle>
					<DialogContent sx={{ padding: "20px" }}>
                    <Box align="left" className="supportFormBox">
                        <form onSubmit={handleSubmit3}>
                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" name="fullName" value={data3.fullName} onChange={handleChange3} required></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" name="email" value={data3.email} onChange={handleChange3} required></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="tel" name="phone" value={data3.phone} onChange={handleChange3} required></Form.Control>
                                {phoneError2 && <Typography className="errorMsg" sx={{pt : 3, mb: 0, pb: "0px !important", mt: 0}}>Please enter 10 digit contact number</Typography>}
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" name="message" rows={7}  value={data3.message} required onChange={handleChange3}/>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                            {windowWidth > 500 ? (
                                <ReCAPTCHA
                                ref={recaptchaRef2}
                                    sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                    onChange={handleRecaptchaChange3}
                                    className="recaptcha"
                                    size="normal"
                                />
                            ):(
                                <ReCAPTCHA
                                ref={recaptchaRef2}
                                    sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                    onChange={handleRecaptchaChange3}
                                    className="recaptcha"
                                    size="compact"
                                />
                            )}
                            </Form.Group>
                            <Button sx={{mt: 1}} type="submit" disabled={!recaptchaValue3} className='b1'>Submit</Button>
                        
                        </form>
                       
                    </Box>                  
					</DialogContent>
				</Dialog>
            </Container>
            <Footer />
        </>
    );
}

export default ContactUs;
