import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Col, Row, Form, } from 'react-bootstrap';
import Request from '../customUtils/Http/Request';
import Cookies from 'universal-cookie';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import { Login } from '@mui/icons-material';


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px",
        flexWrap: "wrap",
        marginTop: "20px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: "25px",
        paddingTop: "30px"
    },
    orderInnerGrid: {
        padding: "30px",
        border: "1px solid lightgray",
        borderRadius: "10px",
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}));

const SendOrderDetail = () => {
    const classes = useStyles();
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(null);
    const [invoiceNo,setInvoiceNo] = useState(null)
    // const { orderId } = useParams();
    // const [orderState, setOrderState] = useState({
    //   orderId: orderId
    // });
    const [orderId, setOrderId] = useState(null);
    const [orderInfo, setOrderInfo] = useState({});
    const [arrivalTime, setArrivalTime] = useState('');
    const [estArrivalTime, setEstArrivalTime] = useState('');

    let login = checkLogin()

    useEffect(() => {
        (async () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const urlParams = Object.fromEntries(urlSearchParams.entries());

            const idFromUrl = urlParams.orderId;

            setOrderId(idFromUrl);

            if (login === true) {
                await fetchData();
            }
            else {
                cookies.remove('cartItems', {path: "/"});
                cookies.remove('totalCartPrice', {path: "/"});
            }
        })();
    }, []);

    const fetchData = async () => {
        try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const urlParams = Object.fromEntries(urlSearchParams.entries());

            const idFromUrl = urlParams.orderId;

            let response = await Request.get(`order/order_detail/${idFromUrl}`, [], true);
            if (response) {
                setOrderInfo(response)
                setInvoiceNo(response.invoiceNo)
                setEstArrivalTime(response.estDeliveryTimeInDay)
                // const givenDate = new Date(response.estDeliveryDate);
                // const numberOfDays = response.estDeliveryTime;
                // const totalArrivalTime = new Date(givenDate + (numberOfDays * 24 * 60 * 60 * 1000));
                // // var arrivalTime = response.createdAt;
                // setArrivalTime(totalArrivalTime.toISOString())
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    return (

        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Order Confirmation</Typography>
                <Box className={classes.headerFlexBox}>
                    <Typography className={classes.pathName}>Barrel</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={classes.pathName}>Checkout</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={classes.pathName}>Payment</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Order Confirmation</Typography>
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Grid container className='paymentBox'>
                    <Grid item xs={12} className='cartColLeft paymentCol detailBox'>
                        <Box align="center">
                            <Typography className='font-22 font-bold color2' sx={{ pb: 3 }}>Your Order Has Been Received</Typography>
                            <Box sx={{ pb: 3 }}>
                                <img src="/assets/img/orderIcon.png" alt='order_icon' className='order_img'></img>
                            </Box>
                            <Typography className='font-22 color2' sx={{ pb: 2 }}>Thanks For Your Order!!</Typography>
                            <Box className='font-18 color1'>Your Order <span className='font-18 font-bold'>{invoiceNo}</span> is in the process</Box>

                            {Object.keys(orderInfo).length !== 0 & estArrivalTime !== '' &&
                                <Box className={classes.orderGrid}>
                                    <Box>
                                        <Box className={classes.orderInnerGrid} align="center">
                                            <Box>
                                                <Box sx={{ pb: 2 }}>
                                                    <img src="/assets/img/order_detail.png" alt='order_detail' className='order_img'></img>
                                                </Box>
                                                <Typography className='color2 font-18 font-bold'>Estimated Pick Up</Typography>
                                                <Typography className='color3 font-16'>{orderInfo.slot.date} {orderInfo.slot.start_time}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box className={classes.orderInnerGrid} align="center">
                                            <Box>
                                                <Box sx={{ pb: 2 }}>
                                                    <img src="/assets/img/order_detail.png" alt='order_detail' className='order_img'></img>
                                                </Box>
                                                <Typography className='color2 font-18 font-bold'>Shipping To</Typography>
                                                <Typography className='color3 font-16'>{orderInfo.shippingAddress.address_line_1}</Typography>
                                                <Typography className='color3 font-16'>{orderInfo.shippingAddress.address_line_2}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box className={classes.orderInnerGrid} align="center">
                                            <Box>
                                                <Box sx={{ pb: 2 }}>
                                                    <img src="/assets/img/order_detail.png" alt='order_detail' className='order_img'></img>
                                                </Box>
                                                <Typography className='color2 font-18 font-bold'>Estimated Arrival</Typography>
                                                <Typography className='color3 font-16'>{estArrivalTime}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            }

                            <Box sx={{ pt: 5, display: "flex", gap: "20px", justifyContent: "center" }}>
                                <Link to='/'>
                                    <Button className='b1 b3'>Continue Shopping</Button>
                                </Link>
                                <Link to='/user/customer/order-history'>
                                    <Button className='b1'>Order History</Button>
                                </Link>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
};

export default SendOrderDetail;