import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Paper, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import { Col, Row } from 'react-bootstrap';
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchHelpCategories, fetchPopularQuestion } from '../../redux/helpCenterSlice';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: "#fff",
        borderRadius: "25px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "50px 15px",
        textAlign: "center",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },
    categoryLogo:{
        maxWidth: "100px"
    }
}));

const HelpCenter = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const helpCategoryList = useSelector((state) => state.helpCenter.helpCategoryList);
    const popularQuestions = useSelector((state) => state.helpCenter.popularQuestionList);

    useEffect(() => {
		dispatch(fetchHelpCategories());
        dispatch(fetchPopularQuestion());
	}, []);

	useEffect(() => {
		dispatch(fetchHelpCategories());
        dispatch(fetchPopularQuestion());
	}, [dispatch]);

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>IOF Help Center</Typography>
                        </Col>
                    </Row>
                    <Box sx={{pt: 10, pb: 7,'@media (max-width: 767px)': {pt: 4, pb: 3},}}>
                        <Box>
                            <Typography textAlign="left" variant='h4' className='font-600 color4'>Most Popular Articles</Typography>
                            <Box sx={{pt: 5, pb: 5,'@media (max-width: 767px)': {pt: 3, pb: 3},}} className="dottedBorder">
                                <Grid container spacing={2} alignItems="stretch">
                                    {(popularQuestions && popularQuestions.length>0) && popularQuestions.map((question) => (
                                        <Grid item xs={12} sm={12} md={5.9} lg={5.9} key={question.id}>
                                            {/* <Paper elevation={3}> */}
                                                <Grid container alignItems="stretch">
                                                    <Grid item xs={1}>
                                                        <DescriptionOutlinedIcon className='color4'/>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                    <Link to={`/help-center/answer?category=${question.category}&id=${question.id}`}>
                                                        <Typography textAlign="left"className='font-19 color1 font-600'>{question.question}</Typography>
                                                    </Link>
                                                    </Grid>
                                                </Grid>
                                            {/* </Paper> */}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Box sx={{pt: 5, pb: 5,'@media (max-width: 767px)': {pt: 3, pb: 3},}}>
                                <Grid container spacing={3}>
                                {(helpCategoryList && helpCategoryList.length>0) && helpCategoryList.map((category) => (
                                    <Grid item xs={12} sm={12} md={6} lg={4} key={category.id}>
                                        <Link to={`/help-center/question?category=${category.category}`} >
                                            <Paper sx={{ borderRadius: "25px" }}>
                                                <Box textAlign="center" className={classes.grid}>
                                                    <img src='/assets/img/web_logo.svg' className={classes.categoryLogo}></img>
                                                    <Typography className='font-20 color1 font-600 mt-3'>{category.category}</Typography>
                                                    <Typography className='font-14 font-600 color4 mt-3 mb-0'>{category.questionCount} articles</Typography>
                                                </Box>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                ))}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default HelpCenter;