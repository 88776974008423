import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function FormatPrice(price) {
  const numericPrice = parseFloat(price);

  if (!isNaN(numericPrice)) {
    return numericPrice.toFixed(2);
  } else {
    return "N/A";
  }
}