import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form } from 'react-bootstrap';
import { fetchWeeklyDeals } from '../redux/islanderListSlice';
import PDFViewer from './pdfData';

const WeeklyDeals = () => {
    const [pdfData, setPdfData] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchReview()
      }, []);
  
    const fetchReview = async () => {
        try {
            const response = await Request.get(`utils/weeklyDeals`, []);
            if (response && Array.isArray(response)) {
                let pdf = [];
                let requests = response.map((item, i) => {
                    return fetch(`${process.env.REACT_APP_DatabaseServer}utils/getFileObj?file=${item.weeklyDeals}`, {
                        method: 'GET',
                        redirect: 'follow'
                    })
                    .then(res => res.blob())
                    .then((responseData) => {
                        const file = URL.createObjectURL(responseData);
                        pdf.push(file);
                    });
                });
    
                Promise.all(requests).then(() =>{
                     setPdfData(pdf)
                     setIsLoading(false);
                });
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
    

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Weekly Deals</Typography>
                        </Col>
                    </Row>
                    <Box sx={{pt: 5, pb: 7,'@media (max-width: 600px)': {pt: 3, pb: 3},}}>
                    {isLoading ? (
                        <div className="loader2-container4">
                            <div className="loader2"></div>
                        </div>
                    ) :
                     pdfData.length>0 ? (
                        <>
                        {pdfData.map((pdfData, index) => (
                            <div key={index}>
                                <PDFViewer pdfData={pdfData} />
                                <hr />
                            </div>
                         ))}
                         </>
                    ):(
                        <Box textAlign="left" sx={{mt: 3, minHeight: "176px"}}>
                            <Typography className='font20 font-600 color4'>
                                No Weekly Deals Available
                            </Typography>
                        </Box>
                    )}               
                    </Box>
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default WeeklyDeals;