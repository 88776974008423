import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
    TextField
} from "@mui/material";
// import Form from "react-bootstrap/Form";
import { Form, Row, Col } from "react-bootstrap";
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
// import DateFnsUtils from '@date-io/date-fns';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Cookies from 'universal-cookie';
// import Datepicker from 'react-datepicker';
import 'react-calendar/dist/Calendar.css'; 
// import { format, parse } from 'date-fns';
import Calendar from 'react-calendar';
import { isSameDay,format } from 'date-fns';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DateTime, Settings } from 'luxon';

const cookies = new Cookies();
const customStyles = `
  .react-calendar__tile--active {
    background-color: transparent; /* Remove the background color */
    color: inherit; /* Use the default text color */
  }
  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    background: transparent;
    color: var(--primary-color1);
  } 
  .react-calendar__month-view__days__day--weekend{
    color: var(--primary-color1);
  }
  .css-1ed1o9w-MuiList-root-MuiMultiSectionDigitalClockSection-root{
    min-width: 75px !important;
  }
  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item{
    width: 83% !important;
  }
  .react-calendar__tile:disabled{
    color: lightgray;
  }
`;

function TimeSlot() {
    const [open, setOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const dispatch = useDispatch();
    const [selectedDates, setSelectedDates] = useState([]);
    const [defaultSelectedDates, setDefaultSelectedDates] = useState([]);
    const [loadSlot, setLoadSlot] = useState(true);
    const [slotErrorMessage, setSlotErrorMessage] = useState('');
    const [slotErrorMessage2, setSlotErrorMessage2] = useState('');
    const [slotErrorMessage3, setSlotErrorMessage3] = useState('');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedSlot, setSelectedSlot] = useState(null);
    const [title, setTitle] = useState(0);
    const [checkedBoxes, setCheckedBoxes] = useState([]);
    const [timeData, setTimeData] = useState({
        startTime: dayjs('2022-04-17T10:00'),
        endTime: dayjs('2022-04-17T12:00')
    });
    const [availableSlotByDate, setAvailableSlotByDate] = useState([]);
    const today = new Date();
    today.setHours(0, 0, 0, 0);


    function formatTime(time) {
        const formattedTime = dayjs(time).format('HH:mm');
        return formattedTime;
    }

    function isDateBeforeToday(date) {
        return date < today;
        // return isBefore(date, new Date());
    }

    useEffect(() => {
        viewSlot();
    }, [selectedDates]);

    const handleDateClick = (date) => {
        const dateIndex = selectedDates.findIndex((selectedDate) =>
            isSameDay(selectedDate, date)
        );
        if (dateIndex !== -1) {
            const updatedDates = [...selectedDates];
            updatedDates.splice(dateIndex, 1);
            setSelectedDates(updatedDates);
        } else {
            setSelectedDates([...selectedDates, date]);
        }
    };
    
    const isDateSelected2 = (date) => {
        return defaultSelectedDates.some((selectedDate) => isSameDay(selectedDate, date));
    };
    
      useEffect(() => {
        fetchDates()
      }, [availableSlotByDate]);

        useEffect(() => {
            fetchNextAvailableSlots()
        }, []);

        const fetchDates = async () => {
            try {
                const response = await Request.get("pickup/slot/dates", [], true);
                if (response.dates.length !== 0 && response.dates !== undefined) {
                    const clickableDates = response.dates.map((dateStr) =>
                        DateTime.fromISO(dateStr).setZone('America/Toronto').toJSDate()
                    );
                    setDefaultSelectedDates(clickableDates);
                } else {
                    console.log(response.message)
                }
            } catch (error) {
                console.log('Error:', error);
            }
        }

    const fetchNextAvailableSlots = async () =>{
        try {
            const response = await Request.get(`pickup/slots/next/AvailableSlot`, [] , true);
            if (response) {
                if(Array.isArray(response)){
                    setAvailableSlotByDate(response)
                    setTitle(1)
                    setLoadSlot(false)
                }
                else{
                    setLoadSlot(true)
                    setSlotErrorMessage2(response.message)
            }
            }else {
                alert(response.error)
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const handleStartTimeChange = async (newStartTime) => {
        setTimeData({
			...timeData,
			startTime: newStartTime,
		});
    }

    const handleEndTimeChange = async (newEndTime) => {
        setTimeData({
			...timeData,
			endTime: newEndTime,
		});
    }

    const openDeleteModal = (id) => {
        if(id === "allDelete"){
            const allCheckboxIds = availableSlotByDate.map((data) => data.id);
            setCheckedBoxes(allCheckboxIds)
            setSelectedSlot("allDelete");
        }
        if(id === "bulkSelect"){
            setSelectedSlot("bulkSelect");
        }
        else{
            setSelectedSlot(id);
        }
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedSlot(null);
		setIsDeleteDialogOpen(false);
	};

    const addSlot = async (event) => {
		event.preventDefault();
        const sTime = formatTime(timeData.startTime);
        const eTime = formatTime(timeData.endTime);

        if(selectedDates.length>0){
            var formattedSelectedDates = selectedDates.map((date) => format(date, 'yyyy-MM-dd'));
            let formData = {
                "dates": formattedSelectedDates,
                "startTime": sTime,
                "endTime": eTime
            }
            try {
                const response = await Request.post(`pickup/slot`, formData , true);
                if (response.message === "Success") {
                    setTitle(2)
                    setAvailableSlotByDate(response.slots)
                    setLoadSlot(false)
                    setTimeData({
                        startTime: dayjs('2022-04-17T10:00'),
                        endTime: dayjs('2022-04-17T12:00')
                    })
                    setSelectedDates([])
                } else {
                    // setTitle(1)
                    setLoadSlot(true)
                    setSlotErrorMessage(response.message)
                    setTimeout(() => {
                        setSlotErrorMessage('')
                    }, 3000);
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }else{
            alert("Please Select Date first")
        }

	};

    const viewSlot = async (event) => {
        if(selectedDates.length>0){
            var formattedSelectedDates = selectedDates.map((date) => format(date, 'yyyy-MM-dd'));
            let formData = {
                "dates": formattedSelectedDates,
            }
            try {
                const response = await Request.post(`pickup/slots/getSlotByDates`, formData , true);
                if (response) {
                    if(Array.isArray(response)){
                        setTitle(3)
                        setAvailableSlotByDate(response)
                        setLoadSlot(false)
                    }
                    else{
                        // setTitle(1)
                        setLoadSlot(true)
                        setSlotErrorMessage3(response.message)
                    }
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }else{
            // alert("Please Select Date first")
        }

	};

    const deleteSlot = async (id) => {
        var slotArray = [];
        var data;
        if(id === "bulkSelect" || id === "allDelete"){
            data = {
                "id": checkedBoxes
            }
        }else{
            slotArray.push(id)
            data = {
                "id": slotArray
            }
        }
       
		try {
				const response = await Request.delete(`pickup/slot/delete`, data , true);
				if (response) {
					if (response.status === 200) {
						setIsDeleteDialogOpen(false)
                        setSelectedSlot(null)
                        setCheckedBoxes([])
                        // setSelectedDates([])
						// viewSlot()
                        if(title === 2){
                            viewSlot()
                        }else if(title === 3){
                            viewSlot()
                        }else if(title === 1){
                            fetchNextAvailableSlots()
                        }
                        else{
                            fetchNextAvailableSlots()
                        }
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
		}
	};

    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
    
        if (event.target.checked) {
          setCheckedBoxes((prevCheckedBoxes) => [...prevCheckedBoxes, checkboxId]);
        } else {
          setCheckedBoxes((prevCheckedBoxes) =>
            prevCheckedBoxes.filter((id) => id !== checkboxId)
          );
        }
      };

    return (
        <Box className="adminInnerPage productCategories">
            <style>{customStyles}</style>
            <div className="title-button__container">
                <Typography variant="h2" className="adminInnerPage__title">
                    Pickup Slots
                </Typography>

                {/* <Button
                    variant="primary"
                    color="primary"
                    className="btn btn-secondary"
                    onClick={() => openEditModal(listData.id)}
                >
                    Edit Description
                </Button> */}
            </div>

            <div className="productListPage__container">
                <Box className="order-table-container product-categories-table-container">
                    <Row className="calenderBox">
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Calendar
                            onClickDay={handleDateClick}
                            value={selectedDates}
                            tileDisabled={({ date, view }) => isDateBeforeToday(date)}
                            tileClassName={({ date, view }) => {
                                if (defaultSelectedDates.length > 0 && isDateSelected2(date)) {
                                    if (selectedDates.some(selectedDate => isSameDay(selectedDate, date))) {
                                        return 'hasSlot newSlot';
                                    } else {
                                        return 'hasSlot';
                                    }
                                } 
                                else {
                                    if (selectedDates.some((selectedDate) => isSameDay(selectedDate, date))) {
                                        return 'newSlot';
                                    }else{
                                        return 'custom-tile'; 
                                    }
                                   
                                }
                            }}
                        />
                        </Col>
                   
                    {/* <div>
                        Selected Dates:
                        {selectedDates.length > 0 && (
                        <ul>
                            {selectedDates.map((date) => (
                            <li key={date.toISOString()}>{date.toISOString()}</li>
                            ))}
                        </ul>
                        )}
                    </div> */}
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className="addSlotBox">
                        <Typography className="color4 font-18 mb-3 font-bold">Add Slot Time</Typography>
                        <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker', 'TimePicker']}>
                            <form onSubmit={addSlot}>
                                {/* <Form.Group
                                controlId="startTime"
                                className="slander-address-form__field islander-address-form__field--full pb-2">
                                    <Form.Label>Start Time</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="startTime"
                                        value={timeData.startTime}
                                        required
                                        onChange={handleStartTimeChange}
                                        // style={{"maxWidth": "350px"}}
                                    />
                                </Form.Group> */}
                                <Box>
                                    <TimePicker
                                        label="Start Time"
                                        value={timeData.startTime}
                                        ampm={false} 
                                        className="timePickerBox"
                                        // format="HH:mm"
                                        onChange={handleStartTimeChange}
                                    />
                                </Box>
                                <Box sx={{mt:3}}>
                                    <TimePicker
                                        label="End Time"
                                        value={timeData.endTime}
                                        ampm={false} 
                                        className="timePickerBox"
                                        // format="HH:mm"
                                        onChange={handleEndTimeChange}
                                    />
                                </Box>
                              
                                {/* <Form.Group
                                controlId="endTime"
                                className="slander-address-form__field islander-address-form__field--full">
                                    <Form.Label>End Time</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="endTime"
                                        value={timeData.endTime}
                                        required
                                        onChange={handleEndTimeChange}
                                        // style={{"maxWidth": "350px"}}
                                    />
                                </Form.Group> */}
                                  <Box className="flexBox">
                                  <Button
                                    color="primary"
                                    className="btn btn-primary mt-4"
                                    type="submit"
                                >
                                    Add Slot
                                </Button>
                                {checkedBoxes.length > 0 && (
                                       <Button
                                       color="primary"
                                       className="btn btn-secondary mt-4"
                                       onClick={() => openDeleteModal("allDelete")}
                                       >
                                       Select & Delete All Available Slots
                                   </Button>
                                )}
                                  </Box>
                              

                            </form>
                            </DemoContainer>
                            </LocalizationProvider>
                            <Box className="flexBox">
                                {/* <Button
                                    color="primary"
                                    className="btn btn-secondary mt-4"
                                    onClick={viewSlot}
                                >
                                    View Slot
                                </Button> */}
                                {checkedBoxes.length > 0 && (
                                    <>
                                     <Button
                                        color="primary"
                                        className="btn btn-secondary mt-4"
                                        onClick={() => openDeleteModal("bulkSelect")}
                                        >
                                        Delete Selected Slots
                                    </Button>
                                   
                                    </>
                                   
                                )}
                                  
                             
                            </Box>
                           
                            <Typography className="font-14 color2 font-600 pt-1">{slotErrorMessage}</Typography>
                            <Typography className="font-14 color2 font-600 pCalendart-1">{slotErrorMessage3}</Typography>
                        </Box>
                    </Col>
                    </Row>
                    <Box>
                            {loadSlot ? (
                                <>
                                    <Typography className="font-14 color2 font-600 pt-1">{slotErrorMessage2}</Typography>
                                </>
                                ) : (
                                <>
                                {title === 2 ? (
                                    <Typography className="font-16 color4 font-bold mt-3 mb-3">Added Slots</Typography>
                                ):(
                                    <Typography className="font-16 color4 font-bold mt-3 mb-3">Available Slots</Typography>
                                )}
                                <Box>
                                    {availableSlotByDate && availableSlotByDate.length>0 ? (
                                    <Box className="flexBox">
                                         {availableSlotByDate.map((data, index) => (
                                            <Box key={data.id} className="slotBox">
                                                <Box className="flexBox" sx={{gap: "8px"}}>
                                                    <input type="checkbox" id={data.id} checked={checkedBoxes.includes(data.id)} onChange={handleCheckboxChange}/>
                                                    <Typography className="font-13 color4">Date: {data.date}</Typography>
                                                </Box>
                                                <Box className="flexBox">
                                                    <Typography className="font-15 color2 font-600">{data.start_time}-{data.end_time}</Typography>
                                                    <DeleteOutlinedIcon sx={{color: "#DB2D6A"}} onClick={() => openDeleteModal(data.id)}/>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                    ):(
                                    <Box textAlign="left" sx={{mt: 3, minHeight: "176px"}}>
                                        <Typography className='font-16 font-600 color2'>
                                            No slot available for selected dates.
                                        </Typography>
                                    </Box>
                                    )}
                                </Box>
                                </>
                            )}
                    </Box>
                    {/* <Box>
                        <Typography className="font-16 color4 font-600">Newly Added Slots</Typography>
                        
                    </Box> */}
                </Box>
            </div>

            {/* delete dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
                    {selectedSlot === "bulkSelect" ? (
                        <p>Are you sure you want to delete Selected Slots?</p>
                    ):(
                        <>
                         {selectedSlot === "allDelete" ? (
                            <p>Are you sure you want to delete All Slots?</p>
                         ):(
                            <p>Are you sure you want to delete this Slot?</p>
                         )}
                        </>
                        
                    )}
					
				</DialogContent>
				<DialogActions>
                    {selectedSlot === "bulkSelect" || selectedSlot === "allDelete" ? (
                        <Button onClick={() => deleteSlot(selectedSlot)} color="primary">
						    OK
					    </Button>
                    ):(
                        <Button onClick={() => deleteSlot(selectedSlot)} color="primary">
						    OK
					    </Button>
                    )}
					
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
        </Box>
    );
}

export default TimeSlot;
