import { useEffect, useState  } from 'react';
import { Routes, Route, Switch } from 'react-router-dom';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import Cookies from 'universal-cookie';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import Header from '../header/header';
import Footer from '../footer/footer';
import Cart from '../checkout/cart';
import Checkout from '../checkout/checkout';
import BuyProduct from '../product/buyProduct';
import OrderDetail from '../checkout/orderDetail';
import Payment from '../checkout/payment';
import ComingSoon from './comingSoon';
import BuyProductDetail from '../product/buyProductDetail';
import PageNotFound from './pageNotFound';
const cookies = new Cookies();

function Structure() {
//   const [loginStatus, setAnchorElNav] = useState(null);
  const [loginStatus, setLoginStatus] = useState(false);
//   const classes = useStyles();

  let login = checkLogin()

  return (
    <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Routes>
                    {/* <Route path="/*" element={<PageNotFound />} /> */}
                    <Route path="/buy-product" element={<BuyProduct />}></Route>
                    <Route path="/buy-product-detail/:productId/:type" element={<BuyProductDetail />}></Route>
                    <Route path="/buy-item-cart" element={<Cart />}></Route>
                    <Route path="/checkout" element={<Checkout />}></Route>
                    <Route path="/order-detail" element={<OrderDetail />}></Route>
                    <Route path="/payment" element={<Payment />}></Route>
                </Routes>
            </Container>
        <Footer />
    </>
  )
}
export default Structure;
