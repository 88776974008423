import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DriversOrderTable from "./DriversOrderTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompletedOrders, fetchOutForDeliveryOrders, fetchAssignedOrders, fetchCanceledOrders } from "../redux/driverOrderSlice";

function DriverOrders() {
	const [selectedTab, setSelectedTab] = useState(0);
	const dispatch = useDispatch();


	const assignedOrders = useSelector((state) => state.driverOrder.assignedOrders);
	const outForDeliveryOrders = useSelector((state) => state.driverOrder.outForDeliveryOrders);
	const completedOrders = useSelector((state) => state.driverOrder.completedOrders);
	const canceledOrders = useSelector((state) => state.driverOrder.canceledOrders);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};



	useEffect(() => {
		switch (selectedTab) {
			case 0:
				dispatch(fetchAssignedOrders());
				break;
			case 1:
				dispatch(fetchOutForDeliveryOrders());
				break;
			case 2:
				dispatch(fetchCompletedOrders());
				break;
			case 3:
				dispatch(fetchCanceledOrders());
				break;
			default:
				dispatch(fetchAssignedOrders());
				break;
		  }
	}, [selectedTab]);

	return (
		<>
			<Box className="adminInnerPage driverOrdersPage">
				<Typography variant="h2" className="adminInnerPage__title">
					Orders
				</Typography>

				<Box className="adminInnerPage__tabs">
					<Tabs value={selectedTab} onChange={handleTabChange}>
						<Tab label="Assigned" />
						<Tab label="On Route/Ready to Pick up" />
						<Tab label="Delivered/Picked up" />
						<Tab label="Cancelled" />
					</Tabs>
				</Box>

				<Box className="adminInnerPage__tabs-content driver-tabs">
					{selectedTab === 0 && (
						<Typography component="div" role="tabpanel">
							<div className="order-search-filter">
								<div className="order-search-filter__search">
									{/* <input
										type="text"
										placeholder="Search For Driver"
									/> */}
								</div>
							</div>

							<DriversOrderTable driverInfo={assignedOrders} type="assigned"/>
						</Typography>
					)}
					{selectedTab === 1 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<DriversOrderTable driverInfo={outForDeliveryOrders} type="outForDelivery"/>
					</Typography>
					)}
					{selectedTab === 2 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<DriversOrderTable driverInfo={completedOrders} type="completed"/>
					</Typography>
					)}
					{selectedTab === 3 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<DriversOrderTable driverInfo={canceledOrders} type="Cancelled"/>
					</Typography>
					)}
				</Box>
			</Box>
		</>
	);
}

export default DriverOrders;
