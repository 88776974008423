import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';

const PageNotFound = () => {
    return (
        <>
            <Box className='not_found_block' textAlign="center">
                <Box>
                    <img src='/assets/img/page_not_found.png' alt="not_found" className='notFound'></img>
                </Box>
            </Box>
        </>
    );
}

export default PageNotFound;
