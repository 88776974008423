import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import CMSFooter from '../../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const BuyABarrel = () => {
    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45'>Buy A Barrel</Typography>
                        </Col>
                    </Row>
                    <Box sx={{pt: 5,'@media (max-width: 767px)': {pt: 1},}}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} >
                                <Box textAlign="left" className="service_inner" sx={{ pt: 5 }}>
                                    <Typography className='color2 font-19'>IOF makes buying barrels more seamless than it’s ever been before. Although you are able to purchase barrels without registering, once registered you are better able to track shipments at ease. Simply click here to purchase your barrels. We have a variety of barrel sizes to suit your shipping and other needs.
                                    </Typography>
                                    <Typography sx={{pt: 4,'@media (max-width: 767px)': {pt: 2},}} className='color2 font-19'>Once you purchase your barrels, it’s delivered right to your door. Experience convenience like never before!
                                    </Typography>
                                </Box>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='barrel-img-box'>
                                <Box className="w-80" sx={{ mt: -5 }}>
                                    <img src="../assets/img/service_barrel_img1.png" alt="list_img" className='' />
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default BuyABarrel;
