import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    productCategories: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchProductCategories = createAsyncThunk(
    "customer/productCategories",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/categories", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  
  // Async thunk action to delete a customer
  export const deleteProductCategories = createAsyncThunk(
    "customer/deleteProductCategories",
    async (id) => {
        try {
			const response = await Request.delete(`admin/category/${id}`, [], true);
			if(response) {
				if (response.status === 204) {
					// Update the state to remove the deleted user
					return id
				  }
				}
		  } catch (error) {
			console.error('Error deleting row from API:', error);
		  }
    }
  );
  

    // Async thunk action to delete a customer
    export const updateProductCategories = createAsyncThunk(
        "customer/updateProductCategories",
        async ({id, item}) => {
            try {
                  
                const response = await Request.put(`admin/category/${id}`, item, true);
                if(response) {
                    if (response.status === 200) {
                        // Update the state to remove the deleted user
                        return response.status
                      }
                    }
              } catch (error) {
                console.error('Error update row from API:', error);
              }
        }
      );

  const productCategoriesSlice = createSlice({
    name: "productCategories",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchProductCategories.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchProductCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.productCategories = action.payload;
          })
          .addCase(fetchProductCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(deleteProductCategories.pending, (state) => {
            state.loading = true;
          })
          .addCase(deleteProductCategories.fulfilled, (state, action) => {
            state.loading = false;
            const deletedCategoryId = action.payload;
            state.productCategories = state.productCategories.filter(
              (category) => category.id !== deletedCategoryId
            );
          })
          .addCase(deleteProductCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(updateProductCategories.pending, (state) => {
            state.loading = true;
          })
          .addCase(updateProductCategories.fulfilled, (state, action) => {
            state.loading = false;
            // Update the customer data in the state (if needed)
            const updatedCategoriesData = action.payload;
            state.productCategories = state.productCategories.map((category) =>
            category.id === updatedCategoriesData.id
                ? updatedCategoriesData
                : category
            );
          })
          .addCase(updateProductCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
      },
  });
  
  export default productCategoriesSlice.reducer;