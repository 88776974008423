import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import CMSFooter from '../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';
import { spacing } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    list: {
    },
    listItem: {
        paddingLeft: "0px",
    },
    Avatar: {
        borderRadius: "4px",
        width: "45px",
        height: "45px",
        marginRight: "20px"
    },
    card: {
        maxWidth: 200,
        margin: spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9 aspect ratio
    },
    // grid:{
    //     gap: "20px",
    // },
    grid: {
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "15px",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },

    // For medium screens (between 600px and 960px)

    col2: {
        textAlign: "left",
        paddingLeft: "20px"
    },
    blockBackground: {
        backgroundImage: "url('./assets/img/block_bg_image.svg')",
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
    },
    blockInnerBackground: {
        backgroundColor: "var(--primary-color6) !important",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "200px 0px"
    }

}));



const AboutUs = () => {
    const classes = useStyles();
    const [title, setTitle] = useState('');

    const path = window.location.pathname;
    const cleanedPath = path.replace(/^\/|\/$/g, '');
    
    useEffect(() => {
        if (cleanedPath === 'brand-story') {
            setTitle('Brand Story');
        } else if(cleanedPath === 'about-us') {
            setTitle('Brand Story');
        }
    },[cleanedPath]);

    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                        <Typography className='color2 font-bold font-45'>{title}</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5 }}>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} className={classes.col2}>
                                    <Box textAlign="left" className="service_inner" sx={{pt: 5,'@media (max-width: 767px)': {pt: 1},}}>
                                        <Typography textAlign="left" className='color2 font-19'>
                                            Every successful business has a story to tell - a journey marked by challenges, growth, and unwavering determination. 
                                            The brand story of Isle of Freight is no different. Founded by a group of six millennial friends who shared a common goal, 
                                            the company set out to redefine shipping issues to the Caribbean, by embracing cutting-edge technology and a 
                                            customer-centric approach. Our mission is to provide the most cost-effective, convenient and innovative way to ship 
                                            cargo to the Caribbean through the use of latest technology
                                            We offer exceptional customer service alongside our affordable rates.
                                        </Typography>
                                        <Typography textAlign="left" className='color2 font-19' sx={{pt: 4,'@media (max-width: 767px)': {pt: 2},}}>
                                            The early days were not without hurdles. The team encountered logistical puzzles, 
                                            supply chain problems, and operational constraints. Yet, each challenge was met with 
                                            resilience, as the company constantly sought innovative solutions and adapted to 
                                            ever-evolving industry demands.
                                        </Typography>
                                        
                                    </Box>  
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='d-flex align-items-center justify-content-center'>
                                    <Box className="w-90" sx={{ mt: 0 }}>
                                        <img src="../assets/img/about-image-1.png" alt="list_img" className='about-image-1' />
                                    </Box>
                                </Col>
                            </Row>
                            <Row className='about-section-bottom'>
                                <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='d-flex align-items-center justify-content-start order-2 order-md-1'>
                                    <Box className="w-90" sx={{ mt: 0 }}>
                                        <img src="../assets/img/about-img-2.png" alt="list_img" className='about-image-1' />
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} className='order-1 order-md-2'>
                                <Box textAlign="left" className="service_inner" sx={{pt: 5,'@media (max-width: 767px)': {pt: 0},}}>
                                <Typography textAlign="left" className='color2 font-19'>
                                            In a world driven by technology, Isle of Freight recognized the need to embrace 
                                            digital transformation. The company invested heavily in state-of-the-art software, 
                                            tracking systems, and data analytics. 
                                        </Typography>
                                <Typography textAlign="left" className='color2 font-19' sx={{pt: 4,'@media (max-width: 767px)': {pt: 2},}}>
                                    What truly set Isle of Freight apart was its unwavering commitment to delivering excellence
                                    and superior customer service. Every shipment, every client interaction, and 
                                    every logistical challenge are met with a dedication to surpass expectations. 
                                </Typography>
                                <Typography textAlign="left" className='color2 font-19' sx={{pt: 4,'@media (max-width: 767px)': {pt: 2},}}>
                                    As Isle of Freight continues to navigate the complex world of logistics, 
                                    its story serves as a reminder that with determination and innovation, 
                                    any challenge can be turned into an opportunity for growth and success. 
                                </Typography>
                                    </Box>
                                    </Col> 
                            </Row>
                            {/* <Box className="makeStyle_background" sx={{ mt: 5,mb:5 }}>
                                <Typography textAlign="left" className='color2 font-19'>
                                    What truly set Isle of Freight apart was its unwavering commitment to delivering excellence
                                    and superior customer service. Every shipment, every client interaction, and 
                                    every logistical challenge are met with a dedication to surpass expectations. 
                                </Typography>
                                <Typography textAlign="left" className='color2 font-19' sx={{ pt: 4 }}>
                                    As Isle of Freight continues to navigate the complex world of logistics, 
                                    its story serves as a reminder that with determination and innovation, 
                                    any challenge can be turned into an opportunity for growth and success. 
                                </Typography>
                            </Box> */}
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default AboutUs;
