import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import CMSFooter from '../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const TermAndCondition = () => {
    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45'>Terms & Conditions</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5, pb:4 }}>
                        <div class="row">
                            <div class="MuiBox-root css-pxpt32">
                                <Typography textAlign="left" className='color4 font-bold font-roboto font-22 '>CHANGES TO TERMS OF USE</Typography>
                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>
                                    At IOF, we strive to continually improve our services and offerings to better serve you. With this in mind, please take note of our policy regarding changes to our Terms of Use:
                                </Typography>
                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>
                                Modification of Terms and Conditions: IOF reserves the right to make changes or modifications to any of the terms and conditions outlined in these Terms of Use, as well as any policies or guidelines related to our Site and Services. These changes will be made at our sole discretion.
                                </Typography>
                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>
                                Immediate Effect of Changes: Changes made to these Terms of Use will take effect immediately once they are published on our website. By utilizing our Site and Services, you recognize and consent that you are giving up any entitlement you might have to receive explicit notification of these changes or revisions.
                                </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                Acceptance of Changes: By using the IOF Site after any changes or modifications have been posted, you indicate your acceptance of those changes or modifications. Thus, we recommend that you routinely check these Terms of Use and relevant policies to remain updated about the rules that oversee your usage of our Site.
                                </Typography>

                                <Typography textAlign="left" className='color4 font-bold font-roboto font-22 '>COMPLIANCE WITH LAWS</Typography>

                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>Customs and Regulations: It's your responsibility to ensure that your shipments, whether barrels or Islander Box items, comply with all applicable customs laws, regulations, and requirements.</Typography>

                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>
                                You are entirely accountable for the content and characteristics of any items shipped through the Service. It's your duty to ensure that the item can be legally transported into the Caribbean. This responsibility encompasses adhering to all relevant taxes, import and export regulations, as well as any shipping restrictions, and providing accurate customs declarations when necessary. Familiarizing yourself with and comprehending these requirements is solely your responsibility. Failure to comply with these regulations may result in the item being either destroyed or returned to the seller at your own expense, without IOF assuming any responsibility. Additionally, IOF strictly forbids any engagement in illegal activities associated with or through the Services.
                                </Typography>

                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>
                                You acknowledge and accept that IOF do not have access to any information regarding the content of barrel you send, and IOF bears no responsibility for such content.
                                </Typography>

                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                Additionally, you are responsible for compensating IOF for any damages it incurs due to your failure to adhere to relevant laws and shipping restrictions. This may encompass legal expenses, penalties, or any other losses incurred by IOF.
                                </Typography>

                                <Typography textAlign="left" className='color4 font-bold font-roboto font-22 '>ACCURACY OF INFORMATION AND DECLARATIONS</Typography>

                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>It is your responsibility to ensure the accuracy of any information that you provide to IOF and online retailers. IOF bears no liability for lost or incorrectly delivered packages due to inaccuracies in the names, addresses, or other identification details supplied by you to IOF or the retailer. Similarly, IOF is not accountable for any repercussions resulting from inaccuracies in the declared value or weight of shipped goods</Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>TRADEMARKS</Typography>

                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>The intellectual property rights for IOF trademarks, including IOF logos, as well as any other names or slogans associated with products or services on the website or in the website materials, belong to IOF or their respective suppliers or licensors. They must not be replicated, mimicked, or utilized, either wholly or partially, unless prior written consent is obtained from IOF or the relevant trademark owner.</Typography>
                               
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                You are not allowed to use any meta-tags or hidden text that includes "IOF" or any other name, trademark, product, or service associated with IOF without our explicit written consent. Additionally, the complete look and layout of the website, encompassing components such as page headers, unique graphics, button icons, and scripts, are recognized as the intellectual property of IOF. Replicating, imitating, or partially utilizing these elements without our prior written consent is prohibited.
                                </Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>COPYRIGHT COMPLAINTS</Typography>

                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>If you believe that any content on the Site, including Site Materials, violates your copyright, you can report this infringement to us at the following email address: support@isleoffreight.com </Typography>

                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>Please be aware that if you intentionally provide false information in your notification, falsely claiming that the material or activity is infringing, you may be held responsible for any expenses, including legal costs, incurred by us or the individual accused of infringement due to our reliance on this inaccurate information when removing or restricting access to the material or activity believed to be infringing.
                                </Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>DISCLAIMER</Typography>

                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>Unless a written agreement by IOF specifies otherwise, the Site, Site Materials, and the information, products, and services available on the Site are provided in their current state without any expressed or implied representations, warranties, or conditions. IOF disclaims all other implied representations, warranties, or conditions, including but not limited to merchantability, quality, durability, fitness for a specific purpose, title, and non-infringement regarding the Site and its content. IOF cannot provide assurance that the Site Materials or any other content, products, services, or information on the Site are accurate, complete, dependable, up-to-date, or devoid of errors. Furthermore, IOF does not assure that the Site or its services are free from viruses or other harmful elements.
                                IOF cannot assure the absence of viruses or other detrimental elements on the Site or its servers. Hence, it is recommended to employ reputable software for virus detection and removal when downloading files.</Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>LIMITATION OF LIABILITY</Typography>
                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>Notwithstanding any other provisions stated in these Terms of Use, IOF, including its directors, officers, employees, and agents, shall not bear responsibility for any direct, particular, indirect, punitive, or resultant damages. This includes, but is not limited to, damages such as loss of use, loss of profits, or loss of data. </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>You acknowledge that IOF shall not be held responsible for any expenses or losses resulting from a disagreement between you and any of its logistic partners. To provide additional clarification, IOF assumes no liability for shipments once they have been handed over to the designated carrier.
                                </Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>TERMINATION</Typography>
                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>Despite any provisions in these Terms of Use, IOF has the authority to, at its sole discretion and without any obligation to IOF, discontinue any of its programs, end your access to the Site, and restrict or prohibit your future use of the Site, all without prior notice or any associated costs or liabilities to IOF.</Typography>
                                <Typography sx={{ mb: 1 }} textAlign="left" className='color2 font-19 '>IOF will not assume responsibility for any instances where it cannot inform you of its actions due to reasons such as having an incorrect email address, your neglect in checking your emails online, your failure to update IOF about changes in your email address, or if you do not receive the notification email for any cause.</Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>You have the option to close your account anytime by sending an email to support@isleoffreight.com,  the email address linked to your account, using the subject line "ACCOUNT CLOSURE." However, please be aware that even after closing your account, the Terms of Use and Terms of Service will still be applicable to your previous and potential future use of the website.
                                </Typography>

                                <Box sx={{p: 4, '@media (max-width: 600px)': {
                                            p: 2, // Reduce padding for screens with a width of 600px or less
                                            }}} className="bg-white">
                                <Typography textAlign="left" className='color4 font-bold font-roboto font-25 '>ENTIRE AGREEMENT</Typography>

                                    <Typography sx={{ mb: 3 , mt: 2}} textAlign="left" className='color2 font-19 '>These Terms of Use constitute the comprehensive understanding and agreement between you and IOF concerning the subject matter herein, and they override and substitute any prior agreements, including, but not limited to, all previously published agreements.
                                    </Typography>

                                    <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>WAIVER</Typography>
                                    <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>You acknowledge that IOF's decision not to exercise or enforce any legal rights or remedies outlined in these Terms of Use (or those granted by applicable laws) does not constitute a formal waiver of IOF's rights. IOF retains the availability of those rights and remedies.
                                    </Typography>

                                    <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>SURVIVAL</Typography>
                                    <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>Any covenants, agreements, representations, and warranties stated in these Terms of Use will remain in effect after you accept these Terms of Use and even after these Terms of Use are terminated.</Typography>

                                    <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>SEVERABILITY</Typography>
                                    <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>In the event that any part of these Terms of Use is considered illegal, invalid, or unenforceable for any reason, it will be treated as separate from the rest of these Terms of Use, and the remaining provisions will still be valid and enforceable.</Typography>

                                    <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>QUESTIONS AND CONTACT DETAILS</Typography>
                                    <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>If you require any guidance or have inquiries about utilizing the Site or Service, we encourage you to consult our Help Center. Should you need further support or wish to share any thoughts or concerns regarding the Site or Service, please don't hesitate to reach out to us via our Contact Page.</Typography>

                                    <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>LEGAL CAPACITY</Typography>
                                    <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>You represent and warrant that you possess the legal right, capacity and ability to agree to these Terms and Conditions and use the Website in accordance with them. We understand the importance of protecting children's privacy online.</Typography>

                                </Box>

                                <Box sx={{p: 4, mt: 3,'@media (max-width: 600px)': {
                                        p: 2, // Reduce padding for screens with a width of 600px or less
                                        }}} className="bg-white">
                                <Typography textAlign="left" className='color4 font-bold font-roboto font-25 '>CLIENT AGREEMENT</Typography>

                                    <Typography sx={{ mb: 2 , mt: 2}} textAlign="left" className='color2 font-19 font-600'>The customer acknowledges and agrees that:
                                    </Typography>

                                    <Box className="flexBox2">
                                        <Box>
                                            <Typography textAlign="left" className='color2 font-19'>1.</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                            The Service shall not be used for Restricted or Prohibited Commodities (a designated as such under the Customs Act), including not limited to: Alcohol beverages, Animal skins (non-domesticated), Articles of exceptional value (ie. works of art, antiques, precious stones, gold and silver), Dangerous goods/Hazardous materials, Firearms, Furs, Human or animal remains or parts, Illegal Items, Ivory and ivory products, Live animals, Money or negotiable items, Perishable goods, Plants, Pornographic materials, Seeds, Tobacco and tobacco products, Counterfeit money, any property obtained by crime.
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box className="flexBox2">
                                        <Box>
                                            <Typography textAlign="left" className='color2 font-19'>2.</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                            Isle of Freight is not liable for any failure or delay in fulfilling its obligations due to forces beyond its control, such as strikes, work stoppages, accidents, war or terrorism, civil or military disturbances, nuclear or natural catastrophes, acts of God, interruptions, loss or malfunctions of utilities, communications, or computer services. 
                                            </Typography>
                                        </Box>
                                    </Box>
                                    

                                    <Box className="flexBox2">
                                        <Box>
                                            <Typography textAlign="left" className='color2 font-19'>3.</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                            The Client shall indemnify and Isle of Freight harmless for all penalties imposed by customs department of the respective islands for the clearance and handling of the Cargo.</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="flexBox2">
                                        <Box>
                                            <Typography textAlign="left" className='color2 font-19'>4.</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                            The Customer is responsible for supplying Isle of Freight with all required information and suitable documentation, such as accurate spelling of sender and recipient information, proper identification of barrel contents and identification cards when required for the correct processing of customs.</Typography>
                                        </Box>
                                    </Box>

                                </Box>
                                
                                
                            </div>
                        </div>
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default TermAndCondition;
