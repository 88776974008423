import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm';
import {loadStripe} from '@stripe/stripe-js';
import Request from '../customUtils/Http/Request';
import SendCheckoutForm from './sendCheckoutForm';
import { FetchStripePublicKeyFromAPI } from '../commonFunctions/loadStripe';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51NIltgSHZUnKCsvrZGDFY49bs9HsyUBNiJ6nr1pIbWxlpweEHBdvSnoja71H1Yh1GFSjVQzvMr3Q4gFl0SfxVg2I00tTeDJSHL');

function SendProductCheckout() {
  const [stripeKey, setStripeKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orderIdElements, setOrderIdElement] = useState({
    orderId: null
});
const [options, setOptions] = useState({
    clientSecret: null
});

useEffect(() => {
  const initializeStripe = async () => {
    try {
      const stripePublicKey = await FetchStripePublicKeyFromAPI();
      const stripeInstance = await loadStripe(stripePublicKey);
      setStripeKey(stripeInstance);
    } catch (error) {
      console.error('Error loading Stripe:', error);
    }
  };

  initializeStripe();
}, []);

  useEffect(() => {
    async function fetchClientSecret() {  
        // let response = await Request.get("cart", [], true);
        let response = await Request.get("checkout/stripeSendCheckout", [] , true);
         ;
        const orderIdElements = [];

        // var orderId = response.orderId,
        // var client_secret= response.client_secret,

        setOrderIdElement({orderId : response.orderId})
        // setOrderIdElement({client_secret : response.client_secret})
        setOptions({
            clientSecret : response.client_secret.client_secret
        })
        // for (var i = 0; i < response.length; i++) {
        //     const item = {
        //         orderId: response[i].orderId,
        //         client_secret: response[i].client_secret,
        //     };

        //     orderIdElements.push(item);
            
        // }
        // const response = await fetch(baseURL + '/stripe/stripeCheckout', {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        // });
        // const {client_secret} = await response.json();

        // setClientSecret(orderIdElements.client_secret);
        setIsLoading(false);
      }
  
      fetchClientSecret();
  }, []);

//   useEffect(() => {
//     console.log(orderIdElements.client_secret,'callllll22222')
//     const options = {
//       clientSecret: orderIdElements.client_secret,
//       appearance: {/*...*/},
//     };
//   }, [orderIdElements]);

//   console.log(orderIdElements.client_secret,'callllll')
//   const options = {
//     clientSecret: orderIdElements.client_secret,
//     appearance: {/*...*/},
//   };

  return (
    isLoading ? (
        <div className="spinner-container">
          <div className="loading-spinner">
          </div>
      </div>
    ) : (
    
      <Elements stripe={stripeKey} options={options}>
        <SendCheckoutForm setOrderIdElement={orderIdElements}/>
      </Elements>
    )
  );
}


export default SendProductCheckout;