import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Chip,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import ProductTable from "./ProductListTable";
import ImageTable from "./ImageTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchPriorityProductList } from "../redux/priorityProductSlice";
import { fetchPriorityProductCategories } from "../redux/priorityProductCategoriesSlice";
import Cookies from 'universal-cookie';
import PriorityProductListTable from "./priorityProductListTable";
const cookies = new Cookies();

function PriorityProductList() {
	const [open, setOpen] = useState(false);
	const [productName, setProductName] = useState("");
	const [price, setPrice] = useState("");
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		name: "",
		size: "",
		sku: "",
		price: '',
		selectedCategory: '',
		categories: [],
		description: "",
		// shortDescription: "",
		img_1: '',
		img_2: '',
		img_3: '',
		img_4: '',
		stock: '',
		stockManagement: false,
		status: false
	  });
	  const [newCategory, setNewCategory] = useState("");
	  const [categories, setCategories] = useState([]);
	  const [categoryError, setCategoryError] = useState('');
	  const [rows, setRows] = useState([{}]);
	  const [dropdownOptions, setDropdownOptions] = useState([1, 2, 3, 4]); 
	  const [usedDropdownOptions, setUsedDropdownOptions] = useState([]);
	  const [imageDataArray, setImageDataArray] = useState([]); 

	const priorityProductsList = useSelector((state) => state.priorityProductList.priorityProductList);
	const PriorityProductCategoriesList = useSelector((state) => state.priorityProductCategories.priorityProductCategories);
	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleAddProduct = () => {
		handleCloseDialog();
	};

	const [visibility, setVisibility] = useState("public");
	const handleVisibilityChange = (e) => {
		const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setFormData((prevData) => ({ ...prevData, status: newVisibility }));
		// setVisibility(event.target.value);
	};


	const handleCategorySelect = (event) => {
		
		const selectedCategory = event.target.value;
		if (selectedCategory === 'custom') {
			setFormData({ ...formData, selectedCategory: 'custom' });
		} else {
		//   setFormData({ ...formData, selectedCategory });
		  setFormData({ ...formData, selectedCategory });
		}
	  };

	const handleAddCategory = () => {
		
		if (newCategory.trim() === '') {
			setCategoryError('Please enter a category name');
		  } else {
			// Check if the category is already in the list
			// if (!formData.categories.includes(newCategory)) {
			//   const updatedCategories = [...formData.categories,newCategory];
			//   setFormData({ ...formData, categories: updatedCategories, selectedCategory: newCategory });
			// }

			if (!formData.categories.includes(newCategory)) {
				const updatedCategories = [...formData.categories, newCategory];
				setFormData({
				  ...formData,
				  categories: updatedCategories,
				  selectedCategory: newCategory, // Set selectedCategory to the new category
				});
			  }
	  
			// Clear the input field and error message
			setNewCategory('');
			setCategoryError('');
		  }
	  };

	const handleCategoryDelete = (categoryToDelete) => () => {
		const updatedCategories = formData.categories.filter(
			(category) => category !== categoryToDelete
		  );
		  setFormData({ ...formData, categories: updatedCategories });
	};

	const [tableData, setTableData] = useState([
		{ imageSrc: "/assets/img/no_image_item.png", altText: "Image 1", orders: 5 },
		{ imageSrc: "/assets/img/no_image_item.png", altText: "Image 2", orders: 3 },
		// Add more data objects as needed
	]);

	const handleDelete = (index) => {
		const updatedData = tableData.filter((_, i) => i !== index);
		setTableData(updatedData);
	};

	const handleAltTextChange = (index, altText) => {
		const updatedData = [...tableData];
		updatedData[index].altText = altText;
		setTableData(updatedData);
	};

	const handleOrderChange = (index, orders) => {
		const updatedData = [...tableData];
		updatedData[index].orders = parseInt(orders);
		setTableData(updatedData);
	};

	const addRow = () => {
		
		if (rows.length >= 4 || !isLastRowBlank()) {
			setRows([...rows, {}]); // Disable adding more than four rows
		}

		const availableOptions = dropdownOptions.filter((option) => !usedDropdownOptions.includes(option));
		setUsedDropdownOptions([]);
		setRows([...rows, {}]);
		// setDropdownOptions(availableOptions);
	  };

	  const isLastRowBlank = () => {
		  
		if (rows.length >0){
			const lastRow = rows[rows.length - 1];
			return Object.keys(lastRow).length === 0;
		}else {
			return true
		}
	  };
	
	  const deleteRow = (index) => {
		const updatedRows = [...rows];
		updatedRows.splice(index, 1);
		setRows(updatedRows);

		const updatedImageDataArray = [...imageDataArray];
		updatedImageDataArray.splice(index, 1);
		setImageDataArray(updatedImageDataArray);
	  };
	
	  const handleFileChange = (index, event) => {
		
		const file = event.target.files[0];
		if (file) {
		  const imageName = `image_${index + 1}`;
		  const imagePath = URL.createObjectURL(file);
		  const customFile = {
			name: imageName,
			file: file,
			path: imagePath,
			order : 1
		  };

		  setImageDataArray((prevImageDataArray) => [...prevImageDataArray, customFile]);
		  // Handle file upload here
		}
	  };
	
	  const handleDropdownChange = (index, event) => {
		const selectedValue = parseInt(event.target.value, 10);
		imageDataArray[index]["order"] = selectedValue
		setImageDataArray(imageDataArray)
	  };

	useEffect(() => {
		dispatch(fetchPriorityProductList());
		dispatch(fetchPriorityProductCategories());
	}, []);

	useEffect(() => {
		dispatch(fetchPriorityProductList());
		dispatch(fetchPriorityProductCategories());
	}, [dispatch]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		// setFormData({ ...formData, [name]: value });
		setFormData({ ...formData, [name]: value });
		// if (name === 'shortDescription' && value.length > 100) {
		// 	setFormData({ ...formData, [name]: value.slice(0, 100) });
		//   } else {
		// 	setFormData({ ...formData, [name]: value });
		//   }
	  };
	  
	  const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setFormData({ ...formData, [name]: checked });
	  };

	const onHandleSubmit = async (e) =>{
		  
		e.preventDefault();

		if (formData.categories.length === 0) {
		  // Display an error message, alert, or handle it as needed
		  setCategoryError('Please select at least one category.');
		  return;
		}

		let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('name', formData.name);
        formdata.append('size', formData.size);
        formdata.append('sku', formData.sku);
        formdata.append('price', formData.price);
		formdata.append('description', formData.description);
        // formdata.append('shortDescription', formData.shortDescription);
		
		formdata.append("imageOrder", null)
		imageDataArray.forEach((img) => {
			if (img.dbId) {
				formdata.append("imageOrder", JSON.stringify({
					"order": img.order,
					"dbId": img.dbId
				}))
			} else {
				formdata.append(`img_${img.order}`, img.file);
			}
		})

		formdata.append('inStock', formData.stock);
		formdata.append('status', formData.status);
		formdata.append('stockManagement', formData.stockManagement);
		formData.categories.forEach((category) => {
			formdata.append('categories', category);
		});
		
		formdata.append('categories', null);
		formdata.append('images', formData.images);
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

       
		// fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/islander/product`, requestOptions)
		fetch(`${process.env.REACT_APP_DatabaseServer}admin/islander/product`, requestOptions)
		.then(response => {
			if (response.status === 400) {
				return response.json().then(data => {
					throw new Error(data.message);
				});
			} else {
				return response.json();
			}
		})
		.then(responseData => {
			setOpen(false);
			dispatch(fetchPriorityProductList());
		})
		.catch(error => {
			alert(error.message)
			console.error('Error:', error.message);
		});
	
	
    }

	return (
		<Box className="adminInnerPage productListPage">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
					Product List
				</Typography>

				<Button
					variant="primary"
					color="primary"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					New Product
				</Button>

				<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog admin-dialog--lg"
				>
					<form onSubmit={onHandleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Add New Product
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form">
								<div className="row add-product-form__row">
									<Form.Group
										controlId="productName"
										className="col-md-6"
									>
										<Form.Label>Product Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Product Name"
											name="name"
											value={formData.name} onChange={handleInputChange}
											required
										/>
									</Form.Group>

									<Form.Group
										controlId="productVisibility"
										className="col-md-6"
									>
										<Form.Label>Visibility</Form.Label>
										<div className="add-product-form__visibility">
											<Form.Check
												type="radio"
												label="Public"
												name="visibility"
												value="true"
												checked={formData.status === true}
												onChange={
													handleVisibilityChange
												}
											/>
											<Form.Check
												type="radio"
												label="Hide"
												name="visibility"
												value="false"
												checked={formData.status === false}
												onChange={
													handleVisibilityChange
												}
											/>
										</div>
									</Form.Group>
									<Form.Group
										controlId="description"
										className="col-md-12"
									>
										<Form.Label>Description</Form.Label>
										<Form.Control as="textarea" rows={3} required value={formData.description} name="description" onChange={handleInputChange} />
									</Form.Group>
									{/* <Form.Group
										controlId="shortDescription"
										className="col-md-6"
									>
										<Form.Label>Short Description</Form.Label>
										<Form.Control as="textarea" rows={3} maxLength={100}  required value={formData.shortDescription} name="shortDescription" onChange={handleInputChange}/>
										<Typography className="color1 font-15 mt-2">Character Count: {formData.shortDescription.length} / 100</Typography>
									</Form.Group> */}
									<Form.Group
										controlId="productCategories"
										className="col-md-6"
									>
										<Form.Label>Categories</Form.Label>
										<div className="add-product-form__categories">
                                        <Stack direction="row" spacing={1}>
                                        {(formData.categories && formData.categories.length>0) && formData.categories.map((category) => (
                                            <Chip
                                            key={"category_"+category}
                                            label={category}
                                            deleteIcon={<CloseIcon />}
                                            color="primary"
                                            onDelete={handleCategoryDelete(category)}
                                            />
                                        ))}
                                        </Stack>

      <Form.Select
        label="Select Category"
		value={newCategory}
		onChange={(e) => setNewCategory(e.target.value)}
      >
        <option value="" disabled>
          Select a category
        </option>
        {PriorityProductCategoriesList && PriorityProductCategoriesList.length >0 && PriorityProductCategoriesList.map((category) => (
          <option key={"addProduct_"+category.id} value={category.name}>
            {category.name}
          </option>
        ))}
        {/* <option value="custom">Add Custom Category</option> */}
      </Form.Select>
  
        <Button onClick={handleAddCategory} color="primary" className="btn btn-primary">
          Add
        </Button>
      </div>
      {categoryError && <Typography className="font-14" sx={{ color: 'red' }}>{categoryError}</Typography>}
									</Form.Group>
									<Form.Group
										controlId="price"
										className="col-md-3"
									>
										<Form.Label>Price($)</Form.Label>
										<Form.Control
											type="number"
											placeholder="Price"
											value={formData.price} name="price" onChange={handleInputChange}
											required
										/>
									</Form.Group>
							
									<Form.Group
										controlId="size"
										className="col-md-3"
									>
										<Form.Label>Size</Form.Label>
										<Form.Control
											type="text"
											placeholder="Size"
											value={formData.size} name="size" onChange={handleInputChange}
											required
										/>
									</Form.Group>


									<div className="col-md-12">
										<div className="row">
											<Form.Group
												controlId="sku"
												className="col-md-6"
											>
												<Form.Label>SKU</Form.Label>
												<Form.Control
													type="text"
													placeholder="SKU"
													value={formData.sku} name="sku" onChange={handleInputChange}
													required
												/>
											</Form.Group>

											<Form.Group
												controlId="stock"
												className="col-md-6"
											>
												<Form.Label>
													Stock Quantity
												</Form.Label>
												<Form.Control
													type="number"
													placeholder="Stock Quantity"
													value={formData.stock} name="stock" onChange={handleInputChange}
													required
												/>
											</Form.Group>
										</div>
										<div className="row">
											<Box sx={{mt:3, mb:0}} className="d-flex align-items-center gap-3">
												<input type="checkbox" id="stockManagement" name="stockManagement" style={{"width":"16px","height":"16px"}} value="stockManagement" onChange={handleCheckboxChange }/>
												<label className="font-15 color1 font-600" for="stockManagement">Enable Stock Management</label>
											</Box>
											
										</div>
									</div>
									<div className="col-md-12">
										<Typography
											variant="h6"
											className="section-title"
										>
											Images
										</Typography>
										<div className="order-table-container">
											 <table width="100%">
												<thead>
												<tr>
													<th>Image</th>
													<th>Text</th>
													<th>Order</th>
													<th>Action</th>
												</tr>
												</thead>
												<tbody>
													
													{(rows && rows.length>0) && rows.map((row, index) => (
														<tr key={"rows_"+index}>
														<td>
															
															{imageDataArray[index] ? (
															// ({`${process.env.REACT_APP_DatabaseStatic}${image?.img}`}
																<img style={{
																	maxWidth: "100px",
																	maxHeight: "100px",
																}} src={`${imageDataArray[index].path}`} alt={imageDataArray[index].name} />
															):(
																<input type="file" accept="image/*" onChange={(e) => handleFileChange(index, e)} />
															)}
														</td>
														<td>
															<TextField className="imgAltText" type="text" required/>
														</td>
														<td>
														{imageDataArray[index] ? (
															<select
															className="imgOrderDropdown"
															onChange={(e) => handleDropdownChange(index, e)}
															required
															style={{ height: "60px", minWidth: "100px" }}
															
															>
															{dropdownOptions && dropdownOptions.map((option) => (
																<option key={"dropdownOptions__"+option} selected={imageDataArray[index].order === option ? true : false} value={option}>
																	{option}
																</option>
															))}
															</select>
														) : 	
														// (
														// 	<select className="imgOrderDropdown" disabled={true} onChange={(e) => handleDropdownChange(index, e)} required style={{height:"60px",minWidth: "100px"}}>
														// 	{dropdownOptions.map((option) => (
														// 		<option key={option} value={option}>
														// 		{option}
														// 		</option>
														// 	))}
														// 	</select>
														// )
														""
														}
														</td>
														<td>
															<CloseIcon style={{ color: "#db2d6a" }} onClick={() => deleteRow(index)}/>
															{/* <button >Delete</button> */}
														</td>
														</tr>
													))}
													</tbody>
												</table>
												<Button type="button" className="b1 b4 mt-2" onClick={addRow} disabled={rows.length >= 4} >Add Row</Button>
										</div>
									</div>
								</div>
							</Box>
						</Box>
					</DialogContent>
					</form>
					
				</Dialog>
			</div>

			<div className="productListPage__container">
				<div className="order-search-filter product-search-filter">
					
				</div>
				<PriorityProductListTable productList={priorityProductsList}/>
				{/* <PriorityProductListT productList={priorityProductsList}/> */}
			</div>
		</Box>
	);
};

export default PriorityProductList;
