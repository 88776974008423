import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    pickupListLocation: [],
    pickupListAdminLocation: [],
    deliveryListAdminLocation: [],
    sendPickupListLocation: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchPickupList = createAsyncThunk(
    "customer/pickupListLocation",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("utils/getPickupLocations", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

    // Async thunk action to fetch customers
    export const fetchSendPickupList = createAsyncThunk(
      "customer/sendPickupListLocation",
      async (_, { rejectWithValue }) => {
          try {
              
              const response = await Request.get("utils/getPickupLocationsSend", []);
              if (response.length !== 0) {
                   
                  return response
              } else {
                  console.error("Failed to fetch data from API");
              }
              } catch (error) {
                  return rejectWithValue(error);
              }
      }
    );
  
  export const fetchDeliveryAdminList = createAsyncThunk(
    "customer/deliveryListAdminLocation",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("admin/pickup/location", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  export const fetchPickupAdminList = createAsyncThunk(
    "customer/pickupListAdminLocation",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("admin/pickup/locations/send", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  const pickupListSlice = createSlice({
    name: "pickupList",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchPickupList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPickupList.fulfilled, (state, action) => {
            state.loading = false;
            state.pickupListLocation = action.payload;
          })
          .addCase(fetchPickupList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchSendPickupList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchSendPickupList.fulfilled, (state, action) => {
            state.loading = false;
            state.sendPickupListLocation = action.payload;
          })
          .addCase(fetchSendPickupList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(fetchPickupAdminList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPickupAdminList.fulfilled, (state, action) => {
            state.loading = false;
            state.pickupListAdminLocation = action.payload;
          })
          .addCase(fetchPickupAdminList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchDeliveryAdminList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchDeliveryAdminList.fulfilled, (state, action) => {
            state.loading = false;
            state.deliveryListAdminLocation = action.payload;
          })
          .addCase(fetchDeliveryAdminList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
      },
  });
  
  export default pickupListSlice.reducer;