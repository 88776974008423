import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Card, CardMedia, Link, Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CardActions, CardContent } from '@material-ui/core';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form } from 'react-bootstrap';
import { protect } from '../commonFunctions/isValid';
import { makeStyles } from '@material-ui/styles';
import "slick-carousel/slick/slick.css"; // Import the carousel styles
import "slick-carousel/slick/slick-theme.css"; // Import the carousel theme styles
import Slider from 'react-slick';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { setCart, fetchCartItems, incrementItem, decrementItem , incQuantityAsync, decQuantityAsync } from '../redux/cartSlice';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';


const cookies = new Cookies();
// import { BootstrapInput } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    // icon: {
    //     color: '#727272 !important', 
    //     fontSize: '20px !important',
    //   },
    productAdd: {
        backgroundColor: '#EBF7F1 !important',
        borderTopRightRadius: '5px !important',
        borderBottomRightRadius: '5px !important',

    },
    productRemove: {
        backgroundColor: '#FAFAFA !important',
        borderTopLeftRadius: '5px !important',
        borderBottomLeftRadius: '5px !important',

    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    infoText: {
        position: 'absolute',
        top: '-90px',
        left: '-25%',
        transform: 'translate(30%, 0px)',
        padding: '10px',
        backgroundColor: '#fff',
        color: '#727272',
        borderRadius: '4px',
        fontSize: '14px !important',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        zIndex: '9',
        width: "200px",
        boxShadow: "10px 24px 54px rgba(0, 0, 0, .2)",
        lineHeight: "20px !important"
    },
    icon: {
        color: '#727272 !important',
        fontSize: '20px !important',
        cursor: 'pointer',
        '&:hover': {
            '& ~ $infoText': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    triangle: {
        content: '""',
        position: 'absolute',
        bottom: '-15px',
        left: '18%',
        transform: 'translateX(-50%) rotate(0deg)',
        borderTop: '16px solid #fff',
        borderLeft: '0px solid transparent',
        borderRight: '20px solid transparent',
    },
    categoryDropdown: {
        maxWidth: "300px",
        padding: "10px 15px",
        border: "1px solid var(--primary-color13) !important",
        fontSize: "14px !important",
        color: "var(--primary-color1) !important",
        fontWeight: "600 !important"
    },
    card: {
        border: "1px solid var(--primary-color13) !important",
        boxShadow: "none !important",
        borderRadius: "8px !important"
    },
    categoryDropdown: {
        maxWidth: "300px",
        padding: "10px 15px",
        border: "1px solid var(--primary-color13) !important",
        fontSize: "14px !important",
        color: "var(--primary-color1) !important",
        fontWeight: "600 !important"
    },
    productAdd: {
        backgroundColor: 'var(--primary-color13) !important',
        borderRadius: "0px !important",
        borderTopRightRadius: '5px !important',
        borderBottomRightRadius: '5px !important',
        minWidth: "26px !important",
        height: "32px !important",
    },
    productRemove: {
        backgroundColor: 'var(--primary-color13) !important',
        borderRadius: "0px !important",
        borderTopLeftRadius: '5px !important',
        borderBottomLeftRadius: '5px !important',
        minWidth: "26px !important",
        height: "32px !important",
    },
    quantityMain: {
        color: 'var(--primary-color1) !important',
        height: "32px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid var(--primary-color13) !important",
        borderRadius: "5px",
        margin: "10px 0px"
    },
    quantityInput: {
        padding: "0px 5px",
        color: 'var(--primary-color1) !important',
        fontSize: "16px !important",
        width: "35px",
        border: "none",
        textAlign: "center"
    },
    actionIcon: {
        fontSize: "16px !important",
        color: "var(--primary-color1) !important"
    },
    cartIcon: {
        marginRight: "12px"
    },
}));


function BuyProduct({item}) {
    const location = useLocation();
    const classes = useStyles();
    const [size, setSize] = useState('1');
    let [inputs, setInputs] = useState(([
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
    ]));
    let [num1, setNum1] = useState(0);
    let [num2, setNum2] = useState(0);
    const [expandedItems, setExpandedItems] = useState([]);
    const [barrelInfo, setBarrelInfo] = useState([]);
    const [checkInfo, setCheckInfo] = useState(false);
    const [cartInfo, setCartInfo] = useState([]);
    const [cartNotification, setCartNotification] = useState(false);
    const [accessoriesInfo, setAccessoriesInfo] = useState([]);
    const [cartItem, setCartItem] = useState();
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const [quantity, setQuantity] = useState({});
    const [quantities, setQuantities] = useState({});
    const dispatch = useDispatch();
    const [cartText, setCartText] = useState('');
    const [addedItemId, setAddedItemId] = useState(null);
    const cart = useSelector(state => state.cart);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [maxText, setMaxText] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredBarrelInfo, setFilteredBarrelInfo] = useState([]);
    const [filteredAccessoriesInfo, setFilteredAccessoriesInfo] = useState([]);
    const sectionRef = useRef(null); 
    // const [quantity, setQuantity] = useState({});
   
    let login = checkLogin();

    useEffect(() => {
        fetchList();
    }, []);

    
    useEffect(() => {
        fetchQuantity();
        fetchCategoryList();
        // fetchFilteredProductList("57572724-a5cc-4f7a-9b57-954d3f8bc76e");
    }, []);

    useEffect(() => {
          
        const params = new URLSearchParams(location.search);
        const sectionId = params.get('section');
    
        if (sectionId) {
          // Check if the ref has been assigned a value
          if (sectionRef.current) {
            // sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            const headerHeight = 100; // Set the height of your header
            const offset = sectionRef.current.getBoundingClientRect().top - headerHeight;
            
            window.scrollTo({
            top: offset,
            behavior: 'smooth',
            });
          }
        }

        return () => {
            window.scrollTo({
              top: 0,
              behavior: 'auto',
            });
          };
          
      }, [barrelInfo,accessoriesInfo]);

      //redux data code
      useEffect(() => {
        // Fetch cart items from the API for logged-in users
        if (login) {
          dispatch(fetchCartItems()); // Dispatch the action to fetch cart items from the API
        }
      }, [dispatch, login]);

        useEffect(() => {
        // Fetch cart items from cookies for non-logged-in users
        if (!login) {
            const cartItemsFromCookies = cookies.get('cartItems', {path: '/'});
            if (cartItemsFromCookies) {
            dispatch(setCart(cartItemsFromCookies)); // Dispatch the action to set cart items from cookies
            }
        }
        }, [dispatch, login]);


    const fetchList = async () => {
        try {
            // Fetch data from API
            let response = await Request.get('barrel/');
            if (response) {
                 
                setBarrelInfo(response.barrelList);
                setAccessoriesInfo(response.accessoriesList)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchQuantity = async () => {
        if (login === true) {
            try {
                let response = await Request.get('cart', [], true);
                  
                if (response) {
                    setCartInfo(response.cartItems);


                    const cartQuantity = {}
                    response.cartItems.forEach(item => {
                        cartQuantity[item.barrelId] = item.quantity;
                    });
                    setQuantity(cartQuantity);
                    // setAccessoriesInfo(response.accessoriesList)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }
        else {
            const existingCartItems = cookies.get('cartItems', {path: '/'}) || { cartItems: [] };
            // const cartItems = existingCartItems.cartItems;

            const cartQuantity = {}
            existingCartItems.cartItems.forEach(item => {
                cartQuantity[item.cartItemId] = item.quantity;
            });
            setQuantity(cartQuantity);
        }
    };


    const fetchCategoryList = async () => {
        try {
            const response = await Request.get("utils/getCategories/", []);
            if (response) {
                setCategoryList(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    // Custom CSS styles for the arrows
    const customArrowStyles = {
        display: 'block',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        fontSize: 24,
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '0px',
        borderRadius: '50%',
        cursor: 'pointer',
    };

    // Custom Next Arrow component
    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...customArrowStyles, right: 10 }}
                onClick={onClick}
            >
            </div>
        );
    };

    // Custom Previous Arrow component
    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...customArrowStyles, left: 10 }}
                onClick={onClick}
            >
            </div>
        );
    };

    // Slick carousel settings
    const sliderSettings = {
        dots: false, // Show dots (indicators)
        arrows: true, // Show navigation arrows
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,        // Enable autoplay
        // autoplaySpeed: 3000, 
        nextArrow: <CustomNextArrow />, // Custom Next Arrow component
        prevArrow: <CustomPrevArrow />, // Custom Previous Arrow component
    };

    // Function to handle "Read more" and "Read less" toggle
    const handleToggleExpand = (itemId) => {
        setExpandedItems((prevExpandedItems) => {
            if (prevExpandedItems.includes(itemId)) {
                return prevExpandedItems.filter((id) => id !== itemId);
            } else {
                return [...prevExpandedItems, itemId];
            }
        });
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
      };
    const handleContactPage = () => {
        window.location.href = '/contact-us'
    };

    const handleCategoryChange = (event) => {
        const selectedId = event.target.value;
        setSelectedCategory(selectedId);
        fetchFilteredProductList(selectedId)
    };

    const fetchFilteredProductList = async (id) => {
        setCheckInfo(true)
        if(id === "0"){
            fetchList()
        }else{
        try {
            // Fetch data from API
            const response = await Request.get(`barrel/product/category/`, [{"id" : id}]);
             
            if (response) {
                  
                
                setBarrelInfo(response.barrelList);
                setAccessoriesInfo(response.accessoriesList)
                
            } else {
                console.error('Failed to fetch data from API');
            }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }
    };


    let incQuantity = (id,maxLimit ) => {
        if(maxLimit > 10){
            var maxLimitNew = 10;
        }else{
            var maxLimitNew = maxLimit;
        }
          
        setQuantity((prevQuantity) => {
            const prevQuantity1 = prevQuantity[id] || 0;
            if (prevQuantity1 + 1 <= maxLimitNew) {
                const newQuantity = prevQuantity1 + 1;
                return { ...prevQuantity, [id]: newQuantity };
              } else {
                // Show an error dialog when the quantity exceeds the stock limit
                setDialogOpen(true);
                setMaxText(maxLimitNew)
                return prevQuantity;
              }
        });
    };

    let decQuantity = ( id,maxLimit) => {
        setQuantity((prevQuantity) => {
            const prevQuantity1 = prevQuantity[id] || 1;
            const newQuantity = Math.max(prevQuantity1 - 1, 1);
            return { ...prevQuantity, [id]: newQuantity };
        });
    }
    // const incQuantity = (cartItemId,quantity,maxQuantity) => {
    //       
    //     if (quantity < maxQuantity) {
    //     dispatch(incQuantityAsync(cartItemId));
    //     }
    //   };

    // const decQuantity = (cartItemId,quantity,maxQuantity) => {
    // if (quantity > 1) {
    //     dispatch(decQuantityAsync(cartItemId));
    // }
    // };

    const addProductToCart = async (productId,productPrice,productSize,productName,imgsrc) => {

        if(quantity[productId] === undefined){
            alert("Please add quantity");
            return
        }
        setTimeout(() => {
          setAddedItemId(null);
        }, 3000);
        // const productQuantity = quantity[productId] || 0; 
        if (login === true) {
            const cartItems = [];
            const productQuantity = quantity[productId] || 0; 
                const item = {
                    cartItemId: productId,
                    quantity: productQuantity,
                };

                if (item.quantity > 0) {
                    cartItems.push(item);
                }

            if(cartItems.length === 0){
                return
            }
            else {
                var items = { "cartItems": cartItems, "type": "Purchase" }
                let response = await Request.post("cart", items, true)
                {   
                    try {
                        if (response.message === "Success") {
                            setAddedItemId(productId);
                             
                            let data = await Request.get("cart", [], true)
                            {
                                try {
                                    if(data !== undefined){
                                        setCartNotification(true)
                                        dispatch(setCart(data));
                                    }
                                }
                                catch{

                                }
                            }
                            
                        }
                        else {
                            console.error('Failed to fetch data from API');
                        }
                    }
                    catch (error) {
                        console.error('Error fetching data from API:', error);
                    }
                }
            }
            
}

else {
      
       // Retrieve the existing cartItems from the cookie, if available
       const existingCartItems = cookies.get('cartItems', {path: '/'}) || { cartItems: [] };
       const cartItems = existingCartItems.cartItems;
   
       // Find the index of the item in the cart, if it exists
       const existingItemIndex = cartItems.findIndex(item => item.cartItemId === productId);
   
        // Get the quantity from the input field or set it to the existing quantity
        const productQuantityInput = quantity[productId] || 0;
        setAddedItemId(productId);

        // if(imgsrc === ''){
        //     imgsrc = '/assets/img/no_image_item.png'
        // }

        // const blob = await imgsrc.blob();
        // const reader = new FileReader();
        // var base64Image;

        // reader.onload = () => {
        //    base64Image = reader.result;
        // //   Cookies.set('product_image', base64Image); // Save the base64 image to a cookie
        // };
        
        // reader.readAsDataURL(blob);
    if (productQuantityInput > 0) {
          
        const updatedItem = {
            cartItemId: productId,
            quantity: productQuantityInput,
            'barrel': { 
                price: productPrice, 
                size: productSize, 
                name: productName,
                images: []
                // 'images': { img: base64Image } 
            },
            totalPrice: productPrice * productQuantityInput  
        };

        if (imgsrc !== undefined) {
            // Add imgsrc to the images array
            updatedItem.barrel.images.push({ img: imgsrc });
          }

        if (existingItemIndex !== -1) {
            // Update the existing item's quantity
            cartItems[existingItemIndex] = updatedItem;
        } else {
            // Add a new item to the cart
            
            cartItems.push(updatedItem);
        }

        // Calculate the totalPrice by summing up the productPrice multiplied by the quantity of each item in the cart
        // const totalConfirmPrice = cartItems.reduce((total, item) => total + item.barrel.price * item.quantity, 0);
        const totalCartPrice = cartItems.reduce((total, item) => total + item.totalPrice, 0);
    
        // Save the updated cartItems and totalPrice in the cookie
        // cartItems.totalPrice = totalPrice;
        var items = {"cartItems": cartItems, "totalCartPrice": totalCartPrice}
        // const items = { cartItems, totalPrice }; 
        cookies.set('cartItems', items , { path: '/', secure: true });
        dispatch(setCart(items));
        }
}
    }

    return (
        <>
            <Box className='orderContainer orderBoxContainer' sx={{pt: 10}} >
                {/* <Box>
                    <Typography variant='h5' className='color1 font-bold'>Empty Barrel</Typography>
                    <Box className={classes.headerFlexBox}>
                        <Typography className={classes.pathName}>Barrel</Typography><KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} /><Typography className={`${classes.pathName} ${classes.activePath}`}>Empty Barrel</Typography>
                    </Box>
                </Box> */}
                {/* <Box className='productContainer'> */}
                <Row id="barrels">
                    <Col xs={12} sm={12} md={6} lg={6} className='empty-barrels-title'>
                    {(barrelInfo && barrelInfo.length !== 0) && (
                        <Typography variant='h5' className='color1 font-bold'>Empty Barrels</Typography>
                    )}
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} align="right">
                        <Form.Select aria-label="Default select example" className={classes.categoryDropdown} value={selectedCategory} 
                        onChange={handleCategoryChange}>
                            <option value="0">All</option>
                            {/* {categoryList && (
                                            <> */}
                                            {(categoryList && categoryList.length>0) && categoryList.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                            {/* </>
                                        )} */}
                                      
                        </Form.Select>
                    </Col>
                </Row>
                {(barrelInfo && barrelInfo.length !== 0) && (
                    <Box sx={{pt: 5, pb:7}} >
                        <Box>
                            <Grid container spacing={3}>
                                {/* <Masonry className={classes.masonryContainer} columns={4} spacing={1}> */}
                                        {barrelInfo.map((item, index) => (
                                    
                                            <Grid item xs={12} md={4} lg={3} key={item.id}>
                                            
                                                    <Card className={classes.card}>
                                                        {item.images.length === 0 ? (
                                                            <a href={`/user/buy/buy-product-detail/${item.id}/barrel`} >
                                                            <Slider {...sliderSettings}>
                                                                <div className='productOuterBox'>
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt="No image Of Item Available"
                                                                        image="/assets/img/no_image_item.png"
                                                                        className='productListImg'
                                                                    />
                                                                </div>
                                                            </Slider>
                                                            </a>
                                                        ) : (
                                                            <a href={`/user/buy/buy-product-detail/${item.id}/barrel`} >
                                                            <Slider {...sliderSettings}>
                                                                {item.images.map((image, index) => (
                                                                    <div key={index} className='productOuterBox'>
                                                                        <CardMedia
                                                                            component="img"
                                                                            alt={`Image ${index + 1}`}
                                                                            image={`${process.env.REACT_APP_DatabaseStatic}${image.img}`}
                                                                            className='productListImg'
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                            </a>
                                                        )}

                                                        <CardContent>
                                                            <Typography className='font-18 color1 font-bold'>{item.name}</Typography>
                                                            {/* <Typography className='font-14 color1'>Size: <span className='font-14 color4 font-medium'>{item.size} GL</span></Typography> */}
                                                            <Typography className='font-14 color1 font-600'>Price: <span className='font-14 color4 font-medium'>${FormatPrice(item.discountedPrice)}</span></Typography>
                                                            {/* {item.discount && item.discount !== 0 && (
                                                                <Typography className='font-14 color1 font-600'><del className='font-14 color4 font-medium'>${item.price}</del></Typography>
                                                            )} */}
                                                            {item.description !== null ? (
                                                                <>
                                                                    <Typography className='font-14 color1 font-bold'>Product Description:</Typography>
                                                                    <Typography className={`font-13 color1 truncate-text productDescription ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>
                                                                        {expandedItems.includes(item.id) ? item.description : `${item.description.slice(0, 100)}`}
                                                                        {item.description.length > 100 && (
                                                                            <span
                                                                                className="read-more-link font-13 color4 productDescription"
                                                                                onClick={() => handleToggleExpand(item.id)}
                                                                            >
                                                                                {expandedItems.includes(item.id) ? ' Read less' : ' Read more...'}
                                                                            </span>
                                                                        )}
                                                                    </Typography>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}

        
                                                            <Box className="flexBox">
                                                                <Typography className='color1 font-12 font-bold'>Qty :</Typography>
                                                                <Box>
                                                                    <Box className={classes.quantityMain}>
                                                                        {item.stockManagement === false ? (
                                                                            <>
                                                                                <Box disabled={quantity[item.id] <= 1} min="1" onClick={() => decQuantity(item.id, 1)}>
                                                                                    <Button className={classes.productRemove} disabled={quantity[item.id] <= 1}>
                                                                                        <RemoveIcon className={classes.actionIcon} />
                                                                                    </Button>
                                                                                </Box>
                                                                                <input type="text" className={classes.quantityInput} value={quantity[item.id] || 0} readOnly></input>
                                                                                <Box onClick={() => incQuantity(item.id, 10)} disabled={quantity[item.id] >= 10}>
                                                                                    <Button className={classes.productAdd}>
                                                                                        <AddIcon className={classes.actionIcon} />
                                                                                    </Button>
                                                                                </Box>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                            {item.stock === 0 ? (
                                                                                    <Typography className='font-20 font-600 color4'>Item Out Of Stock</Typography>
                                                                                ):(
                                                                                <>
                                                                                    <Box disabled={quantity[item.id] <= 1} min="1" onClick={() => decQuantity(item.id, 1)}>
                                                                                        <Button className={classes.productRemove} disabled={quantity[item.id] <= 1}>
                                                                                            <RemoveIcon className={classes.actionIcon} />
                                                                                        </Button>
                                                                                    </Box>
                                                                                    <input className={classes.quantityInput} value={quantity[item.id] || 0} readOnly></input>
                                                                                    <Box onClick={() => incQuantity(item.id, item.stock)}>
                                                                                        <Button className={classes.productAdd}>
                                                                                            <AddIcon className={classes.actionIcon} />
                                                                                        </Button>
                                                                                    </Box>
                                                                                </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </Box> 

                                                            <Box align="center" className="cartBtnBox">
                                                                <Button className='b1 b4' sx={{ mt: 2, mb: 3 }} id={item.id} onClick={() => addProductToCart(item.id,item.discountedPrice,item.size,item.name,item.images[0].img)}>
                                                                    <ShoppingCartOutlinedIcon className={classes.cartIcon} />Add to Cart
                                                                </Button>
                                                                {addedItemId === item.id && <Typography className='adsCartMsg'>Product Added to Cart</Typography>}
                                                            </Box>


                                                        </CardContent>
                                                    </Card>
                                            </Grid>
                                        ))}
                            </Grid>
                        </Box>
                    </Box>
                )}
                {/* </Box> */}
                {(accessoriesInfo && accessoriesInfo.length !== 0) && (
                    <Box ref={sectionRef} id="accessories">
                        <Box>
                            <Typography variant='h5' className='color1 font-bold'>Accessories</Typography>
                        </Box>
                        <Box sx={{pt: 5}}>
                            <Grid container spacing={3}>
                                {/* <Masonry className={classes.masonryContainer} columns={4} spacing={1}> */}
                                {(accessoriesInfo && accessoriesInfo.length>0) &&
                                    <>
                                        {accessoriesInfo.map((item, index) => (
                                            <Grid item xs={12} md={4} lg={3} key={item.id}>
                                                {/* <a href={`/user/buy/buy-product-detail/${item.id}`} > */}
                                                        <Card className={classes.card}>
                                                            {item.images.length === 0 ? (
                                                            <a href={`/user/buy/buy-product-detail/${item.id}/accessories`} >
                                                                <Slider {...sliderSettings}>
                                                                    <div className='productOuterBox'>
                                                                        <CardMedia
                                                                            component="img"
                                                                            alt="No image Of Item Available"
                                                                            image="/assets/img/no_image_item.png"
                                                                            className='productListImg'
                                                                        />
                                                                    </div>
                                                                </Slider>
                                                            </a>
                                                            ) : (
                                                                <a href={`/user/buy/buy-product-detail/${item.id}/accessories`} >
                                                                <Slider {...sliderSettings}>
                                                                    {item.images.map((image, index) => (
                                                                        <div key={index} className='productOuterBox'>
                                                                            <CardMedia
                                                                                component="img"
                                                                                alt={`Image ${index + 1}`}
                                                                                image={`${process.env.REACT_APP_DatabaseStatic}${image.img}`}
                                                                                className='productListImg'
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Slider>
                                                                </a>
                                                            )}

                                                            <CardContent>
                                                                <Typography className='font-18 color1 font-bold'>{item.name}</Typography>
                                                                {/* <Typography className='font-14 color1'>Size: <span className='font-14 color4 font-medium'></span></Typography> */}
                                                                <Typography className='font-14 color1 font-600'>Price: <span className='font-14 color4 font-medium'>${FormatPrice(item.discountedPrice)}</span></Typography>
                                                                {item.description !== null ? (
                                                                    <>
                                                                        <Typography className='font-14 color1 font-bold'>Product Description:</Typography>
                                                                        <Typography className={`font-13 color1 truncate-text productDescription ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>
                                                                            {expandedItems.includes(item.id) ? item.description : `${item.description.slice(0, 100)}`}

                                                                            {item.description.length > 100 && (
                                                                                <span
                                                                                    className="read-more-link font-13 color4 productDescription"
                                                                                    onClick={() => handleToggleExpand(item.id)}
                                                                                >
                                                                                    {expandedItems.includes(item.id) ? ' Read less' : ' Read more...'}
                                                                                </span>
                                                                            )}
                                                                        </Typography>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}


                                                                <Box className="flexBox">
                                                                    <Typography className='color1 font-12 font-bold'>Qty :</Typography>
                                                                    <Box>
                                                                    <Box className={classes.quantityMain}>
                                                                            {item.stockManagement === false ? (
                                                                                <>
                                                                                    <Box disabled={quantity[item.id] <= 1} min="1" onClick={() => decQuantity(item.id, 1)}>
                                                                                        <Button className={classes.productRemove} disabled={quantity[item.id] <= 1}>
                                                                                            <RemoveIcon className={classes.actionIcon} />
                                                                                        </Button>
                                                                                    </Box>
                                                                                    <input className={classes.quantityInput} value={quantity[item.id] || 0} readOnly></input>
                                                                                    <Box onClick={() => incQuantity(item.id, 10)}>
                                                                                        <Button className={classes.productAdd}>
                                                                                            <AddIcon className={classes.actionIcon} />
                                                                                        </Button>
                                                                                    </Box>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                {item.stock === 0 ? (
                                                                                    <Typography className='font-20 font-600 color4'>Item Out Of Stock</Typography>
                                                                                ):(
                                                                                    <>
                                                                                        <Box disabled={quantity[item.id] <= 1} min="1" onClick={() => decQuantity(item.id, 1)}>
                                                                                            <Button className={classes.productRemove} disabled={quantity[item.id] <= 1}>
                                                                                                <RemoveIcon className={classes.actionIcon} />
                                                                                            </Button>
                                                                                        </Box>
                                                                                        <input className={classes.quantityInput} value={quantity[item.id] || 0} readOnly></input>
                                                                                        <Box onClick={() => incQuantity(item.id, item.stock)} disabled={quantity[item.id] >= 10}>
                                                                                            <Button className={classes.productAdd}>
                                                                                                <AddIcon className={classes.actionIcon} />
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </>
                                                                                )}
                                                                                </>
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>

                                                                <Box align="center" className="cartBtnBox">
                                                                    <Button className='b1 b4' sx={{ mt: 2, mb: 3 }} id={item.id} onClick={() => addProductToCart(item.id,item.discountedPrice,item.size,item.name,item.images[0].img)}>
                                                                        <ShoppingCartOutlinedIcon className={classes.cartIcon} />Add to Cart
                                                                    </Button>
                                                                    {(addedItemId === item.id && cartNotification === true) && 
                                                                        <Typography className='adsCartMsg'>Product Added to Cart</Typography>
                                                                    }
                                                                </Box>

                                                            </CardContent>
                                                        </Card>
                                                {/* </a> */}
                                            </Grid>
                                        ))}
                                    </>
                                }


                            </Grid>
                        </Box>
                    </Box>
                )}
                
                {checkInfo && (
                    <>
                    {(barrelInfo && barrelInfo.length === 0 && accessoriesInfo && accessoriesInfo.length === 0) && (
                    <Box sx={{pt: 5,mb: 7}}>
                        {/* <Box className="emptyProductBox"> */}
                            <Typography className='font-20 color4 font-600'>No Product Available</Typography>
                        {/* </Box> */}
                    </Box>
                )}  
                    </>
                )}
               

                {/* Material UI Dialog for showing the error */}
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogContent>
                        The quantity cannot exceed {maxText}
                    </DialogContent>
                    <Button onClick={handleCloseDialog}>OK</Button>
                    <Button onClick={handleContactPage}>Contact Us For Bulk Order</Button>
                </Dialog>
            </Box>
        </>
    )
};


export default BuyProduct;

