import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
import ProductCategoriesTable from "./ProductCategoriesTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderAdminList, fetchWeeklyDeals } from "../redux/islanderListSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function WeeklyDealsList() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const weeklyDealList = useSelector((state) => state.islanderList.weeklyDeals);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

  
    useEffect(() => {
        dispatch(fetchWeeklyDeals());
    }, [dispatch]);

    
    useEffect(() => {
        dispatch(fetchWeeklyDeals());
    }, []);

    const openDeleteModal = (id) => {
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          if (file.type === 'application/pdf') {
            setSelectedFile(file);
          } else {
            alert('Please select a PDF file.');
            event.target.value = ''; // Clear the input field
          }
        }
    }

    const productDelete = async () => {
		try {
			const response = await Request.delete(`admin/weeklyDeals/${selectedProduct}`, [], true);
			if (response) {
				if (response.status === 204) {
					setIsDeleteDialogOpen(false)
					dispatch(fetchWeeklyDeals())
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (selectedFile) {
          let accessToken = cookies.get('accessToken', { path: '/' });
          const formdata = new FormData();
          formdata.append('file', selectedFile);

          const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_DatabaseServer}admin/weeklyDeals`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    handleCloseDialog();
                    dispatch(fetchWeeklyDeals())
                }
                else{
                    console.log('Error: Unexpected status code', response.status);
                }
            })
            .catch(error => {
                console.log('error', error);
            });

    }
    else{
        alert("Please Add PDF.")
    }
}

    return (
        <Box className="adminInnerPage pickupLocationsPage">
            <div className="title-button__container">
                <Typography variant="h2" className="adminInnerPage__title">
                    Weekly Deals
                </Typography>
                <Button
					variant="primary"
					color="primary"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add File
				</Button>

                <Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>

				<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Add File
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Form className="edit-order-form add-product-form add-category-form add-location-form">
                                <Form.Group className="form-group col-md-6 add-category-form__field">
                                 <Form.Label htmlFor="file">Add PDF file</Form.Label>
                                    <Form.Control
                                        type="file"
                                        id="file"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                        required
                                    />
                                </Form.Group>
							</Form>
                            {selectedFile && (
                                <div>
                                    <span className="font-16 color2 font-600">Selected PDF file:</span> <span className="font-15 color2 pl-2">{selectedFile.name}</span>
                                </div>
                            )}
						</Box>
					</DialogContent>
					</form>
				</Dialog>
            </div>

            <div className="productListPage__container">

                <Box className="order-table-container product-categories-table-container">
                    <TableContainer
                        component={Paper}
                        className="product-categories-table"
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="textLeft">File</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(weeklyDealList && weeklyDealList.length>0) && weeklyDealList.map((item) => (
                                    <TableRow
                                        key={item.id}
                                    >
                                        <TableCell className="textLeft">
                                            <a href={`${process.env.REACT_APP_DatabaseStatic}${item.weeklyDeals}`} target="_blank" className="color1 font-bold text-underline">{item.imgValue}</a>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="Delete"
                                                onClick={() => openDeleteModal(item.id)} // Replace with your delete logic
                                            >
                                                <DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <p>Are you sure you want to delete this Subscriber?</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => productDelete(selectedProduct)} color="primary">
                                OK
                            </Button>
                            <Button onClick={closeDeleteDialog} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </div>
        </Box>
    );
}

export default WeeklyDealsList;
