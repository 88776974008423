import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Avatar, Button } from "@mui/material";
import DriverForm from "./DriverForm";
import Request from '../customUtils/Http/Request';
import { Form } from "react-bootstrap";
import Cookies from 'universal-cookie';
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const cookies = new Cookies();

function DriverProfile() {
	const [driverProfileInfo, setDriverProfileInfo] = useState({});
	const [selectedImage, setSelectedImage] = useState(null);
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [imgBox, setImageBox] = useState('')
	const [selectedFile, setSelectedFile] = useState(null);
	const [licenceId, setLicenceId] = useState(null);
	const [confirmUpdateMsg, setConfirmUpdateMsg] = useState("");
	const [driverDetail, setDriverDetail] = useState({
		id: '',
		vehicle: '',
		vehicleRegNo: '',
		img: null
	});

	useEffect(() => {
		fetchUserData()
	}, []);

	const fetchUserData = async () => {
		try {
            const response = await Request.get("driver/details/", [], true);
            if (response.length !== 0) {
                setDriverProfileInfo(response)
				setDriverDetail({
					id: response.id,
					vehicle: response.vehicle,
					vehicleRegNo: response.vehicleRegNo,
					img: response.user?.img
				  });

            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return error;
            }
	}

	const handleImageChange = (e) => {
		const selectedImage1 = e.target.files[0];
        if(selectedImage1){
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(selectedImage1);
        }

        // const selectedImage = e.target.files[0];
        // if (selectedImage) {
        //   const imageUrl = URL.createObjectURL(selectedImage);
        //   setDriverDetail({
        //     ...driverDetail,
        //     img: selectedImage,
        //   });
        // }
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDriverDetail({
          ...driverDetail,
          [name]: value,
        });
      };

	  const userDataSave = async (id) => {
		if(selectedImage){
            var convertedImage = dataURItoBlob(selectedImage);
        }
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('vehicle', driverDetail.vehicle);
        formdata.append('vehicleRegNo', driverDetail.vehicleRegNo);
        formdata.append('profileImageFile', convertedImage);
        
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/driver/update/${id}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}driver/update/${id}`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then((responseData) => {
                if (responseData.message === 'Success') {
					setConfirmUpdateMsg("Image Successfully Updated")
                    setTimeout(() => {
                        setConfirmUpdateMsg("")
                    }, 2000);
                    fetchUserData()
                  }else{
					alert("Something went wrong");
				  }
            })
            .catch(error => console.log('error', error));
        }

		const dataURItoBlob = (dataURI) => {
			const byteString = atob(dataURI.split(',')[1]);
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
		  
			for (let i = 0; i < byteString.length; i++) {
			  ia[i] = byteString.charCodeAt(i);
			}
		  
			return new Blob([ab], { type: mimeString });
		};

		const openDialog = (id) => {
			setOpenImageDialog(true)
			setLicenceId(id)
		}

		const handleCloseImageDialog = () =>{
			setOpenImageDialog(false)
		}

		const handleImgBox = (e) => {
			const selectedFile = e.target.files[0];
			if (selectedFile) {
				// Check if the selected file is an image or PDF
				if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'application/pdf') {
					setImageBox(selectedFile);
					setSelectedFile(selectedFile);
				} else {
					alert('Please select a valid image (JPEG, PNG) or PDF file.');
				}
			  }
		  };

		  const addImg = async (id) => {
			if(imgBox){
				let accessToken = cookies.get('accessToken', { path: '/' });
				const formdata = new FormData();
				formdata.append('licenseImage', imgBox);
				
				const headers = {
					'Authorization': `Bearer ${accessToken}`,
				};
		
				var requestOptions = {
					method: 'PUT',
					headers: headers,
					body: formdata,
					redirect: 'follow'
				};
		
				// fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/islander/iAddShipmentImage/${id}`, requestOptions)
				fetch(`${process.env.REACT_APP_DatabaseServer}driver/update/${id}`, requestOptions)
					.then(response => {
						return response.json();
					})
					.then((responseData) => {
						if (responseData.message === 'Success') {
							setOpenImageDialog(false)
							setConfirmUpdateMsg("License Successfully Updated")
							setTimeout(() => {
								setConfirmUpdateMsg("")
							}, 2000);
							setTimeout(() => {
								cookies.remove("accessToken", {path: "/"});
								cookies.remove("refreshToken", {path: "/"});
								cookies.remove("userType", {path: "/"});
								fetchUserData()
							}, 1000);
						
							// setConfirmUpdateMsg2("License Image Successfully Updated")
							
						  }else{
							alert("Something went wrong");
						  }
					})
					.catch(error => console.log('error', error));
			}else{
				alert("Please select valid image")
			}
			
		}

	return (
		<Box className="adminInnerPage driverProfilePage">
			<Typography variant="h2" className="adminInnerPage__title">
				My Profile
			</Typography>

			<div className="driver-form__container">
				<div className="driver-form">
					{/* <form className="driverform"> */}
						<div className="driver-form__form">
							{/* <DriverForm driver={driverProfileInfo}/> */}
								<Box className="driverform">
									<div className="driverform__fields">
										<Form.Group controlId="name" className="driverform__field">
											<Form.Label>Name</Form.Label>
											<Form.Control
												type="text"
												name="name"
												value={driverProfileInfo.user?.fullName}
												// onChange={handleInputChange}
												readOnly
											/>
										</Form.Group>
										<Form.Group controlId="phone" className="driverform__field">
											<Form.Label>Phone</Form.Label>
											<Form.Control
												type="text"
												name="phone"
												value={driverProfileInfo.user?.phone}
												// onChange={handleInputChange}
												readOnly
											/>
										</Form.Group>
										<Form.Group controlId="email" className="driverform__field">
											<Form.Label>Email ID</Form.Label>
											<Form.Control
												type="email"
												name="email"
												value={driverProfileInfo.user?.email}
												// onChange={handleInputChange}
												readOnly
											/>
										</Form.Group>
										<Form.Group controlId="vehicleRegNo" className="driverform__field">
											<Form.Label>Vehicle No.</Form.Label>
											<Form.Control
												type="text"
												name="vehicleRegNo"
												value={driverDetail.vehicleRegNo}
												onChange={handleInputChange}
											/>
										</Form.Group>

										<Form.Group controlId="vehicle" className="driverform__field">
											<Form.Label>Vehicle</Form.Label>
											<Form.Control
												type="text"
												name="vehicle"
												value={driverDetail.vehicle}
												onChange={handleInputChange}
											/>
										</Form.Group>
										<Form.Group controlId="identityProof" className="driverform__field">
											{/* <Form.Label>Identity Proof</Form.Label>

											<Avatar
												alt="License"
												src={driverProfileInfo.driverIdentification}
												variant="rounded"
												sx={{ width: 200, height: 150 }}
												className="center-avatar"
											/> */}
										</Form.Group>

										<Form.Group controlId="License" className="driverform__field edit_parent_box">
											<Form.Label>License</Form.Label>
											<Avatar
												alt="License"
												src={driverProfileInfo.driverLicense ? `${process.env.REACT_APP_DatabaseStatic}${driverProfileInfo.driverLicense}` : "/assets/img/no_image_item.png"}
												variant="rounded"
												sx={{ width: 200, height: 150 }}
												className="center-avatar"
											/>
											<EditIcon className="edit_icon_main" onClick={() => openDialog(driverProfileInfo.user?.id)}/>
											
										</Form.Group>
										<Form.Group controlId="identityProof" className="driverform__field edit_parent_box">
											<Form.Label>Identity Proof</Form.Label>
											<Avatar
												alt="License"
												// src={driverProfileInfo.driverIdentification}
												src={driverProfileInfo.driverIdentification ? `${process.env.REACT_APP_DatabaseStatic}${driverProfileInfo.driverIdentification}` : "/assets/img/no_image_item.png"}
												variant="rounded"
												sx={{ width: 200, height: 150 }}
												className="center-avatar"
											/>
										</Form.Group>
									
									</div>
								</Box>
						</div>
						<div className="driver-form__photo">
							<Avatar
								alt={driverProfileInfo.user?.fullName}
								src={selectedImage || `${process.env.REACT_APP_DatabaseStatic}${driverDetail.img}` || "/assets/img/driver-placeholder.jpg"}
								// src={driverDetail.img === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driverDetail.img}`}
								variant="rounded"
								sx={{ width: 234, height: 234 }}
								className="center-avatar"
							/>

							{/* <Button color="primary" className="btn">
								Change Photo
							</Button> */}
							<div className='mt-4'>
								<input type="file" id="upload" accept="image/*" onChange={handleImageChange} hidden/>
								<label className="photolLabel" for="upload">Change Photo</label>
							</div>
						</div>
						
					{/* </form> */}
				</div>
				<Box sx={{textAlign: "left",'@media (max-width: 600px)': {textAlign: "center"},}}>
					<Button type="submit" className='b1 b3 font-bold mt-4' onClick={() => userDataSave(driverDetail.id)}>Save</Button>
					<Typography className='font-15 color4 pt-2 font-600'>{confirmUpdateMsg}</Typography>
				</Box>
			</div>

			<Modal
                            open={openImageDialog}
                            onClose={handleCloseImageDialog}
                            aria-labelledby="modal-title"
                            className="admin-modal"
                            maxWidth="300px"
                        >
                            <Box className="admin-modal__container box-modal__container">
                                <Box className="admin-modal__title-container">
                                    <Typography variant="h6" className="admin-modal__title" align="center">Upload License Image</Typography>
                                </Box>
                                <Box className="edit-order-form shipping-order-form">
                                    <div className='order-summary-form__fields'>
                                   
                                        <Form.Group
                                            controlId="image"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Upload Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="image"
                                                accept=".jpg, .jpeg, .png, .pdf, .svg"
                                                onChange={handleImgBox}
                                                required
                                            />
                                        </Form.Group>
                                        {selectedFile && (
                                            <Typography className='font-15 color4'>Selected Image: {selectedFile.name}</Typography>
                                        )}
                                    </div>
                                    <Box align="center" sx={{ pt: 4 }}>
                                        <Button color="primary" className="b1 b5 colo2" onClick={() => addImg(licenceId)}>
                                            Add
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Modal>
		</Box>
	);
}

export default DriverProfile;
