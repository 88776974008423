import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Request from '../customUtils/Http/Request';
import Header from '../header/header';
import Footer from '../footer/footer';
import CMSFooter from '../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const PrivacyPolicy = () => {
    const [listData, setListData] = useState({
        "description": ''
    });

    useEffect(() => {
        fetchPolicyDescription()
    }, []);

    const fetchPolicyDescription = async () =>{
        try {
            // Fetch data from API
            const response = await Request.get(`utils/legalPolicy`, []);
            if (Object.keys(response).length!== 0) {
                 
                setListData({
                    description: response.description,
                })
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const DatabaseResponseComponent = ({ htmlContent }) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        );
    };


    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Privacy Policy</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5, pb:4 }} textAlign="left">
                        <div className="row privacy_block">    
                            <div className="MuiBox-root css-pxpt32">
                                <DatabaseResponseComponent htmlContent={listData.description} />
                            </div>
                        </div> 
                        {/* <div class="row">
                            <div class="MuiBox-root css-pxpt32">
                                <Typography textAlign="left" className='color4 font-bold font-roboto font-22 '>Effective September 1, 2023 </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>At Isle of Freight your privacy is important to us, we acknowledge that your personal information should be kept in a confidential manner. We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below.
                                </Typography>

                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>Please read the following to learn more about our Privacy Policy. By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you consent to us collecting the following data when you perform certain functions. </Typography>

                                <Typography textAlign="left" className='color4 font-bold font-roboto font-22 '>Purpose of Privacy Policy </Typography>

                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>The purpose of this privacy policy is to inform users of our site how their personal information is being used, gathered, and stored when they visit or make a purchase. </Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>How do we use your personal information</Typography>
                                <Typography textAlign="left" className='color2 font-19 '>
                                    <ul>
                                        <li>The information collected may be used for various purposes such as:  </li>
                                        <li>Providing and improving our services, products, and user experience.  </li>
                                        <li>Processing and fulfilling orders, shipments, and transactions. </li>
                                        <li>Responding to your inquiries, feedback, and requests for assistance. </li>
                                        <li>Sending you relevant updates, newsletters, and promotional offers (you can opt out of these communications at any time).  </li>
                                        <li>Customizing and personalizing your experience on the Website.  </li>
                                        <li>Conducting data analysis and research to improve our services and offerings.  </li>
                                        <li>Complying with legal obligations and enforcing our terms of use and policies. </li>
                                    </ul>
                                </Typography>


                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Children Privacy </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>We do not purposely collect or use any information from children under 13 years of age. If we learn that we have collected personal data from a child under age 13, the personal data will be deleted as soon as possible. If you believe or know a child under 13 years of age has provided us with their personal data their parent or legal guardian should contact our privacy officer.</Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Sharing your personal information</Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>We may share your personal information with third parties as described below. We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this privacy policy. </Typography>


                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Advertising </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>Your personal information is used to help us with targeted advertising and marketing communication or promotional offers tailored to suit your needs.</Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>Finally in the following circumstances we may share your personal information if it is required for any legal proceeding, search warrants, and to prove or protect our legal rights.</Typography>


                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Personal information we collect </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                    <ul>
                                        <li>When you interact with the Website, you may voluntarily provide us with certain personal information, which may include:</li>
                                        <li>Contact Information: Your name, email address, phone number, and mailing address.</li>
                                        <li>Account Information: Usernames, passwords, and other credentials used to create and access your account.</li>
                                        <li>Shipping Information: Addresses and contact details used for shipping and delivery purposes.</li>
                                        <li>Communication Data: Records of your interactions with our customer support, feedback, inquiries, and communication preferences.</li>
                                        <li>User-Generated Content: Any content you create, upload, or share on the Website, such as reviews and comments.</li>
                                        <li>Social Media Information: Information from your social media profiles if you choose to interact with our Website using your social media accounts.</li>
                                    </ul>

                                </Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Automatically Collected Information  </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                    <ul>
                                        <li>In addition to the information you provide, we may automatically collect certain data when you use the Website, including: </li>
                                        <li>Device Information: Technical information about your device, such as IP address, browser type, and operating system.</li>
                                        <li>Usage Data: Information about your interactions with the Website, including pages visited, links clicked, and actions taken.</li>
                                        <li>Cookies and Similar Technologies: We may use cookies, web beacons, and other tracking technologies to collect information about your browsing behavior and preferences.</li>
                                    </ul>

                                </Typography>


                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>What Personal Information can I access?</Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                    <p>Through your account settings, you may access, and, in some cases, edit or delete the following information you’ve provided to us: </p>
                                    <ul>
                                        <li>Name and Password  </li>
                                        <li>Email Address</li>
                                        <li>User profile information, including billing and shipping addresses, customer addresses, and payment methods.</li>
                                    </ul>

                                </Typography>


                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Your Rights </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                    You have certain rights regarding your personal information, including the right to access, correct, update, or delete your information. You can manage your communication preferences by adjusting your account settings or contacting us directly.

                                </Typography>

                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Changes </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                    We may update this policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically to ensure you are notified about any updates.</Typography>


                                <Typography textAlign="left" className='color4  font-bold font-roboto font-22 '>Contact information </Typography>
                                <Typography sx={{ mb: 3 }} textAlign="left" className='color2 font-19 '>
                                    If you have any questions or concerns about this policy or our data practices, please contact us at <a href="mailto:support@isleoffreight.com"> support@isleoffreight.com</a></Typography>
                            </div>
                        </div> */}
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
