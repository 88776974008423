import React, {useEffect, useState} from "react";
import { Modal, Box, Typography, Avatar, Button } from "@mui/material";
import DriverForm from "./DriverForm";
import DriverPendingOrdersTable from "./DriverPendingOrdersTable";
import Request from '../customUtils/Http/Request';
import DriverCompletedOrdersTable from "./driverCompletedOrderTable";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { approveDriver, pendingDriver, rejectedDriver, updateDriverStatus } from "../redux/driverSlice";
import { fetchApproveReview, fetchPendingReview, fetchRejectedReview, updateReviewStatus } from "../redux/reviewSlice";
import Rating from '@mui/material/Rating';


const ReviewModal = ({ isOpen, handleClose, review, reviewRating, reviewInfo, type, reviewId }) => {
	const [selectedReviewInfo, setSelectedReviewInfo] = useState([]); 
	const dispatch = useDispatch();

	// const selectedIndex = reviewInfo.findIndex((item) => item.id === reviewId);
	// const selectedReview = reviewInfo[selectedIndex]; 

	if (!review) {
		return null;
	}

	const approveReviewStatus = async (id) => {
		dispatch(updateReviewStatus({ id: id, status: true }))
				.then((result) => {
                      
					if (result.payload.status === 200) {
						dispatch(fetchApproveReview());
						dispatch(fetchPendingReview());
						dispatch(fetchRejectedReview());
						handleClose();
					} else {
					}
				})
				.catch((error) => {
					console.error("Error updating customer:", error);
				});
    };

	const rejectReviewStatus = async (id) => {
		dispatch(updateReviewStatus({ id: id, status: false }))
				.then((result) => {
                      
					if (result.payload.status === 200) {
                        dispatch(fetchApproveReview());
						dispatch(fetchPendingReview());
						dispatch(fetchRejectedReview());
						handleClose();
					} else {
					}
				})
				.catch((error) => {
					console.error("Error updating customer:", error);
				});
    };




	return (
		<Modal open={isOpen} onClose={handleClose} className="admin-modal">
			<Box className="admin-modal__container review_container">
				<div className="admin-modal__title-container">
					<Typography variant="h6" className="admin-modal__title">
						{type} review
					</Typography>
				</div>

				<div className="driver-form">
					<div className="driver-form__photo">
                        <Box className="" textAlign="left">
                            <span className="font-bold color2">Review Description: </span>
                            <span className="font-15 color1"> {review}</span>
                        </Box>
                        <Box className="flexBoxDash" sx={{pt: 3}}>
                            <Typography className="font-bold color2">Rating: </Typography>
                            <Box> 
                                <Rating
                                    name="simple-controlled"
                                    value={reviewRating}
                                    size="large"
                                    readOnly 
                                    className="rating d-flex"
                                />
                            </Box>
                        </Box>
							<Box sx={{mt: 3}} textAlign="left">
						{type === "Approved" && (
							<Button className="b1 b4" onClick={() => rejectReviewStatus(reviewId)}>Reject</Button>
						)}
						{type === "Pending for approval" && (
							<Box className="flexBox">
								<Button className="b1 b5" onClick={() => approveReviewStatus(reviewId)}>Approve</Button>
								<Button className="b1 b4" onClick={() => rejectReviewStatus(reviewId)}>Reject</Button>
							</Box>
						)}
							{type === "Rejected" && (
							<Button className="b1 b5" onClick={() => approveReviewStatus(reviewId)}>Approved</Button>
						)}
					</Box>	
					</div>
				
				</div>


			</Box>
		</Modal>
	);
};

export default ReviewModal;
