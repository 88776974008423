import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Popover, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TableSortLabel,
} from "@mui/material";
import CustomerForm from "./customerOrderEditForm";
import OrderSummaryForm from "./orderSummaryForm";
import YourOrderTable from "./yourOrderTable";
import ShippingForm from "./shippingOrderForm";
import Request from '../customUtils/Http/Request';
import StatusList from "./statusList";
import OrderDetailEdit from "./orderDetailEdit";
import IslanderOrderDetailEdit from "./IslanderOrderEdit";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllOrderList, fetchCanceledOrderList, fetchDeliveredOrderList, fetchIslanderOrderList,
fetchPickupOrderList, fetchShippingOrderList } from "../redux/orderListSlice";

const cookies = new Cookies();

const orders = [
	{
		orderId: "Ord#2878",
		orderDate: "2023-08-15",
		customerName: "Alice",
		item: "Product A",
		payment: 100,
		status: "Pending",
	},
	{
		orderId: "Ord#5353",
		orderDate: "2023-08-14",
		customerName: "Bob",
		item: "Product B",
		payment: 150,
		status: "Completed",
	},
];

function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
  }

export const SortableTable = ({ info, selectedTab }) => {
	const dispatch = useDispatch();
	const [sortedField, setSortedField] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");
	const [islanderDetail, setIslanderDetail] = useState([]);
	const [islanderDetail2, setIslanderDetail2] = useState([]);
	const [orderDetail, setOrderDetail] = useState({});
	const [open, setOpen] = useState(false);
	const [orderId, setOrderId] = useState(null);
	const [type, setType] = useState(false);
	const [statusAnchorEl, setStatusAnchorEl] = useState(null);
	const [islanderStatus, setIslanderStatus] = useState(false);
  	const [selectedStatus, setSelectedStatus] = useState('');
	const [selectedStatusNumber, setSelectedStatusNumber] = useState(-1);
	// const [shippingInfo, setShippingInfo] = useState([]);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [updatedTab, setUpdatedTab] = useState(null);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);

	// const [open2, setOpen2] = useState(false);
	const [PaymentAnchorEl, setPaymentAnchorEl] = useState(null);
	const [PaymentSelectedStatus, setPaymentSelectedStatus] = useState(null);
	const [PaymentSelectedId, setPaymentSelectedId] = useState(null);
	const [PaymentSelectedOrderType, setPaymentSelectedOrderType] = useState(null);
	const [imgBox, setImageBox] = useState('')
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [savedData, setSavedData] = useState({
		orderStatus: '',
		trackingNo: '',
		driver: '',
		bol: '',
		// Add other fields as needed with default values
	  });
	const [orderInfo, setOrderInfo] = useState({
        trackingNo: '',
        bol: null,
        status: '',
        driver: ''
      });

	const handleSort = (field) => {
		const isAsc = sortedField === field && sortDirection === "asc";
		setSortDirection(isAsc ? "desc" : "asc");
		setSortedField(field);

		
	};
    const handleCloseImageDialog = () =>{
        setOpenImageDialog(false)
    }

	const handleImgBox = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Check if the selected file is an image or PDF
            if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'application/pdf') {
                setImageBox(selectedFile);
                setSelectedFile(selectedFile);
            } else {
                alert('Please select a valid image (JPEG, PNG) or PDF file.');
            }
          }
      };

	  const addImg = async (id) => {
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('boxImage', imgBox);
        
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/islander/iAddShipmentImage/${id}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}admin/islander/iAddShipmentImage/${id}`, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    return response.json().then(data => {
                        throw new Error("Server Error");
                    });
                } else {
                    return response.json();
                }
            })
            .then(responseData => {
                changeStatus(selectedItemId, 3)
                setImageBox(false)
                window.location.reload();
            })
            .catch(error => console.log('error', error));
    }
	// const handleOpen = () => {
	// 	setOpen(true);
	// };

	const handlePaymentStatusClick = (event, paymentStatus, id, orderType) => {
		setPaymentAnchorEl(event.currentTarget);
		setPaymentSelectedStatus(paymentStatus);
		setPaymentSelectedId(id);
		setPaymentSelectedOrderType(orderType);
	  };
	
	  const handlePaymentStatusClose = () => {
		setOpen(false);
		setPaymentAnchorEl(null)
	  };

	const handleStatusClick = (event, statusNumber, id, type) => {
		setStatusAnchorEl(event.currentTarget);
		setSelectedItemId(id);
		fetchIslanderOrderDetail2(id)
		
    	setSelectedStatusNumber(statusNumber);
		if(type === 'Islander Box'){
			setIslanderStatus(true)
		}
		else{
			setIslanderStatus(false)
		}
	  };

	  const fetchIslanderOrderDetail2 = async (id) => {
		try {
			let response = await Request.get(`admin/islander/iBoxOrderedItem/${id}`, [], true);
			if (Object.keys(response).length !== 0) {
				setIslanderDetail2(response)
				// window.location.reload();
			} else {
				console.error('Failed to fetch data from API');
			}
		}
		catch (error) {
			console.error('Error fetching data from API:', error);
		}
	};
	  
	  const handleStatusClose = () => {
		setStatusAnchorEl(null);
	  };
  
	  const PaymentHandleStatusSelect = async (status) => {
		handlePaymentStatusClose();
		changePaymentStatus(PaymentSelectedId, status)
	  }

	  const changePaymentStatus = async (id, status) => {
		const item = {
			"paymentStatus": status
		}

		try {
			let response = await Request.put(`admin/order/edit/${id}`, item, true);
			if (response.length !== 0) {
			  window.location.reload();
			//   setIslanderDetail(response);
			} else {
			  console.error('Failed to fetch data from API');
			}
		  } catch (error) {
			console.error('Error fetching data from API:', error);
		  }
		}
	  


	const handleClose = () => {
		setOpen(false);
	};

	const handleEdit = (id, type, selectedTab) => {
		setOpen(id);
		if(type === "Islander"){
			setOrderId(id)
			setType(type)
			// fetchIslanderOrderDetail(id)
			fetchOrderDetail(id, selectedTab)
		}
		else if(type === "Send"){
			setOrderId(id)
			setType(type)
			fetchOrderDetail(id)
		}
		else if(type === "Islander Box" && selectedTab !== 2){
			setOrderId(id)
			setType(type)
			fetchOrderDetail(id, selectedTab)
		}
		else if(type === "Islander Box" && selectedTab === 2){
			setOrderId(id)
			setType(type)
			fetchIslanderOrderDetail(id, selectedTab)
		}
		else{
			setOrderId(id)
			setType(type)
			fetchOrderDetail(id)
		}
		
		// const selected = customers.find(customer => customer.id === id)
		// console.log(selected)
		// setEditModalOpen(id);
		// setSelectedCustomer(selected);
	};

	const fetchIslanderOrderDetail = async (id, tab) => {
        try {
            let response = await Request.get(`admin/islander/iBoxOrderedItem/${id}`, [], true);
            if (Object.keys(response).length !== 0) {
				setIslanderDetail(response)
				// window.location.reload();
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const fetchOrderDetail = async (id, selectedTab) => {
        try {
			let response = await Request.get(`order/order_detail/${id}`, [], true);
            if (response.length !== 0) {
				setOrderDetail(response)
				// window.location.reload();
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const openDeleteModal = (id) => {
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

	const handleStatusSelect = (statusNumber) => {
		// Use selectedItemId and statusNumber to perform actions or API call
		if(statusNumber === 8){
			openDeleteModal(selectedItemId)
		}
		else{
			if(islanderStatus === true && statusNumber === 3){
				setOpenImageDialog(true)
			}else if (islanderStatus === true && statusNumber === 6 && islanderDetail2?.shipmentBox?.length === 0) {
				alert('please Enter Box Detail.')
				return
			}
			else{
				changeStatus(selectedItemId, statusNumber)
			}
		}
		

		// if(islanderStatus === true){
			
		// }
		// else{
		// 	changeStatus2(selectedItemId, statusNumber)
		// }
	  };

	  const changeStatus = async (id, statusNumber) => {
		const item = {
			"status": statusNumber
		}
		const item2 = {
			"orderStatus": statusNumber
		}

		if(islanderStatus === true) {
			{
				const updateStatus = async () => {
				  try {
					let response = await Request.put(`admin/islander/iStatusUpdate/${id}`, item, true);
					if (response.length !== 0) {
					  dispatch(fetchShippingOrderList())
					  dispatch(fetchPickupOrderList())
					  dispatch(fetchIslanderOrderList())
					  dispatch(fetchDeliveredOrderList())
					  dispatch(fetchCanceledOrderList())
					  dispatch(fetchAllOrderList())
					} else {
					  console.error('Failed to fetch data from API');
					}
				  } catch (error) {
					console.error('Error fetching data from API:', error);
				  }
				};
			  
				// Call the async function
				updateStatus();
		}
			
      
    }
	else{
		{
			const updateStatus = async () => {
				try {
				  let response = await Request.put(`admin/order/edit/${id}`, item2, true);
				  if (response.length !== 0) {
					dispatch(fetchShippingOrderList())
					dispatch(fetchPickupOrderList())
					dispatch(fetchIslanderOrderList())
					dispatch(fetchDeliveredOrderList())
					dispatch(fetchCanceledOrderList())
					dispatch(fetchAllOrderList())
					
				  } else {
					console.error('Failed to fetch data from API');
				  }
				} catch (error) {
				  console.error('Error fetching data from API:', error);
				}
			  };
			
			  // Call the async function
			  updateStatus();
		}

	};
}

	const handleSaveButtonClick = async () => {
		// Construct the data to send to the API
		const dataToSend = {
		  trackingNo: orderInfo.trackingNo,
		  orderStatus: orderInfo.orderStatus,
		  driver: orderInfo.driver,
		  bol: orderInfo.bol,
		  // other fields
		};
	
		try{
		// Trigger the API call with dataToSend
		let response = await Request.put(`admin/order/edit/${selectedItemId}`, dataToSend, true);
		if (response.length !== 0) {
			// closeModal()
		  //   setIslanderDetail(response);
		  } else {
			console.error('Failed to fetch data from API');
		  }
		} catch (error) {
		  console.error('Error fetching data from API:', error);
		}
	  };

	// shippingInfo.sort((a, b) => {
	// 	const aValue = a[sortedField];
	// 	const bValue = b[sortedField];

	// 	if (sortDirection === "asc") {
	// 		return aValue > bValue ? 1 : -1;
	// 	} else {
	// 		return aValue < bValue ? 1 : -1;
	// 	}	
	// });
	const orderItems = [
		{ productName: "Product A", quantity: 2, price: "$10.00" },
		{ productName: "Product B", quantity: 1, price: "$25.00" },
		// Add more order items as needed
	];

	const getStatusText = (statusNumber) => {
		if (statusNumber === 0) return 'Incomplete';
		if (statusNumber === 1) return <span className="orderReceived">Order Received</span>;
		if (statusNumber === 2) return 'Ready to Pickup';
		if (statusNumber === 3) return <span className="pickedUp">Picked Up</span>;
		if (statusNumber === 4) return <span className="readyToShip">Ready to Ship</span>;
		if (statusNumber === 5) return 'In-Transit';
		if (statusNumber === 6) return 'Delivered';
		if (statusNumber === 7) return 'Canceled';
		if (statusNumber === 8) return 'Delete';
	  };

	  const getIslanderStatusText = (statusNumber) => {
		if (statusNumber === 0) return <span className="orderReceived">Order Received</span>;
		if (statusNumber === 1) return <span className="itemReceiving">Item Receiving</span>;
		if (statusNumber === 2) return <span className="packing">Packing</span>;
		if (statusNumber === 3) return 'Space Available';
		if (statusNumber === 4) return <span className="fillingSpace">Filling Space</span>;
		if (statusNumber === 5) return 'Packed';
		if (statusNumber === 6) return <span className="readyToShip">Ready to Ship</span>;
		if (statusNumber === 7) return 'Payment Received - Qty Change';
		if (statusNumber === 8) return 'Payment Received - No Qty Change';
	  };

	  const handleSaveToDatabase = async () => {
		const dataFromChild = window.saveDataFromChild();
		const dataToSend = {
			trackingNo: dataFromChild.trackingNo,
			orderStatus: dataFromChild.orderStatus,
			driver: dataFromChild.driver,
			bol: dataFromChild.bol,
		  };
	  
		  try{
		  // Trigger the API call with dataToSend
		  let response = await Request.put(`admin/order/edit/${selectedItemId}`, dataToSend, true);
		  if (response.length !== 0) {
			  console.log(response, 'changePaymentStatusInfo');
			
			} else {
			  console.error('Failed to fetch data from API');
			}
		  } catch (error) {
			console.error('Error fetching data from API:', error);
		  }
	  }

	  const updateOrderDetail = (updatedOrderDetail) => {
		setOrderDetail(updatedOrderDetail);
	  };

	  const productDelete = async (id) => {
		try {
			const response = await Request.delete(`admin/order/edit/${id}`, [], true);
			if (response) {
				if (response.status === 204) {
					setIsDeleteDialogOpen(false)
					// dispatch(fetchShippingOrderList())
					// dispatch(fetchPickupOrderList())
					// dispatch(fetchIslanderOrderList())
					// dispatch(fetchDeliveredOrderList())
					// dispatch(fetchCanceledOrderList())
					dispatch(fetchAllOrderList())
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	  

	//   const setDataToDatabase = async (editableData) => {
	// 	// console.log(savedData)
	// 	// const data= {
	// 	// 	savedData
	// 	// }
    //     try {
	// 		let response = await Request.put(`admin/order/edit/${selectedItemId}`, editableData, true);
	// 		if (response.length !== 0) {
	// 			console.log(response, 'change Info')
	// 			// setOrderDetail(response)
    //         } else {
    //             console.error('Failed to fetch data from API');
    //         }
    //     }
    //     catch (error) {
    //         console.error('Error fetching data from API:', error);
    //     }
    // };



	return (
		<>
		<div className="order-table-container">
			<TableContainer component={Paper}>
				<Table className="sortable-table">
					<TableHead>
						<TableRow>
							<TableCell>
								<TableSortLabel
									active={sortedField === "orderId"}
									direction={sortDirection}
									onClick={() => handleSort("orderId")}
								>
									Order ID
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "orderDate"}
									direction={sortDirection}
									onClick={() => handleSort("orderDate")}
								>
									Order Date
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "customerName"}
									direction={sortDirection}
									onClick={() => handleSort("customerName")}
								>
									Customer Name
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "item"}
									direction={sortDirection}
									onClick={() => handleSort("item")}
								>
									Item
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "payment"}
									direction={sortDirection}
									onClick={() => handleSort("payment")}
								>
									Payment
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "status"}
									direction={sortDirection}
									onClick={() => handleSort("status")}
								>
									Status
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{info && info.length>0 && 
							<>
								{info.map((order) => (
									<TableRow key={order.id}>
										<TableCell className="cursor-pointer font-bold text-underline" onClick={() => handleEdit(order.id, order.orderType, selectedTab)}>{order.invoiceNo}</TableCell>
										<TableCell>{formatDate(order.createdAt)}</TableCell>
										<TableCell>{order.user?.fullName || ''}</TableCell>
										{/* <TableCell>
											{order.user === null ? "null" : order.user.fullName}
										</TableCell> */}
											{order.orderType === "Purchase" && (
												<TableCell>Packaging</TableCell>
											)}
											{order.orderType === "Send" && (
												<TableCell>Send</TableCell>
											)}
											{order.orderType === "Islander" && (
												<TableCell>Islander Box</TableCell>
											)}
											{order.orderType === "Islander Box" && (
												<TableCell>Islander Box</TableCell>
											)}
											<>
											{order.orderType === "Islander Box" ? (
												<TableCell>
													{order.paymentStatus === true ? "Received" : "Pending"}
												</TableCell>
											):(
												<TableCell onClick={(e) => handlePaymentStatusClick(e, order.paymentStatus, order.id, order.orderType )}>
													{order.paymentStatus === true ? "Received" : "Pending"}
												</TableCell>
											)}
											</>
											{order.orderType === "Islander Box" ? (
												<TableCell onClick={(e) => handleStatusClick(e, order.orderStatus, order.id, order.orderType )}>
													{getIslanderStatusText(order.orderStatus)}
												</TableCell>	
											):(
												<TableCell onClick={(e) => handleStatusClick(e, order.orderStatus, order.id, order.orderType )}>
													{getStatusText(order.orderStatus)}
												</TableCell>
										)}
									</TableRow>
								))}
							</>
						}
					</TableBody>
				</Table>
				{islanderStatus === true ? (
					<StatusList anchorEl={statusAnchorEl} onClose={handleStatusClose} statuses={[
						{text: 'Order Accepted', number: 0},
						{text: 'Item Receiving', number: 1},
						{text: 'Packing', number: 2},
						{text: 'Space Available', number: 3},
						{text: 'Filling Space', number: 4},
						{text: 'Packed', number: 5}, 
						{text: 'Ready To Ship', number: 6}, 
						{text: 'Payment Received - Qty Change', number: 7},
						{text: 'Payment Received - No Qty Change', number: 8},
						// {text: 'cancelled', number: 9},
					]} onSelectStatus={handleStatusSelect} />
				):(
					<StatusList anchorEl={statusAnchorEl} onClose={handleStatusClose} statuses={[
						{text: 'Incomplete', number: 0},
						{text: 'Order Received', number: 1},
						{ text: 'Ready to Pickup', number: 2 },
					// 	{ text: 'Picked Up', number: 3 },
					// 	...(type === "Send"
					// ? [
					// 	{ text: 'Ready to Pickup', number: 2 },
					// 	{ text: 'Picked Up', number: 3 },
					// 	]
					// : []),
						{ text: 'Picked Up', number: 3 },
						{text: 'Ready to Ship', number: 4},
						{text: 'In-Transit', number: 5}, 
						{text: 'Delivered', number: 6}, 
						{text: 'Canceled', number: 7},
						{text: 'Delete', number: 8},
					]} onSelectStatus={handleStatusSelect} />
				)}

		<Popover
        open={Boolean(PaymentAnchorEl)}
        anchorEl={PaymentAnchorEl}
        onClose={handlePaymentStatusClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItem button onClick={() => PaymentHandleStatusSelect(false)}>
            <ListItemText primary="Pending" className='popOverText'/>
          </ListItem>
          <ListItem button onClick={() => PaymentHandleStatusSelect(true)}>
            <ListItemText primary="Received" className='popOverText'/>
          </ListItem>
        </List>
      </Popover>	
				
			</TableContainer>
		</div>
		
		<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-title"
				className="admin-modal"
			>
				{type === "Islander" || type === "Islander Box" ? (
					<>
						
						{selectedTab === 2 ? (
							<IslanderOrderDetailEdit orderId={orderId} islanderDetail={islanderDetail} selectedTab={selectedTab} orderType={type} updateOrderDetail={updateOrderDetail} setOpen2={setOpen}/>
						):(
							<IslanderOrderDetailEdit orderId={orderId} islanderDetail={orderDetail} selectedTab={selectedTab} orderType={type} updateOrderDetail={updateOrderDetail} setOpen={setOpen}/>
						)}
					</>
					
			
				):(
					<OrderDetailEdit orderId={orderId} orderDetail={orderDetail} selectedTab={selectedTab} orderType={type} updateOrderDetail={updateOrderDetail} setOpen={setOpen}/>
				)}
				
			</Modal>

			<Modal
                            open={openImageDialog}
                            onClose={handleCloseImageDialog}
                            aria-labelledby="modal-title"
                            className="admin-modal"
                            maxWidth="300px"
                        >
                            <Box className="admin-modal__container box-modal__container">
                                <Box className="admin-modal__title-container">
                                    <Typography variant="h6" className="admin-modal__title" align="center">Upload Image Of Box</Typography>
                                </Box>
                                <Box className="edit-order-form shipping-order-form">
                                    <div className='order-summary-form__fields'>
                                   
                                        <Form.Group
                                            controlId="image"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Upload Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="image"
                                                accept=".jpg, .jpeg, .png, .pdf, .svg"
                                                onChange={handleImgBox}
                                                required
                                            />
                                        </Form.Group>
                                        {selectedFile && (
                                            <Typography className='font-15 color4'>Selected Image: {selectedFile.name}</Typography>
                                        )}
                                    </div>
                                    <Box align="center" sx={{ pt: 4 }}>
                                        <Button color="primary" className="b1 b5 colo2" onClick={() => addImg(islanderDetail2.id)}>
                                            Add
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Modal>

						{/* delete dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this Order?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export const SortableTableInner = ({ data, selectedTab, setOpen2 }) => {
	const [sortedField, setSortedField] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");
	const [editedData, setEditedData] = useState({});
	const dispatch = useDispatch();

	const handleSort = (field) => {
		const isAsc = sortedField === field && sortDirection === "asc";
		setSortDirection(isAsc ? "desc" : "asc");
		setSortedField(field);
	};



	// const sortedData = data.sort((a, b) => {
	// 	const aValue = a[sortedField];
	// 	const bValue = b[sortedField];

	// 	if (sortDirection === "asc") {
	// 		return aValue > bValue ? 1 : -1;
	// 	} else {
	// 		return aValue < bValue ? 1 : -1;
	// 	}
	// });

	const handleEdit = (rowIndex) => {
		// Create a copy of the edited quantities state and set edit mode for the specific row
		const updatedEditedData = { ...editedData };
		const order = data[rowIndex];
		updatedEditedData[rowIndex] = {
		  quantity: order.receivedQuantity,
		  description: order.description,
		  itemValue: order.itemValue,
		};
		setEditedData(updatedEditedData);
	  };
	
	  const handleInputChange = (event, rowIndex) => {
		const { name, value } = event.target;

		// Update the edited data for the specific row as the user types
		const updatedEditedData = { ...editedData };
		const order = data[rowIndex];
		if (!updatedEditedData[rowIndex]) {
		  updatedEditedData[rowIndex] = {
			quantity: order.receivedQuantity,
			description: order.description,
			itemValue: order.itemValue,
		  };
		}
		updatedEditedData[rowIndex][name] = value;
		setEditedData(updatedEditedData);
	  };

	  const handleSave = async (rowIndex, event) => {	
		if (editedData[rowIndex] !== undefined) {
		//   const updatedQuantity = parseInt(editedQuantities[rowIndex], 10);
		const updatedQuantity = parseInt(editedData[rowIndex].quantity, 10);
		const updatedDescription = editedData[rowIndex].description;
		const updatedValue = editedData[rowIndex].itemValue;
	
		//   if (!isNaN(updatedQuantity)) {
			// Send the updated quantity and ID to your API here
			const itemId = data[rowIndex].id;
			const item = {
				"quantity":updatedQuantity,
				"description":updatedDescription,
				"value":updatedValue,
			}
			try {
				let response = await Request.put(`admin/islander/iBoxInvoiceStatus/${itemId}`, item, true);
				if (response.length !== 0) {
				  setOpen2(false)
				//   window.location.reload();
				  
				//   dispatch(fetchShippingOrderList())
				//   dispatch(fetchPickupOrderList())
				//   dispatch(fetchIslanderOrderList())
				//   dispatch(fetchDeliveredOrderList())
				//   dispatch(fetchCanceledOrderList())
				//   dispatch(fetchAllOrderList())
				//   setIslanderDetail(response);
				} else {
				  console.error('Failed to fetch data from API');
				}
			  } catch (error) {
				console.error('Error fetching data from API:', error);
			  }
			// }
		  }
		  // Exit edit mode for this row
		  const updatedEditedData = { ...editedData };
		  delete updatedEditedData[rowIndex];
		  setEditedData(updatedEditedData);
	  };

	return (
		<div className="order-table-container">
			<TableContainer component={Paper}>
				<Table className="sortable-table">
					<TableHead>
						<TableRow>
							<TableCell>
								<label
									// active={sortedField === "orderId"}
									// direction={sortDirection}
									// onClick={() => handleSort("orderId")}
								>
									Company
								</label>
							</TableCell>
							<TableCell>
								<label
									// active={sortedField === "orderDate"}
									// direction={sortDirection}
									// onClick={() => handleSort("orderDate")}
								>
									Invoice
								</label>
							</TableCell>
							<TableCell>
								<label
									// active={sortedField === "detail"}
									// direction={sortDirection}
									// onClick={() => handleSort("detail")}
								>
									Description
								</label>
							</TableCell>
							<TableCell>
								<label
									// active={sortedField === "value"}
									// direction={sortDirection}
									// onClick={() => handleSort("value")}
								>
									ItemValue
								</label>
							</TableCell>
							<TableCell>
								<label
									// active={sortedField === "trackingNo"}
									// direction={sortDirection}
									// onClick={() => handleSort("trackingNo")}
								>
									Tracking No
								</label>
							</TableCell>
							<TableCell>
								<label
									// active={sortedField === "qtyOrdered"}
									// direction={sortDirection}
									// onClick={() => handleSort("qtyOrdered")}
								>
									Qty Ordered
								</label>
							</TableCell>
							<TableCell>
								<label
									// active={sortedField === "qtyReceived"}
									// direction={sortDirection}
									// onClick={() => handleSort("qtyReceived")}
								>
									Qty Received
								</label>
							</TableCell>
							{/* <TableCell>
								<label
									active={sortedField === "payment"}
									direction={sortDirection}
									onClick={() => handleSort("payment")}
								>
									Quantity 
								</label>
							</TableCell> */}
							<TableCell>
								<label
									// active={sortedField === "status"}
									// direction={sortDirection}
									
								>
									Status
								</label>
							</TableCell>
							{selectedTab === 2 && (
								<TableCell></TableCell>
							)}
							
						</TableRow>
					</TableHead>
					<TableBody>
						{(data && data.length>0) && (
							<>
								{data.map((order,rowIndex) => (
									<TableRow key={order.id}>
										<TableCell>{order.url}</TableCell>
										<TableCell>
											{/* invoice */}
											<a className="color4 font-bold" href={`${process.env.REACT_APP_DatabaseStatic}${order.invoice}`} target="_blank">
												View Invoice
											</a>
										</TableCell>
										<TableCell>
											{editedData[rowIndex] !== undefined ? (
													<input
													type="text"
													name="description"
													value={editedData[rowIndex].description}
													onChange={(event) => handleInputChange(event, rowIndex)}
													className="editText"
													/>
												) : (
													<span>{order.description}</span>
												)}
										</TableCell>
										<TableCell>
										{editedData[rowIndex] !== undefined ? (
												<input
												type="number"
												name="itemValue"
												value={editedData[rowIndex].itemValue}
												onChange={(event) => handleInputChange(event, rowIndex)}
												className="editText editText2"
												/>
											) : (
												<span>{order.itemValue}</span>
											)}
										</TableCell>
										<TableCell>{order.trackingNumber}</TableCell>
										<TableCell>{order.orderedQuantity}</TableCell>
										<TableCell>
											{editedData[rowIndex] !== undefined ? (
												<input
												type="number"
												name="quantity"
												value={editedData[rowIndex].quantity}
												onChange={(event) => handleInputChange(event, rowIndex)}
												className="editText editText2"
												// onKeyDown={(event) => handleEnterKey(rowIndex, event)}
												/>
											) : (
												<span>{order.receivedQuantity}</span>
											)}
										</TableCell>
										<TableCell>
											{order.status === false ? "Awaiting Items" : "Received"}
											{/* {order.status === 1 && "Pending"}
											{order.status === 1 && "Ready to Pickup"}
											{order.status === 1 && "Pending"}
											{order.status === 1 && "Pending"}
											{order.status === 1 && "Pending"} */}
										</TableCell>
										{selectedTab === 2 && (
										<TableCell>
										{editedData[rowIndex] !== undefined ? (
													<button onClick={() => handleSave(rowIndex)}>Save</button>
												) : (
													// Edit button
													<button onClick={() => handleEdit(rowIndex)}>Edit</button>
												)}
										</TableCell>
									)}
										
									</TableRow>
								))}
							</>
						)}
						
					</TableBody>
				</Table>
			</TableContainer>
			
			 
		</div>
	);
};

export const SortableTableInnerSmall = ({ data }) => {
	const [sortedField, setSortedField] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");

	const handleSort = (field) => {
		const isAsc = sortedField === field && sortDirection === "asc";
		setSortDirection(isAsc ? "desc" : "asc");
		setSortedField(field);
	};

	const sortedData = data.sort((a, b) => {
		const aValue = a[sortedField];
		const bValue = b[sortedField];

		if (sortDirection === "asc") {
			return aValue > bValue ? 1 : -1;
		} else {
			return aValue < bValue ? 1 : -1;
		}
	});

	return (
		<div className="order-table-container">
			<TableContainer component={Paper}>
				<Table className="sortable-table">
					<TableHead>
						<TableRow>
							<TableCell>
								<TableSortLabel
									active={sortedField === "orderId"}
									direction={sortDirection}
									onClick={() => handleSort("orderId")}
								>
									Size
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "orderDate"}
									direction={sortDirection}
									onClick={() => handleSort("orderDate")}
								>
									Weight
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "customerName"}
									direction={sortDirection}
									onClick={() => handleSort("customerName")}
								>
									Qty
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "item"}
									direction={sortDirection}
									onClick={() => handleSort("item")}
								>
									Shipping Price
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel
									active={sortedField === "payment"}
									direction={sortDirection}
									onClick={() => handleSort("payment")}
								>
									&nbsp;
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{(sortedData && sortedData.length>0) && (
						<>
							{sortedData.map((order) => (
								<TableRow key={order.orderId}>
									<TableCell>{order.orderId}</TableCell>
									<TableCell>{order.orderDate}</TableCell>
									<TableCell>{order.customerName}</TableCell>
									<TableCell>{order.item}</TableCell>
									<TableCell>{order.payment}</TableCell>
								</TableRow>
							))}
						</>
					)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

const FilterDropdownList = () => {
	const [selectedOption, setSelectedOption] = useState("");

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	return (
		<select value={selectedOption} onChange={handleOptionChange}>
			<option value="">Select an option</option>
			<option value="option1">Option 1</option>
			<option value="option2">Option 2</option>
			<option value="option3">Option 3</option>
		</select>
	);
};


const Orders = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [shippingInfo, setShippingInfo] = useState([]);
	const [pickupInfo, setPickupInfo] = useState([]);
	const [islanderInfo, setIslanderInfo] = useState([]);
	const [deliveredInfo, setDeliveredInfo] = useState([]);
	const [canceledInfo, setCanceledInfo] = useState([]);
	const [allInfo, setAllInfo] = useState([]);
	const dispatch = useDispatch();
	
	const list1 = useSelector((state) => state.order.orderShippingList);
	const list2 = useSelector((state) => state.order.orderPickupList);
	const list3 = useSelector((state) => state.order.orderIslanderList);
	const list4 = useSelector((state) => state.order.orderDeliveredList);
	const list5 = useSelector((state) => state.order.orderCanceledList);
	const list6 = useSelector((state) => state.order.allOrderList);

	// useEffect(() => {
	// 	fetchData();
	// }, []);

	useEffect(() => {
		switch (selectedTab) {
			case 0:
				dispatch(fetchShippingOrderList())
				break;
			case 1:
				dispatch(fetchPickupOrderList());
				break;
			case 2:
				dispatch(fetchIslanderOrderList());
				break;
			case 3:
				dispatch(fetchDeliveredOrderList());
				break;
			case 4:
				dispatch(fetchCanceledOrderList());
				break;
			case 5:
				dispatch(fetchAllOrderList());
				break;
			default:
				dispatch(fetchShippingOrderList())
				break;
		  }
	}, [selectedTab]);

	const fetchData = async () => {
        try {
            let response = await Request.get('admin/orders/', [], true);
            if (response.length !== 0) {
				setShippingInfo(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const fetchData2 = async () => {
        try {
            let response = await Request.get('admin/order/pickupOrder', [], true);
            if (response.length !== 0) {
				setPickupInfo(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
	const fetchData3 = async () => {
        try {
            let response = await Request.get('admin/islander/iBoxOrderedItemList/', [], true);
            if (response.length !== 0) {
				setIslanderInfo(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
	const fetchData4 = async () => {
        try {
            let response = await Request.get('admin/order/deliveredOrder', [], true);
            if (response.length !== 0) {
				setDeliveredInfo(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
	const fetchData5 = async () => {
        try {
            let response = await Request.get('admin/order/cancelledOrder', [], true);
            if (response.length !== 0) {
				setCanceledInfo(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
	const fetchData6 = async () => {
        try {
            let response = await Request.get('admin/orders/all', [], true);
            if (response.length !== 0) {
				setAllInfo(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<>
			<Box className="adminInnerPage orderpage">
				<Typography variant="h2" className="adminInnerPage__title">
					Orders
				</Typography>

				<Box className="adminInnerPage driverListPage">
					<Box className="adminInnerPage__tabs">
						<Tabs value={selectedTab} onChange={handleTabChange}>
							<Tab label="Shipping" />
							<Tab label="Pick Up" />
							<Tab label="Islander Box" />
							<Tab label="Delivered" />
							<Tab label="Cancelled" />
							<Tab label="All Orders" />
						</Tabs>
					</Box>

					<Box className="adminInnerPage__tabs-content">
					<Typography component="div" role="tabpanel">
								<div className="order-search-filter">
									<div className="order-search-filter__search">
										{/* <input
											type="text"
											placeholder="Search for Order ID, Customer, ISL#"
										/> */}
									</div>
									<div className="order-search-filter__filter">
										<div className="order-search-filter__filter-icon">
											<FilterAltOutlinedIcon />
										</div>
										<div className="order-search-filter__filter-dropdown">
											<FilterDropdownList />
										</div>
									</div>
								</div>
						{selectedTab === 0 && (
							<>
								<SortableTable info={list1} selectedTab={selectedTab}/>
							</>
						)}
						{selectedTab === 1 && (
							<>
								<SortableTable info={list2} selectedTab={selectedTab}/>
							</>
						)}
						{selectedTab === 2 && (
							<>
								<SortableTable info={list3} selectedTab={selectedTab}/>
							</>
						)}
						{selectedTab === 3 && (
							<>
								<SortableTable info={list4} selectedTab={selectedTab}/>
							</>
						)}
						{selectedTab === 4 && (
							<>
								<SortableTable info={list5} selectedTab={selectedTab}/>
							</>
						)}
						{selectedTab === 5 && (
							<>
								<SortableTable info={list6} selectedTab={selectedTab}/>
							</>
						)}
					</Typography>
					</Box>
				</Box>
			</Box>


		</>
	);
};

export default Orders;
