import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import { Container, Typography, Box, TextField, Button, Link, FormHelperText, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import https from '../../customUtils/Http/Request';
import { } from 'react-bootstrap';
import { checkLogin } from '../../commonFunctions/commonFunctions';
import ManageOrder from '../../product/buyProduct';
import Product from '../../product/buyProduct';
import BuyProduct from '../../product/buyProduct';
import { Dashboard } from '@mui/icons-material';
import  ReCAPTCHA  from 'react-google-recaptcha';
// import { Row, Col, Container } from 'react-bootstrap';
// import { Avatar, Typography } from '@mui/material';

const cookies = new Cookies();

const CssTextField = styled(TextField)({
  // '& label':{
  //   fontSize: '15px !important',
  //   fontWeight: 'bold !important',
  //   color: '#033D1E',
  // },
  '& label.Mui-focused': {
    color: '#033D1E',
    fontSize: '15px !important',
    fontWeight: 'bold !important',
    lineHeight: '25px !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#033D1E',
  },

  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    // "&.MuiOutlinedInput-notchedOutline": {
    //   fontSize: "14px",
    // },
    '&:hover fieldset': {
      borderColor: '#FFC72C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFC72C',
      borderWidth: '1px',
    },
  },
});


function DriverSignup() {

  const [data, setData] = useState([])

  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [licenseImage, setLicenseImage] = useState(null);
  const [idImage, setIdImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const recaptchaRef = useRef();
  const [btnDisable, setBtnDisable] = useState(false);
  const [otpBlock, setOtpBlock] = useState(false);
  const [signupBlock, setSignupBlock] = useState(true);
  const [phoneError, setPhoneError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [recaptchaSize, setRecaptchaSize] = useState(windowWidth > 450 ? 'normal' : 'compact');
  const [signupData, setSignupData] = useState({
    "firstName" : '',
    "lastName" : '',
    "email" : '',
    "phone": '',
    "password": '',
    "vehicle": '',
    'vehicleRegNo': ''
});

  let login = checkLogin();

  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setRecaptchaSize(window.innerWidth > 450 ? 'normal' : 'compact');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleChange = (event) => {
    setPassword(event.target.value);
    setError(!isValidPassword(event.target.value));
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleLicenseImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setLicenseImage(selectedFile);
  };

  const handleIdImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setIdImage(selectedFile);
  };

  const handleProfileImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setProfileImage(selectedFile);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (email !== confirmEmail) {
      setEmailError('Emails do not match');
    } else {
      setEmailError('');
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleConfirmEmailChange = (event) => {
    const confirmEmail = event.target.value;
    setConfirmEmail(confirmEmail);

    if (email !== confirmEmail) {
      setEmailError('Emails do not match');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    if(login === true){
      navigate('/user/customer/dashboard')
    }
    else{
      navigate('/driver/signup')
    }

  }, []);

  const otpChange = (event) => {
    setOtp(event.target.value);
  };

  const isValidPassword = (value) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordPattern.test(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (phoneNumber.length === 10 && /^[0-9]+$/.test(phoneNumber)) {
      let inputField = event.target.getElementsByTagName('input');
      let firstname = inputField[0],
        lastname = inputField[1],
        email = inputField[2],
        confirmEmail = inputField[3],
        phone = inputField[4],
        password = inputField[5],
        confirmPassword = inputField[6],
        vehicle = inputField[7],
        vehicleRegNo = inputField[8],
        licenseImage1 = licenseImage,
        idImage1 = idImage,
        profileImage1 = profileImage;


    if (password.value !== confirmPassword.value) {
      setTimeout(() => {
        $('#errorMessage').fadeIn('slow').delay(4000).hide(0);
      }, 4000);
      return
    }

    if (email.value !== confirmEmail.value) {
      setTimeout(() => {
        $('#errorMessage2').fadeIn('slow').delay(4000).hide(0);
      }, 0);
      return
    }

    if(firstname !== '' && lastname !== '' && email !== '' && phone !== '' && password !== '' && vehicle !== '' && vehicleRegNo !== '' && licenseImage1 !== '' && idImage1 !== '' && profileImage1 !== ''){
      setBtnDisable(true)
      setSignupData({
        "firstName" : firstname.value,
        "lastName" : lastname.value,
        "email" : email.value,
        "phone": phone.value,
        "password": password.value,
        "vehicle": vehicle.value,
        "vehicleRegNo": vehicleRegNo.value,
        
      });

      try {
        let response = await https.post('user/signUpVerification', {"email" : email.value,"firstName": firstname.value, "phone": phone.value, "captchaValue": recaptchaValue} );
        if (response) {
            if(response.message === "Success"){
              setSignupBlock(false)
              setOtpBlock(true)
              setBtnDisable(false)
              setRecaptchaValue('')
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
            }
            else{
              setPhoneError(response.message)
              setTimeout(() => {
                setPhoneError('')
              }, 3000);
              setBtnDisable(false)
              setRecaptchaValue('')
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
            }
            // setDriverList(response)
        } else {
            console.error('Failed to fetch data from API');
        }
      }
      catch (error) {
          console.error('Error fetching data from API:', error);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
          setRecaptchaValue('')
      }
        }
    else{
      alert("Something Went Wrong")
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setRecaptchaValue('')
    }
  }else{
    setIsValid(false);
  }
  }

  const handleSignupFormSubmit = async (event) => {
    event.preventDefault();

        
          let accessToken = cookies.get('accessToken', { path: '/' });
          const formdata = new FormData();
          formdata.append('firstName', signupData.firstName);
          formdata.append('lastName', signupData.lastName);
          formdata.append('email', signupData.email);
          formdata.append('phone', signupData.phone);
          formdata.append('password', signupData.password);
          formdata.append('vehicle', signupData.vehicle);
          formdata.append('vehicleRegNo', signupData.vehicleRegNo);
          formdata.append('licenseImage', licenseImage);
          formdata.append('idImage', idImage);
          formdata.append('profileImage', profileImage);
          formdata.append('otp', otp);
          formdata.append('captchaValue', recaptchaValue);


          var requestOptions = {
              method: 'POST',
              // headers: headers,
              body: formdata,
              redirect: 'follow'
          };
  
          fetch(`${process.env.REACT_APP_DatabaseServer}driver/register`, requestOptions)
              .then(response => {
                 
                  return response.json();
              })
              .then((responseData) => {
                  if (responseData.message === "Success") {
                      console.log("Registration successful");
                      setSuccessMessage(true);
                      setRecaptchaValue('')
                      if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                      }
                      setTimeout(() => {
                        setSuccessMessage(false)
                        setTimeout(() => {
                          navigate(`/login`)
                        }, 1000);
                      }, 1000);
  
                    } else {
                      alert("Registration failed, Please try again.")
                      setRecaptchaValue('')
                      if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                      }
                      setServerError(true)
                      $('#errorMessage').show();
                      console.error('Failed to fetch data from API');
                    }
              })
              .catch(error => console.log('error', error));
  }

  const handlePhoneNumberChange = (e) => {
      
    const inputValue = e.target.value;
    // Remove any non-numeric characters
    // const cleanedPhoneNumber = inputValue.replace(/[^0-9]/g, '');

    if (inputValue.length === 10) {
      setPhoneNumber(inputValue);
      setIsValid(true);
    } else {
      setIsValid(false);
      setPhoneNumber('');
    }
  };
    
  const reSendOtp = async (e) => {
    try {
      let response = await https.post('user/signUpVerification', {"email" : signupData.email,"firstName": signupData.firstName,"phone": signupData.phone } );
      if (response) {
          if(response.message === "Success"){
            setSignupBlock(false)
            setOtpBlock(true)
            setBtnDisable(false)
            setMessage('Otp Send Successfully.')
            setTimeout(() => {
              setMessage('')
            }, 2000);
          } else{
            setOtpError(response.message)
            setTimeout(() => {
              setOtpError('')
            }, 3000);
          }
          // setDriverList(response)
      } else {
          console.error('Failed to fetch data from API');
      }
    }
    catch (error) {
        console.error('Error fetching data from API:', error);
    }
  }

  return (
    <>
    
      {login ? (
        <Dashboard />
      ) : (
        <>
        <Header />
        <Container maxWidth="xl" className='homeContainer'>
          <Box className='middle_block' textAlign="center">
            <Box className='middle_inner_block'>
            {signupBlock && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                  <Typography variant='h5' className='formTitle'>Signup </Typography> <Typography variant='h5' className='formTitle formTitle2'>Now</Typography>
                </Box>

                <Box className='login_form' id="login_form"
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                    mt: 3,
                  }}

                  onSubmit={event => handleSubmit(event)}
                >
                  <div>
                    <CssTextField fullWidth id="firstname" floatinglabeltext="First Name" type="text" label="First Name" variant="outlined" className='text_field' required />
                  </div>
                  <div>
                    <CssTextField fullWidth id="lastname" floatinglabeltext="Last Name" type="text" label="Last Name" variant="outlined" className='text_field' required />
                  </div>
                  <div>
                    <CssTextField fullWidth id="email" floatinglabeltext="Email" type="email" label="Email" variant="outlined" className='text_field' onChange={handleEmailChange} required />
                  </div>
                  <div>
                    <CssTextField fullWidth id="confirmEmail" floatinglabeltext="Confirm Email" type="email" label="Confirm Email" variant="outlined" className='text_field' onChange={handleConfirmEmailChange} required />
                    <FormHelperText className="errorMsg signupMsg">{emailError}</FormHelperText>
                  </div>
                  <div>
                    <CssTextField fullWidth id="tel" floatinglabeltext="Phone" label="Phone No" variant="outlined" className='text_field' type="tel" 
                    maxLength="10" onChange={handlePhoneNumberChange} required />
                    {!isValid && <FormHelperText className="errorMsg signupMsg">Please enter a valid 10-digit phone number</FormHelperText>}
                  </div>
                  <div error={error}>
                    <CssTextField fullWidth id="password" hinttext="Password" floatinglabeltext="Password" label="Password" type="password" className='text_field' value={password} onChange={handleChange} required></CssTextField>
                    {error && <FormHelperText className="errorMsg signupMsg">Password must contain Minimum eight characters, at least one letter and one number and one Special character.</FormHelperText>}
                  </div>
                  <div>
                    <CssTextField fullWidth id="confPassword" hinttext="Confirm Password" floatinglabeltext="Confirm Password" label="Confirm Password" type="password" className='text_field' required></CssTextField>
                  </div>
                  <div>
                    <CssTextField fullWidth id="vehicle" floatinglabeltext="Vehicle" type="text" label="Vehicle" variant="outlined" className='text_field' required />
                  </div>
                  <div>
                    <CssTextField fullWidth id="vehicleRegNo" floatinglabeltext="VehicleRegNo" type="text" label="VehicleRegNo" variant="outlined" className='text_field' required />
                  </div>
                  <div>
                    <Box align="left" sx={{mb: 0}}>
                    <label>Upload License *</label>
                    </Box>
                    <CssTextField fullWidth id="licenseImage"  type="file"  variant="outlined" className='text_field' required onChange={handleLicenseImageChange}/>
                  </div>
                  <div>
                    <Box align="left" sx={{mb: 0}}>
                    <label>Upload Identity Card *</label>
                    </Box>
                    <CssTextField fullWidth id="idImage" type="file" variant="outlined" className='text_field' required onChange={handleIdImageChange}/>
                    </div>
                  <div>
                    <Box align="left" sx={{mb: 0}}>
                    <label>Upload Profile Photo *</label>
                    </Box>
                    <CssTextField fullWidth id="profileImage" type="file" variant="outlined" className='text_field' required onChange={handleProfileImageChange}/>
                  </div>
                  <div>
                      {windowWidth > 450 ? (
                          <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="normal"
                          />
                        ):(
                          <ReCAPTCHA
                            ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="compact"
                          />
                        )}
                    </div>
                  <div>
                    <Box className="errorMessage" id="errorMessage">{serverError ? "Account with this email already exists.":"Password and Confirm Password does not match."}</Box>
                    <Button type="submit" variant="contained" className='submitBtn login_Btn' disabled={!recaptchaValue} id="submitBtn">Create An Account</Button>
                    <Box className="errorMessage" id="errorMessage2">{"Email and Confirm Email does not match."}</Box>
                    <Box className="errorMessage3">{phoneError}</Box>
                  </div>
                  {/* <div>
                      <Button variant="contained" className='submitBtn submitBtn2'>Login In with Google</Button>
                    </div> */}
                  <div>
                    <Link href="/login" color="inherit"><Typography sx={{ color: '#033D1E', fontSize: '14px' }}>Already have an account? Log in here!</Typography></Link>
                  </div>
                </Box>
              </>
            )}

          {otpBlock && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                  <Typography variant='h5' className='formTitle'>Verify Email</Typography>
                  </Box>

                  <Box className='login_form'
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                      mt: 3,
                    }}
                    // noValidate
                    autoComplete="off"
                    onSubmit={event => handleSignupFormSubmit(event)}
                  >
                    <div>
                      <CssTextField fullWidth id="otp" floatinglabeltext="Otp" type="text" label="Otp" variant="outlined" className='text_field' onChange={otpChange} required/>
                    </div>

                     <div>
                      {windowWidth > 450 ? (
                          <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="normal"
                          />
                        ):(
                          <ReCAPTCHA
                            ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="compact"
                          />
                        )}
                    </div>

                    <div>
                      <Button variant="contained" type="submit" className='submitBtn login_Btn' disabled={!recaptchaValue} id="loginSubmitBtn">Verify Email</Button>
                    </div>

                    <div>
                      <Button variant="contained" className='b1 b4' id="" onClick={reSendOtp} sx={{mt: 1}}>Resend OTP</Button>
                    </div>

                    {successMessage === true && (
                        <Box sx={{mt: 2}}>
                        <Typography className='font-18 color4 font-600'>Registration Successful.</Typography>
                      </Box>
                    )}

                      {/* <Box className="errorMessage" id="errorMessage">Password and Confirm Password does not match.</Box> */}
                      <Typography className="loginErrorMessage2" id="">{message}</Typography>
                      <Typography className="loginErrorMessage2">{otpError}</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Container>
        <Footer />
      </>
      )}
    </>
  );
}

export default DriverSignup;