import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Dialog,
	DialogTitle,
	DialogContent,
	DialogActions, 
	FormControlLabel,
	Switch} from "@mui/material";
import Form from "react-bootstrap/Form";
import Request from '../customUtils/Http/Request';
import PayPalApikeyForm from "./payPalApikeyForm";

function PaymentPaypal() {
	const [paymentmode, setPaymentmode] = useState("test");
	const [keyId, setKeyId] = useState("");
	const [formData, setFormData] = useState({
		apiUsername: "",
		apiPassword: "",
		apiSignature: "",
		status: false
	});
	const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	const handlePaymentmodeChange = (event) => {
		setPaymentmode(event.target.value);
	};

	const handleChange = (e) => {
		if(e.target.name === "status"){
			setFormData({
				...formData,
				[e.target.name]: e.target.checked,
			  });
		}else{
			const { name, value } = e.target;
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async (e) => {
		try {
			const response = await Request.get(`admin/paypalKey/active`, [], true);
			if (response.length !== 0) {
					setFormData({
						"apiUsername": response.paypalUserName,
						"apiPassword": response.paypalUserPassword,
						"apiSignature": response.paypalSignature,
						"status": response.status
					})
					setKeyId(response.id)
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    	setConfirmationDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setConfirmationDialogOpen(false);
	  };

	  const handleConfirm = async () => {
		setConfirmationDialogOpen(false);
		const data = {
			"paypalUserName": formData.apiUsername,
			"paypalUserPassword": formData.apiPassword,
			"paypalSignature": formData.apiSignature,
			"status": formData.status
		}
		try {
			const response = await Request.put(`admin/paypalKeys/${keyId}`, data, true);
			if (response.status === 200) {
				
			}else{
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	  };


	return (
		<Box className="adminInnerPage paymentPage">
			<form onSubmit={handleSubmit}>
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
				Paypal Gateway
				</Typography>

				<Button
					variant="primary"
					color="primary"
					type="submit"
					className="btn btn-secondary"
				>
					Save Settings
				</Button>
			</div>

			{/* <Form.Group
				controlId="stripePaymentMode"
				className="paymentPage__mode"
			>
				<Form.Label>PayPal Payment Mode</Form.Label>
				<Form.Check
					type="radio"
					label="Test Mode"
					name="paymentmode"
					value="test"
					checked={paymentmode === "test"}
					onChange={handlePaymentmodeChange}
				/>
				<Form.Check
					type="radio"
					label="Live"
					name="paymentmode"
					value="live"
					checked={paymentmode === "live"}
					onChange={handlePaymentmodeChange}
				/>
			</Form.Group> */}

			<div className="paymentPage__keys">
				<Box className="paymentKeysForm">
					<Form.Group controlId="paypalApiUsername" className="paymentKeysForm__field">
						<Form.Label>PayPal API Username</Form.Label>
						<Form.Control
							type="text"
							name="apiUsername"
							value={formData.apiUsername}
							onChange={handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="paypalApiPassword" className="paymentKeysForm__field">
						<Form.Label>PayPal API Password</Form.Label>
						<Form.Control
							type="password"
							name="apiPassword"
							value={formData.apiPassword}
							onChange={handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="paypalApiSignature" className="paymentKeysForm__field">
						<Form.Label>PayPal API Signature</Form.Label>
						<Form.Control
							type="text"
							name="apiSignature"
							value={formData.apiSignature}
							onChange={handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="paypalApiSignature" className="paymentKeysForm__field">
						<Form.Label>Enable Payment</Form.Label>
						<FormControlLabel control={<Switch onChange={handleChange} name="status" checked={formData.status} 
						style={{ color: formData.status ? '#FFC72C' : 'lightGray' }} />} />
					</Form.Group>
				</Box>
				{/* <PayPalApikeyForm /> */}
			</div>
			</form>

			<Dialog open={isConfirmationDialogOpen} onClose={handleCloseDialog}>
					{/* <DialogTitle>Confirm Submission</DialogTitle> */}
					<DialogContent>
						Are you sure you want to edit settings?
					</DialogContent>
					<DialogActions>
					<Button onClick={handleCloseDialog} color="primary">
						Cancel
					</Button>
					<Button onClick={handleConfirm} color="primary">
						Confirm
					</Button>
					</DialogActions>
				</Dialog>
		</Box>
	);
}

export default PaymentPaypal;
