import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function checkLogin() {
    var access_token = cookies.get('accessToken', { path: '/' });
    var refresh_token = cookies.get('refreshToken', {path: "/"});
    if (access_token && access_token !== "null" && access_token !== "undefined") {
        return true
    }
    else {
        return false
    }
}

export function checkUserType() {
    let userType = cookies.get('userType', { path: '/' });
    let userTypeAsNumber = parseInt(userType, 10);
    return userTypeAsNumber
}