import { Box, Button, Grid, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form, Table as BootstrapTable, } from 'react-bootstrap';
import YourOrderTable from './yourOrderTable';
import Cookies from 'universal-cookie';
import { SortableTableInner } from './adminOrder';
import { PriorityProductTable } from './priorityProductTable';
import EditIcon from '@mui/icons-material/Edit';
import OrderSummaryForm from './orderSummaryForm';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import OrderDetail from '../checkout/orderDetail';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
import html2canvas from 'html2canvas';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import { CheckNumeric } from '../commonFunctions/checkNumeric';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    alignRight : {
        textAlign: "right"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: "25px",
        paddingTop: "30px"
    },
    orderInnerGrid: {
        padding: "30px",
        border: "1px solid lightgray",
        borderRadius: "10px",
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
	 imgBox:{
		width: "170px",
		height: "60px"
	 },
     flexEnd: {
        display: "flex",
        justifyContent: "end"
     },
     '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
        flexEnd: {
            justifyContent: "start"
        },
        alignRight: {
            textAlign: "left"
        }
    },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    minWidth: '50%',
    overflow: 'auto',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    color: 'var(--primary-color1)',
    // Define responsive styles
    '@media (max-width: 1100px)': {
      maxWidth: '80%', // Adjust as needed for smaller screens
      minWidth: '80%', // Adjust as needed for smaller screens// Limit the height on smaller screens if necessary
      p: 2, // Reduce padding on smaller screens if necessary
    },
    '@media (max-width: 767px)': {
        maxWidth: '95%', // Adjust as needed for smaller screens
        minWidth: '95%', // Adjust as needed for smaller screens
        maxHeight: '90vh', // Limit the height on smaller screens if necessary
        p: 2, // Reduce padding on smaller screens if necessary
      },
  };

export const BoxTable = ({ data, total, selectedTab }) => {
    const [sortedField, setSortedField] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [editedQuantities, setEditedQuantities] = useState({});
    const handleSort = (field) => {
        const isAsc = sortedField === field && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortedField(field);
    };

    const sortedData = data.sort((a, b) => {
        const aValue = a[sortedField];
        const bValue = b[sortedField];

        if (sortDirection === "asc") {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    const handleDelete = async (id) => {
        try {
            let response = await Request.delete(`admin/islander/iAddBoxDetailUpdate/${id}`,[], true);
            if (response.status === 204) {
                window.location.reload();
                //   setIslanderDetail(response);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
        // Create a copy of the edited quantities state and set edit mode for the specific row
        
    };

    const handleEdit = (rowIndex) => {
        // Create a copy of the edited quantities state and set edit mode for the specific row
        const updatedEditedQuantities = { ...editedQuantities };
        updatedEditedQuantities[rowIndex] = true;
        setEditedQuantities(updatedEditedQuantities);
    };

    const handleInputChange = (event, rowIndex) => {
        // Update the edited quantity for the specific row as the user types
        const { value } = event.target;
        const updatedEditedQuantities = { ...editedQuantities };
        updatedEditedQuantities[rowIndex] = value;
        setEditedQuantities(updatedEditedQuantities);
    };

    const handleSave = async (rowIndex, event) => {
        if (editedQuantities[rowIndex] !== undefined) {
            const updatedQuantity = parseInt(editedQuantities[rowIndex], 10);
            if (!isNaN(updatedQuantity)) {
                // Send the updated quantity and ID to your API here
                const itemId = data[rowIndex].id;
                const item = {
                    "quantity": updatedQuantity
                }
                try {
                    let response = await Request.put(`admin/islander/iBoxInvoiceStatus/${itemId}`, item, true);
                    if (response.length !== 0) {
                        //   setIslanderDetail(response);
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error fetching data from API:', error);
                }
            }
        }
        // Exit edit mode for this row
        const updatedEditedQuantities = { ...editedQuantities };
        delete updatedEditedQuantities[rowIndex];
        setEditedQuantities(updatedEditedQuantities);
    };

    return (
        <div className="order-table-container">
            <TableContainer component={Paper}>
                <Table className="sortable-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <label
                                >
                                    Size
                                </label>
                            </TableCell>
                            <TableCell>
                                <label
                                >
                                    Weight
                                </label>
                            </TableCell>
                            <TableCell>
                                <label
                                >
                                    Qty
                                </label>
                            </TableCell>
                            <TableCell>
                                <label
                                >
                                    Price
                                </label>
                            </TableCell>
                            {selectedTab === 2 && (
                                <TableCell></TableCell>
                            )}
                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(sortedData && sortedData.length>0) && sortedData.map((box, rowIndex) => (
                            <TableRow key={box.id}>
                                <TableCell>{CheckNumeric(box.size)}</TableCell>
                                <TableCell>{box.weight}</TableCell>
                                <TableCell>{box.quantity}</TableCell>
                                <TableCell>{box.price}</TableCell>

                                {selectedTab === 2 && (
                                  <TableCell>
                                    <DeleteOutlineIcon onClick={() => handleDelete(box.id)}/>
                                  {/* {editedQuantities[rowIndex] !== undefined ? (
                                      <button onClick={() => handleSave(rowIndex)}>Save</button>
                                  ) : (
                                      // Edit button
                                      <button onClick={() => handleEdit(rowIndex)}>Edit</button>
                                  )} */}

                              </TableCell>
                                )}
                              
                            </TableRow>
                        ))}
                        <TableRow>
                            {selectedTab === 2 ? (
                                 <TableCell colSpan={4} className='font-bold text-end'>Total Amount:</TableCell>
                            ):(
                                <TableCell colSpan={3} className='font-bold text-end'>Total Amount:</TableCell>
                            )}
                           
                            <TableCell className='font-bold font-18 color4'>${FormatPrice(total)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


        </div>
    );
};

const IslanderOrderDetailEdit = ({ orderId, islanderDetail, updateOrderDetail, selectedTab, orderType, setOpen2 }) => {
    const classes = useStyles();
    const dialogRef = useRef(null);
    const [shippingAddressText, setShippingAddressText] = useState([]);
    const [billingAddressText, setBillingAddressText] = useState([]);
    const [senderAddressText, setSenderAddressText] = useState([]);
    const [estDate, setEstDate] = useState('');
    const [productDetail, setProductDetail] = useState([]);
    const [open, setOpen] = useState(false);
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [imgBox, setImageBox] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [openTrackingBox, setOpenTrackingBox] = useState(false);
    const [isAddingPrice, setIsAddingPrice] = useState(true);
    // const [openDetail, setOpenDetail] = useState(false);

    const [openDetail, setOpenDetail] = useState(false);
    const [orderDetailId, setOrderDetailId] = useState(null);
    const [invoiceNo, setInvoiceNo] = useState(null);
    const [availableBol, setAvailableBol] = useState(false);
    // const [productDetail, setProductDetail] = useState([]);
    const [priorityProductDetail, setPriorityProductDetail] = useState([]);
    const [boxDetail, setBoxDetail] = useState([]);
    const [orderInvoiceDetail, setOrderInvoiceDetail] = useState([]);

    const [trackingData, setTrackingData] = useState({
        statusUpdateDate: '',
        description: '',
        location: '',
    })

    if(selectedTab === 2){
        const address = islanderDetail?.address?.[0] || {};
        const {
            fullName,
            address_line_1, 
            address_line_2,
            city,
            state,
            country,
            zip,
          } = address;
        // const [driverList, setDriverList] = useState([]);
        var formattedAddress = `${fullName},\n${address_line_1}\n${address_line_2},\n${city}-${zip}, ${state}, ${country}`;
    }
    else{
        var shippingAddress = islanderDetail?.shippingAddress || {};
        var billingAddress = islanderDetail?.billingAddress || {};
        var senderAddress = islanderDetail?.senderAddress || {};

        var formatAddress2 = (address) => {
            const {
              fullName,
              address_line_1,
              address_line_2,
              city,
              state,
              country,
              zip,
            } = address;
        
        return `${fullName},\n${address_line_1},\n${address_line_2},\n${city}-${zip}, ${state}, ${country}`;
    }
}

    const [boxData, setBoxData] = useState({
        size: '',
        weight: '',
        quantity: '',
        price: 0,
    });

    const [editableData, setEditableData] = useState({
        orderStatus: islanderDetail?.orderStatus || '',
    });

    const [editableData2, setEditableData2] = useState({
        trackingNo: islanderDetail?.trackingNo || '',
        orderStatus: islanderDetail?.orderStatus || '',
        actualDeliveryDate: islanderDetail?.actualDeliveryDate || '',
        bol: islanderDetail?.bol || '',
        paymentStatus:islanderDetail?.paymentStatus || '',
        estDeliveryTimeInDay:islanderDetail?.estDeliveryTimeInDay || ''
    });


    useEffect(() => {
        setEditableData2({
            trackingNo: islanderDetail.trackingNo || '',
            orderStatus: islanderDetail.orderStatus || editableData2.orderStatus,
            actualDeliveryDate: islanderDetail.actualDeliveryDate || editableData2.actualDeliveryDate,
            bol: islanderDetail.bol || null,
            paymentStatus:islanderDetail.paymentStatus || '',
            estDeliveryTimeInDay:islanderDetail.estDeliveryTimeInDay || ''
        });
        if(islanderDetail?.bol){
            setAvailableBol(true)
        }
    }, [islanderDetail]);



    const handleCloseDetail = () => {
        setOpenDetail(false);
    };
    const handleCloseImageDialog = () =>{
        setOpenImageDialog(false)
    }

    const handleCloseTackingBox = () => {
        setOpenTrackingBox(false);
    };

    const addTrackingData = async () => {
        setOpenTrackingBox(true)
    }

    const handleTrackingData = async (id) => {

        if (!trackingData.location || !trackingData.statusUpdateDate || !trackingData.description) {
            alert('Please fill in all required fields.');
            return 
        }

        try {
            let response = await Request.post(`admin/tracking/${id}`, trackingData, true);
            if (response.message === "Success") {
                setOpenTrackingBox(false)
                window.location.reload();
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const handleDateChange = (date) => {
        setTrackingData({
            ...trackingData,
            "statusUpdateDate" : date,
        });
      };


    const handleDescriptionChange = (event) => {
        const newValue = event.target.value;
        setTrackingData({
            ...trackingData,
            "description" : newValue,
        });
    };

    const options = [
        { value: 0, label: 'Order Received' },
        { value: 1, label: 'Item Receiving' },
        { value: 2, label: 'Packing' },
        { value: 3, label: 'Space Available' },
        { value: 4, label: 'Filling Space' },
        { value: 5, label: 'Packed' },
        { value: 6, label: 'Ready to Ship' },
        { value: 7, label: 'Payment Received - Qty Change' },
        { value: 8, label: 'Payment Received - No Qty Change' },
    ];

    const options2 = [
        { value: 0, label: 'Incomplete' },
        { value: 1, label: 'Pending' },
        { value: 2, label: 'Ready to Pickup' },
        { value: 3, label: 'Picked Up' },
        { value: 4, label: 'ready to ship' },
        { value: 5, label: 'Shipped' },
        { value: 6, label: 'Delivered' },
        { value: 7, label: 'Canceled' },
        { value: 8, label: 'Delete' },
    ];



    const paymentStatus = [
        { text: 'success', value: true },
        { text: 'Pending', value: false }
    ];

    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // useEffect(() => {
    //     fetchDriverData();
    // }, []);
    useEffect(() => {

        const estimatedDeliveryDate = new Date(islanderDetail.createdAt);
        estimatedDeliveryDate.setHours(estimatedDeliveryDate.getHours() + islanderDetail.estDeliveryTime);

        if (!isNaN(estimatedDeliveryDate)) {
            setEstDate(estimatedDeliveryDate.toISOString());
        }
        else {
            console.log('error')
        }
    }, []);

    useEffect(() => {
      
        const estimatedDeliveryDate = new Date(islanderDetail.createdAt);
        estimatedDeliveryDate.setHours(estimatedDeliveryDate.getHours() + islanderDetail.estDeliveryTime);

        if (!isNaN(estimatedDeliveryDate)) {
            setEstDate(estimatedDeliveryDate.toISOString());
        }
        else {
            console.log('error')
        }
    }, [islanderDetail]);


    useEffect(() => {
        setEditableData({
            orderStatus: islanderDetail?.orderStatus || editableData.orderStatus,
        });
    }, [islanderDetail]);

    const fetchDriverData = async () => {
        try {
            let response = await Request.get('admin/driver/approved', [], true);
            if (response.length !== 0) {
                // setDriverList(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const handleClose = () => {
		setOpen(false);
	 }

    const handleOpen = async(orderId) => {
        setOpenDetail(true);
        try {
           let response = await Request.get(`order/order_detail/${orderId}`, [], true);
           
           if (response) {
                setOrderInvoiceDetail(response)
                setOrderDetailId(orderId)
                setInvoiceNo(response.invoiceNumber)
                setProductDetail(response?.products?.iCollectUserProducts)
                setBoxDetail(response?.products?.shipmentBox)
                setPriorityProductDetail(response?.products?.PriorityProducts)

                // if(response.orderType === "Islander")
                // {
                   
                //    // setInvoiceNo(response.invoiceNo)
                // }
                // else{
                //    setProductDetail(response.products)
                //    setPriorityProductDetail([])
                //    setBoxDetail([])   
                // } 
           }
           else {
                console.log("error");
           }
     }
     catch (error) {
           console.error('Error fetching data from API:', error);
     }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditableData2({
            ...editableData2,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEditableData2({
                ...editableData2,
                bol: file, // Store the selected file object
            });
        } else {
            setEditableData2({
                ...editableData2,
                bol: '', // Clear the selected file
            });
        }
    };


    const saveStatusInfo = async (id) => {
        if (editableData.orderStatus === 2) {
            const item = {
                "status": 2
            }
            try {
                let response = await Request.put(`admin/islander/iStatusUpdate/${id}`, item, true);
                if (response.length !== 0) {
                    window.location.reload();
                    //   setIslanderDetail(response);
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }

    }

    const savePaymentInfo = async () => {
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('trackingNo', editableData2.trackingNo);
        formdata.append('orderStatus', editableData2.orderStatus);
        formdata.append('bol', editableData2.bol);
        formdata.append('paymentStatus', editableData2.paymentStatus);
        formdata.append('actualDeliveryDate', editableData2.actualDeliveryDate);
        
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/order/edit/${orderId}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}admin/order/edit/${orderId}`, requestOptions)
            .then(response => {
                setOpen(false)
                window.location.reload();
                return response.text();
            })
            .then((updatedData) => {
                updateOrderDetail(updatedData);
                setOpen(false)
            })
            .catch(error => console.log('error', error));
    }

    const changeItemStatus = async (id) => {
        const item = {
            "status": 3
        }
        try {
            let response = await Request.put(`admin/islander/iStatusUpdate/${id}`, item, true);
            if (response.length !== 0) {
                setOpen2(false)
                //   setIslanderDetail(response);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    // const changeItemStatus2 = async (id) => {
    //     const item = {
    //         "status": 3
    //     }
    //     try {
    //         let response = await Request.put(`admin/islander/iStatusUpdate/${id}`, item, true);

    //         if (response.length !== 0) {
    //             window.location.reload();
    //             //   setIslanderDetail(response);
    //         } else {
    //             console.error('Failed to fetch data from API');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data from API:', error);
    //     }
    // }

    const changeItemShippingStatus = async (id) => {
        const item = {
            "status": 6
        }
        try {
            let response = await Request.put(`admin/islander/iStatusUpdate/${id}`, item, true);
            if (response.length !== 0) {
                setOpen2(false)
                //   setIslanderDetail(response);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const changeItemShippingStatusPacked = async (id) => {
        const item = {
            "status": 5
        }
        try {
            let response = await Request.put(`admin/islander/iStatusUpdate/${id}`, item, true);
            if (response.length !== 0) {
                setOpen2(false)
                //   setIslanderDetail(response);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }


    const addBoxItems = async (id) => {
        setOpen(true)
    }

    const addBox = async (id) => {
        if (boxData.weight !== '') {
        try {
            let response = await Request.post(`admin/islander/iAddBoxDetail/${id}`, boxData, true);
            if (response.message === "Success") {
                setOpen(false)
                setOpen2(false)
                //   setIslanderDetail(response);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }else{
        alert("Please Enter Weight")
    }
    }

    const addImg = async (id) => {
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('boxImage', imgBox);
        
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/islander/iAddShipmentImage/${id}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}admin/islander/iAddShipmentImage/${id}`, requestOptions)
            // .then(response => {
               
              
            //     return response.text();
            // })
            // .then((updatedData) => {
            //     updateOrderDetail(updatedData);
            //     setImageBox(false)
            // })
            .then(response => {
                if (response.status !== 200) {
                    return response.json().then(data => {
                        throw new Error("Server Error");
                    });
                } else {
                    return response.json();
                }
            })
            .then(responseData => {
                changeItemStatus(id)
                setImageBox(false)
                // window.location.reload();
            })
            .catch(error => console.log('error', error));
    }

    const handleImgBox = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Check if the selected file is an image or PDF
            if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' || selectedFile.type === 'application/pdf') {
                setImageBox(selectedFile);
                setSelectedFile(selectedFile);
            } else {
                alert('Please select a valid image (JPEG, PNG) or PDF file.');
            }
          }
      };

    const addMoreItem = (id) => {
        setOpenImageDialog(true)
    }
    const onStatusChange = (e) => {
        // Handle select change event here
        const selectedValue = parseInt(e.target.value);
        setEditableData({ ...editableData, orderStatus: selectedValue });

        if (selectedValue === 3) {
            setOpenImageDialog(true)
            // changeItemStatus(islanderDetail.id);
             // Call your function here with the necessary arguments
        }

        if (selectedValue === 6 && islanderDetail.shipmentBox.length !== 0) {
            changeItemShippingStatus(islanderDetail.id); // Call your function here with the necessary arguments
        }

        if (selectedValue === 6 && islanderDetail.shipmentBox.length === 0) {
            alert('please Enter Box Detail.')
            return
        }

        if (selectedValue === 5) {
            changeItemShippingStatusPacked(islanderDetail.id); // Call your function here with the necessary arguments
        }

        if (selectedValue === 5) {
            changeItemShippingStatusPacked(islanderDetail.id); // Call your function here with the necessary arguments
        }
    }

    const onStatusChange2 = (e) => {
        // Handle select change event here
        const selectedValue = parseInt(e.target.value);
        setEditableData2({ ...editableData2, orderStatus: selectedValue });

    }

    const updatePrice = async () => {
        try {
            const response = await Request.post(`admin/islander/getIslanderBoxRate`, {
                "size" : boxData.size,
                "quantity" : boxData.quantity
            },true);
            if (response) {
                if(response.price){
                    setBoxData({ ...boxData, price: response.price });
                }else{
                    alert("can't fetch Price")
                    return;
                }  
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
     
        // const size = parseFloat(boxData.size);
        // const quantity = parseInt(boxData.quantity);
    
        // if (!isNaN(size) && !isNaN(quantity)) {
        //   const calculatedPrice = size * quantity;
        //   setBoxData({ ...boxData, price: calculatedPrice.toFixed(2) });
        // } else {
        //   setBoxData({ ...boxData, price: '' }); 
        // }
      };

     // Handle changes in Size and Quantity fields
        const handleSizeChange = (e) => {
            setBoxData({ ...boxData, size: e.target.value });
            setIsAddingPrice(true); 
            // updatePrice();
        };

        const handleQuantityChange = (e) => {
            setBoxData({ ...boxData, quantity: e.target.value });
            setIsAddingPrice(true); 
            // updatePrice();
        };

        const addPrice = async () => {
            if (boxData.size.trim() !== '' && boxData.quantity.trim() !== '') {
            try {
                const response = await Request.post(`admin/islander/getIslanderBoxRate`, {
                    "size" : boxData.size,
                    "quantity" : boxData.quantity,
                    "shipmentId": islanderDetail.id
                },true);
                if (response) {
                    if(response.price){
                        setBoxData({ ...boxData, price: response.price });
                    }else{
                        alert("can't fetch Price")
                        return;
                    }  
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
            
            // // Perform your price calculation here and update boxData.price
            // const newPrice = calculatePrice(boxData.size, boxData.quantity); // Replace with your logic
            // setBoxData((prevState) => ({
            //   ...prevState,
            //   price: newPrice,
            // }));
            setIsAddingPrice(false); 
        }else{
            alert("Please Enter Size and Quantity")
        }// Switch to "Submit" mode
          };

          const handlePrint = () => {
            if (dialogRef.current) {
                const input = dialogRef.current;
          
    
                // const handleCaptureAndConvert = () => {
                //     if (divRef.current) {
                //       const divToCapture = divRef.current;
                
                      // Capture a screenshot of the div
                      html2canvas(input, { useCORS: true }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                
                        // Create a new PDF document
                        const pdf = new jsPDF({
                            orientation: 'portrait',  // or 'landscape'
                            unit: 'mm',
                            format: 'a4',  // page size: A4
                            putOnlyUsedFonts: true
                        });
                        const width = 210;  // A4 width in mm5rt
                        const height = (canvas.height * width) / canvas.width;
                        
                        canvas.getContext('2d').imageSmoothingEnabled = false;
                        // Add the canvas image to the PDF
                        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                        pdf.setFontSize(14); // Set the font size
                        pdf.setFont('Helvetica'); // Use a web-safe font
                        // Save the PDF with a filename
                        pdf.save('screenshot.pdf');
                      });
              };
          };

    return (
        <>
            <Box className="admin-modal__container">
                <div className="admin-modal__title-container">
                    <Typography variant="h6" className="admin-modal__title">
                        {islanderDetail?.invoiceNo}
                    </Typography>

                    <Box className="flexBox">
                    
                        {orderType === "Islander" || orderType === "Islander Box" ? (
                            <>
                            {selectedTab === 2 ? (
                                <Button color="primary" className="btn" onClick={() => saveStatusInfo(islanderDetail.id)}>
                                    Save
                                </Button>
                            ):(
                                <>
                                    <Button color="primary" className="btn" onClick={() => handleOpen(orderId)}>
                                        View Invoice
                                    </Button>
                                    <Button color="primary" className="btn" onClick={() => savePaymentInfo(islanderDetail.id)}>
                                        Save
                                    </Button>
                                </>
                            )} 
                            </>
                            
                        ) : (
                            <></>
                        )}
                    </Box>

                </div>
                {selectedTab === 2 && (
                    <Box align="right">

                        <Box className="d-flex justify-content-end gap-10">
                            <Button color="primary" className="b1 b5 colo2" disabled={islanderDetail.orderStatus === 2 ? false : true} onClick={() => addMoreItem(islanderDetail.id)}>
                                Add More Items
                            </Button>
                            <Button color="primary" className="b1 b4 colo2" sx={{ ml: 2 }} onClick={() => changeItemShippingStatus(islanderDetail.id)} disabled={islanderDetail.orderStatus >= 4 && islanderDetail.shipmentBox.length !== 0 ? false : true}>
                                Ready To Ship
                            </Button>
                        </Box>

                    </Box>
                )}

                <div className="shipping-order-modal">
                    <div className="edit-order-form__modal shipping-order-form__modal">
                        <Form
                            className="edit-order-form shipping-order-form"
                        >
                            {islanderDetail && (
                                <div className="edit-order-form__field--bottom">
                                    <Form.Group
                                        controlId="customerName"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="customerName"
                                            value={islanderDetail.user?.fullName || ''}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="orderId"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="orderId"
                                            value={islanderDetail?.invoiceNo || ''}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="orderDate"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="orderDate"
                                            value={formatDate(islanderDetail.createdAt)}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="orderStatus"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order Status</Form.Label>
                                        {selectedTab === 2 ? (
                                            <Form.Select
                                            name="orderStatus"
                                            value={editableData.orderStatus}
                                            onChange={onStatusChange}
                                            >
                                            {/* <option value="" disabled>Select an option</option> */}
                                            {options.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        ):(
                                            <Form.Select
                                            name="orderStatus"
                                            value={editableData2.orderStatus}
                                            onChange={onStatusChange2}
                                        >
                                            {/* <option value="" disabled>Select an option</option> */}
                                            {options2.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        )}
                                       
                                    </Form.Group>

                                    {selectedTab === 2 ? (
                                       <Form.Group
                                        controlId="recipientAddress"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Recipient Address</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="recipientAddress"
                                            value={formattedAddress}
                                            readOnly
                                        />
                                        </Form.Group>
                                    ):(
                                            <>
                                                {(Object.keys(shippingAddress).length!== 0) ? (
                                                <Form.Group
                                                controlId="recipientAddress"
                                                className="edit-order-form__field"
                                                >
                                                    <Form.Label>Recipient Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="recipientAddress"
                                                        value={formatAddress2(shippingAddress)}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                                ):<></>}
                                                {(Object.keys(senderAddress).length!== 0) ? (
                                                <Form.Group
                                                    controlId="senderAddress"
                                                    className="edit-order-form__field"
                                                >
                                                    <Form.Label>Sender Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="senderAddress"
                                                        value={formatAddress2(senderAddress)}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                                ):<></>}
                                                {(Object.keys(billingAddress).length!== 0) ? (
                                                <Form.Group
                                                controlId="billingAddress"
                                                className="edit-order-form__field"
                                                >
                                                    <Form.Label>Billing Address</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="billingAddress"
                                                        value={formatAddress2(billingAddress)}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                                ):<></>}
                                            </>
                                    )}
                                </div>
                            )}

                        </Form>

                        {selectedTab === 2 ? (
                            <>
                                {islanderDetail.iCollectUserProducts ? (
                                    <>
                                        <Typography
                                            variant="h6"
                                            className="section-title space-top"
                                        >
                                            Item Delivery Status
                                        </Typography>
                                        <SortableTableInner data={islanderDetail.iCollectUserProducts} selectedTab={selectedTab} setOpen2={setOpen2}/>
                                    </>


                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <>
                                {islanderDetail.products?.iCollectUserProducts && (
                                    <>
                                        <Typography
                                            variant="h6"
                                            className="section-title space-top"
                                        >
                                            Item Delivery Status
                                        </Typography>
                                        <SortableTableInner data={islanderDetail.products.iCollectUserProducts} selectedTab={selectedTab} setOpen2={setOpen2}/>
                                    </>


                                )}
                            </>
                        )}

                    {selectedTab === 2 ? (
                        <>
                        {islanderDetail.PriorityProducts && islanderDetail.PriorityProducts.length !== 0 ? (
                                                    <>
                                                        <Typography
                                                            variant="h6"
                                                            className="section-title space-top"
                                                        >Additional items to fill the box</Typography>
                                                        <PriorityProductTable data={islanderDetail.PriorityProducts} selectedTab={selectedTab} setOpen2={setOpen2}/>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                        </>
                    ):(
                        <>
                        {islanderDetail.products?.PriorityProducts && islanderDetail.products?.PriorityProducts.length !== 0 ? (
                            <>
                                <Typography
                                    variant="h6"
                                    className="section-title space-top"
                                >Additional items to fill the box</Typography>
                                <PriorityProductTable data={islanderDetail.products.PriorityProducts} selectedTab={selectedTab}/>
                            </>
                        ) : (
                            <></>
                        )}
                        </>
                    )}



                       

                        {islanderDetail.orderStatus >= 4 && selectedTab === 2 ? (
                            <Typography
                                variant="h6"
                                className="section-title section-title-btn space-top"
                            >
                                Box/Barrel Details
                                <Button color="primary" className="btn btn-primary" onClick={() => addBoxItems(islanderDetail.id)}>
                                    Add
                                </Button>
                            </Typography>
                        ):(
                            <Box sx={{pt: 2}}></Box>
                        )}


                {selectedTab === 2 ? (
                        <>
                        {islanderDetail.shipmentBox && islanderDetail.shipmentBox.length !== 0 ? (
                                                     <div className="box-details">
                                                     <div className="box-details__left">
                                                         <BoxTable data={islanderDetail.shipmentBox} total={islanderDetail.totalBoxValue} selectedTab={selectedTab}/>
                     
                                                         {/* <OrderSummaryForm /> */}
                                                     </div>
                                                     <div className="box-details__right">
                                                         <div className="order-summary">
                                                             <Typography
                                                                 variant="h4"
                                                                 className="order-summary__title"
                                                             >
                                                                 Order Summary
                                                             </Typography>
                     
                                                             <div className="order-summary__row">
                                                                 <div className="order-summary__label">
                                                                     Shipping Cost:
                                                                 </div>
                                                                 <div className="order-summary__value">
                                                                     ${FormatPrice(islanderDetail.totalBoxValue)}
                                                                 </div>
                                                             </div>
                                                             <div className="order-summary__row">
                                                                 <div className="order-summary__label">
                                                                     Packaging & Material cost:
                                                                 </div>
                                                                 <div className="order-summary__value">
                                                                     ${FormatPrice(islanderDetail.totalBoxAccessoriesValue)}
                                                                 </div>
                                                             </div>
                                                             <div className="order-summary__row">
                                                                 <div className="order-summary__label">
                                                                     Product cost:
                                                                 </div>
                                                                 <div className="order-summary__value">
                                                                     ${FormatPrice(islanderDetail.totalPriorityProductValue)}
                                                                 </div>
                                                             </div>
                                                             {(islanderDetail.insuranceValue && islanderDetail.insuranceValue !== 0) && (
                                                                        <div className="order-summary__row">
                                                                        <div className="order-summary__label">
                                                                            Insurance:
                                                                        </div>
                                                                        <div className="order-summary__value">
                                                                            ${FormatPrice(islanderDetail.insuranceValue)}
                                                                        </div>
                                                                        </div>
                                                                )}
                     
                                                             <div className="order-summary__row order-summary__row--footer">
                                                                 <div className="order-summary__label">
                                                                     Total Amount
                                                                 </div>
                                                                 <div className="order-summary__value">
                                                                     ${FormatPrice(islanderDetail.totalBoxValue + islanderDetail.totalPriorityProductValue + islanderDetail.totalBoxAccessoriesValue + islanderDetail.insuranceValue)}
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                                ) : (
                                                    <></>
                                                )}
                        </>
                    ):(
                        <>

                        {islanderDetail.products?.shipmentBox && islanderDetail.products?.shipmentBox.length !== 0 ? (
                               <div className="box-details">
                               <div className="box-details__left">
                                   <BoxTable data={islanderDetail.products?.shipmentBox} total={islanderDetail.products?.totalBoxValue} selectedTab={selectedTab}/>

                                   {/* <Form className="edit-order-form customer-form"> */}
                                   <Box sx={{pt:3}}>
                                    <div className="order-summary-form__fields">
                                    <Form.Group
                                            controlId="paymentStatus"
                                            className="edit-order-form__fields"
                                        >
                                            <Form.Label>Payment Status</Form.Label>
                                        
                                            <Form.Select
                                                name="paymentStatus"
                                                value={editableData2.paymentStatus ? 'true' : 'false'}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setEditableData2({ ...editableData2, paymentStatus: selectedValue === 'true' });
                                                }}
                                            // required
                                            >
                                                {/* <option value="" disabled>Select an option</option> */}
                                                {paymentStatus.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.text}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                            <Form.Group
                                            controlId="paymentMethod"
                                            className="edit-order-form__fields"
                                        >
                                            <Form.Label>Payment Method</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="paymentMethod"
                                                value={islanderDetail.payment_detail?.provider || ''}
                                                readOnly
                                            />
                                        </Form.Group>
                                        </div>
                                    <div className="order-summary-form__fields">
                                    <Form.Group
                                        controlId="trackingNo"
                                        className="edit-order-form__fields"
                                    >
                                        <Form.Label>Tracking ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="trackingNo"
                                            value={editableData2.trackingNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                        <Form.Group controlId="billingOfLading" className="edit-order-form__field">
                                        <Form.Label>Billing of Lading</Form.Label>
                                        <div className="file-input-container">
                                            <input
                                                type="file"
                                                name="bol"
                                                accept=".pdf"
                                                onChange={handleFileChange}
                                            />
                                            {editableData.bol !== '' && availableBol ? (
                                                <a
                                                    href={`${process.env.REACT_APP_DatabaseStatic}${editableData2.bol}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className='font-bold font-15 color4'>View BOL</span>
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Form.Group>
									</div>
                                    <div className="order-summary-form__fields">
                                                
                                    {editableData2.paymentStatus === true ? (
                                            <Form.Group
                                            controlId="estimatedDeliveryDate"
                                            className="edit-order-form__fields"
                                            >
                                            <Form.Label>Estimated Delivery Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="estimatedDeliveryDate"
                                                // value={estDate ? new Date(estDate).toISOString().split('T')[0] : ''}
                                                value={editableData2.estDeliveryTimeInDay}
                                                readOnly
                                            />
                                            </Form.Group>
                                    ):<></>}
                                   

                                    <Form.Group
                                        controlId="actualDeliveryDate"
                                        className="edit-order-form__fields"
                                    >
                                        <Form.Label>Actual Delivery Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="actualDeliveryDate"
                                            value={formatDate(editableData2.actualDeliveryDate)}
                                            onChange={handleInputChange}
                                            // value={islanderDetail?.actualDeliveryDate || ''}
                                            // onChange={handleChange}
                                            // readOnly
                                        />
                                    </Form.Group>
                                    </div>

                                   </Box>
									
                               </div>
                               <div className="box-details__right">
                                   <div className="order-summary">
                                       <Typography
                                           variant="h4"
                                           className="order-summary__title"
                                       >
                                           Order Summary
                                       </Typography>

                                       <div className="order-summary__row">
                                           <div className="order-summary__label">
                                               Shipping Cost:
                                           </div>
                                           <div className="order-summary__value">
                                               ${FormatPrice(islanderDetail.products?.totalBoxValue)}
                                           </div>
                                       </div>

                                       <div className="order-summary__row">
                                           <div className="order-summary__label">
                                            Packaging & Material cost:
                                           </div>
                                           <div className="order-summary__value">
                                               ${FormatPrice(islanderDetail.products?.totalBoxAccessoriesValue)}
                                           </div>
                                       </div>

                                       <div className="order-summary__row">
                                           <div className="order-summary__label">
                                               Product cost:
                                           </div>
                                           <div className="order-summary__value">
                                               ${FormatPrice(islanderDetail.products?.totalPriorityProductValue)}
                                           </div>
                                       </div>

                                       {(islanderDetail.insuranceValue && islanderDetail.insuranceValue !== 0) && (
                                            <div className="order-summary__row">
                                            <div className="order-summary__label">
                                                Insurance:
                                            </div>
                                            <div className="order-summary__value">
                                                ${FormatPrice(islanderDetail.insuranceValue)}
                                            </div>
                                            </div>
                                       )}
                                      
                                       <div className="order-summary__row order-summary__row--footer">
                                           <div className="order-summary__label">
                                               Total Amount
                                           </div>
                                           <div className="order-summary__value">
                                               ${FormatPrice(islanderDetail.products?.totalBoxValue + islanderDetail.products?.totalPriorityProductValue + islanderDetail.products?.totalBoxAccessoriesValue + islanderDetail.insuranceValue)}
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        ) : (
                            <></>
                        )}
                        </>
                    )}

            {(islanderDetail.track && islanderDetail.track.length !== 0) && (
                   <div className="order-table-container pt-4">
                        <Typography className="section-title font-20">
                            Shipment Tracking
                        </Typography>
                        {/* <Typography className="section-title font-16 flexBox">
                            <Typography className='font-16 font-600'>Expected Delivery:</Typography>
                            <Typography className='font-16 font-00'>{islanderDetail.estDeliveryTimeInDay}</Typography> 
                        </Typography> */}
                        {islanderDetail.orderStatus >= 4 && (
                            <Button className='b1 b4 mb-3' onClick={addTrackingData}>Add Tracking Details</Button>
                        )}
                        <Typography className="section-title font-18">
                            Tracking Details
                        </Typography>
                        <TableContainer component={Paper} className='trackDetail-table'>
                            <Table aria-label="Tracking Details" className="sortable-table trackingTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Date
                                        </TableCell>
                                        <TableCell>
                                            Status Detail
                                        </TableCell>
                                        <TableCell>
                                            Location
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {islanderDetail.track.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                {formatDate(item.statusUpdate)}
                                            </TableCell>
                                            <TableCell>
                                                {item.description}
                                            </TableCell>
                                            <TableCell>
                                                {item.location}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-title"
                            className="admin-modal"
                            maxWidth="300px"
                        >
                            <Box className="admin-modal__container box-modal__container">
                                <Box className="admin-modal__title-container">
                                    <Typography variant="h6" className="admin-modal__title" align="center">Add Box/Barrel Details</Typography>
                                    {/* <Button color="primary" className="btn" type="submit">
                                        Save
                                    </Button> */}
                                    <CloseIcon onClick={handleClose} />
                                </Box>
                                <Form className="edit-order-form shipping-order-form">
                                    <div className='order-summary-form__fields'>
                                        <Form.Group
                                            controlId="customerName"
                                            className="edit-order-form__field"                                                                                                                                                                         
                                        >
                                            <Form.Label>Size</Form.Label>
                                            {/* <Form.Control
                                                type="text"
                                                name="size"
                                                value={boxData.size}
                                                onChange={handleSizeChange}
                                                // onChange={(e) => setBoxData({ ...boxData, size: e.target.value })}
                                                required
                                            /> */}
                                            		<Form.Select
                                                        name="from"
                                                        value={boxData.size}
                                                        onChange={handleSizeChange}
                                                        required
                                                    >
                                                        <option value="">Select Size</option>
                                                        <option value="L">L</option>
                                                        <option value="M">M</option>
                                                        <option value="S">S</option>
                                                        <option value="20">20 GL</option>
                                                        <option value="30">30 GL</option>
                                                        <option value="55">55 GL</option>
                                                        <option value="75">75 GL</option>
                                                    </Form.Select>
                                        </Form.Group>
                                        <Form.Group
                                            controlId="customerName"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Weight</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="weight"
                                                value={boxData.weight}
                                                onChange={(e) => setBoxData({ ...boxData, weight: e.target.value })}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            controlId="customerName"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Qty</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="qty"
                                                value={boxData.quantity}
                                                onChange={handleQuantityChange}
                                                // onChange={(e) => setBoxData({ ...boxData, quantity: e.target.value })}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            controlId="customerName"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                value={boxData.price}
                                                onChange={(e) => setBoxData({ ...boxData, price: e.target.value })}
                                                readOnly
                                                // disabled={isPriceFieldDisabled}
                                                style={{"backgroundColor":"gray"}}
                                      
                                            />
                                        </Form.Group>

                                    </div>
                                    <Box align="center" sx={{ pt: 4 }}>
                                    {isAddingPrice ? (
                                             <Button color="primary" className="b1 b5 colo2" onClick={addPrice}>
                                             Add Price
                                         </Button>
                                        ) : (
                                            <Button color="primary" className="b1 b5 colo2" onClick={() => addBox(islanderDetail.id)}>
                                            Save
                                        </Button>
                                        )}
                                       
                                    </Box>
                                </Form>
                            </Box>

                        </Modal>


                        <Modal
                            open={openImageDialog}
                            onClose={handleCloseImageDialog}
                            aria-labelledby="modal-title"
                            className="admin-modal"
                            maxWidth="300px"
                        >
                            <Box className="admin-modal__container box-modal__container">
                                <Box className="admin-modal__title-container">
                                    <Typography variant="h6" className="admin-modal__title" align="center">Upload Image Of Box</Typography>
                                </Box>
                                <Form className="edit-order-form shipping-order-form">
                                    <div className='order-summary-form__fields'>
                                   
                                        <Form.Group
                                            controlId="image"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Upload Image</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="image"
                                                accept=".jpg, .jpeg, .png, .pdf, .svg"
                                                onChange={handleImgBox}
                                                required
                                            />
                                        </Form.Group>
                                        {selectedFile && (
                                            <Typography className='font-15 color4'>Selected Image: {selectedFile.name}</Typography>
                                        )}
                                    </div>
                                    <Box align="center" sx={{ pt: 4 }}>
                                        <Button color="primary" className="b1 b5 colo2" onClick={() => addImg(islanderDetail.id)}>
                                            Add
                                        </Button>
                                    </Box>
                                </Form>
                            </Box>

                        </Modal>

                        <Modal
                            open={openTrackingBox}
                            onClose={handleCloseTackingBox}
                            aria-labelledby="modal-title"
                            className="admin-modal"
                            maxWidth="600px"
                        >
                            <Box className="admin-modal__container box-modal__container_tracking">
                                <Box className="admin-modal__title-container">
                                    <Typography variant="h6" className="admin-modal__title" align="center">Add Tracking Details</Typography>
                                </Box>
                                <form>
                                    <Box className="edit-order-form shipping-order-form">
                                        <div className=''>
                                            <Form.Group
                                                controlId="date"
                                                className="edit-order-form__field mb-2"
                                            >
                                                <Form.Label>Date</Form.Label>
                                                <DatePicker
                                                    selected={trackingData.statusUpdateDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="dd/MM/yyyy"
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                controlId="description"
                                                className="edit-order-form__field mb-2"
                                            >
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="description"
                                                    value={trackingData.description}
                                                    onChange={handleDescriptionChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                controlId="location"
                                                className="edit-order-form__field"
                                            >
                                                <Form.Label>Location</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="location"
                                                    value={trackingData.location}
                                                    onChange={(e) => setTrackingData({ ...trackingData, location: e.target.value })}
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                        <Box align="center" sx={{ pt: 4 }}>
                                            <Button color="primary" className="b1 b5 colo2" type="button" onClick={() => handleTrackingData(islanderDetail.id)}>
                                                Add
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>

                        </Modal>

                        <Modal
							open={openDetail}
							onClose={handleCloseDetail}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
							className={classes.modalBox}
							>
							<Box sx={style}>
                                <Button className="b1 b4 printPdfBtn" onClick={handlePrint}>
                                    Print
                                </Button> 
								<Box ref={dialogRef} sx={{p: 4,'@media (max-width: 768px)': {p: 2,},'@media (max-width: 480px)': {p: 1},}}>
									<Box>
										<Box className={classes.imgBox}>
											<img src='/assets/img/web_logo.png' style={{"maxWidth" : "100%"}}></img>
										</Box>
									</Box>
									<Box sx={{pt:3, pb:3}}>
										<Row>
											<Col item xs={12} sm={12} md={8} lg={8} xl={8} className=''>
												<Box>
													<Typography className='font-bold font-20'>ISLE OF FREIGHT INC.</Typography>
													<Typography className='font-16 font-600'><i>We help families help families</i></Typography>
												</Box>
											</Col>
											<Col item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.flexEnd}>
												<Typography className='font-bold font-25'>INVOICE</Typography>
											</Col>
										</Row>
										<Box sx={{pt:3, pb:3}}>
											<Row className='invoiceBox'>
												<Col item xs={12} sm={12} md={12} lg={6} xl={6} className=''>
													<Box>
														<Typography>246 Stewart Green SW</Typography>
														<Typography>Unit #6188</Typography>
														<Typography>Calgary,AB T3H 3C8</Typography>
														<Typography>Phone: (825)-901-3955</Typography>
														<Typography>Email: support@isleoffreight.com</Typography>
													</Box>
												</Col> 
												<Col item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.flexEnd}>
													<Box>
														<Typography className=''>INVOICE  <b>{invoiceNo}</b></Typography>
														<Typography className=''>Date: {formatDate(orderInvoiceDetail.createdAt)}</Typography>
													</Box>
												</Col>
											</Row>
										</Box>
										<Box sx={{pb:3}}>
											<Row className='invoiceBox'>
													<Col item xs={12} sm={12} md={12} lg={6} xl={6} sx={{mb: 2}} className=''>
														{orderInvoiceDetail.billingAddress &&
															<Box>
																<Typography className='font-bold'>BILL TO:</Typography>
																<Typography>{orderInvoiceDetail.billingAddress.fullName}</Typography>
																<Typography>{orderInvoiceDetail.billingAddress.address_line_1}, {orderInvoiceDetail.billingAddress.address_line_2} ,{orderInvoiceDetail.billingAddress.city}-{orderInvoiceDetail.billingAddress.zip}</Typography>
																<Typography>{orderInvoiceDetail.billingAddress.state}, {orderInvoiceDetail.billingAddress.country}</Typography>
															</Box>
														}
													</Col>
													<Col item xs={12} sm={12} md={12} lg={6} xl={6} sx={{mb: 2}} className=''>
														{orderInvoiceDetail.shippingAddress &&
															<Box>
																<Typography className='font-bold'>SEND TO:</Typography>
																<Typography>{orderInvoiceDetail.shippingAddress.fullName}</Typography>
																<Typography>{orderInvoiceDetail.shippingAddress.address_line_1}, {orderInvoiceDetail.shippingAddress.address_line_2} ,{orderInvoiceDetail.shippingAddress.city}-{orderInvoiceDetail.shippingAddress.zip}</Typography>
																<Typography>{orderInvoiceDetail.shippingAddress.state}, {orderInvoiceDetail.shippingAddress.country}</Typography>
															</Box>
														}
													</Col>
											</Row>
										</Box>
										<Box sx={{ pb:3}} className='invoiceBox'>
											<Typography className='font-bold'>COMMENTS OR SPECIAL INSTRUCTIONS:</Typography>
											{orderInvoiceDetail.orderType === "Purchase" && 
												<Typography className=''>Packaging Items</Typography>
											}
											{orderInvoiceDetail.orderType === "Send" && 
												<Typography className=''>Barrel Shipment</Typography>
											}
											{orderInvoiceDetail.orderType === "Islander" && 
												<Typography className=''>Islander Box Shipment</Typography>
											}
										</Box>
										<Box sx={{pb:3}}>
										<table striped bordered className='historyTable'>
											<thead>
												<tr>
													<th>VENDOR ID</th>
													<th>QUANTITY</th>
													<th width="320px">DESCRIPTION</th>
													<th>ITEM VALUE</th>
													<th width="150px">TOTAL</th>
												</tr>
											</thead>
											<tbody>
											{productDetail && productDetail.length !== 0 ? (
												<>
												{productDetail.map((item =>
													<tr>
														<td>
															{orderInvoiceDetail.orderType === "Islander" ?  item.url : "IOF"}
														</td>
														<td>
                                                            {orderInvoiceDetail.orderType === "Islander" ?  item.receivedQuantity : item.quantity }
                                                        </td>    
														<td>{orderInvoiceDetail.orderType === "Send" &&
                                                        (
                                                            <>
                                                                Barrel ({item.product?.size}GL) - {item.product?.description} 
                                                            </>  
                                                        )}
                                                        {orderInvoiceDetail.orderType === "Purchase" &&
                                                        (
                                                            <>
                                                                {item.product.name} ({item.product.size}GL)
                                                            </>  
                                                        )}
                                                        {orderInvoiceDetail.orderType === "Islander" &&
                                                        (
                                                            <>
                                                                {item.description}
                                                            </>  
                                                        )}
                                                        </td>
														<td>
                                                            {orderInvoiceDetail.orderType === "Islander" ?  item.itemValue : item.product?.itemValue }
                                                        </td> 
                                                        <td align='right'>
                                                            {orderInvoiceDetail.orderType === "Islander" ?  '$0.00' : `$${FormatPrice(item.itemPrice)}` }
                                                        </td> 
														{/* <td>${item.itemPrice}</td> */}
													</tr>
												))}
												</>
                                                
											):<></>
                                            }
                                            {/* {boxDetail && boxDetail.length !== 0 ? (
                                                <>
                                                {boxDetail.map((item =>
													<tr>
                                                        <td>IOF</td>
                                                        <td>{item.quantity}</td>
                                                        <td>Box({item.size})</td>
                                                        <td></td>
                                                        <td align='right'>${item.price}</td>
                                                    </tr>
                                                ))}
                                                </>
                                            ):<></>
                                            } */}
                                            {priorityProductDetail && priorityProductDetail.length !== 0 ? (
                                                <>
                                                {priorityProductDetail.map((item =>
													<tr>
                                                        <td>IOF</td>
                                                        <td>{item.quantityPacked}</td>
                                                        <td>{item.islander_product?.name}</td>
                                                        <td>{item.priorityProductValue}</td>
                                                        <td align='right'>${FormatPrice(item.price * item.quantityCharged)}</td>
                                                    </tr>
                                                ))}
                                                </>
                                            ):<></>
                                            }
											<tr>
												<td></td><td></td><td></td><td></td><td></td>
											</tr>
											<tr>
												<td></td><td></td><td></td><td></td><td></td>
											</tr>
											<tr>
												<td></td><td></td><td></td><td></td><td></td>
											</tr>
											<tr>
												<td colSpan={3}></td>
												<td align='right'>SUBTOTAL</td>
												<td align='right'>
                                                {orderInvoiceDetail.orderType === "Send" ? (
                                                        <>
                                                            ${FormatPrice(orderInvoiceDetail.shippingCharge)}
                                                        </>
                                                    ):(
                                                        <>
                                                            ${FormatPrice(orderInvoiceDetail.productPrice)}
                                                        </>
                                                    )}
                                                    </td>
											</tr>
											<tr>
												<td colSpan={3}></td>
												<td align='right'>TAX({orderInvoiceDetail.taxPercentage}%)</td>
												<td align='right'>${FormatPrice(orderInvoiceDetail.tax)}</td>
											</tr>
                                            {orderInvoiceDetail.insuranceValue && (
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td align='right'>Insurance</td>
                                                    <td align='right'>${FormatPrice(orderInvoiceDetail.insuranceValue)}</td>
											    </tr>
                                            )}
                                            
											<tr>
												<td colSpan={3}></td>
												<td align='right'>SHIPPING & HANDLING</td>
												<td align='right'>
                                                    {orderInvoiceDetail.orderType === "Send" ? (
                                                        <>
                                                            $0.00
                                                        </>
                                                    ):(
                                                        <>
                                                            ${orderInvoiceDetail.shippingCharge > 0 ? FormatPrice(orderInvoiceDetail.shippingCharge) : 0}
                                                        </>
                                                    )}
                                                  
                                                </td>
											</tr>
                                            {orderInvoiceDetail.orderType === "Islander" && (
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td align='right'>Packaging & Material cost</td>
                                                    <td align='right'>${FormatPrice(orderInvoiceDetail.products.totalBoxAccessoriesValue)}</td>
											    </tr>
                                            )}
											<tr>
												<td colSpan={3}></td>
												<td align='right' className='font-bold'>TOTAL</td>
												<td align='right' className='font-bold'>${FormatPrice(orderInvoiceDetail.totalPrice)}</td>
											</tr>
											</tbody>
										</table>
										</Box>
									</Box>
								</Box>
							</Box>
							</Modal>
                    </div>




                    {/* <div className="your-order-total-box">
                        <Typography variant="h6" className="section-title">
                            Your Order
                        </Typography> */}
                    {/* <YourOrderTable orderId={orderId} orderDetail={orderDetail} /> */}
                    {/* </div> */}
                </div>
            </Box>
        </>
    );
}

export default IslanderOrderDetailEdit;
