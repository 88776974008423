import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Box,
	Menu,
	MenuItem,
	Checkbox, Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
    Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchHelpCategories, fetchQuestion, fetchPopularQuestion } from "../../redux/helpCenterSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Editor } from '@tinymce/tinymce-react';
import { Form } from "react-bootstrap";
import { identifier } from "@babel/types";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const QuestionListTable = () => {
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const dispatch = useDispatch();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
    const [isCustom, setIsCustom] = useState(false);
	const [open, setOpen] = useState(false);
	
	const [data, setData] = useState({
		"question": '',
		"answer" : '',
        "category":'',
        "popular":false
	});
    const [error, setError] = useState('');

	const questions = useSelector((state) => state.helpCenter.questionList);
    const categories = useSelector((state) => state.helpCenter.helpCategoryList);

	const openDeleteModal = (id) => {
		  
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};


	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		try {
			const response = await Request.get(`utils/helpCentre/question/${id}`, [], true);
			if (response.length !== 0) {
				if (response) {
					setOpen(true);
					const newContentToAppend = " ";
					const updatedAnswer = response.answer + newContentToAppend;
					setData({
						...data,
						"question": response.question,
                        "category": response.category,
                        "popular": response.popular,
						"answer": updatedAnswer,
					  });
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleCloseDialog = () => {
		setSelectedProductEdit(null);
		setOpen(false);
	};

    const handleFieldChange = (field, value) => {
		setData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        if (selectedCategory === 'custom') {
          // Enable custom category input
          setIsCustom(true);
        } else {
          // Disable custom category input and update the selected category
          setIsCustom(false);
          handleFieldChange('category', selectedCategory);
        }
      };

    const handleEditorChange = (content, editor) => {
        setData((prevData) => ({
			...prevData,
			answer: content,
		}));
      };

    const handleCheckboxChange = (fieldName) => {
		  
        setData({
          ...data,
          [fieldName]: !data[fieldName], // Toggle the value of the checkbox
        });
      };

	// const handleNameChange = (e) => {
	// 	const newName = e.target.value;
	// 	setEditedCategoryData((prevData) => ({
	// 	  ...prevData,
	// 	  name: newName,
	// 	}));
	//   };
	useEffect(() => {
		dispatch(fetchQuestion());
        dispatch(fetchHelpCategories());
	}, []);

	useEffect(() => {
		dispatch(fetchQuestion());
        dispatch(fetchHelpCategories());
	}, [dispatch]);

	const productDelete = async () => {
		try {
              
			const response = await Request.delete(`admin/helpCentre/question/${selectedProduct}`, [], true);
            if (response.status === 204) {
                setIsDeleteDialogOpen(false)
                dispatch(fetchQuestion())
            }
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

    const uploadImage = (blobInfo) => {
        return new Promise((resolve, reject) => {
		let accessToken = cookies.get('accessToken', { path: '/' });
          const formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
		
		  const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        //   fetch('https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/helpCentre/uploadImageForHelpCentre', {
          fetch(`${process.env.REACT_APP_DatabaseServer}admin/helpCentre/uploadImageForHelpCentre`, {
            method: 'POST',
			headers: headers,
            body: formData
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Image upload failed');
              }
            })
            .then(data => {
              resolve(data.url);
            })
            .catch(error => {
              console.error('Error uploading image:', error);
              reject('Error uploading image');
            });
        });
    };

	const handleSubmit = async (e) => {
		e.preventDefault()

        if (!data.question || !data.category || !data.answer) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
              
			const response = await Request.put(`admin/helpCentre/question/${selectedProductEdit}`, data , true);
			  
			if (response.status === 200) {
				 
				setOpen(false)
                setIsCustom(false)
				dispatch(fetchQuestion())
                dispatch(fetchHelpCategories());
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
		// if (selectedProductEdit) {
		// 	dispatch(updateProductCategories({ id: selectedProductEdit, item: editedCategoryData.name }))
		// 		.then((result) => {
		// 			if (updateProductCategories.fulfilled.match(result)) {
		// 				// dispatch(fetchProductCategories())
		// 				setOpen(false)
		// 			} else {
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.error("Error updating customer:", error);
		// 		});
		// }
	  };

	return (
		<Box className="order-table-container product-categories-table-container">
			<TableContainer
				component={Paper}
				className="product-categories-table"
			>
				<Table>
					<TableHead>
						<TableRow>

							<TableCell className="textLeft">Question</TableCell>
							<TableCell>Category</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(questions && questions.length>0) && questions.map((question) => (
							<TableRow
								key={question.id}
							>
								<TableCell className="textLeft">{question.question}</TableCell>
								<TableCell>{question.category}</TableCell>
								<TableCell>
									<IconButton
										aria-label="Edit"
										onClick={() => openEditModal(question.id)} 
										// Replace with your edit logic
									>
										<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
									<IconButton
										aria-label="Delete"
										onClick={() => openDeleteModal(question.id)} // Replace with your delete logic
									>
										<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this Question?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					
					<DialogTitle className="admin-modal__title-container"	>
						Edit Question
						<Button color="primary" className="btn" onClick={handleSubmit}>
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form add-category-form">
                            <div className="row add-product-form__row">
                                    <Typography className="font-15 font-600" style={{ color: 'red' }}>{error}</Typography>
                                    
                                    <Form.Group controlId="question" className="shipping-price-form__field">
                                    <Form.Label>Enter Question</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data.question}
                                        placeholder="Enter your question"
                                        onChange={(e) => handleFieldChange("question", e.target.value)}
                                        required
                                    />
                                    </Form.Group>

                                    {/* <Form.Group controlId="category" className="shipping-price-form__field">
                                    <Form.Label>Select Category</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={data.category}
                                        onChange={(e) => handleFieldChange("category", e.target.value)}
                                        required
                                    >
                                       {categories.map(option => (
                                            <option key={option.id} value={option.category}>
                                                {option.category}
                                            </option>
										))}
                                    </Form.Control>
                                    </Form.Group> */}

                                    <Form.Group controlId="category" className="shipping-price-form__field">
                                    <Form.Label>Select Category</Form.Label>
                                     {isCustom ? (
                                    <Form.Control
                                        type="text"
                                        value={data.category}
                                        onChange={(e) => handleFieldChange('category', e.target.value)}
                                        placeholder="Enter custom category"
                                        required
                                    >
                                    </Form.Control>
                                    ):(
                                    <Form.Control
                                        as="select"
                                        value={data.category}
                                        // onChange={(e) => handleFieldChange("category", e.target.value)}
                                        onChange={handleCategoryChange}
                                        required
                                    >
                                       {(categories && categories.length>0) && categories.map(option => (
                                            <option key={option.id} value={option.category}>
                                                {option.category}
                                            </option>
										))}
                                        <option value="custom">Add custom category</option>
                                    </Form.Control>)}
                                    </Form.Group>

                                    <Form.Group controlId="answer" className="shipping-price-form__field">
                                    <Form.Label>Enter Answer</Form.Label>
                                    <Editor
                                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            value={data.answer}
                                            init={{
                                                plugins: 'image lists link autolink',
                                                toolbar: 'bold italic underline undo redo | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image',
                                            	automatic_uploads: true,
												images_upload_handler: uploadImage,
												// convert_urls: false,
												default_link_target: '_blank',
												link_assume_external_targets: true,
												link_context_toolbar: true,
												link_default_protocol: 'https',
												paste_preprocess: function(plugin, args) {
													args.content += ' ';
												},
												urlconverter_callback: function(url, node, on_save, name) {
												  // Prevent conversion of & to &amp;
												  return url.replace(/&amp;/g, '&');
												}
                                            
                                    }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="popular" className="shipping-price-form__field">
                                        <label htmlFor="popular" className="font-600 d-flex align-items-center gap-2">
                                            <input
                                                type="checkbox"
                                                label="Save as Popular Question"
                                                id="popular"
                                                checked={data.popular}
                                                onChange={() => handleCheckboxChange("popular")}
                                                style={{width: "15px",height: "15px"}}
                                            />
                                            Save as Popular Question
                                        </label>
                                    </Form.Group>
								</div>
							</Box>
						</Box>
					</DialogContent>
			</Dialog>
		</Box>


	);
};

export default QuestionListTable;
