import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    sendShippingPriceList: [],
    islanderShippingPriceList: [],
    packagingPriceList: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch
  export const fetchSendShippingPrice = createAsyncThunk(
    "customer/sendShippingPriceList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("utils/getShippingRate", [], true);
            if (response.sendShippingRate) {
                 
                return response.sendShippingRate
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  // Async thunk action to fetch customers
  export const fetchIslanderShippingPrice = createAsyncThunk(
    "customer/islanderShippingPriceList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("utils/getIslanderShippingRateSize", [], true);
            if (response.islanderShippingRate) {
                 
                return response.islanderShippingRate
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

    // Async thunk action to fetch customers
    export const fetchPackagingPrice = createAsyncThunk(
      "customer/packagingPriceList",
      async (_, { rejectWithValue }) => {
          try {
              const response = await Request.get("admin/rate/purchase/shipping", [], true);
              if (response) {
                   
                  return response
              } else {
                  console.error("Failed to fetch data from API");
              }
              } catch (error) {
                  return rejectWithValue(error);
              }
      }
    );
  

  const shippingPriceSlice = createSlice({
    name: "shippingPrice",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchSendShippingPrice.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchSendShippingPrice.fulfilled, (state, action) => {
            state.loading = false;
            state.sendShippingPriceList = action.payload;
          })
          .addCase(fetchSendShippingPrice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchIslanderShippingPrice.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchIslanderShippingPrice.fulfilled, (state, action) => {
            state.loading = false;
            state.islanderShippingPriceList = action.payload;
          })
          .addCase(fetchIslanderShippingPrice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchPackagingPrice.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPackagingPrice.fulfilled, (state, action) => {
            state.loading = false;
            state.packagingPriceList = action.payload;
          })
          .addCase(fetchPackagingPrice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
      },
  });
  
  export default shippingPriceSlice.reducer;