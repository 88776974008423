import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container, Box, Typography, List, ListItem, ListItemIcon, ListItemText, Grid, Input, Button } from '@mui/material';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Request from '../customUtils/Http/Request';
import SendIcon from '@mui/icons-material/Send';
import { ChevronRight } from "@mui/icons-material";
import EastIcon from '@mui/icons-material/East';
import { makeStyles } from '@material-ui/styles';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
    flexBox: {
        display: "flex",
        alignItems: "center",
        gap: "15px",

        '& .flexChild': {
            color: "#FFC72C",
            fontFamily: "Roboto, sans-serif !important",
            fontWeight: "700 !important",
            fontSize: "20px !important"
        },

        '& svg': {
            color: "#FFC72C",
        },
    //     // '@media (max-width: 768px)': {
    //     //     '& .flexChild': {
    //     //         fontSize: "15px"
    //     //     },
    //     //     '& svg': {
    //     //         // Add styles specific to the new layout
    //     //     },
    // },

        
    },
    flexBox2: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
     
        '@media (max-width: 1400px) and (min-width: 1200px)': {
            display: "block", // Change to a different layout for screens up to 768px wide
            '& .flexChild': {
              // Add styles specific to the new layout
            },
            '& svg': {
              // Add styles specific to the new layout
            },
        },
        '@media (max-width: 768px)': {
            display: "block", // Change to a different layout for screens up to 768px wide
            '& .flexChild': {
              // Add styles specific to the new layout
            },
            '& svg': {
              // Add styles specific to the new layout
            },
        },
    },
    footerMenu: {
        '& span': {
            fontFamily: "Roboto, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "16px !important"
        },
    },
    footer_header_border: {
        width: "50px",
        height: "2px",
        background: "#FFC72C !important",
        borderColor: "#FFC72C !important",
        margin: "5px 0px",
        opacity: "1",
    }

}));


function Footer() {
    const classes = useStyles();
    const [subscribeEmail, setSubscribeEmail] = useState('');
    const [emailNotification, setEmailNotification] = useState(false);

    const addEmail = (value) => {
        setSubscribeEmail(value)
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

          
        if(subscribeEmail === ''){
            alert("Please enter your email address");
            return
        }
        else{
            try {
                const response = await Request.post(`utils/addSubscriber`, {"email" : subscribeEmail});
                if (response.message === "Success") {
                    setEmailNotification(true);
                    setSubscribeEmail('')
                    setTimeout(() => {
                        setEmailNotification(false)
                    }, 2000);
                
                } else {
                    alert("Email not added. Please try again.")
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }
  
    }
    
    return (
        <div className='footer'>
            <Container maxWidth="xl">
                {/* <div className='footer_block1'>
                    <Row className='footer_row'>
                        <Col xs={12} sm={12} md={6}>
                            <Typography variant='h4' className='footer_title footer_subscription_title'>Subscription News</Typography>
                            <Typography>Get updates for new products</Typography>
                        </Col>
                        <Col xs={12} sm={12} md={6} className='footer_column'>
                            <Box sx={{ '& > :not(style)': { m: 1, backgroundColor : '#fff', display: "flex", justifyContent: "end", borderRadius: "5px"} }}>
                                <FormControl variant="standard">
                                    <Input sx={{ minWidth: "300px",pt: 1, pb: 1,pl: 2, }}
                                    id="input-with-icon-adornment"
                                    placeholder="Your email address"
                                    endAdornment={
                                        <InputAdornment position="end" sx={{pr: 1,}}>
                                            <SendIcon sx={{ 
                                                color: "#FFC72C", 
                                                transform: "rotate(-45deg)",
                                            }}/>
                                        </InputAdornment>
                                    }
                                    />
                                </FormControl>
                            </Box>
                        </Col>
                    </Row>
                </div> */}
                <div>
                    <Box className='footer_row1'>
                        <Box sx={{ minWidth: "27%" }}>
                            <Box sx={{width: "185px", height: "75px",'@media (max-width: 575px)': {width: "185px", height: "50px"},}}>
                                <img src="/assets/img/footer_logo.svg" className='logo_img footer_logo' />
                            </Box>
                            <Typography className='footer_description'>We help families help families</Typography>
                            <Box className={classes.flexBox2}>
                                <Box className={classes.flexBox}>
                                    <Typography className='flexChild'>Follow Us</Typography>
                                    <EastIcon></EastIcon>
                                </Box>
                                <Box className={classes.flexBox}>
                                {/* <Link to="" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon1.png" alt="linkedIn" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                                <Link to="" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon2.png" alt="twitter" className='logo_img footer_logo' />
                                    </Grid>
                                </Link> */}
                                <Link to="https://www.instagram.com/isleoffreight/" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon3.png" alt="instagram" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                                <Link to="https://www.facebook.com/profile.php?id=61551618483721" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon4.png" alt="facebook" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                                <Link to="https://www.youtube.com/channel/UCod2pCpZvT6WZRzUDWdV4BA" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon5.png" alt="youtube" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                                </Box>
                               
                            </Box>

                            <form onSubmit={handleEmailSubmit} >
                                <Box sx={{position: "relative",pb:4}}>
                                    <Box className={classes.flexBox} sx={{ gap: "0px", height: "45px", mt: 3 }}>
                                        <Form.Control  required value={subscribeEmail} className="footerInput" type="email" placeholder='Email Address' onChange={(e) => addEmail(e.target.value)} />
                                        <Button className='subscribeBtn' type="submit">Subscribe</Button>   
                                    </Box>
                                    {emailNotification && (<Typography className='font-15 color4 font-600 mt-1 subscribeNotification'>Subscribed Successfully.</Typography>)}
                                </Box>
                             
                             </form>

                           

                            {/* <Grid className='social_box'>
                                <Link href="" className="color">
                                    <Grid className='social_inner'>
                                        
                                    </Grid>
                                </Link>
                                <Link href="" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon2.png" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                                <Link href="" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon3.png" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                                <Link href="" className="color">
                                    <Grid className='social_inner'>
                                        <img src="/assets/img/footer_social_icon4.png" className='logo_img footer_logo' />
                                    </Grid>
                                </Link>
                            </Grid> */}
                        </Box>
                        <Box>
                            <Typography variant='h6' className='footer_title'>About IOF</Typography>
                            <hr className={classes.footer_header_border}></hr>
                            <List className='listBox'>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/brand-story" className="color"><ListItemText primary="Brand Story" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/Community" className="color"><ListItemText primary="Community" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/careers" className="color"><ListItemText primary="Careers" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/privacy-policy" className="color"><ListItemText primary="Legal & Privacy Policies" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <Typography variant='h6' className='footer_title'>What We Provide</Typography>
                            <hr className={classes.footer_header_border}></hr>
                            <List className='listBox'>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/services/buy-a-barrel" className="color"><ListItemText primary="Buy a Barrel" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/services/ship-a-barrel" className="color"><ListItemText primary="Ship a Barrel" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/services/packaging-items" className="color"><ListItemText primary="Packaging Items" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/services/ship-islander-box" className="color"><ListItemText primary="Ship an Islander Box" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <Typography variant='h6' className='footer_title'>Resources</Typography>
                            <hr className={classes.footer_header_border}></hr>
                            <List className='listBox'>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/weeklyDeals" className="color"><ListItemText primary="Weekly Deals" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/help-center/answer?category=General&id=7af86d8e-0a8e-4d87-9221-086f873d6ea9" className="color"><ListItemText primary="Prohibited List" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/help-center/answer?category=Island Agents&id=9dc2a883-bff0-4bfe-9cf4-595a742f3700" className="color"><ListItemText primary="Island Info" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to='/help-center/question?category=FAQs' className="color">
                                        <ListItemText primary="FAQs" className={classes.footerMenu}></ListItemText>
                                    </Link>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <Typography variant='h6' className='footer_title'>Support</Typography>
                            <hr className={classes.footer_header_border}></hr>
                            <List className='listBox'>
                                {/* <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to='mailto:support@isleoffreight.com' className="color"><ListItemText primary="Support" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem> */}
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/contact-us" className="color"><ListItemText primary="Contact Us" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="https://wa.me/+18259013955" className="color"><ListItemText primary="WhatsApp" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/help-center" className="color"><ListItemText primary="Help Center" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ChevronRight />
                                    </ListItemIcon>
                                    <Link to="/customerReview" className="color"><ListItemText primary="Customer Reviews" className={classes.footerMenu}></ListItemText></Link>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Typography  className='footer_description font-14 footer-copyright'>© 2023 Isle of Freight, Inc. All rights reserved.</Typography>
                </div>
            </Container>
            {/* <hr className='footer_divider'></hr>
            <Container maxWidth="xl">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='footer_block2'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography>2023©cbd Farmacy. All rights reserved.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography align='right'>Cookie Policy - Privacy Policy - Terms & Conditions</Typography>
                    </Grid>
                </Grid>
            </Container> */}
        </div>
    );
}

export default Footer;