import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TableSortLabel,
} from "@mui/material";
import CustomerForm from "./customerOrderEditForm";
import OrderSummaryForm from "./orderSummaryForm";
import YourOrderTable from "./yourOrderTable";
import ShippingForm from "./shippingOrderForm";
import Request from '../customUtils/Http/Request';
import StatusList from "./statusList";
import OrderDetailEdit from "./orderDetailEdit";
import IslanderOrderDetailEdit from "./IslanderOrderEdit";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FormatPrice } from "../commonFunctions/commonPriceFunction";

export const PriorityProductTable = ({ data, selectedTab,setOpen2}) => {
	const [sortedField, setSortedField] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");
	const [editedQuantities, setEditedQuantities] = useState({});
	const [editedData, setEditedData] = useState({});

	// const handleSort = (field) => {
	// 	const isAsc = sortedField === field && sortDirection === "asc";
	// 	setSortDirection(isAsc ? "desc" : "asc");
	// 	setSortedField(field);
	// };

	// const sortedData = data.sort((a, b) => {
	// 	const aValue = a[sortedField];
	// 	const bValue = b[sortedField];

	// 	if (sortDirection === "asc") {
	// 		return aValue > bValue ? 1 : -1;
	// 	} else {
	// 		return aValue < bValue ? 1 : -1;
	// 	}
	// });

    const handleDelete = async (id) => {
        try {
            const response = await Request.delete(`islander/iDeletePriorityProduct/${id}`, [], true);
            if (response.ok) {
            } else {
              console.error('Failed to delete row from API');
            }
          } catch (error) {
            console.error('Error deleting row from API:', error);
          }
    }


	const handleEdit = (rowIndex) => {
		// Create a copy of the edited quantities state and set edit mode for the specific row
		const updatedEditedQuantities = { ...editedQuantities };
		updatedEditedQuantities[rowIndex] = true;
		setEditedQuantities(updatedEditedQuantities);
	  };
	
	  const handleInputChange = (event, rowIndex) => {
		// Update the edited quantity for the specific row as the user types
		const { value } = event.target;
		const updatedEditedQuantities = { ...editedQuantities };
		updatedEditedQuantities[rowIndex] = value;
		setEditedQuantities(updatedEditedQuantities);
	  };

	  const handleSave = async (rowIndex, event) => {
		  
		if (editedQuantities[rowIndex] !== undefined) {
		  const updatedQuantity = parseInt(editedQuantities[rowIndex], 10);
		  if (!isNaN(updatedQuantity)) {
			// Send the updated quantity and ID to your API here
			const itemId = data[rowIndex].id;
			const item = {
				"packedQuantity":updatedQuantity
			}
			try {
				let response = await Request.put(`admin/islander/iPriorityItemsUpdate/${itemId}`, item, true);
				if (response) {
					setOpen2(false)
					// window.location.reload();
				//   setIslanderDetail(response);
				} else {
				  console.error('Failed to fetch data from API');
				}
			  } catch (error) {
				console.error('Error fetching data from API:', error);
			  }
			}
		  }
		  // Exit edit mode for this row
		  const updatedEditedQuantities = { ...editedQuantities };
		  delete updatedEditedQuantities[rowIndex];
		  setEditedQuantities(updatedEditedQuantities);
	  };

	return (
		<div className="order-table-container">
			<TableContainer component={Paper}>
				<Table className="sortable-table">
					<TableHead>
						<TableRow>
							<TableCell>
								<label
									
								>
									Item
								</label>
							</TableCell>
							<TableCell>
								<label
									
								>
									Priority
								</label>
							</TableCell>
							<TableCell>
								<label
									
								>
									Qty Requested
								</label>
							</TableCell>
							<TableCell>
								<label
									
								>
									Qty Added
								</label>
							</TableCell>
							<TableCell>
								<label
									
								>
									Qty Changed During Payment
								</label>
							</TableCell>
							<TableCell>
								<label
									
								>
									Price 
								</label>
							</TableCell>
							<TableCell>
								<label
									
								>
									Sub Total
								</label>
							</TableCell>
							{selectedTab === 2 && (
								<TableCell></TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{(data && data.length>0) && data.map((item,rowIndex) => (
							<TableRow key={item.id}>
								<TableCell>{item.islander_product?.name || ''}</TableCell>
								<TableCell>
                                    {item.priority}
								</TableCell>
								<TableCell>{item.quantityRequested}</TableCell>
								<TableCell>
								{editedQuantities[rowIndex] !== undefined ? (
										<input
										type="number"
										value={editedQuantities[rowIndex]}
										onChange={(event) => handleInputChange(event, rowIndex)}
										// onKeyDown={(event) => handleEnterKey(rowIndex, event)}
										/>
									) : (
										<span>{item.quantityPacked}</span>
									)}
								</TableCell>
								<TableCell>{item.quantityCharged}
									{/* {editedQuantities[rowIndex] !== undefined ? (
										<input
										type="number"
										value={editedQuantities[rowIndex]}
										onChange={(event) => handleInputChange(event, rowIndex)}
										// onKeyDown={(event) => handleEnterKey(rowIndex, event)}
										/>
									) : (
										<span>{item.receivedQuantity}</span>
									)} */}
								</TableCell>
                                <TableCell>${FormatPrice(item.price)}</TableCell>
								<TableCell>
                                    ${FormatPrice(item.price * item.quantityCharged)}
								</TableCell>
								{selectedTab === 2 && (
									<TableCell>
								{editedQuantities[rowIndex] !== undefined ? (
											<button onClick={() => handleSave(rowIndex)}>Save</button>
										) : (
											// Edit button
											<button onClick={() => handleEdit(rowIndex)}>Edit</button>
										)}
                                       {/* <DeleteOutlineIcon onClick={() => handleDelete(item.id)}/> */}
								</TableCell>
								)}
								
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			
			 
		</div>
	);
};