import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import Request from '../customUtils/Http/Request';
import ShippingPriceTable from "./shippingPriceTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../redux/userSlice";
import UsersListTable from "./userListTable";

function UsersList() {
	const [openDialog, setOpenDialog] = useState(false);
	const dispatch = useDispatch();
	const [toCountryList, setToCountryList] = useState([]);
	const [clickedBtn, setClickedBtn] = useState("");
	const [errorMessage, setErrorMessage] = useState('');
	const [fromCountryList, setFromCountryList] = useState([]);
	const [isValid, setIsValid] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [formData, setFormData] = useState({
        firstName: "",
		lastName: "",
		email: "",
        phone: "",
        userType: 2
	});

	const adminUserList = useSelector((state) => state.userList.userList);

	useEffect(() => {
		dispatch(fetchUserList());
	}, []);

	useEffect(() => {
		dispatch(fetchUserList());
	}, [dispatch]);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleFieldChange = (field, value) => {
		if(field === "phone"){
			if (value.length === 10) {
				setPhoneNumber(value);
				setIsValid(true);
				setFormData((prevData) => ({
					...prevData,
					[field]: value,
				}));
			} else {
				setIsValid(false);
				setPhoneNumber('');
				setFormData((prevData) => ({
					...prevData,
					[field]: value,
				}));
			}
		}else{
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(formData.phone !== '' && formData.phone.length !== 10){
			return;
		}
		else{
			if(formData.firstName !== '' && formData.lastName !== '' && formData.email !== '')
			{
				try {
					// Fetch data from API
					const response = await Request.post('admin/adminUser', formData, true);
					if (response.message === "Success") {
						handleCloseDialog();
						dispatch(fetchUserList())
						setFormData({
							firstName: "",
							lastName: "",
							email: "",
							phone: "",
							userType: 2
						  });
					} else {
						setErrorMessage(response.message)
					}
				} catch (error) {
					console.error('Error fetching data from API:', error);
				}	
			}else{
				alert("First Name, Last Name and Email is required.");
			}
		}
		
	
		
	};

	return (
		<Box className="adminInnerPage shippingPricePage">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
                    User List
				</Typography>

				<Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add User
				</Button>
			</div>

			<div className="shippingPricePage__table-container">
			{/* <div className="title-button__container">
				<Typography variant="h6" className="section-title">
					Send Barrel
				</Typography>
				<Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={() => handleOpenDialog("send")} 
					// onClick={handleOpenDialog}
				>
					Add Send Barrel Shipping Price
				</Button>
			</div> */}

				<UsersListTable list={adminUserList}/>
			</div>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit= {handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
					Add User
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
                                <Form.Group
                                        controlId="firstName"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            value={formData.firstName}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "firstName",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="lastName"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            value={formData.lastName}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "lastName",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="email"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="email"
                                            value={formData.email}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "email",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="phone"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            placeholder="Phone"
											maxLength="10"
                                            value={formData.phone}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "phone",
                                                    e.target.value
                                                )
                                            }
                                        />
                                 </Form.Group>
								<Form.Group
									controlId="userType"
									className="shipping-price-form__field disableControl"
								>
									<Form.Label>User Type</Form.Label>
									<Form.Control
                                            type="text"
                                            value="Admin"
											readOnly
											disabled
                                            required
                                        />
									{/* <Form.Select
										name="userType"
										value={formData.userType}
										onChange={(e) =>
											handleFieldChange(
												"userType",
												e.target.value
											)
										}
										required
									>
										<option value="">Select user type</option>
										<option value="1">1</option>
                                        <option value="2">2</option>
									</Form.Select> */}
								</Form.Group>
							</div>
							{!isValid && <Typography className="errorMsg pt-4">Please enter a valid 10-digit phone number</Typography>}
							<Typography className="errorMsg pt-4">{errorMessage}</Typography>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Add
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			
		</Box>
	);
}

export default UsersList;
