import { useEffect, useState  } from 'react';
import { Routes, Route, Switch } from 'react-router-dom';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import Cookies from 'universal-cookie';
import { checkLogin, checkUserType } from '../commonFunctions/commonFunctions';
import DashboardHeader from '../header/dashboardHeader';
import { useDispatch, useSelector } from 'react-redux';
import DriverProfile from '../admin/driverProfile';
import DriverOrders from '../admin/driverOrders';
import Login from '../user/login/login';
import Orders from '../admin/adminOrder';
import DriverSidebar from '../header/driverSidebar';
import PageNotFound from './pageNotFound';



const cookies = new Cookies();

function DriverStructure() {
  // const [userType, setUserType] = useState(null);
  let userType = checkUserType();
  let login = checkLogin()
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }

  // useEffect(() => {
  //   let userType = cookies.get('userType', { path: '/' });
  //   const userTypeAsNumber = parseInt(userType, 10);
  //   setUserType(userTypeAsNumber)
  // }, []);

  return (
    <>
     {(login === true && userType === 4) ? ( 
        <Box className="main">
            <DriverSidebar />
            <Box className={`orderContainer ${minimized ? 'dashContainer' : 'containerExpand'}`}>
                <DashboardHeader />
                <Container maxWidth="l" className="adminInnerPadding">
                    <Box className="adminInner">
                        <Routes>
                            <Route path="/*" element={<PageNotFound />} />
                            <Route path="/orders" element={<DriverOrders />}></Route>
                            <Route path="/profile" element={<DriverProfile />}></Route>
                        </Routes>
                    </Box>
                </Container>
            </Box>
            {/* <Support /> */}
        </Box>
     ) : (
     <>
        <Login />
     </>
     )}
    </>
  )
}


export default DriverStructure;

