import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
import ProductCategoriesTable from "./ProductCategoriesTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchPriorityProductCategories } from "../redux/priorityProductCategoriesSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PriorityProductCategoriesTable from "./priorityProductCategoriesTable";

function PriorityProductCategories() {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const [categoryData, setCategoryData] = useState({
		"name": '',
		"status" : false
	});

	const priorityProductCategoriesList = useSelector((state) => state.priorityProductCategories.priorityProductCategories);

	

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleNameChange = (e) => {
		const newName = e.target.value;
		setCategoryData((prevData) => ({
		  ...prevData,
		  name: newName,
		}));
	  };

	  const handleVisibilityChange = (e) => {
		const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
		setCategoryData((prevData) => ({ ...prevData, status: newVisibility }));
	  };

	  const handleSubmit = async (e) => {
		try {
			// Fetch data from API
			const response = await Request.post('admin/islander/category', categoryData, true);
			if (response.length !== 0) {
				setOpen(false)
				dispatch(fetchPriorityProductCategories())
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
	  };

	useEffect(() => {
		dispatch(fetchPriorityProductCategories());
	}, [dispatch]);

    useEffect(() => {
		dispatch(fetchPriorityProductCategories());
	}, []);

	return (
		<Box className="adminInnerPage productCategories">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
					Categories
				</Typography>

				<Button
					variant="primary"
					color="primary"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Categories
				</Button>

				<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Add Categories
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form add-category-form">
								<div className="row add-product-form__row">
									<Form.Group
										controlId="productName"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Product Name"
											value={categoryData.name}
											onChange={handleNameChange}
											required
										/>
									</Form.Group>

									<Form.Group
										controlId="productVisibility"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Visibility</Form.Label>
										<div className="add-product-form__visibility">
											<Form.Check
												type="radio"
												label="Public"
												name="visibility"
												value="true"
												checked={categoryData.status === true}
												onChange={handleVisibilityChange}
												
											/>
											<Form.Check
												type="radio"
												label="Hide"
												name="visibility"
												value="false"
												checked={categoryData.status === false}
												onChange={handleVisibilityChange}
											/>
										</div>
									</Form.Group>
								</div>
							</Box>
						</Box>
					</DialogContent>
					</form>
				</Dialog>
			</div>

			<div className="productListPage__container">
				<div className="order-search-filter product-search-filter">
					{/* <IconButton
						aria-label="edit"
						size="small"
						className="product-search-filter__icon"
					>
						<TuneIcon />
					</IconButton>
					<div className="order-search-filter__search">
						<input type="text" placeholder="Search Categories" />
					</div> */}
				</div>

				<PriorityProductCategoriesTable />
			</div>
		</Box>
	);
}

export default PriorityProductCategories;
