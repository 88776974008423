import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Tabs, Tab, IconButton, Avatar, Switch, } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Col, Row, Form, } from 'react-bootstrap';
import Request from '../customUtils/Http/Request';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Login, Support } from '@mui/icons-material';
import { connect, useSelector, useDispatch } from 'react-redux';
import { fetchUserAddress } from '../redux/userAddressSlice';
import { fetchIslanderList } from "../redux/islanderListSlice";
import { fetchPickupList, fetchSendPickupList } from "../redux/pickupLocationSlice";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"
import Cookies from 'universal-cookie';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const cookies = new Cookies();


const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "var(--primary-color2)",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "var(--primary-color8) !important"
    },
    activePath: {
        color: "var(--primary-color6) !important"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}))

const Profile = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const [cityDisabled, setCityDisabled] = useState(true);
    const [cities, setCities] = useState([]);
    const [pickupCities, setPickupCities] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    let login = checkLogin()
    const minimized = useSelector(state => state.cart.minimized);
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    const [errors, setErrors] = useState({});

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [selectedCustomerRecipientAddress, setSelectedCustomerRecipientAddress] = useState([]);
    const [selectedCustomerPickupAddress, setSelectedCustomerPickupAddress] = useState([]);
    const [selectedCustomerSenderAddress, setSelectedCustomerSenderAddress] = useState([]);
    const [selectedCustomerBillingAddress, setSelectedCustomerBillingAddress] = useState([]);
    const [selectedCustomerShippingAddress, setSelectedCustomerShippingAddress] = useState([]);
    const [selectedDefaultCustomerRecipientAddress, setSelectedDefaultCustomerRecipientAddress] = useState(null);
    const [selectedDefaultCustomerPickupAddress, setSelectedDefaultCustomerPickupAddress] = useState(null);
    const [selectedDefaultCustomerSenderAddress, setSelectedDefaultCustomerSenderAddress] = useState(null);
    const [selectedDefaultCustomerBillingAddress, setSelectedDefaultCustomerBillingAddress] = useState(null);
    const [selectedDefaultCustomerShippingAddress, setSelectedDefaultCustomerShippingAddress] = useState(null);
    const [userDetail, setUserDetail] = useState({
        id:'',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        img: '',
        imgID: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [data, setData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [verifyOtp, setVerifyOtp] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const [verificationMessage, setVerificationMessage] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDefaultDialogOpen, setIsDefaultDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [selectedDefaultAddressId, setSelectedDefaultAddressId] = useState(null);
    const [selectedType, setSelectedType] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [confirmUpdateMsg, setConfirmUpdateMsg] = useState("");
    const [addressId, setAddressId] = useState(null);
    const [mode, setMode] = useState(null);
    const [stateDisabled, setStateDisabled] = useState(true);
    const [states, setStates] = useState([]);
    const [allCountries, setAllCountries] = useState([
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo (Congo-Brazzaville)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czechia",
        "Democratic Republic of the Congo (Congo-Kinshasa)",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Holy See",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar (formerly Burma)",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Korea",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine State",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Korea",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        ],
    )
    const [addresses, setAddresses] = useState({
        "firstName": '',
        "lastName": '',
        "address1": '',
        "address2": '',
        "city": '',
        "state": '',
        "zip": '',
        "country": '',
        "phone": '',
        "is_default": false,
        "address_type": 0
    })
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    const userAddressList = useSelector((state) => state.userAddress.userAddress);

	const getFormattedAddress = (address) => {
		return `${address.fullName}\n${address.address_line_1}\n${address.address_line_2}\n${address.city}, ${address.state}, ${address.zip}\n${address.country}`;
	};

    const pickupLocationList = useSelector((state) => state.pickupList.pickupListLocation);
    const islanderShippingList = useSelector((state) => state.islanderList.islanderList);
    const sendPickupList = useSelector((state) => state.pickupList.sendPickupListLocation);

    useEffect(() => {
		dispatch(fetchUserAddress());
        dispatch(fetchPickupList());
        dispatch(fetchSendPickupList());
        dispatch(fetchIslanderList());
	}, []);

	useEffect(() => {
		dispatch(fetchUserAddress());
	}, [dispatch]);

    useEffect(() => {
        if(Object.keys(userAddressList).length > 0){
            let defaultRecepientAddress = userAddressList.recipientAddresses.find(item => item.is_default === true);
            let defaultPickupAddress = userAddressList.pickupAddresses.find(item => item.is_default === true);
            let defaultSenderAddress = userAddressList.senderAddresses.find(item => item.is_default === true);
            let defaultBillingAddress = userAddressList.billingAddresses.find(item => item.is_default === true);
            let defaultShippingAddress = userAddressList.shippingAddresses.find(item => item.is_default === true);
            let RecepientAddress = userAddressList.recipientAddresses.filter(item => item.is_default === false);
            let PickupAddress = userAddressList.pickupAddresses.filter(item => item.is_default === false);
            let SenderAddress = userAddressList.senderAddresses.filter(item => item.is_default === false);
            let BillingAddress = userAddressList.billingAddresses.filter(item => item.is_default === false);
            let ShippingAddress = userAddressList.shippingAddresses.filter(item => item.is_default === false);
            setSelectedDefaultCustomerRecipientAddress(defaultRecepientAddress);
            setSelectedDefaultCustomerPickupAddress(defaultPickupAddress);
            setSelectedDefaultCustomerSenderAddress(defaultSenderAddress);
            setSelectedDefaultCustomerBillingAddress(defaultBillingAddress);
            setSelectedDefaultCustomerShippingAddress(defaultShippingAddress);
            setSelectedCustomerRecipientAddress(RecepientAddress);
            setSelectedCustomerPickupAddress(PickupAddress);
            setSelectedCustomerSenderAddress(SenderAddress);
            setSelectedCustomerBillingAddress(BillingAddress);
            setSelectedCustomerShippingAddress(ShippingAddress);
        }
        // fetchAddresses();
    }, [userAddressList]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryname = searchParams.get('tab');   
        if(categoryname === "address"){
            setSelectedTab(1)
        }
        else{
            setSelectedTab(0) 
        }
    }, [location.search]);

    useEffect(() => {
        getEmail();
    }, []);

    const getEmail = async () => {
		try {
			const response = await Request.get(`user/`, [], true);
			if (response) {
                 
				setUserEmail(response.email)
                setUserDetail({
                    id:response.id,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    phone: response.phone,
                    img: response.img,
                    imgID: response.imgID
                })
                if(response.isMFA === true){
                    setIsEnabled(true)
                }
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
	};
    

    const fetchAddresses = async () => {
		try {
			const response = await Request.get(`user/addresses`, [], true);
			if (response) {
				 
				setSelectedCustomerRecipientAddress(response.shippingAddresses);
                setSelectedCustomerPickupAddress(response.pickupAddresses);
                setSelectedCustomerSenderAddress(response.senderAddresses);
                setSelectedCustomerBillingAddress(response.billingAddresses);
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
	};


    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'newPassword') {
            const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
            if (!passwordPattern.test(value)) {
              setErrors({ ...errors, newPassword: 'Password must contain Minimum eight characters, at least one letter and one number and one Special character.' });
            } else {
              setErrors({ ...errors, newPassword: '' });
            }
          }
      };

    const handleSubmit = async (e) => {

      e.preventDefault();
      const newErrors = {};
      
      if (!formData.oldPassword) {
        newErrors.oldPassword = 'Old password is required.';
      }
  
      if (!formData.newPassword) {
        newErrors.newPassword = 'New password is required.';
      } else if (!passwordPattern.test(formData.newPassword)) {
        newErrors.newPassword = 'Password must meet the specified criteria.';
      }
  
      if (!formData.confirmPassword) {
        newErrors.confirmPassword = 'Confirm password is required.';
      } else if (formData.newPassword !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match.';
      }

      if (formData.oldPassword === formData.newPassword) {
        newErrors.newPassword = 'New password cannot be the same as the old password.';
      }

      if (Object.keys(newErrors).length === 0) {
        try {
            const requestData = {
              oldPassword: formData.oldPassword,
              newPassword: formData.newPassword,
            };
      
              
            let response = await Request.put('user/updateUserPassword', requestData, true);
            if (response.status === 200) {
                  
              setSuccessMessage('Password changed successfully.');
              setFormData({
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
              });   
            } else {
              console.error('API Error:', response);
            }
          } catch (error) {
            console.error('API Request Error:', error);
          }
        } else {
          setErrors(newErrors);
      }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault()
          
        const data = {
            "first_name" : addresses.firstName,
            "last_name" : addresses.lastName,
            "address_line_1" : addresses.address1,
            "address_line_2" : addresses.address2,
            "city": addresses.city, 
            "state": addresses.state, 
            "zip": addresses.zip, 
            "country": addresses.country,
            "is_default": addresses.is_default,  
            "address_type": selectedType,  
        }
        try {
			const response = await Request.put(`user/address/${selectedDefaultAddressId}`, data, true);
             
			if (response.status === 200) {
				setEditDialogOpen(false)
                dispatch(fetchUserAddress())
			} else {
                setErrorMessage(response.message)
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
    }

    const handleCancel = () => {
        setFormData({
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        setErrors({});
        setSuccessMessage('');
        setIsFormDisabled(true);
      };

      const userDataSave = async (id) => {
        // setSelectedImage(null);
        if(selectedImage){
            var convertedImage = dataURItoBlob(selectedImage);
        }
       
        if(selectedImageId){
            var convertedImageId = dataURItoBlob(selectedImageId);
        }

        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('firstName', userDetail.firstName);
        formdata.append('lastName', userDetail.lastName);
        // formdata.append('profileImageFile', userDetail.img);
        formdata.append('profileImageFile', convertedImage);
        formdata.append('idImageFile', convertedImageId);
        
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/user/edit/${id}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}user/edit/${id}`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    setSelectedImage(null);
                    setConfirmUpdateMsg("Image Successfully Updated")
                    setTimeout(() => {
                        setConfirmUpdateMsg("")
                    }, 2000);
                    getEmail();
                }
                else{
                    console.log('Error: Unexpected status code', response.status);
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
      
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
      
        return new Blob([ab], { type: mimeString });
      };

      const handleImageChange = (e) => {
        const selectedImage1 = e.target.files[0];
        if(selectedImage1){
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(selectedImage1);
        }
        // if (selectedImage) {
        //   const imageUrl = URL.createObjectURL(selectedImage);
        //   setUserDetail({
        //     ...userDetail,
        //     img: selectedImage,
        //   });
        // }
      };

      const handleIdImageChange = (e) => {
        const selectedImageId1 = e.target.files[0];
        if(selectedImageId1){
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImageId(reader.result);
            };
            reader.readAsDataURL(selectedImageId1);
        }

    //     const selectedImageId1 = e.target.files[0];
    //     if (selectedImage) {
    //       const imageUrl = URL.createObjectURL(selectedImage);
    //       setUserDetail({
    //         ...userDetail,
    //         imgID: selectedImage,
    //       });
    //     }
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetail({
          ...userDetail,
          [name]: value,
        });
      };

      const handleSwitchChange = async (event) => {
        const isChecked = event.target.checked;
        if(isEnabled === false){
            setIsEnabled(!isEnabled);
            fetchOtpData();
        }
        else{
            setIsEnabled(!isEnabled);
            fetchOtpData();
        }
      };

    const defaultAddress = (newId, type, newMode) => {
          
        setSelectedDefaultAddressId(newId);
        if (type) {
            setSelectedType(type);
          }
        if (newId) {
            setAddressId(newId);
        }
        if (newMode) {
            setMode(newMode);
        }
	};

    useEffect(() => {
          
        if (selectedType) {
          fetchSelectedAddressData(addressId, mode);
        }
      }, [selectedType, addressId , mode]);

    const deleteAddress = (id) => {
          
		setSelectedAddressId(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedAddressId(null);
        setSelectedDefaultAddressId(null);
		setIsDeleteDialogOpen(false);
        setSelectedType(0);
        setMode(null);
        setAddressId(null);
	};

    const closeDefaultDialog = () => {
		setIsDefaultDialogOpen(false);
        setSelectedType(0);
        setMode(null);
        setAddressId(null);
	};

    const fetchOtpData = async () => {
          
        try {
			const response = await Request.get(`utils/send/otp`, [], true);
              
			if (response.message === "Success") {
				setOpenDialog(true)
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
    };

    const fetchSelectedAddressData = async (id,mode) => {
          
        if(mode === "default")
        {
            try {
                const response = await Request.get(`user/address/${id}`, [], true);
                  
                if (Object.keys(response).length > 0) {
                    setAddresses({
                        "firstName": response.first_name,
                        "lastName": response.last_name,
                        "address1": response.address_line_1,
                        "address2": response.address_line_2,
                        "city": response.city,
                        "state": response.state,
                        "zip": response.zip,
                        "country": response.country,
                        "phone": response.phone,
                        "is_default": response.is_default,
                    })
                    setIsDefaultDialogOpen(true)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }else{
            try {
                const response = await Request.get(`user/address/${id}`, [], true);
                if (Object.keys(response).length > 0) {
                    if (response.city && response.country) {
                        // let newCity = response.city.charAt(0).toUpperCase() + response.city.slice(1).toLowerCase();
                        // let newState = response.state.charAt(0).toUpperCase() + response.state.slice(1).toLowerCase();
                        // let newCountry = response.country.charAt(0).toUpperCase() + response.country.slice(1).toLowerCase();

                        setAddresses({
                            "firstName": response.first_name,
                            "lastName": response.last_name,
                            "address1": response.address_line_1,
                            "address2": response.address_line_2,
                            "city": response.city,
                            "state": response.state,
                            "zip": response.zip,
                            "country": response.country,
                            "phone": response.phone,
                            "is_default": response.is_default,
                        })

                        if(response.state && selectedType === 1){
                              
                            fetchCitiesForState(response.state);
                            setCityDisabled(false)
                        }
                        else if(response.state && selectedType === 3){
                            fetchPickupCitiesForState(response.state);
                            setCityDisabled(false)
                        }
                        else if(response.country && selectedType === 2){
                            fetchStateForCountries(response.country);
                            setStateDisabled(false)
                        }else{
                            setStateDisabled(true)
                        }
                        setEditDialogOpen(true)
                    }
                    else{
                        setErrorMessage2(response.message)
                    }
                 
                  
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }
    };

    const handleChangeShippingAddress = (field, value) => {
        if(field === "state" && selectedType === 1){
          setAddresses((prevAddresses) => ({
            ...prevAddresses,
              [field]: value
          }));
          fetchCitiesForState(value);
          setCityDisabled(false)
        }
        else if(field === "state" && selectedType === 3){
            setAddresses((prevAddresses) => ({
                ...prevAddresses,
                  [field]: value
              }));
            fetchPickupCitiesForState(value);
            setCityDisabled(false)
        }
        else if(field === "country" && selectedType === 2){
            setAddresses((prevAddresses) => ({
              ...prevAddresses,
                [field]: value
            }))
            fetchStateForCountries(value);
            setStateDisabled(false)
        }
        else{
          setAddresses((prevAddresses) => ({
            ...prevAddresses,
              [field]: value
          }));
        }
      };

      const fetchPickupCitiesForState = async (state) => {
        try {
                // Fetch data from API
                const response = await Request.get(`utils/getPickupLocationSendByProvince?province=${state}`, []);
                if (response.length !== 0) {
                    setPickupCities(response)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }	
      };

      const fetchStateForCountries = async (country) => {
        try {
                  
                const response = await Request.get(`utils/getProvinceListByCountry?country=${country}`, []);
                if (response.provinces.length !== 0) {
                    setStates(response.provinces)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }	
        // fetch(`/api/cities?state=${state}`)
        //   .then((response) => response.json())
        //   .then((data) => setCities(data))
        //   .catch((error) => console.error('Error fetching cities:', error));
      };

      const fetchCitiesForState = async (state) => {
        try {
                // Fetch data from API
                const response = await Request.get(`utils/getPickupLocationByProvince?province=${state}`, []);
                if (response.length !== 0) {
                    setCities(response)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }	
      };

      const confirmDelete = async (id) => {
          
        try {
			const response = await Request.delete(`user/address/${id}`, [], true);
			if (response.status === 204) {
				setIsDeleteDialogOpen(false)
                dispatch(fetchUserAddress())
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
      };

      const confirmDefault = async (id) => {
          
        const data = {
            "first_name" : addresses.firstName,
            "last_name" : addresses.lastName,
            "address_line_1" : addresses.address1,
            "address_line_2" : addresses.address2,
            "city": addresses.city, 
            "state": addresses.state, 
            "zip": addresses.zip, 
            "country": addresses.country,
            "is_default": true,  
            "address_type": selectedType,  
        }
        try {
			const response = await Request.put(`user/address/${id}`, data, true);
			if (response.status === 200) {
				setIsDefaultDialogOpen(false)
                dispatch(fetchUserAddress())
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
      };

      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const handleEditCloseDialog = () => {
        setEditDialogOpen(false);
        setSelectedType(0);
        setMode(null);
        setAddressId(null);
      };

      const otpChange = (e) => {
        let val = e.target.value; 
        setVerifyOtp(val)
      };

      const handleOtpSubmit = async (event) => {
        event.preventDefault(); 
          
        if(verifyOtp !== ''){
            try {
                const response = await Request.post(`utils/verify/otp`, {"otp": verifyOtp}, true);
                 
                if (response.message === "Success") {
                    setOpenDialog(false)
                    setVerificationMessage(true)
                    setTimeout(() => {
                        setVerificationMessage(false)
                    }, 3000);
                } else {
                    setOpenDialog(true)
                    setOtpMessage(response.message)

                    setTimeout(() => {
                        setOtpMessage('')
                    }, 2000);
                    setVerifyOtp('')
                    return
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }else{
            alert("Please enter Otp first.")
        }    
      }
  

    return (
        <>

                            <Box className={classes.dashboardInner}>
                                <Box>
                                    <Box>
                                        <Typography variant='h5' className='color1 font-bold'>My Account</Typography>
                                    </Box>
                                    <Box className={classes.headerFlexBox}>
                                        <Typography className={`${classes.pathName} ${classes.activePath}`}>My Account</Typography>
                                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='cartMainContainer'>
                                <Box sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' }, // Change direction on small screens
                                        textAlign: 'center', // Center the content
                                        }} className="tabBox">
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={selectedTab}
                                        onChange={handleChange}
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                        className='tabs-block'
                                    >
                                        <Tab label="Account Owner" icon={<AccountCircleOutlinedIcon />}/>
                                        <Tab label="Addresses" icon={<ContactMailOutlinedIcon />}/>
                                        <Tab label="2-step verification" icon={<QrCode2OutlinedIcon />}/>
                                        <Tab label="change password" icon={<VpnKeyOutlinedIcon />}/>
                                    </Tabs>

                                    <TabPanel2 value={selectedTab} index={0}>
                                        <Box className="tab-form1">
                                            <Typography className='font-24 font-bold color4' sx={{mb:5,textAlign: "left"}}>Account Detail</Typography>
                                            <form>
                                                <div className="driver-form">
                                                    <div className="driver-form__form">
                                                        <div className="driverform__fields">
                                                            <Form.Group controlId="firstName" className="driverform__field">
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={userDetail.firstName}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="lastName" className="driverform__field">
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={userDetail.lastName}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="email" className="driverform__field">
                                                                <Form.Label>Email ID</Form.Label>
                                                                <Form.Control
                                                                    className='email-id-box'
                                                                    type="email"
                                                                    name="email"
                                                                    value={userDetail.email}
                                                                    // onChange={handleInputChange}
                                                                    readOnly
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="phone" className="driverform__field">
                                                                <Form.Label>Phone</Form.Label>
                                                                <Form.Control
                                                                className='email-id-box'
                                                                    type="tel"
                                                                    name="phone"
                                                                    value={userDetail.phone}
                                                                    // onChange={handleInputChange}
                                                                    readOnly
                                                                />
                                                            </Form.Group>
                                                            {/* <Form.Group controlId="phone" className="driverform__field">
                                                                <Form.Label>Identity Image</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="idImageFile"
                                                                    value={userDetail.idImageFile}
                                                                    // onChange={handleInputChange}
                                                                />
                                                            </Form.Group> */}
                                                            <div className="driver-form__photo">
                                                            <Avatar
                                                                alt="idImage"
                                                                src={selectedImageId || `${process.env.REACT_APP_DatabaseStatic}${userDetail.imgID}` || "/assets/img/default-profile.jpg"}
                                                                // src={userDetail.imgID === null ?  "/assets/img/cart_img.png" : `${process.env.REACT_APP_DatabaseStatic}${userDetail.imgID}`}
                                                                variant="rounded"
                                                                sx={{ width: 234, height: 234 }}
                                                                className="center-avatar"
                                                            />
                                                              <div className='mt-4' style={{textAlign: "left", paddingLeft: "30px"}}>
                                                                <input type="file" id="upload1" accept="image/*" onChange={handleIdImageChange} hidden/>
                                                                <label className="photolLabel" for="upload1">Upload Photo ID</label>
                                                                </div>
                                                            </div>
                                                            <Form.Group>
                                                                <Button type="button" className='b1 b3 font-bold mt-4' onClick={() => userDataSave(userDetail.id)}>Save</Button>
                                                                <Typography className='font-15 color4 pt-2 font-600'>{confirmUpdateMsg}</Typography>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="driver-form__photo">
                                                        <Avatar
                                                            alt={userDetail.firstName}
                                                            src={selectedImage || `${process.env.REACT_APP_DatabaseStatic}${userDetail.img}` || "/assets/img/default-profile.jpg"}
                                                            // userDetail.img === null ? selectedImage === null ? "/assets/img/driver-placeholder.jpg" : selectedImage : `${process.env.REACT_APP_DatabaseStatic}${userDetail.img}`}
                                                            // src={userDetail.img === null ? "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${userDetail.img}`}
                                                            variant="rounded"
                                                            sx={{ width: 234, height: 234 }}
                                                            className="center-avatar"
                                                        />
                                                        <div className='mt-4'>
                                                            <input type="file" id="upload" accept="image/*" onChange={handleImageChange} hidden/>
                                                            <label className="photolLabel" for="upload">Change Photo</label>
                                                            {/* <label>
                                                                <Button className='b1 b4 font-bold'>
                                                                    Change Photo
                                                                </Button>
                                                
                                                                <Input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleImageChange}
                                                                style={{ display: 'none' }}
                                                                id="imageInput"
                                                                />
                                                            </label> */}
                                                        </div>
                                                        {/* <div className=''>
                                                            <Input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleImageChange}
                                                            style={{ display: 'none' }}
                                                            id="imageInput"
                                                            />
                                                            <label htmlFor="imageInput">
                                                                <Button className='b1 b4 font-bold'>
                                                                    Change Photo
                                                                </Button>
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </Box>
                                        
                                    </TabPanel2>
                                    <TabPanel2 value={selectedTab} index={1}>
                                        <Box className="tab-form1">
                                            <Typography className='font-24 font-bold color4' sx={{mb: 5,textAlign: "left",'@media (max-width: 600px)': {mb: 3},}}>Addresses</Typography>
                                                {((selectedCustomerShippingAddress && selectedCustomerShippingAddress.length>0) || (selectedDefaultCustomerShippingAddress)) ? 
                                                    <>
                                                        <Typography className="font-20 font-600 pb-2" textAlign="left">{errorMessage2}</Typography>
                                                        <Typography className="color2 font-20 font-600 pb-2" textAlign="left">Shipping Addresses (Canada)</Typography>
                                                        <div className="customer-form__address-modify-container addressArea">
                                                            {selectedDefaultCustomerShippingAddress && (
                                                                  <div class="customer-form__address-modify">
                                                                    <Form.Control controlId={`formAddress${selectedDefaultCustomerShippingAddress.id}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                        as="textarea"
                                                                        rows="5"
                                                                        value={getFormattedAddress(selectedDefaultCustomerShippingAddress)}
                                                                        readOnly>
                                                                    </Form.Control>

                                                                    <Box textAlign="left" className="editAddressBox">
                                                                        <Button size="small" onClick={() => defaultAddress(selectedDefaultCustomerShippingAddress.id, 1,"edit")}>Edit</Button>
                                                                        <Button size="small" onClick={() => deleteAddress(selectedDefaultCustomerShippingAddress.id)}>Delete</Button>
                                                                        {/* {selectedDefaultCustomerShippingAddress.is_default === false ? (
                                                                            <Button size="small" onClick={() => defaultAddress(selectedDefaultCustomerShippingAddress.id, 1,"default")}>Set as Default</Button>
                                                                        ):( */}
                                                                            <Button size="small" className='color2 font-bold defaultBtn'>Default</Button>
                                                                        {/* )} */}
                                                                    </Box>
                                                              </div>
                                                            )}
                                                            {selectedCustomerShippingAddress && selectedCustomerShippingAddress.length>0 && selectedCustomerShippingAddress.map((address, index) => (
                                                                <div class="customer-form__address-modify" key={index}>
                                                                    <Form.Control key={index} controlId={`formAddress${index}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                        as="textarea"
                                                                        rows="5"
                                                                        value={getFormattedAddress(address)}
                                                                        readOnly>
                                                                    </Form.Control>

                                                                    <Box textAlign="left" className="editAddressBox">
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 1 ,"edit")}>Edit</Button>
                                                                        <Button size="small" onClick={() => deleteAddress(address.id)}>Delete</Button>
                                                                        {/* {address.is_default === false ? ( */}
                                                                            <Button size="small" onClick={() => defaultAddress(address.id, 1,"default")}>Set as Default</Button>
                                                                    </Box>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </> : 
                                                    <Typography className='font-18 color2' textAlign="left">No Addresses Available</Typography>
                                                }   

                                                {((selectedCustomerRecipientAddress && selectedCustomerRecipientAddress.length>0) || (selectedDefaultCustomerRecipientAddress)) && 
                                                    <>
                                                        <Typography className="color2 font-20 font-600 pb-2" textAlign="left">Recipient Addresses (Islander)</Typography>
                                                        <div className="customer-form__address-modify-container">
                                                            {selectedDefaultCustomerRecipientAddress && (
                                                                  <div class="customer-form__address-modify addressArea">
                                                                  <Form.Control controlId={`formAddress${selectedDefaultCustomerRecipientAddress.id}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                      as="textarea"
                                                                      rows="5"
                                                                      value={getFormattedAddress(selectedDefaultCustomerRecipientAddress)}
                                                                      readOnly>
                                                                  </Form.Control>

                                                                  <Box textAlign="left" className="editAddressBox">
                                                                      <Button size="small" onClick={() => defaultAddress(selectedDefaultCustomerRecipientAddress.id, 5,"edit")}>Edit</Button>
                                                                      <Button size="small" onClick={() => deleteAddress(selectedDefaultCustomerRecipientAddress.id)}>Delete</Button>
                                                                      <Button size="small" className='color2 font-bold defaultBtn'>Default</Button>
                                                                  </Box>
                                                              </div>
                                                            )}
                                                            {selectedCustomerRecipientAddress && selectedCustomerRecipientAddress.length>0 && selectedCustomerRecipientAddress.map((address, index) => (
                                                                <div class="customer-form__address-modify addressArea" key={index}>
                                                                    <Form.Control key={index} controlId={`formAddress${index}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                        as="textarea"
                                                                        rows="5"
                                                                        value={getFormattedAddress(address)}
                                                                        readOnly>
                                                                    </Form.Control>

                                                                    <Box textAlign="left" className="editAddressBox">
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 5,"edit")}>Edit</Button>
                                                                        <Button size="small" onClick={() => deleteAddress(address.id)}>Delete</Button>
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 5,"default")}>Set as Default</Button>
                                                                    </Box>
                                                                   
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }   
                                                {((selectedCustomerPickupAddress && selectedCustomerPickupAddress.length>0) || (selectedDefaultCustomerPickupAddress)) && 
                                                    <>
                                                        <Typography className="color2 font-20 font-600 pb-2" textAlign="left">Pickup Addresses</Typography>
                                                        <div className="customer-form__address-modify-container addressArea">
                                                            {selectedDefaultCustomerPickupAddress && (
                                                                 <div class="customer-form__address-modify">
                                                                 <Form.Control controlId={`formAddress${selectedDefaultCustomerPickupAddress.id}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                     as="textarea"
                                                                     rows="5"
                                                                     value={getFormattedAddress(selectedDefaultCustomerPickupAddress)}
                                                                     readOnly>
                                                                 </Form.Control>

                                                                 <Box textAlign="left" className="editAddressBox">
                                                                     <Button size="small" onClick={() => defaultAddress(selectedDefaultCustomerPickupAddress.id, 3,"edit")}>Edit</Button>
                                                                     <Button size="small" onClick={() => deleteAddress(selectedDefaultCustomerPickupAddress.id)}>Delete</Button>
                                                                     <Button size="small" className='color2 font-bold defaultBtn'>Default</Button>
                                                                 </Box>
                                                             </div>
                                                            )}
                                                            {selectedCustomerPickupAddress && selectedCustomerPickupAddress.length>0 && selectedCustomerPickupAddress.map((address, index) => (
                                                                <div class="customer-form__address-modify" key={index}>
                                                                    <Form.Control key={index} controlId={`formAddress${index}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                        as="textarea"
                                                                        rows="5"
                                                                        value={getFormattedAddress(address)}
                                                                        readOnly>
                                                                    </Form.Control>

                                                                    <Box textAlign="left" className="editAddressBox">
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 3,"edit")}>Edit</Button>
                                                                        <Button size="small" onClick={() => deleteAddress(address.id)}>Delete</Button>
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 3,"default")}>Set as Default</Button>
                                                                    </Box>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                                {((selectedCustomerSenderAddress && selectedCustomerSenderAddress.length>0) || (selectedDefaultCustomerSenderAddress)) && 
                                                    <>
                                                        <Typography className="color2 font-20 font-600 pb-2" textAlign="left">Sender Addresses</Typography>
                                                        <div className="customer-form__address-modify-container addressArea">
                                                            {selectedDefaultCustomerSenderAddress && (
                                                                <div class="customer-form__address-modify">
                                                                <Form.Control controlId={`formAddress${selectedDefaultCustomerSenderAddress.id}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                    as="textarea"
                                                                    rows="5"
                                                                    value={getFormattedAddress(selectedDefaultCustomerSenderAddress)}
                                                                    readOnly>
                                                                </Form.Control>

                                                                <Box textAlign="left" className="editAddressBox">
                                                                    <Button size="small" onClick={() => defaultAddress(selectedDefaultCustomerSenderAddress.id, 4,"edit")}>Edit</Button>
                                                                    <Button size="small" onClick={() => deleteAddress(selectedDefaultCustomerSenderAddress.id)}>Delete</Button>
                                                                    <Button size="small" className='color2 font-bold defaultBtn'>Default</Button>
                                                                </Box>
                                                            </div>
                                                            )}
                                                            {selectedCustomerSenderAddress && selectedCustomerSenderAddress.length>0 && selectedCustomerSenderAddress.map((address, index) => (
                                                                <div class="customer-form__address-modify" key={index}>
                                                                    <Form.Control key={index} controlId={`formAddress${index}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                        as="textarea"
                                                                        rows="5"
                                                                        value={getFormattedAddress(address)}
                                                                        readOnly>
                                                                    </Form.Control>

                                                                    <Box textAlign="left" className="editAddressBox">
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 4,"edit")}>Edit</Button>
                                                                        <Button size="small" onClick={() => deleteAddress(address.id)}>Delete</Button>
                                                                        <Button size="small" onClick={() => defaultAddress(address.id, 4,"default")}>Set as Default</Button>
                                                                    </Box>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                                {((selectedCustomerBillingAddress && selectedCustomerBillingAddress.length>0) || (selectedDefaultCustomerBillingAddress)) && 
                                                    <>
                                                        <Typography className="color2 font-20 font-600 pb-2" textAlign="left">Billing Addresses</Typography>
                                                        <div className="customer-form__address-modify-container addressArea">
                                                            {selectedDefaultCustomerBillingAddress && (
                                                                 <div class="customer-form__address-modify">
                                                                 <Form.Control controlId={`formAddress${selectedDefaultCustomerBillingAddress.id}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                     as="textarea"
                                                                     rows="5"
                                                                     value={getFormattedAddress(selectedDefaultCustomerBillingAddress)}
                                                                     readOnly>
                                                                 </Form.Control>

                                                                 <Box textAlign="left" className="editAddressBox">
                                                                     <Button size="small" onClick={() => deleteAddress(selectedDefaultCustomerBillingAddress.id)}>Delete</Button>
                                                                 </Box>
                                                             </div>
                                                            )}
                                                            {selectedCustomerBillingAddress && selectedCustomerBillingAddress.length>0 && selectedCustomerBillingAddress.map((address, index) => (
                                                                <div class="customer-form__address-modify" key={index}>
                                                                    <Form.Control key={index} controlId={`formAddress${index}`} className="edit-order-form__field edit-order-form__field--addresses"
                                                                        as="textarea"
                                                                        rows="5"
                                                                        value={getFormattedAddress(address)}
                                                                        readOnly>
                                                                    </Form.Control>

                                                                    <Box textAlign="left" className="editAddressBox">
                                                                        {/* <Button size="small" onClick={() => defaultAddress(address.id, 2,"edit")}>Edit</Button> */}
                                                                        <Button size="small" onClick={() => deleteAddress(address.id)}>Delete</Button>
                                                                        {/* {address.is_default === false && (
                                                                            <Button size="small" onClick={() => defaultAddress(address.id, 2,"default")}>Set as Default</Button>
                                                                        )} */}
                                                                    </Box>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                } 
                                               
                                                       
                                        </Box> 
                                    </TabPanel2>
                                    <TabPanel2 value={selectedTab} index={2}>
                                        <Box textAlign= "left">
                                            <Typography className='font-24 font-bold color4' sx={{mb: 5,textAlign: "left",'@media (max-width: 600px)': {mb: 3},}}>2-Step Verification</Typography>
                                            <Box sx={{pt: 2}}>
                                                Enable Verification:
                                                <Switch checked={isEnabled} onChange={handleSwitchChange} />
                                                {verificationMessage && (
                                                    <Typography className='font-15 color4 font-600'>2-step Verification Completed</Typography>
                                                )}
                                            </Box>
                                            <Dialog open={openDialog} onClose={handleCloseDialog}>
                                                {/* <DialogTitle>Fetched Data</DialogTitle> */}
                                                <DialogContent>
                                                <form onSubmit={handleOtpSubmit}>
                                                    <Form.Label htmlFor="otp" className='font-bold'>Enter OTP</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            id="otp"
                                                            aria-describedby="otp"
                                                            required
                                                            onChange={otpChange}
                                                        />
                                                        <Box className="flexBox mt-4">
                                                            <Button className='b1 b3' type='submit'>Save</Button>
                                                            <Button onClick={handleCloseDialog} className='b1 b4'>
                                                                Close
                                                            </Button>
                                                            
                                                        </Box>
                                                        <Typography className='font-15 color4 font-600 mt-1'>{otpMessage}</Typography>
                                                </form>
                                                 
                                                </DialogContent>
                                                <DialogActions>
                                               
                                                </DialogActions>
                                            </Dialog>
                                            {/* {isEnabled && (
                                                <Button variant="contained" onClick={fetchData}>
                                                    Fetch Data
                                                </Button>
                                            )} */}
                                        </Box> 
                                    </TabPanel2>
                                    <TabPanel2 value={selectedTab} index={3}>
                                        <Box className="tab-form" textAlign="left">
                                            <Typography className='font-24 font-bold color4' sx={{mb: 5,textAlign: "left",'@media (max-width: 600px)': {mb: 3},}}>Change Password</Typography>
                                            <form onSubmit={handleSubmit}>
                                                <div className="mt-3">
                                                        <Form.Group controlId="oldPassword">
                                                            <Form.Label>Old Password</Form.Label>
                                                            <Form.Control
                                                                type="password"
                                                                name="oldPassword"
                                                                value={formData.oldPassword}
                                                                onChange={handlePasswordChange}
                                                                isInvalid={!!errors.oldPassword}
                                                                disabled={isFormDisabled}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.oldPassword}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="newPassword" className='mt-4'>
                                                            <Form.Label>New Password</Form.Label>
                                                            <Form.Control
                                                                type="password"
                                                                name="newPassword"
                                                                value={formData.newPassword}
                                                                onChange={handlePasswordChange}
                                                                isInvalid={!!errors.newPassword}
                                                                disabled={isFormDisabled}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.newPassword}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="confirmPassword" className='mt-4'>
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            name="confirmPassword"
                                                            value={formData.confirmPassword}
                                                            onChange={handlePasswordChange}
                                                            isInvalid={!!errors.confirmPassword}
                                                            disabled={isFormDisabled}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.confirmPassword}
                                                        </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Box className='mt-5 d-flex gap-3'>
                                                            <Button className='b1 b3' onClick={handleCancel}>Cancel</Button>
                                                            <Button type="submit" className='b1 b4'>Save</Button>
                                                        </Box>
                                                </div>
                                                {successMessage && <Typography className='font-15 font-600 mt-3' sx={{color: "red"}}>{successMessage}</Typography>}
                                            </form>
                                        </Box>    
                                    </TabPanel2>
                                </Box>
                            </Box>

                    {/* delete dialog */}
                        <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <p>Are you sure you want to delete this Address?</p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => confirmDelete(selectedAddressId)} color="primary">
                                    OK
                                </Button>
                                <Button onClick={closeDeleteDialog} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                       {/* delete dialog */}
                       <Dialog open={isDefaultDialogOpen} onClose={closeDefaultDialog}>
                            <DialogTitle>Confirm Edition</DialogTitle>
                            <DialogContent>
                                <p className='mb-1'>Are you sure you want to make this</p> 
                                <p>Address as default Address?</p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => confirmDefault(selectedDefaultAddressId)} color="primary">
                                    OK
                                </Button>
                                <Button onClick={closeDefaultDialog} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* edit dialog */}
                        <Dialog
                            open={editDialogOpen}
                            onClose={handleEditCloseDialog}
                            className="admin-modal admin-dialog shipping-dialog"
                        >
                            <form onSubmit={handleEditSubmit}>
                                <DialogTitle className="admin-modal__title-container">
                                    Edit Address
                                </DialogTitle>
                                <DialogContent sx={{ padding: "30px" }}>
                                    <Form className="edit-order-form shipping-price-form">
                                        <div className="shipping-price-form__fields">
                                            <Form.Group
                                                    controlId="firstName"
                                                    className="col-md-6 add-category-form__field userField"
                                                >
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="firstName"
                                                        placeholder="First Name"
                                                        value={addresses.firstName}
                                                        onChange={(e) => handleChangeShippingAddress('firstName', e.target.value)}
                                                        required
                                                    />
                                            </Form.Group>

                                            <Form.Group
                                                    controlId="lastName"
                                                    className="col-md-6 add-category-form__field userField"
                                                >
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Last Name"
                                                        value={addresses.lastName}
                                                        onChange={(e) => handleChangeShippingAddress('lastName', e.target.value)}
                                                        required
                                                    />
                                            </Form.Group>
                                            <Form.Group
                                                    controlId="address1"
                                                    className="col-md-6 add-category-form__field userField"
                                                >
                                                    <Form.Label>Address Line 1</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="address1"
                                                        placeholder="Address Line 1"
                                                        value={addresses.address1}
                                                        onChange={(e) => handleChangeShippingAddress('address1', e.target.value)}
                                                        required
                                                    />
                                            </Form.Group>
                                            <Form.Group
                                                    controlId="address2"
                                                    className="col-md-6 add-category-form__field userField"
                                                >
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control
                                                        name="address2"
                                                        type="text"
                                                        placeholder="Address Line 2"
                                                        value={addresses.address2}
                                                        onChange={(e) => handleChangeShippingAddress('address2', e.target.value)}
                                                        required
                                                    />
                                            </Form.Group>

                                            <Form.Group
                                                    controlId="country"
                                                    className="col-md-6 add-category-form__field userField select-field"
                                                >
                                                    <Form.Label>Country</Form.Label>
                                                    {selectedType === 4 && (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="country"
                                                            name="country"
                                                            value={addresses.country}
                                                            onChange={(e) => handleChangeShippingAddress('country', e.target.value)}
                                                            required
                                                        />
                                                    )}
                                                    
                                                        {(selectedType === 1 || selectedType === 3) && 
                                                        <Form.Select
                                                            name="country"
                                                            value={addresses.country}
                                                            required
                                                            onChange={(e) => handleChangeShippingAddress('country', e.target.value)}
                                                            // onChange={(e) => handleInputChange(e, "shippingAddress")}
                                                        >
                                                            <option value="">Select Country</option>
                                                            <option value="Canada">Canada</option>  
                                                        </Form.Select>}
                                                        {selectedType === 5 && (
                                                              <Form.Select
                                                              name="country"
                                                              value={addresses.country}
                                                              required
                                                              onChange={(e) => handleChangeShippingAddress('country', e.target.value)}
                                                          >
                                                              <option value="">Select country</option>
                                                              {(islanderShippingList && islanderShippingList.length>0) && islanderShippingList.map((country) => (
                                                                  <option key={country.country} value={country.country}>
                                                                      {country.country}
                                                                  </option>
                                                              ))}
                                                          </Form.Select>
                                                        )}
                                                        {selectedType === 2 && (
                                                            <Form.Select
                                                            name="country"
                                                            value={addresses.billingAddress.country}
                                                            required
                                                            onChange={(e) => handleChangeShippingAddress('country', e.target.value)}
                                                            // onChange={(e) => handleInputChange(e, "shippingAddress")}
                                                        >
                                                            <option value="">Select Country</option>
                                                            {(allCountries && allCountries.length>0) && allCountries.map((item) => (
                                                                <option key={item} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                            </Form.Select>
                                                        )}
                                            </Form.Group>
                                            <Form.Group
                                                    controlId="state"
                                                    className="col-md-6 add-category-form__field userField select-field"
                                                >
                                                    <Form.Label>State</Form.Label>
                                                    {(selectedType === 4 || selectedType === 5) && (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="state"
                                                            name="state"
                                                            value={addresses.state}
                                                            onChange={(e) => handleChangeShippingAddress('state', e.target.value)}
                                                            required
                                                        /> 
                                                    )}
                                                   
                                                    {selectedType === 1 && (
                                                             <Form.Select
                                                             name="state"
                                                             value={addresses.state}
                                                             required
                                                             onChange={(e) => handleChangeShippingAddress('state', e.target.value)}
                                                             // onChange={(e) => handleInputChange(e, "shippingAddress")}
                                                         >
                                                             <option value="">Select Province</option>
                                                             {(pickupLocationList && pickupLocationList.length>0) && pickupLocationList.map((item) => (
                                                                 <option key={item.province} value={item.province}>
                                                                     {item.province}
                                                                 </option>
                                                             ))}
                                                         </Form.Select>
                                                    )}

                                                    {selectedType === 2 && (
                                                             <Form.Select
                                                             name="state"
                                                             value={addresses.state}
                                                             required
                                                             onChange={(e) => handleChangeShippingAddress('state', e.target.value)}
                                                            //  disabled={stateDisabled}
                                                         >
                                                              <option value="">Select Province</option>
                                                                {(states && states.length>0) && states.map((item) => (
                                                                <option key={item} value={item}>
                                                                    {item}
                                                                </option>
                                                                ))}
                                                         </Form.Select>
                                                    )}

                                                    {selectedType === 3 && (
                                                         <Form.Select
                                                         name="state"
                                                         value={addresses.state}
                                                         required
                                                         onChange={(e) => handleChangeShippingAddress('state', e.target.value)}
                                                     >
                                                         <option value="">Select Province</option>
                                                         {(sendPickupList && sendPickupList.length>0) && sendPickupList.map((item) => (
                                                             <option key={item.province} value={item.province}>
                                                                 {item.province}
                                                             </option>
                                                         ))}
                                                     </Form.Select>
                                                    )}
                                                    
                                            </Form.Group>
                                            <Form.Group
                                                    controlId="city"
                                                    className="col-md-6 add-category-form__field userField select-field"
                                                >
                                                    <Form.Label>City</Form.Label>
                                                    {(selectedType === 2 || selectedType === 4 || selectedType === 5) && (
                                                        <Form.Control
                                                        type="text"
                                                        placeholder="city"
                                                        name="city"
                                                        value={addresses.city}
                                                        onChange={(e) => handleChangeShippingAddress('city', e.target.value)}
                                                        required
                                                        /> 
                                                    )}
                                                   
                                                    {selectedType === 1 && (
                                                        <Form.Select
                                                         name="city"
                                                         value={addresses.city}
                                                         required
                                                         onChange={(e) => handleChangeShippingAddress('city', e.target.value)}
                                                        //  disabled={cityDisabled}
                                                     >
                                                         <option value="">Select City</option>
                                                         {(cities && cities.length>0) && cities.map((item) => (
                                                             <option key={item.city} value={item.city}>
                                                                 {item.city}
                                                             </option>
                                                         ))}
                                                     </Form.Select>
                                                    )}

                                                    {selectedType === 3 && (
                                                        <Form.Select
                                                         name="city"
                                                         value={addresses.city}
                                                         required
                                                         onChange={(e) => handleChangeShippingAddress('city', e.target.value)}
                                                        //  disabled={cityDisabled}
                                                     >
                                                         <option value="">Select City</option>
                                                         {(pickupCities && pickupCities.length>0) && pickupCities.map((item) => (
                                                             <option key={item.city} value={item.city}>
                                                                 {item.city}
                                                             </option>
                                                         ))}
                                                     </Form.Select>
                                                    )}  
                                            </Form.Group>
                                            <Form.Group
                                                    controlId="zip"
                                                    className="col-md-6 add-category-form__field userField"
                                                >
                                                    <Form.Label>Zip</Form.Label>
                                                    <Form.Control
                                                        name="zip"
                                                        type="text"
                                                        placeholder="zip"
                                                        value={addresses.zip}
                                                        onChange={(e) => handleChangeShippingAddress('zip', e.target.value)}
                                                        required
                                                    />
                                                    
                                            </Form.Group> 
                                        </div>
                                         {/* {!isValid && <Typography className="errorMsg pt-4">Please enter a valid 10-digit phone number</Typography>} */}
                                        <Typography className="color4 font-15 font-600 pt-3">{errorMessage}</Typography> 
                                    </Form>
                                </DialogContent>
                                <DialogActions className="btns-add-shipping-price">
                                    <Button
                                        color="primary"
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        Edit
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>

                        {/* <Support /> */}
                
        </>
    )
};

function TabPanel2(props) {
    const { children, value, index } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        className="tabPanelInner"
      >
        {value === index && 
        <Box pl={4} pt={1} className="tab-main">
            {children}
        </Box>}
      </div>
    );
  }

export default Profile;