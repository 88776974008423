import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import CMSFooter from '../../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const ShipABarrel = () => {
    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Ship A Barrel</Typography>
                        </Col>
                       
                    </Row>
                    <Box sx={{pt: 5,'@media (max-width: 767px)': {pt: 1},}}>

                            <Row>

                                 <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} className=' order-2 order-md-1'>
                                    <Box sx={{ display: "flex", justifyContent: "start", pt: 0 , pb: 0}}>
                                        <Box>
                                            <Box className="headerFlexBox headerFlexBox2"><h4>We</h4><h4 className="middleHeader">help families</h4><h4> help families</h4></Box>
                                            <hr className='border-bottom'></hr>
                                        </Box>
                                    </Box>
                                    <Box textAlign="left" className="service_inner" sx={{ pt: 2 }}>
                                        <Typography textAlign="left" className='color2 font-19'>Isle of Freight introduces our premium barrel shipping service. Our service is designed to provide a convenient and reliable solution for shipping your goods across the globe. With IOF, your goods are easily transported in a secure and cost-effective manner. Our service is ideal for individuals and businesses looking to ship a variety of items, including personal belongings and household goods.
                                        </Typography>
                                    </Box>
                                </Col>
                                 <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='barrel-img-box order-1 order-md-2' >
                                    <Box className="w-90" sx={{ mt: -7 }}>
                                        <img src="../assets/img/ship_barrel_img1.png" alt="list_img" className='' />
                                    </Box>
                                </Col>
                            </Row>
                            <Row>


                                 <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='barrel-img-box'>
                                    <Box className="w-60" sx={{ mt: -7 }}>
                                        <img src="../assets/img/ship_barrel_img2.png" alt="list_img" className='' />
                                    </Box>
                                </Col>
                                 <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5}>
                                    <Box textAlign="left" className="service_inner" sx={{pt: 5,'@media (max-width: 767px)': {pt: 2},}}>
                                        <Typography textAlign="left" className='color2 font-19'>Our team of experienced professionals is dedicated to providing exceptional customer service by also picking up your barrels from your doorsteps after it has been packed and ensuring that your shipment is handled with care and delivered on time. We also offer tracking and insurance options to give you peace of mind throughout the shipping process.
                                        </Typography>
                                    </Box>
                                </Col>
                            </Row>
                            <Row>

                                 <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} >
                                    <Box textAlign="left" className="service_inner" sx={{pt: 5,'@media (max-width: 767px)': {pt: 2},}}>
                                        <Typography textAlign="left" className='color2 font-19'>At IOF, we understand the importance of timely and reliable shipping, which is why we strive to provide a seamless and hassle-free experience for our customers. For all our international shippers, our service is designed to meet your needs and exceed your expectations.
                                        </Typography>
                                    </Box>
                                </Col>
                                 <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='barrel-img-box'>
                                    <Box className="w-60" sx={{ mt: -7 }}>
                                        <img src="../assets/img/ship_barrel_img3.png" alt="list_img" className='' />
                                    </Box>
                                </Col>
                            </Row>


                         

                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default ShipABarrel;
