import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    orderShippingList: [],
    orderPickupList: [],
    orderIslanderList: [],
    orderDeliveredList: [],
    orderCanceledList: [],
    allOrderList: [],
    islanderOrderDetail: [],
    orderDetail: [],
    loading: false,
    error: null,
    message: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchShippingOrderList = createAsyncThunk(
    "driver/orderShippingList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/orders", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

    // Async thunk action to fetch customers
    export const fetchPickupOrderList = createAsyncThunk(
      "driver/orderPickupList",
      async (_, { rejectWithValue }) => {
          try {
              const response = await Request.get("admin/order/pickupOrder", [], true);
              if (response.length !== 0) {
                   
                  return response
              } else {
                  console.error("Failed to fetch data from API");
              }
              } catch (error) {
                  return rejectWithValue(error);
              }
      }
    );

        // Async thunk action to fetch customers
        export const fetchIslanderOrderList = createAsyncThunk(
          "driver/orderIslanderList",
          async (_, { rejectWithValue }) => {
              try {
                  const response = await Request.get("admin/islander/iBoxOrderedItemList", [], true);
                  if (response.length !== 0) {
                       
                      return response
                  } else {
                      console.error("Failed to fetch data from API");
                  }
                  } catch (error) {
                      return rejectWithValue(error);
                  }
          }
        );

         // Async thunk action to fetch customers
         export const fetchDeliveredOrderList = createAsyncThunk(
          "driver/orderDeliveredList",
          async (_, { rejectWithValue }) => {
              try {
                  const response = await Request.get("admin/order/deliveredOrder", [], true);
                  if (response.length !== 0) {
                       
                      return response
                  } else {
                      console.error("Failed to fetch data from API");
                  }
                  } catch (error) {
                      return rejectWithValue(error);
                  }
          }
        );

        // Async thunk action to fetch customers
        export const fetchCanceledOrderList = createAsyncThunk(
        "driver/orderCanceledList",
        async (_, { rejectWithValue }) => {
            try {
                const response = await Request.get("admin/order/cancelledOrder", [], true);
                if (response.length !== 0) {
                     
                    return response
                } else {
                    console.error("Failed to fetch data from API");
                }
                } catch (error) {
                    return rejectWithValue(error);
                }
        }
        );

        // Async thunk action to fetch customers
        export const fetchAllOrderList = createAsyncThunk(
        "driver/allOrderList",
        async (_, { rejectWithValue }) => {
            try {
                const response = await Request.get("admin/orders/all", [], true);
                if (response.length !== 0) {
                     
                    return response
                } else {
                    console.error("Failed to fetch data from API");
                }
                } catch (error) {
                    return rejectWithValue(error);
                }
        }
        );

        // Async thunk action to fetch customers
        export const fetchIslanderOrderDetail = createAsyncThunk(
            "driver/islanderOrderDetail",
            async (_, { rejectWithValue }) => {
                try {
                    const response = await Request.get("admin/orders/all", [], true);
                    if (response.length !== 0) {
                         
                        return response
                    } else {
                        console.error("Failed to fetch data from API");
                    }
                    } catch (error) {
                        return rejectWithValue(error);
                    }
            }
            );

                            // Async thunk action to fetch customers
        export const fetchOrderDetail = createAsyncThunk(
            "driver/orderDetail",
            async (_, { rejectWithValue }) => {
                try {
                    const response = await Request.get("admin/orders/all", [], true);
                    if (response.length !== 0) {
                         
                        return response
                    } else {
                        console.error("Failed to fetch data from API");
                    }
                    } catch (error) {
                        return rejectWithValue(error);
                    }
            }
            );
       

  const orderListSlice = createSlice({
    name: "order",
    initialState, 
    loading: false,
    error: null,
    message: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchShippingOrderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchShippingOrderList.fulfilled, (state, action) => {
            state.loading = false;
            state.orderShippingList = action.payload;
          })
          .addCase(fetchShippingOrderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchPickupOrderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPickupOrderList.fulfilled, (state, action) => {
            state.loading = false;
            state.orderPickupList = action.payload;
          })
          .addCase(fetchPickupOrderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchIslanderOrderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchIslanderOrderList.fulfilled, (state, action) => {
            state.loading = false;
            state.orderIslanderList = action.payload;
          })
          .addCase(fetchIslanderOrderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchDeliveredOrderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchDeliveredOrderList.fulfilled, (state, action) => {
            state.loading = false;
            state.orderDeliveredList = action.payload;
          })
          .addCase(fetchDeliveredOrderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchCanceledOrderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchCanceledOrderList.fulfilled, (state, action) => {
            state.loading = false;
            state.orderCanceledList = action.payload;
          })
          .addCase(fetchCanceledOrderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchAllOrderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchAllOrderList.fulfilled, (state, action) => {
            state.loading = false;
            state.allOrderList = action.payload;
          })
          .addCase(fetchAllOrderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
        //   .addCase(updateDriverStatus.pending, (state) => {
        //     state.loading = true;
        //   })
        //   .addCase(updateDriverStatus.fulfilled, (state, action) => {
        //       
        //     state.loading = false;
        //     state.message = action.payload.message;
        //   })
        //   .addCase(updateDriverStatus.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        //   });
      },
  });
  
  export default orderListSlice.reducer;