import { useEffect, useRef, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import https from '../../customUtils/Http/Request';
import $ from 'jquery';
import { Container, Typography, Box, TextField, Button, Link } from '@mui/material';
import Cookies from 'universal-cookie';
// import { } from 'react-bootstrap';
import { checkLogin } from '../../commonFunctions/commonFunctions';
import { Dashboard } from '@mui/icons-material';
import  ReCAPTCHA  from 'react-google-recaptcha';

const cookies = new Cookies();
const CssTextField = styled(TextField)({
  // '& label':{
  //   fontSize: '15px !important',
  //   fontWeight: 'bold !important',
  //   color: '#033D1E',
  // },
  '& label.Mui-focused': {
    color: '#033D1E',
    fontSize: '15px !important',
    fontWeight: 'bold !important',
    lineHeight: '25px !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#033D1E',
  },

  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    // "&.MuiOutlinedInput-notchedOutline": {
    //   fontSize: "14px",
    // },
    '&:hover fieldset': {
      borderColor: '#FFC72C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFC72C',
      borderWidth: '1px',
    },
  },
});

function Login() {

  let login = checkLogin();
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const [message, setMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [otpStatus, setOtpStatus] = useState(false);
  const [loginOtp, setLoginOtp] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [recaptchaSize, setRecaptchaSize] = useState(windowWidth > 450 ? 'normal' : 'compact');


  useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setRecaptchaSize(window.innerWidth > 450 ? 'normal' : 'compact');
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  const [userDetail, setUserDetail] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = checkLogin();
    let userType = cookies.get('userType', { path: '/' });
    let userTypeAsNumber = parseInt(userType, 10);
    // If the user is logged in, redirect to the dashboard
    if(isLoggedIn && (userTypeAsNumber === 3)) {
      // return <Navigate to="/dashboard" replace />;
      navigate('/user/customer/dashboard')
    }else if(isLoggedIn && (userTypeAsNumber === 1 || userTypeAsNumber === 2)){
      navigate("/admin/orders")
    }else if(isLoggedIn && (userTypeAsNumber === 4)){
      navigate('/driver/orders')
    }else{
      navigate('/login')
    }
  }, []);

  const resendOtp = async (e) => {
    const cartItemsCookie = cookies.get('cartItems', { path: "/" });
    if (cartItemsCookie !== undefined && cartItemsCookie.cartItems.length > 0) {
      var loginData2 = {
        email: userDetail.email,
        password: userDetail.password,
        cartItems: cartItemsCookie.cartItems,
      }
    }else{
      var loginData2 = {
        email: userDetail.email,
        password: userDetail.password,
      }
    }
    var response = await https.post("user/login", loginData2);
    if (response.message) {
        
      if (response.message === 'OTP sent') {
        setMessageText(response.message)
        setTimeout(() => {
          setMessageText('')
        }, 4000);
        setOtpStatus(true)
        setLoginOtp('')
      }
      else {
        setMessageText(response.message)
        setTimeout(() => {
          setMessageText('')
        }, 4000);
        return
      }
    }else{
      alert("Please try again")
    }
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if ((otpStatus === true && loginOtp === '') || loginOtp === undefined) {
      alert("Please Enter Otp first")
    }
    else {
        
      if (userDetail.email && userDetail.password !== '') {
        const cartItemsCookie = cookies.get('cartItems', { path: "/" });
        if (cartItemsCookie !== undefined && cartItemsCookie.cartItems.length > 0) {
          if (otpStatus === true) {
            var loginData = {
              email: userDetail.email,
              password: userDetail.password,
              cartItems: cartItemsCookie.cartItems,
              otp: loginOtp,
              captchaValue: recaptchaValue
            }
          } else {
            var loginData = {
              email: userDetail.email,
              password: userDetail.password,
              cartItems: cartItemsCookie.cartItems,
              captchaValue: recaptchaValue
            }
          }

          var response = await https.post("user/login", loginData);
          if (response.message) {
              
            if (response.message === 'OTP sent') {
              setMessageText(response.message)
              setTimeout(() => {
                setMessageText('')
              }, 4000);
              setOtpStatus(true)
              setRecaptchaValue('')
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
            }
            else {
              setMessageText(response.message)
              setTimeout(() => {
                setMessageText('')
              }, 4000);
              setRecaptchaValue('')
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              return
            }
          }

          else {
            var date = new Date();
            let accessDate = new Date(date.getTime() + 120 * 60000);
            let refreshDate = new Date(date.getTime() + 7 * 24 * 60 * 60000);
            cookies.set("accessToken", response.accessToken, { path: "/", expires: accessDate, secure: true });
            cookies.set("refreshToken", response.refreshToken, { path: "/", expires: refreshDate , secure: true});
            cookies.set("userType", response.userType, { path: "/", expires: accessDate, secure: true });
            if (response.userType === 1) {
              navigate('/admin/orders')
            } else if (response.userType === 4) {
              navigate('/driver/orders')
            }else if(response.userType === 2){
              navigate('/admin/orders')
            }
            else {
              navigate('/user/buy/buy-item-cart')
            }
          }
        }

        else {
          if (otpStatus === true) {
            var loginItems = {
              email: userDetail.email,
              password: userDetail.password,
              otp: loginOtp,
              captchaValue: recaptchaValue
            }
          } else {
            var loginItems = {
              email: userDetail.email,
              password: userDetail.password,
              captchaValue: recaptchaValue
            }
          }
          var response = await https.post("user/login", loginItems);
          if (response.message) {
            // setMessage(true)
            if (response.message === 'OTP sent') {
              setMessageText(response.message)
              setTimeout(() => {
                setMessageText('')
              }, 4000);
              setOtpStatus(true)
              setRecaptchaValue('')
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
            }
            else {
              setMessageText(response.message)
              setTimeout(() => {
                setMessageText('')
              }, 4000);
              setRecaptchaValue('')
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              return
            }
          }
          else {
            var date = new Date();
            let accessDate = new Date(date.getTime() + 120 * 60000);
            let refreshDate = new Date(date.getTime() + 7 * 24 * 60 * 60000);
            cookies.set("accessToken", response.accessToken, { path: "/", expires: accessDate, secure: true });
            cookies.set("refreshToken", response.refreshToken, { path: "/", expires: refreshDate, secure: true });
            cookies.set("userType", response.userType, { path: "/", expires: accessDate, secure: true });
            if (response.userType === 1) {
              navigate('/admin/orders')
            }
            else if (response.userType === 4) {
              navigate('/driver/orders')
            }
            else if (response.userType === 2) {
              navigate('/admin/orders')
            }
            else {
              navigate('/user/customer/dashboard')
            }
          }
        }
      }
      else {
        alert("Please fill the details first")
      }
    }
  };


  const otpChange = (e) => {
    let val = e.target.value;
    setLoginOtp(val)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetail({
      ...userDetail,
      [name]: value,
    });
  };

  
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  return (
    <>
      {login === false ? (

        <>
          <Header />
          <Container maxWidth="xl" className='homeContainer'>
            <Box className='middle_block' textAlign="center">
              <Box className='middle_inner_block'>
                {otpStatus === true ? (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                      <Typography variant='h5' className='formTitle'>Verify Email</Typography>
                      </Box>
                      <Box className='login_form'
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                          mt: 3,
                        }}
                        // noValidate
                        autoComplete="off"
                        onSubmit={event => handleLoginSubmit(event)}
                      >
                        <div>
                          <CssTextField fullWidth value={loginOtp} id="otp" floatinglabeltext="Otp" type="text" label="Otp" variant="outlined" className='text_field' onChange={otpChange} />
                        </div>
                        {/* <div>
                        <CssTextField fullWidth id="password" hinttext="Password" floatinglabeltext="Password" label="Password" type="password" className='text_field'></CssTextField>
                      </div> */}
                        <div>
                          <Button variant="contained" type="submit" className='submitBtn' id="loginSubmitBtn">Verify</Button>
                        </div>
                        <div>
                        {windowWidth > 450 ? (
                              <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="normal"
                          />
                        ):(
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_CAPCHA_KEY}
                                onChange={handleRecaptchaChange}
                                className="recaptcha_block"
                                size="compact"
                            />
                        )}
                      </div>
                        <div>
                          <Button variant="contained" className='b1 b4' id="" onClick={resendOtp} sx={{mt: 1}} >Resend OTP</Button>
                        </div>
                        
                        <Typography className="loginErrorMessage2" id="">{messageText}</Typography>
                        {/* <div>
                        <Link href="/forgot-password" color="inherit"><Typography sx={{ color: '#646464', fontSize: '16px !important' }}>Forgot Username/Password?</Typography></Link>
                        <Link href="/signup"><Typography sx={{ fontSize: '14px', mt: 1 }} className='color4'>Sign Up</Typography></Link>
                      </div> */}
                      </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                      <Typography variant='h5' className='formTitle'>Login </Typography> <Typography variant='h5' className='formTitle formTitle2'>Now</Typography>
                    </Box>

                    <Box className='login_form'
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                        mt: 3,
                      }}
                      // noValidate
                      autoComplete="off"
                      onSubmit={event => handleLoginSubmit(event)}
                    >
                      <div>
                        <CssTextField fullWidth name="email" value={userDetail.email} id="email" floatinglabeltext="Email" type="text" label="Email" variant="outlined" className='text_field' required onChange={handleInputChange} />
                      </div>
                      <div>
                        <CssTextField fullWidth name="password" value={userDetail.password} id="password" hinttext="Password" floatinglabeltext="Password" label="Password" type="password" className='text_field' onChange={handleInputChange} required></CssTextField>
                      </div>
                      <div>
                      {windowWidth > 450 ? (
                          <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="normal"
                          />
                        ):(
                          <ReCAPTCHA
                            ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="compact"
                          />
                        )}
                      </div>
                      <div>
                        <Button variant="contained" type="submit" className='submitBtn login_Btn'  id="loginSubmitBtn">Login </Button>
                      </div>

                      <Typography className="loginErrorMessage2" id="">{messageText}</Typography>


                      {/* <div>
                          <Button variant="contained" className='submitBtn submitBtn2'>Login In with Google</Button>
                        </div> */}
                      <div>
                        <Link href="/forgot-password" color="inherit"><Typography sx={{ color: '#646464', fontSize: '16px !important' }}>Forgot Username/Password?</Typography></Link>
                        <Link href="/signup"><Typography sx={{ fontSize: '14px', mt: 1 }} className='color4'>Sign Up</Typography></Link>
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Container>
          <Footer />
        </>
      ) : (
        <>
          <Dashboard />
        </>
      )}
    </>
  );
}

export default Login;