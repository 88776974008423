import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DriverTable from "./DriverTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchAssignedTaskList,fetchPendingTaskList, fetchCompletedTaskList, fetchRejectedTaskList } from "../redux/taskManagementSlice";
import { approveDriver } from "../redux/driverSlice";
import OrderTable from "./orderTable";

function TaskManagement() {
	const [selectedTab, setSelectedTab] = useState(0);
	const [assignedTaskInfo, setAssignedTaskInfo] = useState([]);
	const [pendingTaskInfo, setPendingTaskInfo] = useState([]);
    const [completedTaskInfo, setCompletedTaskInfo] = useState([]);
	const [rejectedTaskInfo, setRejectedTaskInfo] = useState([]);
	const dispatch = useDispatch();

	const assignedTaskList = useSelector((state) => state.taskManagement.assignedTaskList);
	const pendingTaskList = useSelector((state) => state.taskManagement.pendingTaskList);
    const completedTaskList = useSelector((state) => state.taskManagement.completedTaskList);
	const rejectedTaskList = useSelector((state) => state.taskManagement.rejectedTaskList);
	

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	useEffect(() => {
		switch (selectedTab) {
			case 0:
				dispatch(fetchPendingTaskList());
				break;
			case 1:
                dispatch(fetchAssignedTaskList());
				break;
			case 2:
				dispatch(fetchCompletedTaskList());
				break;
            case 3:
                dispatch(fetchRejectedTaskList());
                break;
			default:
				dispatch(fetchPendingTaskList());
				break;
		  }
	}, [selectedTab]);


	return (
		<>
			<Box className="adminInnerPage driverListPage">
				<Typography variant="h2" className="adminInnerPage__title">
					Task Management
				</Typography>

				<Box className="adminInnerPage__tabs">
					<Tabs value={selectedTab} onChange={handleTabChange}>
						<Tab label="Pending For Assigned" />
						<Tab label="Assigned" />
						<Tab label="Completed" />
                        <Tab label="Cancelled" />
					</Tabs>
				</Box>

				<Box className="adminInnerPage__tabs-content">
					{selectedTab === 0 && (
						<Typography component="div" role="tabpanel">
							<div className="order-search-filter">
								<div className="order-search-filter__search">
									{/* <input
										type="text"
										placeholder="Search For Order"
									/> */}
								</div>
							</div>

							<OrderTable orderInfo={pendingTaskList} type="pending for assignment"/>
						</Typography>
					)}
					{selectedTab === 1 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Order"
								/> */}
							</div>
						</div>

						<OrderTable orderInfo={assignedTaskList} type="assigned"/>
					</Typography>
					)}
					{selectedTab === 2 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Order"
								/> */}
							</div>
						</div>

						<OrderTable orderInfo={completedTaskList} type="completed"/>
					</Typography>
					)}
                    {selectedTab === 3 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Order"
								/> */}
							</div>
						</div>

						<OrderTable orderInfo={rejectedTaskList} type="cancelled"/>
					</Typography>
					)}
				</Box>
			</Box>
		</>
	);
}

export default TaskManagement;
