import React from "react";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
} from "@mui/material";


function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
  }

const DriverPendingOrdersTable = ({ orders }) => {
	return (
		<div className="order-table-container">
			<TableContainer component={Paper}>
				<Table aria-label="Pending Orders Table">
					<TableHead>
						<TableRow>
							<TableCell>Order ID</TableCell>
							<TableCell>Order Date</TableCell>
							<TableCell>Customer</TableCell>
							<TableCell>Item</TableCell>
							<TableCell>Est. Pick Up & Delivery Date</TableCell>
							<TableCell>Location</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(orders && orders.length>0) && (
							<>
								{orders.map((order) => (
								<TableRow key={order.id}>
									<TableCell>{order.order?.invoiceNo}</TableCell>
									<TableCell>{formatDate(order.createdAt)}</TableCell>
									<TableCell>{order.order.user.fullName}</TableCell>
									<TableCell>{order.order.orderType === "Purchase" ? "Packaging" : "Shipping"}</TableCell>
									<TableCell>
									{formatDate(order.order.estDeliveryTimeInDay)}
									</TableCell>
									<TableCell>{order.order.shippingAddress?.city}</TableCell>
									<TableCell	
										className={
											order.status === 3
											? "color4"
											: "color3"
										}
									>
										{order.status === 3 ? "Completed" : "Pending"}
									</TableCell>
								</TableRow>
								))}
							</>
						)}
					
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default DriverPendingOrdersTable;
