import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Avatar,
} from "@mui/material";
import DriverModal from "./DriverModal";
import Request from '../customUtils/Http/Request';
import Rating from '@mui/material/Rating';
import ReviewModal from "./reviewModal";


function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
  }

const AdminCustomerReviewTable = ({reviewInfo , type}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedReview, setSelectedReview] = useState(null);
    const [selectedReviewRating, setSelectedReviewRating] = useState(0);
	const [selectedReviewId, setSelectedReviewId] = useState(null);

	const handleOpenModal = (review, rate, id) => {
		setSelectedReview(review);
        setSelectedReviewRating(rate);
		setSelectedReviewId(id);
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedReview(null);
		setModalOpen(false);
	};

	return (
		<div className="order-table-container driver-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>User Name</TableCell>
							<TableCell>Rate</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Title</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{reviewInfo && reviewInfo.length>0 ? (
							<>
							{reviewInfo.map((review) => (
							<TableRow key={review.id}>
								<TableCell className="font-bold text-underline">
                                    <span onClick={() => handleOpenModal(review.review, review.rate, review.id, )}
										className="driver-table__name cursor-pointer font-bold text-underline">
                                        {review.user?.fullName}
                                    </span>
								</TableCell>
								<TableCell>
                                <Rating
                                    name="simple-controlled"
                                    value={review.rate}
                                    size="large"
                                    readOnly 
                                    className="rating tableRating"
                                />
								</TableCell>
								<TableCell>{formatDate(review.createdAt)}</TableCell>
								<TableCell>{review.title}</TableCell>
							</TableRow>
						))}
							</>
						):<></>}
						
					</TableBody>
				</Table>
			</TableContainer>

			{reviewInfo && reviewInfo.length !== 0 && (
                <ReviewModal
                    isOpen={modalOpen}
                    handleClose={handleCloseModal}
                    review={selectedReview}
                    reviewRating={selectedReviewRating}
                    reviewInfo={reviewInfo} type={type} reviewId={selectedReviewId}
                />
			)}
			
		</div>
	);
};

export default AdminCustomerReviewTable;
