
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export function CheckNumeric(value) {
    if (isNumeric(value)) {
        return value + "GL";
      } else {
        return value; // Return the original value if it's not numeric
      }
}