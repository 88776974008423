import React, { useState, useEffect, useRef } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
// import ProductCategoriesTable from "./ProductCategoriesTable";
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchHelpCategories, fetchQuestion, fetchPopularQuestion } from "../../redux/helpCenterSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Editor } from '@tinymce/tinymce-react';
import QuestionListTable from "./questionListTable";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function QuestionList() {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
  const [isCustom, setIsCustom] = useState(false);
  const editorRef = useRef(null);
	const [data, setData] = useState({
		"question": '',
		"answer" : '',
        "category":'',
        "popular":false
	});
    const [error, setError] = useState('');

	const questions = useSelector((state) => state.helpCenter.questionList);
  const categories = useSelector((state) => state.helpCenter.helpCategoryList);
  

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	// const handleNameChange = (e) => {
	// 	const newName = e.target.value;
	// 	setCategoryData((prevData) => ({
	// 	  ...prevData,
	// 	  question: newName,
	// 	}));
	//   };

  

      const handleFieldChange = (field, value) => {
		setData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

    const handleEditorChange = (content, editor) => {
        setData((prevData) => ({
			...prevData,
			answer: content,
		}));
      };

      const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        if (selectedCategory === 'custom') {
          // Enable custom category input
          setIsCustom(true);
        } else {
          // Disable custom category input and update the selected category
          setIsCustom(false);
          handleFieldChange('category', selectedCategory);
        }
      };

    const handleCheckboxChange = (fieldName) => {
        setData({
          ...data,
          [fieldName]: !data[fieldName], // Toggle the value of the checkbox
        });
      };

	  const handleSubmit = async (e) => {
        e.preventDefault();

        if (!data.question || !data.category || !data.answer) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.post('admin/helpCentre', data, true);
			if (response.message === "Success") {
				 
				setOpen(false)
        setIsCustom(false)
				dispatch(fetchQuestion())
                setData({
                    "question": '',
                    "answer" : '',
                    "category":'',
                    "popular":false
                });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}

        setError('');
	  };

	useEffect(() => {
		dispatch(fetchQuestion());
        dispatch(fetchHelpCategories());
	}, [dispatch]);

  const handleLink = (editor) => {
    const url = prompt('Enter the URL for the link:');
    if (url) {
        editor.execCommand('mceInsertLink', false, url);
    }
};

    const uploadImage = (blobInfo) => {
        return new Promise((resolve, reject) => {
          let accessToken = cookies.get('accessToken', { path: '/' });
          const formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          
          const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        //  fetch('https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/helpCentre/uploadImageForHelpCentre', {
          fetch(`${process.env.REACT_APP_DatabaseServer}admin/helpCentre/uploadImageForHelpCentre`, {
            method: 'POST',
            headers: headers,
            body: formData
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Image upload failed');
              }
            })
            .then(data => {
              resolve(data.url);
            })
            .catch(error => {
              console.error('Error uploading image:', error);
              reject('Error uploading image');
            });
        });
    };

	return (
		<Box className="adminInnerPage productCategories">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
					Questions
				</Typography>

				<Button
					variant="primary"
					color="primary"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Questions
				</Button>

				<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					{/* <form onSubmit={handleSubmit}> */}
					<DialogTitle className="admin-modal__title-container">
						Add Question
						<Button color="primary" className="btn" onClick={handleSubmit}>
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form add-category-form">
								<div className="row add-product-form__row">
                                    <Typography className="font-15 font-600" style={{ color: 'red' }}>{error}</Typography>

                                    <Form.Group controlId="question" className="shipping-price-form__field">
                                    <Form.Label>Enter Question</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data.question}
                                        placeholder="Enter your question"
                                        onChange={(e) => handleFieldChange("question", e.target.value)}
                                        className="questionPlaceholder"
                                        required
                                    />
                                    </Form.Group>

                                    <Form.Group controlId="category" className="shipping-price-form__field">
                                    <Form.Label>Select Category</Form.Label>
                                     {isCustom ? (
                                      <Form.Control
                                          type="text"
                                          value={data.category}
                                          onChange={(e) => handleFieldChange('category', e.target.value)}
                                          placeholder="Enter custom category"
                                          required
                                      >
                                      </Form.Control>
                                      ):(
                                      <Form.Control
                                          as="select"
                                          value={data.category}
                                          // onChange={(e) => handleFieldChange("category", e.target.value)}
                                          onChange={handleCategoryChange}
                                          required
                                      >
                                        <option value="">Select category</option>
                                        {(categories && categories.length>0) && categories.map(option => (
                                              <option key={option.id} value={option.category}>
                                                  {option.category}
                                              </option>
                                         ))}
                                          <option value="custom">Add custom category</option>
                                      </Form.Control>)}
                                      </Form.Group>

                                    <Form.Group controlId="answer" className="shipping-price-form__field">
                                    <Form.Label>Enter Answer</Form.Label>
                                    <Editor
                                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            initialValue='<p>Type Your Answer Here..</p>'
                                            init={{
                                              plugins: 'image lists link autolink',
                                              toolbar: 'bold italic underline undo redo | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image',
                                              // menubar: 'insert',
                                              automatic_uploads: true,
                                              images_upload_handler: uploadImage,
                                              // links_upload_handler: uploadImage,
                                              // convert_urls: false,
                                              default_link_target: '_blank',
                                              link_assume_external_targets: true,
                                              link_context_toolbar: true,
                                              link_default_protocol: 'https',
                                              paste_preprocess: function(plugin, args) {
                                                args.content += ' ';
                                              },
                                              urlconverter_callback: function(url, node, on_save, name) {
                                                // Prevent conversion of & to &amp;
                                                return url.replace(/&amp;/g, '&');
                                              }
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="popular" className="shipping-price-form__field">
                                        <label for="popular" className="font-600 d-flex align-items-center gap-2">
                                            <input
                                                type="checkbox"
                                                label="Save as Popular Question"
                                                id="popular"
                                                checked={data.popular}
                                                onChange={() => handleCheckboxChange("popular")}
                                                style={{width: "15px",height: "15px"}}
                                            />
                                            Save as Popular Question
                                        </label>
                                        
                                    </Form.Group>

                                   
								</div>
							</Box>
						</Box>
					</DialogContent>
				</Dialog>
			</div>

			<div className="productListPage__container">
				<div className="order-search-filter product-search-filter">
					{/* <IconButton
						aria-label="edit"
						size="small"
						className="product-search-filter__icon"
					>
						<TuneIcon />
					</IconButton> */}
					{/* <div className="order-search-filter__search">
						<input type="text" placeholder="Search Question" />
					</div> */}
				</div>

				<QuestionListTable />
			</div>
		</Box>
	);
}

export default QuestionList;
