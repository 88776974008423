import { useEffect, useState, useRef } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Header from '../header/header';
import Footer from '../footer/footer';
import Cookies from 'universal-cookie';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, ListItemAvatar, ListItemText, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';
import { protect } from '../commonFunctions/isValid';
import { useInView } from 'react-intersection-observer';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Carousel as Carousel2 } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Avatar, Card, CardMedia } from '@material-ui/core';
import { spacing } from '@mui/system';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typed from 'typed.js';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import CMSFooter from '../footer/cmsFooter';
const cookies = new Cookies();
// import { Carousel as ReactCarousel  } from 'react-bootstrap';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { BootstrapInput } from 'react-bootstrap';

// const useStyles = makeStyles((theme) => ({
//     // contentContainer: {
//     //   paddingRight: theme.spacing(2),
//     // },
//     // sliderContainer: {
//     //   position: 'relative',
//     //   height: '100%',
//     //   display: 'flex',
//     //   alignItems: 'center',
//     // },
//     // sliderDotContainer: {
//     //   position: 'absolute',
//     //   bottom: theme.spacing(2),
//     //   left: '50%',
//     //   transform: 'translateX(-50%)',
//     //   display: 'flex',
//     // },
//     // sliderDot: {
//     //   width: theme.spacing(1),
//     //   height: theme.spacing(1),
//     //   borderRadius: '50%',
//     //   margin: theme.spacing(0, 0.5),
//     //   backgroundColor: theme.palette.primary.main,
//     //   opacity: 0.5,
//     //   cursor: 'pointer',
//     //   transition: 'opacity 0.3s',
//     // },
//     // activeSliderDot: {
//     //   opacity: 1,
//     // },
//   }));
const useStyles = makeStyles((theme) => ({
    list: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    listItem: {
        paddingLeft: "0px",
    },
    Avatar: {
        borderRadius: "4px",
        width: "45px",
        height: "45px",
        marginRight: "20px"
    },
    card: {
        maxWidth: 200,
        margin: spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9 aspect ratio
    },
    // grid:{
    //     gap: "20px",
    // },
    grid: {
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "15px",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },

    // For medium screens (between 600px and 960px)

    col2: {
        textAlign: "left",
        paddingLeft: "20px"
    },
    blockBackground: {
        backgroundImage: "url('./assets/img/block_bg_image.svg')",
        backgroundPosition: "left top",
        backgroundRepeat: "no-repeat",
    },
    blockInnerBackground: {
        backgroundColor: "#FFC933 !important",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "50px 0px"
    },
    blockBackground1: {
        backgroundImage: "url('./assets/img/block_bg_pink.svg')",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
    }

}));

const companyLogo = [
    { id: 1, title: 'logo1', imageUrl: 'carousel_logo1.svg' },
    { id: 2, title: 'logo4', imageUrl: 'carousel_logo2.svg' },
    { id: 3, title: 'logo3', imageUrl: 'carousel_logo3.svg' },
    { id: 4, title: 'logo4', imageUrl: 'carousel_logo4.svg' },
    { id: 5, title: 'logo5', imageUrl: 'carousel_logo5.svg' },
    { id: 6, title: 'logo6', imageUrl: 'carousel_logo6.svg' },
    { id: 7, title: 'logo7', imageUrl: 'carousel_logo7.svg' },
    { id: 8, title: 'logo8', imageUrl: 'carousel_logo8.svg' },
    { id: 9, title: 'logo9', imageUrl: 'carousel_logo9.svg' },
    { id: 10, title: 'logo10', imageUrl: 'carousel_logo10.svg' },
    { id: 11, title: 'logo11', imageUrl: 'carousel_logo11.svg' },
    { id: 12, title: 'logo12', imageUrl: 'carousel_logo12.svg' },
    { id: 13, title: 'logo13', imageUrl: 'carousel_logo13.svg' },
];

const countries = [
    { id: 1, title: 'Dominica', imageUrl: 'country8.svg' },
    { id: 2, title: 'Saint Lucia', imageUrl: 'country13.svg' },
    { id: 3, title: 'Jamaica', imageUrl: 'country11.svg' },
    { id: 4, title: 'Antigua and Barbuda', imageUrl: 'country4.svg' },
    { id: 5, title: 'Barbados', imageUrl: 'country6.svg' },
    { id: 6, title: 'Grenada', imageUrl: 'country9.svg' },
    { id: 7, title: 'Guyana', imageUrl: 'country18.svg' },
    { id: 8, title: 'Saint Vincent', imageUrl: 'country19.svg' },
    { id: 9, title: 'Trinidad and Tobago', imageUrl: 'country17.svg' },

];

const images = [{
    path: './assets/img/slider_img2.svg',
    id: 'slide 1'
},
{
    path: './assets/gif/iof_slider-2.gif',
    id: 'slide 2'
},
{
    path: './assets/img/iof_slider-3.svg',
    id: 'slide 3'
}];

function Home() {

    let login = checkLogin()
    // const classes = useStyles();
    const [activeItemId, setActiveItemId] = useState(1); // State to track active item
    const classes = useStyles();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const isLoop = false;
    const videoRef = useRef(null);
    const [showOverlay, setShowOverlay] = useState(true);
    let [currentSlide, setCurrentSlide] = useState(2); 
    const [ref, inView] = useInView({
        triggerOnce: false, // Animation will only trigger once when element comes into view
        threshold: 0, // Set the threshold for triggering the animation
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true, // Animation will only trigger once when element comes into view
        threshold: 0, // Set the threshold for triggering the animation
    });
    const handleCarouselChange = (index) => {
        setActiveItemId(index + 1); // Update active item ID
    };

    const handleVideoPlay = () => {
        setShowOverlay(false);
    };

    const [animated, setAnimated] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    // const [ref3, inView3] = useInView({
    //     triggerOnce: true, // Trigger the animation only once
    //   });
    // const textRef = useRef(null);
    // const typedRef = useRef(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (panel) => (event, isExpanded2) => {
        setExpanded2(isExpanded2 ? panel : false);
    };

      // Handle slide change
  const handleSlideChange = (animationCurrentSlide) => {
    currentSlide+=1
    setCurrentSlide(currentSlide);
  };


    const dotProps = {
        style: {
            /* Customize the dot styling here */
            backgroundColor: '#DB2D6A !important',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            margin: '0 5px',
        },
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    function Section({ text, image }) {
        const [ref3, inView3] = useInView({
            triggerOnce: true, // Trigger the animation only once
        });

        const textRef = useRef(null);
        const typedRef = useRef(null);

        useEffect(() => {
            if (inView3) {
                const options = {
                    strings: [text],
                    typeSpeed: 200, // Typing speed in milliseconds
                    showCursor: false,
                    onComplete: () => {
                        typedRef.current.reset(); // Reset the typed animation to loop
                    },
                };

                typedRef.current = new Typed(textRef.current, options);
            }
        }, [inView3, text]);

        return (
            <div className="sectionBlock" ref={ref3}>
                <span className='font-60 font-bold font-roboto font-60-height' ref={textRef}></span>
                <span>{image !== '' && <img src={`./assets/img/${image}`} alt="Section Image" className='Question_image' />}</span>
            </div>
        );
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust this threshold as needed, e.g., 0.3 for 30% visibility
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        const videoElement = videoRef.current;

        if (videoElement) {
            observer.observe(videoElement);
        }

        return () => {
            if (videoElement) {
                observer.unobserve(videoElement);
            }
        };
    }, []);


    useEffect(() => {
        setAnimated(true);
    }, []);

    // const SplitText = ({ copy, role }) => {
    //     const [animated, setAnimated] = useState(false);

    //     useEffect(() => {
    //       // Trigger animation once the component mounts
    //       setAnimated(true);
    //     }, []);

    //     return (
    //       <span aria-label={copy} role={role}>
    //         {copy.split("").map((char, index) => {
    //           let style = {
    //             animationDelay: animated ? (0.5 + index / 10) + "s" : "0s",
    //           };
    //           return (
    //             <span
    //               aria-hidden="true"
    //               key={index}
    //               style={style}
    //               className={animated ? "animated-text" : ""}
    //             >
    //               {char}
    //             </span>
    //           );
    //         })}
    //       </span>
    //     );
    //   };

    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // Video section is in view
                setIsVideoPlaying(true);
                videoRef.current.play();
            } else {
                // Video section is out of view
                setIsVideoPlaying(false);
                videoRef.current.pause();
            }
        });
    };

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(current => !current);
      };
    return (
        <>
            <Header />
                <Box sx={{ backgroundColor: "#fff !important" }} className="homeContainer">
                    <Container maxWidth="xl">
                        <Box className="container">
                            <Grid container spacing={2} alignItems="flex-start" style={{marginTop: 20}}>
                                <Grid item xs={12} md={6} lg={5} xl={5} sx={{ display: "flex", alignItems: "center" }} className='container-content-box'>
                                    <Box>
                                        <Typography className='font-60 font-bold font-roboto font-60-height font-capital'>We Ship Barrels To <span className='font-60 font-bold font-roboto font-60-height font-capital highlight-text'>Your Island</span></Typography>
                                        <Typography sx={{ mt: 1, mb: 0 }} className='sliderContentBox'>We make barrel shipping easy!</Typography>
                                        <Typography sx={{ mt: 1, mb: 0 }} className='sliderContentBox'>Pack your barrel, we’ll pick it up from your home and ship it to your loved ones on the island. Giving you the best service at unbeatable prices.
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 2 }} className='sliderContentBox'>Isle of Freight makes everything great!</Typography>
                                        <Link to='/signup'>
                                            <Button className='homeBtn'>Get Started</Button>
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={7} xl={7} className="carousel-Box">
                                    <Carousel2 autoPlay
                                        infiniteLoop
                                        interval={5000}
                                        showThumbs={false}
                                        showArrows={false}
                                        showStatus={false}
                                        showIndicators={true}
                                        dotProps={dotProps}
                                        responsive={responsive}
                                        autoPlaySpeed={5000}
                                    >
                                        {images.map((item, index) => (
                                            <div key={index}>
                                                {/* <h3>{item.title}</h3> */}
                                                <img src={item.path} alt={item.id} className='slide_image'></img>
                                                {/* Add your item content here */}
                                            </div>
                                        ))}
                                    </Carousel2>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                            <Box>
                                <Box className="headerFlexBox"><h4>We</h4><h4 className="middleHeader">help families</h4><h4> help families</h4></Box>
                                <hr className='border-bottom'></hr>
                            </Box>
                        </Box>
                        <Box align="center" sx={{ pt: 10, pb: 5 }}>
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
                                    <Box className="video-img" sx={{ position: "relative" }}>
                                        {/* <Typography className='font-60 font-bold font-roboto font-60-height'> */}
                                        <Section text="It's Very Simple" image="" />
                                        {/* It's Very Simple */}
                                        {/* </Typography> */}
                                        {/* <Typography sx={{ pt: 1, pb: 2 }}>video text heading content goes here</Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                    <Box sx={{ pt: 0, pb: 5 }} className="video-container">
                        {/* <iframe
                                    title="YouTube Video"
                                    width="100%"
                                    height="700"
                                    src="https://www.youtube.com/embed/VIDEO_ID"
                                    frameBorder="0"
                                    allowFullScreen>
                                </iframe> */}
                        <CardMedia
                            component="video"
                            src="/assets/video/iof_process.mp4" // Replace this with your actual video URL
                            controls={false} // This enables video controls (play, pause, etc.)
                            title="IOF_Process"
                            loop
                            className="videoBox"
                            ref={videoRef}
                            autoPlay={isVideoPlaying}
                            onContextMenu={(e) => e.preventDefault()}
                        // onClick={handlePlay}
                        />

                    <div className="fixsocial" style={{
                        left: isClicked ? '0px' : '-235px',
                        // include other styles as needed
                        }}
                        onClick={handleClick}>
                        <div className="socials">
                            <Typography className='font-16 font-bold'>Flat Shipping Rate: $5.99</Typography>
                        </div>
                        <div className="arrow" >
                        <div  id="fixsocial" className="">
					        <i className="fas fa-angle-right"></i>
				        </div>
                        </div>
		            </div>


                    </Box>
                   
                        <Box className={classes.blockInnerBackground}>
                            <Container maxWidth="xl">
                                <Box align="center">
                                    {/* <Grid container spacing={2}>
                                        <Grid item xs={12} md={7}> */}
                                    {/* <Box className="left-to-right-animation"> */}
                                    <Box>
                                        <Typography className="font-60 font-bold font-roboto font-60-height text-uppercase">
                                            Buy Your Empty Barrel With US
                                        </Typography>
                                        {/* <Typography className='font-60 font-bold font-roboto font-60-height animate five'>
                                            <span className={inView ? 'animated-letter' : ''}>Buy</span>
                                            <span className={inView ? 'animated-letter' : ''}>&nbsp;</span>
                                            <span className={inView ? 'animated-letter' : ''}>Your</span>
                                            <span className={inView ? 'animated-letter' : ''}>&nbsp;</span>
                                            <span className={inView ? 'animated-letter' : ''}>Empty</span>
                                            <span className={inView ? 'animated-letter' : ''}>&nbsp;</span>
                                            <span className={inView ? 'animated-letter' : ''}>Barrel</span>
                                            <span className={inView ? 'animated-letter' : ''}>&nbsp;</span>
                                            <span className={inView ? 'animated-letter' : ''}>With</span>
                                            <span className={inView ? 'animated-letter' : ''}>&nbsp;</span>
                                            <span className={inView ? 'animated-letter' : ''}>Us</span>
                                        </Typography> */}
                                        <Typography className="color font-26" sx={{ pt: 1, pb: 2 }} font>
                                            No more headache brand new barrels delivered to your door step.
                                        </Typography>
                                    </Box>
                                    </Box>
                                    
                                    <Row>
                                <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='d-flex align-items-center justify-content-center'>
                                    <Box className="w-90" sx={{ mt: 0 }}>
                                        <img src="../assets/img/home-empty.png" alt="list_img" className='home-empty-img' />
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} className={classes.col2}>
                                <Box textAlign="left" className="service_inner" sx={{ pt: 5 }}>
                                        <Box className="empty-barrel-icon">
                                            <div className='icon-header'>
                                                <img src='./assets/img/empty-icon1.svg' alt="icon1"/>
                                                <Typography className='colTitle-1 color2 font-bold'>Brand new fiber Drums</Typography>
                                            </div>
                                            <Typography textAlign="left" className='color2 font-14 empty-barrel-text'>
                                                Brand NEW fiber drums, meticulously crafted to meet industry standards and available in sizes from 20-75 gallons, offering exceptional quality and durability for the safe storage and transport of your valuable cargo.
                                            </Typography>
                                        </Box>
                                        <Box className="empty-barrel-icon">
                                        <div className='icon-header'>
                                                <img src='./assets/img/empty-icon2.svg' alt="icon1"/>
                                                <Typography className='colTitle-1 color2 font-bold'>FREE drum Locks</Typography>
                                            </div>
                                        <Typography textAlign="left" className='color2 font-14 empty-barrel-text' >
                                        We prioritize security with FREE drum locks when shipping with us, each featuring a unique ID, ensuring the safety and integrity of your cargo during transit.
                                        </Typography>
                                        </Box>
                                        <Box className="empty-barrel-icon">
                                        <div className='icon-header'>
                                                <img src='./assets/img/empty-icon3.svg' alt="icon1"/>
                                                <Typography className='colTitle-1 color2 font-bold'>FREE pickups</Typography>
                                            </div>
                                        <Typography textAlign="left" className='color2 font-14 empty-barrel-text'>
                                        Enjoy a stress-free shipping experience with our FREE pickup service for barrels, eliminating the logistical challenge of transporting them to us.
                                           
                                        </Typography>
                                        </Box>
                                        <Box className="empty-barrel-icon">
                                        <div className='icon-header'>
                                                <img src='./assets/img/empty-icon4.svg' alt="icon1"/>
                                                <Typography className='colTitle-1 color2 font-bold'>Bubble Wraps</Typography>
                                            </div>
                                        <Typography textAlign="left" className='color2 font-14 empty-barrel-text'>
                                            Safeguard delicate items during shipping or storage with our cushioning bubble wraps. These wraps effectively absorb and distribute impact forces, minimizing the risk of breakage or cracking.
                                        </Typography>
                                        </Box>

                                        <Link to='/user/buy/buy-product'>
                                            <Button className='homeBtn w-100 buy-empty-btn'>Buy Now</Button>
                                        </Link>
                                    </Box>
                                    </Col> 
                            </Row>
                            </Container> 
                                    {/* <Container maxWidth="xl">
                                        <Box sx={{ pb: 3, pt: 3 }} align="center">
                                        <Link to='/user/buy/buy-product'>
                                            <Button className='homeBtn'>Buy Now</Button>
                                        </Link>
                                    </Box>
                                    </Container> */}
                                    {/* </Grid>
                                    </Grid> */}

                        </Box>
                        <Box className={classes.blockBackground} ref={ref}>
                        <Container maxWidth="xl" id="block4">
                            <Box sx={{ pt: 10, pb: 0 }}>
                                <Box align="center">
                                    <Typography className='font-60 font-bold font-roboto font-60-height'>Shop on Your Favourite Canadian Websites</Typography>
                                    <Typography className='font-22'>IOF is the first to provide you with an innovative way to shop online in Canada. You are now able to shop on your </Typography>
                                    <Typography className='font-22'>Favourite Canadian sites and get it delivered to your island.</Typography>
                                </Box>
                                <Box className="multi_carousel logo_carousel" sx={{ mt: 5, mb: 0 }}>
                                    <Carousel
                                        responsive={responsive}
                                        infinite={true} // Enable infinite loop
                                        autoPlay// Enable auto play
                                        autoPlaySpeed={5000}
                                        afterChange={handleSlideChange}
                                    >
                                         {companyLogo.map((item,index) => (
                                        <div key={item.id} className={`logoImgBox ${index ===  currentSlide ? 'default-hover' : ''}`} >
                                                {/* <h3>{item.title}</h3> */}
                                                <img src={`./assets/img/company_logo/${item.imageUrl}`} alt={item.title} className='slide_image'></img>
                                                {/* Add your item content here */}
                                            </div>
                                        ))}
                                    </Carousel>
                                </Box>
                            </Box>
                        </Container> 
                    
                        <Box > 
                            <Container maxWidth="xl" id="block4">
                            <Box sx={{ pt: 0, pb: 5 }}>
                                <Box align="center" className="shopBgBox">

                                </Box>
                                <Box align="center" sx={{ pt: 10 }}>
                                    <Typography className='font-60 font-bold font-roboto font-60-height'>And we will ship it to your island</Typography>
                                </Box>
                                <Box className="multi_carousel country_carousel" sx={{mt: 10,mb: 10,'@media (max-width: 600px)': {mt: 4,mb: 4},}}>
                                    <Box className="country_box">
                                        {countries.map((item) => (
                                            <Grid className="country_inner_box" key={item.id} align="center">
                                                {/* <h3>{item.title}</h3> */}
                                                <img src={`./assets/img/country_flag/${item.imageUrl}`} alt={item.title} className='country_img'></img>
                                                <Typography align='center' sx={{ pt: 3 }} className='font-22 color2 font-bold'>{item.title}</Typography>
                                                {/* Add your item content here */}
                                            </Grid>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            </Container>
                        </Box>
                        </Box> 
                            <Box className={classes.blockBackground1} ref={ref}>
                            <Box align="center" sx={{pb: 10,'@media (max-width: 600px)': {pb: 5},}} id="block5">
                                {/* <Typography className='block4_title font-60 font-bold font-roboto font-60-height'>How it Works</Typography> */}
                                <Section text="How it works" image="work_icon.png" />
                                <hr className='headerLine'></hr>
                                {/* <Typography sx={{ mt: 1, mb: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography> */}
                                <Box className="block4_box" sx={{ pt: 4 }}>
                                    <Box className="videoContainer">

                                        {showOverlay && (
                                            <Box className="videoOverlay" onClick={handleVideoPlay}>
                                                <PlayCircleRoundedIcon className='playIcon' />
                                            </Box>
                                        )}

                                        <CardMedia
                                            component="video"
                                            src="/assets/video/how_work_new.mp4" // Replace this with your actual video URL
                                            controls // This enables video controls (play, pause, etc.)
                                            title="IOF_Process"
                                            className="videoBox videoBox2"
                                            onPlay={handleVideoPlay}
                                            onContextMenu={(e) => e.preventDefault()}
                                        />                                      {/* <PlayCircleOutlinedIcon className='playIcon'/> */}
                                    </Box>
                                </Box>
                                {/* <Box align="center" sx={{mt: 7}}>
                                    <Link to='/signup' >
                                        <Button className='homeBtn'>Get Started</Button>
                                    </Link>
                                </Box> */}
                            </Box>
                        <Box sx={{ pb: 3 }}>
                            <CMSFooter />
                        </Box>
                        </Box>
                        <Container maxWidth="xl"> 
                            <Box align="center" sx={{mb: 15,'@media (max-width: 600px)': {pt: 4,pb: 4, mb: 6},}}>
                                <Section text="Why IOF" image="work_icon.png" />
                                <hr className='headerLine'></hr>
                                <Typography className="font-22" sx={{ mt: 1, mb: 2 }}>Canada’s preferred barrel shipping provider for islanders</Typography>
                                <Box sx={{ pt: 5 }}>
                                    <Box className="gridRow">
                                        <Box className={classes.grid}>
                                            <Row>
                                                <Col xs={12} sm={12} md={2} lg={1} xl={1}>
                                                    <img src="./assets/img/service_img1.svg" alt="list_img" className='provider_img' />
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={11} xl={11} className={classes.col2}>
                                                    <Typography className='colTitle color4 font-bold'>PRICING</Typography>
                                                    <Typography className='colSubTitle'>Why settle for higher prices when you can save more?</Typography>
                                                    <Typography>We won’t switch up on you! Trust IOF to provide transparent and economical pricing,
                                                        ensuring that your barrel needs are met without breaking the bank.</Typography>
                                                </Col>
                                            </Row>
                                        </Box>

                                        <Box className={classes.grid}>
                                            <Row>
                                                <Col xs={12} sm={12} md={2} lg={1} xl={1}>
                                                    <img src="./assets/img/service_img2.svg" alt="list_img" className='provider_img' />
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={11} xl={11} className={classes.col2}>
                                                    <Typography className='colTitle color4 font-bold'>INNOVATIVE SOLUTIONS</Typography>
                                                    <Typography className='colSubTitle'>You BUY, We Deliver!</Typography>
                                                    <Typography>IOF is the first in Canada to offer barrel delivery services to islanders. We've also introduced,
                                                        the islander box, tailored specifically for you to shop all the deals online in Canada.</Typography>
                                                </Col>
                                            </Row>
                                        </Box>

                                        <Box className={classes.grid}>
                                            <Row>
                                                <Col xs={12} sm={12} md={2} lg={1} xl={1}>
                                                    <img src="./assets/img/service_img3.svg" alt="list_img" className='provider_img' />
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={11} xl={11} className={classes.col2}>
                                                    <Typography className='colTitle color4 font-bold'>CUSTOMER SERVICE</Typography>
                                                    <Typography className='colSubTitle'>Your Satisfaction Is Our Top Priority!</Typography>
                                                    <Typography>At IOF, we put our customers first above all else. There’s simply nothing more frustrating than having to wait days for a response!
                                                        Whether it’s telephone, Live Chat or email we are committed to providing you with prompt support.</Typography>
                                                </Col>
                                            </Row>
                                        </Box>

                                        <Box className={classes.grid}>
                                            <Row>
                                                <Col xs={12} sm={12} md={2} lg={1} xl={1}>
                                                    <img src="./assets/img/service_img4.svg" alt="list_img" className='provider_img' />
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={11} xl={11} className={classes.col2}>
                                                    <Typography className='colTitle color4 font-bold'>USER-FRIENDLY PORTAL</Typography>
                                                    <Typography className='colSubTitle'>Experience simplicity effortlessly.</Typography>
                                                    <Typography>With our easy-to-use website, the entire order process becomes a breeze. From hassle-free registration to seamless order
                                                        pickup and delivery, you can navigate our platform with confidence.</Typography>
                                                </Col>
                                            </Row>
                                        </Box>

                                        <Box className={classes.grid}>
                                            <Row>
                                                <Col xs={12} sm={12} md={2} lg={1} xl={1}>
                                                    <img src="./assets/img/service_img5.svg" alt="list_img" className='provider_img' />
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={11} xl={11} className={classes.col2}>
                                                    <Typography className='colTitle color4 font-bold'>TRANSPARENCY</Typography>
                                                    <Typography className='colSubTitle'>No Secrets, No Lies, On Honesty We Thrive.</Typography>
                                                    <Typography>Our trackable software ensures transparency from Canada to the Caribbean, making your
                                                        shopping experience smoother than ever. IOF is here to fulfill your needs.</Typography>
                                                </Col>
                                            </Row>
                                        </Box>

                                        <Box className={classes.grid}>
                                            <Row>
                                                <Col xs={12} sm={12} md={2} lg={1} xl={1}>
                                                    <img src="./assets/img/service_img6.svg" alt="list_img" className='provider_img' />
                                                </Col>
                                                <Col xs={12} sm={12} md={10} lg={11} xl={11} className={classes.col2}>
                                                    <Typography className='colTitle color4 font-bold'>FAMILY ORIENTED</Typography>
                                                    <Typography className='colSubTitle'>The core of our business!</Typography>
                                                    <Typography>As fellow islanders, we consider ourselves part of your family, committed to helping you support and care for your loved ones.
                                                        You matter to us!</Typography>
                                                </Col>
                                            </Row>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Container> 
                        
                    
                </Box>
            <Footer />
        </>
    )
};


export default Home;