import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    assignedTaskList: [],
    pendingTaskList: [],
    rejectedTaskList: [],
    completedTaskList: [],
    loading: false,
    error: null,
    message: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchAssignedTaskList = createAsyncThunk(
    "driver/assignedTaskList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/driver/assignedOrder", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

    // Async thunk action to fetch customers
    export const fetchPendingTaskList = createAsyncThunk(
      "driver/pendingTaskList",
      async (_, { rejectWithValue }) => {
          try {
              const response = await Request.get("admin/driver/pendingOrderAssignment", [], true);
              if (response.length !== 0) {
                   
                  return response
              } else {
                  console.error("Failed to fetch data from API");
              }
              } catch (error) {
                  return rejectWithValue(error);
              }
      }
    );

        // Async thunk action to fetch customers
        export const fetchCompletedTaskList = createAsyncThunk(
          "driver/completedTaskList",
          async (_, { rejectWithValue }) => {
              try {
                  const response = await Request.get("admin/driver/deliveredOrder", [], true);
                  if (response.length !== 0) {
                       
                      return response
                  } else {
                      console.error("Failed to fetch data from API");
                  }
                  } catch (error) {
                      return rejectWithValue(error);
                  }
          }
        );

        // Async thunk action to fetch customers
        export const fetchRejectedTaskList = createAsyncThunk(
            "driver/rejectedTaskList",
            async (_, { rejectWithValue }) => {
                try {
                    const response = await Request.get("admin/order/cancelledOrder", [{taskList:1}], true);
                    if (response.length !== 0) {
                         
                        return response
                    } else {
                        console.error("Failed to fetch data from API");
                    }
                    } catch (error) {
                        return rejectWithValue(error);
                    }
            }
            );

         // Async thunk action to delete a customer
    export const updateDriverStatus = createAsyncThunk(
      "customer/updateDriverStatus",
      async ({id, isStatus}) => {
          
          try {
              const response = await Request.post(`admin/driver/verify/${id}`, {"isStatus":isStatus}, true);
              if (response && response.message === "Success") {
              
                    return { message: response.message };
                  }
                  else{
                    throw new Error('Update failed or invalid response.');
                  }
            } catch (error) {
              console.error('Error updating row from API:', error);
              throw error; 
            }
      }
    );
  
  // Async thunk action to delete a customer
  export const deleteCustomer = createAsyncThunk(
    "customer/deleteCustomer",
    async (id) => {
        try {
			const response = await Request.delete(`admin/user/${id}`, [], true);
			if(response) {
				if (response.status === 204) {
					// Update the state to remove the deleted user
					return id
				  }
				}
		  } catch (error) {
			console.error('Error deleting row from API:', error);
		  }
    }
  );
  

  const taskManagementSlice = createSlice({
    name: "taskManagement",
    initialState, 
    loading: false,
    error: null,
    message: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchAssignedTaskList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchAssignedTaskList.fulfilled, (state, action) => {
            state.loading = false;
            state.assignedTaskList = action.payload;
          })
          .addCase(fetchAssignedTaskList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchPendingTaskList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPendingTaskList.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingTaskList = action.payload;
          })
          .addCase(fetchPendingTaskList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchCompletedTaskList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchCompletedTaskList.fulfilled, (state, action) => {
            state.loading = false;
            state.completedTaskList = action.payload;
          })
          .addCase(fetchCompletedTaskList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchRejectedTaskList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchRejectedTaskList.fulfilled, (state, action) => {
            state.loading = false;
            state.rejectedTaskList = action.payload;
          })
          .addCase(fetchRejectedTaskList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(deleteCustomer.pending, (state) => {
            state.loading = true;
          })
          // .addCase(deleteCustomer.fulfilled, (state, action) => {
          //   state.loading = false;
          //   const deletedCustomerId = action.payload;
          //   state.customers = state.customers.filter(
          //     (customer) => customer.id !== deletedCustomerId
          //   );
          // })
          // .addCase(deleteCustomer.rejected, (state, action) => {
          //   state.loading = false;
          //   state.error = action.payload;
          // })
          .addCase(updateDriverStatus.pending, (state) => {
            state.loading = true;
          })
          .addCase(updateDriverStatus.fulfilled, (state, action) => {
              
            state.loading = false;
            state.message = action.payload.message;
          })
          .addCase(updateDriverStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
      },
  });
  
  export default taskManagementSlice.reducer;