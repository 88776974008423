import { useEffect, useState  } from 'react';
import { Routes, Route, Switch, Outlet } from 'react-router-dom';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import Cookies from 'universal-cookie';
import { checkLogin, checkUserType } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import Support from '../commonFunctions/support';
import { connect } from 'react-redux';
import SendProduct from '../product/sendProduct';
import SendCheckout from '../checkout/sendCheckout';
import SendPayment from '../checkout/sendPayment';
import SendOrderDetail from '../checkout/sendOrderDetail';
import Login from '../user/login/login';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import PageNotFound from './pageNotFound';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginLeft: 'auto',
    boxSizing: 'border-box',
    marginRight: 'auto',
    display: 'block',
    padding: '16px', // Default padding for larger screens
    '@media (max-width: 600px)': {
        paddingLeft: '5px !important',
        paddingRight: '5px !important'
  },
  },
}));

function SendBoxStructure() {
  const classes = useStyles();
  let login = checkLogin();
  let userType = checkUserType();
  // const [userType, setUserType] = useState(null);
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }

  return (
    <>
     {(login === true && userType && userType === 3) ? ( 
        <Box className="main">
            <Sidebar />
            <Box className={`orderContainer ${minimized ? 'dashContainer' : 'containerExpand'}`}>
                <DashboardHeader />
                <Container maxWidth="l" className={classes.container}>
                  <Outlet />
                    <Routes>
                        <Route path="/*" element={<PageNotFound />} />
                        <Route path="/send-product" element={<SendProduct />}></Route>
                        <Route path="/checkout" element={<SendCheckout />}></Route>
                        <Route path='/send-payment' element={<SendPayment />}></Route>
                        <Route path='/send-order-detail' element={<SendOrderDetail />}></Route>
                    </Routes>
                </Container>
            </Box>
            <Support />
        </Box>
     ) : (
        <Login />
     )}
    </>
  )
}


export default SendBoxStructure;

