// StarRating.js
import React, { useState } from 'react';
import Rating from '@mui/material/Rating';

const StarRating = ({ value, onChange }) => {
  return (
    <div className="star-rating">
      <Rating
        name="simple-controlled"
        value={value}
        onChange={onChange}
        precision={1}
        size="large"
      />
    </div>
  );
};

export default StarRating;