import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    helpCategoryList: [],
    popularQuestionList: [],
    questionList:[],
    vacancyList:[],
    loading: false,
    error: null,
  };
  
  export const fetchHelpCategories = createAsyncThunk(
    "customer/helpCategoryList",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("utils/helpCentre/category/", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
              console.log(error)
                return rejectWithValue(error);
            }
    }
  );

  export const fetchQuestion = createAsyncThunk(
    "customer/questionList",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("utils/helpCentre", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  export const fetchPopularQuestion = createAsyncThunk(
    "customer/popularQuestionList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("utils/helpCentre/questions/popular", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  export const fetchVacancyList = createAsyncThunk(
    "customer/vacancyList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/careers", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  

  const helpCenterSlice = createSlice({
    name: "helpCenter",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchHelpCategories.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchHelpCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.helpCategoryList = action.payload;
          })
          .addCase(fetchHelpCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchQuestion.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchQuestion.fulfilled, (state, action) => {
            state.loading = false;
            state.questionList = action.payload;
          })
          .addCase(fetchQuestion.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchPopularQuestion.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPopularQuestion.fulfilled, (state, action) => {
            state.loading = false;
            state.popularQuestionList = action.payload;
          })
          .addCase(fetchPopularQuestion.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchVacancyList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchVacancyList.fulfilled, (state, action) => {
            state.loading = false;
            state.vacancyList = action.payload;
          })
          .addCase(fetchVacancyList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          // .addCase(deleteCustomer.pending, (state) => {
          //   state.loading = true;
          // })
          // .addCase(deleteCustomer.fulfilled, (state, action) => {
          //   state.loading = false;
          //   const deletedCustomerId = action.payload;
          //   state.customers = state.customers.filter(
          //     (customer) => customer.id !== deletedCustomerId
          //   );
          // })
          // .addCase(deleteCustomer.rejected, (state, action) => {
          //   state.loading = false;
          //   state.error = action.payload;
          // })
          // .addCase(updateCustomer.pending, (state) => {
          //   state.loading = true;
          // })
          // .addCase(updateCustomer.fulfilled, (state, action) => {
          //   state.loading = false;
          //   // Update the customer data in the state (if needed)
          //   const updatedCustomerData = action.payload;
          //   state.customers = state.customers.map((customer) =>
          //     customer.id === updatedCustomerData.id
          //       ? updatedCustomerData
          //       : customer
          //   );
          // })
          // .addCase(updateCustomer.rejected, (state, action) => {
          //   state.loading = false;
          //   state.error = action.payload;
          // });
      },
  });
  
  export default helpCenterSlice.reducer;