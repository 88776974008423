import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import CMSFooter from '../../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const ShipIslanderBox = () => {
    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Ship An Islander Box</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5, pb:5 }}>

                            <Row>

                                 <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} >
                                    <Box textAlign="left" className="service_inner" sx={{ pt: 1 }}>
                                        <Typography textAlign="left" className='color2 font-19'>
                                            Shopping online has never been easier! Isle of Freight offers a comprehensive packing 
                                            and shipping service for your convenience. Once you have completed shopping online and 
                                            your items have been received at our warehouse , we meticulously pack and ship your box,
                                            ensuring that they are protected during transit.In the event that your box is not 
                                            completely filled you are notified and given the option to purchase additional items 
                                            from IOF’s online store.</Typography> 
                                            
                                            <Typography textAlign="left" className='color2 font-19' sx={{ pt: 3 }}>We use high-quality materials for our boxes which allows your
                                            items to arrive at their destination in the same condition as when they left our hands.
                                            Our shipping service is reliable and efficient, ensuring that your package arrives at 
                                            its destination on time. We take pride in our attention to detail and our commitment to
                                            customer satisfaction. Trust us to pack and ship your box with the utmost care and 
                                            professionalism. 
                                        </Typography>
                                    </Box>
                                </Col>
                                 <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='barrel-img-box'>
                                    <Box className="w-80" sx={{ mt: -5 }}>
                                        <img src="../assets/img/truckgre.png" alt="list_img" className='' />
                                    </Box>
                                </Col>
                            </Row>

                           
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default ShipIslanderBox;
