import { useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box } from '@mui/material';

function Order() {

    const [activeStep, setActiveStep] = useState(0);

    return (
        <>
            <Header />
                <Container maxWidth="xl">
                    
                </Container>
            <Footer />
        </>
    )
}

export default Order;