import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useHistory  } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Dialog, DialogContent } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { checkLogin } from '../commonFunctions/commonFunctions';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { makeStyles } from '@material-ui/styles';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';
// import { addToCart, removeFromCart } from '../commonFunctions/actions'; 
import Cookies from 'universal-cookie';
import { setCart, fetchCartItems, removeItemAsync, incQuantityAsync, decQuantityAsync } from '../redux/cartSlice'
import { FormatPrice } from '../commonFunctions/commonPriceFunction';


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    productAdd: {
        backgroundColor: '#EBF7F1 !important',
        borderTopRightRadius: '5px !important',
        borderBottomRightRadius: '5px !important',

    },
    productRemove: {
        backgroundColor: '#FAFAFA !important',
        borderTopLeftRadius: '5px !important',
        borderBottomLeftRadius: '5px !important',

    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    infoText: {
        position: 'absolute',
        top: '-90px',
        left: '0%',
        transform: 'translate(30%, 0px)',
        padding: '10px',
        backgroundColor: '#fff',
        color: '#727272',
        borderRadius: '4px',
        fontSize: '14px !important',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        zIndex: '9',
        width: "200px",
        boxShadow: "10px 24px 54px rgba(0, 0, 0, .2)",
        lineHeight: "20px !important"
    },
    icon: {
        color: '#727272 !important',
        fontSize: '20px !important',
        cursor: 'pointer',
        '&:hover': {
            '& ~ $infoText': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    triangle: {
        content: '""',
        position: 'absolute',
        bottom: '-15px',
        left: '18%',
        transform: 'translateX(-50%) rotate(0deg)',
        borderTop: '16px solid #fff',
        borderLeft: '0px solid transparent',
        borderRight: '20px solid transparent',
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
    cartPaymentBottom: {
        position: "absolute",
        bottom: "0px",
        width: "100%",
        padding: "32px",
        marginTop: "100px"
    },
    
}));


function Cart() {
    const classes = useStyles();
    // const history = useHistory();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    let [num, setNum] = useState(0);
    const [cartInfo, setCartInfo] = useState([]);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [editBtnDisable, setEditBtnDisable] = useState(true)
    const [updateCartInfo, setUpdateCartInfo] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [maxText, setMaxText] = useState(0);

    const cart = useSelector((state) => state.cart);
    const totalCartPrice = useSelector((state) => state.cart.totalCartPrice);

    let login = checkLogin()

    // useEffect(() => {
    //     // Check the cart status (whether it's empty)
    //     const cartIsEmpty = cart.length === 0;
        
    //     if (cartIsEmpty) {
    //       // Redirect to the shop page or any other page
    //       navigate('/buy-product');
    //     }
    //   }, [navigate]);

    //redux data code
    useEffect(() => {
        // Fetch cart items from the API for logged-in users
        if (login) {
            dispatch(fetchCartItems()); 
        }
    }, [dispatch, login]);

    useEffect(() => {
        // Fetch cart items from cookies for non-logged-in users
        if (!login) {
            const cartItemsFromCookies = cookies.get('cartItems', { path: '/' });
            if (cartItemsFromCookies) {
                dispatch(setCart(cartItemsFromCookies));
            }
        }
    }, [dispatch, login, ]);


    const handleRemoveItem = (cartItemId) => {
          
        dispatch(removeItemAsync(cartItemId));
    };

    const handleContactPage = () => {
        window.location.href = '/contact-us'
    };

    const incQuantity = (cartItemId, quantity, maxQuantity) => {
    if(maxQuantity > 10){
        var maxLimitNew = 10;
        }else{
        var maxLimitNew = maxQuantity;
        }
        if (quantity < maxLimitNew) {
            dispatch(incQuantityAsync(cartItemId));
        }else {
            // Show an error dialog when the quantity exceeds the maxQuantity
            setDialogOpen(true);
            setMaxText(maxLimitNew); // Set maxText to the maxQuantity value
          }
    };

    const decQuantity = (cartItemId, quantity, maxQuantity) => {
          
        if (quantity > 1) {
            dispatch(decQuantityAsync(cartItemId));
        }
    };
    //redux data code end

    useEffect(() => {
        // Recalculate the total price of all items in the cart whenever cartInfo changes
        const newTotalPrice = cartInfo.reduce((total, item) => total + item.totalPrice, 0);
        setCartTotalPrice(newTotalPrice);
    }, [cartInfo]);

    const handleCloseDialog = () => {
        setDialogOpen(false);
      };

    let deleteCartItem = async (id) => {
        if (login === true) {
            let response = await Request.delete(`cart/${id}`, {}, true)
            {
                if (response.status === 204) {
                    setCartInfo((prevCartInfo) => {
                        return prevCartInfo.filter(item => item.cartItemId !== id);
                    });
                }
                else {
                    console.log("Something Went wrong when delete item.");
                }
            }
            if (!response) {
                console.log('something went wrong');
            }
        }
        else {
            const aa = await cookies.get('cartItems', { path: "/" });
            removeRowFromCookies(id);
        }
    }

    const removeRowFromCookies = (id) => {

        // Get the current cookie data
        const cookieData = cookies.get('cartItems', { path: "/" });

        if (Array.isArray(cookieData.cartItems)) {
            // Filter out the row with the given ID
            const updatedData = cookieData.cartItems.filter((row) => row.cartItemId !== id);

            var items = { "cartItems": updatedData }
            cookies.set('cartItems', items, { path: "/", secure: true });
            const cartItems = cookies.get('cartItems', { path: "/" });

            setCartInfo(cartItems.cartItems);
            const countList = cartItems.cartItems;
            var confirmPrice = 0;
            for (var i = 0; i < countList.length; i++) {
                // let totalQuantity = countList[i].quantity;
                let totalPrice = countList[i].totalPrice;
                confirmPrice += totalPrice;
            }

            cookies.set('totalCartPrice', confirmPrice, { path: "/" , secure: true});
            setCartTotalPrice(confirmPrice);
            // Update the cookie with the new data
            // cookies.set('cartItems', updatedData);
        }
    };

    const updateCartItems = async (items) => {
        try {
            const response = await Request.put("cart/update", items, true);
            if (response.status === 200) {
                setUpdateCartInfo((current) => !current)
                // API call was successful, update the state accordingly
                setEditBtnDisable(true);
                fetchData();
            } else {
                console.log("Something Went wrong when updating item.");
            }
        } catch (error) {
            console.log('Error updating cart items:', error);
        }
    };

    let updateCartItem = async () => {
          
        var input = document.getElementsByClassName('cartEditInput');
        var price = document.getElementsByClassName('priceText');
        var size = document.getElementsByClassName('barrelSize');
        const cartItems = [];
        const images = [];

        if (login === true) {
            for (var i = 0; i < input.length; i++) {
                const item = {
                    cartItemId: input[i].id,
                    quantity: input[i].value,
                };
                if (item.quantity > 0) {
                    cartItems.push(item);
                }

            }
            var items = { "cartItems": cartItems }
            await updateCartItems(items);

        }
        else {
            for (var i = 0; i < input.length; i++) {
                const item = {
                    cartItemId: input[i].id,
                    quantity: parseInt(input[i].value),
                    'barrel': {
                        price: parseFloat(price[i].textContent.replace("$", "")).toFixed(2),
                        size: size[i].textContent
                     
                    },
                    totalPrice: parseFloat(price[i].textContent.replace("$", "")) * parseInt(input[i].value).toFixed(2)
                };


                if (item.quantity > 0) {
                    cartItems.push(item);
                }
            }

            var items = { "cartItems": cartItems }
            cookies.set('cartItems', items, { path: "/", secure: true });
            const aa = cookies.get('cartItems', { path: "/" });
            setCartInfo(aa.cartItems);
            const countList = aa.cartItems;
            var confirmPrice = 0;
            for (var i = 0; i < countList.length; i++) {
                // let totalQuantity = countList[i].quantity;
                let totalPrice = countList[i].totalPrice;
                confirmPrice += totalPrice;
            }

            cookies.set('totalCartPrice', confirmPrice, { path: "/", secure: true });
            setCartTotalPrice(confirmPrice);
            setEditBtnDisable(true)
        }
    }

    let handleInput = (e) => {
        setNum(e.target.value);
    }

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        if (login === true) {
            let data = await Request.get("cart", [], true);
            {
                setCartInfo(data.cartItems);
                setCartTotalPrice(data.totalCartPrice);
            }
        }
        else {
            const cartItems = cookies.get('cartItems', { path: "/" });
            setCartInfo(cartItems.cartItems);
            var countList = cartItems.cartItems;
            var confirmPrice = 0;
            for (var i = 0; i < countList.length; i++) {
                // let totalQuantity = countList[i].quantity;
                let totalPrice = countList[i].totalPrice;
                confirmPrice += totalPrice;
            }

            cookies.set('totalCartPrice', confirmPrice, { path: "/", secure: true });
            setCartTotalPrice(confirmPrice);
        }
    }

    // const handleAddToCart = (item) => {
    //     dispatch(addToCart(item));
    //   };

    //   const handleRemoveFromCart = (itemId) => {
    //     dispatch(removeFromCart(itemId));
    //   };

    return (
        <>
            <Box className='orderContainer orderBoxContainer'>
                <Box>
                    <Typography variant='h5' className='color1 font-bold'>Cart</Typography>
                    <Box className={classes.headerFlexBox}>
                        <Link to='/user/buy/buy-product'>
                            <Typography className={classes.pathName}>Shop</Typography>
                        </Link>
                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                        <Typography className={`${classes.pathName} ${classes.activePath}`}>Cart</Typography>
                    </Box>
                </Box>
                <Box className='cartMainContainer'>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className=''>
                            <Grid item xs={12} sm={12} md={12} lg={11.7} xl={11.7} className='cartTable col-left-padding cartColLeft column buyCartTotal'>
                                <TableContainer component={Paper} >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left">Product</TableCell>
                                                <TableCell align="left">Price</TableCell>
                                                <TableCell align="left">Qty</TableCell>
                                                <TableCell align="left">size</TableCell>
                                                <TableCell align="left">Subtotal</TableCell>
                                                <TableCell align="left"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cart && cart.cartItems.length > 0 ? (
                                                <>
                                                    {cart.cartItems.map((product, index) =>

                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell scope="row">
                                                                <Box sx={{ width: "70px", height: "70px", position: "relative" }} className="product_img_box">
                                                                    {product.barrel.images && product.barrel.images.length >= 1 ? (
                                                                        // <img src={`${process.env.REACT_APP_DatabaseStatic}${product.barrel.images[0].img}`} className="product_cart_img" alt="product_img" />
                                                                       <>
                                                                        <div
                                                                        className="product_cart_img"
                                                                        style={{
                                                                          backgroundImage: `url("${process.env.REACT_APP_DatabaseStatic}${product.barrel.images[0].img.replace(/\\/g, '/').replace(/ /g, '%20')}")`,
                                                                          backgroundRepeat: "no-repeat",
                                                                          backgroundSize: "cover",
                                                                          backgroundPosition: "center",
                                                                        }}
                                                                      />
                                                                      <div className="aspect-ratio"></div>
                                                                      </>
                                                                    ) : (
                                                                        <>
                                                                        <div
                                                                        className="product_cart_img"
                                                                        style={{ backgroundImage: 'url("/assets/img/no_image_item.png")',
                                                                        backgroundRepeat: "no-repeat",
                                                                        backgroundSize: "cover",
                                                                        backgroundPosition: "center", }}
                                                                      />
                                                                      <div className="aspect-ratio"></div>
                                                                      </>
                                                                        // <img src="/assets/img/no_image_item.png" className="product_cart_img" alt="product_img" />
                                                                    )}
                                                                </Box>
                                                                {/* <img src="/assets/img/product_img.png" style={{ "objectFit": "contain", "width": "60px", "height": "40px" }}></img> */}
                                                            </TableCell>
                                                            <TableCell align="left">{product.barrel.name}</TableCell>
                                                            <TableCell align="left" className="color2 priceText">${FormatPrice(product.barrel.price)}</TableCell>
                                                            <TableCell align="left" sx={{ display: "flex" }}>
                                                                <Box className="quantityBox cartQuantityBox">
                                                                    {product.barrel.stockManagement === false ? (
                                                                        <>
                                                                            <Box disabled={product.quantity <= 1} onClick={() => decQuantity(product.cartItemId, product.quantity, 10)} min="1" >
                                                                                <Button className={classes.productRemove} disabled={product.quantity <= 1}>-</Button>
                                                                            </Box>
                                                                            <input type="number" className="cartEditInput" id={product.cartItemId} value={product.quantity} readOnly min="0" max="10"></input>
                                                                            <Box onClick={() => incQuantity(product.cartItemId, product.quantity, 10)}>
                                                                                <Button className={classes.productAdd}>+</Button>
                                                                            </Box>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            <Box disabled={product.quantity <= 1} onClick={() => decQuantity(product.cartItemId, product.quantity, 10)} min="1" >
                                                                                <Button className={classes.productRemove} disabled={product.quantity <= 1}>-</Button>
                                                                            </Box>
                                                                            <input type="number" className="cartEditInput" id={product.cartItemId} value={product.quantity} readOnly min="0" max="10"></input>
                                                                            <Box onClick={() => incQuantity(product.cartItemId, product.quantity, product.barrel.stock)}>
                                                                                <Button className={classes.productAdd}>+</Button>
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="left" className='barrelSize'>{product.barrel.size}</TableCell>
                                                            <TableCell align="left">${FormatPrice(product.totalPrice)}</TableCell>
                                                            <TableCell align="left">
                                                                <CloseIcon id={product.cartItemId} sx={{ cursor: "pointer" }} onClick={() => handleRemoveItem(product.cartItemId)} className='color2'></CloseIcon>
                                                            </TableCell>
                                                        </TableRow>

                                                    )}
                                                </>
                                            ) : (
                                                <TableRow align="center">
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>
                                                        <Typography className='color2 font-20' align="center">Cart is Empty</Typography>
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.gridBox}>
                            <Box className="col-right-padding cartColRight column cartGridColumn cartGridColumnRight">
                                <Box className='cartTable cartTotalTable '>
                                    <Box sx={{ p: 2 }} className={classes.totalHeader} align="center">
                                        <Typography className='font-20 color2 font-bold'>Cart Totals</Typography>
                                    </Box>
                                    <Box sx={{ p: 4, pt: 4, pb: 0 }}>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography className='font-15'>SubTotal</Typography>
                                                </Grid>
                                                <Grid item xs={6} align="right">
                                                    <Typography className='font-15 color3'>${FormatPrice(totalCartPrice)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box className={classes.cartPaymentBottom}>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography className='font-16 font-bold color2'>Total</Typography>
                                            </Grid>
                                            <Grid item xs={6} align="right">
                                                <Typography className='font-16 font-bold color2'>${FormatPrice(totalCartPrice)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <Link to="/user/buy/checkout">
                                            <Button sx={{ mb: 0, width: "100%" }} variant="contained" className='b1'>Proceed to checkout</Button>
                                        </Link>
                                        <Link to="/user/buy/buy-product"><Typography sx={{ mt: 1, textDecoration: "underline" }} className='font-13 color2' align="center">Continue Shopping</Typography></Link>
                                    </Box>
                                    <Box>
                                        <Typography className='font-16 font-bold color2'>We Accept</Typography>
                                        <Box className="flexBox cardFlexBox">
                                            <img src="/assets/img/payment_logo/payment_method1.svg" className="" alt="payment_method" />
                                            <img src="/assets/img/payment_logo/payment_method2.svg" className="" alt="payment_method" />
                                            <img src="/assets/img/payment_logo/payment_method3.svg" className="" alt="payment_method" />
                                            <img src="/assets/img/payment_logo/payment_method4.svg" className="" alt="payment_method" />
                                            <img src="/assets/img/payment_logo/payment_method5.svg" className="" alt="payment_method" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* Material UI Dialog for showing the error */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogContent>
            The quantity cannot exceed {maxText}
            </DialogContent>
            <Button onClick={handleCloseDialog}>OK</Button>
            <Button onClick={handleContactPage}>Contact Us For Bulk Order</Button>
        </Dialog>

        </>
    )
}

export default Cart;