import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TableSortLabel,
} from "@mui/material";
import CustomerForm from "./customerForm";
import PendingOrdersTable from "./PendingOrdersTable";
import LoginDetailsForm from "./LoginDetailsForm";
import CustomersOrderTable from "./CustomersOrderTable";

const FilterDropdownList = () => {
	const [selectedOption, setSelectedOption] = useState("");

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	return (
		<select value={selectedOption} onChange={handleOptionChange}>
			<option value="">Select an option</option>
			<option value="option1">Option 1</option>
			<option value="option2">Option 2</option>
			<option value="option3">Option 3</option>
		</select>
	);
};

function Customers() {
	return (
		<>
			<Box className="adminInnerPage customerpage">
				<Typography variant="h2" className="adminInnerPage__title">
					Customers
				</Typography>

				<div className="adminInnerPage__tabs-content">
					<div className="order-search-filter">
						<div className="order-search-filter__search">
							{/* <input
								type="text"
								placeholder="Search for Order ID, Customer, ISL#"
							/> */}
						</div>
						<div className="order-search-filter__filter">
							{/* <div className="order-search-filter__filter-icon">
								<FilterAltOutlinedIcon />
							</div>
							<div className="order-search-filter__filter-dropdown">
								<FilterDropdownList />
							</div> */}
						</div>
					</div>
					
					<CustomersOrderTable />
				</div>
			</Box>
		</>
	);
}

export default Customers;
