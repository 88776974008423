import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Dialog,
	DialogTitle,
	DialogContent,
	DialogActions, } from "@mui/material";
import Form from "react-bootstrap/Form";
import Request from '../customUtils/Http/Request';
import StripApiKeyForm from "./stripApiKeyForm";

function PaymentStripe() {
	const [paymentmode, setPaymentmode] = useState("test");
	const [publishableKey, setPublishableKey] = useState("");
	const [secretKey, setSecretKey] = useState("");
	const [keyId, setKeyId] = useState("");
	const [signatureKey, setSignatureKey] = useState("");
	const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	const handlePaymentmodeChange = (event) => {
		setPaymentmode(event.target.value);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async (e) => {
		try {
			const response = await Request.get(`admin/stripeKey/active`, [], true);
			if (response.length !== 0) {
					setSignatureKey(response.stripeSignature)
					setPublishableKey(response.stripePublishableKey)
					setSecretKey(response.stripeSecretKey)
					setKeyId(response.id)
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    	setConfirmationDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setConfirmationDialogOpen(false);
	  };

	const handleConfirm = async () => {
		setConfirmationDialogOpen(false);
		const data = {
			"stripePublishableKey": publishableKey,
			"stripeSecretKey": secretKey,
			"stripeSignature": signatureKey
		}
		try {
			const response = await Request.put(`admin/stripeKeys/${keyId}`, data, true);
			if (response.status === 200) {
			}else{
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	  };

	return (
		<Box className="adminInnerPage paymentPage">
			<form onSubmit={handleSubmit}>
				<div className="title-button__container">
					<Typography variant="h2" className="adminInnerPage__title">
						Stripe Gateway
					</Typography>

					<Button
						variant="primary"
						color="primary"
						className="btn btn-secondary"
						type="submit"
					>
						Save Settings
					</Button>
				</div>

				{/* <Form.Group
					controlId="stripePaymentMode"
					className="paymentPage__mode"
				>
					<Form.Label>Strip Payment Mode</Form.Label>
					<Form.Check
						type="radio"
						label="Test Mode"
						name="paymentmode"
						value="test"
						checked={paymentmode === "test"}
						onChange={handlePaymentmodeChange}
					/>
					<Form.Check
						type="radio"
						label="Live"
						name="paymentmode"
						value="live"
						checked={paymentmode === "live"}
						onChange={handlePaymentmodeChange}
					/>
				</Form.Group> */}

				<div className="paymentPage__keys">
					<Typography variant="h6" className="section-title">
						Stripe Keys
					</Typography>

					{/* <StripApiKeyForm /> */}
					<Box className="paymentKeysForm">
						<Form.Group controlId="publishableKey" className="paymentKeysForm__field">
							<Form.Label>Publishable Key</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Publishable Key"
								value={publishableKey}
								onChange={(e) => setPublishableKey(e.target.value)}
								required
							/>
						</Form.Group>

						<Form.Group controlId="secretKey" className="paymentKeysForm__field">
							<Form.Label>Secret Key</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Secret Key"
								value={secretKey}
								onChange={(e) => setSecretKey(e.target.value)}
								required
							/>
						</Form.Group>

						<Form.Group controlId="signature" className="paymentKeysForm__field">
							<Form.Label>Signature</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Signature"
								value={signatureKey}
								onChange={(e) => setSignatureKey(e.target.value)}
								required
							/>
						</Form.Group>
					</Box>
					
				</div>
			</form>

				<Dialog open={isConfirmationDialogOpen} onClose={handleCloseDialog}>
					{/* <DialogTitle>Confirm Submission</DialogTitle> */}
					<DialogContent>
						Are you sure you want to edit settings?
					</DialogContent>
					<DialogActions>
					<Button onClick={handleCloseDialog} color="primary">
						Cancel
					</Button>
					<Button onClick={handleConfirm} color="primary">
						Confirm
					</Button>
					</DialogActions>
				</Dialog>
		</Box>
	);
}

export default PaymentStripe;
