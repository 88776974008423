import { useEffect, useState, useLocation } from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/system';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Radio, RadioGroup, FormControlLabel, TableCell, TableRow } from '@mui/material';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { checkLogin } from '../commonFunctions/commonFunctions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './paymentForm';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import Login from '../user/login/login';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import PaymentAddress from '../commonFunctions/paymentAddress';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
import { FetchStripePublicKeyFromAPI } from '../commonFunctions/loadStripe';

// const stripePromise = loadStripe('pk_test_51NIltgSHZUnKCsvrZGDFY49bs9HsyUBNiJ6nr1pIbWxlpweEHBdvSnoja71H1Yh1GFSjVQzvMr3Q4gFl0SfxVg2I00tTeDJSHL');


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px",
        flexWrap: "wrap",
        marginTop: "20px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
    formPadding: {
        marginTop: "12px"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}));

function SendPayment() {
    const classes = useStyles();
    const [stripeKey, setStripeKey] = useState(null);
    // const [selectedValue, setSelectedValue] = useState('a');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState('Stripe');
    const [orderID, setOrderID] = useState(null);
    const [options, setOptions] = useState({
        clientSecret: null
    });
    const [orderIdElements, setOrderIdElement] = useState({
        clientSecret: null
    });
    const [cartInfo, setCartInfo] = useState([]);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [taxValue, setTaxValue] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [type,setType] = useState('send');
    const [address1, setAddress1] = useState({});
    const [address2, setAddress2] = useState({});
    const [address3, setAddress3] = useState({});
    const [address4, setAddress4] = useState({});
    const [address5, setAddress5] = useState({});
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(false);


    const [orderType, setOrderType] = useState(null);

    let login = checkLogin()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderId = urlParams.get('orderId');
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);

    // // Get the value of a specific parameter
    // const orderId = searchParams.get('orderId');
    // const accessToken = searchParams.get('accessToken');
    useEffect(() => {
        const initializeStripe = async () => {
          try {
            const stripePublicKey = await FetchStripePublicKeyFromAPI();
            const stripeInstance = await loadStripe(stripePublicKey);
            setStripeKey(stripeInstance);
          } catch (error) {
            console.error('Error loading Stripe:', error);
          }
        };
    
        initializeStripe();
      }, []);

      useEffect(() => {
        getPaypalStatus()
      }, []);


      const getPaypalStatus = async () => {
        try {
            // Fetch data from API
            const data = await Request.get(`utils/checkPaypalStatus`, []);
            if (Object.keys(data).length !== 0){
               if(data.paypalStatus){
                    if(data.paypalStatus === true){
                        setPaymentStatus(true)
                    }else{
                        setPaymentStatus(false)
                    }
                }
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    useEffect(() => {
        (async () => {
            // if (login === true) {
                try {
                    const data = await Request.get(`order/order_detail/${orderId}`, []);
                    if (data) {
                        setCartInfo(data.products);
                        setCartTotalPrice(data.totalPrice);
                        setAddress1(data.shippingAddress);
                        setAddress2(data.billingAddress);
                        setAddress4(data.senderAddress);
                        setAddress5(data.pickupAddress);
                        setAddress3(data.user);
                        setDeliveryCharge(data.shippingCharge);
                        setTaxValue(data.tax);
                        setTaxPercentage(data.taxPercentage);
                        setInsurance(data.insuranceValue)
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error fetching data from API:', error);
                }
            // }
            // else {
            //     const cartItems = cookies.get('cartItems', { path: "/" });
            //     const cartPrice = cookies.get('totalCartPrice', { path: "/" });
            //     const totalCartPrice = parseInt(cartPrice) + 20;
            //     setCartInfo(cartItems.cartItems);
            //     setCartTotalPrice(totalCartPrice);
            // }
        })();
    }, []);

    useEffect(() => {
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const orderId = urlParams.get('orderId');

        if(orderId){
            setOrderID(orderId)
            // const accessToken = urlParams.get('accessToken');
            // const orderId = parameterList.get(orderId);
            // const accessToken = parameterList.get(accessToken);
            const accessToken = cookies.get('accessToken', {path: "/"});

            fetchClientSecret();

            async function fetchClientSecret() {

            // let response = await Request.get("cart", [], true);
            let response = await Request.get("checkout/stripePaymentIntent", [{ orderId }]);
            const orderIdElements = [];

            setOrderIdElement({ orderId: response.orderId })
            setOrderType(response.client_secret.metadata.orderType)
            setOptions({
                clientSecret: response.client_secret.client_secret
            })
            setIsLoading(false);
        }
        }else{
            
        }


    }, []);

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
        // setSelectedValue(event.target.value);
    };

    const confirmPayment = async () => {
        var order_id = orderID;
        let response = await Request.post(`checkout/cashCheckout?orderId=${order_id}`, {}, true);
        if (response) {
            window.location.href = `https://phpstack-1065403-3747209.cloudwaysapps.com/user/send/send-order-detail?orderId=${order_id}`
        }
        // const orderIdElements = [];

        // console.log(response,"response")

        // setOrderIdElement({orderId : response.orderId})
        // setOptions({
        //     clientSecret : response.client_secret.client_secret
        // })
    }

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
          }
    }, [redirectUrl]);

    const paymentPaypal = async () => {
        setIsLoading2(true);
        try {
            let response = await Request.get("checkout/paypalPaymentIntent", [{ orderId }]);
            if (response && response.redirect_url) {
                setRedirectUrl(response.redirect_url)
            }
          } catch (error) {
            // Handle errors here
            console.error("Error fetching data:", error);
          } finally {
            setIsLoading2(false);
          }
    }

    const StripePaymentBox = () => {
        return (
            <>
                {isLoading ? (
                    <div className="spinner-container">
                        <div className="loading-spinner">
                        </div>
                    </div>
                ) : (

                    <Elements stripe={stripeKey} options={options} className="">
                        <PaymentForm setOrderIdElement={orderIdElements} orderType={orderType} />
                    </Elements>
                )}
            </>
        );
    };

    const PayPalPaymentBox = () => {
        return (
            // <Box className="paypalInnerBox"></Box>
            <Box>
                {isLoading2 ? (
                   <div className="loader2-container4">
                        <div className="loader2"></div>
                    </div>
                ) : (
                    <Box sx={{pt: 4}}>
                        <Button className='b1 b4' onClick={paymentPaypal}>Pay Now</Button>
                    </Box>
                )} 
            </Box>
        );
    };

    const OtherPaymentBox = () => {
        return (
            <Box align="right">
                <Button className='b1' onClick={confirmPayment}>Confirm Order</Button>
            </Box>
        );
    };

    return (

        <Box className={classes.dashboardInner}>
            <Grid container>
                <Grid item xs={12} sm={12} md={16} lg={8} xl={6}>
                    <Typography variant='h5' className='color1 font-bold'>Payment</Typography>
                    <Box className={classes.headerFlexBox}>
                        <Typography className={classes.pathName}>Empty Barrel</Typography>
                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                        <Typography className={classes.pathName}>Checkout</Typography>
                        <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                        <Typography className={`${classes.pathName} ${classes.activePath}`}>Payment</Typography>
                    </Box>
                </Grid>
                {/* <Grid xs={12} sm={12} md={16} lg={8} xl={6} align="right">
                     <Button className='b1 b4'>Edit Order Details</Button> 
                </Grid> */}
            </Grid>
            <Box className='cartMainContainer'>
                <Grid container className='paymentBox'>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='cartColLeft paymentCol paymentMainBox'>
                        <Grid item xs={12} sm={12} md={12} lg={11.7} xl={11.7} className='cartColLeft paymentCol'>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="Stripe"
                                // name="radio-buttons-group"
                                name="payment" value={selectedPayment} onChange={handlePaymentChange}

                            >
                                <Box className={selectedPayment === 'Stripe' ? "paymentOption active" : "paymentOption"}>
                                    <Box className="paymentLogoBox">
                                        <img src="/assets/img/stripe.png" alt='Stripe' className='paymentLogo'></img>
                                    </Box>
                                    <FormControlLabel value="Stripe" control={<Radio
                                        // checked={selectedValue === 'a'}
                                        // onChange={handleChange}
                                        // value="a"
                                        name="radio-buttons"
                                        // inputProps={{ 'aria-label': 'A' }}
                                        sx={{
                                            color: "lightgray",
                                            '&.Mui-checked': {
                                                color: "#FFC72C",
                                            },
                                        }}
                                    />} label="Stripe" />

                                    {selectedPayment === 'Stripe' && <StripePaymentBox />}

                                </Box>

                                {paymentStatus && (
                                     <Box className={selectedPayment === 'Paypal' ? "paymentOption active" : "paymentOption"}>
                                    <Box className="paymentLogoBox paymentLogoBox2">
                                        <img src="/assets/img/paypal.png" alt='PayPal' className='paymentLogo'></img>
                                    </Box>
                                    <FormControlLabel value="Paypal" control={<Radio
                                        // checked={selectedValue === 'b'}
                                        // onChange={handleChange}
                                        // value="b"
                                        name="radio-buttons"
                                        // inputProps={{ 'aria-label': 'B' }}
                                        sx={{
                                            color: "lightgray",
                                            '&.Mui-checked': {
                                                color: "#FFC72C",
                                            },
                                        }}
                                    />} label="Paypal" />

                                    {selectedPayment === 'Paypal' && <PayPalPaymentBox />}
                                </Box> )}
{/* 
                                <Box className={selectedPayment === 'Other' ? "paymentOption active" : "paymentOption"}>
                                    <FormControlLabel value="Other" control={<Radio
                                        // checked={selectedValue === 'c'}
                                        // onChange={handleChange}
                                        // value="c"
                                        name="radio-buttons"
                                        // inputProps={{ 'aria-label': 'C' }}
                                        sx={{
                                            color: "lightgray",
                                            '&.Mui-checked': {
                                                color: "#FFC72C",
                                            },
                                        }}
                                    />} label="Pay on Pickup" />

                                    {selectedPayment === 'Other' && <OtherPaymentBox />}
                                </Box> */}


                            </RadioGroup>
                        </Grid>
                        <PaymentAddress address1={address1} address2={address2} address3={address3} address4={address4} address5={address5} type={type}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className=''>
                        <Box className='checkoutCartInfoBox cartTotalTable'>
                            <Box sx={{ p: 2 }} className={classes.totalHeader} align="center">
                                <Typography className='font-20 color2 font-bold'>Your Order</Typography>
                            </Box>
                            <Box sx={{ p: 3, pt: 0, pb: 4 }}>
                                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                                    <Table aria-label="Total table" sx={{ mt: 2 }}>
                                        <TableBody>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell scope="row">
                                                    <Typography className='font-15 font-bold color2'>Product</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {/* <Typography sx={{ fontWeight: "bold" }}>SubTotal</Typography> */}
                                                </TableCell>
                                            </TableRow>

                                            {(cartInfo && cartInfo.length>0) && (
                                                <>
                                                    {cartInfo.map((cart, index) =>
                                                        <TableRow key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell scope="row">
                                                                <Typography className='font-15 font-bold color1'>{cart.product.productType.charAt(0).toUpperCase() + cart.product.productType.slice(1)}</Typography>
                                                                <Box sx={{ display: "flex", gap: "40px" }}>
                                                                    <Typography className='font-15 font-bold color1'>Size: {cart.product.size % 1 === 0 ? `${cart.product.size} GL` : cart.product.size}</Typography>
                                                                    <Typography className='font-15 font-bold color1'>Qty: {cart.quantity}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography className='font-15 font-bold color1'>${FormatPrice(cart.itemPrice)}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    
                                                </>
                                            )}

                                            {/* <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell scope="row">
                                                    <Typography className='font-15 font-bold color2'>Shipping & Handling Cost</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography className='font-15 font-bold color2'>${deliveryCharge}</Typography>
                                                </TableCell>
                                            </TableRow> */}

                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell scope="row">
                                                    <Typography className='font-15 font-bold color2'>Tax({taxPercentage}%)</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography className='font-15 font-bold color2'>${FormatPrice(taxValue)}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            {(insurance && insurance !== 0) && (
                                                <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell scope="row">
                                                    <Typography className='font-15 font-bold color2'>Insurance</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography className='font-15 font-bold color2'>${FormatPrice(insurance)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            )}
                                            

                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell scope="row">
                                                    <Typography className='font-20 font-bold color2'>Total</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography className='font-20 font-bold color2'>${FormatPrice(cartTotalPrice)}</Typography>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    );




}

export default SendPayment;
