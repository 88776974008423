import { useEffect, useState, Fragment } from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { toggleSidebar } from '../commonFunctions/actions';
import { useDispatch, useSelector } from 'react-redux';
import {sidebar} from '../redux/cartSlice'
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import {
  Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Dashboard, Inbox, Mail, Settings } from '@mui/icons-material';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { spacing } from '@mui/system';
import Request from '../customUtils/Http/Request';
import CloseIcon from '@mui/icons-material/Close';
import DashboardHeader from './dashboardHeader';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
const cookies = new Cookies();


const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "fit-content",
    border: "none !important",
    // position: "unset !important",
    // height: "100vh !important"
  }
}));

function Sidebar() {
  const classes = useStyles();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }

  const specificSubmenuUrls = ['/user/islander/islander-order', '/user/islander/islander-order-status','/user/islander/islander-shipment'];

  const handleSubMenuClick = (url) => {
    // if (url) {  
    //   window.location.href = url;
    // }
  }

  // const handleMenuItemClick = (url) => {
  //   if (url) {
  //     window.location.href = url;
  //   }
  // }

  const menuItems = [
    { icon: "icon_1.svg", text: 'Track My Package', url: '/user/customer/track-package' },
    {
      icon: "icon_2.svg", text: 'Barrels/Boxes', url: '',
      subMenuItems: [
        { icon: "icon_11.svg", text: 'Buy Barrel/Box', url: '/user/buy/buy-product' },
        { icon: "icon_3.svg", text: 'Send Barrel/Box', url: '/user/send/send-product' }
      ],
    },
    {
      icon: "icon_4.svg", text: 'Islander Box', url: '',
      subMenuItems: [
        { icon: "icon_5.svg", text: 'My Islander Address', url: '/user/islander/islander-address' },
        { icon: "icon_6.svg", text: 'Send Islander Box', url: '/user/islander/islander-send' },
      ],
    },
    { icon: "icon_7.svg", text: 'My Account', url: '/user/customer/profile' },
    { icon: "icon_8.svg", text: 'Order History', url: '/user/customer/order-history' },
    { icon: "icon_9.svg", text: 'Shipping Estimator', url: '/user/customer/shipping-estimator' },
    { icon: "icon_10.svg", text: 'Support', url: '/help-center' }
  ];

  const shouldHideSidebar = () => {
    // Check if the sidebar is minimized and the screen width is less than a threshold (e.g., 992 pixels)
    return minimized && window.innerWidth < 992;
  };

  return (
   
    shouldHideSidebar() ? null : (
      <Drawer
        // className={`drawer ${minimized ? 'minimized' : ''}`}
        className='drawer'
        id="drawer"
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >

        <div className="toolbar" />
        {/* <CloseIcon className="closeSidebarIcon" onClick={openSidebar} /> */}
        
        {minimized ? (
          <Box sx={{ height: "45px", margin: "0 auto", mt: 3, mb: 2 }}>
            <Link to="/user/customer/dashboard">
              <img src="/assets/img/iof_mobile.svg" className='webHeader_logo'></img>
          </Link> 
          </Box>
        ) : (
          <Box sx={{ width: "110px", height: "45px", margin: "0 auto", mt: 3, mb: 2 }}>
            <Link to="/user/customer/dashboard">
              <img src="/assets/img/web_logo.png" className='webHeader_logo'></img>
            </Link>
          </Box>
        )}

        
        <List className="menuBox userMenuBox">
          {(menuItems && menuItems.length>0) && menuItems.map((item, index) => (
            <Fragment key={index}>
              {item.subMenuItems ? 
               <div className='linkBox'>
                <ListItem
                // onClick={() => {
                //   if (item.subMenuItems) {
                //     // Handle submenu item click
                //     handleSubMenuClick(item.url);
                //   } else {
                //     // Handle menu item click (without submenu)
                //     handleMenuItemClick(item.url);
                //   }
                // }}
                className="menulist non-clickable-menu-with-submenu"
              // selected={selectedIndex === index}
              >

                    {minimized ? (
                        <ListItemIcon className="iconBox">
                            <img src={`/assets/img/${item.icon}`}></img>
                        </ListItemIcon>
                      ): (
                        <>
                        <ListItemIcon className="iconBox">
                          <img src={`/assets/img/${item.icon}`}></img>
                        </ListItemIcon>
                              
                        <ListItemText primary={item.text} />
                         </>
                      )}
              
              </ListItem>
              {Array.isArray(item.subMenuItems) && (
                <List className={minimized ? 'nestedMenuBox' : 'nestedMenuBoxExpand'} component="div" disablePadding >
                  {item.subMenuItems.map((subItem, subIndex) => (
                    <>
                      {subItem.text === "Send Islander Box" ? (
                        <Link to={subItem.url} key={subIndex} className={`${location.pathname === subItem.url
                        || specificSubmenuUrls.includes(location.pathname) ? 'sub_menu_active' : ''}`}>
                      <ListItem
                        button
                        className="nested"
                        // key={subIndex}
                        // onClick={() => handleMenuItemClick(subItem.url)}
                      // selected={selectedIndex === subIndex}
                      >
                        {minimized ? (
                          <ListItemIcon className="iconBox2">
                              <img src={`/assets/img/${subItem.icon}`}></img>
                          </ListItemIcon>
                        ) : (
                          <>
                          <ListItemIcon className="iconBox2">
                              <img src={`/assets/img/${subItem.icon}`}></img>                                           
                          </ListItemIcon>
                          
                            <ListItemText primary={subItem.text} />
                          </>
                        )}
                  
                      </ListItem>
                    </Link>
                      ):(
                        <Link to={subItem.url} key={subIndex} className={`${location.pathname === subItem.url ? 'sub_menu_active' : ''}`}>
                      <ListItem
                        button
                        className="nested"
                        // key={subIndex}
                        // onClick={() => handleMenuItemClick(subItem.url)}
                      // selected={selectedIndex === subIndex}
                      >
                        {minimized ? (
                          <ListItemIcon className="iconBox2">
                              <img src={`/assets/img/${subItem.icon}`}></img>
                          </ListItemIcon>
                        ) : (
                          <>
                          <ListItemIcon className="iconBox2">
                              <img src={`/assets/img/${subItem.icon}`}></img>                                           
                          </ListItemIcon>
                          
                            <ListItemText primary={subItem.text} />
                          </>
                        )}
                  
                      </ListItem>
                    </Link>
                      )}
                    </>
                    
                  ))}
                </List>

              )}
              </div>
              :(
                <div className='linkBox'>
                <Link to={item.url}>
                <ListItem
                  className={`menulist ${location.pathname === item.url ? 'menu_active' : ''}`}
                // selected={selectedIndex === index}
                > 
                   {minimized ? (
                        <ListItemIcon className="iconBox">
                          <img src={`/assets/img/${item.icon}`}></img>
                        </ListItemIcon>
                      ): (
                        <> 
                        <ListItemIcon className="iconBox">
                          <img src={`/assets/img/${item.icon}`}></img>
                        </ListItemIcon> 
                        <ListItemText primary={item.text} />
                        </>
                    )}

                </ListItem>

                </Link>
                </div>
               )}
            
            </Fragment>
          ))}
        </List>
      </Drawer>
    )
   
  );
}

// const mapDispatchToProps = {
//   toggleSidebar,
// };

// const mapStateToProps = (state) => {
//   return {
//     minimized: state.sidebar.minimized,
//   };
// };

export default Sidebar;