import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Box,
	Menu,
	MenuItem,
	Checkbox, Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
    Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancyList } from "../../redux/helpCenterSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Editor } from '@tinymce/tinymce-react';
import { Form } from "react-bootstrap";
import { identifier } from "@babel/types";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CareerVacancyTable = () => {
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const dispatch = useDispatch();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
    const [isCustom, setIsCustom] = useState(false);
	const [open, setOpen] = useState(false);
	
	const [data, setData] = useState({
		"title": '',
		"status" : true,
		"description" : '',
		"expireOn" : '',   
	});
    const [error, setError] = useState('');

	const vacancy = useSelector((state) => state.helpCenter.vacancyList);

	const openDeleteModal = (id) => {
		  
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};


	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		try {
			const response = await Request.get(`admin/career/${id}`, [], true);
			if (response.length !== 0) {
				if (response) {
					setOpen(true);
					const newContentToAppend = " ";
					const updatedAnswer = response.description + newContentToAppend;
					setData({
						...data,
						"title": response.title,
                        "description": updatedAnswer,
                        "status": response.status,
						"expireOn": response.expireOn,
					  });
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleCloseDialog = () => {
		setSelectedProductEdit(null);
		setOpen(false);
	};

    const handleFieldChange = (field, value) => {
		setData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};


    const handleEditorChange = (content, editor) => {
        setData((prevData) => ({
			...prevData,
			description: content,
		}));
      };
	  
	  const handleVisibilityChange = (e) => {
        const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setData((prevData) => ({ ...prevData, status: newVisibility }));
        };

	useEffect(() => {
		dispatch(fetchVacancyList());
	}, []);

	useEffect(() => {
		dispatch(fetchVacancyList());
	}, [dispatch]);

	const productDelete = async () => {
		try {
              
			const response = await Request.delete(`admin/career/${selectedProduct}`, [], true);
            if (response.status === 204) {
                setIsDeleteDialogOpen(false)
                dispatch(fetchVacancyList())
            }
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault()

        if (!data.title || !data.description || !data.expireOn) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
              
			const response = await Request.put(`admin/career/${selectedProductEdit}`, data , true);
			  
			if (response.status === 200) {
				 
				setOpen(false)
                setIsCustom(false)
				dispatch(fetchVacancyList())
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
	  };

	return (
		<Box className="order-table-container product-categories-table-container">
			<TableContainer
				component={Paper}
				className="product-categories-table"
			>
				<Table>
					<TableHead>
						<TableRow>

							<TableCell className="textLeft">Title</TableCell>
							{/* <TableCell>Description</TableCell> */}
							<TableCell>Visibility</TableCell>
							<TableCell>Expiry Date</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(vacancy && vacancy.length>0) && vacancy.map((question) => (
							<TableRow
								key={question.id}
							>
								<TableCell className="textLeft">{question.title}</TableCell>
								{/* <TableCell>{question.description}</TableCell> */}
								<TableCell
									className={
										question.status === false
											? "order-table-container__outofstock"
											: " "
									}
								>
									<span className="product-categories-table__stock">
										{
											question.status === false
												? "Hide"
												: "Visible"
										}
									</span>
								</TableCell>
								<TableCell>{question.expireOn}</TableCell><TableCell>
									<IconButton
										aria-label="Edit"
										onClick={() => openEditModal(question.id)} 
										// Replace with your edit logic
									>
										<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
									<IconButton
										aria-label="Delete"
										onClick={() => openDeleteModal(question.id)} // Replace with your delete logic
									>
										<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
								</TableCell>

							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this Vacancy?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					
					<DialogTitle className="admin-modal__title-container"	>
						Edit Vacancy
						<Button color="primary" className="btn" onClick={handleSubmit}>
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form add-category-form">
                            <div className="row add-product-form__row">
                                    <Typography className="font-15 font-600" style={{ color: 'red' }}>{error}</Typography>
                                    
                                    <Form.Group controlId="title" className="shipping-price-form__field">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data.title}
                                        placeholder="Enter your question"
                                        onChange={(e) => handleFieldChange("title", e.target.value)}
                                        className="questionPlaceholder"
                                        required
                                    />
                                    </Form.Group>


                                    <Form.Group controlId="description" className="shipping-price-form__field">
                                    <Form.Label>Enter Description</Form.Label>
                                    <Editor
                                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            value={data.description}
                                            init={{
                                                plugins: 'lists link autolink',
                                                toolbar: 'bold italic underline undo redo | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                                            	automatic_uploads: true,
												// convert_urls: false,
												default_link_target: '_blank',
												link_assume_external_targets: true,
												link_context_toolbar: true,
												link_default_protocol: 'https',
												paste_preprocess: function(plugin, args) {
													args.content += ' ';
												},
												urlconverter_callback: function(url, node, on_save, name) {
													// Prevent conversion of & to &amp;
													return url.replace(/&amp;/g, '&');
												}
                                            
                                    }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Form.Group>

									<Form.Group
                                        controlId="productVisibility"
                                        className="col-md-6 add-category-form__field"
                                      >
                                        <Form.Label>Visibility</Form.Label>
                                        <div className="add-product-form__visibility">
                                          <Form.Check
                                            type="radio"
                                            label="Public"
                                            name="visibility"
                                            value="true"
                                            checked={data.status === true}
                                            onChange={handleVisibilityChange}
                                            
                                          />
                                          <Form.Check
                                            type="radio"
                                            label="Hide"
                                            name="visibility"
                                            value="false"
                                            checked={data.status === false}
                                            onChange={handleVisibilityChange}
                                          />
                                        </div>
                                      </Form.Group>
									<Form.Group controlId="expireOn" className="shipping-price-form__field">
									<Form.Label>Expiry Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={data.expireOn}
                                        onChange={(e) => handleFieldChange("expireOn", e.target.value)}
                                        className="questionPlaceholder"
                                        required
                                    />
                                    </Form.Group>

								</div>
							</Box>
						</Box>
					</DialogContent>
			</Dialog>
		</Box>


	);
};

export default CareerVacancyTable;
