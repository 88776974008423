import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DriverTable from "./DriverTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchApproveReview, fetchPendingReview, fetchRejectedReview } from "../redux/reviewSlice";
import AdminCustomerReviewTable from "./adminCustomerReviewTable";

function AdminCustomerReviews() {
	const [selectedTab, setSelectedTab] = useState(0);
	const [approvedReviewInfo, setApprovedReviewInfo] = useState([]);
	const [pendingReviewInfo, setPendingReviewInfo] = useState([]);
	const [rejectedReviewInfo, setRejectedReviewInfo] = useState([]);
	const dispatch = useDispatch();

	const approveReviewList = useSelector((state) => state.review.approveReview);
	const pendingReviewList = useSelector((state) => state.review.pendingReview);
	const rejectedReviewList = useSelector((state) => state.review.rejectedReview);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	useEffect(() => {
		switch (selectedTab) {
			case 0:
				dispatch(fetchApproveReview());
				break;
			case 1:
				dispatch(fetchPendingReview());
				break;
			case 2:
				dispatch(fetchRejectedReview());
				break;
			default:
				dispatch(fetchApproveReview());
				break;
		  }
	}, [selectedTab]);


	return (
		<>
			<Box className="adminInnerPage driverListPage">
				<Typography variant="h2" className="adminInnerPage__title">
					Customer Reviews
				</Typography>

				<Box className="adminInnerPage__tabs">
					<Tabs value={selectedTab} onChange={handleTabChange}>
						<Tab label="Approved" />
						<Tab label="Pending For Approval" />
						<Tab label="Rejected" />
					</Tabs>
				</Box>

				<Box className="adminInnerPage__tabs-content">
					{selectedTab === 0 && (
						<Typography component="div" role="tabpanel">
							<div className="order-search-filter">
								<div className="order-search-filter__search">
									{/* <input
										type="text"
										placeholder="Search For Driver"
									/> */}
								</div>
							</div>

							<AdminCustomerReviewTable reviewInfo={approveReviewList} type="Approved"/>
						</Typography>
					)}
					{selectedTab === 1 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<AdminCustomerReviewTable reviewInfo={pendingReviewList} type="Pending for approval"/>
					</Typography>
					)}
					{selectedTab === 2 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<AdminCustomerReviewTable reviewInfo={rejectedReviewList} type="Rejected"/>
					</Typography>
					)}
				</Box>
			</Box>
		</>
	);
}

export default AdminCustomerReviews;
