import React, { useState, useEffect, useRef } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Typography,
} from "@mui/material";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompletedOrders, fetchOutForDeliveryOrders, fetchAssignedOrders, fetchCanceledOrders } from "../redux/driverOrderSlice";
import StatusList from "./statusList";
import Request from '../customUtils/Http/Request';
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';


const ordersData = [
	{
		orderId: 1,
		orderDate: "2023-08-28",
		customer: "John Doe",
		item: "Product A",
		pickUpDeliveryDate: "2023-09-05",
		payment: "$100",
		location: "123 Main St",
		assignedOn: "2023-08-29",
		status: "Pending",
	},
	{
		orderId: 2,
		orderDate: "2023-08-28",
		customer: "Joe Lucky",
		item: "Product N",
		pickUpDeliveryDate: "2023-09-05",
		payment: "$100",
		location: "123 Main St",
		assignedOn: "2023-08-29",
		status: "Completed",
	},
];


const DriversOrderTable = ({driverInfo, type}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [editId, setEditId] = useState(null);
	const [selectedOrderType, setSelectedOrderType] = useState(null);
	const dispatch = useDispatch();
	const [statusAnchorEl, setStatusAnchorEl] = useState(null);
	const [routeDisable, setRouteDisable] = useState(true);
	const [islanderStatus, setIslanderStatus] = useState(false);
  	const [selectedStatus, setSelectedStatus] = useState('');
	const [selectedStatusNumber, setSelectedStatusNumber] = useState(-1);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [orderDetail, setOrderDetail] = useState([]);
	const [orderProductDetail, setOrderProductDetail] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [mapData, setMapData] = useState([]);
	const [productTotal, setProductTotal] = useState(0);
	const [invoiceNo, setInvoiceNo] = useState('');
	let mapContainer = useRef(null);
	const [mapLoader, setMapLoader] = useState(false);
	let [mapInst, setMapInst] = useState(null);
	// const mapInstRef = useRef(null);


	const [editableData, setEditableData] = useState({
        orderStatus: '',
        paymentStatus:''
    });

	const getStatusText = (statusNumber) => {
		if (statusNumber === 1) return 'Assigned';
		if (statusNumber === 2) return 'On Route/Ready to Pick up';
		if (statusNumber === 3) return 'Delivered/Picked up';
		if (statusNumber === 4) return 'Cancelled';
	  };

	  const paymentStatus = [
        { text: 'Success', value : 'true' },
        { text: 'Pending' ,  value : 'false'}
    ];

	const orderStatusOptions = [
        { text: 'Assigned', value: 1 },
        { text: 'On Route/Ready to Pick up', value: 2 },
        { text: 'Delivered/Picked up', value: 3 },
        { text: 'Cancelled', value: 4 },
    ];

	const handleDialogOpen = (id, type, editId) => {
		setSelectedOrder(id);
		setEditId(editId)
		setSelectedOrderType(type)
		setIsDialogOpen(true);
		fetchOrderDetail(id)
	};

	const handleDialogClose = (id) => {
		if(id){
			saveStatusInfo(id)
		}
		setSelectedOrder(null);
		setIsDialogOpen(false);
		
	};

	const saveStatusInfo = async (id) => {   
		const statusInfo = {
			status: editableData.orderStatus,
			paymentStatus: editableData.paymentStatus === "true"
		}
        try {
            let response = await Request.put(`driver/orders/updateOrderStatus/${id}`, statusInfo, true);
            if (response.status === 200) {
				dispatch(fetchAssignedOrders());
				dispatch(fetchOutForDeliveryOrders());
				dispatch(fetchCompletedOrders());
				dispatch(fetchCanceledOrders());
                // setDriverList(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const handleFieldChange = (e, fieldName) => {
		setSelectedOrder((prevOrder) => ({
			...prevOrder,
			[fieldName]: e.target.value,
		}));
	};

	const getFormattedAddress = (address) => {
		return `${address?.fullName}, ${address?.address_line_1}, ${address?.address_line_2}\n${address?.city}-${address?.zip}, \n ${address?.state}\n${address?.country}`;
	};

	useEffect(() => {
		if(type === "outForDelivery" && driverInfo && driverInfo.length>0){
			setMapLoader(true)
			showRouteForDelivery(driverInfo)
		}
	}, []);

	const showRouteForDelivery = async (driverInfo) => {
		const idsArray = driverInfo.map(obj => obj.id);
		const item = {
			"orderList": idsArray
		}
		try {
			let response = await Request.put('driver/orders/moveOrderForPickupOrDelivery', item, true);
			if (response.status === 200) {
				// setMapData(response.json())
				const responseData = await response.json();
				setMapLoader(false)
				showRoute(responseData)
			} else {
				console.error('Failed to fetch data from API');
			}
			} catch (error) {
			console.error('Error fetching data from API:', error);
			}

	}

	const fetchOrderDetail = async (id) => {
        try {
			let response = await Request.get(`driver/orders/order_detail/${id}`, [], true);
            if (response.length !== 0) {
				setInvoiceNo(response.invoiceNo)
				setOrderDetail(response)
				setOrderProductDetail(response.products)
				setProductTotal(response?.totalPrice)
				setEditableData({
					orderStatus: response?.driver_order_assignment?.status,
					paymentStatus: response.paymentStatus
				})

            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };


	const orderItems = [
		{
			id: 1,
			name: "Item A",
			weight: "0.5 kg",
			quantity: 2,
			price: "$10",
			subtotal: "$20",
		},
		{
			id: 2,
			name: "Item B",
			weight: "1 kg",
			quantity: 1,
			price: "$15",
			subtotal: "$15",
		},
		// Add more items here...
	];

	const total = orderItems.reduce(
		(sum, item) => sum + parseFloat(item.subtotal.replace("$", "")),
		0
	);

	function formatDate(timestamp) {
		const months = [
			"Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		  ];
		const dateObj = new Date(timestamp);
		const year = dateObj.getFullYear();
		const month = months[dateObj.getMonth()];
		const day = dateObj.getDate().toString().padStart(2, '0');
		return `${month} ${day} ${year}`;
	  }
	
	  const handleStatusClick = (event, statusNumber, id) => {
		setStatusAnchorEl(event.currentTarget);
		setSelectedItemId(id);
    	setSelectedStatusNumber(statusNumber);
	  };

	  const handleStatusClose = () => {
		setStatusAnchorEl(null);
	  };

	  const handleStatusSelect = (statusNumber) => {
		changeStatus(selectedItemId, statusNumber)
	  };

	  const changeStatus = async (id, statusNumber) => {
		const item = {
			"status": statusNumber
		}
			try {
			let response = await Request.put(`driver/orders/updateOrderStatus/${id}`, item, true);
			if (response.length !== 0) {
				dispatch(fetchAssignedOrders());
				dispatch(fetchOutForDeliveryOrders());
				dispatch(fetchCompletedOrders());
				dispatch(fetchCanceledOrders());
			} else {
				console.error('Failed to fetch data from API');
			}
			} catch (error) {
			console.error('Error fetching data from API:', error);
			}
		
}

	const showRoute = (responseData) => {
			// map data start
					//map route code start
					if(mapInst) mapInst.remove();
					mapInst = L.map(mapContainer.current).setView([51.0447, -114.0719], 10);
					setMapInst(mapInst)
					L.tileLayer('https://maps.geoapify.com/v1/tile/osm-carto/{z}/{x}/{y}.png?apiKey=512529fe75ff4856ad47e5180c083889', {
					attribution: 'Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>',
					maxZoom: 20, id: 'osm-bright'
					}).addTo(mapInst);

					let flattened = [].concat(responseData.message.features[0].geometry.coordinates);
					let latlngs = flattened.flat(1).map(([lon, lat]) => [lat, lon]);
					L.polyline(latlngs, { color: 'red' }).addTo(mapInst);
				//map route code end

				// map points code start
					let routePoints = responseData.message.features[0].properties.waypoints.map((waypoint) => [waypoint.location[1], waypoint.location[0]]);
					let blueIcon = L.icon({
						iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Map_pin_icon_green.svg',
						iconSize: [25, 41], // size of the icon
						iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
						popupAnchor: [0, -41] // point from which the popup should open relative to the iconAnchor
					});

					let orderIds =  responseData.orderIds
					routePoints.forEach(([lat, lon], index) => {
						let marker = L.marker([lat, lon], {icon: blueIcon}).addTo(mapInst);
						if (index === 0|| index == (routePoints.length-1)) {
							marker.bindPopup("Warehouse");
						} else {
							marker.bindPopup(`(${index})Order ID: ${orderIds[index-1]}`); 
						}
					});

					mapInst.fitBounds([51.0447, -114.0719], { padding: [50, 50] });
				// map points code end
				// map data end
}

const toggleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      // If the row is already selected, remove it from selectedRows
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      // If the row is not selected, add it to selectedRows
      setSelectedRows([...selectedRows, id]);
    }
  };
  
		const handleSave = async () => {
			setMapLoader(true)
			const item = {
				"orderList": selectedRows
			}
			try {
				let response = await Request.put('driver/orders/moveOrderForPickupOrDelivery', item, true);
				if (response.status === 200) {
					// setMapData(response.json())
					const responseData = await response.json();
					setMapLoader(false)
					showRoute(responseData)
				} else {
					console.error('Failed to fetch data from API');
				}
				} catch (error) {
				console.error('Error fetching data from API:', error);
				}
		  };


	return (
		<>
			<Box className="order-table-container driver-order-table-container table-block">
				{type === "assigned" &&(
					<Box className={`optimizeBox ${selectedRows.length === 0 ? 'disabled' : ''}`} onClick={handleSave}>
						<img src="/assets/img/route.png"></img>
						<Typography className="color1 font-15 font-600 mb-0 routeText">
							Optimize Delivery &
						</Typography>
						<Typography className="color1 font-15 font-600 routeText">Pick Up Route</Typography>
					</Box>
				)}
				<TableContainer component={Paper} className="routeTable">
					<Table>
						<TableHead>
							<TableRow>
								{type === "assigned" && (
									<TableCell></TableCell>
								)}
								
								<TableCell>Order ID</TableCell>
								<TableCell>Order Date</TableCell>
								<TableCell>Customer</TableCell>
								<TableCell>Order Type</TableCell>
								<TableCell>Est. Pick Up & Delivery Date</TableCell>
								<TableCell>Payment to be Collected</TableCell>
								<TableCell>Location</TableCell>
								<TableCell>Assigned on</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(driverInfo && driverInfo.length>0) && (
								<>	
									{driverInfo.map((order) => (
										<>
								<TableRow
									key={order.orderId}
								>

									{type === "assigned" && (
										<TableCell>
											<input type="checkbox"
												checked={selectedRows.includes(order?.id)}
												onChange={() => toggleRowSelection(order?.id)}
											/>
										</TableCell>
									)}		
									
									<TableCell onClick={() => handleDialogOpen(order?.order?.id,order.order?.orderType,order?.id)} className="text-underline font-bold cursor-pointer">{order.order?.invoiceNo}</TableCell>
									<TableCell>{formatDate(order.order?.createdAt)}</TableCell>
									<TableCell>{order.order?.user?.fullName}</TableCell>
									<TableCell>{order.order?.orderType}</TableCell>
									<TableCell>{formatDate(order.order?.estDeliveryTimeInDay)}</TableCell>
									<TableCell>${order.order?.payment_detail?.amount}</TableCell>
									<TableCell>{order.order.orderType === "Send" ? order.order?.pickupAddress?.city : order.order?.shippingAddress?.city}</TableCell>
									<TableCell>{formatDate(order.createdAt)}</TableCell>
									<TableCell onClick={(e) => handleStatusClick(e, order.status, order.id )}>
										{getStatusText(order.status)}
									</TableCell>
								</TableRow>
</>
							))}
								</>
							)}
							
						</TableBody>
					</Table>

					<StatusList anchorEl={statusAnchorEl} onClose={handleStatusClose} statuses={[
						{text: 'Assigned', number: 1},
						{text: 'On Route/Ready to Pick up', number: 2},
						{text: 'Delivered/Picked up', number: 3}, 
						{text: 'Cancelled', number: 4}, 
					]} onSelectStatus={handleStatusSelect} />
				</TableContainer>
			</Box>
			<Box align="center" style={{width: "100%",marginTop: "30px"}}>
				{mapLoader && (
					<div class="loader-container mapLoader-container" id="loader">
						<div class="loader mapLoader"></div>
						<p className="font-15 font-600">Loading Map...</p>
					</div>
				)}
				<Box id="map" align="center" ref={mapContainer} style={{ height: '400px', width: '800px' }} className="mapBox"></Box>
			</Box>
			<Dialog
				open={isDialogOpen}
				onClose={handleDialogClose}
				className="admin-modal admin-dialog driver-dialog"
				// maxWidth="744px"
			>
				<DialogTitle className="admin-modal__title-container">
					Edit Order {invoiceNo && <>{invoiceNo}</>}
					<Button
						// onClick={handleDialogClose}
						onClick={() => handleDialogClose(editId)}
						color="primary"
						className="btn"
					>
						Save
					</Button>
				</DialogTitle>
				<DialogContent sx={{ padding: "30px" }}>
					{orderDetail && (
						<>
							<Box className="driver-dialog-form__container">
								<Typography
									variant="h3"
									className="section-title"
								>
									Order Details
								</Typography>
								<Form className="edit-order-form driver-dialog-form">
									<div className="driver-dialog-form__fields">
										<Form.Group
											controlId="formEmail"
											className="driver-dialog-form__field"
										>
											<Form.Label>Customer</Form.Label>
											<Form.Control
												type="email"
												value={orderDetail?.user?.fullName}
												readOnly
											/>
										</Form.Group>
										<Form.Group
											controlId="formEmail"
											className="driver-dialog-form__field"
										>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												value={orderDetail?.user?.email}
												readOnly
											/>
										</Form.Group>
										<Form.Group
											controlId="formPhone"
											className="driver-dialog-form__field"
										>
											<Form.Label>Phone</Form.Label>
											<Form.Control
												type="text"
												value={orderDetail?.user?.phone}
												readOnly
											/>
										</Form.Group>
										{/* <Form.Group
											controlId="formPhone"
											className="driver-dialog-form__field"
										>
											<Form.Label>
												Payment Method
											</Form.Label>
											<Form.Control
												type="text"
												value={orderDetail.payment_detail?.provider}
												readOnly
											/>
										</Form.Group>
										<Form.Group
											controlId="formPhone"
											className="driver-dialog-form__field"
										>
											<Form.Label>
												Payment to be Collected
											</Form.Label>
											<Form.Control
												type="text"
												value={`${orderDetail.payment_detail?.amount}`}
												readOnly
											/>
										</Form.Group>
										<Form.Group
											controlId="formPhone"
											className="driver-dialog-form__field"
										>
											<Form.Label>
												Payment Status
											</Form.Label>
											<Form.Select
												name="paymentStatus"
												value={editableData.paymentStatus}
												onChange={(e) => {
													const selectedValue = e.target.value;
													setEditableData({ ...editableData, paymentStatus: selectedValue });
												}}
											>
												<option value="" disabled>Select an option</option>
												{paymentStatus.map(option => (
													<option key={option.value} value={option.value}>
														{option.text}
													</option>
												))}
                                        </Form.Select>
										</Form.Group> */}
										<Form.Group
                                        controlId="orderStatus"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order Status</Form.Label>
                                        <Form.Select
                                            name="orderStatus"
                                            value={editableData.orderStatus}
                                            onChange={(e) => {
                                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                                const selectedValue = parseInt(e.target.value, 10);
                                                setEditableData({ ...editableData, orderStatus: selectedValue });
                                            }}
											className="font-14"
                                        >
                                            <option value="" disabled>Select an option</option>
                                            {orderStatusOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
										<Form.Group
											controlId="formPhone"
											className="driver-dialog-form__field"
										>
											<Form.Label>Order Date</Form.Label>
											<Form.Control
												type="text"
												value={formatDate(orderDetail.createdAt)}
												readOnly
											/>
										</Form.Group>

										{selectedOrderType === "Send" && (
											<>
												<Form.Group
													controlId="formPhone"
													className="driver-dialog-form__field"
												>
													<Form.Label>
														Preferred Pickup Date
													</Form.Label>
													<Form.Control
														type="text"
														value={formatDate(orderDetail?.slot?.date)}
														readOnly
													/>
												</Form.Group>
												<Form.Group
													controlId="formPhone"
													className="driver-dialog-form__field"
												>
													<Form.Label>
													Preferred PickUp Time Slot
													</Form.Label>
													<Form.Control
														type="text"
														value={`${orderDetail?.slot?.start_time} to ${orderDetail?.slot?.end_time}`}
														readOnly
													/>
												</Form.Group>
												<Form.Group
													controlId="formPhone"
													className="driver-dialog-form__field"
												>
													<Form.Label>
														Pick Up Address
													</Form.Label>
													<Form.Control
														as="textarea"
														value={getFormattedAddress(orderDetail?.pickupAddress)}
														readOnly
													/>
												</Form.Group>
											</>
										)}

										{selectedOrderType === "Purchase" && (
											<>
												<Form.Group
													controlId="formPhone"
													className="driver-dialog-form__field"
												>
													<Form.Label>
													Estimated Delivery Date
													</Form.Label>
													<Form.Control
														type="text"
														value={formatDate(orderDetail?.estDeliveryTimeInDay)}
														readOnly
													/>
												</Form.Group>
												<Form.Group
													controlId="formPhone"
													className="driver-dialog-form__field"
												>
													<Form.Label>
														Delivery Address
													</Form.Label>
													<Form.Control
														as="textarea"
														value={getFormattedAddress(orderDetail?.shippingAddress)}
														readOnly
													/>
												</Form.Group>
											</>	
										)}
									</div>
								</Form>
							</Box>

							<Box className="order-table-container driver-dialog__order-details-table">
								<TableContainer component={Paper}>
									<Table aria-label="Order details table">
										<TableHead>
											<TableRow>
												<TableCell>Items</TableCell>
												<TableCell align="right">
													Qty
												</TableCell>
												{/* <TableCell align="right">
													Price
												</TableCell>
												<TableCell align="right">
													Subtotal
												</TableCell> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{(orderProductDetail && orderProductDetail.length>0) && (
												<>
													{orderProductDetail.map((item) => (
													<TableRow key={item.id}>
														<TableCell>
														{orderDetail.orderType === "Send" ? (
																<>
																	{item.product?.productType} ({item.product?.size}GL)
																</>
															):(
																<>
																	{item.product?.name}
																</>
															)}
															
														</TableCell>
														<TableCell align="right">
															{orderDetail.orderType === "Send" ? (
																<>
																{item.product?.quantity}
																</>
															):(
																<>
																	{item?.quantity}
																</>
															)}
															
														</TableCell>
														{/* <TableCell align="right">
															${item.product?.price}
														</TableCell>
														<TableCell align="right">
															${item.itemPrice}
														</TableCell> */}
													</TableRow>
											))}
												</>
											)}
										
											<TableRow>
												{/* <TableCell
													colSpan={3}
													align="right"
												>
													<strong>Total Amount: </strong>
												</TableCell>
												<TableCell align="right">
													<strong>
														${productTotal.toFixed(2)}
													</strong>
												</TableCell> */}
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DriversOrderTable;
