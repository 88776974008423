import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Box,
	Menu,
	MenuItem,
	Checkbox, Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchPriorityProductCategories } from "../redux/priorityProductCategoriesSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from "react-bootstrap";

const PriorityProductCategoriesTable = () => {
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const dispatch = useDispatch();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	const [open, setOpen] = useState(false);
	const [editedCategoryData, setEditedCategoryData] = useState({
		"name": '',
		"status" : false
	});

	const priorityProductCategoriesList = useSelector((state) => state.priorityProductCategories.priorityProductCategories);

	// const toggleSelectAll = () => {
	// 	if (selectAll) {
	// 		setSelectedCategories([]);
	// 	} else {
	// 		setSelectedCategories(
	// 			productCategories.map((category) => category.id)
	// 		);
	// 	}
	// 	setSelectAll(!selectAll);
	// };

	// const ITEM_HEIGHT = 48;

	// const [anchorEl, setAnchorEl] = useState(null); // Use a single anchorEl state

	// const handleClick = (event, categoryId) => {
	// 	console.log('Menu clicked for category:', categoryId);
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handleClose = () => {
	// 	console.log('Menu closed');
	// 	setAnchorEl(null);
	// };

	const openDeleteModal = (categoryId) => {
		  
		setSelectedProduct(categoryId);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};


	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		try {
			const response = await Request.get(`admin/islander/category/${id}`, [], true);
			if (response.length !== 0) {
				if (response) {
					setOpen(true);
					setEditedCategoryData({
						"name" : response.name,
						"status" : response.status
					})
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleCloseDialog = () => {
		setSelectedProductEdit(null);
		setOpen(false);
	};

	const handleNameChange = (e) => {
		const newName = e.target.value;
		setEditedCategoryData((prevData) => ({
		  ...prevData,
		  name: newName,
		}));
	  };

	  const handleVisibilityChange = (e) => {
		const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
		setEditedCategoryData((prevData) => ({ ...prevData, status: newVisibility }));
	  };


	const productCategories = [
		{ id: 1, name: "Category A", items: 10, visibility: "Public" },
		{ id: 2, name: "Category B", items: 15, visibility: "Hide" },
	];

	useEffect(() => {
		dispatch(fetchPriorityProductCategories());
	}, []);

	useEffect(() => {
		dispatch(fetchPriorityProductCategories());
	}, [dispatch]);

	const productDelete = async () => {
		try {
			const response = await Request.delete(`admin/islander/category/${selectedProduct}`, [], true);
			if (response) {
				if (response.status === 204) {
					setIsDeleteDialogOpen(false)
					dispatch(fetchPriorityProductCategories())
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			// Fetch data from API
			const response = await Request.put(`admin/islander/category/${selectedProductEdit}`, {"name" : editedCategoryData.name, "status": editedCategoryData.status}, true);
			  
			if (response.status === 200) {
				setOpen(false)
				dispatch(fetchPriorityProductCategories())
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
		// if (selectedProductEdit) {
		// 	dispatch(updateProductCategories({ id: selectedProductEdit, item: editedCategoryData.name }))
		// 		.then((result) => {
		// 			if (updateProductCategories.fulfilled.match(result)) {
		// 				// dispatch(fetchProductCategories())
		// 				setOpen(false)
		// 			} else {
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.error("Error updating customer:", error);
		// 		});
		// }
	  };

	return (
		<Box className="order-table-container product-categories-table-container">
			<TableContainer
				component={Paper}
				className="product-categories-table"
			>
				<Table>
					<TableHead>
						<TableRow>

							<TableCell className="textLeft">Name</TableCell>
							<TableCell>No Of Items</TableCell>
							<TableCell>Visibility</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{priorityProductCategoriesList && priorityProductCategoriesList.length>0 && priorityProductCategoriesList.map((category) => (
							<TableRow
								key={category.id}
							>
								<TableCell className="textLeft">{category.name}</TableCell>
								{/* <TableCell>{category.items}</TableCell> */}
								<TableCell>Item</TableCell>
								<TableCell
									className={
										category.status === false
											? "order-table-container__outofstock"
											: " "
									}
								>
									<span className="product-categories-table__stock">
										{
											category.status === false
												? "Hide"
												: "Visible"
										}
									</span>
								</TableCell>
								<TableCell>
									<IconButton
										aria-label="Edit"
										onClick={() => openEditModal(category.id)} 
										// Replace with your edit logic
									>
										<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
									<IconButton
										aria-label="Delete"
										onClick={() => openDeleteModal(category.id)} // Replace with your delete logic
									>
										<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this category?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container"	>
						Edit Categories
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form add-category-form">
								<div className="row add-product-form__row">
									<Form.Group
										controlId="productName"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Product Name"
											value={editedCategoryData.name}
											onChange={handleNameChange}
											required
										/>
									</Form.Group>

									<Form.Group
										controlId="productVisibility"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Visibility</Form.Label>
										<div className="add-product-form__visibility">
											<Form.Check
												type="radio"
												label="Public"
												name="visibility"
												value="true"
												checked={editedCategoryData.status === true}
												onChange={handleVisibilityChange}
												
											/>
											<Form.Check
												type="radio"
												label="Hide"
												name="visibility"
												value="false"
												checked={editedCategoryData.status === false}
												onChange={handleVisibilityChange}
											/>
										</div>
									</Form.Group>
								</div>
							</Box>
						</Box>
					</DialogContent>
					</form>
				</Dialog>
		</Box>


	);
};

export default PriorityProductCategoriesTable;
