import React, {useEffect, useState} from "react";
import { Modal, Box, Typography, Avatar, Button } from "@mui/material";
import DriverForm from "./DriverForm";
import DriverPendingOrdersTable from "./DriverPendingOrdersTable";
import Request from '../customUtils/Http/Request';
import DriverCompletedOrdersTable from "./driverCompletedOrderTable";
import { useDispatch, useSelector } from "react-redux";
import { approveDriver, pendingDriver, rejectedDriver, updateDriverStatus } from "../redux/driverSlice";


	const DriverModal = ({ isOpen, handleClose, driver, driverInfo, type, driverId }) => {
	const [pendingOrderInfo, setPendingOrderInfo] = useState([]);
	const [completedOrderInfo, setCompletedOrderInfo] = useState([]);
	const [selectedDriverIndex, setSelectedDriverIndex] = useState(0); 
	const dispatch = useDispatch();


	useEffect(() => {
		if(type === "Approved"){
			fetchOrders(driverId)
		}
	}, [isOpen, driverId]);


	const fetchOrders = async (id) => {
        try {
			if (isOpen) {
            let response = await Request.get(`admin/driver/${id}`, [], true);
            if (response.length !== 0) {
				setPendingOrderInfo(response.pendingOrder)
				setCompletedOrderInfo(response.completedOrder)
            } else {
                console.error('Failed to fetch data from API');
            }
		}
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	if(driverInfo && driverInfo.length > 0){
		const selectedIndex = driverInfo.findIndex((item) => item.id === driverId);
		var selectedDriver = driverInfo[selectedIndex]; 
	}else{
		var selectedDriver = null;
	}


	const selectNextDriver = () => {
		setSelectedDriverIndex((prevIndex) => prevIndex + 1);
	  };
	  
	  const selectPreviousDriver = () => {
		setSelectedDriverIndex((prevIndex) => prevIndex - 1);
	  };

	if (!driver) {
		return null;
	}

	const approveDriverStatus = async (id) => {
		// const status = {
			const isStatus = true
		// }

		dispatch(updateDriverStatus({ id: id, isStatus: true }))
				.then((result) => {
					if (result.payload.message === "Success") {
						dispatch(approveDriver());
						dispatch(pendingDriver());
						dispatch(rejectedDriver());
						handleClose();
					} else {
					}
				})
				.catch((error) => {
					console.error("Error updating customer:", error);
				});
    };

	const rejectDriverStatus = async (id) => {
		// const status = {
		// 	"isStatus": false
		// }
		dispatch(updateDriverStatus({ id: id, isStatus: false }))
				.then((result) => {
					if (result.payload.message === "Success") {
						dispatch(approveDriver());
						dispatch(pendingDriver());
						dispatch(rejectedDriver());
						handleClose();
					} else {
					}
				})
				.catch((error) => {
					console.error("Error updating customer:", error);
				});
    };

	return (
		<Modal open={isOpen} onClose={handleClose} className="admin-modal">
			<Box className="admin-modal__container">
				<div className="admin-modal__title-container">
					<Typography variant="h6" className="admin-modal__title">
						{type}
					</Typography>
				</div>

				<div className="driver-form">
					<div className="driver-form__form">
						<DriverForm driver={selectedDriver} type={type}/>
					</div>
					<div className="driver-form__photo">
						<Avatar
							alt={driver.name}
							src={driver.user?.img === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driver.user.img}`}
							variant="rounded"
							sx={{ width: 234, height: 234 }}
							className="center-avatar"
						/>
							<Box sx={{mt: 3}}>
						{type === "Approved" && (
							<Button className="b1 b4" onClick={() => rejectDriverStatus(driverId)}>Reject</Button>
						)}
						{type === "Pending for approval" && (
							<Box className="flexBox">
								<Button className="b1 b5" onClick={() => approveDriverStatus(driverId)}>Approve</Button>
								<Button className="b1 b4" onClick={() => rejectDriverStatus(driverId)}>Reject</Button>
							</Box>
						)}
							{type === "Rejected" && (
							<Button className="b1 b5" onClick={() => approveDriverStatus(driverId)}>Approved</Button>
						)}
					</Box>	
					</div>
				
				</div>

				{pendingOrderInfo && pendingOrderInfo.length !== 0 && (
				<div className="driver-pending-orders">
					<Typography variant="h6" className="section-title">
						Pending Orders
						<span className="section-title__link">
							<a href="#">See All</a>
						</span>
					</Typography>
					<DriverPendingOrdersTable orders={pendingOrderInfo} />
				</div>
				)}

				{completedOrderInfo && completedOrderInfo.length !== 0 && (
				<div className="driver-pending-orders driver-completed-orders">
						<Typography variant="h6" className="section-title">
						Completed Orders
						<span className="section-title__link">
							<a href="#">See All</a>
						</span>
					</Typography>
					<DriverCompletedOrdersTable orders={completedOrderInfo} />
				</div>
				)}
			</Box>
		</Modal>
	);
};

export default DriverModal;
