import React from 'react';
import CookieConsent from 'react-cookie-consent';

const ConsentNotification = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I acknowledge"
      cookieName="cookie-consent"
      style={{ background: '#333', color: '#fff' }}
      buttonStyle={{ background: '#007BFF', color: '#fff' }}
    >
      This website uses cookies to ensure you get the best experience on our website.
    </CookieConsent>
  );
};

export default ConsentNotification;