import { useEffect, useState } from 'react';
import Request from '../customUtils/Http/Request';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Container, FormControlLabel, Grid, Paper, Radio, RadioGroup, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Table } from 'react-bootstrap';
import PaymentAddress from './paymentAddress';
import { FormatPrice } from './commonPriceFunction';

const useStyles = makeStyles((theme) => ({
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
}));

const PaypalPayment = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [address1, setAddress1] = useState({});
    const [address2, setAddress2] = useState({});
    const [address3, setAddress3] = useState({});
    const [cartInfo, setCartInfo] = useState([]);
    const [type, setType] = useState('buy');
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [taxValue, setTaxValue] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [invoiceNo, setInvoiceNo] = useState('');
    const [orderId, setOrderId] = useState('');
    const [orderType, setOrderType] = useState('');
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessage2, setErrorMessage2] = useState('');

    useEffect(() => {
        fetchOrderDetails()
    }, []);

    const fetchOrderDetails = async () => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let orderId = urlParams.get('orderId');
        let orderType = urlParams.get('orderType');
        setOrderId(orderId)
        setOrderType(orderType)

        try {
            // Fetch data from API
            const data = await Request.get(`order/order_detail/${orderId}`, []);
            if (data) {
                setCartInfo(data.products);
                setCartTotalPrice(data.totalPrice);
                setAddress1(data.shippingAddress);
                setAddress2(data.billingAddress);
                setAddress3(data.user);
                setDeliveryCharge(data.shippingCharge);
                setTaxValue(data.tax);
                setTaxPercentage(data.taxPercentage);
                setInvoiceNo(data.invoiceNo);
                setInsurance(data.insuranceValue);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const handlePayment = async () => {
        setLoader(true)
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let orderId = urlParams.get('orderId');
        let token = urlParams.get('token');
        let PayerID = urlParams.get('PayerID');
        let orderType = urlParams.get('orderType');
        let invoiceNo = urlParams.get('invoiceNo');

        try {
            // Fetch data from API
            const data = await Request.get(`checkout/capturePaypalPayment`, [{ orderId }, { token }, { PayerID }]);
            if (data.message === "Payment successful") {

                if (orderType === "Send") {
                    window.location.href = `https://www.isleoffreight.com/user/send/send-order-detail?orderId=${orderId}&invoiceNo=${invoiceNo}`
                }
                else if (orderType === "Purchase") {
                    window.location.href = `https://www.isleoffreight.com/user/buy/order-detail?orderId=${orderId}&invoiceNo=${invoiceNo}`
                }
                else if (orderType === "Islander") {
                    window.location.href = `https://www.isleoffreight.com/user/islander/islander-order-detail?orderId=${orderId}&invoiceNo=${invoiceNo}`
                }
                else {
                    // navigate('/dashboard')
                }
            } else {
                setLoader(false)
                setErrorMessage(true)
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
            setLoader(false)
            setErrorMessage(true)
            setErrorMessage2("error")
        }
    }

    const cancelPayment = async () => {
        if (orderType === "Send") {
            window.location.href = `${process.env.REACT_APP_HomePage}user/send/send-payment?orderId=${orderId}`
            // navigate(`${process.env.REACT_APP_HomePage}/user/send/send-payment?orderId=${orderId}`,{ replace: true })
        }
        if (orderType === "Purchase") {
            window.location.href = `${process.env.REACT_APP_HomePage}user/buy/payment?orderId=${orderId}`
            // navigate(`${process.env.REACT_APP_HomePage}/user/buy/payment?orderId=${orderId}`,{ replace: true })
        }
        if (orderType === "Islander") {
            window.location.href = `${process.env.REACT_APP_HomePage}user/islander/islander-payment?orderId=${orderId}`
            // navigate(`${process.env.REACT_APP_HomePage}/user/islander/islander-payment?orderId=${orderId}`, { replace: true })
        }
    }

    return (
        <div>
            {loader ? (
                <div className="loader2-container3">
                    <div className="loader2 paypalLoader"></div>
                    <p>Please Wait...</p>
                </div>
            ) : (
                <>
                    {!errorMessage ? (
                        <Container maxWidth="xl" className='homeContainer'>
                            <Typography variant='h4' className='color1 font-bold' sx={{ pb: 3 }}>Review Payment</Typography>
                            <Grid container className='paymentBox'>
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='cartColLeft paymentCol paymentMainBox'>
                                    <PaymentAddress address1={address1} address2={address2} address3={address3} type={type} page="paypal" />
                                    <Grid item xs={12} sm={12} md={12} lg={11.7} xl={11.7}>
                                        <Box className="confirm_payment_box">
                                            <Box>
                                                <Button
                                                    type="button"
                                                    className="b1 b3"
                                                    onClick={handlePayment}
                                                >
                                                    Pay Now
                                                </Button>
                                                <Button
                                                    type="button"
                                                    className="b1 b4"
                                                    onClick={cancelPayment}
                                                    sx={{ ml: 3 }}
                                                >
                                                    Cancel Payment
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className=''>
                                    <Box className='checkoutCartInfoBox cartTotalTable'>
                                        <Box sx={{ p: 2 }} className={classes.totalHeader} align="center">
                                            <Typography className='font-20 color2 font-bold'>Your Order</Typography>
                                        </Box>
                                        <Box sx={{ p: 3, pt: 0, pb: 4 }}>
                                            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                                                <Table aria-label="Total table" sx={{ mt: 2 }}>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell scope="row">
                                                                <Typography className='font-15 font-bold color2'>Product</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {/* <Typography sx={{ fontWeight: "bold" }}>SubTotal</Typography> */}
                                                            </TableCell>
                                                        </TableRow>

                                                        {(cartInfo && cartInfo.length > 0) && (
                                                            <>
                                                                {cartInfo.map((cart, index) =>
                                                                    <TableRow key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell scope="row">
                                                                            <Typography className='font-15 font-bold color1'>{cart.product.name}</Typography>
                                                                            <Box sx={{ display: "flex", gap: "40px" }}>
                                                                                <Typography className='font-15 font-bold color1'>Size: {cart.product.size}</Typography>
                                                                                <Typography className='font-15 font-bold color1'>Qty: {cart.quantity}</Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Typography className='font-15 font-bold color1'>${(FormatPrice(cart.quantity * cart.product.discountedPrice))}</Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}

                                                            </>
                                                        )}

                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell scope="row">
                                                                <Typography className='font-15 font-bold color2'>Shipping & Handling Cost</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography className='font-15 font-bold color2'>${FormatPrice(deliveryCharge)}</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell scope="row">
                                                                <Typography className='font-15 font-bold color2'>Tax({taxPercentage} %)</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography className='font-15 font-bold color2'>${FormatPrice(taxValue)}</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        {(insurance && insurance !== 0) && (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell scope="row">
                                                                    <Typography className='font-15 font-bold color2'>Insurance</Typography>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Typography className='font-15 font-bold color2'>${FormatPrice(insurance)}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}

                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell scope="row">
                                                                <Typography className='font-20 font-bold color2'>Total</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography className='font-20 font-bold color2'>${FormatPrice(cartTotalPrice)}</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    ) : (
                        <Container maxWidth="xl" className='homeContainer'>
                            <div style={{ "textAlign": "center" }}>
                                <Typography variant='h4' className='color1 font-bold' sx={{ pb: 3 }}>Payment Failed Please try Again..</Typography>
                                <Button
                                    type="button"
                                    className="b1 b4"
                                    onClick={cancelPayment}
                                    sx={{ ml: 3 }}
                                >
                                    Try again
                                </Button>
                            </div>

                        </Container>
                    )}
                </>
            )}


        </div>
    );
}

export default PaypalPayment;
