import { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import { Label } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Request from '../customUtils/Http/Request';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Njq0FINevRiy9hIzn9dDVerXRCLVIViDbDMokLHdFwAin6f70eM3EiOJA0co7IOjay1kQnNbnQYIBdEpjtruPbD00jpKfgNvO');

const countries = [
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'CU', label: 'Cuba', phone: '53' },
];

let CheckoutForm = ({ setOrderIdElement }) => {


    const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = useState(null);
    const [type, setType] = useState("");
    const [cartInfo, setCartInfo] = useState([]);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [clientSecret, setClientSecret] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [formData, setFormData] = useState({})


    let login = checkLogin()


    let checkAddress = (e) => {
        var checkBox = document.getElementById("myCheck");
        // var textShip = document.getElementById("ShippingAd");
        // var textBill = document.getElementById("BillingAd");
        var shippingBox = document.getElementById("shippingBox");
        if (checkBox.checked === true) {
            //   textShip.value = textBill.value; 
            shippingBox.style.display = "none";
        } else {
            shippingBox.style.display = "block";
        }
    }

    async function saveAddress(shippingAddress, billingAddress, billingBoolean, orderId) {

        // let response = await Request.post("order/saveAddress", {
        //     "shippingAddress": {
        //       "address1": "motera",
        //       "address2": "",
        //       "city": "ahmedabad",
        //       "state": "gujarat",
        //       "zip": "380001",
        //       "country": "india"
        //     },
        //     "billingAddress": {
        //       "address1": "motera 2",
        //       "address2": "",
        //       "city": "ahmedabad 2",
        //       "state": "gujarat",
        //       "zip": "380001",
        //       "country": "india"
        //     },
        //     "billingBoolean": 0,
        //     "orderId":orderId
        //   } , true);

        //   console.log(response,'response');

        // const response = await fetch(baseURL + "/order/saveAddress", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        //   body:  JSON.stringify({
        //     "shippingAddress": {
        //       "address1": "motera",
        //       "address2": "",
        //       "city": "ahmedabad",
        //       "state": "gujarat",
        //       "zip": "380001",
        //       "country": "india"
        //     },
        //     "billingAddress": {
        //       "address1": "motera 2",
        //       "address2": "",
        //       "city": "ahmedabad 2",
        //       "state": "gujarat",
        //       "zip": "380001",
        //       "country": "india"
        //     },
        //     "billingBoolean": 0,
        //     "orderId":orderId
        //   })
        // });
        // const { message } = await response.json();
        // console.log(message);
    }

    useEffect(() => {
        (async () => {
            let data = await Request.get("cart", [], true);
            {
                if (data) {
                    setCartInfo(data.cartItems);
                    setCartTotalPrice(data.totalCartPrice);
                }

            }
        })();
    }, []);

    // const handleSubmit = async (event) => {
    //     // We don't want to let default form submission happen here,
    //     // which would refresh the page.
    //     event.preventDefault();

    //     if (!stripe || !elements) {
    //         // Stripe.js hasn't yet loaded.
    //         // Make sure to disable form submission until Stripe.js has loaded.
    //         return;
    //     }
    //     let d =  saveAddress("shippingAddress", "billingAddress", 0, "123");


    //     const { error } = await stripe.confirmPayment({
    //         //`Elements` instance that was used to create the Payment Element
    //         elements,
    //         confirmParams: {
    //             return_url: 'https://example.com/order/123/complete',

    //         },
    //     });


    //     if (error) {
    //         // This point will only be reached if there is an immediate error when
    //         // confirming the payment. Show error to your customer (for example, payment
    //         // details incomplete)
    //         setErrorMessage(error.message);
    //     } else {
    //         // Your customer will be redirected to your `return_url`. For some payment
    //         // methods like iDEAL, your customer will be redirected to an intermediate
    //         // site first to authorize the payment, then redirected to the `return_url`.
    //     }
    // };


    // const handleSubmit = async (event) => {
    //     // We don't want to let default form submission happen here,
    //     // which would refresh the page.
    //     event.preventDefault();

    //     if (!stripe || !elements) {
    //       // Stripe.js hasn't yet loaded.
    //       // Make sure to disable form submission until Stripe.js has loaded.
    //       return;
    //     }

    //     let d = await saveAddress("shippingAddress", "billingAddress", 0 ,"123");


    //     const {error} = await stripe.confirmPayment({
    //       //`Elements` instance that was used to create the Payment Element
    //       elements,
    //       confirmParams: {
    //         return_url: 'http://localhost:3000/order-detail?orderId='+setOrderIdElement.orderId,    
    //       },
    //     });


    //     if (error) {
    //       // This point will only be reached if there is an immediate error when
    //       // confirming the payment. Show error to your customer (for example, payment
    //       // details incomplete)
    //       setErrorMessage(error.message);
    //     } else {
    //       // Your customer will be redirected to your `return_url`. For some payment
    //       // methods like iDEAL, your customer will be redirected to an intermediate
    //       // site first to authorize the payment, then redirected to the `return_url`.
    //     }
    //   };


    return (
        <>
            {login == true ? (
                <>

                    <Header />
                    <Box className="cartContainer">
                        <Box className="cartBox">
                            <Container maxWidth="xl">
                                <Box>
                                    <Typography variant='h3' sx={{ pb: 2 }}>Checkout</Typography>
                                    <Box>Home &gt; Checkout</Box>
                                </Box>
                            </Container>
                        </Box>
                        <Box sx={{ pt: 8, pb: 8, backgroundColor: "#ffffff" }}>
                            <Container maxWidth="xl">
                                <Box className='stepperGrid' sx={{ pb: 6 }}>
                                    <Grid container xs={12} sm={8} md={4} lg={4} xl={4} columnSpacing={{ xs: 1, sm: 2, md: 2 }} sx={{ justifyContent: "center", textAlign: "center", mx: 'auto !important' }}>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box className="stepperList active">
                                                    {/* <Typography> */}
                                                    <CheckIcon fontSize="small"></CheckIcon>
                                                    {/* </Typography> */}
                                                </Box>
                                                <Typography>Cart</Typography>
                                                <ChevronRightIcon fontSize="small"></ChevronRightIcon>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box className="stepperList active">
                                                    <Typography>2</Typography>
                                                </Box>
                                                <Typography>Checkout</Typography>
                                                <ChevronRightIcon fontSize="small"></ChevronRightIcon>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box className="stepperList">
                                                    <Typography>3</Typography>
                                                </Box>
                                                <Typography>Order</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box align="center" sx={{ p: 2, pt: 3, pb: 3, border: "1px solid #BCBCBC" }}>
                                    <Typography>Returning Customer? <Link to="/login" className='loginLink'>Click here to login</Link></Typography>
                                </Box>
                                <Row className='formRow' maxWidth="lg">
                                    <Col sm={12} xs={12} md={6} lg={6} xl={6} className='formColumn'>
                                        <Typography align="left" variant='h5' sx={{ pb: 2, color: "#033D1E", fontWeight: "bold" }}>Billing Details</Typography>
                                        {/* <form className='checkoutForm' onSubmit={handleSubmit}> */}
                                        <form className='checkoutForm'>

                                            <Form.Group controlId="formBasicSelect">
                                                <Form.Label>Select Country</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={type}
                                                    onChange={e => {
                                                        setType(e.target.value);
                                                    }}
                                                    required
                                                >
                                                    <option value="DICTUM">Jamaica</option>
                                                    <option value="CONSTANCY">Haiti</option>
                                                    <option value="COMPLEMENT">Cuba</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Billing Address</Form.Label>
                                                <Form.Control type="text" required placeholder="Address" />
                                            </Form.Group>
                                            <Form.Group>
                                                <input type="checkbox" id="myCheck" onClick={checkAddress}></input>
                                                <label htmlFor='myCheck' className='checkLabel'>Click here to copy Billing address to Shipping address</label>
                                            </Form.Group>
                                            <Form.Group id="shippingBox">
                                                <Form.Label>Shipping Address</Form.Label>
                                                <Form.Control type="text" placeholder="Address" required />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Province</Form.Label>
                                                <Form.Control type="text" required placeholder="Province" />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>City / Town</Form.Label>
                                                <Form.Control type="text" required placeholder="City/Town" />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Postal Code</Form.Label>
                                                <Form.Control type="text" required placeholder="Postal-code" />
                                            </Form.Group>
                                            <PaymentElement />
                                            <Form.Group>
                                                <Button type="submit" disabled={!stripe} className='submitBtn submitBtn2 cartBtn cartBtn2'>Pay Now</Button>
                                            </Form.Group>

                                            {errorMessage && <div>{errorMessage}</div>}
                                        </form>
                                    </Col>
                                    <Col sm={12} xs={12} md={6} lg={6} xl={6} className='formColumn'>
                                        <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 3, backgroundColor: '#F5F5F5', borderRadius: '10px' }}>
                                            <Typography align="center" variant='h6' sx={{ color: "#033D1E", fontWeight: "bold" }}>Your Order</Typography>
                                            <TableContainer component={Paper} sx={{ backgroundColor: "#F5F5F5", boxShadow: "none" }}>
                                                <Table aria-label="Total table" sx={{ mt: 2 }}>
                                                    <TableBody>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ fontWeight: "bold" }}>Product</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography sx={{ fontWeight: "bold" }}>SubTotal</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        {(cartInfo && cartInfo.length>0) && (
                                                            <>
                                                                {cartInfo.map((cart, index) =>
                                                                    <TableRow key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            <Typography sx={{ fontWeight: "bold" }}>Empty Barrel &#10005; {cart.quantity}</Typography>
                                                                            <Typography sx={{ fontWeight: "bold" }}>Size: {cart.barrel.size}</Typography>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Typography sx={{ fontWeight: "bold" }}>${cart.barrel.price}</Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </>
                                                        )}


                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ fontWeight: "bold" }}>Total</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography variant='h6' sx={{ fontWeight: "bold" }}>${cartTotalPrice}</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Box>
                    <Footer />

                </>
            )
                : (
                    <>
                        <Login />
                    </>
                )}
        </>
    )
}

export default CheckoutForm;