import React, { useState, useEffect } from 'react';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';

export default function Faq() {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>
            <Header />
                <Box className='orderContainer orderBoxContainer' sx={{pt: 10}} >
                    <Box className='middle_block faq_block' textAlign="center">
                        <Box sx={{mb: 2}} align='left'>
                            <Typography className='color2 font-bold font-45'>FAQs</Typography>
                        </Box>
                        <Box sx={{mt: 5}}>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{pl:3, pr: 3}}
                                >
                                  <Typography className='font-20 font-bold color4'>How do I sign up?</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{pl:3,pr:3, pt: 0}}>
                                    <Box align='left'>
                                        <Typography className='font-20 font-medium color1' sx={{pb: 1}}>Signing up is very easy!</Typography>
                                        <ul>
                                            <li className='mb-2'>Click the sign in button at the top right-hand side of the homepage.
                                                <Box sx={{p: 2}}>
                                                    <img src="/assets/img/faq/faq_img1.png" alt='sign_up' className=''/>
                                                </Box>    
                                            </li>
                                            <li className='mb-2'>Below the log in button click on “sign up”.
                                                <Box sx={{p: 2}}>
                                                    <img src="/assets/img/faq/faq_img2.png" alt='sign_up' className=''/>
                                                </Box>   
                                            </li>
                                            <li className='mb-2'>After clicking sign up, fill in your information and then click on create an account.
                                                <Box sx={{p: 2}}>
                                                    <img src="/assets/img/faq/faq_img3.png" alt='sign_up' className=''/>
                                                </Box>   
                                            </li>
                                        </ul>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    sx={{pl:3, pr: 3}}
                                >
                                <Typography className='font-20 font-bold color4'>How to shop online in Canada from the Caribbean?</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{pl:3,pr:3, pt: 0}} align='left'>
                                    <Box>
                                        <Typography className='font-16 color1 pb-1'>Shopping online in Canada is just as easy as shopping anywhere else in the world. Most Canadian websites use the domain of .ca rather than
                                        .com.</Typography>
                                        <Typography className='font-16 color1 pb-3'>For example, Walmart.ca, Shein.ca, Amazon.ca etc. However, in a few instances, some stores utilize .com, such as sephora.com.</Typography>
                                        <Typography className='font-16 color1 pb-1'>Please ensure that you are using the Canadian version of the stores and if you have any questions do not hesitate to contact our customer care.</Typography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    sx={{pl:3, pr: 3}}
                                >
                                <Typography className='font-20 font-bold color4'>How to upload your invoices after shopping?</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{pl:3,pr:3, pt: 0}} align='left'>
                                    <Typography className='font-16 color1 pb-3'>In other to proceed with your islander box process, it’s imperative to add your invoices so that the consolidation of your goods can begin. You can follow the prompts below to add your invoices in just a few simple steps:</Typography>
                                    <Typography className='font-16 color1 pb-3'>Click <a href="">here (link to acceptable invoices for uploading)</a> to ensure that your invoices are uploaded accurately per our guidelines.</Typography>
                                        <ol>
                                            <li className='mb-2'>Enter the recipient's address then click next.
                                                <Box sx={{p: 2}}>
                                                    <img src="/assets/img/faq/faq_img4.png" alt='sign_up' className=''/>
                                                </Box>
                                            </li>
                                            <li className='mb-2'>Select the website that you shopped on from the drop down menu</li>
                                            <li className='mb-2'>Enter the tracking number provided by the vendor</li>
                                            <li className='mb-2'>Enter the number of packages</li>
                                            <li className='mb-2'>Select “upload Invoice” and upload the respective invoice for this order. See here for guidelines.</li>
                                            <li className='mb-2'>Add additional rows for multiple invoices if needed</li>
                                            <li className='mb-2'>Hit create Order(Please write #s 2-7 on the following image to display the steps)
                                                <Box sx={{p: 2}}>
                                                    <img src="/assets/img/faq/faq_img5.png" alt='sign_up' className=''/>
                                                </Box>
                                            </li>
                                        </ol>
                                        <Typography className='font-16 color1 pb-1'>Congratulations! Now that you have successfully uploaded your invoice(s), upon receipt of all your orders you will be notified of the status of your order at every step and consolidation will begin.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                    sx={{pl:3, pr: 3}}
                                >
                                    <Typography className='font-20 font-bold color4'>How to pack a barrel efficiently?</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{pl:3,pr:3, pt: 0}} align='left'>
                                    <Typography className='font-16 color1 pb-3'>Barrel shipping is one of the most functional methods for shipping various items to other parts of the world and is a customary means for shipping large amounts of personal goods and household products to the caribbean.</Typography>
                                    <Typography className='font-16 color1 pb-3'>The efficacy of your barrel will depend largely on how you are able to successfully pack your shipment and maximize your barrel space.</Typography>
                                    <Typography className='font-16 color1 pb-3'>Barrel packing takes tremendous amounts of time and patience but with these tips, 
                                    we can help you uncomplicate what is seen as a daunting task.Whether it’s your first time packing a barrel or you regard 
                                    yourself as a seasoned veteran,here are some tips and tricks which can be essential for your barrel packing process:</Typography>
                                    <ol type="a">
                                            <li className='mb-2'>Get proper packing area: If possible, do not pack barrels in the basement as it's more difficult to manage during the pick up process. Pack close to the door but away from hard-wood or other areas that are prone to damage during pick-ups.</li>
                                            <li className='mb-2'>Sort Items: If packing multiple barrels then pack cleaning products, chemicals, toiletries, and tinned goods in one barrel and other items in the second barrel. If packing one barrel, pack detergent and chemical at base of the barrel to avoid contamination to other food items which should be packed towards the top.</li>
                                            <li className='mb-2'>Layer Items: Barrels should be packed properly to avoid spillage of products into food items and the transfer of smell/taste.</li>
                                            <li className='mb-2'>Fragile Items: Fragile items should be wrapped properly in items such as clothing, bubble wraps or towels. For delicate food items such as cereal and snacks it should be packed at the top to avoid damage to the item.</li>
                                            <li className='mb-2'>Heavy and Bulky Items: Pack heavy and bulky items at the bottom of the barrel. This creates a base for the other items to be upon. Packing heavy items towards the top will cause damage to the items below.</li>
                                            <li className='mb-2'>Small Items: Items such as toothpaste, candy or even snacks can be pushed into gaps in the barrels to utilize every available space in the barrel.</li>
                                            <li className='mb-2'>Soft Items: Pack softer items like paper towels etc towards the sides of the barrels or at the top.</li>
                                            <li className='mb-2'>Chemicals/Detergents/Soaps: These items can be packed in other barrels away from food products if possible. If not, pack at the bottom of the barrel and seal these items in air- tight containers or bags to avoid contamination with other food items ESPECIALLY RICE (You do not want IRISH SPRING TASTING RICE OR FLOUR)</li>
                                            <li className='mb-2'>Line top with Bags: Usually, when customs have gone through the barrels the items may no longer fit. Bags at the top will make it easier for whomever is clearing the barrels back home.</li>
                                        </ol>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header"
                                    sx={{pl:3, pr: 3}}
                                >
                                    <Typography className='font-20 font-bold color4'>List Of Prohibited Items</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{pl:3,pr:3, pt: 0}} align='left'>
                                    <ul>
                                        <li className='mb-2'>Alcoholic beverages</li>
                                        <li className='mb-2'>Animal skins (non-domesticated)</li>
                                        <li className='mb-2'>Articles of exceptional value (ie. works of art, antiques, precious stones, gold and silver)</li>
                                        <li className='mb-2'>Dangerous goods/Hazardous materials</li>
                                        <li className='mb-2'>Firearms</li>
                                        <li className='mb-2'>Furs</li>
                                        <li className='mb-2'>Human or animal remains or parts</li>
                                        <li className='mb-2'>Illegal Items</li>
                                        <li className='mb-2'>Ivory and ivory products</li>
                                        <li className='mb-2'>Live animals</li>
                                        <li className='mb-2'>Money or negotiable items</li>
                                        <li className='mb-2'>Perishable goods</li>
                                        <li className='mb-2'>Plants</li>
                                        <li className='mb-2'>Pornographic materials</li>
                                        <li className='mb-2'>Seeds</li>
                                        <li className='mb-2'>Tobacco and tobacco products</li>
                                        <li className='mb-2'>Counterfeit money</li>
                                        <li className='mb-2'>Any property obtained by crime</li>
                                    </ul>
                                    <Typography className='font-16 color1 pb-1'>Isle Of Freight advises against packing FRAGILE ITEMS.</Typography>
                                    <Typography className='font-16 color1 pb-1'>Fragile items include anything that can be easily broken, from glassware to antiques and more. These delicate items can be made of any material, including crystal and ceramic, and need extra attention before shipping. Broken items are NOT the responsibility of Isle of Freight.</Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                    sx={{pl:3, pr: 3}}
                                >
                                    <Typography className='font-20 font-bold color4'>Acceptable invoices for uploading</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{pl:3,pr:3, pt: 0}} align='left'>
                                    <Typography className='font-16 color1 pb-1'>At Isle of Freight, we understand that every transaction is unique, and our goal is to ensure that your shipment is processed accurately and efficiently. To achieve this, we kindly request that the following essential information be present on the invoice uploaded for your islander box order:</Typography>
                                    <ol className='pt-4 pb-2'>
                                        <li className='mb-2'>Item Details: The detailed list of the items you've ordered, complete with product names, descriptions, and quantities.</li>
                                        <li className='mb-2'>Unit Price and Total Amount: Clearly stated cost per unit and the total amount for each item you've purchased.</li>
                                        <li className='mb-2'>Shipping Information: The full shipping address. This should be your islander address as provided in your customer dashboard.</li>
                                        <li className='mb-2'>Invoice Date: The date of when the invoice was issued should be visible.</li>
                                        <li className='mb-2'>Vendor Information: Include the name and contact details of the company or individual that issued the invoice.</li>
                                    </ol>
                                    <Typography className='font-16 color1 pb-1'>With these key details in mind, you can choose between two hassle-free methods for submitting your invoice information:</Typography>
                                    <Typography className='font-18 color1 pb-2 pt-2 font-bold'>Option 1: Original Invoice from Vendor</Typography>
                                    <Typography className='font-16 color1 pb-1'>If applicable, you can simply upload the original invoice received from your vendor. This option provides a direct and accurate representation of your purchase.</Typography>
                                    <Typography className='font-18 color1 pb-2 pt-2 font-bold'>Option 2: Screenshot of Order Summary</Typography>
                                    <Typography className='font-16 color1 pb-1'>Alternatively, you can capture a screenshot of the order summary provided in your email or account. Just ensure that all required information mentioned above is clearly visible.</Typography>
                                    <Typography className='font-16 color1 pb-1 pt-2'>By offering these flexible options, we aim to accommodate your preferred way of sharing invoice details, making the process smoother and more convenient for you. Should you have any questions or require further assistance, our dedicated support team is always available to guide you through the process.</Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>
            <Footer />
        </>
    );
}

