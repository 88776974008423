import React, { useState, useEffect, useRef } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
// import ProductCategoriesTable from "./ProductCategoriesTable";
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancyList } from "../../redux/helpCenterSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Editor } from '@tinymce/tinymce-react';
import Cookies from 'universal-cookie';
import CareerVacancyTable from "./careerVacancyTable";

const cookies = new Cookies();

function CareerVacancyList() {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
  const [isCustom, setIsCustom] = useState(false);
  const editorRef = useRef(null);
	const [data, setData] = useState({
		"title": '',
    "status" : true,
    "description" : '',
    "expireOn" : '',   
	});
    const [error, setError] = useState('');

	const vacancy = useSelector((state) => state.helpCenter.vacancyList);
  

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};


      const handleFieldChange = (field, value) => {
		setData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

    const handleEditorChange = (content, editor) => {
        setData((prevData) => ({
			...prevData,
			description: content,
		}));
      };

      const handleVisibilityChange = (e) => {
        const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setData((prevData) => ({ ...prevData, status: newVisibility }));
        };

	  const handleSubmit = async (e) => {
        e.preventDefault();
          
        if (!data.title || !data.description || !data.expireOn) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.post('admin/careers', data, true);
			if (response.message === "Success") {
				setOpen(false)
        setIsCustom(false)
				dispatch(fetchVacancyList())
        setData({
          "title": '',
          "status" : true,
          "description" : '',
          "expireOn" : '', 
        });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}

        setError('');
	  };

	useEffect(() => {
		dispatch(fetchVacancyList());
	}, [dispatch]);

  const handleLink = (editor) => {
    const url = prompt('Enter the URL for the link:');
    if (url) {
        editor.execCommand('mceInsertLink', false, url);
    }
};

    // const uploadImage = (blobInfo) => {
    //     return new Promise((resolve, reject) => {
    //       let accessToken = cookies.get('accessToken', { path: '/' });
    //       const formData = new FormData();
    //       formData.append('file', blobInfo.blob(), blobInfo.filename());
          
    //       const headers = {
    //         'Authorization': `Bearer ${accessToken}`,
    //     };

    //     //  fetch('https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/helpCentre/uploadImageForHelpCentre', {
    //       fetch(`${process.env.REACT_APP_DatabaseServer}admin/helpCentre/uploadImageForHelpCentre`, {
    //         method: 'POST',
    //         headers: headers,
    //         body: formData
    //       })
    //         .then(response => {
    //           if (response.ok) {
    //             return response.json();
    //           } else {
    //             throw new Error('Image upload failed');
    //           }
    //         })
    //         .then(data => {
    //           resolve(data.url);
    //         })
    //         .catch(error => {
    //           console.error('Error uploading image:', error);
    //           reject('Error uploading image');
    //         });
    //     });
    // };

	return (
		<Box className="adminInnerPage productCategories">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
					Vacancy List
				</Typography>

				<Button
					variant="primary"
					color="primary"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Vacancy
				</Button>

				<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					{/* <form onSubmit={handleSubmit}> */}
					<DialogTitle className="admin-modal__title-container">
						Add Vacancy
						<Button color="primary" className="btn" onClick={handleSubmit}>
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form add-category-form">
								<div className="row add-product-form__row">
                                    <Typography className="font-15 font-600" style={{ color: 'red' }}>{error}</Typography>

                                    <Form.Group controlId="title" className="shipping-price-form__field">
                                      <Form.Label>Title</Form.Label>
                                      <Form.Control
                                          type="text"
                                          value={data.title}
                                          placeholder="Enter Title"
                                          onChange={(e) => handleFieldChange("title", e.target.value)}
                                          className="questionPlaceholder"
                                          required
                                      />
                                    </Form.Group>

                                  

                                    <Form.Group controlId="description" className="shipping-price-form__field">
                                    <Form.Label>Description</Form.Label>
                                    <Editor
                                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            initialValue='<p>Enter description about vacancy...</p>'
                                            init={{
                                              plugins: 'image lists link',
                                              toolbar: 'bold italic underline undo redo | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                                              // menubar: 'insert',
                                              automatic_uploads: true,
                                              // images_upload_handler: uploadImage,
                                              // links_upload_handler: uploadImage,
                                              convert_urls: false,
                                              default_link_target: '_blank',
                                              link_assume_external_targets: true,
                                              link_context_toolbar: true,
                                              link_default_protocol: 'https',
                                              urlconverter_callback: function(url, node, on_save, name) {
                                                // Prevent conversion of & to &amp;
                                                return url.replace(/&amp;/g, '&');
                                              }
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Form.Group>

                                		<Form.Group
                                        controlId="productVisibility"
                                        className="col-md-6 add-category-form__field"
                                      >
                                        <Form.Label>Visibility</Form.Label>
                                        <div className="add-product-form__visibility">
                                          <Form.Check
                                            type="radio"
                                            label="Public"
                                            name="visibility"
                                            value="true"
                                            checked={data.status === true}
                                            onChange={handleVisibilityChange}
                                            
                                          />
                                          <Form.Check
                                            type="radio"
                                            label="Hide"
                                            name="visibility"
                                            value="false"
                                            checked={data.status === false}
                                            onChange={handleVisibilityChange}
                                          />
                                        </div>
                                      </Form.Group>

                                    <Form.Group controlId="expireOn" className="shipping-price-form__field">
                                    <Form.Label>Expiry Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={data.expireOn}
                                        onChange={(e) => handleFieldChange("expireOn", e.target.value)}
                                        className="questionPlaceholder"
                                        required
                                    />
                                    </Form.Group>                 
								</div>
							</Box>
						</Box>
					</DialogContent>
				</Dialog>
			</div>

			<div className="productListPage__container">
				<div className="order-search-filter product-search-filter">
				</div>

				<CareerVacancyTable/>
			</div>
		</Box>
	);
}

export default CareerVacancyList;
