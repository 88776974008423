import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function PrivacyPolicy() {
    const [open, setOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedProductEdit, setSelectedProductEdit] = useState(null);
    const [listData, setListData] = useState({
        "id":'',
        "title":'',
        "description": ''
    });
    const [editListData, setEditListData] = useState({
        "id":'',
        "title":'',
		"description": ''
	});

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleEditorChange = (content, editor) => {
        setEditListData((prevData) => ({
			...prevData,
			description: content,
		}));
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const editData = {
            "title": editListData.title,
            "description": editListData.description
        }
          
        try {
            // Fetch data from API
            const response = await Request.put(`admin/legalPolicy/${selectedProductEdit}`, editData , true);
            if (response.status === 200) {
                 
                setOpen(false)
                fetchPolicyDescription();
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const DatabaseResponseComponent = ({ htmlContent }) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        );
      };
    
    useEffect(() => {
        fetchPolicyDescription()
    }, []);

    const fetchPolicyDescription = async () =>{
        try {
            // Fetch data from API
            const response = await Request.get(`utils/legalPolicy`, [] , true);
            if (Object.keys(response).length!== 0) {
                 
                setListData({
                    id:response.id,
                    title:response.title,
                    description: response.description,
                })
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const openEditModal = async (id) => {
		setSelectedProductEdit(id);
		try {
			const response = await Request.get(`admin/legalPolicy/${id}`, [], true);
			if (response.length !== 0) {
				if (response) {
                     
					setOpen(true);
                    const newContentToAppend = " ";
					const updatedData = response.description + newContentToAppend;
                    setEditListData({
                        "id": response.id,
                        "title": response.title,
						"description" : updatedData
					})
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

    const uploadImage = (blobInfo) => {
        return new Promise((resolve, reject) => {
		let accessToken = cookies.get('accessToken', { path: '/' });
          const formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
		
		  const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

          fetch(`${process.env.REACT_APP_DatabaseServer}admin/legalPolicy/uploadLegalPolicyImg`, {
            method: 'POST',
			headers: headers,
            body: formData
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Image upload failed');
              }
            })
            .then(data => {
              resolve(data.url);
            })
            .catch(error => {
              console.error('Error uploading image:', error);
              reject('Error uploading image');
            });
        });
    };

    return (
        <Box className="adminInnerPage productCategories">
            <div className="title-button__container">
                <Typography variant="h2" className="adminInnerPage__title">
                    Legal & Privacy Policies
                </Typography>

                <Button
                    variant="primary"
                    color="primary"
                    className="btn btn-secondary"
                    onClick={() => openEditModal(listData.id)}
                >
                    Edit
                </Button>
            </div>

            <div className="productListPage__container">
                <Box className="order-table-container product-categories-table-container">
                    <Box>
                        <DatabaseResponseComponent htmlContent={listData.description} />
                    </Box>

                    {/* <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <p>Are you sure you want to delete this Island?</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => productDelete(selectedProduct)} color="primary">
                                OK
                            </Button>
                            <Button onClick={closeDeleteDialog} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog> */}

                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                        className="admin-modal admin-community-dialog admin-dialog"
                    >
                        <form onSubmit={handleSubmit}>
                            <DialogTitle className="admin-modal__title-container"	>
                                Edit Description
                                <Button color="primary" className="btn" type="submit">
                                    Save
                                </Button>
                            </DialogTitle>
                            <DialogContent sx={{ padding: "30px" }}>
                                <Box className="add-product-form__container">
                                    <Box className="edit-order-form add-product-form add-category-form">
                                        <div className="row add-product-form__row">
                                        <Form.Group controlId="answer" className="shipping-price-form__field">
                                        <Form.Label>Enter Description</Form.Label>
                                        <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                                value={editListData.description}
                                                init={{
                                                    plugins: 'image lists link autolink',
                                                    toolbar: 'bold italic underline undo redo | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image',
                                                    automatic_uploads: true,
                                                    images_upload_handler: uploadImage,
                                                    // convert_urls: false,
                                                    default_link_target: '_blank',
                                                    link_assume_external_targets: true,
                                                    link_context_toolbar: true,
                                                    link_default_protocol: 'https',
                                                    paste_preprocess: function(plugin, args) {
                                                        args.content += ' ';
                                                    },
                                                    urlconverter_callback: function(url, node, on_save, name) {
                                                        // Prevent conversion of & to &amp;
                                                        return url.replace(/&amp;/g, '&');
                                                    }
                                                
                                        }}
                                                onEditorChange={handleEditorChange}
                                            />
                                        </Form.Group>
                                        </div>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </form>
                    </Dialog>
                </Box>
            </div>
        </Box>
    );
}

export default PrivacyPolicy;
