import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import PickupLocationTable from "./PickupLocationTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchPickupAdminList } from "../redux/pickupLocationSlice";

function PickupLocations() {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		"city": "",
		"province": "",
		"status" : false
	});

	const pickupLocationList = useSelector((state) => state.pickupList.pickupListAdminLocation);

	useEffect(() => {
		dispatch(fetchPickupAdminList());
	}, []);

	useEffect(() => {
		dispatch(fetchPickupAdminList());
	}, [dispatch]);

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleFieldChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const [visibility, setVisibility] = useState("public");
	// const handleVisibilityChange = (event) => {
	// 	setVisibility(event.target.value);
	// };

	const handleVisibilityChange = (e) => {
        const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setFormData((prevData) => ({ ...prevData, status: newVisibility }));
    };

	const handleSubmit = async (e) => {
		  
		e.preventDefault();
		try {
			// Fetch data from API
			const response = await Request.post('admin/pickup/locations/send', formData, true);
			if (response.message === "Success") {
				handleCloseDialog();
				dispatch(fetchPickupAdminList())
				setFormData({
					city: '',
					province: '',
					status: false
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	return (
		<Box className="adminInnerPage pickupLocationsPage">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
					Pick up Locations
				</Typography>

				<Button
					variant="primary"
					color="primary"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Location
				</Button>

				<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>

				<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Add Pick Up Location
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Form className="edit-order-form add-product-form add-category-form add-location-form">
								<div className="row add-product-form__row">
									<Form.Group
										controlId="city"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>City</Form.Label>
										<Form.Control
											type="text"
											placeholder="City"
											value={formData.city}
											onChange={(e) =>
												handleFieldChange(
													"city",
													e.target.value
												)
											}
											required
										/>
									</Form.Group>

									<Form.Group
										controlId="visibility"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Visibility</Form.Label>
										<div className="add-product-form__visibility">
											<Form.Check
												type="radio"
												label="Public"
												name="visibility"
												value="true"
												checked={formData.status === true}
												onChange={
													handleVisibilityChange
												}
											/>
											<Form.Check
												type="radio"
												label="Hide"
												name="visibility"
												value="false"
												checked={formData.status === false}
												onChange={
													handleVisibilityChange
												}
											/>
										</div>
									</Form.Group>
									<Form.Group
										controlId="province"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Province</Form.Label>
										<Form.Control
											type="text"
											placeholder="Province"
											value={formData.province}
											onChange={(e) =>
												handleFieldChange(
													"province",
													e.target.value
												)
											}
											required
										/>
									</Form.Group>
								</div>
							</Form>
						</Box>
					</DialogContent>
					</form>
				</Dialog>
			</div>

			<div className="productListPage__container">
				<PickupLocationTable list={pickupLocationList}/>
			</div>
		</Box>
	);
}

export default PickupLocations;
