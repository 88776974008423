import React, { useState } from 'react';
import { Popover, List, ListItem, ListItemText } from '@mui/material';

const StatusList = ({ anchorEl, onClose, statuses, onSelectStatus  }) => {

    const handleItemClick = (status) => {
        onSelectStatus(status.number);
        onClose(status);
      };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List>
        {(statuses && statuses.length>0) && statuses.map((status, index) => (
          <ListItem key={index} 
          onClick={() => handleItemClick(status)} >
            <ListItemText primary={status.text} className='popOverText'/>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default StatusList;