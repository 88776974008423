import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './css/custom.css';
import './css/responsive.css';
import './css/admin.css';

import { Routes, Route, Switch, Router, Navigate } from 'react-router-dom';
import Login from './components/user/login/login';
import Signup from './components/user/signUp/signup';
import Cookies from 'universal-cookie';
import ManageOrder from './components/product/buyProduct';

// import ProtectedRoute from './components/commonFunctions/protectedRoute';
import Cart from './components/checkout/cart';
import Order from './components/checkout/order';
import Checkout from './components/checkout/checkout';
import OrderDetail from './components/checkout/orderDetail';
import OrderHistory from './components/checkout/orderHistory';
import BuyProduct from './components/product/buyProduct';
import SendProduct from './components/product/sendProduct';
import Dashboard from './components/dashboard/dashboard';
import SendItemCart from './components/checkout/sendItemCart';
import SendProductCheckout from './components/checkout/sendProductCheckout';
import Home from './components/home/home';
import Structure from './components/commonComponents/structure';
import SendCheckout from './components/checkout/sendCheckout';
import SendPayment from './components/checkout/sendPayment';
import SendOrderDetail from './components/checkout/sendOrderDetail';
import IslanderStructure from './components/commonComponents/islanderBoxStructure';
import { checkLogin } from './components/commonFunctions/commonFunctions';
import SendBoxStructure from './components/commonComponents/sendBoxStructure';
import ComingSoon from './components/commonComponents/comingSoon';
import AboutUs from './components/home/aboutUs';
import Services from './components/home/services';
import BuyABarrel from './components/home/services/buy-a-barrel';
import ShipABarrel from './components/home/services/ship-a-barrel';
import ContactUs from './components/home/contactUs';
import Pricing from './components/home/pricing';
import ShippingEstimator from './components/dashboard/ShippingEstimator';
import Profile from './components/dashboard/Profile';
import Myaddresses from './components/dashboard/myaddresses';
import AdminBoxStructure from './components/commonComponents/adminBoxStructure';
import Faq from './components/home/faq';
import Community from './components/home/community';
import PrivacyPolicy from './components/home/PrivacyPolicy';
import ShipIslanderBox from './components/home/services/ship-islander-box';
import TermAndCondition from './components/home/termsandCondition';
import PackageItems from './components/home/services/packaging-items';
import HelpCenter from './components/home/helpCenter/helpCenter';
import DriverSignup from './components/user/signUp/driverSignup';
import DriverStructure from './components/commonComponents/driverStructure';
import HelpCenterCategory from './components/home/helpCenter/helpCenterCategory';
import Careers from './components/home/careers';
import CustomerReview from './components/home/customerReview';
import WeeklyDeals from './components/home/weeklyDeals';
import ForgotPassword from './components/user/forgotPassword';
import PasswordReset from './components/user/passwordReset';
import ConsentNotification from './components/commonFunctions/consentNotification';
import PaypalPayment from './components/commonFunctions/paypalPayment';
import EmailVerification from './components/user/signUp/emailVerification';
import HelpCenterQuestions from './components/home/helpCenter/helpCenterQuestions';
// import HelpCenterAnswer from './components/home/helpCenter/helpCenterAnswer';
import HelpCenterAnswers from './components/home/helpCenter/helpCenterAnswers';
import VacancyDetails from './components/home/vacancyDetails';
import AdminPasswordReset from './components/user/adminPasswordReset';
import UserStructure from './components/commonComponents/userStructure';
import PageNotFound from './components/commonComponents/pageNotFound';

const cookies = new Cookies();
const isLoggedIn = checkLogin();

function ProtectedRoute({ element: Element, ...rest }) {
  return (
    checkLogin() ? (
      <Element {...rest} />
    ) : (
      <Navigate to="/login" />
    )
  );
}
function App() {
  const [auth, setAuth] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <>
        <Routes>

          {/* Public route */}
          {/* Default Not Found route */}
          {/* <Route path="*" element={<Navigate to="/user" />} /> */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/user/buy/*" element={<Structure />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/signup/otp" element={<EmailVerification />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/password-reset" element={<PasswordReset />}></Route>
          <Route path="/admin-password-reset" element={<AdminPasswordReset />}></Route>
          <Route path="/driver/signup" element={<DriverSignup />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/brand-story" element={<AboutUs />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/services/buy-a-barrel" element={<BuyABarrel />}></Route>
          <Route path="/services/ship-a-barrel" element={<ShipABarrel />}></Route>
          <Route path="/services/ship-islander-box" element={<ShipIslanderBox />}></Route>
          <Route path="/services/packaging-items" element={<PackageItems />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/help-center" element={<HelpCenter />}></Route>
          <Route path="/weeklyDeals" element={<WeeklyDeals />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/careers/vacancy" element={<VacancyDetails />}></Route>
          <Route path="/customerReview" element={<CustomerReview />}></Route>
          <Route path="/help-center/category" element={<HelpCenterCategory />}></Route>
          <Route path="/help-center/answer" element={<HelpCenterAnswers />}></Route>
          <Route path="/help-center/question" element={<HelpCenterQuestions />}></Route>
          {/* <Route path="/help-center/article/:articlename" element={<HelpCenterCategory />}></Route>
          <Route path="/help-center/article/:categoryName/popular/:id" element={<HelpCenterCategory />}></Route> */}
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/community" element={<Community />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-and-conditions" element={<TermAndCondition />}></Route>
     
          {/* Private routes */}
    
          {/* <ProtectedRoute path="/user/islander/*" element={<IslanderStructure />}></ProtectedRoute>
          <ProtectedRoute path="/user/send/*" element={<SendBoxStructure />}></ProtectedRoute>
          <ProtectedRoute path="/admin/*" element={<AdminBoxStructure />}></ProtectedRoute>
          <ProtectedRoute path="/driver/*" element={<DriverStructure />}></ProtectedRoute>
          <ProtectedRoute path="/dashboard" element={<Dashboard />}></ProtectedRoute> 
          <ProtectedRoute path="/order-history" element={<OrderHistory />}></ProtectedRoute>
          <ProtectedRoute path="/shipping-estimator" element={<ShippingEstimator />}></ProtectedRoute>
          <ProtectedRoute path="/profile" element={<Profile />}></ProtectedRoute>
          <ProtectedRoute path="/my-addresses" element={<Myaddresses />}></ProtectedRoute> */}
           {/* Private routes */}
          <Route path="/user/islander/*" element={<ProtectedRoute element={IslanderStructure} />} />
          <Route path="/user/send/*" element={<ProtectedRoute element={SendBoxStructure} />} />
          <Route path="/user/customer/*" element={<ProtectedRoute element={UserStructure} />} />
          <Route path="/admin/*" element={<ProtectedRoute element={AdminBoxStructure} />} />
          <Route path="/driver/*" element={<ProtectedRoute element={DriverStructure} />} />
          <Route path="/user" element={<PageNotFound />} />
          <Route path="/admin" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />

        
          {/* <Route path="/dashboard" element={<Dashboard} />} />
          <Route path="/order-history" element={<OrderHistory} />} />
          <Route path="/shipping-estimator" element={<ShippingEstimator} />} />
          <Route path="/profile" element={<Profile} />} />
          <Route path="/my-addresses" element={<Myaddresses} />} />
          <Route path="/paypal-payment" element={<PaypalPayment} />} /> */}
          <Route path="/paypal-payment" element={<PaypalPayment />} />
        </Routes>

        <ConsentNotification />
    </>  
  );
}

export default App;
