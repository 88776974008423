import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";

const DriverForm = ({driver, type, profile}) => {
	const [driverInfo, setDriverInfo] = useState({
		driverName: '',
		phone: '',
		email: '',
		vehicleNo: '',
		vehicle: '',
		identityProof: "/assets/img/driver-placeholder.jpg",
		licence: "/assets/img/driver-placeholder.jpg"

});

	useEffect(() => {
		if(driver){
			setDriverInfo({
				driverName: driver.user?.fullName || '',
				phone: driver.user?.phone || '',
				email: driver.user?.email || '',
				vehicleNo: driver.vehicleRegNo || '',
				vehicle: driver.vehicle || '',
				identityProof: driver.driverIdentification === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driver.driverIdentification}`,
				licence: driver.driverLicense === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driver.driverLicense}`,
			});
		}
		
	}, [driver, type]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setDriverInfo((prevInfo) => ({
			...prevInfo,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<>
		<Form onSubmit={handleSubmit} className="driverform">
			{driver && driverInfo !== undefined ? (
				<div className="driverform__fields">
				<Form.Group controlId="driverName" className="driverform__field">
					<Form.Label>Driver Name</Form.Label>
					<Form.Control
						type="text"
						name="driverName"
						value={driverInfo.driverName}
						// onChange={handleInputChange}
						readOnly
					/>
				</Form.Group>

				<Form.Group controlId="phone" className="driverform__field">
					<Form.Label>Phone</Form.Label>
					<Form.Control
						type="text"
						name="phone"
						value={driverInfo.phone}
						// onChange={handleInputChange}
						readOnly
					/>
				</Form.Group>

				<Form.Group controlId="email" className="driverform__field">
					<Form.Label>Email ID</Form.Label>
					<Form.Control
						type="email"
						name="email"
						value={driverInfo.email}
						// onChange={handleInputChange}
						readOnly
					/>
				</Form.Group>

				<Form.Group controlId="vehicleNo" className="driverform__field">
					<Form.Label>Vehicle No.</Form.Label>
					<Form.Control
						type="text"
						name="vehicleNo"
						value={driverInfo.vehicleNo}
						onChange={handleInputChange}
					/>
				</Form.Group>

				<Form.Group controlId="vehicle" className="driverform__field">
					<Form.Label>Vehicle</Form.Label>
					<Form.Control
						type="text"
						name="vehicle"
						value={driverInfo.vehicle}
						onChange={handleInputChange}
					/>
				</Form.Group>

				<Form.Group controlId="identityProof" className="driverform__field">
					{/* <Form.Label>Identity Proof</Form.Label>

					<Avatar
						alt="License"
						src={driverInfo.identityProof}
						variant="rounded"
						sx={{ width: 200, height: 150 }}
						className="center-avatar"
					/> */}
				</Form.Group>

				<Form.Group controlId="license" className="driverform__field">
					<Form.Label>License</Form.Label>
					<Avatar
						alt="License"
						src={driverInfo.licence}
						variant="rounded"
						sx={{ width: 200, height: 150 }}
						className="center-avatar"
					/>
				</Form.Group>
				
				<Form.Group controlId="identityProof" className="driverform__field">
					<Form.Label>Identity Proof</Form.Label>

					<Avatar
						alt="License"
						src={driverInfo.identityProof}
						variant="rounded"
						sx={{ width: 200, height: 150 }}
						className="center-avatar"
					/>
				</Form.Group>
				
				{/* <Button variant="primary" type="submit">
					Submit
					</Button> */}
			</div>
			): 
			<>
			</>}
		</Form>
		</>
	);
};

export default DriverForm;
