import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Card, CardMedia } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { Carousel } from 'react-responsive-carousel';
import "slick-carousel/slick/slick.css"; // Import the carousel styles
import "slick-carousel/slick/slick-theme.css"; // Import the carousel theme styles
import Slider from 'react-slick';
import { CardActions, CardContent } from '@material-ui/core';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ProgressBar from '../commonComponents/progressbar';
import { useDispatch, useSelector } from "react-redux";
import { fetchPriorityProductCategories } from "../redux/priorityProductCategoriesSlice";
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
// import Masonry from '@mui/lab/Masonry';
// import MasonryItem from '@mui/lab/MasonryItem';


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "var(--primary-color2)",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "var(--primary-color8) !important"
    },
    activePath: {
        color: "var(--primary-color6) !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    cartIcon: {
        marginRight: "12px"
    },
    alignRight: {
        textAlign: "right"
    },
    priority:{
        width: "30px",
        height: "30px",
        border: "1px solid var(--primary-color13) !important",
        borderRadius: "50%",
        textAlign: "center",
        fontSize: "14px !important",
        color: "var(--primary-color1) !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "600 !important",
        cursor: "pointer",
        '&.active': {
            backgroundColor: "var(--primary-color2) !important" ,
            border: "1px solid var(--primary-color2) !important",
        },
    },
    priorityBox:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
    },
    productAdd: {
        backgroundColor: 'var(--primary-color13) !important',
        borderRadius: "0px !important",
        borderTopRightRadius: '5px !important',
        borderBottomRightRadius: '5px !important',
        minWidth: "26px !important",
        height: "32px !important",
      },
      productRemove: {
        backgroundColor: 'var(--primary-color13) !important',
        borderRadius: "0px !important",
        borderTopLeftRadius: '5px !important',
        borderBottomLeftRadius: '5px !important',
        minWidth: "26px !important",
        height: "32px !important",
      },
      quantityMain:{
        color: 'var(--primary-color1) !important',
        height: "32px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid var(--primary-color13) !important",
        borderRadius: "5px",
        margin: "10px 0px"
      },
      quantityInput:{
        padding: "0px 5px",
        color: 'var(--primary-color1) !important',
        fontSize: "16px !important",
        width: "35px",
        border: "none",
        textAlign: "center"
      },
      actionIcon:{
        fontSize: "16px !important",
        color: "var(--primary-color1) !important"
      },
      card:{
        border: "1px solid var(--primary-color13) !important",
        boxShadow: "none !important",
        borderRadius: "8px !important"
      },
      categoryDropdown:{
        maxWidth: "300px",
        padding: "10px 15px",
        border: "1px solid var(--primary-color13) !important",
        fontSize: "14px !important",
        color: "var(--primary-color1) !important",
        fontWeight: "600 !important"
      },
      '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
    '@media (max-width: 575px)': {
        alignRight: {
            textAlign: "left"
        },
    },

}));


const priorities = [1, 2, 3, 4, 5]; 
const fillPercentage = [100, 100, 100, 0];

function IslanderFillBox() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [itemList, setItemList] = useState([])
    const [expandedItems, setExpandedItems] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [activePriority, setActivePriority] = useState({});
    const [disabledPriorities, setDisabledPriorities] = useState([]);
    const [quantity, setQuantity] = useState({});
    const navigate = useNavigate();
    const [addedItemId, setAddedItemId] = useState(null); 
    const [btnEnabled, setBtnEnabled] = useState(true); 
    const [notPointerEvent, setNotPointerEvent] = useState("notPointerEvent"); 
    const [invoiceNo, setInvoiceNo] = useState(null); 
    const [selectedCategory, setSelectedCategory] = useState('');
    let [activePriorities, setActivePriorities] = useState({});

    const PriorityProductCategoriesList = useSelector((state) => state.priorityProductCategories.priorityProductCategories);

    const handlePriorityClick = (id, priority) => {
       

        let updatedActivePriority = { ...activePriority }
        let updatedDisabledPriorities = []
        for (const key in updatedActivePriority) {
            updatedDisabledPriorities.push(updatedActivePriority[key])
        }

        if (updatedActivePriority[id] === priority) {
            delete updatedActivePriority[id];
        }
        else if (!updatedDisabledPriorities.includes(priority)) {
            updatedActivePriority[id] = priority
        }

        // If priorities are fulled to four priorities then active 5th priority for all products

        itemList.forEach((p) => {
            let id = p.id
            if (updatedActivePriority[id] === 5) {
                delete updatedActivePriority[id];
            }
        })

        let length = Object.keys(updatedActivePriority).length

        if (length === 4) {
            itemList.forEach((p) => {
                let id = p.id
                if (!updatedActivePriority[id]) {
                    updatedActivePriority[id] = 5
                }
            })
        }

        setActivePriority(updatedActivePriority)
    };

      let incNum =(maxLimit,id)=>{
        setQuantity((prevQuantity) => {
            const prevQuantity1 = prevQuantity[id] || 0;
            const newQuantity = Math.min(prevQuantity1 + 1, maxLimit);
            return { ...prevQuantity, [id]: newQuantity };
          });
    };
    
    let decNum = (maxLimit,id) => {
        setQuantity((prevQuantity) => {
            const prevQuantity1 = prevQuantity[id] || 0;
            const newQuantity = Math.max(prevQuantity1 - 1, 0);
            return { ...prevQuantity, [id]: newQuantity };
          });
    }

    const addProduct = async (productId) => {
          
        setAddedItemId(productId);
        if(Object.keys(activePriority).length !== 0 && Object.keys(quantity).length !== 0){
            setBtnEnabled(false)
        }
        else{
            alert("Please select Priority and Quantity")
        }
        setTimeout(() => {
          setAddedItemId(null);
        }, 3000);

        const productQuantity = quantity[productId] || 0; // Get the quantity for the selected box
        const productPriority = activePriority[productId] || ''; // Get the priority for the selected box

           let productDetail = {
            'productId' : productId,
            'quantity': productQuantity,
            'priority': productPriority,
            'shipmentId': id
        }
        try {
            // Fetch data from API
            const response = await Request.post("islander/iAddItemsPriority/", productDetail , true);
            if (response.status === 200) {
                
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    // Custom CSS styles for the arrows
    const customArrowStyles = {
        display: 'block',   
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        fontSize: 24,
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '0px',
        borderRadius: '50%',
        cursor: 'pointer',
    };

    // Custom Next Arrow component
    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...customArrowStyles, right: 10 }}
                onClick={onClick}
            >
            </div>
        );
    };

    // Custom Previous Arrow component
    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...customArrowStyles, left: 10 }}
                onClick={onClick}
            >
            </div>
        );
    };

    // Slick carousel settings
    const sliderSettings = {
        dots: false, // Show dots (indicators)
        arrows: true, // Show navigation arrows
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,        // Enable autoplay
        // autoplaySpeed: 3000, 
        nextArrow: <CustomNextArrow />, // Custom Next Arrow component
        prevArrow: <CustomPrevArrow />, // Custom Previous Arrow component
    };

    useEffect(() => {
        fetchItemList();
    }, []);


	useEffect(() => {
		fetchCategoryList();
	}, []);

    useEffect(() => {
        fetchShipment();
    }, []);

    // Function to handle "Read more" and "Read less" toggle
    const handleToggleExpand = (itemId) => {

        setExpandedItems((prevExpandedItems) => {
            if (prevExpandedItems.includes(itemId)) {
                return prevExpandedItems.filter((id) => id !== itemId);
            } else {
                return [...prevExpandedItems, itemId];
            }
        });
    };

    const fetchItemList = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("utils/islander/listProducts/", [], true);
            if (response) {
                setItemList(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchShipment = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                setInvoiceNo(response.invoiceNo)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchCategoryList = async () => {
        try {
            const response = await Request.get("utils/getIslandCategories", [], true);
            if (response.length !== 0) {
                 
                setCategoryList(response)
            } else {
                console.error("Failed to fetch data from API");
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };


    const consolidateItems = async () => {
          
        try {
            // Fetch data from API
            const packStatus = {
                "status" : 4
            }
            const response = await Request.put(`admin/islander/iStatusUpdate/${id}`, packStatus , true);
            if (response.status === 200) {
                  
                // fetchData();
                 ;
                navigate('/user/islander/islander-order-status')
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const handleCategoryChange = (event) => {
        const selectedId = event.target.value;
        setSelectedCategory(selectedId);
        fetchFilteredProductList(selectedId)
    };

    const fetchFilteredProductList = async (id) => {
          
        if(id === "0"){
            fetchItemList()
        }
        else{
            try {
                // Fetch data from API
                const response = await Request.get(`utils/islander/getProductByCategory/`, [{"id" : id}], true);
                 
                if (response) {
                    setItemList(response)
                    //   
                    // setBarrelInfo(response.barrelList);
                    // setAccessoriesInfo(response.accessoriesList)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }
        
    };



    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Additional Items To Fill The Space In Shipment</Typography>
                 <Box>
                    <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Box className="gridInnerBlockLeft">
                    <Box>
                        {id && (
                            <p className='font-20 color2 font-bold'>Order: <span className='font-20 color3 font-medium'>{invoiceNo}</span></p>
                        )}
                    </Box>
                    <Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Form.Select aria-label="Default select example" className={classes.categoryDropdown} value={selectedCategory} 
                                        onChange={handleCategoryChange}>
                                        <option value="0">All</option>
                                        {categoryList && (
                                            <>
                                            {categoryList.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                            </>
                                        )}
                                      
                                    </Form.Select>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.alignRight}>
                                    <Button className='b1 b4'  disabled={btnEnabled} onClick={consolidateItems}>Consolidate Now</Button>
                                </Grid>
                            </Grid>
                        </Box>
                      
                        <Box sx={{pt: 3}}>
                            <Grid container spacing={2}>
                            {/* <Masonry className={classes.masonryContainer} columns={4} spacing={1}> */}
                                {(itemList && itemList.length>0) ? (
                                    <>
                                        {itemList.map((item,index) => (
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={item.id}>
                                                <Card className={classes.card}>
                                                    {item.images.length === 0 ? (
                                                        <Slider {...sliderSettings}>
                                                            <div className='priorityProductOuterBox'>
                                                                <CardMedia
                                                                    component="img"
                                                                    alt="No image Of Item Available"
                                                                    image="/assets/img/no_image_item.png"
                                                                    className='priorityProductListImg'
                                                                />
                                                            </div>
                                                        </Slider>
                                                    ) : (
                                                        <Slider {...sliderSettings}>
                                                            {(item.images && item.images.length>0) && item.images.map((image, index) => (
                                                                <div key={index} className='priorityProductOuterBox'>
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt={`Image ${index + 1}`}
                                                                        image={`${process.env.REACT_APP_DatabaseStatic}${image.img}`}
                                                                        className='priorityProductListImg'
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Slider>
                                                    )}

                                                    <CardContent>
                                                        <Typography className='font-18 color1 font-bold'>{item.name}</Typography>
                                                        <Typography className='font-14 color1'>${FormatPrice(item.price)}<span className='font-14 color3'> (Size : {item.size})</span></Typography>
                                                        <Typography className='font-13 color4'>Only {item.inStock} Available</Typography>
                                                        <Typography className='font-14 color1 font-bold'>Product Description:</Typography>
                                                        <Typography className={`font-13 color3 truncate-text productDescription ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>
                                                            {expandedItems.includes(item.id) ? item.description : `${item.description.slice(0, 35)}`}

                                                            {item.description.length > 35 && (
                                                                <span
                                                                    className="read-more-link font-13 color4 productDescription"
                                                                    onClick={() => handleToggleExpand(item.id)}
                                                                >
                                                                    {expandedItems.includes(item.id) ? ' Read less' : ' Read more...'}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                        
                                                        <Box className="flexBox">
                                                            <Typography className='color1 font-12 font-bold'>Qty :</Typography>
                                                            <Box>
                                                                <Box className={classes.quantityMain}>
                                                                    <Box onClick={() => decNum(item.inStock,item.id)}>
                                                                        <Button className={classes.productRemove}>
                                                                            <RemoveIcon className={classes.actionIcon}/>
                                                                        </Button>
                                                                    </Box>
                                                                    <input type="text" className={classes.quantityInput} value={quantity[item.id] || 0}></input>
                                                                    <Box onClick={() => incNum(item.inStock,item.id)}>
                                                                        <Button className={classes.productAdd}>
                                                                            <AddIcon className={classes.actionIcon}/>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="flexBox">
                                                            <Typography className='color1 font-12 font-bold'>Priority :</Typography>
                                                            <Box className={classes.priorityBox}>
                                                                {priorities && priorities.length> 0 && priorities.map((priority) => (
                                                                        <div
                                                                        key={priority}
                                                                        className={`
                                                                        ${activePriority[item.id] === priority ? 'active' : ''} 
                                                                        ${priority === 5 ? notPointerEvent : ""} 
                                                                        ${classes.priority} 
                                                                         `}
                                                                        value={priority}
                                                                        disabled = {priority === 5 ? true : false}
                                                                        onClick={() => handlePriorityClick(item.id, priority)}
                                                                        >
                                                                        {priority}
                                                                        </div>
                                                                    ))}
                                                            </Box>
                                                        </Box>
                                                        <Button className='b1' sx={{mt: 2}} id={item.id} onClick={() => addProduct(item.id)}>
                                                            <ShoppingCartOutlinedIcon className={classes.cartIcon}/>Add to Box
                                                        </Button>
                                                        {addedItemId === item.id && 
                                                            <Typography className='color4 font-14 mt-2 font-600'>Item Added</Typography>
                                                        }
                                                        
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                ):
                                (   
                                    <Box sx={{pt: 3, pl: 3}}>
                                        {/* <Box className="emptyProductBox"> */}
                                        <Typography className='font-20 color4 font-600'>No Product Available</Typography>
                                        {/* </Box>  */}
                                    </Box>   
                                )}
                            </Grid>
                        {/* </Box> */}

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default IslanderFillBox;
