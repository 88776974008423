import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Request from '../customUtils/Http/Request';
import Header from '../header/header';
import Footer from '../footer/footer';
import CMSFooter from '../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const Community = () => {
    const [listData, setListData] = useState({
        "description": ''
    });

    useEffect(() => {
        fetchCommunityDescription()
    }, []);

    const fetchCommunityDescription = async () =>{
        try {
              
            // Fetch data from API
            const response = await Request.get(`utils/community`, []);
            if (response.length !== 0) {
                 
                setListData({
                    description: response.description,
                })
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const DatabaseResponseComponent = ({ htmlContent }) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        );
      };

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className='MuiBox-root css-pxpt32'>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45'>Community</Typography>
                        </Col>
                    </Row>
                    <Box sx={{pt: 5, pb: 5}}>
                        <div className="row">    
                            <div className="MuiBox-root css-pxpt32">
                                <DatabaseResponseComponent htmlContent={listData.description} />
                            </div>
                        </div> 
                    </Box>
                </Box> 
            </Container>

            <CMSFooter/>
        <Footer />
        </>
    );
}

export default Community;
