import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, TableContainer, Table as MuiTable, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Col, Row, Form, Table } from 'react-bootstrap';
import Request from '../customUtils/Http/Request';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import { Login } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import StarRating from '../dashboard/starRating';
import { fetchOrderHistory } from '../redux/reviewSlice';

const columns = [
    { id: 'order#', label: 'Order#', minWidth: 120 },
    { id: 'order_type', label: 'Order Type', minWidth: 120 },
    {
        id: 'status',
        label: 'Status',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'subtotal',
        label: 'SubTotal',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'tax',
        label: 'Tax',
        minWidth: 100,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'total',
        label: 'Order Total',
        minWidth: 170,
        format: (value) => value.toFixed(2),
    },
    {
        id: 'tracking',
        label: 'Tracking',
        minWidth: 170,
        format: (value) => value.toFixed(2),
    },
    {
        id: 'bol',
        label: 'BOL',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'date',
        label: 'Date',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'review',
        label: 'Review',
        minWidth: 150,
        format: (value) => value.toLocaleString('en-US'),
    },
];

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    alignRight: {
        textAlign: "right"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: "25px",
        paddingTop: "30px"
    },
    orderInnerGrid: {
        padding: "30px",
        border: "1px solid lightgray",
        borderRadius: "10px",
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    imgBox: {
        width: "170px",
        height: "60px",
        float: "left"
    },
    flexEnd: {
        display: "flex",
        justifyContent: "end"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
        flexEnd: {
            justifyContent: "start",
            marginTop: "10px"
        },
        alignRight: {
            textAlign: "left"
        },
        addressTop: {
            marginTop: "15px"
        }
    },
    dialogInner: {
        padding: "30px 30px",
    },
    dialogTitle: {
        padding: "10px 0px !important",
        lineHeight: "30px !important"
    },
    dialogContent: {
        padding: "10px 0px !important",
    },
    dialogBtnBox: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "5px"
    },
    dialogClose: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "var(--primary-color5)",
        cursor: "pointer"
    },
    dialogBtn: {
        minWidth: "190px !important",
        padding: "10px 0px !important"
    },
    // '@media (max-width: 992px)': {
    //     dashboardInner: {
    //         padding: "10px !important",
    //     },
    // },
    // modalBox: {
    //     position: "relative"
    // }
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    minWidth: '50%',
    overflow: 'auto',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    color: 'var(--primary-color1)',
    // Define responsive styles
    '@media (max-width: 1100px)': {
        maxWidth: '80%', // Adjust as needed for smaller screens
        minWidth: '80%', // Adjust as needed for smaller screens// Limit the height on smaller screens if necessary
        p: 2, // Reduce padding on smaller screens if necessary
    },
    '@media (max-width: 767px)': {
        maxWidth: '95%', // Adjust as needed for smaller screens
        minWidth: '95%', // Adjust as needed for smaller screens
        maxHeight: '90vh', // Limit the height on smaller screens if necessary
        p: 2, // Reduce padding on smaller screens if necessary
    },
};

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '60%',
    minWidth: '50%',
    overflow: 'auto',
    maxHeight: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    color: 'var(--primary-color1)',
    // Define responsive styles
    '@media (max-width: 1100px)': {
        maxWidth: '80%', // Adjust as needed for smaller screens
        minWidth: '80%', // Adjust as needed for smaller screens// Limit the height on smaller screens if necessary
        p: 2, // Reduce padding on smaller screens if necessary
    },
    '@media (max-width: 767px)': {
        maxWidth: '95%', // Adjust as needed for smaller screens
        minWidth: '95%', // Adjust as needed for smaller screens
        maxHeight: '90vh', // Limit the height on smaller screens if necessary
        p: 2, // Reduce padding on smaller screens if necessary
    },
};

const OrderHistory = () => {
    const dialogRef = useRef(null);
    const dispatch = useDispatch();
    // const [orderDetail, setOrderDetail] = useState([]);
    const [orderId, setOrderId] = useState(null);
    const [invoiceNo, setInvoiceNo] = useState(null);
    const [productDetail, setProductDetail] = useState([]);
    const [priorityProductDetail, setPriorityProductDetail] = useState([]);
    const [boxDetail, setBoxDetail] = useState([]);
    const [orderInvoiceDetail, setOrderInvoiceDetail] = useState([]);
    const classes = useStyles();
    const minimized = useSelector(state => state.cart.minimized);
    const [open, setOpen] = useState(false);
    const [trackBoxOpen, setTrackBoxOpen] = useState(false);
    const [trackingDetail, setTrackingDetail] = useState({});
    const [tracks, setTracks] = useState([]);
    const [scroll, setScroll] = useState('paper');
    const [reviewId, setReviewId] = useState(null);
    const [loading, setLoading] = useState(false)
    const [rating, setRating] = useState(0);
    const [reviewBox, setReviewBox] = useState(false);
    const [reviewMsg, setReviewMsg] = useState('');
    const [ratingError, setRatingError] = useState(false);
    const hasActionBeenPerformedRef = useRef(false);
    const [reviewData, setReviewData] = useState({
        "title": '',
        "description": '',
        "rating": '',
    });

    const orderDetail = useSelector((state) => state.review.orderHistory);

    const handleOpen = async (orderId) => {
        setOpen(true);
        try {
            let response = await Request.get(`order/order_detail/${orderId}`, [], true);

            if (response) {
                setOrderInvoiceDetail(response)
                setOrderId(orderId)
                setInvoiceNo(response.invoiceNo)

                if (response.orderType === "Islander") {
                    setProductDetail(response?.products?.iCollectUserProducts)
                    setBoxDetail(response?.products?.shipmentBox)
                    const filteredProducts = response?.products?.PriorityProducts.filter(product => product.quantityCharged !== 0);
                    setPriorityProductDetail(filteredProducts)
                    // setPriorityProductDetail(response?.products?.PriorityProducts)
                    // setInvoiceNo(response.invoiceNo)
                }
                else {
                    setProductDetail(response.products)
                    setPriorityProductDetail([])
                    setBoxDetail([])
                }
            }
            else {
                console.log("error");
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }


    const handleClose = () => {
        setOpen(false);
    }

    const handleOpenTrackBox = async (orderId) => {
        setTrackBoxOpen(true);
        setLoading(true);
        try {
            let response = await Request.get(`utils/trackOrder/${orderId}`, [], true);

            if (response) {
                setLoading(false);
                // setTimeout(() => {
                //     console.log(response, 'Track response');
                //     setTrackingDetail(response)
                //     setTracks(response.track)
                //   }, 2000); 
                setTrackingDetail(response)
                setTracks(response.track)
                // setOrderId(orderId)
                // setInvoiceNo(response.invoiceNo)

                // if(response.orderType === "Islander")
                // {
                //    setProductDetail(response?.products?.iCollectUserProducts)
                //    setBoxDetail(response?.products?.shipmentBox)
                //    setPriorityProductDetail(response?.products?.PriorityProducts)
                //    // setInvoiceNo(response.invoiceNo)
                // }
                // else{
                //    setProductDetail(response.products)
                //    setPriorityProductDetail([])
                //    setBoxDetail([])   
                // } 
            }
            else {
                console.log("error");
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }


    const handleTrackBoxClose = () => {
        setTrackBoxOpen(false);
    }

    function formatDate(timestamp) {
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        return `${day}-${month}-${year}`;
    }
    //   useEffect(() => {
    //     (async () => {
    //             let response = await Request.get("order/order_history", [] , true);
    //             if(response){
    //                 console.log(response,'order detail response');
    //                 setOrderDetail(response)
    //             }
    //             else
    //             {
    //                 console.log("error");
    //             }
    //     })();
    //   }, [orderDetail]);

    let login = checkLogin()

    useEffect(() => {
        dispatch(fetchOrderHistory())
    }, []);

    useEffect(() => {
        dispatch(fetchOrderHistory())
    }, [dispatch]);


    // const fetchDetail = async () => {
    //     try {
    //         let response = await Request.get("order/order_history", [], true);
    //         if (response) {
    //             console.log(response, 'order history response');
    //             setOrderDetail(response)
    //         }
    //         else {
    //             console.log("error");
    //         }
    //     }
    //     catch (error) {
    //         console.error('Error fetching data from API:', error);
    //     }
    // }

    const handlePrint = () => {
          
        if (dialogRef.current) {
            const input = dialogRef.current;


            // const handleCaptureAndConvert = () => {
            //     if (divRef.current) {
            //       const divToCapture = divRef.current;

            // Capture a screenshot of the div
            html2canvas(input, { useCORS: true }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                // Create a new PDF document
                const pdf = new jsPDF({
                    orientation: 'portrait',  // or 'landscape'
                    unit: 'mm',
                    format: 'a4',  // page size: A4
                    putOnlyUsedFonts: true
                });
                const width = 210;  // A4 width in mm5rt
                const height = (canvas.height * width) / canvas.width;

                canvas.getContext('2d').imageSmoothingEnabled = false;
                // Add the canvas image to the PDF
                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                pdf.setFontSize(14); // Set the font size
                pdf.setFont('Helvetica'); // Use a web-safe font
                // Save the PDF with a filename
                pdf.save('screenshot.pdf');
            });
        };
    };
    const handleReviewClose = () => {
        setReviewBox(false);
    };

    const handleRatingChange = (id, event, newValue) => {
        setReviewId(id)
        setRating(newValue); // Update the rating value
        setReviewBox(true); // Call the function to open the dialog with the 'id'
    };

    const handleReviewChange = (e) => {
        let { name, value } = e.target;

        if (name === "rating") {
            setRating(value);
            setRatingError(value === 0);
        }
        setReviewData({ ...reviewData, [name]: value });
    }

    const handleReviewSubmit = async (e) => {
        e.preventDefault();

        if (rating === 0) {
            setRatingError(true);
            return;
        } else {
            setRatingError(false);
        }

        const reviewDataInfo = {
            "title": reviewData.title,
            "rate": rating,
            "review": reviewData.description
        }

          
        try {
            let response = await Request.post(`review/add/${reviewId}`, reviewDataInfo, true);
            if (response.message === "Success") {
                setReviewMsg("Thank you for Your review")
                setTimeout(() => {
                    setReviewBox(false)
                }, 2000);
                setReviewData({
                    "title": '',
                    "description": '',
                })
                dispatch(fetchOrderHistory())
                setRating(0)
            } else {
                setReviewMsg("Review is not added, Please try again..")
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }

    };

    return (
        <>

            <Box className={classes.dashboardInner}>
                <Box>
                    <Typography variant='h5' className='color1 font-bold'>Your Orders</Typography>
                </Box>
                <Box className='cartMainContainer'>
                    <Box container className='paymentBox orderHistory'>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 500 }}>
                                <MuiTable stickyHeader aria-label="sticky table" className='order_history'>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    className='font-bold color2'
                                                    sx={{
                                                        minWidth: { xs: '200px !important', sm: '200px !important', md: '120px !important' }, // Adjust as needed
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='historyTableBody'>

                                        {(orderDetail && orderDetail.length !== 0) && (
                                            <>
                                                {orderDetail.map((order =>
                                                    <TableRow key={order.id}>
                                                        <TableCell className="color4 font-bold text-underline">
                                                            <span onClick={() => handleOpen(order.id)} className='orderId'>{order.invoiceNo}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {order.orderType === "Purchase" ? "Packaging" : "Shipping"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {order.orderType === "Purchase" && (
                                                                <>
                                                                    {order.orderStatus === 0 && "Order Received"}
                                                                    {order.orderStatus === 1 && "Processing"}
                                                                    {order.orderStatus === 2 && "Processing"}
                                                                    {order.orderStatus === 3 && "Processing"}
                                                                    {order.orderStatus === 4 && "Processing"}
                                                                    {order.orderStatus === 5 && "Shipped"}
                                                                    {order.orderStatus === 6 && "Completed"}
                                                                    {order.orderStatus === 7 && "Cancelled"}
                                                                </>
                                                            )}
                                                            {order.orderType === "Send" && (
                                                                <>
                                                                    {order.orderStatus === 0 && "Incomplete"}
                                                                    {order.orderStatus === 1 && "Order Received"}
                                                                    {order.orderStatus === 2 && "Ready to Pickup"}
                                                                    {order.orderStatus === 3 && "Picked Up"}
                                                                    {order.orderStatus === 4 && "Processing"}
                                                                    {order.orderStatus === 5 && "In-Transit"}
                                                                    {order.orderStatus === 6 && "Completed"}
                                                                    {order.orderStatus === 7 && "Cancelled"}
                                                                </>
                                                            )}
                                                            {order.orderType === "Islander" && (
                                                                <>
                                                                    {order.orderStatus === 0 && "Order Received"}
                                                                    {order.orderStatus === 1 && "Processing"}
                                                                    {order.orderStatus === 2 && "Processing"}
                                                                    {order.orderStatus === 3 && "Processing"}
                                                                    {order.orderStatus === 4 && "Processing"}
                                                                    {order.orderStatus === 5 && "In-Transit"}
                                                                    {order.orderStatus === 6 && "Completed"}
                                                                    {order.orderStatus === 7 && "Cancelled"}
                                                                </>
                                                            )}
                                                            {/* {order.orderStatus === 0 && "Order Received"}
                                                                            {order.orderStatus === 1 && "Pending"}
                                                                            {order.orderStatus === 2 && "Ready to Pickup"}
                                                                            {order.orderStatus === 3 && "Picked Up"}
                                                                            {order.orderStatus === 4 && "ready to ship"}
                                                                            {order.orderStatus === 5 && "Shipped"}
                                                                            {order.orderStatus === 6 && "Delivered"}
                                                                            {order.orderStatus === 7 && "Canceled"} */}
                                                        </TableCell>
                                                        <TableCell>
                                                            ${order.orderType === "Send" ? FormatPrice(order.shippingCharge) : FormatPrice(order.productPrice)}
                                                        </TableCell>
                                                        <TableCell>${FormatPrice(order.tax)}</TableCell>
                                                        <TableCell>${FormatPrice(order.totalPrice)}</TableCell>
                                                        <TableCell className="color4 font-bold text-underline">
                                                            <span onClick={() => handleOpenTrackBox(order.id)}>
                                                                {order.trackingNo === null ? "Track" : order.trackingNo}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {order.bol !== null ?
                                                                (
                                                                    <a href={`${process.env.REACT_APP_DatabaseStatic}${order.bol}`} target="_blank">
                                                                        <Typography className="color4 font-bold text-underline">View</Typography>
                                                                    </a>
                                                                ) : (
                                                                    <Typography className="color1">Not Available</Typography>
                                                                )}
                                                        </TableCell>
                                                        <TableCell>{formatDate(order.createdAt)}</TableCell>
                                                        <TableCell>
                                                            {order.review === null ?
                                                                (
                                                                    <Rating
                                                                        name="simple-controlled"
                                                                        value="0"
                                                                        size="large"
                                                                        readOnly
                                                                        disabled
                                                                        className="rating c-rating d-flex"
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {order.review.rate === null ? (
                                                                            <Rating
                                                                                name="simple-controlled"
                                                                                value={rating}
                                                                                size="large"
                                                                                className="rating c-rating d-flex"
                                                                                onChange={(event, newValue) => handleRatingChange(order.review.id, event, newValue)} />
                                                                        ) : (
                                                                            <Rating
                                                                                name="simple-controlled"
                                                                                value={order.review.rate}
                                                                                size="large"
                                                                                readOnly
                                                                                className="rating c-rating d-flex" />
                                                                        )}
                                                                    </>
                                                                )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        )}
                                    </TableBody>
                                </MuiTable>
                            </TableContainer>
                        </Paper>
                    </Box>
                </Box>
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalBox}

            >

                <Box sx={style}>
                    <Button className="b1 b4 printPdfBtn" onClick={handlePrint}>
                        Print
                    </Button>
                    <Box ref={dialogRef} sx={{ p: 4, '@media (max-width: 768px)': { p: 2, }, '@media (max-width: 480px)': { p: 1 }, }}>
                        <Box sx={{ display: "inline-block", width: "100%" }}>
                            <Box className={classes.imgBox}>
                                <img src='/assets/img/web_logo.png' style={{ "maxWidth": "100%" }}></img>
                            </Box>

                        </Box>
                        <Box sx={{ pt: 3, pb: 3 }}>
                            <Row>
                                <Col item xs={12} sm={12} md={8} lg={8} xl={8} className=''>
                                    <Box>
                                        <Typography className='font-bold font-20'>ISLE OF FREIGHT INC.</Typography>
                                        <Typography className='font-16 font-600'><i>We help families help families</i></Typography>
                                    </Box>
                                </Col>
                                <Col item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.flexEnd}>
                                    <Typography className='font-bold font-25'>INVOICE</Typography>
                                </Col>
                            </Row>
                            <Box sx={{ pt: 3, pb: 3 }}>
                                <Row className='invoiceBox'>
                                    <Col item xs={12} sm={12} md={12} lg={6} xl={6} className=''>
                                        <Box>
                                            <Typography>246 Stewart Green SW</Typography>
                                            <Typography>Unit #6188</Typography>
                                            <Typography>Calgary,AB T3H 3C8</Typography>
                                            <Typography>Phone: (825)-901-3955</Typography>
                                            <Typography>Email: support@isleoffreight.com</Typography>
                                        </Box>
                                    </Col>
                                    <Col item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.flexEnd}>
                                        <Box>
                                            <Typography className=''>INVOICE  <b>{invoiceNo}</b></Typography>
                                            <Typography className=''>Date: {formatDate(orderInvoiceDetail.createdAt)}</Typography>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            <Box sx={{ pb: 3 }}>
                                <Row className='invoiceBox'>
                                    <Col item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mb: 2 }} className=''>
                                        {orderInvoiceDetail.billingAddress &&
                                            <Box>
                                                <Typography className='font-bold'>BILL TO:</Typography>
                                                <Typography>{orderInvoiceDetail.billingAddress.fullName}</Typography>
                                                <Typography>{orderInvoiceDetail.billingAddress.address_line_1}, {orderInvoiceDetail.billingAddress.address_line_2} ,{orderInvoiceDetail.billingAddress.city}-{orderInvoiceDetail.billingAddress.zip}</Typography>
                                                <Typography>{orderInvoiceDetail.billingAddress.state}, {orderInvoiceDetail.billingAddress.country}</Typography>
                                            </Box>
                                        }
                                    </Col>
                                    <Col item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mb: 2 }} className={classes.addressTop}>
                                        {orderInvoiceDetail.shippingAddress &&
                                            <Box>
                                                <Typography className='font-bold'>SEND TO:</Typography>
                                                <Typography>{orderInvoiceDetail.shippingAddress.fullName}</Typography>
                                                <Typography>{orderInvoiceDetail.shippingAddress.address_line_1}, {orderInvoiceDetail.shippingAddress.address_line_2} ,{orderInvoiceDetail.shippingAddress.city}-{orderInvoiceDetail.shippingAddress.zip}</Typography>
                                                <Typography>{orderInvoiceDetail.shippingAddress.state}, {orderInvoiceDetail.shippingAddress.country}</Typography>
                                            </Box>
                                        }
                                    </Col>
                                </Row>
                            </Box>
                            <Box sx={{ pb: 3 }} className='invoiceBox'>
                                <Typography className='font-bold'>COMMENTS OR SPECIAL INSTRUCTIONS:</Typography>
                                {orderInvoiceDetail.orderType === "Purchase" &&
                                    <Typography className=''>Packaging Items</Typography>
                                }
                                {orderInvoiceDetail.orderType === "Send" &&
                                    <Typography className=''>Barrel Shipment</Typography>
                                }
                                {orderInvoiceDetail.orderType === "Islander" &&
                                    <Typography className=''>Islander Box Shipment</Typography>
                                }
                            </Box>
                            <Box sx={{ pb: 3 }}>
                                <table striped bordered className='historyTable'>
                                    <thead>
                                        <tr>
                                            <th>VENDOR ID</th>
                                            <th>QUANTITY</th>
                                            <th width="320px">DESCRIPTION</th>
                                            <th>ITEM VALUE</th>
                                            <th width="150px">TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productDetail && productDetail.length !== 0 ? (
                                            <>
                                                {productDetail.map((item =>
                                                    <tr>
                                                        <td>
                                                            {orderInvoiceDetail.orderType === "Islander" ? item.url : "IOF"}
                                                        </td>
                                                        <td>
                                                            {orderInvoiceDetail.orderType === "Islander" ? item.receivedQuantity : item.quantity}
                                                        </td>
                                                        <td>{orderInvoiceDetail.orderType === "Send" &&
                                                            (
                                                                <>
                                                                    Barrel ({item.product?.size}GL) - {item.product?.description}
                                                                </>
                                                            )}
                                                            {orderInvoiceDetail.orderType === "Purchase" &&
                                                                (
                                                                    <>
                                                                        {item.product.name} ({item.product.size}GL)
                                                                    </>
                                                                )}
                                                            {orderInvoiceDetail.orderType === "Islander" &&
                                                                (
                                                                    <>
                                                                        {item.description}
                                                                    </>
                                                                )}
                                                        </td>
                                                        <td>
                                                            {orderInvoiceDetail.orderType === "Islander" ? item.itemValue : item.product?.itemValue}
                                                        </td>
                                                        <td align='right'>
                                                            {orderInvoiceDetail.orderType === "Islander" ? '$0.00' : `$${FormatPrice(item.itemPrice)}`}
                                                        </td>
                                                        {/* <td>${item.itemPrice}</td> */}
                                                    </tr>
                                                ))}
                                            </>

                                        ) : <></>
                                        }
                                        {/* {boxDetail && boxDetail.length !== 0 ? (
                                                <>
                                                {boxDetail.map((item =>
													<tr>
                                                        <td>IOF</td>
                                                        <td>{item.quantity}</td>
                                                        <td>Box({item.size})</td>
                                                        <td></td>
                                                        <td align='right'>${item.price}</td>
                                                    </tr>
                                                ))}
                                                </>
                                            ):<></>
                                            } */}
                                        {priorityProductDetail && priorityProductDetail.length !== 0 ? (
                                            <>
                                                {priorityProductDetail.map((item =>
                                                    <tr>
                                                        <td>IOF</td>
                                                        <td>{item.quantityPacked}</td>
                                                        <td>{item.islander_product?.name}</td>
                                                        <td>{item.priorityProductValue}</td>
                                                        <td align='right'>${FormatPrice(item.price * item.quantityCharged)}</td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : <></>
                                        }
                                        <tr>
                                            <td></td><td></td><td></td><td></td><td></td>
                                        </tr>
                                        <tr>
                                            <td></td><td></td><td></td><td></td><td></td>
                                        </tr>
                                        <tr>
                                            <td></td><td></td><td></td><td></td><td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}></td>
                                            <td align='right'>SUBTOTAL</td>
                                            <td align='right'>
                                                {orderInvoiceDetail.orderType === "Send" ? (
                                                    <>
                                                        ${FormatPrice(orderInvoiceDetail.shippingCharge)}
                                                    </>
                                                ) : (
                                                    <>
                                                        ${FormatPrice(orderInvoiceDetail.productPrice)}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}></td>
                                            <td align='right'>TAX({orderInvoiceDetail.taxPercentage}%)</td>
                                            <td align='right'>${FormatPrice(orderInvoiceDetail.tax)}</td>
                                        </tr>
                                        {orderInvoiceDetail.insuranceValue && (
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td align='right'>Insurance</td>
                                                <td align='right'>${FormatPrice(orderInvoiceDetail.insuranceValue)}</td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td colSpan={3}></td>
                                            <td align='right'>SHIPPING & HANDLING</td>
                                            <td align='right'>
                                                {orderInvoiceDetail.orderType === "Send" ? (
                                                    <>
                                                        $0.00
                                                    </>
                                                ) : (
                                                    <>
                                                        ${orderInvoiceDetail.shippingCharge > 0 ? FormatPrice(orderInvoiceDetail.shippingCharge) : 0}
                                                    </>
                                                )}

                                            </td>
                                        </tr>
                                        {orderInvoiceDetail.orderType === "Islander" && (
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td align='right'>Packaging & Material cost</td>
                                                <td align='right'>${FormatPrice(orderInvoiceDetail.products.totalBoxAccessoriesValue)}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td colSpan={3}></td>
                                            <td align='right' className='font-bold'>TOTAL</td>
                                            <td align='right' className='font-bold'>${FormatPrice(orderInvoiceDetail.totalPrice)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={trackBoxOpen}
                onClose={handleTrackBoxClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalBox}
            >
                <Box sx={style2}>
                    <Typography className="section-title font-22 color2 font-600">Tracking Details</Typography>

                    {loading ? (
                        <div className="loader2-container">
                            <div className="loader2"></div>
                        </div>
                    ) : (
                        <>
                            <Typography className="section-title font-16 flexBox pt-3">
                                <Typography className='font-16 font-600'>Expected Delivery:</Typography>
                                <Typography className='font-16 font-00'>{formatDate(trackingDetail.estDeliveryTimeInDay)}</Typography>
                            </Typography>
                            {(tracks && tracks.length > 0) ? (
                                <TableContainer component={Paper} className='trackDetail-table mt-4'>
                                    <Table striped bordered className='historyTable' aria-label="Tracking Details">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Date
                                                </th>
                                                <th>
                                                    Status Detail
                                                </th>
                                                <th>
                                                    Location
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tracks.map(item => (
                                                <tr key={item.id}>
                                                    <td style={{ textAlign: "center" }}>
                                                        {formatDate(item.statusUpdate)}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.description}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.location}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Typography className='font-16 color2 mt-4'>No Other Tracking Details Available</Typography>
                            )}
                        </>
                    )}

                </Box>
            </Modal>

            <Dialog
                open={reviewBox}
                onClose={handleReviewClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                align="left"
                className={classes.alertDialog}

            >

                <Box className={classes.dialogInner}>
                    <DialogTitle className={`${classes.dialogTitle} font-25 font-bold`} id="alert-dialog-title">
                        {/* Add Review */}
                        Welcome back! We've recently completed your order, how did we do?
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Box align="left" className="supportFormBox">
                            <form onSubmit={handleReviewSubmit}>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" name="title" value={reviewData.title} onChange={handleReviewChange} required></Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" name="description" rows={10} value={reviewData.description} onChange={handleReviewChange} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Rating</Form.Label>
                                    <StarRating
                                        value={rating}
                                        name="rating"
                                        // onChange={handleReviewChange}
                                        onChange={(e, newValue) => {
                                            setRating(newValue);
                                            setRatingError(newValue === 0);
                                        }}
                                    />
                                    {ratingError && <p className="error-text">Please select a rating.</p>}
                                </Form.Group>
                                <Button sx={{ mt: 1 }} type="submit" className='b1'>Submit</Button>
                            </form>
                            {reviewMsg !== '' &&
                                <Typography className='font-15 color4' sx={{ pt: 1 }}>{reviewMsg}</Typography>
                            }
                        </Box>

                    </DialogContent>
                    {/* <DialogActions>
                                                <Box className={classes.dialogBtnBox}>
                                                    <Button className={`${classes.dialogBtn} b1 b4`} autoFocus onClick={handleClose}>Ok</Button>
                                                </Box>
                                            </DialogActions> */}
                </Box>
                <CloseIcon className={classes.dialogClose} onClick={handleReviewClose} />
            </Dialog>

        </>
    )
};


export default OrderHistory;