import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    userList: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchUserList = createAsyncThunk(
    "customer/userList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/adminUser", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  

  const userListSlice = createSlice({
    name: "userList",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchUserList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchUserList.fulfilled, (state, action) => {
            state.loading = false;
            state.userList = action.payload;
          })
          .addCase(fetchUserList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
      },
  });
  
  export default userListSlice.reducer;