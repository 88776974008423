import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
    TableSortLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
import ProductCategoriesTable from "./ProductCategoriesTable";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderAdminList, fetchSubscriberList } from "../redux/islanderListSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';;


function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
  }

function SubscriberList() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');

    const subscribers = useSelector((state) => state.islanderList.subscriberList);

      
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

      const sortedSubscribers = React.useMemo(() => {
        return [...subscribers].sort((a, b) => {
          if (order === 'asc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
          } else {
            return b[orderBy] > a[orderBy] ? 1 : -1;
          }
        });
      }, [order, orderBy, subscribers]);

      const getSortingIcon = (column) => {
        if (orderBy === column) {
          return order === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;
        }
        return null;
      }

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

  
    useEffect(() => {
        dispatch(fetchSubscriberList());
    }, [dispatch]);

    
    useEffect(() => {
        dispatch(fetchSubscriberList());
    }, []);

    const openDeleteModal = (id) => {
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

    const productDelete = async () => {
		try {
			const response = await Request.delete(`admin/subscriber/${selectedProduct}`, [], true);
			if (response) {
				if (response.status === 204) {
					setIsDeleteDialogOpen(false)
					dispatch(fetchSubscriberList())
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

    return (
        <Box className="adminInnerPage pickupLocationsPage">
            <div className="title-button__container">
                <Typography variant="h2" className="adminInnerPage__title">
                    Subscribers List
                </Typography>
            </div>

            <div className="productListPage__container">

                <Box className="order-table-container product-categories-table-container">
                    <TableContainer
                        component={Paper}
                        className="product-categories-table"
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell max-width="70px">Sr No</TableCell>
                                    <TableCell className="textLeft">Email Id</TableCell>
                                    <TableCell className="textLeft">
                                    <TableSortLabel
                                            active={orderBy === 'createdAt'}
                                            direction={orderBy === 'createdAt' ? order : 'asc'}
                                            onClick={() => handleRequestSort('createdAt')}
                                        >
                                            Created On
                                            {getSortingIcon('createdAt')}
                                    </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(sortedSubscribers && sortedSubscribers.length>0) && sortedSubscribers.map((item,index) => (
                                    <TableRow
                                        key={item.id}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell className="textLeft">{item.email}</TableCell>
                                        <TableCell className="textLeft">{formatDate(item.createdAt)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="Delete"
                                                onClick={() => openDeleteModal(item.id)} // Replace with your delete logic
                                            >
                                                <DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <p>Are you sure you want to delete this Subscriber?</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => productDelete(selectedProduct)} color="primary">
                                OK
                            </Button>
                            <Button onClick={closeDeleteDialog} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </div>
        </Box>
    );
}

export default SubscriberList;
