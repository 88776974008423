import { useEffect, useState, Fragment } from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { toggleSidebar } from '../commonFunctions/actions';
import { useDispatch, useSelector } from 'react-redux';
import {sidebar} from '../redux/cartSlice'
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import {
  Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Dashboard, Inbox, Mail, Settings } from '@mui/icons-material';
import { spacing } from '@mui/system';
import Request from '../customUtils/Http/Request';
import CloseIcon from '@mui/icons-material/Close';
import DashboardHeader from './dashboardHeader';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import WheelchairPickupOutlinedIcon from '@mui/icons-material/WheelchairPickupOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const cookies = new Cookies();


const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "fit-content",
    maxWidth: "260px",
    border: "none !important",
  }
}));

function AdminSidebar() {
  const classes = useStyles();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userType, setUserType] = useState(0);
  const dispatch = useDispatch();
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }
  const handleSubMenuClick = (url) => {
    
  }

  const handleMenuItemClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  }

  useEffect(() => {
    let userType = cookies.get('userType', { path: '/' });
    const userTypeAsNumber = parseInt(userType, 10);
    setUserType(userTypeAsNumber)

  }, []);

  const menuItems = [
    { icon: <ShoppingBagOutlinedIcon />, text: 'Orders', url: '/admin/orders' },
    { icon: <LocalShippingOutlinedIcon />, text: 'Pick up & Delivery', url: '',
    subMenuItems: [
      { icon: <ListAltOutlinedIcon />, text: 'Driver List', url: '/admin/driver-list' },
      { icon: <TaskOutlinedIcon />, text: 'Task Management', url: '/admin/task-management' },
    ],
    },
    { icon: <GroupsOutlinedIcon />, text: 'Customers', url: '/admin/customers'},
    // { icon: <DonutSmallOutlinedIcon />, text: 'Analytics', url: '' },
    { icon: <SettingsOutlinedIcon />, text: 'Settings', url: '',
    subMenuItems: [
        { icon: <PriceChangeOutlinedIcon />, text: 'Shipping Price', url: '/admin/shipping-price' },
        { icon: <QueryBuilderOutlinedIcon />, text: 'Estimated Delivery Time', url: '/admin/delivery-time' },
        { icon: <WheelchairPickupOutlinedIcon />, text: 'Delivery Locations', url: '/admin/delivery-locations' },
        { icon: <WheelchairPickupOutlinedIcon />, text: 'Pick up Locations', url: '/admin/pickup-locations' },
        { icon: <AttachMoneyIcon />, text: 'Tax Setting', url: '/admin/tax' },
        { icon: <TimerOutlinedIcon />, text: 'Pickup Slots', url: '/admin/time-slot' },
        { icon: <LocationOnOutlinedIcon />, text: 'Base Islander Address', url: '/admin/base-islander-address' },
        { icon: <ListOutlinedIcon />, text: 'Islander Shipping List', url: '/admin/island-list' },
        { icon: <RateReviewOutlinedIcon />, text: 'Customer Reviews', url: '/admin/review-list' },
        { icon: <MailOutlineOutlinedIcon />, text: 'Subscribers', url: '/admin/subscribers' },
        { icon: <LocalOfferIcon />, text: 'Weekly Deals', url: '/admin/weeklyDealsList' },
        { icon: <LocalLibraryOutlinedIcon />, text: 'Community', url: '/admin/community' },
        { icon: <PolicyIcon />, text: 'Legal & Privacy Policies', url: '/admin/privacy-policy' },
        { icon: <PaymentOutlinedIcon />, text: 'Paypal Setting', url: '/admin/payment-paypal' },
        { icon: <PaymentOutlinedIcon />, text: 'Stripe Setting', url: '/admin/payment-stripe' },
        { icon: <SettingsOutlinedIcon />, text: 'General Settings', url: '/admin/general-setting' },
    ],
    },
    { icon: <ShoppingCartOutlinedIcon />, text: 'Islander Box-Extra Items', url: '',
        subMenuItems: [
            { icon: <ListAltOutlinedIcon />, text: 'Product List', url: '/admin/additional-item-list' },
            { icon: <CategoryOutlinedIcon />, text: 'Categories', url: '/admin/additional-item-categories' },
          
      ],},
          { icon: <OilBarrelOutlinedIcon />, text: 'Barrel & Accessories', url: '' ,
            subMenuItems: [
              { icon: <ListAltOutlinedIcon />, text: 'Product List', url: '/admin/product-list' },
              { icon: <CategoryOutlinedIcon />, text: 'Categories', url: '/admin/product-categories' },
          ],},
        { icon: <HelpCenterOutlinedIcon />, text: 'Help Center', url: '/admin/help-center' },
        { icon: <WorkOutlineOutlinedIcon />, text: 'Careers', url: '/admin/career' },
        { icon: <PeopleAltOutlinedIcon />, text: 'Users', url: '/admin/user-list' },
  ];

  const shouldHideSidebar = () => {
    // Check if the sidebar is minimized and the screen width is less than a threshold (e.g., 992 pixels)
    return minimized && window.innerWidth < 992;
  };

  return (
    shouldHideSidebar() ? null : (
      
    <Drawer
        // className={`drawer ${minimized ? 'minimized' : ''}`}
        className='drawer'
        id="drawer"
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >

        <div className="toolbar" />
        {/* <CloseIcon className="closeSidebarIcon" onClick={openSidebar} /> */}
        
        {minimized ? (
          <Box sx={{ height: "45px", margin: "0 auto", mt: 3, mb: 2 }}>
              <Link to="/admin/orders">
                <img src="/assets/img/iof_mobile.svg" className='webHeader_logo'></img>
              </Link>   
          </Box>
        ) : (
          <Box sx={{ width: "110px", height: "45px", margin: "0 auto", mt: 3, mb: 2 }}>
            <Link to="/admin/orders">
              <img src="/assets/img/web_logo.png" className='webHeader_logo'></img>
            </Link>
          </Box>
        )}

        
        <List className="menuBox adminMenuBox">
          {(menuItems && menuItems.length>0) && menuItems.map((item, index) => (
            <Fragment key={index}>
              {item.subMenuItems ? 
              (
                <div className='linkBox'>
                  {/* <Link to=""> */}
                  <ListItem
                className="menulist non-clickable-menu-with-submenu"
              // selected={selectedIndex === index}
              >

                    {minimized ? (
                        <ListItemIcon className="iconBox">
                            {item.icon}
                        </ListItemIcon>
                      ): (
                        <> 
                          <ListItemIcon className="iconBox">
                              {item.icon}
                          </ListItemIcon>
                          <ListItemText primary={item.text} />
                         </>
                      )}
              
              </ListItem>
              {/* </Link> */}
              {Array.isArray(item.subMenuItems) && (
                <List className={minimized ? 'nestedMenuBox' : 'nestedMenuBoxExpand'} component="div" disablePadding >
                  {item.subMenuItems.map((subItem, subIndex) => (
                    <Link to={subItem.url}  key={subIndex} className={`${location.pathname === subItem.url ? 'sub_menu_active' : ''}`}>
                    <ListItem
                      button
                      className="nested"
                     
                      // onClick={() => handleMenuItemClick(subItem.url)}
                    // selected={selectedIndex === subIndex}
                    >
                      {minimized ? (
                           <ListItemIcon className="iconBox2">
                            {subItem.icon}
                         </ListItemIcon>
                      ) : (
                        <>
                        <ListItemIcon className="iconBox2">
                        {subItem.icon}
                         </ListItemIcon>
                         
                          <ListItemText primary={subItem.text} />
                         </>
                      )}
                
                    </ListItem>
                    </Link>
                   
                  ))}
                </List>

              )}
      
                </div>
                 
               
              ):(
                
              <div className='linkBox'>
                <Link to={item.url} className={`${location.pathname === item.url ? 'menu_active' : ''}`}>
                <ListItem
              className="menulist non-clickable-menu-with-submenu"
            // selected={selectedIndex === index}
            >

                  {minimized ? (
                        <ListItemIcon className="iconBox">
                          {item.icon}
                      </ListItemIcon>
                    ): (
                      <> <ListItemIcon className="iconBox">
                     {item.icon}
                      </ListItemIcon>
                          <ListItemText primary={item.text} />
                       </>
                    )}
            
            </ListItem>
            </Link>
            </div>
               
              )}
            
            </Fragment>
          ))}
        </List>
      </Drawer>
      
      
    )
  );
}

// const mapDispatchToProps = {
//   toggleSidebar,
// };

// const mapStateToProps = (state) => {
//   return {
//     minimized: state.sidebar.minimized,
//   };
// };

export default AdminSidebar;