import { useEffect, useState } from 'react';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';


const useStyles = makeStyles((theme) => ({
    formPadding: {
        marginTop: "12px"
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },


}));

function OrderList({ cartInfo1, totalPrice }) {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className='cartGridColumn'>
            <Box className='gridInnerBlockRight cartTotalTable'>
                <Box sx={{ p: 2 }} className={classes.totalHeader} align="center">
                    <Typography className='font-20 color2 font-bold'>Your Order</Typography>
                </Box>
                <Box sx={{ p: 3, pt: 0, pb: 4 }}>
                    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                        <Table aria-label="Total table" sx={{ mt: 2 }}>
                            <TableBody>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">
                                        <Typography className='font-15 font-bold color2'>Product</Typography>
                                    </TableCell>
                                    <TableCell align="right">

                                    </TableCell>
                                </TableRow>

                                {(cartInfo1 && cartInfo1.length>0) && cartInfo1.map((cart, index) =>
                                    <TableRow key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell scope="row">
                                            <Typography className='font-15 font-bold color1 transform-capital'>{cart.productType}</Typography>
                                            <Box sx={{ display: "flex", gap: "40px" }}>
                                                <Typography className='font-15 font-bold color1'>Size: {cart.size % 1 === 0 ? `${cart.size} GL` : cart.size}</Typography>
                                                <Typography className='font-15 font-bold color1'>Qty: {cart.quantity}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography className='font-15 font-bold color1'>${(FormatPrice(cart.price))}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {/* <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell scope="row">
                                        <Typography className='font-15 font-bold color2'>Delivery Cost</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography className='font-15 font-bold color2'>$20</Typography>
                                    </TableCell>
                                </TableRow> */}

                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">
                                        <Typography className='font-20 font-bold color2'>Total</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography className='font-20 font-bold color2'>${(FormatPrice(totalPrice))}</Typography>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            </Box>
        </Grid>
    );
}

export default OrderList;
