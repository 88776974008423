import { useEffect, useState, useLocation } from 'react';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Radio, RadioGroup, FormControlLabel, TableCell, TableRow } from '@mui/material';

const PaymentAddress = ({address1, address2, address3, address4, address5, type, page}) => {
    return (
        <Grid item xs={12} sm={12} md={12} lg={11.7} xl={11.7} className={`cartColLeft paymentCol ${page === "paypal" ? 'mt-0' : 'mt-3'}`}>
        <Box className="addressFlexBox">
            <Box className=''>
                <Box sx={{p: 1}}>
                    <Typography className='color1 font14 font-600'>Recipient Address</Typography>
                    <Box className="paymentAddressBox">
                        <Typography className='color3 font14'>Name: {address1?.fullName}</Typography>
                        <Typography className='color3 font14'>Address: {address1?.address_line_1}, {address1?.address_line_2}, {address1?.city}, {address1?.state}, {address1?.country}, {address1?.zip} </Typography>
                    </Box>
                </Box>  
            </Box>
            <Box className=''>
                <Box sx={{p: 1}}>
                    <Typography className='color1 font14 font-600'>Billing Address</Typography>
                    <Box className="paymentAddressBox">
                        <Typography className='color3 font14'>Name: {address2?.fullName}</Typography>
                        <Typography className='color3 font14'>Address: {address2?.address_line_1}, {address2?.address_line_2}, {address2?.city}, {address2?.state}, {address2?.country}, {address2?.zip} </Typography>
                    </Box>
                </Box>  
            </Box>
            {(type === 'send' || type === 'islander') && 
                <>
                    <Box className=''>
                        <Box sx={{p: 1}}>
                            <Typography className='color1 font14 font-600'>Sender Address</Typography>
                            <Box className="paymentAddressBox">
                                <Typography className='color3 font14'>Name: {address4?.fullName}</Typography>
                                <Typography className='color3 font14'>Address: {address4?.address_line_1}, {address4?.address_line_2},{address4?.city}, {address4?.country}, {address4?.zip} </Typography>
                            </Box>
                        </Box>  
                    </Box>
                </>
            }
                {type === 'send' &&
                 <>
                 <Box className=''>
                    <Box sx={{p: 1}}>
                        <Typography className='color1 font14 font-600'>Pickup Address</Typography>
                        <Box className="paymentAddressBox">
                            <Typography className='color3 font14'>Name: {address5?.fullName}</Typography>
                            <Typography className='color3 font14'>Address: {address5?.address_line_1}, {address5?.address_line_2},{address5?.city}, {address5?.country}, {address5?.zip} </Typography>
                        </Box>
                    </Box>  
                </Box>
             </>
            }       
            {type === 'buy' && 
                <Box className=''>
                    <Box sx={{p: 1}}>
                        <Typography className='color1 font14 font-600'>Contact Details</Typography>
                        <Box className="paymentAddressBox">
                            <Typography className='color3 font14'>Name: {address3?.fullName}</Typography>
                            <Typography className='color3 font14'>Contact No: {address3?.phone}</Typography>
                            <Typography className='color3 font14'>Email: {address3?.email}</Typography>
                        </Box>
                    </Box>  
                </Box>
            }    
                
        </Box>
    </Grid>
    );
}

export default PaymentAddress;
