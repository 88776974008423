import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import Request from '../customUtils/Http/Request';
import ShippingPriceTable from "./shippingPriceTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxList } from "../redux/taxSlice";
import TaxListTable from "./taxListTable";

function TaxList() {
	const [openDialog, setOpenDialog] = useState(false);
	const dispatch = useDispatch();
	const [toCountryList, setToCountryList] = useState([]);
	const [clickedBtn, setClickedBtn] = useState("");
	const [fromCountryList, setFromCountryList] = useState([]);
	const [isValid, setIsValid] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [formData, setFormData] = useState({
        province: "",
        gst: 0,
        hst: 0,
        pst: 0,
	});

	const adminTaxList = useSelector((state) => state.taxList.taxList);

	useEffect(() => {
		dispatch(fetchTaxList());
	}, []);

	useEffect(() => {
		dispatch(fetchTaxList());
	}, [dispatch]);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleFieldChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
	};

	const handleSubmit = async (e) => {
		  
		e.preventDefault();
		if(formData.province !== '' && formData.gst !== '' && formData.hst !== '' && formData.pst !== '')
		{
			try {
				// Fetch data from API
				const response = await Request.post('admin/rate/tax', formData, true);
				if (response.message === "Success") {
					handleCloseDialog();
					dispatch(fetchTaxList())
					setFormData({
                        province: "",
						gst: 0,
						hst: 0,
						pst: 0,
					  });
				} else {
					console.error('Failed to fetch data from API');
				}
			} catch (error) {
				console.error('Error fetching data from API:', error);
			}	
		}else{
			alert("Please fill all the fields");
		}
		
	};

	return (
		<Box className="adminInnerPage shippingPricePage">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
                    Tax List
				</Typography>

				<Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Tax
				</Button>
			</div>

			<div className="shippingPricePage__table-container">
				<TaxListTable list={adminTaxList}/>
			</div>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit= {handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
					Add Tax
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
                            <Form.Group
                                        controlId="province"
                                        className="col-md-6 add-category-form__field userField select-field"
                                    >
                                        <Form.Label>Province</Form.Label>
										<Form.Select
											name="province"
											value={formData.province}
											onChange={(e) =>
                                                handleFieldChange(
                                                    "province",
                                                    e.target.value
                                                )
                                            }
											required
										>
											<option value="">Select Province</option>
											<option value="Alberta">Alberta</option>
											<option value="British Columbia">British Columbia</option>
											<option value="Manitoba">Manitoba</option>
											<option value="New Brunswick">New Brunswick</option>
											<option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
											<option value="Northwest Territories">Northwest Territories</option>
											<option value="Nova Scotia">Nova Scotia</option>
											<option value="Nunavut">Nunavut</option>
											<option value="Ontario">Ontario</option>
											<option value="Prince Edward Island">Prince Edward Island</option>
											<option value="Quebec">Quebec</option>
											<option value="Saskatchewan">Saskatchewan</option>
											<option value="Yukon">Yukon</option>
										</Form.Select>
                                 </Form.Group>
                                 <Form.Group
                                        controlId="gst"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>GST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="GST"
                                            min={0}
                                            value={formData.gst}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "gst",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="hst"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>HST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="HST"
                                            min={0}
                                            value={formData.hst}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "hst",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
                                 <Form.Group
                                        controlId="pst"
                                        className="col-md-6 add-category-form__field userField"
                                    >
                                        <Form.Label>PST</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="PST"
                                            min={0}
                                            value={formData.pst}
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    "pst",
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                 </Form.Group>
							</div>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Add
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			
		</Box>
	);
}

export default TaxList;
