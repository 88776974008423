import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Col, Row, Form, } from 'react-bootstrap';
import Request from '../customUtils/Http/Request';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import { Login } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

const MyAddresses = () => {

    let login = checkLogin()
    const minimized = useSelector(state => state.cart.minimized);

    return (
        <>
            {login === true ? (
                <Box className="main">
                    <Sidebar />
                    <Box className={`orderContainer ${minimized ? 'dashContainer' : 'containerExpand'}`}>
                        <DashboardHeader />
                        <Container maxWidth="l" className='s-container'>
                            <Box className='middle_block middle_coming_block coming_soon_loginBox' textAlign="center">
                                <Box>
                                    <img src='/assets/img/comingSoon.svg' className='comingSoon_img'></img>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            ) : (
                <>
                    <Login />
                </>
            )}
        </>
    )
};


export default MyAddresses;