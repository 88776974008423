import Cookies from 'universal-cookie';
import Request from '../customUtils/Http/Request';
const cookies = new Cookies();

export const FetchStripePublicKeyFromAPI = async () => {
  try {
    const response = await Request.get(`utils/getStripePublishableKey`, []);
    
        if (response && response.publishableKey) {
          return response.publishableKey;
        }}catch (error) {
        throw new Error('Failed to fetch Stripe public key');
}
};