import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Typography,
	TableSortLabel,
} from "@mui/material";
import Modal from '@mui/material/Modal';
import { Form, ModalBody } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PendingOrdersTable from "./PendingOrdersTable";
import LoginDetailsForm from "./LoginDetailsForm";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import {
	updateCustomer,
	deleteCustomer,
	fetchCustomers,
} from "../redux/customerSlice";
import { FormatPrice } from "../commonFunctions/commonPriceFunction";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function formatDate(timestamp) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];
	const dateObj = new Date(timestamp);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const day = dateObj.getDate().toString().padStart(2, '0');
	return `${month} ${day} ${year}`;
}

const CustomersOrderTable = () => {
	// const [customers, setCustomers] = useState([]);
	const [editModalOpen, setEditModalOpen] = useState(false);
	// const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [selectedCustomerAddress, setSelectedCustomerAddress] = useState([]);
	const [orderHistory, setOrderHistory] = useState([]);
	const dispatch = useDispatch();
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [orderBy, setOrderBy] = useState('name');
	const [order, setOrder] = useState('asc');
	// const [order, setOrderDate] = useState('desc');
    // const [orderBy, setOrderDateBy] = useState('createdAt');

	// Use useSelector to access the customers array from the Redux store
	const customer = useSelector((state) => state.customer.customers);

	const [editedCustomer, setEditedCustomer] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
	});

	const handleEmailChange = (e) => {
		setEditedCustomer({
			...editedCustomer,
			email: e.target.value,
		});
	};

	const handleNameChange = (e) => {
		const fullName = e.target.value;
		const nameParts = fullName.split(' ');
		const updatedFirstName = nameParts[0] || ''; // Default to empty string if no first name
		const updatedLastName = nameParts.slice(1).join(' ') || ''; // Default to empty string if no last name
		setEditedCustomer({
			...editedCustomer,
			firstName: updatedFirstName,
			lastName: updatedLastName
		});
	};

	const handlePhoneChange = (e) => {
		setEditedCustomer({
			...editedCustomer,
			phone: e.target.value,
		});
	};

	const handleEdit = (id) => {
		fetchCustomerData(id);
		setEditModalOpen(id);
	};

	const fetchCustomerData = async (id) => {
		try {
			// Fetch data from API
			const response = await Request.get(`admin/user/${id}`, [], true);
			if (response) {
				setSelectedCustomer(response.user);
				setSelectedCustomerAddress(response.user.address);
				setEditedCustomer({
					firstName: response.user.firstName,
					lastName: response.user.lastName,
					email: response.user.email,
					phone: response.user.phone
				})
				setOrderHistory(response.order);
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}
	};

	const openDeleteModal = (customer) => {
		setSelectedCustomer(customer);
		setIsDeleteDialogOpen(true);
	};

	// Function to close the confirmation modal
	const closeDeleteDialog = () => {
		setSelectedCustomer(null);
		setIsDeleteDialogOpen(false);
	};

	const handleEditModalClose = () => {
		setSelectedCustomer(null);
		setEditModalOpen(false);
	};


	const handleSaveEdit = () => {
		if (selectedCustomer) {
			dispatch(updateCustomer({ id: selectedCustomer.id, item: editedCustomer }))
				.then((result) => {
					if (updateCustomer.fulfilled.match(result)) {
						dispatch(fetchCustomers());
						handleEditModalClose();
					} else {
					}
				})
				.catch((error) => {
					console.error("Error updating customer:", error);
				});
		}
	};

	const handleDelete = async () => {
		if (selectedCustomer) {
			dispatch(deleteCustomer(selectedCustomer.id))
				.then((result) => {
					if (deleteCustomer.fulfilled.match(result)) {
						closeDeleteDialog(); 
					} else {
						console.error(`Failed to delete customer with ID ${selectedCustomer.id}.`);
					}
				})
				.catch((error) => {
					console.error("Error deleting customer:", error);
				});
		}
	};

	useEffect(() => {
		dispatch(fetchCustomers());
	}, []);

	useEffect(() => {
		dispatch(fetchCustomers());
	}, [dispatch]);

	useEffect(() => {
		// Load the component with the 'name' column sorted in ascending order
		handleRequestSort('name', 'asc');
	  }, []);

	const getFormattedAddress = (address) => {
		return `${address.fullName}\n${address.address_line_1}\n${address.address_line_2}\n${address.city}, ${address.state}, ${address.zip}\n${address.country}`;
	};

	if (customer && customer.length > 0) {
		var sortedData = [...customer].sort((a, b) => {
		const aName = a.firstName || ''; // Provide a default value if 'name' is undefined
		const bName = b.firstName || ''; // Provide a default value if 'name' is undefined
		
		const isAsc = order === 'asc';
		return isAsc ? aName.localeCompare(bName) : bName.localeCompare(aName);
	});
	}else{
		var sortedData =  customer;
	}

	const sortedDataList = React.useMemo(() => {
        return [...sortedData].sort((a, b) => {
          if (order === 'asc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
          } else {
            return b[orderBy] > a[orderBy] ? 1 : -1;
          }
        });
      }, [order, orderBy, sortedData]);


	const handleRequestSort = (property) => {
        const isAscDate = orderBy === property && order === 'asc';
        setOrder(isAscDate ? 'desc' : 'asc');
        setOrderBy(property);
      };

	const getSortingIcon = (column) => {
		if (orderBy === column) {
			return order === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;
		}
		return null;
	}

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	  };

	return (
		<>
			<div className="order-table-container customer-table-container">
				<TableContainer component={Paper} className="customer-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell max-width="70px">Sr No</TableCell>
								<TableCell>ISL ID</TableCell>
								<TableCell className="left-align">
									<TableSortLabel
										active={orderBy === 'name'}
										direction={orderBy === 'name' ? order : 'asc'}
										onClick={() => handleRequestSort('name')}
									>
										Name
										{getSortingIcon('name')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="left-align">E-mail ID</TableCell>
								<TableCell>Phone</TableCell>
								<TableCell>
								<TableSortLabel
                                            active={orderBy === 'createdAt'}
                                            direction={orderBy === 'createdAt' ? order : 'asc'}
                                            onClick={() => handleRequestSort('createdAt')}
                                        >
                                            Created On
                                            {getSortingIcon('createdAt')}
                                    </TableSortLabel>
								</TableCell>
								<TableCell>Total Orders</TableCell>
								<TableCell>&nbsp;</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{(sortedDataList && sortedDataList.length>0) && (
							<>
								{sortedDataList.map((customer,index) => (
									<TableRow key={customer.id}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>#ISL{customer.islNumber}</TableCell>
										<TableCell className="cursor-pointer font-bold text-underline left-align" onClick={() => handleEdit(customer.id)}>{capitalizeFirstLetter(`${customer.firstName} ${customer.lastName}`)}</TableCell>
										<TableCell className="left-align">{customer.email}</TableCell>
										<TableCell>{customer.phone}</TableCell>
										<TableCell>{formatDate(customer.createdAt)}</TableCell>
										<TableCell>
											{FormatPrice(customer.totalPurchaseValue) !== 'N/A' ? '$' : ''}{FormatPrice(customer.totalPurchaseValue) || '0.00'}
										</TableCell>
										<TableCell className="customer-table__action">
											<span
												onClick={() => handleEdit(customer.id)}
												className="customer-table__action--edit"
											>
												Edit
											</span>
											<span className="customer-table__action--devider">
												{" "}
												|{" "}
											</span>
											<span
												onClick={() => openDeleteModal(customer)}
												// onClick={() =>
												// 	handleDelete(customer.id)
												// }
												className="customer-table__action--delete"
											>
												Delete
											</span>
										</TableCell>
									</TableRow>
								))}
							</>
						)}
						</TableBody>
					</Table>
				</TableContainer>

				<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						<p>Are you sure you want to delete this customer?</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDelete} color="primary">
							OK
						</Button>
						<Button onClick={closeDeleteDialog} color="primary">
							Cancel
						</Button>
					</DialogActions>
				</Dialog>

			</div>

			{/* Edit Modal */}
			<Dialog
				open={editModalOpen}
				onClose={handleEditModalClose}
				className="admin-modal admin-dialog"
			>
				<DialogTitle className="admin-modal__title-container">
					{selectedCustomer && <> {selectedCustomer.fullName} ({selectedCustomer.islNumber}) </>}
					<Button
						onClick={handleSaveEdit}
						color="primary"
						className="btn"
					>
						Save
					</Button>
				</DialogTitle>
				<DialogContent>
					{selectedCustomer && (
						<>
							<div className="customers-modal__customer-form">
								<Form className="edit-order-form customer-form">
									<div className="customer-form__fields">
										<Form.Group
											controlId="formEmail"
											className="edit-order-form__field"
										>
											<Form.Label>
												Email Address
											</Form.Label>
											<Form.Control
												type="email"
												value={editedCustomer.email}
												onChange={handleEmailChange}
											/>
										</Form.Group>

										<Form.Group
											controlId="formPhone"
											className="edit-order-form__field"
										>
											<Form.Label>
												Phone Number
											</Form.Label>
											<Form.Control
												type="text"
												value={editedCustomer.phone}
												onChange={handlePhoneChange}
											/>
										</Form.Group>
									</div>

									{selectedCustomer.addresses && selectedCustomer.addresses.length !== 0 && (
										<Form.Group
											controlId="formAddress"
											className="edit-order-form__field edit-order-form__field--addresses"
										>
											<Form.Label>Addresses</Form.Label>
											<div className="customer-form__address-modify-container">
												{selectedCustomer.addresses && selectedCustomer.addresses.map((address, index) => (
													<div class="customer-form__address-modify" key={index}>
														<Form.Control controlId={`formAddress${index}`} className="edit-order-form__field edit-order-form__field--addresses"
															as="textarea"
															value={getFormattedAddress(address)}
															readOnly>
														</Form.Control>

														{/* <IconButton
														aria-label="edit"
														size="small"
														className="customer-form__address-modify-edit"
													>
														<EditIcon />
													</IconButton>

													<IconButton
														aria-label="delete"
														size="small"
														className="customer-form__address-modify-delete"
													>
														<DeleteIcon />
													</IconButton> */}
													</div>
												))}

											</div>
										</Form.Group>
									)}

								</Form>
							</div>

							{orderHistory.length !== 0 &&
								<div className="admin-modal__order-history">
									<Typography
										variant="h6"
										className="section-title"
									>
										Order History
									</Typography>
									<div className="order-table-container">
										<TableContainer component={Paper}>
											<Table className="sortable-table">
												<TableHead>
													<TableRow>
														<TableCell>
															<label
															>
																Order Id
															</label>
														</TableCell>
														<TableCell>
															<label
															>
																Order Date
															</label>
														</TableCell>
														{/* <TableCell>
														<label
														>
															Item
														</label>
													</TableCell> */}
														<TableCell>
															<label
															>
																Payment
															</label>
														</TableCell>
														<TableCell
														> <label
														>
																Status
															</label></TableCell>

													</TableRow>
												</TableHead>
												<TableBody>
												{(orderHistory && orderHistory.length>0) && (
													<>
													{orderHistory.map((box, rowIndex) => (
														<TableRow key={box.id}>
															<TableCell>{box.invoiceNo}</TableCell>
															<TableCell>{formatDate(box.createdAt)}</TableCell>
															<TableCell>${FormatPrice(box.totalPrice)}</TableCell>
															<TableCell>
																{box.orderStatus === 0 && "Incomplete"}
																{box.orderStatus === 1 && "Pending"}
																{box.orderStatus === 2 && "Ready to Pickup"}
																{box.orderStatus === 3 && "Picked Up"}
																{box.orderStatus === 4 && "ready to ship"}
																{box.orderStatus === 5 && "Shipped"}
																{box.orderStatus === 6 && "Delivered"}
																{box.orderStatus === 7 && "Canceled"}

															</TableCell>
														</TableRow>
													))}
													</>
												)}
												</TableBody>
											</Table>
										</TableContainer>
									</div>
								</div>
							}


							{/* <div className="admin-modal__pending-action">
								<Typography
									variant="h6"
									className="section-title"
								>
									Pending Action
								</Typography>
								<PendingOrdersTable
									orders={customersData}
									onEdit={handleEdit}
									onDelete={handleDelete}
								/>
							</div> */}

							<div className="admin-modal__login-details">
								<Typography
									variant="h6"
									className="section-title"
								>
									Log in details
								</Typography>
								<Form className="edit-order-form customer-form">
									<div className="customer-form__fields">
										<Form.Group
											controlId="formName"
											className="edit-order-form__field"
										>
											<Form.Label>Name</Form.Label>
											<Form.Control
												type="text"
												value={`${editedCustomer.firstName} ${editedCustomer.lastName}`}
												onChange={handleNameChange}
											/>
										</Form.Group>
										<Form.Group
											controlId="formAccount"
											className="edit-order-form__field"
										>
											<Form.Label>
												Account Created on
											</Form.Label>
											<Form.Control
												type="text"
												value={formatDate(selectedCustomer.createdAt)}
												readOnly
											/>
										</Form.Group>
										<Form.Group
											controlId="formEmail"
											className="edit-order-form__field"
										>
											<Form.Label>
												Email Address
											</Form.Label>
											<Form.Control
												type="email"
												value={editedCustomer.email}
												onChange={handleEmailChange}
											/>
										</Form.Group>
										<Form.Group
											controlId="formPhone"
											className="edit-order-form__field"
										>
											<Form.Label>
												Phone Number
											</Form.Label>
											<Form.Control
												type="text"
												value={editedCustomer.phone}
												onChange={handlePhoneChange}
											/>
										</Form.Group>
									</div>
								</Form>
							</div>
						</>
					)}
				</DialogContent>
				{/* <DialogActions>
					<Button onClick={handleEditModalClose}>Cancel</Button>
					<Button onClick={handleSaveEdit} color="primary">
						Save
					</Button>
				</DialogActions> */}
			</Dialog>


		</>
	);
};

export default CustomersOrderTable;
