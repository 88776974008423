import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import Request from "../customUtils/Http/Request";

function GeneralSetting() {
  const [insuranceText, setInsuranceText] = useState("");
  const [insurancePrice, setInsurancePrice] = useState();
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    try {
      const response = await Request.get(`admin/general-setting`, [], true);

      if (response.length !== 0) {
        setInsuranceText(response[0].value);
        setInsurancePrice(response[1].value);
      }
    } catch (error) {
      console.error("Error deleting row from API:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmationDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirm = async () => {
    setConfirmationDialogOpen(false);
    const data = [
      {
        key: "insurance_text",
        value: insuranceText,
      },
      {
        key: "insurance_price",
        value: insurancePrice,
      },
    ];
    try {
      const response = await Request.post(`admin/general-setting`, data, true);
      if (response.status === 200) {
      } else {
        console.error("Failed to fetch data from API");
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  return (
    <Box className="adminInnerPage paymentPage">
      <form onSubmit={handleSubmit}>
        <div className="title-button__container">
          <Typography variant="h2" className="adminInnerPage__title">
            General Setting
          </Typography>

          <Button
            variant="primary"
            color="primary"
            className="btn btn-secondary"
            type="submit"
          >
            Save
          </Button>
        </div>

        <div className="paymentPage__keys">
          {/* <Typography variant="h6" className="section-title">
						Stripe Keys
					</Typography> */}

          {/* <StripApiKeyForm /> */}
          <Box className="paymentKeysForm generalSettingForm">
            <Form.Group
              controlId="publishableKey"
              className="paymentKeysForm__field"
            >
              <Form.Label>Insurance Text</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={insuranceText}
                onChange={(e) => setInsuranceText(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group
              controlId="publishableKey"
              className="paymentKeysForm__field"
            >
              <Form.Label>Insurance Price</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={insurancePrice}
                onChange={(e) => setInsurancePrice(e.target.value)}
                required
              />
            </Form.Group>
          </Box>
        </div>
      </form>

      <Dialog open={isConfirmationDialogOpen} onClose={handleCloseDialog}>
        <DialogContent>Are you sure you want to edit settings?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default GeneralSetting;
