import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DriverTable from "./DriverTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { approveDriver, pendingDriver, rejectedDriver } from "../redux/driverSlice";

function DriverList() {
	const [selectedTab, setSelectedTab] = useState(0);
	const [approvedDriverInfo, setApprovedDriverInfo] = useState([]);
	const [pendingDriverInfo, setPendingDriverInfo] = useState([]);
	const [rejectedDriverInfo, setRejectedDriverInfo] = useState([]);
	const dispatch = useDispatch();

	const approveDriverList = useSelector((state) => state.driver.approveDrivers);
	const pendingDriverList = useSelector((state) => state.driver.pendingDrivers);
	const rejectedDriverList = useSelector((state) => state.driver.rejectedDrivers);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	useEffect(() => {
		switch (selectedTab) {
			case 0:
				dispatch(approveDriver());
				break;
			case 1:
				dispatch(pendingDriver());
				break;
			case 2:
				dispatch(rejectedDriver());
				break;
			default:
				dispatch(approveDriver());
				break;
		  }
	}, [selectedTab]);


	return (
		<>
			<Box className="adminInnerPage driverListPage">
				<Typography variant="h2" className="adminInnerPage__title">
					Driver List
				</Typography>

				<Box className="adminInnerPage__tabs">
					<Tabs value={selectedTab} onChange={handleTabChange}>
						<Tab label="Approved" />
						<Tab label="Pending For Approval" />
						<Tab label="Rejected" />
					</Tabs>
				</Box>

				<Box className="adminInnerPage__tabs-content">
					{selectedTab === 0 && (
						<Typography component="div" role="tabpanel">
							<div className="order-search-filter">
								<div className="order-search-filter__search">
									{/* <input
										type="text"
										placeholder="Search For Driver"
									/> */}
								</div>
							</div>

							<DriverTable driverInfo={approveDriverList} type="Approved"/>
						</Typography>
					)}
					{selectedTab === 1 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<DriverTable driverInfo={pendingDriverList} type="Pending for approval"/>
					</Typography>
					)}
					{selectedTab === 2 && (
						<Typography component="div" role="tabpanel">
						<div className="order-search-filter">
							<div className="order-search-filter__search">
								{/* <input
									type="text"
									placeholder="Search For Driver"
								/> */}
							</div>
						</div>

						<DriverTable driverInfo={rejectedDriverList} type="Rejected"/>
					</Typography>
					)}
				</Box>
			</Box>
		</>
	);
}

export default DriverList;
