import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form } from 'react-bootstrap';
import Header from '../header/header';
import Footer from '../footer/footer';

const VacancyDetails = () => {
    const [vacancyDetails, setVacancyDetails] = useState({});
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('vacancy');
        fetchVacancyDetails(id)
      }, []);
  
      const fetchVacancyDetails = async (id) => {
        try {
            let response = await Request.get(`utils/career/${id}`,[]);
             
            if (Object.keys(response).length !== 0) {
                 
                setVacancyDetails(response)
                // setLoading(false);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
            // setLoading(true);
        }
      };

      const DatabaseResponseComponent = ({ htmlContent }) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        );
      };

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
            <Box className="MuiBox-root css-pxpt32">
                {Object.keys(vacancyDetails).length !== 0 ?
                (
                    <>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                                <Typography className='color2 font-bold font-45 line-50'>{vacancyDetails.title}</Typography>
                            </Col>
                        </Row>
                        <Box sx={{p: 3,'@media (max-width: 600px)': {p: 1, pt: 3},}} className="vacancyBox vacancyDetailBox">
                            <Box>
                                <Typography className='font-24 font-600 color1'>
                                    <DatabaseResponseComponent htmlContent={vacancyDetails.description} />
                                </Typography>
                            </Box>
                        </Box>
                    </>
                ):(
                    <Box textAlign="left" sx={{mt: 3, minHeight: "176px"}}>
                        <Typography className='font20 font-600 color4'>
                            No Vacancies Available
                        </Typography>
                    </Box>
                )}
                    
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default VacancyDetails;