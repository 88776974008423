import { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent } from '@mui/material';
import { connect } from 'react-redux';
// import { addToCart, removeFromCart } from '../commonFunctions/actions';
// import Checkout from '../checkout/checkoutForm';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Request from '../customUtils/Http/Request';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Col, Row } from 'react-bootstrap';
import { checkLogin } from '../commonFunctions/commonFunctions';
import { setCart, fetchCartItems, removeItemAsync, incQuantityAsync, decQuantityAsync, cartToggle } from '../redux/cartSlice'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';

const cookies = new Cookies();

const pages = [
  { label: 'Home', path: '/' },
  { label: 'About Us', path: '/about-us' },
  { label: 'What We Provide', path: '/services' },
  { label: 'Shop', path: '/user/buy/buy-product' },
  { label: 'Pricing', path: '/pricing' },
  { label: 'Contact us', path: '/contact-us' },
];
const settings = [
  { id: 1, name: 'Dashboard' },
  { id: 2, name: 'Profile' },
  { id: 3, name: 'My Addresses' },
  { id: 4, name: 'Logout' },
];

const useStyles = makeStyles((theme) => ({
  // activeMenuItem: {
  //   color: '#DB2D6A !important', // Change the color to your desired active color
  // },
  // manuItemBox: {
  //   '& > button:nth-child(3)': {
  //     textDecoration: "underline",
  //     fontSize: "22px !important",
  //     fontWeight: "600 !important"
  //   },
  // }
}));

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [profileBox, setProfileBox] = useState(false);
  // const [cartPopup, setCartPopup] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [userType, setUserType] = useState(0);
  const [cartInfo, setCartInfo] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [maxText, setMaxText] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  let timeoutId = null;
  const cart = useSelector(state => state.cart);
  const isOpen = useSelector(state => state.cart.isOpen);
  const isScreenBelow992px = useMediaQuery('(max-width:1200px)');
  // setCartInfo(cart)

  let login = checkLogin()

  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    let userType = cookies.get('userType', { path: '/' });
    const userTypeAsNumber = parseInt(userType, 10);
    setUserType(userTypeAsNumber)
  }, [])


  const handleScroll = () => {
    const scrollThreshold = window.innerHeight * 0.40;
    setScrolled(window.scrollY > scrollThreshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleContactPage = () => {
      window.location.href = '/contact-us'
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSubMenu = () => {
    // setIsSubMenuOpen(!isSubMenuOpen);
  }

  const handleSubMenuHover = () => {
    setIsSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };

  // const openCartPopup = () =>{
  //   setCartPopup(!cartPopup)
  // }

  const handleCartIconClick = (e) =>{
    e.stopPropagation();
    // e.stopPropagation();
    dispatch(cartToggle());
  }
  // const openCartPopupInside2 = (e) =>{
  //   setCartPopup(!cartPopup)
  // }

  const handleRemoveItem = (cartItemId) => {
    dispatch(removeItemAsync(cartItemId)); // Dispatch async thunk to remove item
  };

  const incQuantity = (cartItemId,quantity,maxQuantity) => {
    if(maxQuantity > 10){
      var maxLimitNew = 10;
    }else{
      var maxLimitNew = maxQuantity;
    }

    if (quantity < maxLimitNew) {
      dispatch(incQuantityAsync(cartItemId));
    }else {
      // Show an error dialog when the quantity exceeds the maxQuantity
      setDialogOpen(true);
      setMaxText(maxLimitNew); // Set maxText to the maxQuantity value
    }
  };
  
  const decQuantity = (cartItemId,quantity,maxLimitNew) => {
    if (quantity > 1) {
      dispatch(decQuantityAsync(cartItemId));
    }
  };


  useEffect(() => {
    var access_token = cookies.get('accessToken', { path: '/' });
    var refresh_token = cookies.get('refreshToken', {path: "/"});
    if (access_token && access_token !== "null" && access_token !== "undefined") {
      setProfileBox(true)
    }
  }, []);

  useEffect(() => {
    // Fetch cart items from the API for logged-in users
    if (login) {
      dispatch(fetchCartItems()); // Dispatch the action to fetch cart items from the API
    }
  }, [dispatch, login]);

  useEffect(() => {
    // Fetch cart items from cookies for non-logged-in users
    if (!login) {
      const cartItemsFromCookies = cookies.get('cartItems', {path: '/'});
      if (cartItemsFromCookies) {
        dispatch(setCart(cartItemsFromCookies)); // Dispatch the action to set cart items from cookies
      }
    }
  }, [dispatch, login]);

  const profileHandle = (id) => {
    switch (id) {
      case 1:
          
        if (userType === 1) {
          navigate('/admin/orders');
        } else if (userType === 3) {
          navigate('/user/customer/dashboard');
        } else if (userType === 4) {
          navigate('/driver/orders');
        }
        else{
          navigate('/login')
        }
        break;
      case 2:
        if (userType === 1) {
          navigate('/admin/orders');
        } else if (userType === 3) {
          navigate('/user/customer/dashboard');
        } else if (userType === 4) {
          navigate('/driver/orders');
        }
        else{
          navigate('/login')
        }
        navigate('/user/customer/profile');
        break;
      case 3:
        navigate('/user/customer/profile?tab=address');
        break;
      case 4:
        cookies.remove("accessToken", {path: "/"});
        cookies.remove("refreshToken", {path: "/"});
        cookies.remove("userType", {path: "/"});
        navigate('/login');
        break;
      default:
    }
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if(isOpen && cartRef.current && !cartRef.current.contains(event.target)) {
        dispatch(cartToggle()); // Close the cart when clicked outside
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, dispatch]);

  const handleCartInsideClick = () =>{
    dispatch(cartToggle(true));
  }

  const handleMouseLeaveCart = () => {
    if (isOpen) {
      dispatch(cartToggle()); // Close the cart popup when cursor leaves the cartPopup
    }
  };

  return (
    <AppBar position="static" className='menuHeaderTop'>
      <Container maxWidth="xl">
        <Toolbar disableGutters className='menuHeaderTopAppBar'>
          <Box className="logo-image-section">
            <Link to="/">
              <img src={scrolled ? '/assets/img/web_logo.svg' : '/assets/img/web_logo.png'} className={scrolled ? 'logo_mob_img' : 'logo_img'}></img>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none', zIndex: "9999" },
                }}
              >
                {pages.length > 0 && pages.map((page) => (
                  <MenuItem key={page.path} onClick={handleCloseNavMenu}>
                    <Link to={page.path}>
                      <Typography textAlign="center" className="color2">{page.label} </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="manuItemBox">
            {pages.map((page, index) => (
              <Box
              key={page.path}
              onMouseEnter={page.label === 'Shop' && page.path === '/user/buy/buy-product' ? handleSubMenuHover : undefined}
              onMouseLeave={page.label === 'Shop' && page.path === '/user/buy/buy-product' ? handleMouseLeave : undefined}
              className='submenuOuter'
            >
              <Link to={page.path} className='MenuLink'>
                <Button
                  onClick={handleSubMenu}
                  sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'Open Sans, sans-serif !important', fontSize: '19px !important' }}
                  color={location.pathname === page.path ? 'secondary' : 'inherit'}
                  className={location.pathname === page.path ? "headerText activeMenuItem" : 'headerText'}
                >
                  {page.label}
                </Button>
              </Link>
              {page.label === 'Shop' && page.path === '/user/buy/buy-product' && isSubMenuOpen && (
                <Box className="submenu">
                  <a href='/user/buy/buy-product#barrels'>
                    <Button className='font-19 color2' align="left">Empty Barrels</Button>
                  </a>
                  <a href='/user/buy/buy-product?section=accessories'>
                    <Button className='font-19 color2' align="left">Accessories</Button>
                  </a>
                </Box>
              )}
              </Box>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }} className="right-header-section">
          <IconButton sx={{ p: 0 }} className='avatarBox' onClick={handleCartIconClick}>  
                  {/* <IconButton sx={{ p: 0 }} className='avatarBox' onClick={() => navigate("/checkout")}> */}
                  {/* <Link to="/user/buy/buy-item-cart"> */}
                    <Box>
                      <Avatar alt="Cart" src="/assets/img/cart_img.png" className='headerAvatarIcon'>
                      </Avatar>
                      {cart && cart.cartItems && cart.cartItems.length > 0 ? (
                         <p className='cartIemCount' id="cartItem">{cart.cartItems.length}</p>
                      ): (
                        <p className='cartIemCount' id="cartItem">0</p>
                      )}
                      
                    </Box>
                  {/* </Link> */}
            {/* {isOpen === true &&
            ( */}
            <Box ref={cartRef} className={`cartPopup ${isOpen ? 'open' : ''}`} onClick={handleCartInsideClick} onMouseLeave={handleMouseLeaveCart}>
              <Box sx={{pl: 3, pr:3}}>
                <Typography className='colo
                r2 font-19 font-bold' align='left'>My Cart</Typography>
              </Box>
              <Box className="cartInnerSection">
                {(cart && cart.cartItems && cart.cartItems.length >0) ? (
                    <>
                        {cart.cartItems.map((item) => (
                          <Row className='cartRow' key={item.cartItemId}>
                            <Col xs={12} sm={12} md={3} lg={3} className='cartFlexBox cartFlexCol'>
                              <Box sx={{ width: "60px", height: "65px", position: "relative" }}>
                                    {/* `<img src="/assets/img/product_img.png" alt='product_img' className="cartImg"/>` */}
                                    {item.barrel.images && item.barrel.images.length >= 1 ? (
                                       <>
                                       <div
                                       className="product_cart_img"
                                       alt="product_img"
                                       style={{
                                         backgroundImage: `url("${process.env.REACT_APP_DatabaseStatic}${item.barrel.images[0].img.replace(/\\/g, '/').replace(/ /g, '%20')}")`,
                                         backgroundRepeat: "no-repeat",
                                         backgroundSize: "cover",
                                         backgroundPosition: "center",
                                        }}
                                     />
                                     <div className="aspect-ratio"></div>
                                     </>
                                    ):(
                                      <>
                                      <div
                                      className="product_cart_img"
                                      style={{ backgroundImage: 'url("/assets/img/no_image_item.png")',
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center", }}
                                    />
                                    <div className="aspect-ratio"></div>
                                    </>
                                    )}
                              </Box>

                            </Col>
                            <Col xs={12} sm={12} md={7} lg={7}>
                              <Box align="left">
                                <Typography className='color2 font-15 font-bold'>{item.barrel.name}</Typography>
                                <Typography className='color2 font-15 font-bold'>
                                <Box className="flexBox">
                                  <Typography className='color1 font-12 font-bold'>Qty :</Typography>
                                  <Box>
                                      <Box className="quantityMain">
                                      {item.barrel.stockManagement === false ? (
                                        <>
                                          <Box onClick={() => decQuantity(item.cartItemId,item.quantity,10)} disabled={item.quantity <= 1} min="1">
                                              <Button className="productRemove" disabled={item.quantity <= 1}>
                                                  <RemoveIcon className="actionIcon" />
                                              </Button>
                                          </Box>
                                          <input className="quantityInput" value={item.quantity}></input>
                                          <Box onClick={() => 
                                            incQuantity(item.cartItemId,item.quantity,10)} disabled={item.quantity >= 10}>
                                              <Button className="productAdd">
                                                  <AddIcon className="actionIcon" />
                                              </Button>
                                          </Box>
                                        </>
                                      ):(
                                        <>
                                          <Box onClick={() => decQuantity(item.cartItemId,item.quantity,10)} disabled={item.quantity <= 1} min="1">
                                              <Button className="productRemove" disabled={item.quantity <= 1}>
                                                  <RemoveIcon className="actionIcon" />
                                              </Button>
                                          </Box>
                                          <input className="quantityInput" value={item.quantity}></input>
                                          <Box onClick={() => 
                                            incQuantity(item.cartItemId,item.quantity,item.barrel.stock)} disabled={item.quantity >= 10}>
                                              <Button className="productAdd">
                                                  <AddIcon className="actionIcon" />
                                              </Button>
                                          </Box>
                                        </>
                                      )}
                                      </Box>
                                    </Box>
                                </Box>
                                </Typography>
                                <Typography className='color1 font-15 font-600'>Price: ${item.barrel.price}</Typography>
                                <Typography className='color1 font-15 font-600'>Size: {item.barrel.size}</Typography>
                                <Typography className='color1 font-15 font-600'>TotalPrice: ${FormatPrice(item.totalPrice)}</Typography>
                              </Box>
                            </Col>
                            <Col xs={12} sm={12} md={2} lg={2} className='cartFlexBox'>
                              <IconButton aria-label="delete">
                                <DeleteIcon onClick={() => handleRemoveItem(item.cartItemId)}/>
                              </IconButton>
                            </Col>
                        </Row>
                        ))}
                      </>
                ) : (
                  <Box align="left" sx={{pt: 2, pl: 1}}>
                    <Typography>Your cart is empty.</Typography>
                  </Box>
                )}
              </Box>

              {cart && cart.cartItems && cart.cartItems.length > 0 ? (
              <Box sx={{pl: 3, pr:3, pt: 2}}>
                <Link to='/user/buy/buy-item-cart'>
                  <Typography className='color2 font-15 font-bold text-underline' align='center'>View Cart Detail</Typography>
                </Link>
                
              </Box>
              ): (
                <></>
              )}
            </Box>  
            {/* )}   */}

            <Link> 

            <Typography>

            </Typography>
            </Link>
            </IconButton>
            {profileBox ?
              <>
                {/* <IconButton sx={{ p: 0 }} className='avatarBox'>
                  <Avatar alt="Search" src="/assets/img/search_img.png" className='headerAvatarIcon'/>
                </IconButton> */}
                <Tooltip>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className='avatarBox'>
                    <Avatar alt="Profile" src="/assets/img/profile_img.png" className='headerAvatarIcon'/>
                  </IconButton>
                </Tooltip>
                
              </>
              :
              <>
             
                <Link to="/Login">
                  <Button className='homeBtn headerBtn'>Sign In</Button>
                </Link>
              </>
            }

            <Link to="/user/customer/order-history">
              {isScreenBelow992px ? (
                <img src="/assets/img/tracking_icon.svg" className='track_icon' /> 
              ):(
                <Button className='homeBtn headerBtn'>Track Shipment</Button>
              )}
            </Link>
            
            {profileBox ? <Menu
              sx={{ mt: '45px',zIndex: "99999" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              className='mobDropdownDashboard'
            >
              {settings.map((element, index) => (
                <MenuItem onClick={() => profileHandle(element.id)} key={index} sx={{pt: 0,pb: 0}}>

                  {(userType !== 3 && (index === 1 || index === 2)) ? (
                    <></>
                  ):(
                    <Typography key={element.id} textAlign="center" sx={{pb: 1, pt: 1}}>{element.name}</Typography>
                  )}
                </MenuItem>
              ))}
            </Menu> : null
            }
          </Box>
        </Toolbar>
      </Container>
          {/* Material UI Dialog for showing the error */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          The quantity cannot exceed {maxText}
        </DialogContent>
        <Button onClick={handleCloseDialog}>OK</Button>
        <Button onClick={handleContactPage}>Contact Us For Bulk Order</Button>
      </Dialog>

    </AppBar>
  );
}

// const mapStateToProps = state => ({
//   cartItems: state.cart.cartItems, // Mapping cartItems from the Redux store's state
// });

export default Header;
