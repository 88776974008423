import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import Form from "react-bootstrap/Form";
import ProductCategoriesTable from "./ProductCategoriesTable";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderAdminList } from "../redux/islanderListSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

function IslanderShippingList() {
    const [open, setOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const dispatch = useDispatch();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedProductEdit, setSelectedProductEdit] = useState(null);
    const [listData, setListData] = useState({
        "country": '',
        "status": false
    });
    const [editedCategoryData, setEditedCategoryData] = useState({
		"country": '',
		"status" : false
	});


    const islanderShippingList = useSelector((state) => state.islanderList.islanderAdminList);


    const handleAddOpenDialog = () => {
        setAddOpen(true);
    };

    const handleAddCloseDialog = () => {
        setAddOpen(false);
    };


    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };
    const handleNameChange = (e) => {
        const newName = e.target.value;
        setListData((prevData) => ({
            ...prevData,
            country: newName,
        }));
    };

    const handleVisibilityChange = (e) => {
        const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setListData((prevData) => ({ ...prevData, status: newVisibility }));
    };

    const handleNameChange1 = (e) => {
        const newName = e.target.value;
        setEditedCategoryData((prevData) => ({
            ...prevData,
            country: newName,
        }));
    };

    const handleVisibilityChange1 = (e) => {
        const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setEditedCategoryData((prevData) => ({ ...prevData, status: newVisibility }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
          
        try {
            // Fetch data from API
            const response = await Request.post('admin/island/location', listData, true);
            if (response.length !== 0) {
                setAddOpen(false)
                dispatch(fetchIslanderAdminList())
                setListData({
                    "country": '',
                    "status": false
                });
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();

        try {
              
            // Fetch data from API
            const response = await Request.put(`admin/island/location/${selectedProductEdit}`, editedCategoryData , true);
            if (response.status === 200) {
                setOpen(false)
                dispatch(fetchIslanderAdminList())
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    useEffect(() => {
        dispatch(fetchIslanderAdminList());
    }, [dispatch]);

    
    useEffect(() => {
        dispatch(fetchIslanderAdminList());
    }, []);

    const openDeleteModal = (categoryId) => {
		  
		setSelectedProduct(categoryId);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

    const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
        // setOpen(true);
		try {
			const response = await Request.get(`admin/island/location/${id}`, [], true);
			if (response.length !== 0) {
				if (response) {
					setOpen(true);
                    setEditedCategoryData({
						"country" : response.country,
						"status" : response.status
					})
					
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

    const productDelete = async () => {
		try {
			const response = await Request.delete(`admin/island/location/${selectedProduct}`, [], true);
			if (response) {
				if (response.status === 204) {
					setIsDeleteDialogOpen(false)
					dispatch(fetchIslanderAdminList())
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

    return (
        <Box className="adminInnerPage productCategories">
            <div className="title-button__container">
                <Typography variant="h2" className="adminInnerPage__title">
                    Island Shipping List
                </Typography>

                <Button
                    variant="primary"
                    color="primary"
                    className="btn btn-secondary"
                    onClick={handleAddOpenDialog}
                >
                    Add Island
                </Button>

                <Dialog
                    open={addOpen}
                    onClose={handleAddCloseDialog}
                    className="admin-modal admin-dialog"
                >
                    <form onSubmit={handleSubmit}>
                        <DialogTitle className="admin-modal__title-container">
                            Add Island
                            <Button color="primary" className="btn" type="submit">
                                Save
                            </Button>
                        </DialogTitle>
                        <DialogContent sx={{ padding: "30px" }}>
                            <Box className="add-product-form__container">
                                <Box className="edit-order-form add-product-form add-category-form">
                                    <div className="row add-product-form__row">
                                        <Form.Group
                                            controlId="countryName"
                                            className="col-md-6 add-category-form__field"
                                        >
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Country Name"
                                                value={listData.country}
                                                onChange={handleNameChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group
                                            controlId="productVisibility"
                                            className="col-md-6 add-category-form__field"
                                        >
                                            <Form.Label>Visibility</Form.Label>
                                            <div className="add-product-form__visibility">
                                                <Form.Check
                                                    type="radio"
                                                    label="Public"
                                                    name="visibility"
                                                    value="true"
                                                    checked={listData.status === true}
                                                    onChange={handleVisibilityChange}

                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Hide"
                                                    name="visibility"
                                                    value="false"
                                                    checked={listData.status === false}
                                                    onChange={handleVisibilityChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Box>
                            </Box>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>

            <div className="productListPage__container">
                {/* <div className="order-search-filter product-search-filter">
					<IconButton
						aria-label="edit"
						size="small"
						className="product-search-filter__icon"
					>
						<TuneIcon />
					</IconButton>
					<div className="order-search-filter__search">
						<input type="text" placeholder="Search ISland" />
					</div>
				</div> */}

                <Box className="order-table-container product-categories-table-container">
                    <TableContainer
                        component={Paper}
                        className="product-categories-table"
                    >
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell className="textLeft">Island</TableCell>
                                    <TableCell>Visibility</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(islanderShippingList && islanderShippingList.length>0) && islanderShippingList.map((item) => (
                                    <TableRow
                                        key={item.id}
                                    >
                                        <TableCell className="textLeft">{item.country}</TableCell>
                                        <TableCell
                                            className={
                                                item.status === false
                                                    ? "order-table-container__outofstock"
                                                    : " "
                                            }
                                        >
                                            <span className="product-categories-table__stock">
                                                {
                                                    item.status === false
                                                        ? "Hide"
                                                        : "Visible"
                                                }
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="Edit"
                                                onClick={() => openEditModal(item.id)}
                                            // Replace with your edit logic
                                            >
                                                <EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }} />
                                            </IconButton>
                                            <IconButton
                                                aria-label="Delete"
                                                onClick={() => openDeleteModal(item.id)} // Replace with your delete logic
                                            >
                                                <DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <p>Are you sure you want to delete this Island?</p>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => productDelete(selectedProduct)} color="primary">
                                OK
                            </Button>
                            <Button onClick={closeDeleteDialog} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                        className="admin-modal admin-dialog"
                    >
                        <form onSubmit={handleSubmit1}>
                            <DialogTitle className="admin-modal__title-container"	>
                                Edit Island
                                <Button color="primary" className="btn" type="submit">
                                    Save
                                </Button>
                            </DialogTitle>
                            <DialogContent sx={{ padding: "30px" }}>
                                <Box className="add-product-form__container">
                                    <Box className="edit-order-form add-product-form add-category-form">
                                        <div className="row add-product-form__row">
                                            <Form.Group
                                                controlId="productName"
                                                className="col-md-6 add-category-form__field"
                                            >
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Product Name"
                                                    value={editedCategoryData.country}
                                                    onChange={handleNameChange1}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                controlId="productVisibility"
                                                className="col-md-6 add-category-form__field"
                                            >
                                                <Form.Label>Visibility</Form.Label>
                                                <div className="add-product-form__visibility">
                                                    <Form.Check
                                                        type="radio"
                                                        label="Public"
                                                        name="visibility"
                                                        value="true"
                                                        checked={editedCategoryData.status === true}
                                                        onChange={handleVisibilityChange1}

                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Hide"
                                                        name="visibility"
                                                        value="false"
                                                        checked={editedCategoryData.status === false}
                                                        onChange={handleVisibilityChange1}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </form>
                    </Dialog>
                </Box>
            </div>
        </Box>
    );
}

export default IslanderShippingList;
