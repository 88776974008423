import React from 'react';

const ProgressBar = ({ totalChevrons, fillPercentage }) => {
    return (
        <ul className="step-menu">
            {(fillPercentage && fillPercentage.length>0) && fillPercentage.map((fillPercentage, index) => (
            <li
                key={index}
                className={`step ${index < fillPercentage ? 'filled' : ''}`}
                >
                <span>Step {index + 1}</span>
                {fillPercentage > 0 && (
                    <div
                    className={`step-fill ${fillPercentage > 0 ? 'filled' : ''}`}
                    style={{ width: `${fillPercentage}%` }}
                    ></div>
                )}
            </li>
            ))}
         </ul>
    );
}

export default ProgressBar;
