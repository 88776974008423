import { useEffect, useState, useRef } from 'react';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
// import OrderList from './orderList';
import { Link } from 'react-router-dom';
import OrderList from '../checkout/orderList';
import IslanderOrderInfo from './islanderOrderInfo';
import ProgressBar from '../commonComponents/progressbar';


const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },

    formPadding: {
        marginTop: "12px"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },

}));

const IslanderCheckout = () => {
    const classes = useStyles();
    // const [cartInfo, setCartInfo] = useState([]);
    // const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [shippingPrice, setShippingPrice] = useState(0);
    const [shipmentId, setShipmentId] = useState(null);
    const [productTotal, setProductTotal] = useState(null);
    const [productTotalPrice,setProductTotalPrice] = useState(0);
    const [totalBoxAccessoriesValue,setTotalBoxAccessoriesValue] = useState(0);
    const [boxDetail,setBoxDetail] = useState([]);
    const [insurance, setInsurance] = useState(null);
    const [checkoutError, setCheckoutError] = useState(false);
    const [addresses, setAddresses] = useState({
        senderAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        },
        billingAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        },
        billingBoolean: true,
        insurance: false
    });
    // Create a ref to store the latest addresses state
    const addressesRef = useRef(addresses);


    const fillPercentage = [100, 100, 100, 50];
    // Update the addressesRef whenever the addresses state changes
    useEffect(() => {
        addressesRef.current = addresses;
    }, [addresses]);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                 
                setShippingPrice(response.totalBoxValue);
                setTotalBoxAccessoriesValue(response.totalBoxAccessoriesValue);
                setShipmentId(response.id);
                setProductTotalPrice(response.totalPriorityProductValue)
                setBoxDetail(response.shipmentBox);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    useEffect(() => {
        fetchShppingDefaultAddress()
      }, []);
    
      const fetchShppingDefaultAddress = async () => {
              
            // setIsDialogOpen(true);
                try {
            const response = await Request.get(`user/getDefaultAddresses`, [], true);
            if (Object.keys(response).length > 0) {
                
                response.senderAddresses.forEach((address) => {
                  var dataFirst_name = address.first_name;
                  var dataLast_name = address.last_name;
                  var dataAddress_line_1 = address.address_line_1;
                  var dataAddress_line_2 = address.address_line_2;
                  var dataCity = address.city;
                  var dataState = address.state;
                  var dataCountry = address.country;
                  var dataZip = address.zip;
               
                  
                setAddresses((prevAddresses) => ({
                  ...prevAddresses,
                  senderAddress: {
                    firstName: dataFirst_name,
                    lastName: dataLast_name,
                    address1: dataAddress_line_1,
                    address2: dataAddress_line_2,
                    city: dataCity,
                    state: dataState,
                    zip: dataZip,
                    country: dataCountry,
                  },
                }));
              });
              }
              else{
                console.log(response.message)
              }
                    }
            catch (error) {
                      console.error('Error deleting row from API:', error);
                }
        };

    const handleBillingCheckboxChange = () => {
        setAddresses((prevAddresses) => ({
            ...prevAddresses,
            billingBoolean: !prevAddresses.billingBoolean,
            billingAddress: prevAddresses.billingBoolean
                ? { ...prevAddresses.senderAddress }
                : { ...prevAddresses.billingAddress }
        }));
    };

    const handleChangeShippingAddress = (field, value) => {
        setAddresses((prevAddresses) => ({
            ...prevAddresses,
            senderAddress: {
                ...prevAddresses.senderAddress,
                [field]: value
            }
        }));
    };

    const handleInsuranceChange = (e) => {
          
        const selectedValue = e.target.value;
        // Convert the selected value to a boolean
        const insuranceValue = selectedValue === 'true';
        setInsurance(insuranceValue);
       
      };
      
      useEffect(() => {
        setAddresses((prevState) => ({
            ...prevState,
            "insurance" : insurance
        }));
      }, [insurance]);

    const handleChangeBillingAddress = (field, value) => {
        setAddresses((prevAddresses) => ({
            ...prevAddresses,
            billingAddress: {
                ...prevAddresses.billingAddress,
                [field]: value
            }
        }));
    };

    const handleSubmit = async (event) => {
          
        const form = event.target;
        if (!form.checkValidity()) {
            form.reportValidity();
            return;
        }
        event.preventDefault();

        if (addresses.billingBoolean) {
            setAddresses((prevAddresses) => ({
                ...prevAddresses,
                billingAddress: { ...prevAddresses.senderAddress },
            }));
        }

        const formData = {
            ...addresses,
        };

        try {
              
            // Fetch data from API
            const response = await Request.post(`checkout/islanderCheckout/${shipmentId}`, formData, true);
             
            if (response) {
                if(response.orderId === undefined){
                    setCheckoutError(true)
                    return;
                }else{
                    window.location.href = "/user/islander/islander-payment?orderId=" + response.orderId;
                }  
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Checkout</Typography>
                {/* <Box className={classes.headerFlexBox}>
                    <Typography className={classes.pathName}>cartMainContainer </Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={classes.pathName}>Step 2</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Step 3</Typography>
                </Box> */}
                <Box>
                    <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='cartTable'>
                        <Box className="gridInnerBlockLeft">
                            <form className='checkoutForm' onSubmit={handleSubmit}>
                                <Box className="ShippingBox">
                                    <Typography align="left" className='font-22 color2 font-bold'>Sender Address</Typography>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name" name="firstName" required value={addresses.senderAddress.firstName}
                                                    onChange={(e) => handleChangeShippingAddress('firstName', e.target.value)} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name" name="lastName" required value={addresses.senderAddress.lastName}
                                                    onChange={(e) => handleChangeShippingAddress('lastName', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address1" required value={addresses.senderAddress.address1}
                                            onChange={(e) => handleChangeShippingAddress('address1', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address2" value={addresses.senderAddress.address2}
                                            onChange={(e) => handleChangeShippingAddress('address2', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>City/Town</Form.Label>
                                                <Form.Control type="text" value={addresses.senderAddress.city} placeholder="City"
                                                    name="city" required onChange={(e) => handleChangeShippingAddress('city', e.target.value)} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Province</Form.Label>
                                                <Form.Control type="text" value={addresses.senderAddress.state} placeholder="Province"
                                                    name="state" onChange={(e) => handleChangeShippingAddress('state', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control type="text" value={addresses.senderAddress.country} placeholder="Country"
                                                    name="country" required onChange={(e) => handleChangeShippingAddress('country', e.target.value)} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control type="text" placeholder="Zip Code" name="zip" required value={addresses.senderAddress.zip}
                                                    onChange={(e) => handleChangeShippingAddress('zip', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Box>

                                <Box className="BillingBox">
                                    <Typography align="left" className='font-22 color2 font-bold'>Billing Address</Typography>
                                    <Form.Group className={`${classes.formPadding} flex-check`}>
                                        <input type="checkbox" id="myCheck" className='check-input' checked={addresses.billingBoolean}
                                            onChange={handleBillingCheckboxChange}></input>
                                        <label htmlFor='myCheck' className='checkLabel'>My billing address is same as sender address</label>
                                    </Form.Group>
                                    {!addresses.billingBoolean && (
                                        <>
                                            <Box id="BillingBox">
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control type="text" placeholder="First Name" name="firstName" required value={addresses.billingAddress.firstName}
                                                                onChange={(e) => handleChangeBillingAddress('firstName', e.target.value)} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Last Name" name="lastName" required value={addresses.billingAddress.lastName}
                                                                onChange={(e) => handleChangeBillingAddress('lastName', e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Form.Label>Address Line 1</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address1" required value={addresses.billingAddress.address1}
                                                        onChange={(e) => handleChangeBillingAddress('address1', e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address2" value={addresses.billingAddress.address2}
                                                        onChange={(e) => handleChangeBillingAddress('address2', e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>City/Town</Form.Label>
                                                            <Form.Control type="text" placeholder="City" name="city"
                                                                required value={addresses.billingAddress.city} onChange={(e) => handleChangeBillingAddress('city', e.target.value)} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Province</Form.Label>
                                                            <Form.Control type="text" placeholder="Province" name="state"
                                                                 value={addresses.billingAddress.state} onChange={(e) => handleChangeBillingAddress('state', e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Control type="text" placeholder="Country" name="country" required
                                                                value={addresses.billingAddress.country} onChange={(e) => handleChangeBillingAddress('country', e.target.value)} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Zip Code</Form.Label>
                                                            <Form.Control type="text" placeholder="Zip Code" name="zip" required value={addresses.billingAddress.zip}
                                                                onChange={(e) => handleChangeBillingAddress('zip', e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Box>
                                        </>
                                    )}

                                </Box>

                                <Box sx={{pt: 2}}>
                                    <Row className='insurance-section-select'>
                                        <Col className='insurance-section-select'>
                                        <Form.Group className={classes.formPadding}>
                                        <Form.Label>Insurance is offered on personal effects @ C$5.00 for $500.00 maximum per shipment for TOTAL LOSS.</Form.Label>
                                            <Typography className='insurance-subtitle'>Select yes or no to add insurance to your shipping order.</Typography>
                                            <Form.Select
                                                name="insurance"
                                                value={insurance === null ? '' : insurance.toString()} 
                                                onChange={handleInsuranceChange}
                                                required
                                                >
                                                <option value="">Select an option</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </Form.Select>
                                         </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                </Box>

                                <Box align="right" sx={{ pt: 5 }}>
                                    {/* <Link to=''> */}
                                    <Button type="submit" className='b1'>Proceed to Payment</Button>
                                    {/* </Link> */}
                                    
                                </Box>
                                {checkoutError === true ? (
                                    <Typography className="font-600 font-18" style={{color: "red"}}>Server Error</Typography>
                                ):(
                                    <></>
                                )}
                               
                            </form>

                        </Box>
                    </Grid>
                    <IslanderOrderInfo boxDetail={boxDetail} productTotalPrice={productTotalPrice} shippingPrice={shippingPrice} totalBoxAccessoriesValue = {totalBoxAccessoriesValue}/>
                </Grid>
            </Box>
        </Box>
    );
}

export default IslanderCheckout;
