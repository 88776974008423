import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Box,
	Menu,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Stack,
	Chip,
	Typography,
	TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchProductList, deleteProduct } from "../redux/productListSlice";
import { fetchProductCategories } from "../redux/productCategoriesSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { FormatPrice } from "../commonFunctions/commonPriceFunction";
const cookies = new Cookies();

const products = [
	{
		id: 1,
		product: "Product 1",
		category: "Category A",
		stock: 10,
		price: 20.99,
	},
	{
		id: 2,
		product: "Product 2",
		category: "Category B",
		stock: "Out of stock",
		price: 15.49,
	},
	// Add more products here
];

const ProductTable = ({productList}) => {
	const [sortedProducts, setSortedProducts] = useState(products);
	const [sortConfig, setSortConfig] = useState({
		field: null,
		direction: "ascending",
	});
	const [open, setOpen] = useState(false);
	const [newCategory, setNewCategory] = useState("");
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [categories, setCategories] = useState([]);
	const [categoryError, setCategoryError] = useState('');
	let [imageDataArray, setImageDataArray] = useState([]);
	let [rows, setRows] = useState([]);
	const [dropdownOptions, setDropdownOptions] = useState([1, 2, 3, 4]);
	const [usedDropdownOptions, setUsedDropdownOptions] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const dispatch = useDispatch();
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	const [formData, setFormData] = useState({
		name: "",
		size: "",
		sku: "",
		price: '',
		selectedCategory: '',
		categories: [],
		discount: 0,
		description: "",
		shortDescription: "",
		img_1: '',
		img_2: '',
		img_3: '',
		img_4: '',
		material: "",
		color: "",
		item: "",
		weight: "",
		dimensions: "",
		numberOfItems: "",
		style: "",
		recommendedUses: "",
		specialFeatures: "",
		surfaceRecommendation: "",
		compatibleMaterial: "",
		closureType: "",
		shape: "",
		stock: '',
		stockManagement: false,
		status: false
	  });
	
	  const productCategoriesList = useSelector((state) => state.productCategories.productCategories);

	const addRow = () => {
		  
		if (rows.length >= 4 || !isLastRowBlank()) {
			setRows([...rows, {}]); // Disable adding more than four rows
		}

		const availableOptions = dropdownOptions.filter((option) => !usedDropdownOptions.includes(option));
		setUsedDropdownOptions([]);
		rows.push({})
		setTimeout(() => {
			setRows(rows);
			
		}, 2000);
		setDropdownOptions(availableOptions);
	  };

	  const isLastRowBlank = () => {
		  
		if (rows.length >0){
			const lastRow = rows[rows.length - 1];
			return Object.keys(lastRow).length === 0;
		}else {
			return true
		}
	  };
	
	  const deleteRow = async (index,id) => {
		  
		if (id){
			try {

				const response = await Request.delete(`admin/product/deleteImg/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						// Update the state to remove the deleted user
						const updatedRows = [...rows];
						updatedRows.splice(index, 1);
						setRows(updatedRows);
				
						const updatedImageDataArray = [...imageDataArray];
						updatedImageDataArray.splice(index, 1);
						setImageDataArray(updatedImageDataArray);
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		}else{
			// Update the state to remove the deleted user
			const updatedRows = [...rows];
			updatedRows.splice(index, 1);
			setRows(updatedRows);

			const updatedImageDataArray = [...imageDataArray];
			updatedImageDataArray.splice(index, 1);
			setImageDataArray(updatedImageDataArray);
		}



	  };
	
	  const handleFileChange = (index, event) => {
		
		const file = event.target.files[0];
		if (file) {
			let length2 = imageDataArray.length +1
			  
		  const imageName = `image_${length2}`;
		  const imagePath = URL.createObjectURL(file);
		  const customFile = {
			name: imageName,
			file: file,
			path: imagePath,
			order : 1,
		  };

		  setImageDataArray((prevImageDataArray) => [...prevImageDataArray, customFile]);
		  // Handle file upload here
		}
	  };
	
	  const handleDropdownChange = (index, event) => {
		  
		let val = event.target.value
		// check type

		const selectedValue = parseInt(event.target.value, 10);
		imageDataArray[index]["order"] = selectedValue

		setImageDataArray(imageDataArray)
		// setDropdownOptions((prevOptions) => prevOptions.filter((option) => option !== selectedValue));
		// setUsedDropdownOptions((prevUsedOptions) => [...prevUsedOptions, selectedValue]);
		// const updatedUsedOptions = [...usedDropdownOptions, selectedValue];
    	// setUsedDropdownOptions(updatedUsedOptions);
	  };
	  window.imageDataArray = imageDataArray
	const requestSort = (field) => {
		let direction = "ascending";
		if (
			sortConfig.field === field &&
			sortConfig.direction === "ascending"
		) {
			direction = "descending";
		}
		setSortConfig({ field, direction });
		sortProducts(field, direction);
	};

	const sortProducts = (field, direction) => {
		const sorted = [...sortedProducts].sort((a, b) => {
			if (a[field] < b[field]) {
				return direction === "ascending" ? -1 : 1;
			}
			if (a[field] > b[field]) {
				return direction === "ascending" ? 1 : -1;
			}
			return 0;
		});
		setSortedProducts(sorted);
	};

	const [visibility, setVisibility] = useState("public");
	const handleVisibilityChange = (e) => {
		const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
        setFormData((prevData) => ({ ...prevData, status: newVisibility }));
	};

	const handleCategoryDelete = (categoryToDelete) => () => {
		const updatedCategories = formData.categories.filter(
			(category) => category !== categoryToDelete
		  );
		  setFormData({ ...formData, categories: updatedCategories });
	};

	const handleAddCategory = () => {
		
		if (newCategory.trim() === '') {
			setCategoryError('Please enter a category name');
		  } else {
			// Check if the category is already in the list
			// if (!formData.categories.includes(newCategory)) {
			//   const updatedCategories = [...formData.categories,newCategory];
			//   setFormData({ ...formData, categories: updatedCategories, selectedCategory: newCategory });
			// }

			if (!formData.categories.includes(newCategory)) {
				const updatedCategories = [...formData.categories, newCategory];
				setFormData({
				  ...formData,
				  categories: updatedCategories,
				  selectedCategory: newCategory, // Set selectedCategory to the new category
				});
			  }
	  
			// Clear the input field and error message
			setNewCategory('');
			setCategoryError('');
		  }
	  };
	// const [isDialogOpen, setIsDialogOpen] = useState(false);

	// const ITEM_HEIGHT = 48;

	// const [anchorEl, setAnchorEl] = React.useState(null);
	// const open = Boolean(anchorEl);
	// const handleClick = (event) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	const productDelete = async () => {
		// if (selectedProduct !== null) {
		// 	try {
		// 		
		// 		const result = await dispatch(deleteProduct(selectedProduct));
		// 		if (deleteProduct.fulfilled.type === result.type) {
		// 		  closeDeleteDialog();
		// 		} else {
		// 		  console.error("Failed to delete Product");
		// 		}
		// 	  } catch (error) {
		// 		console.error("Error deleting Product:", error);
		// 	  }
		// }
		try {
			const response = await Request.delete(`admin/barrel/delete/${selectedProduct}`, [], true);
			if(response) {
				if (response.status === 204) {
					setIsDeleteDialogOpen(false)
					dispatch(fetchProductList())
				  }
				}
		  } catch (error) {
			console.error('Error deleting row from API:', error);
		  }
	};

	const openEditModal = async (id) => {
		setRows([])
		setImageDataArray([])
		setSelectedProductEdit(id);
		try {
			const response = await Request.get(`barrel/product/${id}`, [], true);
			// const data = await response.json()
			if (response.length !== 0) {
				if (response) {
					setOpen(true);
					// return


					const mappedImageData = (response.images || []).map((image, index) => ({
						path: `${process.env.REACT_APP_DatabaseStatic}${image.img}`  || '', 
						name: image.imgValue || '', 
						order: image.order || 0, 
						dbId : image.id,
						
					  }));
					rows.length = 0
					mappedImageData.forEach((im)=>{
						rows.push({})
					})
					setRows(rows)

					// set category
					let responseCategory = []

					response.categories.forEach(c => {
						responseCategory.push(c.name)
					});
					setFormData({
						name: response.name || '',
						size: response.size || '',
						sku: response.sku || '',
						price: response.price || '',
						selectedCategory: response.selectedCategory || '',
						categories: responseCategory,
						discount: response.discount || 0,
						description: response.description || '',
						shortDescription: response.shortDescription || '',
						img_1: mappedImageData.length > 0 ? mappedImageData[0].path : '', // Get the first image img
						img_2: mappedImageData.length > 1 ? mappedImageData[1].path : '', // Get the second image img
						img_3: mappedImageData.length > 2 ? mappedImageData[2].path : '', // Get the third image img
						img_4: mappedImageData.length > 3 ? mappedImageData[3].path : '', // Get the fourth image URL
						material: response.material || '',
						color: response.color || '',
						item: response.item || '',
						weight: response.weight || '',
						dimensions: response.dimensions || '',
						numberOfItems: response.numberOfItems || '',
						style: response.style || '',
						recommendedUses: response.recommendedUses || '',
						specialFeatures: response.specialFeatures || '',
						surfaceRecommendation: response.surfaceRecommendation || '',
						compatibleMaterial: response.compatibleMaterial || '',
						closureType: response.closureType || '',
						shape: response.shape || '',
						stock: response.stock || '',
						stockManagement: response.stockManagement || false,
						status: response.is_active || false,
					  });
					
					  setCategories(response.categories || []);
					  setImageDataArray(mappedImageData);
				}
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleCloseDialog = () => {
		setSelectedProductEdit(null);
		setOpen(false);
	};
		

	const openDeleteModal = (id) => {
		
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};

	const handleSubmit = async (e) => {
		
		e.preventDefault()
		if (formData.categories.length === 0) {
			// Display an error message, alert, or handle it as needed
			setCategoryError('Please select at least one category.');
			return;
		  }
  
		  let accessToken = cookies.get('accessToken', { path: '/' });
		  const formdata = new FormData();
		  formdata.append('name', formData.name);
		  formdata.append('size', formData.size);
		  formdata.append('sku', formData.sku);
		  formdata.append('price', formData.price);
		  formdata.append('discount', formData.discount);
		  formdata.append('description', formData.description);
		  formdata.append('shortDescription', formData.shortDescription);
		  formdata.append('material', formData.material);

		formdata.append("imageOrder", null)
		imageDataArray.forEach((img) => {
			if (img.dbId) {
				formdata.append("imageOrder", JSON.stringify({
					"order": img.order,
					"dbId": img.dbId
				}))
			} else {
				formdata.append(`img_${img.order}`, img.file);
			}
		})
		
		// if (imageDataArray[0] && imageDataArray[0].file) {
		// 	formdata.append('img_2', imageDataArray[1].file);
		// }
		// if (imageDataArray[1] && imageDataArray[1].file) {
		// 	formdata.append('img_2', imageDataArray[1].file);
		// }
		// if (imageDataArray[2] && imageDataArray[2].file) {
		// 	formdata.append('img_3', imageDataArray[2].file);
		// }
		// if (imageDataArray[3] && imageDataArray[3].file) {
		// 	formdata.append('img_4', imageDataArray[3].file);
		// }

		  formdata.append('color', formData.color);
		  formdata.append('item', formData.item);
		  formdata.append('weight', formData.weight		);
		  formdata.append('numberOfItems', formData.numberOfItems);
		  formdata.append('style', formData.style);
		  formdata.append('recommendedUses', formData.recommendedUses);
		  formdata.append('specialFeatures', formData.specialFeatures);
		  formdata.append('surfaceRecommendation', formData.surfaceRecommendation);
		  formdata.append('compatibleMaterial', formData.compatibleMaterial);
		  formdata.append('closureType', formData.closureType);
		  formdata.append('stock', formData.stock);
		  formdata.append('status', formData.status);
		  formdata.append('stockManagement', formData.stockManagement);
		formdata.append('shape', formData.shape);
		formdata.append('dimensions', formData.dimensions);
		  formData.categories.forEach((category) => {
			  formdata.append('categories', category);
		  });
		    
		  formdata.append('categories', null);
		  formdata.append('images', formData.images);

		  const headers = {
			  'Authorization': `Bearer ${accessToken}`,
		  };
  
		  var requestOptions = {
			  method: 'PUT',
			  headers: headers,
			  body: formdata,
			  redirect: 'follow'
		  };
  
		
		// fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/barrel/edit/${selectedProductEdit}`, requestOptions)
		fetch(`${process.env.REACT_APP_DatabaseServer}admin/barrel/edit/${selectedProductEdit}`, requestOptions)
			  .then(response => {
				  setOpen(false)
				  dispatch(fetchProductList())
				  return response.text();
			  })
			  .then(
			  // result => 
			  // // fetchData()
		  )
			  .catch(error => console.log('error', error));
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		// setFormData({ ...formData, [name]: value });

		if (name === 'shortDescription' && value.length > 100) {
			setFormData({ ...formData, [name]: value.slice(0, 100) });
		  } else {
			setFormData({ ...formData, [name]: value });
		  }
	  };
	  
	  const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setFormData({ ...formData, [name]: checked });
	  };


	return (
		<Box className="order-table-container product-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{/* <TableCell onClick={() => requestSort("productImage")}> */}
							<TableCell>
								<span className="MuiTableSortLabel-root">
									Product Image
								</span>
							</TableCell>
							<TableCell>
								<span className="MuiTableSortLabel-root">
									Name
								</span>
							</TableCell>
							<TableCell>
								<span className="MuiTableSortLabel-root">
									Size
								</span>
							</TableCell>
							{/* <TableCell onClick={() => requestSort("category")}>
								<span className="MuiTableSortLabel-root">
									Category
								</span>
							</TableCell>
							<TableCell onClick={() => requestSort("stock")}>
								<span className="MuiTableSortLabel-root">
									Stock
								</span>
							</TableCell> */}
							<TableCell>
								<span className="MuiTableSortLabel-root">
									Price
								</span>
							</TableCell>
							<TableCell>
								<span className="MuiTableSortLabel-root">
									Stock
								</span>
							</TableCell> 
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(productList?.barrelList && productList?.barrelList.length>0) && (
							<>
							{productList?.barrelList.map((product) => (		
							<TableRow key={product.id}>
								{product?.images.length !== 0 ? (
									<>
										{product?.images.map((image, imageIndex) => (
											<>
											{ imageIndex === 0 && (
												<TableCell key={"product__"+imageIndex}>
													<Box className="productImgCell">
														<img src={`${process.env.REACT_APP_DatabaseStatic}${image?.img}`} alt={`Image ${imageIndex}`}/>
													</Box>
												</TableCell>
											)}
											</>							
										))}
									</>
									
								):(
									<TableCell>
										<Box className="productImgCell">
											<img src="/assets/img/no_image_item.png" alt='barrelImg' /> 
										</Box>
									</TableCell>
								)}
								
								<TableCell>{product.name}</TableCell>
								{/* <TableCell>{product?.categories?.name}</TableCell> */}
								{/* <TableCell
									className={
										product.stock === "Out of stock"
											? "order-table-container__outofstock"
											: " "
									}
								>
									<span className="product-table-container__stock">{product.stock}</span>
								</TableCell> */}
								<TableCell>{product.size}</TableCell>
								<TableCell>
									${FormatPrice(product.price)}
								</TableCell>
								<TableCell>{product.stock}</TableCell>
								<TableCell>
									<IconButton
										aria-label="Edit"
										onClick={() => openEditModal(product.id)}
									>
										<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
									<IconButton
										aria-label="Delete"
										onClick={() => openDeleteModal(product.id)} // Replace with your delete logic
									>
										<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
							</>
						)}

						{(productList?.accessoriesList && productList?.accessoriesList.length>0) && (
							<>
							{productList?.accessoriesList.map((product) => (		
							<TableRow key={product.id}>
								{product?.images.length !== 0 ? (
									<>
										{product?.images.map((image, imageIndex) => (
											<>
											{ imageIndex === 0 && (
												<TableCell key={imageIndex}>
													<Box className="productImgCell">
														<img src={`${process.env.REACT_APP_DatabaseStatic}${image?.img}`} alt={`Image ${imageIndex}`}/>
													</Box>
												</TableCell>
											)}
											</>						
										))}
									</>
								):(
									<TableCell>
										<Box className="productImgCell">
											<img src="/assets/img/no_image_item.png" alt='accessoriesImg' /> 
										</Box>
									</TableCell>
								)}
								<TableCell>{product.name}</TableCell>
								{/* <TableCell>{product?.categories?.name}</TableCell> */}
								{/* <TableCell
									className={
										product.stock === "Out of stock"
											? "order-table-container__outofstock"
											: " "
									}
								>
									<span className="product-table-container__stock">{product.stock}</span>
								</TableCell> */}
								<TableCell>{product.size}</TableCell>
								<TableCell>
									${product.price.toFixed(2)}
								</TableCell>
								<TableCell>{product.stock}</TableCell>
								<TableCell>
									<IconButton
										aria-label="Edit"
										onClick={() => openEditModal(product.id)} 
									>
										<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
									<IconButton
										aria-label="Delete"
										onClick={() => openDeleteModal(product.id)} // Replace with your delete logic
									>
										<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
							</>
						)}
						
					</TableBody>
				</Table>
			</TableContainer>
				<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						<p>Are you sure you want to delete this Product?</p>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => productDelete(selectedProduct)} color="primary">
							OK
						</Button>
						<Button onClick={closeDeleteDialog} color="primary">
							Cancel
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={open}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog admin-dialog--lg"
				>
					<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Edit New Product
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Box className="edit-order-form add-product-form">
							<div className="row add-product-form__row">
									<Form.Group
										controlId="productName"
										className="col-md-6"
									>
										<Form.Label>Product Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Product Name"
											name="name"
											value={formData.name} onChange={handleInputChange}
											required
										/>
									</Form.Group>

									<Form.Group
										controlId="productVisibility"
										className="col-md-6"
									>
										<Form.Label>Visibility</Form.Label>
										<div className="add-product-form__visibility">
											<Form.Check
												type="radio"
												label="Public"
												name="visibility"
                                                value="true"
												checked={formData.status === true}
												onChange={
													handleVisibilityChange
												}
											/>
											<Form.Check
												type="radio"
												label="Hide"
												name="visibility"
                                                value="false"
												checked={formData.status === false}
												onChange={
													handleVisibilityChange
												}
											/>
										</div>
									</Form.Group>
									<Form.Group
										controlId="description"
										className="col-md-12"
									>
										<Form.Label>Description</Form.Label>
										<Form.Control as="textarea" rows={3} required value={formData.description} name="description" onChange={handleInputChange} />
									</Form.Group>
									<Form.Group
										controlId="shortDescription"
										className="col-md-6"
									>
										<Form.Label>Short Description</Form.Label>
										<Form.Control as="textarea" rows={3} maxLength={100}  required value={formData.shortDescription} name="shortDescription" onChange={handleInputChange}/>
										{/* <Typography className="color1 font-15 mt-2">Character Count: {formData.shortDescription.length} / 100</Typography> */}
									</Form.Group>
									<Form.Group
										controlId="productCategories"
										className="col-md-6"
									>
										<Form.Label>Categories</Form.Label>
										<div className="add-product-form__categories">
										<Stack direction="row" spacing={1}>
										{(formData.categories && formData.categories.length>0) && formData.categories.map((category) => (
											<Chip
											key={category}
        									label={category}
											deleteIcon={<CloseIcon />}
											color="primary"
											onDelete={handleCategoryDelete(category)}
											/>
										))}
										</Stack>

									<Form.Select
										label="Select Category"
										value={newCategory}
										onChange={(e) => setNewCategory(e.target.value)}
									>
										<option value="" disabled>
										Select a category
										</option>
										{(productCategoriesList && productCategoriesList.length>0) && productCategoriesList.map((category) => (
											<option key={category.id} value={category.name}>
												{category.name}
											</option>
										))}
										{/* <option value="custom">Add Custom Category</option> */}
									</Form.Select>
								
										<Button onClick={handleAddCategory} color="primary" className="btn btn-primary">
										Add
										</Button>
									</div>
									{categoryError && <Typography className="font-14" sx={{ color: 'red' }}>{categoryError}</Typography>}
									</Form.Group>
									<Form.Group
										controlId="price"
										className="col-md-3"
									>
										<Form.Label>Price($)</Form.Label>
										<Form.Control
											type="number"
											placeholder="Price"
											value={formData.price} name="price" onChange={handleInputChange}
											required
										/>
									</Form.Group>
									<Form.Group
										controlId="discount"
										className="col-md-3"
									>
										<Form.Label>Discount</Form.Label>
										<Form.Control
											type="number"
											placeholder="Discount"
											value={formData.discount} name="discount" onChange={handleInputChange}
										/>
									</Form.Group>
								

									<Form.Group
										controlId="size"
										className="col-md-3"
									>
										<Form.Label>Size</Form.Label>
										<Form.Control
											type="text"
											placeholder="Size"
											value={formData.size} name="size" onChange={handleInputChange}
											required
										/>
									</Form.Group>

									
									<Form.Group
										controlId="material"
										className="col-md-3"
									>
										<Form.Label>Material</Form.Label>
										<Form.Control
											type="text"
											placeholder="Material"
											value={formData.material} name="material" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="color"
										className="col-md-3"
									>
										<Form.Label>Color</Form.Label>
										<Form.Control
											type="text"
											placeholder="Color"
											value={formData.color} name="color" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="item"
										className="col-md-3"
									>
										<Form.Label>Item</Form.Label>
										<Form.Control
											type="text"
											placeholder="Item"
											value={formData.item} name="item" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="weight"
										className="col-md-3"
									>
										<Form.Label>Weight</Form.Label>
										<Form.Control
											type="text"
											placeholder="Weight"
											value={formData.weight} name="weight" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="dimensions"
										className="col-md-3"
									>
										<Form.Label>Dimensions</Form.Label>
										<Form.Control
											type="text"
											placeholder="Dimensions"
											value={formData.dimensions} name="dimensions" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="numberOfItems"
										className="col-md-3"
									>
										<Form.Label>No of Items</Form.Label>
										<Form.Control
											type="number"
											placeholder="No of Items"
											value={formData.numberOfItems} name="numberOfItems" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="style"
										className="col-md-3"
									>
										<Form.Label>Style</Form.Label>
										<Form.Control
											type="text"
											placeholder="Style"
											value={formData.style} name="style" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="recommendedUses"
										className="col-md-3"
									>
										<Form.Label>Recommended Uses</Form.Label>
										<Form.Control
											type="text"
											placeholder="Recommended Uses"
											value={formData.recommendedUses} name="recommendedUses" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="specialFeatures"
										className="col-md-3"
									>
										<Form.Label>Special Features</Form.Label>
										<Form.Control
											type="text"
											placeholder="Special Features"
											value={formData.specialFeatures} name="specialFeatures" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="surfaceRecommendation"
										className="col-md-3"
									>
										<Form.Label>Surface Recommendation</Form.Label>
										<Form.Control
											type="text"
											placeholder="Surface Recommendation"
											value={formData.surfaceRecommendation} name="surfaceRecommendation" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="compatibleMaterial"
										className="col-md-3"
									>
										<Form.Label>Compatible Material</Form.Label>
										<Form.Control
											type="text"
											placeholder="Compatible Material"
											value={formData.compatibleMaterial} name="compatibleMaterial" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="closureType"
										className="col-md-3"
									>
										<Form.Label>Closure Type</Form.Label>
										<Form.Control
											type="text"
											placeholder="Closure Type"
											value={formData.closureType} name="closureType" onChange={handleInputChange}
										/>
									</Form.Group>
									<Form.Group
										controlId="shape"
										className="col-md-3"
									>
										<Form.Label>Shape</Form.Label>
										<Form.Control
											type="text"
											placeholder="Shape"
											value={formData.shape} name="shape" onChange={handleInputChange}
										/>
									</Form.Group>

									<div className="col-md-12">
										{/* <Typography
											variant="h6"
											className="section-title"
										>
											Inventory
										</Typography> */}
										<div className="row">
											<Form.Group
												controlId="sku"
												className="col-md-6"
											>
												<Form.Label>SKU</Form.Label>
												<Form.Control
													type="text"
													placeholder="SKU"
													value={formData.sku} name="sku" onChange={handleInputChange}
													readOnly
													required
												/>
											</Form.Group>

											<Form.Group
												controlId="stock"
												className="col-md-6"
											>
												<Form.Label>
													Stock Quantity
												</Form.Label>
												<Form.Control
													type="number"
													placeholder="Stock Quantity"
													value={formData.stock} name="stock" onChange={handleInputChange}
													required
												/>
											</Form.Group>
										</div>
										<div className="row">
											<Box sx={{mt:3, mb:0}} className="d-flex align-items-center gap-3">
												<input type="checkbox" id="stockManagement" name="stockManagement" style={{"width":"16px","height":"16px"}} checked={formData.stockManagement}  value={formData.stockManagement} onChange={handleCheckboxChange }/>
												<label className="font-15 color1 font-600" for="stockManagement">Enable Stock Management</label>
											</Box>
											
										</div>
									</div>
									<div className="col-md-12">
										<Typography
											variant="h6"
											className="section-title"
										>
											Images
										</Typography>
										<div className="order-table-container">
											{/* <ImageTable
												data={tableData}
												onDelete={handleDelete}
												onAltTextChange={
													handleAltTextChange
												}
												onOrderChange={
													handleOrderChange
												}
											/> */}
											 <table width="100%">
												<thead>
												<tr>
													<th>Image</th>
													<th>Text</th>
													<th>Order</th>
													<th>Action</th>
												</tr>
												</thead>
												<tbody>
													
												{(rows && rows.length>0) && rows.map((data, index) => (
													<tr key={"imageDataArray_"+index}>
														<td>
															{imageDataArray[index] ? (
																// ({`${process.env.REACT_APP_DatabaseStatic}${image?.img}`}
																<img style={{
																	maxWidth: "100px",
																	maxHeight: "100px",
																}} src={`${imageDataArray[index].path}`} alt={imageDataArray[index].name} />
															) : (
																<input type="file" accept="image/*" onChange={(e) => handleFileChange(index, e)} />
															)}
														</td>
														<td>
														{imageDataArray[index] ? (
															<TextField
															className="imgAltText"
															type="text"
															value={imageDataArray[index].name}
															readOnly
															/>
														) : (<TextField className="imgAltText" type="text" required/>)}
														</td>
														<td>
														{imageDataArray[index] ? (
															<select
															className="imgOrderDropdown"
															onChange={(e) => handleDropdownChange(index, e)}
															required
															style={{ height: "60px", minWidth: "100px" }}
															>
															{dropdownOptions && dropdownOptions.map((option) => (
																<option key={"dropdownOptions__"+option} selected={imageDataArray[index].order === option ? true : false} value={option}>
																	{option}
																</option>
															))}
															</select>
														) : 	
														// (
														// 	<select className="imgOrderDropdown" disabled={true} onChange={(e) => handleDropdownChange(index, e)} required style={{height:"60px",minWidth: "100px"}}>
														// 	{dropdownOptions.map((option) => (
														// 		<option key={option} value={option}>
														// 		{option}
														// 		</option>
														// 	))}
														// 	</select>
														// )
														""
														}
														</td>

														<td>
														{imageDataArray[index] ? (
															<CloseIcon style={{ color: "#db2d6a" }} onClick={() => deleteRow(index,imageDataArray[index].dbId)} />
														) : null}
														</td>
													</tr>
												))}
													</tbody>
												</table>
												<Button type="button" className="b1 b4 mt-2" onClick={addRow} disabled={rows.length >= 4} >Add Row</Button>
										</div>
									</div>
								</div>
							</Box>
						</Box>
					</DialogContent>
					</form>
					
				</Dialog>
		</Box>
	);
};

export default ProductTable;
