import { useEffect, useState } from 'react';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { spacing } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import zIndex from '@mui/material/styles/zIndex';
import { Link } from 'react-router-dom';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    supportBox:{
        position: "fixed",
        right: "35px",
        bottom: "35px"
    },
    supportInnerBox:{
        position: "relative"
    },
    supportMsgBox:{
        position: 'relative',
        backgroundColor: "#FFC72C",
        padding: spacing(2),
        borderRadius: "5px",
        padding: "10px 15px",
        marginBottom: "5px",
        marginRight: "15px",
    },
    arrow: {
        position: 'absolute',
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '28px solid transparent',
        borderRight: '0px solid transparent',
        borderTop: '28px solid #FFC72C',
        bottom: '-17px',
        left: '86%',
        transform: 'translateX(-65%) rotate(-10deg)',
      },
      messageText:{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      },
      message:{
        fontSize: "14px !important",
        fontWeight: "700 !important"
      },
      supportInnerBox:{
        textAlign: "right"
      },
      closeSupportBoxIcon:{
        // position: "absolute",
        // top: "0px",
        // right: "15px",
        fontSize: "45px !important",
        color: "var(--primary-color)",
        border: "1px solid var(--primary-color8)",
        borderRadius: "50%",
        padding:"5px"
      },
      closeSupportBox:{
        borderRadius: "50%",
        border: "1px solid var(--primary-color8)",
        backgroundColor: "var(--primary-color6) !important",
        width: "52px",
        height: "52px",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 0 0 auto",
        cursor: "pointer"
      }
  //   toolbar: theme.mixins.toolbar,
  }));
function Support() {

    const classes = useStyles();
    const [openSupport, setOpenSupport] = useState(false);
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    // const [phone, setPhone] = useState('');

    const openSupportBox = () =>{
      setOpenSupport(true)
    }

    const closeSupportBox = () =>{
      setOpenSupport(false)
    }

    const handleNameChange = event => {
      setFullName(event.target.value);
    };
  
    const handleEmailChange = event => {
      setEmailAddress(event.target.value);
    };
  
    const handlePhoneChange = event => {
      setPhone(event.target.value);
    };

    const handleMessageChange = event => {
      setMessage(event.target.value);
    };
    


    useEffect(() => {
      (async () => {
        let response = await Request.get("user/", [], true);
        {
          if (response) {
            setFullName(response.firstName + ' ' + response.lastName)
            setEmailAddress(response.email)
            setPhone(response.phone)
          }
          else {
            console.log("error");
            return
          }
        }
      })();
    }, []);

    const handleSubmit = async () => {
      // Send updated user data to API
      const updatedUserData = {
        name: fullName,
        email: emailAddress,
        phone: phone,
        message: message
      };
      try {
        let response = await Request.post("utils/sendSupportNotification", updatedUserData , true);
        if (response.message === "Success") {
            setTimeout(() => {
              setShowNotification(false);
            }, 5000); 
             ;
            //   setIslanderDetail(response);
        } else {
            console.error('Failed to fetch data from API');
        }
    } catch (error) {
        console.error('Error fetching data from API:', error);
    }
      
    };

  return (
    <Box className={classes.supportBox}>
    <Box className={classes.supportInnerBox}>
        {openSupport && (
          <>            
              <Box className={classes.supportMsgBox}>
              <div className={classes.arrow} />
              {/* <Link to='mailto:iof@gmail.com' className='emailLink'> */}
                {/* <Box className={classes.messageText}>
                    <img src='/assets/img/support_img.png'></img>
                    <Typography className={classes.message}>Email Support</Typography>
                </Box> */}
              {/* </Link> */}
              <Box align="left" className="supportFormBox">
                <form onClick={handleSubmit}>
                  <Form.Group >
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" name="fullName" value={fullName} onChange={handleNameChange} required></Form.Control>
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" name="email" value={emailAddress} onChange={handleEmailChange} required></Form.Control>
                  </Form.Group>
                  <Form.Group>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="tel" name="phone" value={phone} onChange={handlePhoneChange} required></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={7}  required onChange={handleMessageChange}/>
                  </Form.Group>
                  <Button sx={{mt: 1}} type="submit" className='b1'>Submit</Button>
                  {showNotification && (
                      <Box className="notification notification font-15 color2 font-bold" sx={{pt:1}}>
                        Thank you for submitting the message, and we will get back to you soon.
                      </Box>
                     )} 
                    {/* <input type="text">{fullName}</input> */}
                </form>
              </Box>
            </Box>
          </>           
        )}
        {openSupport === true ? (
          <Box className={classes.closeSupportBox} align="center">
            <CloseIcon className={classes.closeSupportBoxIcon} onClick={closeSupportBox}/>
          </Box>
        ): (
            <img src='/assets/img/support_icon.svg' className='support_icon' onClick={openSupportBox}></img>
        )}
        
    </Box>
</Box>
  );
}

export default Support;
