import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    deliveryTimeList: [],
    purchaseDeliveryTimeList: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchDeliveryTimeList = createAsyncThunk(
    "customer/deliveryTimeList",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("admin/rate/country", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  export const fetchPurchaseDeliveryTimeList = createAsyncThunk(
    "customer/purchaseDeliveryTimeList",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("admin/rate/purchaseEstimates", [], true);
             
              
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );  
  

  const deliveryTimeSlice = createSlice({
    name: "deliveryTime",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchDeliveryTimeList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchDeliveryTimeList.fulfilled, (state, action) => {
            state.loading = false;
            state.deliveryTimeList = action.payload;
          })
          .addCase(fetchDeliveryTimeList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(fetchPurchaseDeliveryTimeList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchPurchaseDeliveryTimeList.fulfilled, (state, action) => {
            state.loading = false;
            state.purchaseDeliveryTimeList = action.payload;
          })
          .addCase(fetchPurchaseDeliveryTimeList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
      },
  });
  
  export default deliveryTimeSlice.reducer;