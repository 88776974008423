import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import { Col, Row } from 'react-bootstrap';
import Request from '../../customUtils/Http/Request';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import HelpCenterCategory from './helpCenterCategory';

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: "#fff",
        borderRadius: "25px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "50px 15px",
        textAlign: "center",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },
    listItem: {
        paddingLeft: "0px !important",
        paddingBottom: "0px !important"
    },
    listIcon: {
        minWidth: "40px !important",
    },
    listText:{
        "& span" : {
        fontSize: "18px !important",
        }
    }
}));

const DatabaseResponseComponent = ({ htmlContent }) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    );
  };
  

const HelpCenterAnswers = (props) => {
    const location = useLocation();
    const classes = useStyles();
    
    const [answer, setAnswer] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [category, setCategory] = useState('');
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryname = searchParams.get('category');
        const id = searchParams.get('id');
        setCategory(categoryname)  
        fetchQuestions(id)
    }, []);

    const fetchQuestions = async (id) => {
        try {
            let response = await Request.get(`utils/helpCentre/question/${id}`,[]);
             
            if (Object.keys(response).length !== 0) {
                 
                setAnswer(response.answer)
                // setAnswerCategory(response.category)
                setSelectedQuestion(response.question)
                setLoading(false);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
            setLoading(true);
        }
    };


    if (loading) {
        return  <div className="loader-overlay">
            <div className="loader">
            </div>
        </div>;
      }

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Box sx={{pt: 5, pb: 7,'@media (max-width: 767px)': {pt: 3, pb: 3},}} textAlign="left">
                        <Link to="/help-center">
                            <Typography className='font-22 color4 pb-5 text-underline'>
                                <ArrowBackOutlinedIcon sx={{mr:1}}/> Back to Help Center
                            </Typography>
                        </Link>
                  
                       <Grid container spacing={2}>
                           <HelpCenterCategory category={category}/>
                            <Grid item xs={12} md={9} lg={10}>
                                <Paper>
                                    <Box textAlign="left" sx={{p: 4}}>
                                             <>
                                             <Box>
                                                 <Typography className='font-30 font-bold color2 mb-4'>{selectedQuestion}</Typography>
                                                 <Typography className='font-18 color1 mt-3'>
                                                     <DatabaseResponseComponent htmlContent={answer} />
                                                 </Typography>
                                             </Box>
                                         </>
                                    </Box>
                                </Paper>
                            </Grid>
                       </Grid>
                    </Box>
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default HelpCenterAnswers;