import React, {useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from "@mui/material";
import { FormatPrice } from "../commonFunctions/commonPriceFunction";

const YourOrderTable = ({ orderId, orderDetail}) => {

    // const calculateTotal = () => {
    //     return orderDetail.reduce(
    //         (total, item) =>
    //             total + parseFloat(item.price.replace("$", "")) * item.quantity,
    //         0
    //     );
    // };

    return (
        <TableContainer component={Paper} className="your-order-total-table__container">
            <Table aria-label="Your Order Table" className="your-order-total-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Qty</TableCell>
                        <TableCell align="right">Price</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* {Array.isArray(orderDetail?.products) && (
                        <> */}
                            {(Array.isArray(orderDetail?.products) && orderDetail?.products.length>0) && orderDetail?.products.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {orderDetail?.orderType === "Send" ? `${item?.product?.productType} (${item?.product?.size}GL)` : item.product.name}
                                        {/* {item.product.name} */}
                                        {/* <Typography className="color1 font-14">{item.product.name}</Typography>
                                        <Typography className="color1 font-14">Size:<span className="color3 font-14">{item.product.size}GL</span></Typography> */}
                                    </TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">${(FormatPrice(item.itemPrice))}</TableCell>
                                </TableRow>
                            ))}
                        {/* </>
                    )} */}
                    {orderDetail && (
                            <TableRow>
                            <TableCell colSpan={2} align="right">
                                <strong>Shipping and Handling Cost:</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>${FormatPrice(orderDetail?.shippingCharge)}</strong>
                                {/* <strong>${calculateTotal().toFixed(2)}</strong> */}
                            </TableCell>
                            </TableRow>
                    )}
                      {orderDetail?.insuranceValue && (
                            <TableRow>
                            <TableCell colSpan={2} align="right">
                                <strong>Insurance</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>${FormatPrice(orderDetail.insuranceValue)}</strong>
                                {/* <strong>${calculateTotal().toFixed(2)}</strong> */}
                            </TableCell>
                            </TableRow>
                    )}
                    {orderDetail && (
                            <TableRow>
                            <TableCell colSpan={2} align="right">
                                <strong>Tax({orderDetail?.taxPercentage} %):</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>${FormatPrice(orderDetail?.tax)}</strong>
                                {/* <strong>${calculateTotal().toFixed(2)}</strong> */}
                            </TableCell>
                            </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={2} align="right">
                            <strong>Total:</strong>
                        </TableCell>
                        <TableCell align="right">
                            <strong>${(FormatPrice(orderDetail?.totalPrice))}</strong>
                            {/* <strong>${calculateTotal().toFixed(2)}</strong> */}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default YourOrderTable;
