import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Avatar,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import DriverModal from "./DriverModal";
import Request from '../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchAssignedTaskList,fetchPendingTaskList, fetchCompletedTaskList, fetchRejectedTaskList } from "../redux/taskManagementSlice";
import { approveDriver } from "../redux/driverSlice";
import OrderDetailEdit from "./orderDetailEdit";


const OrderTable = ({orderInfo,type}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedDriver, setSelectedDriver] = useState(null);
	const [selectedDriverId, setSelectedDriverId] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [orderDetail, setOrderDetail] = useState([]);
	const [open, setOpen] = useState(false);
	const [orderId, setOrderId] = useState(null);
	const [productType, setProductType] = useState(false);
	const [selectedValues, setSelectedValues] = useState({});
	const dispatch = useDispatch();

	const approveDriverList = useSelector((state) => state.driver.approveDrivers);

	const getStatusText = (statusNumber) => {
		if (statusNumber === 0) return 'Incomplete';
		if (statusNumber === 1) return 'Pending';
		if (statusNumber === 2) return 'Ready to Pickup';
		if (statusNumber === 3) return 'Picked Up';
		if (statusNumber === 4) return 'Ready to Ship';
		if (statusNumber === 5) return 'Shipped';
		if (statusNumber === 6) return 'Delivered';
		if (statusNumber === 7) return 'Canceled';
		if (statusNumber === 8) return 'Delete';
	  };

	const handleOpenModal = (driver, id) => {
		setSelectedDriver(driver);
		setSelectedDriverId(id);
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedDriver(null);
		setModalOpen(false);
	};

	const handleEdit = (id, type, selectedTab) => {
		setOpen(id);
		setOrderId(id)
		setProductType(type)
		fetchOrderDetail(id)
	}

	const handleClose = () => {
		setOpen(false);
	};
	
	const handleDropdownChange = (orderId, id) => {
		  
		// const selectedValue = e.target.value;
		// setSelectedItem(id);
		// setSelectedItemId(orderId)
		setSelectedValues((prevSelectedValues) => ({
			...prevSelectedValues,
			[orderId]: id,
		  }));
		// const selectedItemId = approveDriverList.find(
		// 	(item) => item.user.fullName === selectedValue
		//   )?.id;

		if (id) {
			assignDriver(orderId, id);
		}
	};

	const assignDriver = async (orderId, id) => {
		const listData = {
			"orderId": orderId,
			"driverId": id,
		}
		try {
            // Fetch data from API
            const response = await Request.post('admin/driver/assignOrder', listData, true);
            if (response.message === "Success") {
                dispatch(fetchPendingTaskList());
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
	}

	const fetchOrderDetail = async (id) => {
        try {
			let response = await Request.get(`order/order_detail/${id}`, [], true);
            if (response.length !== 0) {
				setOrderDetail(response)
				// window.location.reload();
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	useEffect(() => {
		dispatch(approveDriver());
	}, []);

    function formatDate(timestamp) {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear();
        const month = months[dateObj.getMonth()];
        const day = dateObj.getDate().toString().padStart(2, '0');
        return `${month} ${day} ${year}`;
    }

	return (
		<div className="order-table-container driver-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>OrderID</TableCell>
							<TableCell>Order Date</TableCell>
							<TableCell>Customer</TableCell>
							<TableCell>Item</TableCell>
							<TableCell>Est. Pickup & Delivery Date</TableCell>
							<TableCell>Location</TableCell>
							
                            {type === "pending for assignment" && (
                                <>
                                    <TableCell>Order Status</TableCell>
                                    <TableCell>Driver</TableCell>
                                </>
                               
                            )}
                            {type === "assigned" && (
                                <>
                                    <TableCell>Assigned to</TableCell>
                                    <TableCell>Assigned on</TableCell>
                                </> 
                            )}
                            {type === "completed" && (
                                <>
                                <TableCell>Assigned to</TableCell>
                                <TableCell>Assigned on</TableCell>
                                <TableCell>Completed on</TableCell>
                            </> 
							)}
							 {type === "cancelled" && (
                                <>
                                <TableCell>Assigned to</TableCell>
                                <TableCell>Assigned on</TableCell>
                                <TableCell>Completed on</TableCell>
                            </> 
                            )}
						</TableRow>
					</TableHead>
					<TableBody>
						{orderInfo && orderInfo.length > 0 ? (
							<>
							{orderInfo.map((order) => (
							<TableRow key={order.id}>
								<TableCell className="cursor-pointer font-bold text-underline" onClick={() => handleEdit(order.id, order.orderType)}>
									{order.invoiceNumber}
								</TableCell>
								<TableCell>
									{formatDate(order.createdAt)}
								</TableCell>
								<TableCell>{order?.user?.fullName}</TableCell>
								<TableCell>
                                    {order.orderType === "Purchase" ? "Packaging" : "Shipping"}
                                </TableCell>
								<TableCell>{order.estDeliveryTimeInDay}</TableCell>
								<TableCell>{order.shippingAddress?.city}</TableCell>
                                {type === "pending for assignment" && (
                                    <>
                                        <TableCell>{getStatusText(order.orderStatus)}</TableCell>
                                        <TableCell>
											<select value={selectedValues[order.id] || ''} onChange={(e) => handleDropdownChange(order.id, e.target.value)} style={{border: "none"}}>
												{/* <option value="">Select an item</option> */}
												<option value="" disabled>Select an option</option>
												{(approveDriverList && approveDriverList.length>0) && approveDriverList.map((item) => (
												<option key={item.id} value={item.id}>
													{item.user.fullName}
												</option>
												))}
											</select>
										</TableCell>
                                    </>
                                )}
                                {type === "assigned" && (
                                    <>
                                          <TableCell>
											<select value={order?.driver_order_assignment?.driverId} onChange={(e) => handleDropdownChange(order.id, e.target.value)} style={{border: "none"}}>
													{/* <option value="">Select an item</option> */}
													{(approveDriverList && approveDriverList.length>0) && approveDriverList.map((item) => (
														<option key={item.id} value={item.id}>
															{item.user.fullName}
														</option>
													))}
											</select>
										</TableCell>
                                        <TableCell>{formatDate(order.driver_order_assignment?.createdAt)}</TableCell>
                                    </> 
                                )}
                                {type === "completed" && (
                                    <>
                                        <TableCell>
											<select value={order?.driver_order_assignment?.driverId} style={{border: "none"}}>
													{/* <option value="">Select an item</option> */}
													{(approveDriverList && approveDriverList.length>0) && approveDriverList.map((item) => (
														<option key={item.id} value={item.id}>
															{item.user.fullName}
														</option>
													))}
											</select>
										</TableCell>
                                        <TableCell>{formatDate(order.driver_order_assignment?.createdAt)}</TableCell>
                                        <TableCell>{formatDate(order.driver_order_assignment?.updatedAt)}</TableCell>
                                    </> 
                                )}


							</TableRow>
						))}
							</>
						):<></>}
						
					</TableBody>
				</Table>
			</TableContainer>

			{/* {driverInfo && driverInfo.length !== 0 && (
				<DriverModal
				isOpen={modalOpen}
				handleClose={handleCloseModal}
				driver={selectedDriver}
				driverInfo={driverInfo} type={type} driverId={selectedDriverId}
			/>
			)} */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-title"
				className="admin-modal"
			>
					<OrderDetailEdit orderId={orderId} orderDetail={orderDetail} orderType={type} setOpen={setOpen}/>
			</Modal>
		</div>
	);
};

export default OrderTable;
