import { useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';

function SendItemCart() {

    const [activeStep, setActiveStep] = useState(0);
    let [num, setNum] = useState(0);
    const [cartInfo, setCartInfo] = useState([]);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [editBtnDisable, setEditBtnDisable] = useState(true)
    const [updateCartInfo, setUpdateCartInfo] = useState(false)

    let login = checkLogin()

    let incNum =(index)=>{
        const updatedInputs = [...cartInfo];
        if (updatedInputs[index].quantity < 10 && updatedInputs[index].quantity >= 0) {
            updatedInputs[index].quantity += 1;
            setCartInfo(updatedInputs);
            setEditBtnDisable(false)
        }
    };
    
    let decNum = (index) => {
        const updatedInputs = [...cartInfo];
        if (updatedInputs[index].quantity <= 10 && updatedInputs[index].quantity > 0) {
            updatedInputs[index].quantity -= 1;
            setCartInfo(updatedInputs);
            setEditBtnDisable(false)
        }
    }

    let deleteCartItem = async (id) =>{
        let response = await Request.delete(`cart/${id}`, {}, true)
        {  
            if(response.status == 204){
                setDeleteStatus((current) => !current)
            }
            else{
                console.log("Something Went wrong when delete item.");
            }
        }
        if(!response){
            console.log('something went wrong');
        }
    }

    let updateCartItem = async () =>{

        var input = document.getElementsByClassName('cartEditInput');
        const cartItems = [];

        for (var i = 0; i < input.length; i++) {
            const item = {
                cartItemId: input[i].id,
                quantity: input[i].value,
            };
            if(item.quantity > 0){
                cartItems.push(item);
            }
            
        }
        var items = {"cartItems": cartItems}
        let response = await Request.put("cart/update", items , true)
        {
            if(response.status == 200){
                setUpdateCartInfo((current) => !current) 
                setEditBtnDisable(true)
            }
            else{
                console.log("Something Went wrong when update item.");
            }
            // window.location.href = '/cart'
        }
        if(!response){
            console.log('something went wrong');
        }

        // let response = await Request.put(`cart`, {}, true)
        // {
        //     console.log(response.status,'deleted status');  
        //     if(response.status == 204){
        //         setDeleteStatus(true)
        //     }
        //     else{
        //         console.log("Something Went wrong when delete item.");
        //     }
        // }
        // if(!response){
        //     console.log('something went wrong');
        // }
    }

    let handleInput = (e) => {
        setNum(e.target.value);
    }

    useEffect(() => {
        (async () => {
            let data = await Request.get("cart", [], true);
            {
                setCartInfo(data.cartItems);
                setCartTotalPrice(data.totalCartPrice);
                var countList = data.cartItems;
                var count = 0;
                for (var i = 0; i < countList.length; i++) {
                    count += countList[i].quantity;
                }
                document.getElementById('cartItem').innerHTML = count
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let data = await Request.get("cart", [], true);
            {
                setCartInfo(data.cartItems);
                setCartTotalPrice(data.totalCartPrice);
                var countList = data.cartItems;
                var count = 0;
                for (var i = 0; i < countList.length; i++) {
                    count += countList[i].quantity;
                }
                document.getElementById('cartItem').innerHTML = count
            }
        })();
    }, [deleteStatus]);

    useEffect(() => {
        (async () => {
            let data = await Request.get("cart", [], true);
            {
                setCartInfo(data.cartItems);
                setCartTotalPrice(data.totalCartPrice);
                var countList = data.cartItems;
                var count = 0;
                for (var i = 0; i < countList.length; i++) {
                    count += countList[i].quantity;
                }
                document.getElementById('cartItem').innerHTML = count
            }
        })();
    }, [updateCartInfo]);

    // useEffect(() => {
    //     console.log(cartInfo,'infoooooooooo');
    // }, [cartInfo]);
    

    return (
        <>
            {login == true ? (
                <>
                    <Header />
                    <Box className="cartContainer">
                        <Box className="cartBox">
                            <Container maxWidth="xl">
                                <Box>
                                    <Typography variant='h3' sx={{ pb: 2 }}>Cart</Typography>
                                    <Box>Home &gt; Cart</Box>
                                </Box>
                            </Container>
                        </Box>
                        <Box sx={{ pt: 8, pb: 8, backgroundColor: "#ffffff" }}>
                            <Container maxWidth="xl">
                                <Box className='stepperGrid' sx={{ pb: 6 }}>
                                    <Grid item container xs={12} sm={8} md={4} lg={4} xl={4} columnSpacing={{ xs: 1, sm: 2, md: 2 }} sx={{ justifyContent: "center", textAlign: "center", mx: 'auto !important' }}>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box className="stepperList active">
                                                    <Typography>1</Typography>
                                                </Box>
                                                <Typography>Cart</Typography>
                                                <ChevronRightIcon fontSize="small"></ChevronRightIcon>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box className="stepperList">
                                                    <Typography>2</Typography>
                                                </Box>
                                                <Typography>Checkout</Typography>
                                                <ChevronRightIcon fontSize="small"></ChevronRightIcon>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                                                <Box className="stepperList">
                                                    <Typography>3</Typography>
                                                </Box>
                                                <Typography>Order</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={9} className='cartTable col-left-padding'>
                                        <TableContainer component={Paper} >
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        {/* <TableCell align="right">PRODUCT</TableCell>
                                                        <TableCell align="right">PRICE&nbsp;(g)</TableCell> */}
                                                        <TableCell align="right">QUANTITY&nbsp;(g)</TableCell>
                                                        <TableCell align="right">SIZE&nbsp;(g)</TableCell>
                                                        {/* <TableCell align="right">SUBTOTAL&nbsp;(g)</TableCell> */}
                                                        <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(cartInfo && cartInfo.length>0) && cartInfo.map((product, index) =>
                                                        <>
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child tr, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <img src="./assets/img/product_img.png" style={{"objectFit":"cover", "width":"60px","height":"40px"}}></img>
                                                                </TableCell>
                                                                {/* <TableCell align="right" sx={{color: '#000000', fontWeight: '600'}}>Empty Barrel</TableCell> */}
                                                                {/* <TableCell align="right" sx={{color: '#033D1E'}}>${product.barrel.price}</TableCell> */}
                                                                <TableCell align="right" sx={{display: "flex"}}>
                                                                    <Box className="quantityBox tableQuantityBox">
                                                                        <Box onClick={() => decNum(index)}>
                                                                            <Button>-</Button>
                                                                        </Box>
                                                                        <input type="number" className="cartEditInput" id={product.id} value={product.quantity} readOnly min="0" max="10"></input>
                                                                        <Box>
                                                                            <Button onClick={() => incNum(index)}>+</Button>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="right" sx={{color: '#000000', fontWeight: '600'}}>{product.barrel.size}</TableCell>
                                                                {/* <TableCell align="right" sx={{color: '#033D1E'}}>${product.totalPrice}</TableCell> */}
                                                                <TableCell align="right">
                                                                    <CloseIcon id={product.id} sx={{cursor: "pointer"}} onClick={() => deleteCartItem(product.id)}></CloseIcon>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box align="right" sx={{ mt: 1 }}>
                                            <Button variant="contained" id="cartUpdate" disabled={editBtnDisable}  onClick={() => updateCartItem()} className='submitBtn submitBtn2 cartBtn checkoutBtn editBtn'>Update Cart</Button>
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={4} xl={3} className='col-right-padding'>
                                        <Box className='cartTable' sx={{ pl: 3, pr: 3, pt: 3, pb: 3, backgroundColor: '#F5F5F5', borderRadius: '10px' }}>
                                            <Typography variant='h6'>Shipping Total Charges</Typography>
                                            <TableContainer component={Paper} >
                                                <Table aria-label="Total table" sx={{ mt: 2 }}>
                                                    <TableBody>
                                                        {/* <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography>SubTotal</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography>${cartTotalPrice}</Typography>
                                                            </TableCell>
                                                        </TableRow> */}
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography sx={{ fontWeight: "bold" }}>Total</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography variant='h6' sx={{ fontWeight: "bold" }}>$40</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <Box sx={{ mt: 2 }}>
                                                <Link to="/send-product-checkout">
                                                    <Button sx={{ marginBottom: "0px !important" }} variant="contained" className='submitBtn submitBtn2 cartBtn checkoutBtn'>Proceed to checkout</Button>
                                                </Link>
                                                <Link to="/send-product"><Typography sx={{ color: '#033D1E', fontSize: '14px', mt: 2, textDecoration: "underline" }}>Continue Shopping</Typography></Link>
                                            </Box>

                                        </Box>
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Box>
                    <Footer />

                </>) :
                (
                    <>
                        <Login />
                    </>
                )}
        </>
    )
}

export default SendItemCart;