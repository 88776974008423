import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Col, Row, Form, } from 'react-bootstrap';
import Request from '../customUtils/Http/Request';
import Cookies from 'universal-cookie';
import { checkLogin } from '../commonFunctions/commonFunctions';

const cookies = new Cookies();
const isLoggedIn = checkLogin();

const useStyles = makeStyles((theme) => ({
  productRow: {
    paddingTop: "20px",
  },
  productCol: {
    display: "flex",
    alignItems: "center"
  },
  grid: {
    paddingLeft: "100px !important"
  },
  headerFlexBox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    paddingTop: "2px",
    flexWrap: "wrap",
    marginTop: "20px"
  },
  doubleArrow: {
    color: "#FFC72C",
    fontSize: "10px"
  },
  pathName: {
    fontSize: "14px !important",
    color: "#727272 !important"
  },
  activePath: {
    color: "#033D1E !important"
  },
  totalHeader: {
    borderBottom: "1px solid #E2E2E2"
  },
  formPadding: {
    marginTop: "12px"
  },
}));

const OrderDetail = () => {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [invoiceNo,setInvoiceNo] = useState(null)
  // const { orderId } = useParams();
  // const [orderState, setOrderState] = useState({
  //   orderId: orderId
  // });
  const [orderId, setOrderId] = useState(null);
  const [orderInfo, setOrderInfo] = useState({});

  let login = checkLogin()

//   useEffect(() => {
//     (async () => {
//         if (login === true) {
//             let data = await Request.get("cart", [], true);
//             {
//                 var countList = data.cartItems.length;
//                 var count = countList;
              
//                 document.getElementById('cartItem').innerHTML = count;
//             }
//         }
//         else {
//             const cartItems = cookies.get('cartItems', {path: "/"});
//             var count2 = 0;
//             var countList2 = cartItems.cartItems.length;
//             count2 = countList2;
          
//             document.getElementById('cartItem').innerHTML = count2;
//         }
//       })();
// }, []);

  useEffect(() => {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());

    const idFromUrl = urlParams.orderId;
    const invoiceNo = urlParams.invoiceNo;

    setOrderId(idFromUrl);
    setInvoiceNo(invoiceNo);

    if (login === true) {
      (async () => {
        let response = await Request.get(`order/order_detail/${idFromUrl}`, [], true);
        {
          if (response) {
            setOrderInfo(response)
            // setInvoiceNo(response.invoiceNo)
          } else {
            console.error('Failed to fetch data from API');
          }

        }
      })();
    }
    else {
      cookies.remove('cartItems',{path: "/"});
      cookies.remove('totalCartPrice', {path: "/"});
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     let response = await Request.get(`order/order_detail/${orderId}`, [] , true);
  //     {
  //       console.log(response,'order detail response');
  //       setOrderInfo(response)
  //     }
  // })();
  // }, [orderId]);

  // useEffect(() => {
  //   (async () => {
  //     let response = await Request.get(`order/order_detail/${orderId}`, [] , true);
  //     {
  //       console.log(response,'order detail response');
  //       setOrderInfo(response)
  //     }
  // })();
  // }, [orderInfo]);



  // useEffect(() => {
  //   (async () => {
  //     let response = await Request.get("order/order_detail", [orderId] , true);
  //     {
  //       console.log(response.json(),'order detail response');
  //     }
  // })();
  // }, [orderId]);


  //   useEffect(() => {
  //     if (!stripe) {
  //       return;
  //     }

  //     // Retrieve the "payment_intent_client_secret" query parameter appended to
  //     // your return_url by Stripe.js
  //     const clientSecret = new URLSearchParams(window.location.search).get(
  //       'payment_intent_client_secret'
  //     );

  //     // Retrieve the PaymentIntent
  //     stripe
  //       .retrievePaymentIntent(clientSecret)
  //       .then(({paymentIntent}) => {
  //         // Inspect the PaymentIntent `status` to indicate the status of the payment
  //         // to your customer.
  //         //
  //         // Some payment methods will [immediately succeed or fail][0] upon
  //         // confirmation, while others will first enter a `processing` state.
  //         //
  //         // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
  //         switch (paymentIntent.status) {
  //           case 'succeeded':
  //             setMessage('Success! Payment received.');
  //             setStatus(paymentIntent.id)
  //             break;

  //           case 'processing':
  //             setMessage("Payment processing. We'll update you when payment is received.");
  //             break;

  //           case 'requires_payment_method':
  //             // Redirect your user back to your payment page to attempt collecting
  //             // payment again
  //             setMessage('Payment failed. Please try another payment method.');
  //             break;

  //           default:
  //             setMessage('Something went wrong.');
  //             break;
  //         }
  //       });
  //   }, [stripe]);


  return (
    <>
      <Box className={login === 'true' ? 'orderContainer' : 'orderContainer orderBoxContainer'}>
        <Box>
            <Typography variant='h5' className='color1 font-bold'>Order Confirmation</Typography>
            <Box className={classes.headerFlexBox}>
            <Typography className={classes.pathName}>Shop</Typography>
            <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
            <Typography className={classes.pathName}>Cart</Typography>
            <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
            <Typography className={classes.pathName}>Checkout</Typography>
            <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
            <Typography className={classes.pathName}>Payment</Typography>
            <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
            <Typography className={`${classes.pathName} ${classes.activePath}`}>Order Confirmation</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={login === 'true' ? '' : ''}>
        <Grid container className='paymentBox'>
          <Grid item xs={12} className='cartColLeft paymentCol detailBox'>
            <Box align="center">
              <Typography className='font-22 font-bold color2' sx={{ pb: 3 }}>Your Order Has Been Received</Typography>
              <Box sx={{ pb: 3 }}>
                <img src="/assets/img/orderIcon.png" alt='order_icon' className='order_img'></img>
              </Box>
              <Typography className='font-22 color2' sx={{ pb: 2 }}>Thanks For Your Order!!</Typography>
              <Box className='font-18 color1'>Your Order <span className='font-18 font-bold'>{invoiceNo}</span> is in the process</Box>
              <Box sx={{ pt: 5, display: "flex", gap: "20px", justifyContent: "center" }}>
                <Link to='/'>
                  <Button className='b1 b3'>Continue Shopping</Button>
                </Link>
                {isLoggedIn === true ? (
                  <Link to='/user/customer/order-history'>
                    <Button className='b1'>Track Order</Button>
                  </Link>
                ):(
                  <></>
                )}
                
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>


  )
};

export default OrderDetail;