// import { createStore, combineReducers } from 'redux';

// // Define your initial state for the sidebar
// const initialSidebarState = {
//   minimized: false,
//   cartItems: [],
// };

// // Define a reducer for the sidebar state
// const sidebarReducer = (state = initialSidebarState, action) => {
//   switch (action.type) {
//     case 'TOGGLE_SIDEBAR':
//       return { ...state, minimized: !state.minimized };
//     default:
//       return state;
//   }
// };

// const initialCartState = {
//   cartItems: [],
// };

// const cartReducer = (state = initialCartState, action) => {
//   switch (action.type) {
//     case 'ADD_TO_CART':
//       return { ...state, cartItems: [...state.cartItems, action.payload] };
//     case 'REMOVE_FROM_CART':
//       return {
//         ...state,
//         cartItems: state.cartItems.filter((item) => item.id !== action.payload),
//       };
//     default:
//       return state;
//   }
// }

// // Combine reducers if you have multiple reducers
// const rootReducer = combineReducers({
//   sidebar: sidebarReducer,
//   cart: cartReducer,
// });

// // Create the Redux store
// const store = createStore(rootReducer);

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import customerReducer from "./customerSlice";
import driverReducer from "./driverSlice";
import driverOrderReducer from "./driverOrderSlice";
import productListReducer from "./productListSlice";
import taskListReducer from "./taskManagementSlice"
import productCategoriesReducer from "./productCategoriesSlice"
import islanderListReducer from "./islanderListSlice"
import shippingPriceReducer from "./shippingPriceSlice"
import helpCenterReducer from "./helpCenterSlice"
import pickupLocationReducer from "./pickupLocationSlice";
import deliveryTimeReducer from "./deliveryTimeSlice";
import orderListReducer from "./orderListSlice"
import priorityProductListReducer from "./priorityProductSlice"
import priorityProductCategoriesReducer from "./priorityProductCategoriesSlice"
import reviewReducer from "./reviewSlice"
import userListReducer from "./userSlice"
import taxListReducer from "./taxSlice"
import userAddressReducer from "./userAddressSlice"

const store = configureStore({
  reducer: {
    cart: cartReducer,
    customer: customerReducer,
    driver: driverReducer,
    driverOrder: driverOrderReducer,
    productList: productListReducer,
    taskManagement: taskListReducer,
    productCategories: productCategoriesReducer,
    islanderList: islanderListReducer,
    shippingPrice: shippingPriceReducer,
    helpCenter: helpCenterReducer,
    pickupList: pickupLocationReducer,
    deliveryTime: deliveryTimeReducer,
    order: orderListReducer,
    priorityProductList: priorityProductListReducer,
    priorityProductCategories: priorityProductCategoriesReducer,
    review: reviewReducer,
    userList: userListReducer,
    taxList: taxListReducer,
    userAddress: userAddressReducer
  }
});

export default store;