import { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Card, CardMedia, Dialog, DialogContent } from '@mui/material';
import { checkLogin } from '../commonFunctions/commonFunctions';
import { CardActions, CardContent } from '@material-ui/core';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form, Dropdown, Table } from 'react-bootstrap';
import { protect } from '../commonFunctions/isValid';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import BarrelList from './barrelList';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css"; // Import the carousel styles
import "slick-carousel/slick/slick-theme.css"; // Import the carousel theme styles
import Slider from 'react-slick';
import Cookies from 'universal-cookie';
import ProductDescription from './productDescription';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { setCart, fetchCartItems, incrementItem, decrementItem , incQuantityAsync, decQuantityAsync } from '../redux/cartSlice';

const cookies = new Cookies();

// import { BootstrapInput } from 'react-bootstrap';
const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "30px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    productRow: {
        paddingTop: "15px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    productAdd: {
        backgroundColor: '#EBF7F1 !important',
        borderTopRightRadius: '5px !important',
        borderBottomRightRadius: '5px !important',

    },
    productRemove: {
        backgroundColor: '#FAFAFA !important',
        borderTopLeftRadius: '5px !important',
        borderBottomLeftRadius: '5px !important',

    },
    grid: {
        paddingLeft: "100px !important"
    },
    infoText: {
        position: 'absolute',
        top: '-90px',
        left: '-25%',
        transform: 'translate(30%, 0px)',
        padding: '10px',
        backgroundColor: '#fff',
        color: '#727272',
        borderRadius: '4px',
        fontSize: '14px !important',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        zIndex: '9',
        width: "200px",
        boxShadow: "10px 24px 54px rgba(0, 0, 0, .2)",
        lineHeight: "20px !important"
    },
    icon: {
        color: '#727272 !important',
        fontSize: '20px !important',
        cursor: 'pointer',
        '&:hover': {
            '& ~ $infoText': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    triangle: {
        content: '""',
        position: 'absolute',
        bottom: '-15px',
        left: '18%',
        transform: 'translateX(-50%) rotate(0deg)',
        borderTop: '16px solid #fff',
        borderLeft: '0px solid transparent',
        borderRight: '20px solid transparent',
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
        grid: {
            paddingLeft: "0px !important"
        },
    },
    
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

function BuyProductDetail() {
    const location = useLocation();
    const classes = useStyles();
    const [size, setSize] = useState('1');

    const [selectedProductId, setSelectedProductId] = useState(null);
    const [productName, setProductName] = useState('');
    const [productImg, setProductImg] = useState('');
    const [productSize, setProductSize] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productStock, setProductStock] = useState('');
    const [productStockManagement, setProductStockManagement] = useState(false);
    const [quantity, setQuantity] = useState({});
    const [addedItemId, setAddedItemId] = useState(null);
    const [expandedItems, setExpandedItems] = useState([]);
    const [info, setInfo] = useState([]);
    const [selectedProductInfo, setSelectedProductInfo] = useState({
        "closureType": '',
        "color": '',
        "compatibleMaterial": '',
        "description": '',
        "dimensions": '',
        "discount": 0,
        "discountedPrice": 0,
        "price": 0,
        "item": '',
        "material": '',
        "name": '',
        "numberOfItems": 0,
        "recommendedUses": '',
        "shape": '',
        "shortDescription": '',
        "specialFeatures": 0,
        "style": '',
        "surfaceRecommendation": '',
        "weight": "",
        "stock":0,
        "images":[]
    })

    let [num1, setNum1] = useState(0);
    let [num2, setNum2] = useState(0);
    const [barrelInfo, setBarrelInfo] = useState([]);
    const [cartItem, setCartItem] = useState();
    const [selectedOption, setSelectedOption] = useState('');
    const [dropdownOption, setDropdownOption] = useState([]);
    const [daysCount, setDaysCount] = useState(0);
    const [sliderProductType,setSliderProductType] = useState('')
    const [linkProductType,setLinkProductType] = useState('')
    const [dialogOpen, setDialogOpen] = useState(false);
    const [maxText, setMaxText] = useState(0);
    const dispatch = useDispatch();

    let login = checkLogin()

    const handleSize = (event) => {
        setSize(event.target.value);
    };

        // Custom CSS styles for the arrows
        const customArrowStyles = {
            display: 'block',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            fontSize: 24,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '0px',
            borderRadius: '50%',
            cursor: 'pointer',
        };
        


        // Custom Next Arrow component
        const CustomNextArrow = (props) => {
            const { className, onClick } = props;
            return (
                <div
                    className={className}
                    style={{ ...customArrowStyles, right: 10 }}
                    onClick={onClick}
                >
                </div>
            );
        };
    
        // Custom Previous Arrow component
        const CustomPrevArrow = (props) => {
            const { className, onClick } = props;
            return (
                <div
                    className={className}
                    style={{ ...customArrowStyles, left: 10 }}
                    onClick={onClick}
                >
                </div>
            );
        };

        const sliderSettings = {
            dots: false, // Show dots (indicators)
            arrows: true, // Show navigation arrows
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <CustomNextArrow />, // Custom Next Arrow component
            prevArrow: <CustomPrevArrow />, // Custom Previous Arrow component
        };

            // Function to handle "Read more" and "Read less" toggle
    const handleToggleExpand = (itemId) => {
        setExpandedItems((prevExpandedItems) => {
            if (prevExpandedItems.includes(itemId)) {
                return prevExpandedItems.filter((id) => id !== itemId);
            } else {
                return [...prevExpandedItems, itemId];
            }
        });
    };


    const addProductToCart = async (productId,productPrice,productSize,productName,imgsrc) => {
        
        if(quantity[productId] === undefined){
            alert("Please add quantity");
            return
        }
        setTimeout(() => {
          setAddedItemId(null);
        }, 3000);
        // const productQuantity = quantity[productId] || 0; 
        if (login === true) {
            const cartItems = [];
            const productQuantity = quantity[productId] || 0; 
                const item = {
                    cartItemId: productId,
                    quantity: productQuantity,
                };

                if (item.quantity > 0) {
                    cartItems.push(item);
                }

            if(cartItems.length === 0){
                return
            }
            else {
                var items = { "cartItems": cartItems, "type": "Purchase" }
                let response = await Request.post("cart", items, true)
                {   
                    try {
                        if (response.message === "Success") {
                            setAddedItemId(productId);
                             
                            let data = await Request.get("cart", [], true)
                            {
                                try {
                                    if(data !== undefined){
                                        dispatch(setCart(data));
                                    }
                                }
                                catch{

                                }
                            }
                            
                        }
                        else {
                            console.error('Failed to fetch data from API');
                        }
                    }
                    catch (error) {
                        console.error('Error fetching data from API:', error);
                    }
                }
            }
            
}

else {
      
       // Retrieve the existing cartItems from the cookie, if available
       const existingCartItems = cookies.get('cartItems', {path: '/'}) || { cartItems: [] };
       const cartItems = existingCartItems.cartItems;
       // Find the index of the item in the cart, if it exists
       const existingItemIndex = cartItems.findIndex(item => item.cartItemId === productId);
   
        // Get the quantity from the input field or set it to the existing quantity
        const productQuantityInput = quantity[productId] || 0;
        setAddedItemId(productId);

        // if(imgsrc === ''){
        //     imgsrc = '/assets/img/no_image_item.png'
        // }

        // const blob = await imgsrc.blob();
        // const reader = new FileReader();
        // var base64Image;

        // reader.onload = () => {
        //    base64Image = reader.result;
        // //   Cookies.set('product_image', base64Image); // Save the base64 image to a cookie
        // };
        
        // reader.readAsDataURL(blob);
    if (productQuantityInput > 0) {
        const updatedItem = {
            cartItemId: productId,
            quantity: productQuantityInput,
            'barrel': { 
                price: productPrice, 
                size: productSize, 
                name: productName,
                images: []
                // 'images': { img: base64Image } 
            },
            totalPrice: productPrice * productQuantityInput  
        };

        if (imgsrc !== "") {
            // Add imgsrc to the images array
            updatedItem.barrel.images.push({ img: imgsrc });
          }

        if (existingItemIndex !== -1) {
            // Update the existing item's quantity
            cartItems[existingItemIndex] = updatedItem;
        } else {
            // Add a new item to the cart
            cartItems.push(updatedItem);
        }

        // Calculate the totalPrice by summing up the productPrice multiplied by the quantity of each item in the cart
        // const totalConfirmPrice = cartItems.reduce((total, item) => total + item.barrel.price * item.quantity, 0);
        const totalCartPrice = cartItems.reduce((total, item) => total + item.totalPrice, 0);
    
        // Save the updated cartItems and totalPrice in the cookie
        // cartItems.totalPrice = totalPrice;
        var items = {"cartItems": cartItems, "totalCartPrice": totalCartPrice}
        // const items = { cartItems, totalPrice }; 
        cookies.set('cartItems', items , { path: '/', secure: true });
        dispatch(setCart(items));
        }
}
    }


    useEffect(() => {
          
        const searchParams = new URLSearchParams(location.search);
        const questionId = searchParams.get('questionId');
        const pathSegments = window.location.pathname.split('/'); // Split the URL by "/"
        const productId = pathSegments[pathSegments.length - 2];
        const sliderProductType = pathSegments[pathSegments.length - 1]
        // setSliderProductType(sliderProductType)
        setSelectedProductId(productId);

        if(sliderProductType === "barrel"){
            setSliderProductType(sliderProductType)
            setLinkProductType("accessories")
        }
        else{
            setSliderProductType(sliderProductType)
            setLinkProductType("barrel")
        }
    }, []);

    useEffect(() => {
      fetchDetail();
    }, []);

    useEffect(() => {
        fetchInfo();
    }, []);


    useEffect(() => {
          
        fetchQuantity();
    }, [selectedProductId]);

    const handleCloseDialog = () => {
        setDialogOpen(false);
      };
    const handleContactPage = () => {
        window.location.href = '/contact-us'
    };

    let incQuantity = (id,maxLimit ) => {
        if(maxLimit > 10){
            var maxLimitNew = 10;
        }else{
            var maxLimitNew = maxLimit;
        }
        setQuantity((prevQuantity) => {
            const prevQuantity1 = prevQuantity[id] || 0;
            if (prevQuantity1 + 1 <= maxLimitNew) {
                const newQuantity = prevQuantity1 + 1;
                return { ...prevQuantity, [id]: newQuantity };
              } else {
                // Show an error dialog when the quantity exceeds the stock limit
                setDialogOpen(true);
                setMaxText(maxLimitNew)
                return prevQuantity;
              }
        });
    };

    let decQuantity = ( id,maxLimit) => {
        setQuantity((prevQuantity) => {
            const prevQuantity1 = prevQuantity[id] || 1;
            const newQuantity = Math.max(prevQuantity1 - 1, 1);
            return { ...prevQuantity, [id]: newQuantity };
        });
    }

    const fetchInfo = async () => {
        // try {
            const pathSegments = window.location.pathname.split('/'); // Split the URL by "/"
            const productId = pathSegments[pathSegments.length - 2];
            const productType = pathSegments[pathSegments.length - 1];
               
            if(productType === "barrel"){
                try {
                    // Fetch data from API
                    let response = await Request.get('barrel/');
                    if (response) {
                         
                        setInfo(response.accessoriesList);
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error fetching data from API:', error);
                }
            }
            else{
                try {
                    // Fetch data from API
                    let response = await Request.get('barrel/');
                    if (response) {
                          
                         
                        setInfo(response.barrelList);
                        // setAccessoriesInfo(response.accessoriesList)
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error fetching data from API:', error);
                }
            }
        // }
    }

    const fetchDetail = async () => {
        try {
            const pathSegments = window.location.pathname.split('/'); // Split the URL by "/"
            const productId = pathSegments[pathSegments.length - 2];
            const productType = pathSegments[pathSegments.length - 1];
            // Fetch data from API
            let response = await Request.get(`barrel/product/${productId}`, [] );
            if (response) {
                setSelectedProductInfo({
                    "closureType": response.closureType,
                    "color": response.color,
                    "compatibleMaterial": response.compatibleMaterial,
                    "description": response.description,
                    "dimensions": response.dimensions,
                    "discount": response.discount,
                    "discountedPrice": response.discountedPrice,
                    "price": response.price,
                    "item": response.item,
                    "material": response.material,
                    "name": response.name,
                    "numberOfItems": response.numberOfItems,
                    "recommendedUses": response.recommendedUses,
                    "shape": response.shape,
                    "shortDescription": response.shortDescription,
                    "specialFeatures": response.specialFeatures,
                    "style": response.style,
                    "surfaceRecommendation": response.surfaceRecommendation,
                    "weight": response.weight,
                    "stock":response.stock,
                    "images":response.images.length > 0 ? response.images : []
                })
                    setProductImg(response.images[0].img);
                    setProductName(response.name);
                    setProductSize(response.size);
                    setProductPrice(response.price);
                    setProductStock(response.stock);
                    setProductStockManagement(response.stockManagement);
                    setProductDescription(response.description);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchQuantity = async () => {
          
        if (login === true) {
            try {
                let response = await Request.get('cart', [], true);
                if (response) {
                    const pathSegments = window.location.pathname.split('/'); // Split the URL by "/"
                    const productId = pathSegments[pathSegments.length - 2];

                    const selectedProduct = response.cartItems.find(prod => prod.barrelId === productId);
                    if (selectedProduct) {
                        setQuantity({ [selectedProductId]: selectedProduct.quantity });
                    }
                    // setQuantity(cartQuantity);
                    // setAccessoriesInfo(response.accessoriesList)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        }
        else {

            const pathSegments = window.location.pathname.split('/'); // Split the URL by "/"
            const productId = pathSegments[pathSegments.length - 2];

            const existingCartItems = cookies.get('cartItems', {path: '/'}) || { cartItems: [] };
            // const cartItems = existingCartItems.cartItems;

            // const cartQuantity = {}
            // existingCartItems.cartItems.forEach(item => {
            //     cartQuantity[item.cartItemId] = item.quantity;
            // });
            // setQuantity(cartQuantity);
            if(existingCartItems.cartItems.length === 0){
                setQuantity({ [selectedProductId]: 0 });
            }
            else{
                const selectedProduct = existingCartItems.cartItems.find(prod => prod.cartItemId === productId);
                if (selectedProduct) {
                    setQuantity({ [selectedProductId]: selectedProduct.quantity });
                }
            }
          
        }
    };

    
    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Shop
                </Typography>
                <Box className={classes.headerFlexBox}>
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Buy {selectedProductInfo.name}</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                </Box>
            </Box>
            <Box className='productContainer'>
                <Grid container className="block1" columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pl: 0 }}>
                        <Box className="ProductImageBox">
                                <Card className={classes.card}>
                                    {selectedProductInfo.images.length === 0 ? (
                                        <Slider {...sliderSettings}>
                                            <div className='productOuterBox'>
                                                <CardMedia
                                                    component="img"
                                                    alt="No image Of Item Available"
                                                    image="/assets/img/no_image_item.png"
                                                    className='productListImg'
                                                />
                                            </div>
                                        </Slider>
                                    ) : (
                                        <Slider {...sliderSettings}>
                                            {selectedProductInfo.images.map((image, index) => (
                                                <div key={index} className='productOuterBox '>
                                                    <CardMedia
                                                        component="img"
                                                        alt={`Image ${index + 1}`}
                                                        image={`${process.env.REACT_APP_DatabaseStatic}${image.img}`}
                                                        className='productListImg'
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    )}
                                </Card>
                            {/* {selectedProductInfo.img ? (
                                 <img src={`${process.env.REACT_APP_DatabaseStatic}${selectedProductInfo.img}`} className='product_img'/>
                            ):(
                                <img src="/assets/img/product_img.png" className='product_img' />
                            )} */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}  sx={{ display: "flex", alignItems: "center" }} className={classes.grid}>
                        <Box className="product-Description-box">
                            <Typography className='font-30 color1 font-bold'>{selectedProductInfo.name}</Typography>
                            {/* <BarrelList barrelInfo={barrelInfo} onClick1={decNum} onClick2={incNum} inputs={inputs} /> */}
                            {/* </Grid> */}
                            <Box>
                                <Row className={classes.productRow} >
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", position: "relative" }}>
                                            <Typography variant="standard" align="left" className='font-16 color1 font-bold barrelSize'>{productSize}</Typography>
                                            <InfoOutlinedIcon className={classes.icon} />
                                            <span className={classes.infoText}>
                                                <span className={classes.triangle}></span>
                                                {selectedProductInfo.shortDescription}</span>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "15px", mt: 0 }}>
                                            <Typography className='priceText font-medium'>${selectedProductInfo.discountedPrice}</Typography>
                                            {selectedProductInfo.discount !== 0 && (
                                                 <Typography className='priceText font-medium'><del>${selectedProductInfo.price}</del></Typography>
                                            )}
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className={classes.productCol}>
                                        <Box className="flexBox">
                                            {/* <Typography className='color1 font-12 font-bold'>Qty :</Typography> */}
                                            <Box>
                                                <Box className="quantityBox">
                                                    {productStockManagement === false ? (
                                                        <>
                                                            <Box disabled={quantity <= 1} min="1" onClick={() => decQuantity(selectedProductId,1)}>
                                                                <Button className={classes.productRemove} disabled={quantity <= 1}>-
                                                                    {/* <RemoveIcon className={classes.actionIcon} /> */}
                                                                </Button>
                                                            </Box>
                                                            <input className="cartInput" value={quantity[selectedProductId] || 0} readOnly></input>
                                                            <Box onClick={() => incQuantity(selectedProductId,10)}>
                                                                <Button className={classes.productAdd}>+
                                                                    {/* <AddIcon className={classes.actionIcon} /> */}
                                                                </Button>
                                                            </Box>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Box disabled={quantity <= 1} min="1" onClick={() => decQuantity(selectedProductId,1)}>
                                                                <Button className={classes.productRemove} disabled={quantity <= 1}>
                                                                    <RemoveIcon className={classes.actionIcon} />
                                                                </Button>
                                                            </Box>
                                                            <input className={classes.quantityInput} value={quantity[selectedProductId] || 0} readOnly></input>
                                                            <Box onClick={() => incQuantity(selectedProductId,productStock)}>
                                                                <Button className={classes.productAdd}>
                                                                    <AddIcon className={classes.actionIcon} />
                                                                </Button>
                                                            </Box>
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            <Box sx={{pt: 2}}>
                            {/* {selectedProductInfo.stock && selectedProductInfo.stock !== 0 ? (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Stock:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.stock}</Typography>
                                </Box>
                            ):(<></>)} */}
                             {selectedProductInfo.weight && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Weight:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.weight}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.closureType && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Closure Type:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.closureType}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.recommendedUses && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Recommended Uses:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.recommendedUses}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.color && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Color:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.color}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.compatibleMaterial && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Compatible Material:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.compatibleMaterial}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.dimensions && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Dimensions:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.dimensions}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.item && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Item:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.item}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.material && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Material:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.material}</Typography>
                                </Box>
                            )}
                            {selectedProductInfo.numberOfItems && selectedProductInfo.numberOfItems !== 0 ? (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Number of Items:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.numberOfItems}</Typography>
                                </Box>
                            ):(<></>)}
                            {selectedProductInfo.shape && selectedProductInfo.shape !== null ? (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Shape:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.shape}</Typography>
                                </Box>
                            ):(<></>)}
                               {selectedProductInfo.style && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Style:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.style}</Typography>
                                </Box>
                            )}
                               {selectedProductInfo.specialFeatures && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Special Features:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.specialFeatures}</Typography>
                                </Box>
                            )}
                               {selectedProductInfo.surfaceRecommendation && (
                                <Box>
                                    <Typography className='font-16 font-600 color1 d-inline'>Surface Recommendation:</Typography>
                                    <Typography className='font-15 color1 pl-10 d-inline'>{selectedProductInfo.surfaceRecommendation}</Typography>
                                </Box>
                            )}
                              
                            </Box>
                            <Box sx={{ mt: 5, position: "relative" }}>
                                <Button sx={{ marginBottom: "0px !important" }} variant="contained" className='b1' onClick={() => addProductToCart(selectedProductId,productPrice,productSize,productName,productImg)}>Add to Cart</Button>
                                {addedItemId === selectedProductId && <Typography className='adsCartMsg' sx={{pt: 3}}>Product Added to Cart</Typography>}                            
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <ProductDescription description={productDescription}/>
                <Box>
                    <Box className="multi_carousel logo_carousel" sx={{ mt: 5, mb: 0 }}>
                            <Typography className='font22 color1 font-600 mb-3'>Related Items</Typography>
                            <Carousel
                                responsive={responsive}
                                infinite={true} // Enable infinite loop
                                autoPlay// Enable auto play
                                autoPlaySpeed={3000}
                            >
                                {/* {info && (
                                    <> */}
                                        {(info && info.length>0) && info.map((item) => (
                                            <Box key={item.id} sx={{margin: 5 + 'px' , border: "1px solid #EBEBEB"}}>
                                                <Card className={classes.card}>
                                                {item.images.length === 0 ? (
                                                    <a href={`/user/buy/buy-product-detail/${item.id}/${linkProductType}`} >
                                                    <Slider {...sliderSettings}>
                                                        <div className='productOuterBox'>
                                                            <CardMedia
                                                                component="img"
                                                                alt="No image Of Item Available"
                                                                image="/assets/img/no_image_item.png"
                                                                className='productListImg'
                                                            />
                                                        </div>
                                                    </Slider>
                                                    </a>
                                                ) : (
                                                    <a href={`/user/buy/buy-product-detail/${item.id}/${linkProductType}`} >
                                                    <Slider {...sliderSettings}>
                                                        {item.images.map((image, index) => (
                                                            <div key={index} className='productOuterBox'>
                                                                <CardMedia
                                                                    component="img"
                                                                    alt={`Image ${index + 1}`}
                                                                    image={`${process.env.REACT_APP_DatabaseStatic}${image.img}`}
                                                                    className='productListImg'
                                                                />
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                    </a>
                                                )}

                                                <CardContent>
                                                    <Typography className='font-18 color1 font-bold'>{item.name}</Typography>
                                                    {/* <Typography className='font-14 color1'>Size: <span className='font-14 color4 font-medium'>{item.size} GL</span></Typography> */}
                                                    <Typography className='font-14 color1 font-600'>Price: <span className='font-14 color4 font-medium'>${item.price}</span></Typography>
                                                    {item.description !== null ? (
                                                        <>
                                                            <Typography className='font-14 color1 font-bold'>Product Description:</Typography>
                                                            <Typography className={`font-13 color1 truncate-text productDescription ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>
                                                                {expandedItems.includes(item.id) ? item.description : `${item.description.slice(0, 100)}`}
                                                                {item.description.length > 100 && (
                                                                    <span
                                                                        className="read-more-link font-13 color4 productDescription"
                                                                        onClick={() => handleToggleExpand(item.id)}
                                                                    >
                                                                        {expandedItems.includes(item.id) ? ' Read less' : ' Read more...'}
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </CardContent>
                                                </Card>
                                            </Box>
                                        ))}
                                    {/* </>
                                )} */}
                                
                            </Carousel>
                    </Box>
                </Box>
            </Box>
              {/* Material UI Dialog for showing the error */}
              <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogContent>
                        The quantity cannot exceed {maxText}
                    </DialogContent>
                    <Button onClick={handleCloseDialog}>OK</Button>
                    <Button onClick={handleContactPage}>Contact Us For Bulk Order</Button>
                </Dialog>
        </Box>


    )
};


export default BuyProductDetail;

