import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect,useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Request from '../customUtils/Http/Request';
import YourOrderTable from './yourOrderTable';
import Cookies from 'universal-cookie';
import { Col, Row, Form, Table as BootstrapTable, } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllOrderList, fetchCanceledOrderList, fetchDeliveredOrderList, fetchIslanderOrderList,
fetchPickupOrderList, fetchShippingOrderList } from "../redux/orderListSlice";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    Modal,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
} from "@mui/material"
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    alignRight : {
        textAlign: "right"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    totalHeader: {
        borderBottom: "1px solid #E2E2E2"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: "25px",
        paddingTop: "30px"
    },
    orderInnerGrid: {
        padding: "30px",
        border: "1px solid lightgray",
        borderRadius: "10px",
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
	 imgBox:{
		width: "170px",
		height: "60px"
	 },
     flexEnd: {
        display: "flex",
        justifyContent: "end"
     },
     '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
        flexEnd: {
            justifyContent: "start"
        },
        alignRight: {
            textAlign: "left"
        }
    },
    contactDetailBox: {
        border: "1px solid #BCBCBC",
        borderRadius: "10px",
        padding: "10px 15px"
    }
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    minWidth: '50%',
    overflow: 'auto',
    height: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    color: 'var(--primary-color1)',
    // Define responsive styles
    '@media (max-width: 1100px)': {
      maxWidth: '80%', // Adjust as needed for smaller screens
      minWidth: '80%', // Adjust as needed for smaller screens// Limit the height on smaller screens if necessary
      p: 2, // Reduce padding on smaller screens if necessary
    },
    '@media (max-width: 767px)': {
        maxWidth: '95%', // Adjust as needed for smaller screens
        minWidth: '95%', // Adjust as needed for smaller screens
        maxHeight: '90vh', // Limit the height on smaller screens if necessary
        p: 2, // Reduce padding on smaller screens if necessary
      },
  };


const OrderDetailEdit = ({ orderId, orderDetail, updateOrderDetail, orderType, setOpen }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const classes = useStyles();
    const dialogRef = useRef(null);
    const [description, setDescription] = useState('');
    // const [orderId, setOrderId] = useState(null);
    const [billingAddressText, setBillingAddressText] = useState([]);
    const [shippingAddressText, setShippingAddressText] = useState([]);
    const [senderAddressText, setSenderAddressText] = useState([]);
    const [pickupAddressText, setPickupAddressText] = useState([]);
    const [estDate, setEstDate] = useState('');
    const [productDetail, setProductDetail] = useState([]);
    const [driverList, setDriverList] = useState([]);
    const [openTrackingBox, setOpenTrackingBox] = useState(false);

    const [openDetail, setOpenDetail] = useState(false);
    const [orderDetailId, setOrderDetailId] = useState(null);
    const [invoiceNo, setInvoiceNo] = useState(null);
    const [availableBol, setAvailableBol] = useState(false);
    // const [productDetail, setProductDetail] = useState([]);
    const [priorityProductDetail, setPriorityProductDetail] = useState([]);
    const [boxDetail, setBoxDetail] = useState([]);
    const [orderInvoiceDetail, setOrderInvoiceDetail] = useState([]);
    const dispatch = useDispatch()
    

    // const list1 = useSelector((state) => state.order.orderShippingList);
	// const list2 = useSelector((state) => state.order.orderPickupList);
	// const list3 = useSelector((state) => state.order.orderIslanderList);
	// const list4 = useSelector((state) => state.order.orderDeliveredList);
	// const list5 = useSelector((state) => state.order.orderCanceledList);
	// const list6 = useSelector((state) => state.order.allOrderList);
    // useEffect(() => {
    //     dispatch(fetchShippingOrderList())
    //     dispatch(fetchPickupOrderList())
    //     dispatch(fetchIslanderOrderList())
    //     dispatch(fetchDeliveredOrderList())
    //     dispatch(fetchCanceledOrderList())
    //     dispatch(fetchAllOrderList())
    // },[dispatch]);

    // useEffect(() => {
    //     dispatch(fetchShippingOrderList())
    //     dispatch(fetchPickupOrderList())
    //     dispatch(fetchIslanderOrderList())
    //     dispatch(fetchDeliveredOrderList())
    //     dispatch(fetchCanceledOrderList())
    //     dispatch(fetchAllOrderList())
    // },[]);
    const isValidTime = (time) => {
        const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        return timeRegex.test(time);
      };
      
    const formatTime = (startTime,endTime) => {
        // const hours = Math.floor(time);
        // const minutes = Math.round((time - hours) * 60);
        // return `${hours.toFixed(2)}.${minutes.toFixed(2)}`;
        return `${startTime} - ${endTime}`;
    };
    
    let start_time = orderDetail?.slot?.start_time;
    let end_time = orderDetail?.slot?.end_time;
    // var pickUpSlot = isNaN(start_time) || isNaN(end_time) ? 'N/A' : `${formatTime(start_time)}-${formatTime(end_time)}`;
    if (orderDetail && orderDetail?.slot && (start_time) && (end_time)) {
        var pickUpSlot = `${formatTime(start_time,end_time)}`;
    } else {
        var pickUpSlot = 'N/A';
    }
    
    const [trackingData, setTrackingData] = useState({
        statusUpdateDate: '',
        description: '',
        location: '',
    })
    const [formData, setFormData] = useState({
        customerName: "",
        orderId: "",
        orderDate: "",
        orderStatus: "",
        recipientAddress: "",
        senderAddress: "",
        pickUpAddress: "",
        billingAddress: "",
        pickUpDate: orderDetail?.slot?.date,
        actualPickupDate: orderDetail?.actualDeliveryDate,
        pickUpSlot: pickUpSlot,
        estimatedDeliveryDate: orderDetail?.estDeliveryTimeInDay,
        trackingId: "",
        billingOfLading: "",
        paymentMethod: "",
        paymentStatus: "",
    });
   
    const [editableData, setEditableData] = useState({
        trackingNo: orderDetail?.trackingNo || '',
        actualDeliveryDate: orderDetail?.actualDeliveryDate || '',
        orderStatus: orderDetail?.orderStatus || '',
        driver: orderDetail?.driver_order_assignment?.driver?.user?.fullName || '',
        bol: orderDetail?.bol || '',
        paymentStatus:orderDetail?.paymentStatus || 'false'
    });

    const orderStatusOptions = [
        { text: 'Incomplete', value: 0 },
        {text: 'Order Received', value: 1},
        { text: 'Ready to Pickup', value: 2 },
        { text: 'Picked Up', value: 3 },
        { text: 'Ready to Ship', value: 4 },
        { text: 'In-Transit', value: 5 },
        { text: 'Delivered', value: 6 },
        { text: 'Canceled', value: 7 },
        { text: 'Delete', value: 8 },
    ];

    const orderStatusOptions2 = [
        { text: 'Incomplete', value: 0 },
        { text: 'Order Received', value: 1 },
        { text: 'Ready to Ship', value: 4 },
        { text: 'In-Transit', value: 5 },
        { text: 'Delivered', value: 6 },
        { text: 'Canceled', value: 7 },
    ];

    const paymentStatus = [
        { text: 'Success', value : 'true' },
        { text: 'Pending' ,  value : 'false'}
    ];

    function formatDate(timestamp) {
        if(timestamp !== null){
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }  
    }

    useEffect(() => {
        fetchDriverData();
    }, []);

    useEffect(() => {
        setProductDetail(orderDetail?.products)
        formatBillingAddress(orderDetail?.billingAddress);
        formatShippingAddress(orderDetail?.shippingAddress);
        formatSenderAddress(orderDetail?.senderAddress);
        formatPickupAddress(orderDetail?.pickupAddress);

        const estimatedDeliveryDate = new Date(orderDetail?.createdAt);
        estimatedDeliveryDate.setHours(estimatedDeliveryDate.getHours() + orderDetail?.estDeliveryTime);

        if (!isNaN(estimatedDeliveryDate)) {
            setEstDate(estimatedDeliveryDate.toISOString());
        }
        else {
            console.log('error')
        }
    }, [orderDetail]);

    useEffect(() => {
        setEditableData({
            actualDeliveryDate: orderDetail?.actualDeliveryDate || '',
            trackingNo: orderDetail?.trackingNo || '',
            orderStatus: orderDetail?.orderStatus || editableData.orderStatus,
            driver: orderDetail?.driver_order_assignment?.driver?.id || '',
            bol: orderDetail?.bol || null,
            paymentStatus:orderDetail?.paymentStatus || ''
        });
        if(orderDetail?.bol){
            setAvailableBol(true)
        }
    }, [orderDetail]);

    const fetchDriverData = async () => {   
        try {
            let response = await Request.get('admin/driver/approved', [], true);
            if (response.length !== 0) {
                setDriverList(response)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };
    
    const handleDateChange = (date) => {
        setTrackingData({
            ...trackingData,
            "statusUpdateDate" : date,
        });
      };


    const handleDescriptionChange = (event) => {
    const newValue = event.target.value;
    setTrackingData({
        ...trackingData,
        "description" : newValue,
    });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditableData({
            ...editableData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEditableData({
                ...editableData,
                bol: file, // Store the selected file object
            });
        } else {
            setEditableData({
                ...editableData,
                bol: '', // Clear the selected file
            });
        }
    };

    const formatBillingAddress = (address) => {
        if (address) {
            const formattedAddress = [
                address.fullName,
                address.address_line_1,
                address.address_line_2,
                address.city, address.zip,
                address.state,
                address.country,

            ].filter(Boolean).join(', ');

            setBillingAddressText(formattedAddress);
        } else {
            setBillingAddressText('');
        }
    };

    const formatShippingAddress = (address) => {
        if (address) {
            const formattedAddress = [
                address.fullName,
                address.address_line_1,
                address.address_line_2,
                address.city, address.zip,
                address.state,
                address.country,
            ].filter(Boolean).join(', ');

            setShippingAddressText(formattedAddress);
        } else {
            setShippingAddressText('');
        }
    };

    const formatSenderAddress = (address) => {
        if (address) {
            const formattedAddress = [
                address.fullName,
                address.address_line_1,
                address.address_line_2,
                address.city, address.zip,
                address.state,
                address.country,
            ].filter(Boolean).join(', ');

            setSenderAddressText(formattedAddress);
        } else {
            setSenderAddressText('')
        }
    };

    const formatPickupAddress = (address) => {
        if (address) {
            const formattedAddress = [
                address.fullName,
                address.address_line_1,
                address.address_line_2,
                address.city, address.zip,
                address.state,
                address.country,
            ].filter(Boolean).join(', ');

            setPickupAddressText(formattedAddress);
        } else {
            setPickupAddressText('')
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const saveBuyDetails = async () => {
          
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('trackingNo', editableData.trackingNo);
        formdata.append('orderStatus', editableData.orderStatus);
        formdata.append('driver', editableData.driver);
        formdata.append('bol', editableData.bol);
        formdata.append('paymentStatus', editableData.paymentStatus);
        formdata.append('actualDeliveryDate', editableData.actualDeliveryDate);

        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/order/edit/${orderId}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}admin/order/edit/${orderId}`, requestOptions)
            .then(response => {
                setOpen(false)
                dispatch(fetchShippingOrderList())
                dispatch(fetchPickupOrderList())
                dispatch(fetchIslanderOrderList())
                dispatch(fetchDeliveredOrderList())
                dispatch(fetchCanceledOrderList())
                dispatch(fetchAllOrderList())
            })
            .then((updatedData) => {
                updateOrderDetail(updatedData);
            })
            .catch(error => console.log('error', error));
    }


    const saveSendDetails = async () => {
          
        let accessToken = cookies.get('accessToken', { path: '/' });
        const formdata = new FormData();
        formdata.append('trackingNo', editableData.trackingNo);
        formdata.append('orderStatus', editableData.orderStatus);
        formdata.append('driver', editableData.driver);
        formdata.append('bol', editableData.bol);
        formdata.append('paymentStatus', editableData.paymentStatus);
        formdata.append('actualDeliveryDate', editableData.actualDeliveryDate);

        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        var requestOptions = {
            method: 'PUT',
            headers: headers,
            body: formdata,
            redirect: 'follow'
        };

       
        // fetch(`https://phpstack-1065403-3744116.cloudwaysapps.com/api/admin/order/edit/${orderId}`, requestOptions)
        fetch(`${process.env.REACT_APP_DatabaseServer}admin/order/edit/${orderId}`, requestOptions)
            .then(response => {
                  
                // setMessageVisible(true);
                setOpen(false)
                // return response.text();
                dispatch(fetchShippingOrderList())
                dispatch(fetchPickupOrderList())
                dispatch(fetchIslanderOrderList())
                dispatch(fetchDeliveredOrderList())
                dispatch(fetchCanceledOrderList())
                dispatch(fetchAllOrderList())
            })
            .then(
            // result => 
            // // fetchData()
        )
            .catch(error => console.log('error', error));
    }

    const handleCloseTackingBox = () => {
        setOpenTrackingBox(false);
    };

    const addTrackingData = async () => {
        setOpenTrackingBox(true)
    }

    const handleTrackingData = async (id) => {
          
        if (!trackingData.location || !trackingData.statusUpdateDate || !trackingData.description) {
            alert('Please fill in all required fields.');
            return 
        }
          
        try {
            let response = await Request.post(`admin/tracking/${id}`, trackingData, true);
            if (response.message === "Success") {
                  
                setOpenTrackingBox(false)
                // window.location.reload();
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    const handleClose = () => {
		setOpenDetail(false);
	 }

    const handleOpen = async(orderId) => {
        setOpenDetail(true);
        try {
           let response = await Request.get(`order/order_detail/${orderId}`, [], true);
           
           if (response) {
                setOrderInvoiceDetail(response)
                setOrderDetailId(orderId)
                setInvoiceNo(response.invoiceNumber)

                if(response.orderType === "Islander")
                {
                   setProductDetail(response?.products?.iCollectUserProducts)
                   setBoxDetail(response?.products?.shipmentBox)
                   setPriorityProductDetail(response?.products?.PriorityProducts)
                   // setInvoiceNo(response.invoiceNo)
                }
                else{
                   setProductDetail(response?.products)
                   setPriorityProductDetail([])
                   setBoxDetail([])   
                } 
           }
           else {
                console.log("error");
           }
     }
     catch (error) {
           console.error('Error fetching data from API:', error);
     }
    }

    const handlePrint = () => {
          
        if (dialogRef.current) {
            const input = dialogRef.current;
      

            // const handleCaptureAndConvert = () => {
            //     if (divRef.current) {
            //       const divToCapture = divRef.current;
            
                  // Capture a screenshot of the div
                  html2canvas(input, { useCORS: true }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
            
                    // Create a new PDF document
                    const pdf = new jsPDF({
                        orientation: 'portrait',  // or 'landscape'
                        unit: 'mm',
                        format: 'a4',  // page size: A4
                        putOnlyUsedFonts: true
                    });
                    const width = 210;  // A4 width in mm5rt
                    const height = (canvas.height * width) / canvas.width;
                    
                    canvas.getContext('2d').imageSmoothingEnabled = false;
                    // Add the canvas image to the PDF
                    pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                    pdf.setFontSize(14); // Set the font size
                    pdf.setFont('Helvetica'); // Use a web-safe font
                    // Save the PDF with a filename
                    pdf.save('screenshot.pdf');
                  });
          };
      };

    return (
        <>
            <Box className="admin-modal__container">
                <div className="admin-modal__title-container">
                    <Typography variant="h6" className="admin-modal__title">
                        {orderDetail?.invoiceNo}
                    </Typography>

                    {orderDetail?.orderType === 'Purchase' && (
                        <Box className="flexBox">
                            <Button color="primary" className="btn" onClick={() => handleOpen(orderId)}>
                                View Invoice
                            </Button>
                            <Button color="primary" className="btn" onClick={saveBuyDetails}>
                                Save
                            </Button>
                        </Box>
                       
                    )}

                    {orderDetail?.orderType === 'Send' && (
                         <Box className="flexBox">
                            <Button color="primary" className="btn" onClick={() => handleOpen(orderId)}>
                                View Invoice
                            </Button>
                            <Button color="primary" className="btn" onClick={saveSendDetails}>
                                Save
                            </Button>
                        </Box>
                    )}
                </div>
                <div className="shipping-order-modal">
                    <div className="edit-order-form__modal shipping-order-form__modal">
                        <Form
                            className="edit-order-form shipping-order-form"
                        >
                            {orderDetail && (
                                <div className="shipping-order-form__fields">
                                    <Form.Group
                                        controlId="customerName"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="customerName"
                                            value={orderDetail?.user?.fullName || ''}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="orderId"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="orderId"
                                            value={orderDetail?.invoiceNo}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="orderDate"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="orderDate"
                                            value={formatDate(orderDetail?.createdAt)}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="orderStatus"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Order Status</Form.Label>
                                        <Form.Select
                                            name="orderStatus"
                                            value={editableData.orderStatus}
                                            onChange={(e) => {
                                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                                const selectedValue = parseInt(e.target.value, 10);
                                                setEditableData({ ...editableData, orderStatus: selectedValue });
                                            }}
                                        >
                                            <option value="" disabled>Select an option</option>
                                            {orderDetail?.orderType === 'Send' ? (
                                                <>
                                                   {orderStatusOptions.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.text}
                                                    </option>
                                                    ))}
                                                </>
                                            ):(
                                                <>
                                                {orderStatusOptions2.map(option => (
                                                 <option key={option.value} value={option.value}>
                                                     {option.text}
                                                 </option>
                                                 ))}
                                             </>
                                            )}
                                         
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group
                                        controlId="recipientAddress"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Recipient Address</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="recipientAddress"
                                            value={shippingAddressText && shippingAddressText}
                                            readOnly
                                        />
                                    </Form.Group>

                                    {orderDetail?.orderType === 'Send' && (
                                        <Form.Group
                                            controlId="senderAddress"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Sender Address</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="senderAddress"
                                                value={senderAddressText && senderAddressText}
                                                readOnly
                                            />
                                        </Form.Group>
                                    )}

                                    {orderDetail?.orderType === 'Send' && (
                                        <Form.Group
                                            controlId="pickUpAddress"
                                            className="edit-order-form__field"
                                        >
                                            <Form.Label>Pick Up Address</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="pickUpAddress"
                                                value={pickupAddressText && pickupAddressText}
                                                readOnly
                                            />
                                        </Form.Group>
                                    )}

                                    <Form.Group
                                        controlId="billingAddress"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Billing Address</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="billingAddress"
                                            value={billingAddressText && billingAddressText}
                                            readOnly
                                        />
                                    </Form.Group>

                                    {orderDetail?.orderType === 'Send' && (
                                        <>
                                            <Form.Group
                                                controlId="pickUpDate"
                                                className="edit-order-form__field"
                                            >
                                                <Form.Label>Preferred Pickup Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="pickUpDate"
                                                    value={formatDate(orderDetail?.slot.date) || ''}
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                controlId="pickUpDate"
                                                className="edit-order-form__field"
                                            >
                                                <Form.Label>Actual Pick Up Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="pickUpDate"
                                                    value={formatDate(orderDetail?.actualPickupDate) || ''}
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group
                                                controlId="pickUpSlot"
                                                className="edit-order-form__field"
                                            >
                                                <Form.Label>Preferred PickUp Slot</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="pickUpSlot"
                                                    value={pickUpSlot}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </>
                                    )}

                                    <Form.Group
                                        controlId="estimatedDeliveryDate"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Estimated Delivery Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="estimatedDeliveryDate"
                                            value={formatDate(orderDetail?.estDeliveryTimeInDay) || ''}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="actualDeliveryDate"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Actual Delivery Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="actualDeliveryDate"
                                            value={formatDate(editableData.actualDeliveryDate)}
                                            // value={editableData.trackingNo}
                                            onChange={handleInputChange}
                                            // onChange={handleChange}
                                            // readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        controlId="trackingNo"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Tracking ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="trackingNo"
                                            value={editableData.trackingNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        controlId="driver"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Driver</Form.Label>
                                        <Form.Select
                                            name="driver"
                                            value={editableData.driver}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setEditableData({ ...editableData, driver: selectedValue });
                                              }}
                                        // required
                                        >
                                            <option value="" disabled>Select an option</option>
                                            {(driverList && driverList.length>0) && driverList.map(option => (
                                                <option key={option.id} selected = {editableData.driver === option.id ? true : false}  value={option.id}>
                                                    {option.user.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId="billingOfLading" className="edit-order-form__field">
                                        <Form.Label>Billing of Lading</Form.Label>
                                        <div className="file-input-container">
                                            <input
                                                type="file"
                                                name="bol"
                                                accept=".pdf"
                                                onChange={handleFileChange}
                                            />
                                            {editableData.bol !== '' && availableBol ? (
                                                <a
                                                    href={`${process.env.REACT_APP_DatabaseStatic}${editableData.bol}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className='font-bold font-15 color4'>View BOL</span>
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group
                                        controlId="paymentMethod"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Payment Method</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="paymentMethod"
                                            value={orderDetail?.payment_detail?.provider || ''}
                                            readOnly
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        controlId="paymentStatus"
                                        className="edit-order-form__field"
                                    >
                                        <Form.Label>Payment Status</Form.Label>
                                        <Form.Select
                                            name="paymentStatus"
                                            value={editableData.paymentStatus ? 'true' : 'false'}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setEditableData({ ...editableData, paymentStatus: selectedValue === 'true'});
                                            }}
                                        // required
                                        >
                                            {/* <option value="" disabled>Select an option</option> */}
                                            {paymentStatus.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            )}

                        </Form>
                    </div>
                    <div className="your-order-total-box">
                        <Typography variant="h6" className="section-title">
                            Your Order
                        </Typography>
                        <YourOrderTable orderId={orderId} orderDetail={orderDetail} />
                    </div>
                </div>

                {orderDetail?.orderType === 'Purchase' && (
                    <div className="order-table-container pt-4">
                        <Typography className="section-title font-18">
                            Contact Details
                        </Typography>
                        <Box className={classes.contactDetailBox}>
                            <Box className="flexBoxDash mb-1">
                                <Typography className='font-16 font-600'>Name:</Typography>
                                <Typography className='font-16'>{orderDetail?.user.fullName}</Typography>
                            </Box>
                            <Box className="flexBoxDash mb-1">
                                <Typography className='font-16 font-600'>Email:</Typography>
                                <Typography className='font-16'>{orderDetail?.user.email}</Typography>
                            </Box>
                            <Box className="flexBoxDash mb-1">
                                <Typography className='font-16 font-600'>Contact No:</Typography>
                                <Typography className='font-16'>{orderDetail?.user.phone}</Typography>
                            </Box>
                        </Box>
                    </div>
                )}
                
                {(orderDetail?.track && orderDetail?.track.length !== 0) && (
                   <div className="order-table-container pt-4">
                        <Typography className="section-title font-20">
                            Shipment Tracking
                        </Typography>
                        {/* <Typography className="section-title font-16 flexBox">
                            <Typography className='font-16 font-600'>Expected Delivery:</Typography>
                            <Typography className='font-16 font-00'>{orderDetail?.estDeliveryTimeInDay}</Typography> 
                        </Typography> */}
                        {(orderDetail?.orderType === "Send" && orderDetail?.orderStatus >= 4) && (
                            <Button className='b1 b4 mb-3' onClick={addTrackingData}>Add Tracking Details</Button>
                        )}
                        <Typography className="section-title font-18">
                            Tracking Details
                        </Typography>
                        <TableContainer component={Paper} className='trackDetail-table'>
                            <Table aria-label="Tracking Details" className="sortable-table trackingTable">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Date
                                        </TableCell>
                                        <TableCell>
                                            Status Detail
                                        </TableCell>
                                        <TableCell>
                                            Location
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderDetail.track.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                {formatDate(item.statusUpdate)}
                                            </TableCell>
                                            <TableCell>
                                                {item.description}
                                            </TableCell>
                                            <TableCell>
                                                {item.location}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Box>

            <Modal
                open={openTrackingBox}
                onClose={handleCloseTackingBox}
                aria-labelledby="modal-title"
                className="admin-modal"
                maxWidth="700px"
            >
                <Box className="admin-modal__container box-modal__container_tracking">
                    <Box className="admin-modal__title-container">
                        <Typography variant="h6" className="admin-modal__title" align="center">Add Tracking Details</Typography>
                    </Box>
                    <form>
                        <Box className="edit-order-form shipping-order-form">
                            <div className=''>
                                <Form.Group
                                    controlId="date"
                                    className="edit-order-form__field mb-2"
                                >
                                    <Form.Label>Date</Form.Label>
                                    <DatePicker
                                        selected={trackingData.statusUpdateDate}
                                        onChange={handleDateChange}
                                        dateFormat="dd/MM/yyyy"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    controlId="description"
                                    className="edit-order-form__field mb-2"
                                >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        value={trackingData.description}
                                        onChange={handleDescriptionChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    controlId="location"
                                    className="edit-order-form__field"
                                >
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="location"
                                        value={trackingData.location}
                                        onChange={(e) => setTrackingData({ ...trackingData, location: e.target.value })}
                                        required
                                    />
                                </Form.Group>
                            </div>
                            <Box align="center" sx={{ pt: 4 }}>
                                <Button color="primary" className="b1 b5 colo2" type="button" onClick={() => handleTrackingData(orderId)}>
                                    Add
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>

             </Modal>

             <Modal
							open={openDetail}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
							className={classes.modalBox}
							>
							<Box sx={style}>
                                <Button className="b1 b4 printPdfBtn" onClick={handlePrint}>
                                    Print
                                </Button> 
								<Box ref={dialogRef} sx={{p: 4,'@media (max-width: 768px)': {p: 2,},'@media (max-width: 480px)': {p: 1},}}>
									<Box>
										<Box className={classes.imgBox}>
											<img src='/assets/img/web_logo.png' style={{"maxWidth" : "100%"}}></img>
										</Box>
									</Box>
									<Box sx={{pt:3, pb:3}}>
										<Row>
											<Col item xs={12} sm={12} md={8} lg={8} xl={8} className=''>
												<Box>
													<Typography className='font-bold font-20'>ISLE OF FREIGHT INC.</Typography>
													<Typography className='font-16 font-600'><i>We help families help families</i></Typography>
												</Box>
											</Col>
											<Col item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.flexEnd}>
												<Typography className='font-bold font-25'>INVOICE</Typography>
											</Col>
										</Row>
										<Box sx={{pt:3, pb:3}}>
											<Row className='invoiceBox'>
												<Col item xs={12} sm={12} md={12} lg={6} xl={6} className=''>
													<Box>
														<Typography>246 Stewart Green SW</Typography>
														<Typography>Unit #6188</Typography>
														<Typography>Calgary,AB T3H 3C8</Typography>
														<Typography>Phone: (825)-901-3955</Typography>
														<Typography>Email: support@isleoffreight.com</Typography>
													</Box>
												</Col> 
												<Col item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.flexEnd}>
													<Box>
														<Typography className=''>INVOICE  <b>{invoiceNo}</b></Typography>
														<Typography className=''>Date: {formatDate(orderInvoiceDetail.createdAt)}</Typography>
													</Box>
												</Col>
											</Row>
										</Box>
										<Box sx={{pb:3}}>
											<Row className='invoiceBox'>
													<Col item xs={12} sm={12} md={12} lg={6} xl={6} sx={{mb: 2}} className=''>
														{orderInvoiceDetail.billingAddress &&
															<Box>
																<Typography className='font-bold'>BILL TO:</Typography>
																<Typography>{orderInvoiceDetail.billingAddress.fullName}</Typography>
																<Typography>{orderInvoiceDetail.billingAddress.address_line_1}, {orderInvoiceDetail.billingAddress.address_line_2} ,{orderInvoiceDetail.billingAddress.city}-{orderInvoiceDetail.billingAddress.zip}</Typography>
																<Typography>{orderInvoiceDetail.billingAddress.state}, {orderInvoiceDetail.billingAddress.country}</Typography>
															</Box>
														}
													</Col>
													<Col item xs={12} sm={12} md={12} lg={6} xl={6} sx={{mb: 2}} className=''>
														{orderInvoiceDetail.shippingAddress &&
															<Box>
																<Typography className='font-bold'>SEND TO:</Typography>
																<Typography>{orderInvoiceDetail.shippingAddress.fullName}</Typography>
																<Typography>{orderInvoiceDetail.shippingAddress.address_line_1}, {orderInvoiceDetail.shippingAddress.address_line_2} ,{orderInvoiceDetail.shippingAddress.city}-{orderInvoiceDetail.shippingAddress.zip}</Typography>
																<Typography>{orderInvoiceDetail.shippingAddress.state}, {orderInvoiceDetail.shippingAddress.country}</Typography>
															</Box>
														}
													</Col>
											</Row>
										</Box>
										<Box sx={{ pb:3}} className='invoiceBox'>
											<Typography className='font-bold'>COMMENTS OR SPECIAL INSTRUCTIONS:</Typography>
											{orderInvoiceDetail.orderType === "Purchase" && 
												<Typography className=''>Packaging Items</Typography>
											}
											{orderInvoiceDetail.orderType === "Send" && 
												<Typography className=''>Barrel Shipment</Typography>
											}
											{orderInvoiceDetail.orderType === "Islander" && 
												<Typography className=''>Islander Box Shipment</Typography>
											}
										</Box>
										<Box sx={{pb:3}}>
										<table striped bordered className='historyTable'>
											<thead>
												<tr>
													<th>VENDOR ID</th>
													<th>QUANTITY</th>
													<th width="320px">DESCRIPTION</th>
													<th>ITEM VALUE</th>
													<th width="150px">TOTAL</th>
												</tr>
											</thead>
											<tbody>
											{productDetail && productDetail.length > 0 ? (
												<>
												{productDetail.map((item =>
													<tr>
														<td>
															{orderInvoiceDetail.orderType === "Islander" ?  item.url : "IOF"}
														</td>
														<td>
                                                            {orderInvoiceDetail.orderType === "Islander" ?  item.receivedQuantity : item.quantity }
                                                        </td>    
														<td>{orderInvoiceDetail.orderType === "Send" &&
                                                        (
                                                            <>
                                                                Barrel ({item.product?.size}GL) - {item.product?.description} 
                                                            </>  
                                                        )}
                                                        {orderInvoiceDetail.orderType === "Purchase" &&
                                                        (
                                                            <>
                                                                {item.product.name} ({item.product.size}GL)
                                                            </>  
                                                        )}
                                                        {orderInvoiceDetail.orderType === "Islander" &&
                                                        (
                                                            <>
                                                                {item.description}
                                                            </>  
                                                        )}
                                                        </td>
														<td>
                                                            {orderInvoiceDetail.orderType === "Islander" ?  item.itemValue : item.product?.itemValue }
                                                        </td> 
                                                        <td align='right'>
                                                            {orderInvoiceDetail.orderType === "Islander" ?  '$0.00' : `$${FormatPrice(item.itemPrice)}` }
                                                        </td> 
														{/* <td>${item.itemPrice}</td> */}
													</tr>
												))}
												</>
                                                
											):<></>
                                            }
                                            {/* {boxDetail && boxDetail.length !== 0 ? (
                                                <>
                                                {boxDetail.map((item =>
													<tr>
                                                        <td>IOF</td>
                                                        <td>{item.quantity}</td>
                                                        <td>Box({item.size})</td>
                                                        <td></td>
                                                        <td align='right'>${item.price}</td>
                                                    </tr>
                                                ))}
                                                </>
                                            ):<></>
                                            } */}
                                            {priorityProductDetail && priorityProductDetail.length !== 0 ? (
                                                <>
                                                {priorityProductDetail.map((item =>
													<tr>
                                                        <td>IOF</td>
                                                        <td>{item.quantityPacked}</td>
                                                        <td>{item.islander_product?.name}</td>
                                                        <td>{item.priorityProductValue}</td>
                                                        <td align='right'>${FormatPrice(item.price * item.quantityCharged)}</td>
                                                    </tr>
                                                ))}
                                                </>
                                            ):<></>
                                            }
											<tr>
												<td></td><td></td><td></td><td></td><td></td>
											</tr>
											<tr>
												<td></td><td></td><td></td><td></td><td></td>
											</tr>
											<tr>
												<td></td><td></td><td></td><td></td><td></td>
											</tr>
											<tr>
												<td colSpan={3}></td>
												<td align='right'>SUBTOTAL</td>
												<td align='right'>
                                                {orderInvoiceDetail.orderType === "Send" ? (
                                                        <>
                                                            ${FormatPrice(orderInvoiceDetail.shippingCharge)}
                                                        </>
                                                    ):(
                                                        <>
                                                            ${FormatPrice(orderInvoiceDetail.productPrice)}
                                                        </>
                                                    )}
                                                    </td>
											</tr>
											<tr>
												<td colSpan={3}></td>
												<td align='right'>TAX({orderInvoiceDetail.taxPercentage}%)</td>
												<td align='right'>${FormatPrice(orderInvoiceDetail.tax)}</td>
											</tr>
                                            {orderInvoiceDetail.insuranceValue && (
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td align='right'>Insurance</td>
                                                    <td align='right'>${FormatPrice(orderInvoiceDetail.insuranceValue)}</td>
											    </tr>
                                            )}
                                            
											<tr>
												<td colSpan={3}></td>
												<td align='right'>SHIPPING & HANDLING</td>
												<td align='right'>
                                                    {orderInvoiceDetail.orderType === "Send" ? (
                                                        <>
                                                            $0.00
                                                        </>
                                                    ):(
                                                        <>
                                                            ${orderInvoiceDetail.shippingCharge > 0 ? FormatPrice(orderInvoiceDetail.shippingCharge) : 0}
                                                        </>
                                                    )}
                                                  
                                                </td>
											</tr>
                                            {orderInvoiceDetail.orderType === "Islander" && (
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td align='right'>Packaging & Material cost</td>
                                                    <td align='right'>${FormatPrice(orderInvoiceDetail.products.totalBoxAccessoriesValue)}</td>
											    </tr>
                                            )}
											<tr>
												<td colSpan={3}></td>
												<td align='right' className='font-bold'>TOTAL</td>
												<td align='right' className='font-bold'>${FormatPrice(orderInvoiceDetail.totalPrice)}</td>
											</tr>
											</tbody>
										</table>
										</Box>
									</Box>
								</Box>
							</Box>
							</Modal>
        </>
    );
}

export default OrderDetailEdit;
