import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    userAddress: {},
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchUserAddress = createAsyncThunk(
    "customer/userAddress",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("user/addresses", [], true);
            if (Object.keys(response)) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  

  const userAddressSlice = createSlice({
    name: "userAddress",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchUserAddress.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchUserAddress.fulfilled, (state, action) => {
            state.loading = false;
            state.userAddress = action.payload;
          })
          .addCase(fetchUserAddress.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
      },
  });
  
  export default userAddressSlice.reducer;