import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Link, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import CMSFooter from '../../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';

const PackageItems = () => {
    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Packaging Items</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5 }}>

                            <Row>

                                 <Col xs={12} sm={12} md={6} lg={6.5} xl={6.5} >
                                    <Box textAlign="left" className="service_inner" sx={{ pt: 1 }}>
                                        <Typography textAlign="left" className='color2 font-19'>
                                            Isle of Freight packaging items are designed to provide optimal protection and convenience for
                                            your goods during shipping and transportation. Made from high-quality materials, 
                                            our packaging items are durable and reliable, ensuring that your products arrive at their 
                                            destination in pristine condition. 
                                        </Typography>
                                        <Typography textAlign="left" sx={{mt:3}} className='color2 font-19'>
                                            Our range of packaging items includes islander boxes, barrels, cable seals, heavy-duty 
                                            shipping tapes, bubble wrap rolls and other packaging solutions that are suitable for a wide 
                                            variety of goods. Whether you need to package small items or large, bulky products, we have 
                                            the perfect solution for you. 
                                        </Typography>
                                    </Box>
                                </Col>
                                 <Col xs={12} sm={12} md={6} lg={5.5} xl={5.5} className='barrel-img-box'>
                                    <Box className="w-80" sx={{ mt: -2 }}>
                                        <img src="../assets/img/islander_img2.png" alt="list_img" className='' />
                                    </Box>
                                </Col>
                            </Row>

                              <Box sx={{pb:6, mt:0}}>
                                    <Typography textAlign="left" className='color2 font-19'><a href='/user/buy/buy-product'><span className='Click-me'>Click here</span></a> to shop our packaging items.</Typography>
                                    <Typography textAlign="left" sx={{ mt: 3 }} className='color2 font-19'>
                                    Our packaging items are easy to use and are customized to meet your specific requirements. 
                                    In addition to our standard packaging items, we also offer custom packaging solutions with 
                                    our islander boxes that are tailored to your specific needs. Our team of experts work with 
                                    and for you to design and create custom packaging that meets your exact specifications. 
                                    </Typography>
                                    <Typography className='font-19 color2' align='left'>
                                        At Isle of Freight, we are committed to providing our customers with the highest 
                                        quality packaging items and exceptional customer service. We strive to exceed your 
                                        expectations and ensure that your products are protected and presented in the best 
                                        possible way.
                                    </Typography>
                            </Box>
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default PackageItems;
