import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import Request from '../customUtils/Http/Request';
import ShippingPriceTable from "./shippingPriceTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeliveryTimeList, fetchPurchaseDeliveryTimeList } from "../redux/deliveryTimeSlice";
import { fetchDeliveryAdminList } from "../redux/pickupLocationSlice";
import DeliveryTimeListTable from "./deliveryTimeListTable";
import BarrelShippingEditableGrid from "./BarrelShippingEditableGrid";

function DeliveryTimeList() {
	const [openDialog, setOpenDialog] = useState(false);
	const dispatch = useDispatch();
	const [toCountryList, setToCountryList] = useState([]);
	const [clickedBtn, setClickedBtn] = useState("");
	const [type, setType] = useState("");
	const [fromCountryList, setFromCountryList] = useState([]);
	const [formData, setFormData] = useState({
        from: "",
		to: "",
		estDeliveryTime: 0,
	});
	const [openDialogDeliveryTime, setOpenDialogDeliveryTime] = useState(false);
	const [rows, setRows] = React.useState([]);

	const estimatedTimeList = useSelector((state) => state.deliveryTime.deliveryTimeList);
	const purchaseDeliveryList = useSelector((state) => state.deliveryTime.purchaseDeliveryTimeList);
	const deliveryAdminList = useSelector((state) => state.pickupList.deliveryListAdminLocation);

	useEffect(() => {
		dispatch(fetchDeliveryTimeList());
		dispatch(fetchPurchaseDeliveryTimeList());
		dispatch(fetchDeliveryAdminList());
	}, []);

	useEffect(() => {
		dispatch(fetchDeliveryTimeList());
		dispatch(fetchPurchaseDeliveryTimeList());
	}, [dispatch]);

	const handleOpenDialog = (type) => {
		// setClickedBtn()
		if(type === "purchase"){
			setType("purchase")
			setOpenDialog(true);
		}
		else{
			setType("send")
			setOpenDialogDeliveryTime(true);
		}
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseDialogDeliveryTime = () => {
		setOpenDialogDeliveryTime(false);
	};

	const handleFieldChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const saveDelivryTimeDataGrid = async (data) => {
		try {
			const response = await Request.post('admin/rate/country-bulk', rows, true);
			if (response.message === "Success") {
				setOpenDialogDeliveryTime(false);
				dispatch(fetchDeliveryTimeList())
				setRows([])
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(formData.from !== '' && formData.to !== '' && formData.estDeliveryTime !== 0)
		{
			if(type === "purchase"){
				const data = {
					from: formData.from,
					to: formData.to,
					estDeliveryTime: formData.estDeliveryTime,
					status: true
				}
				try {
					// Fetch data from API
					const response = await Request.post('admin/rate/purchaseEstimates', data, true);
					if (response.message === "Success") {
						handleCloseDialog();
						dispatch(fetchPurchaseDeliveryTimeList())
						setFormData({
							from: "",
							to: "",
							estDeliveryTime: "",
						  });
					} else {
						console.error('Failed to fetch data from API');
					}
				} catch (error) {
					console.error('Error fetching data from API:', error);
				}
			}else{
				try {
					// Fetch data from API
					const response = await Request.post('admin/rate/country', formData, true);
					if (response.message === "Success") {
						handleCloseDialog();
						dispatch(fetchDeliveryTimeList())
						setFormData({
							from: "",
							to: "",
							estDeliveryTime: "",
						});
					} else {
						console.error('Failed to fetch data from API');
					}
				} catch (error) {
					console.error('Error fetching data from API:', error);
				}	
			}

		}else{
			alert("Please fill all the fields");
		}
		
	};

	useEffect(() => {
		fetchToList();
		fetchPurchaseToList();
	}, []);

	const fetchFromList = async () => {
        try {
            let response = await Request.get('admin/orders/', [], true);
            if (response.length !== 0) {
				const countries = response.map(item => item.country);
				setFromCountryList(countries)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const fetchToList = async () => {
        try {
            let response = await Request.get('utils/getIslandCountries', [], true);
            if (response.length !== 0) {
				const countries = response.map(item => item.country);
				setToCountryList(countries)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const fetchPurchaseToList = async () => {
        try {
            let response = await Request.get('utils/getIslandCountries', [], true);
            if (response.length !== 0) {
				const countries = response.map(item => item.country);
				setToCountryList(countries)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
	};

	return (
		<Box className="adminInnerPage shippingPricePage">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
                    Estimated Delivery Time
				</Typography>

				{/* <Button
					variant="primary"	
					type="button"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Delivery Time
				</Button> */}
			</div>

			<div className="shippingPricePage__table-container">
			<div className="title-button__container">
				<Typography variant="h6" className="section-title">
					Estimated Delivery Time For Islands
				</Typography>
				<Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={() => handleOpenDialog("send")}
					// onClick={handleOpenDialog}
				>
					Add Delivery Time
				</Button>
			</div>

				<DeliveryTimeListTable list={estimatedTimeList} toCountryList={toCountryList} type="send"/>
			</div>

			<div className="shippingPricePage__table-container">
			<div className="title-button__container">
				<Typography variant="h6" className="section-title">
					Estimated Delivery Time For Packaging Items
				</Typography>
				<Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={() => handleOpenDialog("purchase")}
					// onClick={handleOpenDialog}
				>
					Add Delivery Time
				</Button>
			</div>
				<DeliveryTimeListTable list={purchaseDeliveryList} toCountryList={deliveryAdminList} type="purchase"/>
			</div>

			<Dialog
				open={openDialogDeliveryTime}
				onClose={handleCloseDialogDeliveryTime}
				maxWidth="lg"
			>
					<BarrelShippingEditableGrid toCountryList={toCountryList} setRows={setRows} rows={rows} type="delivery" />
					<DialogActions>
					<Button  autoFocus onClick={saveDelivryTimeDataGrid}>
						Save Data
					</Button>
					</DialogActions>
			</Dialog>
			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit= {handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
					Add Delivery Time
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
			
								<Form.Group
									controlId="from"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>From</Form.Label>
									<Form.Select
										name="from"
										value={formData.from}
										onChange={(e) =>
											handleFieldChange(
												"from",
												e.target.value
											)
										}
										required
									>
										<option value="">Select an Option</option>
										<option value="Calgary">Calgary</option>
									</Form.Select>
								</Form.Group>

								{type === "purchase" ? (
									<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
											required
										>
											<option value="">Select an option</option>
											{(deliveryAdminList && deliveryAdminList.length>0) && deliveryAdminList.map(option => (
												<option key={option} value={option.city}>
													{option.city}
												</option>
											))}
										</Form.Select>
									</Form.Group>
								):(
									<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
											required
										>
											<option value="">Select an option</option>
											{(toCountryList && toCountryList.length>0) && toCountryList.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</Form.Select>
								</Form.Group>
								)}
								

								<Form.Group
									controlId="estDeliveryTime"
									className="shipping-price-form__field"
								>	
									<Form.Label>Enter Delivery Time (Days)</Form.Label>
									<Form.Control
										type="number"
										value={formData.estDeliveryTime}
										placeholder="Enter Estimated Delivery Days"
										onChange={(e) =>
											handleFieldChange(
												"estDeliveryTime",
												e.target.value
											)
										}
										required
									/>
								</Form.Group>
							</div>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Add
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			
		</Box>
	);
}

export default DeliveryTimeList;
