import { useEffect, useState, Fragment } from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { toggleSidebar } from '../commonFunctions/actions';
import { useDispatch, useSelector } from 'react-redux';
import {sidebar} from '../redux/cartSlice'
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import {
  Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Dashboard, Inbox, Mail, Settings } from '@mui/icons-material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { spacing } from '@mui/system';
import Request from '../customUtils/Http/Request';
import CloseIcon from '@mui/icons-material/Close';
import DashboardHeader from './dashboardHeader';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';

const cookies = new Cookies();


const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "fit-content",
    border: "none !important",
    // minWidth: "234px",
    maxWidth: "234px"
    // position: "unset !important",
    // height: "100vh !important"
  },
  textField: {
    '& span':{
    minWidth: "160px",
  }
}
}));

function DriverSidebar() {
  const classes = useStyles();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userType, setUserType] = useState('');
  const dispatch = useDispatch();
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }


  const handleSubMenuClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  }

  useEffect(() => {
    let userType = cookies.get('userType', { path: '/' });
    const userTypeAsNumber = parseInt(userType, 10);
    setUserType(userTypeAsNumber)
  }, []);

  const menuItems = [
    { icon: <LocalShippingOutlinedIcon />, text: 'My Profile', url: '/driver/profile'},
    { icon: <GroupsOutlinedIcon />, text: 'Orders', url: '/driver/orders'},
  ];

  const shouldHideSidebar = () => {
    // Check if the sidebar is minimized and the screen width is less than a threshold (e.g., 992 pixels)
    return minimized && window.innerWidth < 992;
  };
  
  return (
    shouldHideSidebar() ? null : (
      <Drawer
        // className={`drawer ${minimized ? 'minimized' : ''}`}
        className='drawer'
        id="drawer"
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >

        <div className="toolbar" />
        {/* <CloseIcon className="closeSidebarIcon" onClick={openSidebar} /> */}
        
        {minimized ? (
          <Box sx={{ height: "45px", margin: "0 auto", mt: 3, mb: 2 }}>
              <Link to="/driver/orders">
                <img src="/assets/img/iof_mobile.svg" className='webHeader_logo'></img>
              </Link>   
          </Box>
        ) : (
          <Box sx={{ width: "110px", height: "45px", margin: "0 auto", mt: 3, mb: 2 }}>
            <Link to="/driver/orders">
              <img src="/assets/img/web_logo.png" className='webHeader_logo'></img>
            </Link>
          </Box>
        )}


        
        <List className="menuBox adminMenuBox driverMenuBox">
          {menuItems.map((item, index) => (
            <Fragment key={index}>
               <div className='linkBox'>
               <Link to={item.url} className={`${location.pathname === item.url ? 'menu_active' : ''}`}>
                <ListItem
                  button
                  // onClick={() => handleSubMenuClick(item.url)}
                  className="menulist"
                // selected={selectedIndex === index}
                >

                      {minimized ? (
                            <ListItemIcon className="iconBox">
                            {item.icon}
                          </ListItemIcon>
                        ): (
                          <> <ListItemIcon className="iconBox">
                          {item.icon}
                          </ListItemIcon>
                              <ListItemText primary={item.text} className={classes.textField}/>
                          </>
                        )}
                
                </ListItem>
              </Link>
              </div>
              {/* {Array.isArray(item.subMenuItems) && (
                <List className={minimized ? 'nestedMenuBox' : 'nestedMenuBoxExpand'} component="div" disablePadding >
                </List>

              )} */}
            </Fragment>
          ))}
        </List>
      </Drawer>
    
 )
  );
}

// const mapDispatchToProps = {
//   toggleSidebar,
// };

// const mapStateToProps = (state) => {
//   return {
//     minimized: state.sidebar.minimized,
//   };
// };

export default DriverSidebar;