import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ pdfData }) {
  const [numPages, setNumPages] = useState(null);
  const a4Width = 1000; // Approximately 8.27 inches
//   const a4Height = 841.890; // Approximately 11.69 inches

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="pdf-container">
      <Document
        file={`${pdfData}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index+ 1}
            width={a4Width}
            // height={a4Height}
          />
        ))}
      </Document>
    </div>
  );
}

export default PDFViewer;