import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { fetchPickupAdminList } from "../redux/pickupLocationSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Request from '../customUtils/Http/Request';

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { randomId, randomArrayItem } from "@mui/x-data-grid-generator";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, size: "", from: "", to: "", price: 0, estDeliveryTime: 0 },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Shipping Price
      </Button>
    </GridToolbarContainer>
  );
}

export default function BarrelShippingEditableGrid(props) {
  const pickupLocationList = useSelector(
    (state) => state.pickupList.pickupListAdminLocation
  );
  const dispatch = useDispatch();
  const setRows = props.setRows;
  const [islanderProvince, setIslanderProvince] = useState("");

  useEffect(() => {
    if (props.clickedBtn === "islander") {
      fetchAddress();
    }
  }, []);

  const fetchAddress = async () => {
    try {
      // Fetch data from API
      const response = await Request.get("admin/getWarehouseAddress", [], true);
      if (response !== undefined) {
        setIslanderProvince(response.state);
        console.log(response)
      } else {
        console.error("Failed to islander province data from API");
      }
    } catch (error) {
      console.error("Error fetching islander province from API:", error);
    }
  };

  const filterProvince = (pickupLocations) => {
    var provincesList = [];
    pickupLocations.forEach((element) => {
      if (!provincesList.includes(element)) {
        provincesList.push(element);
      }
    });

    return provincesList;
  };
  useEffect(() => {
    dispatch(fetchPickupAdminList());
  }, []);

  useEffect(() => {
    dispatch(fetchPickupAdminList());
  }, [dispatch]);

  // const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    props.setRows(props.rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = props.rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      props.setRows(props.rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    props.setRows(
      props.rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const actionObj = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  };

  const columns = [
    { field: "size", headerName: "Size", width: 200, editable: true },
    {
      field: "from",
      headerName: "From",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions:
        props.clickedBtn === "islander"
          ? [islanderProvince]
          : filterProvince(
              pickupLocationList.map((value) => {
                return value.province;
              })
            ),
    },
    {
      field: "to",
      headerName: "To Country",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: props.toCountryList,
    },
    { field: "price", headerName: "Shiping Price", width: 150, editable: true },
    actionObj,
  ];

  const columnsDelivery = [
    {
      field: "from",
      headerName: "From",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: filterProvince(
        pickupLocationList.map((value) => {
          return value.province;
        })
      ),
    },
    {
      field: "to",
      headerName: "To Country",
      width: 220,
      editable: true,
      type: "singleSelect",
      valueOptions: props.toCountryList,
    },
    {
      field: "estDeliveryTime",
      headerName: "Estimated Delivery Time (Days)",
      width: 240,
      editable: true,
    },
    actionObj,
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={props.type === "delivery" ? columnsDelivery : columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}
