import React from 'react';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';

function ProductDescription({description}) {
  return (
    <Box  sx={{mt: 5,'@media (max-width: 600px)': {mt: 1,},}}>
        {/* <Typography className='font-24 color1 font-bold'>Product Description</Typography> */}
        <Typography className='font-16' sx={{ color: "#727272", pt: 1 }}>{description}</Typography>
    </Box>
  );
}

export default ProductDescription;
