import { useEffect, useState, useRef } from 'react';
import { Col, Row, Form, Dropdown } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Table, Grid, TableContainer, TableCell, TableRow, TableBody, } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
// import OrderList from './orderList';
import { Link } from 'react-router-dom';
import OrderList from '../checkout/orderList';
import Paper from '@mui/material/Paper';
import { FormatPrice } from '../commonFunctions/commonPriceFunction';
import { CheckNumeric } from '../commonFunctions/checkNumeric';

const IslanderOrderInfo = ({boxDetail, shippingPrice, productTotalPrice, insuranceValue, totalBoxAccessoriesValue}) => {
    
    function checkNumericValue(value) {
        if (isNumeric(value)) {
            return true
          } else {
            return false // Return the original value if it's not numeric
          }
    }

    function isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className=''>
        <Box className='checkoutCartInfoBox cartTotalTable'>
            <Box sx={{ p: 2 }} className="totalHeader" align="center">
                <Typography className='font-20 color2 font-bold'>Your Order</Typography>
            </Box>
            <Box sx={{ p: 3, pt: 0, pb: 4 }}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table aria-label="Total table" sx={{ mt: 2 }}>
                        <TableBody>
                        {boxDetail.length !== 0 && (
                             <>
                                <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">
                                    <Typography className='font-15 font-bold color2'>Product</Typography>
                                </TableCell>
                                <TableCell align="right">
                                 
                                </TableCell>
                                </TableRow>
                                    {(boxDetail && boxDetail.length>0) && boxDetail.map((cart, index) =>
                                        <TableRow key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell scope="row">
                                                <Typography className='font-15 font-bold color1'>{checkNumericValue(cart.size) ? "Barrel" : "Box"}</Typography>
                                                <Box sx={{ display: "flex", gap: "40px" }}>
                                                    <Typography className='font-15 font-bold color1'>Size: {CheckNumeric(cart.size)}</Typography>
                                                    <Typography className='font-15 font-bold color1'>Qty: {cart.quantity}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right">
                                                {/* <Typography className='font-15 font-bold color1'>${cart.price}</Typography> */}
                                            </TableCell>
                                        </TableRow>
                                    )}  
                                </>
                            )}

                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell scope="row">
                                    <Typography className='font-15 font-bold color2'>Shipping and Handling Charge</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography className='font-15 font-bold color2'>${FormatPrice(shippingPrice)}</Typography>
                                </TableCell>
                            </TableRow>     
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell scope="row">
                                    <Typography className='font-15 font-bold color2'>Price - Additional items added</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography className='font-15 font-bold color2'>${FormatPrice(productTotalPrice)}</Typography>
                                </TableCell>
                            </TableRow>                         
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell scope="row">
                                    <Typography className='font-15 font-bold color2'>Packaging & Material cost</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography className='font-15 font-bold color2'>${FormatPrice(totalBoxAccessoriesValue)}</Typography>
                                </TableCell>
                            </TableRow>                         
                           
                            {(insuranceValue && insuranceValue !== 0) ? (
                                    <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell scope="row">
                                        <Typography className='font-15 font-bold color2'>Insurance</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography className='font-15 font-bold color2'>${FormatPrice(insuranceValue)}</Typography>
                                    </TableCell>
                                </TableRow>
                            ):( <></>)}

                            {(insuranceValue && insuranceValue !== 0) ? (
                                    <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">
                                        <Typography className='font-20 font-bold color2'>Total</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography className='font-20 font-bold color2'>${FormatPrice(productTotalPrice + shippingPrice + totalBoxAccessoriesValue + insuranceValue)}</Typography>
                                    </TableCell>
                                </TableRow>
                            ):(<TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row">
                                    <Typography className='font-20 font-bold color2'>Total</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography className='font-20 font-bold color2'>${FormatPrice(productTotalPrice + shippingPrice + totalBoxAccessoriesValue)}</Typography>
                                </TableCell>
                            </TableRow>)}

                            

                           
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    </Grid>
    );
}

export default IslanderOrderInfo;
