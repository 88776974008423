import { protect } from "../../commonFunctions/isValid";

const func = (function () {
  return {
    get: async function (path, params, headers) {
      if (params === undefined) params = [];
      if (headers === undefined) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }
      if(headers === true){
        let accessToken = await protect()
        if(!accessToken){
          console.log(`%cyou are unauthorized user ${accessToken}`,"color:blue")
          //after some time we will redirect to direct login page
          window.location.href = "/login"
          return {}
        }
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        };
      }
      try {
        if (typeof path !== "string") {
          throw Object.assign(
            new Error(
              "api address { first parameter } must be in string formate."
            ),
            { code: 402 }
          );
        } else if (!Array.isArray(params)) {
          throw Object.assign(
            new Error("{ second parameter } must be type of array."),
            { code: 402 }
          );
        } else if (typeof headers !== "object") {
          throw Object.assign(
            new Error("{ third parameter } must be type of object."),
            { code: 402 }
          );
        } else {
        }
      } catch (err) {
        console.log(err);
        // return undefined;
      }

      var queryParams = "";
      params.forEach((item, index) => {
        queryParams += `${Object.keys(item)[0].toString()}=${
          item[Object.keys(item)[0].toString()]
        }&&`;
      });
      queryParams = queryParams.substring(0, queryParams.length - 2);
      return await fetch(
        `${process.env.REACT_APP_DatabaseServer}${path}?${queryParams}`,
        {
          method: "GET",
          headers: headers,
        }
      )
        .then((response) => response.json())
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => console.log(error));
    },
    post: async function (path, params, headers, abortController) {
      if (params === undefined) params = {};
      if (headers === undefined) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }
      if(headers === true){
        let accessToken = await protect()

        if(!accessToken){
          console.log(`%cyou are unauthorized user ${accessToken}`,"color:blue")
          //after some time we will redirect to direct login page
          window.location.href = "/login"
          return {}
        }
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        };
      }
      try {
        if (typeof path !== "string") {
          throw Object.assign(
            new Error(
              "api address { first parameter } must be in string formate."
            ),
            { code: 402 }
          );
        } else if (typeof params !== "object") {
          throw Object.assign(
            new Error("{ second parameter } must be type of object."),
            { code: 402 }
          );
        } else if (typeof headers !== "object") {
          throw Object.assign(
            new Error("{ third parameter } must be type of object."),
            { code: 402 }
          );
        } else {
        }
      } catch (err) {
        console.log(`%c Http.post -${err}`, "color:blue;");
        return undefined;
      }
      let Data = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(params),
      };
      if (abortController !== undefined) {
        Data = {
          signal: abortController.signal,
          method: "POST",
          headers: headers,
          body: JSON.stringify(params),
        };
      }
      if (params === undefined) params = {};
      return await fetch(process.env.REACT_APP_DatabaseServer + path,
        Data
      )
        .then((response) => {
          if(response.status === 429){
            // alert(response.data.message);
            alert("Request limit exceeded from this IP, please try again after an hour.");
          }
          else{
            return response.json()
          }
        })
       
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => console.log(error));
    },
    delete: async function (path, params, headers, abortController) {
      if (params === undefined) params = {};
      if (headers === undefined) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }
      if(headers === true){
        let accessToken = await protect()
        if(!accessToken){
          //after some time we will redirect to direct login page
          window.location.href = "/login"
          return {}
        }
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        };
      }
      try {
        if (typeof path !== "string") {
          throw Object.assign(
            new Error(
              "api address { first parameter } must be in string formate."
            ),
            { code: 402 }
          );
        } else if (typeof params !== "object") {
          throw Object.assign(
            new Error("{ second parameter } must be type of object."),
            { code: 402 }
          );
        } else if (typeof headers !== "object") {
          throw Object.assign(
            new Error("{ third parameter } must be type of object."),
            { code: 402 }
          );
        } else {
        }
      } catch (err) {
        console.log(`%c Http.post -${err}`, "color:blue;");
        return undefined;
      }
      let Data = {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(params),
      };
      if (abortController !== undefined) {
        Data = {
          signal: abortController.signal,
          method: "DELETE",
          headers: headers,
          body: JSON.stringify(params),
        };
      }
      if (params === undefined) params = {};
      return await fetch(process.env.REACT_APP_DatabaseServer + path,
        Data
      )
        .then((response) => response)
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => console.log(error));
    },
    put: async function (path, params, headers, abortController) {
      if (params === undefined) params = {};
      if (headers === undefined) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }
      if(headers === true){
        let accessToken = await protect()
        if(!accessToken){
          console.log(`%cyou are unauthorized user ${accessToken}`,"color:blue")
          //after some time we will redirect to direct login page
          window.location.href = "/login"
          return {}
        }
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        };
      }
      try {
        if (typeof path !== "string") {
          throw Object.assign(
            new Error(
              "api address { first parameter } must be in string formate."
            ),
            { code: 402 }
          );
        } else if (typeof params !== "object") {
          throw Object.assign(
            new Error("{ second parameter } must be type of object."),
            { code: 402 }
          );
        } else if (typeof headers !== "object") {
          throw Object.assign(
            new Error("{ third parameter } must be type of object."),
            { code: 402 }
          );
        } else {
        }
      } catch (err) {
        console.log(`%c Http.post -${err}`, "color:blue;");
        return undefined;
      }
      let Data = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(params),
      };
      if (abortController !== undefined) {
        Data = {
          signal: abortController.signal,
          method: "PUT",
          headers: headers,
          body: JSON.stringify(params),
        };
      }
      if (params === undefined) params = {};
      console.log(process.env.REACT_APP_DatabaseServer + path);
      return await fetch(process.env.REACT_APP_DatabaseServer + path,
        Data
      )
        .then((response) => response)
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => console.log(error));
    },
  };
})();

export default func;