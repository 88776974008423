import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import Request from '../customUtils/Http/Request';
import ShippingPriceTable from "./shippingPriceTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderShippingPrice, fetchSendShippingPrice, fetchPackagingPrice } from "../redux/shippingPriceSlice";
import { fetchPickupList } from "../redux/pickupLocationSlice";
import BarrelShippingEditableGrid from "./BarrelShippingEditableGrid";
import { fetchPickupAdminList } from "../redux/pickupLocationSlice";

function ShippingPrice() {
	const [openDialog, setOpenDialog] = useState(false);
	const [openDialogSend, setOpenDialogSend] = useState(false);
	const dispatch = useDispatch();
	const [toCountryList, setToCountryList] = useState([]);
	const [clickedBtn, setClickedBtn] = useState("");
	const [fromCountryList, setFromCountryList] = useState([]);
	const [error, setError] = useState('');
	const [formData, setFormData] = useState({
		// item: "",
		dimension: "",
		size: "",
		from: "calgary",
		to: "",
		price: "",
	});

	const sendShippingPriceList = useSelector((state) => state.shippingPrice.sendShippingPriceList);
	const islanderShippingPriceList = useSelector((state) => state.shippingPrice.islanderShippingPriceList);
	const packagingList = useSelector((state) => state.shippingPrice.packagingPriceList);
	const pickupCityList = useSelector((state) => state.pickupList.pickupListLocation);
	const [rows, setRows] = React.useState([]);

	useEffect(() => {
		dispatch(fetchSendShippingPrice());
		dispatch(fetchIslanderShippingPrice());
		dispatch(fetchPackagingPrice());
		dispatch(fetchPickupList());
	}, []);

	useEffect(() => {
		dispatch(fetchSendShippingPrice());
		dispatch(fetchIslanderShippingPrice());
		dispatch(fetchPackagingPrice());
		dispatch(fetchPickupList());
	}, [dispatch]);

	const handleOpenDialog = (buttonName) => {
		setClickedBtn(buttonName)
		switch(buttonName) {
			case "send":
				setOpenDialogSend(true);
			  break;
			case "islander":
				setOpenDialogSend(true);
			  break;
			default:
				setOpenDialog(true);
		}
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseDialogSend = () => {
		setOpenDialogSend(false);
	};

	const handleFieldChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const saveShipingPricesDataGrid = async (data) => {
		try {
			// Fetch data from API
			if (clickedBtn === 'send') {
				const response = await Request.post('admin/rate/send/shipping-bulk', rows, true);
				if (response.message === "Success") {
					setOpenDialogSend(false);
					dispatch(fetchSendShippingPrice());
					setRows([])
				} else {
					console.error('Failed to fetch data from API');
				}
			} else if (clickedBtn === 'islander') {
				const response = await Request.post('admin/rate/island/shipping-bulk', rows, true);
				if (response.message === "Success") {
					setOpenDialogSend(false);
					dispatch(fetchIslanderShippingPrice())
					setRows([])
				} else {
					console.error('Failed to fetch data from API');
				}
			}
			
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	const handleSubmit = async (e) => {
		  
		e.preventDefault();

		if (!formData.from || !formData.to || !formData.size || !formData.price) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.post('admin/rate/send/shipping', formData, true);
			if (response.message === "Success") {
				handleCloseDialog();
				dispatch(fetchSendShippingPrice())
				setFormData({
					size: '',
					dimension: '',
					from: 'calgary',
					to: 'guyana',
					price: '',
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	const handleSubmit1 = async (e) => {
		  
		e.preventDefault();

		if (!formData.from || !formData.to || !formData.size || !formData.price) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.post('admin/rate/island/shipping', formData, true);
			if (response.message === "Success") {
				handleCloseDialog();
				dispatch(fetchIslanderShippingPrice())
				setFormData({
					size: '',
					dimension: '',
					from: 'calgary',
					to: 'guyana',
					price: '',
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	const handleSubmit2 = async (e) => {
		  
		e.preventDefault();

		if (!formData.from || !formData.to || !formData.price) {
            setTimeout(() => {
                setError('Please fill out all fields.');
                setTimeout(() => {
                  setError('');
                }, 2000);
              }, 0); 
            return;
        }

		try {
			// Fetch data from API
			const response = await Request.post('admin/rate/purchase/shipping', formData, true);
			if (response.message === "Success") {
				handleCloseDialog();
				dispatch(fetchPackagingPrice())
				setFormData({
					size: '',
					from: 'calgary',
					to: 'guyana',
					price: '',
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	useEffect(() => {
		// fetchFromList();
		  
		fetchToList();
	}, []);

	const fetchFromList = async () => {
        try {
            let response = await Request.get('admin/orders/', [], true);
			  
            if (response.length !== 0) {
				const countries = response.map(item => item.country);
				setFromCountryList(countries)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const fetchToList = async () => {
        try {
            let response = await Request.get('utils/getIslandCountries', [], true);
            if (response.length !== 0) {
				  
				const countries = response.map(item => item.country);
				setToCountryList(countries)
            } else {
                console.error('Failed to fetch data from API');
            }
        }
        catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	// const fetchDropdownList = async () => {
    //     try {
    //         let response = await Request.get('utils/getIslandCountries', [], true);
    //         if (response.length !== 0) {
	// 			  
	// 			const countries = response.map(item => item.country);
	// 			setToCountryList(countries)
    //         } else {
    //             console.error('Failed to fetch data from API');
    //         }
    //     }
    //     catch (error) {
    //         console.error('Error fetching data from API:', error);
    //     }
    // };

	return (
		<Box className="adminInnerPage shippingPricePage">
			<div className="title-button__container">
				<Typography variant="h2" className="adminInnerPage__title">
					Shipping Price
				</Typography>

				{/* <Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={handleOpenDialog}
				>
					Add Send Barrel Shipping Price
				</Button> */}
			</div>

			<div className="shippingPricePage__table-container">
			<div className="title-button__container">
				<Typography variant="h6" className="section-title">
					Send Barrel/Box
				</Typography>
				<Button
					variant="primary"
					type="button"
					className="btn btn-secondary"
					onClick={() => handleOpenDialog("send")} 
					// onClick={handleOpenDialog}
				>
					Add Send Barrel/Box Shipping Price
				</Button>
			</div>

				<ShippingPriceTable list={sendShippingPriceList} toCountryList={toCountryList} fromList={pickupCityList} type="send"/>
			</div>

			<div className="shippingPricePage__table-container">
				<div className="title-button__container">
					<Typography variant="h6" className="section-title">
						Islander Box
					</Typography>
					<Button
						variant="primary"
						type="button"
						className="btn btn-secondary"
						onClick={() => handleOpenDialog("islander")} 
					>
						Add Islander Shipping Price
					</Button>
				</div>
				<ShippingPriceTable list={islanderShippingPriceList} toCountryList={toCountryList} type="islander"/>
			</div>

			<div className="shippingPricePage__table-container">
				<div className="title-button__container">
					<Typography variant="h6" className="section-title">
						Packaging Item
					</Typography>
					<Button
						variant="primary"
						type="button"
						className="btn btn-secondary"
						onClick={() => handleOpenDialog("purchase")} 
					>
						Add Packaging Price
					</Button>
				</div>
				<ShippingPriceTable list={packagingList} toCountryList={toCountryList} fromList={pickupCityList} type="purchase"/>
			</div>

			<Dialog
				open={openDialogSend}
				onClose={handleCloseDialogSend}
				maxWidth="lg"
			>
					<BarrelShippingEditableGrid toCountryList={toCountryList} setRows={setRows} rows={rows} clickedBtn={clickedBtn} />
					<DialogActions>
					<Button  autoFocus onClick={saveShipingPricesDataGrid}>
						Save Data
					</Button>
					</DialogActions>
			</Dialog>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				className="admin-modal admin-dialog shipping-dialog"
			>
				<form onSubmit={clickedBtn === "send" ? handleSubmit : (clickedBtn === "islander" ? handleSubmit1 : handleSubmit2)}>
					<DialogTitle className="admin-modal__title-container">
						Add Shipping Price
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Typography className="font-15 font-600 pb-3" style={{ color: 'red' }}>{error}</Typography>
						<Form className="edit-order-form shipping-price-form">
							<div className="shipping-price-form__fields">
					{clickedBtn !== "purchase" && (
								<Form.Group
									controlId="size"
									className="shipping-price-form__field"
								>
									<Form.Label>Size</Form.Label>
									<Form.Control
										type="text"
										value={formData.size}
										placeholder="Enter Size"
										onChange={(e) =>
											handleFieldChange(
												"size",
												e.target.value
											)
										}
										required
									>
										{/* <option value="">Select Size</option>
										<option value="sizes">S</option>
										<option value="sizem">M</option> */}
									</Form.Control>
								</Form.Group>
					)}
								{/* {clickedBtn === "islander" && (
									<Form.Group
									controlId="dimension"
									className="shipping-price-form__field"
								>
									<Form.Label>Dimension</Form.Label>
									<Form.Control
										type="text"
										value={formData.dimension}
										placeholder="Enter Dimension"
										onChange={(e) =>
											handleFieldChange(
												"dimension",
												e.target.value
											)
										}
										required
									>
									</Form.Control>
									</Form.Group>
								)}
								 */}


					{clickedBtn === "send" ? (
								<Form.Group
									controlId="from"
									className="shipping-price-form__field"
								>
									<Form.Label>From</Form.Label>
									<Form.Control
										as="select"
										value={formData.from}
										defaultValue="calgary" 
										onChange={(e) =>
											handleFieldChange(
												"from",
												e.target.value
											)
										}
										required
									>
										{(pickupCityList && pickupCityList.length>0) && pickupCityList.map(option => (
												<option key={option.city} value={option.city}>
													{option.city}
												</option>
											))}
									</Form.Control>
								</Form.Group>
							):(
								<Form.Group
									controlId="from"
									className="shipping-price-form__field"
								>
									<Form.Label>From</Form.Label>
									<Form.Control
										as="select"
										value={formData.from}
										onChange={(e) =>
											handleFieldChange(
												"from",
												e.target.value
											)
										}
										required
									>
										{/* <option value="">Select From</option> */}
										<option value="calgary">Calgary</option>
									</Form.Control>
								</Form.Group>)}

								{clickedBtn === "purchase" ? (
								<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
											defaultValue="calgary" 
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
											required
										>
											<option value="">Select an option</option>
											{(pickupCityList && pickupCityList.length>0) && pickupCityList.map(option => (
												<option key={option.city} value={option.city}>
													{option.city}
												</option>
											))}
											</Form.Select>
								</Form.Group>
								):(
									<Form.Group
									controlId="to"
									className="shipping-price-form__field select-field"
								>
									<Form.Label>To</Form.Label>
										<Form.Select
											name="to"
											value={formData.to}
											defaultValue="calgary" 
											onChange={(e) =>
												handleFieldChange(
													"to",
													e.target.value
												)
											}
											required
										>
											<option value="">Select an option</option>
											{(toCountryList && toCountryList.length>0) && toCountryList.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
											</Form.Select>
								</Form.Group>	
								)}

								{/* <Form.Group
									controlId="weight"
									className="shipping-price-form__field"
								>
									<Form.Label>Weight</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Size"
										value={formData.weight}
										onChange={(e) =>
											handleFieldChange(
												"weight",
												e.target.value
											)
										}
										// required
									>
									</Form.Control>
								</Form.Group> */}

								<Form.Group
									controlId="price"
									className="shipping-price-form__field"
								>	
									<Form.Label>Enter Shipping Price</Form.Label>
									<Form.Control
										type="number"
										value={formData.price}
										placeholder="Enter shipping price"
										onChange={(e) =>
											handleFieldChange(
												"price",
												e.target.value
											)
										}
										required
									/>
								</Form.Group>
							</div>
						</Form>
					</DialogContent>
					<DialogActions className="btns-add-shipping-price">

					{clickedBtn === "islander" && (
						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Add
						</Button>
					)}

					{clickedBtn === "send" && (
						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Add
						</Button>
					)}

					{clickedBtn === "purchase" && (
						<Button
							color="primary"
							className="btn btn-primary"
							type="submit"
						>
							Add
						</Button>
					)}
						
					</DialogActions>
				</form>
			</Dialog>
			
		</Box>
	);
}

export default ShippingPrice;
