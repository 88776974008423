import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    approveDrivers: [],
    pendingDrivers: [],
    rejectedDrivers: [],
    loading: false,
    error: null,
    message: null,
  };
  
  // Async thunk action to fetch customers
  export const approveDriver = createAsyncThunk(
    "driver/approveDriver",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("admin/driver/approved/", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

    // Async thunk action to fetch customers
    export const pendingDriver = createAsyncThunk(
      "driver/pendingDriver",
      async (_, { rejectWithValue }) => {
          try {
              const response = await Request.get("admin/driver/pending/", [], true);
              if (response.length !== 0) {
                   
                  return response
              } else {
                  console.error("Failed to fetch data from API");
              }
              } catch (error) {
                  return rejectWithValue(error);
              }
      }
    );

        // Async thunk action to fetch customers
        export const rejectedDriver = createAsyncThunk(
          "driver/rejectedDriver",
          async (_, { rejectWithValue }) => {
              try {
                  const response = await Request.get("admin/driver/rejected/", [], true);
                  if (response.length !== 0) {
                       
                      return response
                  } else {
                      console.error("Failed to fetch data from API");
                  }
                  } catch (error) {
                      return rejectWithValue(error);
                  }
          }
        );

         // Async thunk action to delete a customer
    export const updateDriverStatus = createAsyncThunk(
      "customer/updateDriverStatus",
      async ({id, isStatus}) => {
          
          try {
              const response = await Request.post(`admin/driver/verify/${id}`, {"isStatus":isStatus}, true);
              if (response && response.message === "Success") {
              
                    return { message: response.message };
                  }
                  else{
                    throw new Error('Update failed or invalid response.');
                  }
            } catch (error) {
              console.error('Error updating row from API:', error);
              throw error; 
            }
      }
    );
  
  // Async thunk action to delete a customer
  export const deleteCustomer = createAsyncThunk(
    "customer/deleteCustomer",
    async (id) => {
        try {
			const response = await Request.delete(`admin/user/${id}`, [], true);
			if(response) {
				if (response.status === 204) {
					// Update the state to remove the deleted user
					return id
				  }
				}
		  } catch (error) {
			console.error('Error deleting row from API:', error);
		  }
    }
  );
  

    // Async thunk action to delete a customer
    // export const updateCustomer = createAsyncThunk(
    //     "customer/updateCustomer",
    //     async ({id, item}) => {
    //         try {
    //               
    //             const response = await Request.put(`admin/user/${id}`, item, true);
    //             if(response) {
    //                 if (response.status === 200) {
    //                     // Update the state to remove the deleted user
    //                     return response.status
    //                   }
    //                 }
    //           } catch (error) {
    //             console.error('Error deleting row from API:', error);
    //           }
    //     }
    //   );

  const driverSlice = createSlice({
    name: "driver",
    initialState, 
    loading: false,
    error: null,
   message: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(approveDriver.pending, (state) => {
            state.loading = true;
          })
          .addCase(approveDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.approveDrivers = action.payload;
          })
          .addCase(approveDriver.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(pendingDriver.pending, (state) => {
            state.loading = true;
          })
          .addCase(pendingDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingDrivers = action.payload;
          })
          .addCase(pendingDriver.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(rejectedDriver.pending, (state) => {
            state.loading = true;
          })
          .addCase(rejectedDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.rejectedDrivers = action.payload;
          })
          .addCase(rejectedDriver.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
          .addCase(deleteCustomer.pending, (state) => {
            state.loading = true;
          })
          // .addCase(deleteCustomer.fulfilled, (state, action) => {
          //   state.loading = false;
          //   const deletedCustomerId = action.payload;
          //   state.customers = state.customers.filter(
          //     (customer) => customer.id !== deletedCustomerId
          //   );
          // })
          // .addCase(deleteCustomer.rejected, (state, action) => {
          //   state.loading = false;
          //   state.error = action.payload;
          // })
          .addCase(updateDriverStatus.pending, (state) => {
            state.loading = true;
          })
          .addCase(updateDriverStatus.fulfilled, (state, action) => {
              
            state.loading = false;
            state.message = action.payload.message;
          })
          .addCase(updateDriverStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          });
      },
  });
  
  export default driverSlice.reducer;