import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import OrderList from './orderList';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderList } from "../redux/islanderListSlice";
import { fetchPickupList, fetchSendPickupList } from "../redux/pickupLocationSlice";
import { DateTime, Settings } from 'luxon';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    productRow: {
        paddingTop: "20px",
    },
    productCol: {
        display: "flex",
        alignItems: "center"
    },
    grid: {
        paddingLeft: "100px !important"
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },

    formPadding: {
        marginTop: "12px"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
    
}));

function SendCheckout() {
   

    const classes = useStyles();
    const [cartInfo, setCartInfo] = useState([]);
    const [cartTotalPrice, setCartTotalPrice] = useState(0);
    const [date, setDate] = useState('');
    const [availableSlot, setAvailableSlot] = useState([]);
    const datePickerRef = useRef(null);
    const [clickableDates, setClickableDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loginCheck, setLoginCheck] = useState(false);
    const [isEmailTaken, setIsEmailTaken] = useState(false);
    const [cityDisabled, setCityDisabled] = useState(true);
    const [cities, setCities] = useState([]);
    const [stateDisabled, setStateDisabled] = useState(true);
    const [states, setStates] = useState([]);
    const [allCountries, setAllCountries] = useState([
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo (Congo-Brazzaville)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czechia",
      "Democratic Republic of the Congo (Congo-Kinshasa)",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Holy See",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Ivory Coast",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (formerly Burma)",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Korea",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine State",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Korea",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States of America",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
      ],
  )

    const [addresses, setAddresses] = useState({
        "shippingAddress": {
            "firstName": "",
            "lastName": "",
            "address1": "",
            "address2": "",
            "phone": "",
            "email": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": ""
        },
        "pickupAddress": {
            "firstName": "",
            "lastName": "",
            "address1": "",
            "address2": "",
            "phone": "",
            "email": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": ""
        },
        "senderAddress": {
            "firstName": "",
            "lastName": "",
            "address1": "",
            "address2": "",
            "phone": "",
            "email": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": ""
        },
        "billingAddress": {
            "firstName": "",
            "lastName": "",
            "address1": "",
            "address2": "",
            "phone": "",
            "email": "",
            "city": "",
            "state": "",
            "zip": "",
            "country": ""
        },
        "senderAddressBoolean": true,
        "billingBoolean": true,
        "slot": "",
        "insurance": false
    });
    const [sameAsShipping, setSameAsShipping] = useState(true);
    const [sameAsSender, setSameAsSender] = useState(true);
    const [selectedSlot, setSelectedSlot] = useState('');
	const [insuranceText, setInsuranceText] = useState("");
    const dispatch = useDispatch();
    const [checkoutError, setCheckoutError] = useState(false);
    const [insurance, setInsurance] = useState(null);
    const islanderShippingList = useSelector((state) => state.islanderList.islanderList);
    const pickupLocationList = useSelector((state) => state.pickupList.pickupListLocation);
    const sendPickupList = useSelector((state) => state.pickupList.sendPickupListLocation);
    var sendPickupListProvinces = [];
    sendPickupList.forEach(element => {
        if (!sendPickupListProvinces.includes(element.province)) {
            sendPickupListProvinces.push(element.province);
        }
    });


    useEffect(() => {
        Settings.defaultZoneName = 'Asia/Kolkata';
        fetchData();
      }, []);

	const fetchData = async (e) => {
		try {
			const response = await Request.get(`admin/general-setting/insurance`, [], true);
			
			if (response.length !== 0) {
				setInsuranceText(response[0].value)
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};
    // const pickupDate = (selectedDate) => {
    //     setDate(selectedDate);
    //   };
    
      const openDatePicker = () => {
        // Focus on the DatePicker when the icon is clicked
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(true);
        }
      };

    let login = checkLogin()

    useEffect(() => {
        (async () => {
            let data = await Request.get("send/cart", [], true);
            {
                if (data) {
                    var confirmPrice = data.totalPrice;
                    setCartInfo(data.cartItems);
                    setCartTotalPrice(confirmPrice);
                }
                else {
                    console.log("error");
                }

            }
        })();
    }, []);

    const isAddressValid = (address) => {
        return Object.values(address).every((value) => value.trim() !== '');
    };

    useEffect(() => {
        fetchDate();
    }, []);

    const fetchDate = async () => {
        try {
            const response = await Request.get("pickup/slot/dates", [], true);
            if (response.dates.length !== 0 && response.dates !== undefined) {
                const clickableDates = response.dates.map((dateStr) =>
                    DateTime.fromISO(dateStr).setZone('America/Toronto').toJSDate()
                );
                setClickableDates(clickableDates);
            } else {
                console.log("Something Went wrong");
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    useEffect(() => {
        fetchShppingDefaultAddress()
      }, []);
    
      const fetchShppingDefaultAddress = async () => {
              
            // setIsDialogOpen(true);
            try {
            const response = await Request.get(`user/getDefaultAddresses`, [], true);
             
            if (Object.keys(response).length > 0) {
                
                response.recipientAddresses.forEach((address) => {
                  var dataFirst_name = address.first_name;
                  var dataLast_name = address.last_name;
                  var dataAddress_line_1 = address.address_line_1;
                  var dataAddress_line_2 = address.address_line_2;
                  var dataCity = address.city;
                  var dataState = address.state;
                  var dataEmail = address.email;
                  var dataPhone = address.phone;
                  var dataCountry = address.country;
                  var dataZip = address.zip;
               
                //   if(dataCountry){
                //     fetchStateForCountries(dataCountry);
                //     // setCityDisabled(false)
                // }

                
                  
                setAddresses((prevAddresses) => ({
                  ...prevAddresses,
                  shippingAddress: {
                    firstName: dataFirst_name,
                    lastName: dataLast_name,
                    address1: dataAddress_line_1,
                    address2: dataAddress_line_2,
                    city: dataCity,
                    state: dataState,
                    zip: dataZip,
                    country: dataCountry,
                    email: dataEmail,
                    phone: dataPhone
                  },
                }));
              });

              response.pickupAddresses.forEach((address) => {
                let dataFirst_name = address.first_name;
                let dataLast_name = address.last_name;
                let dataAddress_line_1 = address.address_line_1;
                let dataAddress_line_2 = address.address_line_2;
                let dataCity = address.city;
                let dataState = address.state;
                let dataCountry = address.country;
                let dataZip = address.zip;
                let dataEmail = address.email;
                let dataPhone = address.phone;
             

                if(dataCountry){
                    fetchStateForCountries(dataCountry);
                }

                if(dataCity){
                  fetchCitiesForState(dataState);
                  setCityDisabled(false)
              }

           

              setAddresses((prevAddresses) => ({
                ...prevAddresses,
                pickupAddress: {
                  firstName: dataFirst_name,
                  lastName: dataLast_name,
                  address1: dataAddress_line_1,
                  address2: dataAddress_line_2,
                  city: dataCity,
                  state: dataState,
                  zip: dataZip,
                  country: dataCountry,
                  email: dataEmail,
                phone: dataPhone
                },
              }));
            });

            // response.senderAddress.forEach((address) => {
            //     let dataFirst_name = address.first_name;
            //     let dataLast_name = address.last_name;
            //     let dataAddress_line_1 = address.address_line_1;
            //     let dataAddress_line_2 = address.address_line_2;
            //     let dataCity = address.city;
            //     let dataState = address.state;
            //     let dataCountry = address.country;
            //     let dataZip = address.zip;
             
            // //     if(dataCity){
            // //       fetchCitiesForState(dataCity);
            // //       setCityDisabled(false)
            // //   }

            //   setAddresses((prevAddresses) => ({
            //     ...prevAddresses,
            //     pickupAddress: {
            //       firstName: dataFirst_name,
            //       lastName: dataLast_name,
            //       address1: dataAddress_line_1,
            //       address2: dataAddress_line_2,
            //       city: dataCity,
            //       state: dataState,
            //       zip: dataZip,
            //       country: dataCountry,
            //     },
            //   }));
            // });
              }
              else{
                
              }
                    }
            catch (error) {
                      console.error('Error deleting row from API:', error);
                }
        };

    useEffect(() => {
    }, [clickableDates]);
       
    useEffect(() => {
        return () => {

        };
    }, [addresses]);

    
    useEffect(() => {
        dispatch(fetchIslanderList());
        dispatch(fetchPickupList());
        dispatch(fetchSendPickupList());
    }, [dispatch]);

    
    useEffect(() => {
        // console.log('bbbbbbbbbb')
        // dispatch(fetchIslanderList());
        // dispatch(fetchPickupList());
        // dispatch(fetchSendPickupList());
    }, []);

    useEffect(() => {
        if (addresses.senderAddressBoolean) {
            // Copy the sender's address to pickupAddress and billingAddress
            setAddresses((prevAddresses) => ({
                ...prevAddresses,
                pickupAddress: { ...prevAddresses.senderAddress },
                billingAddress: { ...prevAddresses.senderAddress },
            }));
        }
    }, [addresses.senderAddressBoolean, addresses.senderAddress]);

    const handleSubmit = async (event) => {
          
        event.preventDefault();
        setTimeout(() => {

        }, 2000);


        if(addresses.pickupAddress.phone.length === 10 && addresses.shippingAddress.phone.length === 10){
            addresses.senderAddress.phone = addresses.pickupAddress.phone;
            addresses.billingAddress.phone = addresses.pickupAddress.phone;
        }
        if(addresses.pickupAddress.phone.length === 10 && addresses.senderAddress.phone.length === 10 && 
            addresses.billingAddress.phone.length === 10 && addresses.shippingAddress.phone.length === 10){
                let response = await Request.post("checkout/sendCheckout", addresses, true)
                {
                    if (response.orderId === undefined) {
                        setCheckoutError(true)
                        return;
                    }
                    else{
                        window.location.href = "/user/send/send-payment?orderId=" + response.orderId;
                    }
                }
                if (!response) {
                    console.log('something went wrong');
                }
        }else{
            alert("Please 10 digit phone number in all addresses");
            
        }

    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setAddresses((prevState) => {
            const updatedState = {
                ...prevState,
                [name]: checked
            };

            if (checked) {
                updatedState.senderAddress = { ...prevState.pickupAddress };
                updatedState.billingAddress = { ...prevState.pickupAddress };
            }
            return updatedState;
        });
    };

    const handleInsuranceChange = (e) => {
          
        const selectedValue = e.target.value;
        // Convert the selected value to a boolean
        const insuranceValue = selectedValue === 'true';
        setInsurance(insuranceValue);
       
      };

      useEffect(() => {
        setAddresses((prevState) => ({
            ...prevState,
            "insurance" : insurance
        }));
      }, [insurance]);



    const handleInputChange = (e, addressType) => {
        let { name, value } = e.target;
        if(name === "state" && addressType === "pickupAddress"){
            setAddresses((prevState) => ({
                ...prevState,
                [addressType]: {
                    ...prevState[addressType],
                    [name]: value,
                },
            }));
            fetchCitiesForState(value);
            setCityDisabled(false)
        }else if(name === "country" && addressType === "billingAddress"){
            setAddresses((prevState) => ({
                ...prevState,
                [addressType]: {
                    ...prevState[addressType],
                    [name]: value,
                },
            }));
            fetchStateForCountries(value);
            setStateDisabled(false)
        }else if(name === "phone"){
            value = value.replace(/[^0-9]/g, '');
            setAddresses((prevState) => ({
                ...prevState,
                [addressType]: {
                    ...prevState[addressType],
                    [name]: value,
                },
            }));
        }else{
            setAddresses((prevState) => ({
                ...prevState,
                [addressType]: {
                    ...prevState[addressType],
                    [name]: value,
                },
            }));
        }
    };

    const fetchCitiesForState = async (state) => {
        try {
                // Fetch data from API
                const response = await Request.get(`utils/getPickupLocationSendByProvince?province=${state}`, []);
                if (response.length !== 0) {
                    setCities(response)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }	
      };

      const fetchStateForCountries = async (country) => {
        try {
                  
                const response = await Request.get(`utils/getProvinceListByCountry?country=${country}`, []);
                if (response.provinces.length !== 0) {
                    setStates(response.provinces)
                } else {
                    console.error('Failed to fetch data from API');
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }	
      };

    const pickupDate = async (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDate(date)
        const selectedDate = {
            "date": formattedDate
        };

        let data = await Request.post("pickup/slot/check_slots", selectedDate, true);
        {
            setAvailableSlot(data)
            if (data.length > 0) {
                const defaultSlot = data[0].id;
                setAddresses((prevState) => ({
                    ...prevState,
                    slot: defaultSlot,
                }));
                setSelectedSlot(defaultSlot);
            }
        }
        if (!data) {
            console.log("Api error");
            return
        }
    }


    const handleSlot = (e) => {
        const { value } = e.target;

        setAddresses((prevState) => ({
            ...prevState,
            slot: value,
        }));

        setSelectedSlot(value);
    };
    
    // const datePickerRef = React.createRef();

    // const countryOptions = ["Anitigua and Barbuda", "Barbados", "Jamaica","Grenada","Guyana","Saint Vincent","Trinidad and Tobago","Saint Lucia","Dominica"];
    const countryOptions = ["Canada"];

    return (

        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Checkout</Typography>
                <Box className={classes.headerFlexBox}>
                    <Link to='/user/send/send-product'>
                        <Typography className={classes.pathName}>Send Barrels</Typography>
                    </Link>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Checkout</Typography>
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='cartTable'>
                        <Box className="gridInnerBlockLeft">
                            <form className='checkoutForm' onSubmit={handleSubmit}>
                              
                                <Box className="ShippingBox">
                                    <Typography align="left" className='font-20 color2 font-bold'>Recipient Address</Typography>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name" name="firstName" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.firstName} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name" name="lastName" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.lastName} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address1" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.address1} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address2" onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.address2} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Contact No</Form.Label>
                                                <Form.Control type="tel" placeholder="phone" name="phone" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.phone} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Email" name="email" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.email} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Select
                                                    name="country"
                                                    value={addresses.shippingAddress.country}
                                                    required
                                                    onChange={(e) => handleInputChange(e, "shippingAddress")}
                                                >
                                                    <option value="">Select country</option>
                                                    {(islanderShippingList && islanderShippingList.length>0) && islanderShippingList.map((country) => (
                                                        <option key={country.country} value={country.country}>
                                                            {country.country}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Province</Form.Label>
                                                <Form.Control type="text" placeholder="Province" name="state" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.state} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>City/Town</Form.Label>
                                                <Form.Control type="text" placeholder="City" name="city" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.city} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control type="text" placeholder="Zip Code" name="zip" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.zip} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                  
                                </Box>

                                <Box className="SenderBox">
                                    <Typography align="left" className='font-22 color2 font-bold'>Pickup Address</Typography>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name" name="firstName" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.firstName} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name" name="lastName" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.lastName} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address1" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.address1} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address2" onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.address2} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Contact No</Form.Label>
                                                <Form.Control type="tel" placeholder="phone" name="phone" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.phone} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" placeholder="Email" name="email" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.email} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Select
                                                    name="country"
                                                    value={addresses.pickupAddress.country}
                                                    required
                                                    onChange={(e) => handleInputChange(e, "pickupAddress")}
                                                >
                                                    <option value="">Select country</option>
                                                    {(countryOptions && countryOptions.length>0) && countryOptions.map((country) => (
                                                        <option key={country} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Province</Form.Label>
                                                <Form.Select
                                                    name="state"
                                                    value={addresses.pickupAddress.state}
                                                    required
                                                    onChange={(e) => handleInputChange(e, "pickupAddress")}
                                                >
                                                    <option value="">Select Province</option>
                                                    {(sendPickupListProvinces && sendPickupListProvinces.length>0) && sendPickupListProvinces.map((item) => (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {/* <Form.Control type="text" placeholder="State" name="state" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.state} /> */}
                                            </Col>
                                          
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>City/Town</Form.Label>
                                                <Form.Select
                                                    name="city"
                                                    value={addresses.pickupAddress.city}
                                                    required
                                                    onChange={(e) => handleInputChange(e, "pickupAddress")}
                                                    disabled={cityDisabled}
                                                >
                                                    <option value="">Select City</option>
                                                    {(cities && cities.length>0) && cities.map((item) => (
                                                        <option key={item.city} value={item.city}>
                                                            {item.city}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {/* <Form.Control type="text" placeholder="City" name="city" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.city} /> */}
                                            </Col>
                                         
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control type="text" placeholder="Zip Code" name="zip" required onChange={(e) => handleInputChange(e, "pickupAddress")} value={addresses.pickupAddress.zip} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row className="slotRow">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Preferred Pickup Date</Form.Label>
                                                <div style={{ display: 'flex', alignItems: 'center', position: "relative" }}>
                                                    {/* Icon */}
                                                    <div style={{ cursor: 'pointer', zIndex: 9,position: "absolute", right:"15px" }} onClick={openDatePicker}>
                                                    <i className="fa fa-calendar" />
                                                    </div>
                                                    {/* DatePicker */}
                                                    <DatePicker
                                                    selected={selectedDate}
                                                    includeDates={(clickableDates && clickableDates.length>0) && clickableDates.map((dateStr) => new Date(dateStr))}
                                                    onChange={pickupDate}
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholder="Select Date"
                                                    required
                                                    className="custom-datepicker-calendar"
                                                    ref={datePickerRef}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                {selectedDate !== '' ? <>
                                                    <Form.Label>Preferred Time Slot</Form.Label>
                                                    <Form.Select
                                                        name="slot"
                                                        value={selectedSlot}
                                                        onChange={(e) => handleSlot(e)}
                                                        required
                                                        id="slot"
                                                    >
                                                        {/* <option value="">Select Slot</option> */}
                                                        {(availableSlot && availableSlot.length>0) && availableSlot.map((slot) => (
                                                            <option key={slot.id} value={slot.id}>
                                                                {slot.start_time} - {slot.end_time}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </>
                                                    :
                                                    <></>
                                                }
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                </Box>

                                <Box className="BillingBox">
                                    <Typography align="left" className='font-22 color2 font-bold'>Sender Address</Typography>
                                    <Form.Group className={`${classes.formPadding} flex-check`}>
                                        <input type="checkbox" id="myCheck1" className='check-input' name="senderAddressBoolean" checked={addresses.senderAddressBoolean} onChange={handleCheckboxChange}></input>
                                        <label htmlFor='myCheck' className='checkLabel'>My Sender address is same as Pickup address</label>
                                    </Form.Group>
                                    {!addresses.senderAddressBoolean && (
                                        <>
                                            <Box id="BillingBox">
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control type="text" placeholder="First Name" name="firstName" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.firstName} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Last Name" name="lastName" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.lastName} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Form.Label>Address Line 1</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address1" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.address1} />
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address2" onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.address2} />
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Contact No</Form.Label>
                                                            <Form.Control type="tel" placeholder="phone" name="phone" onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.phone} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" placeholder="Email" name="email" onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.email} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Select
                                                                name="country"
                                                                onChange={(e) => handleInputChange(e, "senderAddress")}
                                                                value={addresses.senderAddress.country}
                                                                required
                                                            >
                                                                <option value="">Select country</option>
                                                                {(countryOptions && countryOptions.length>0) && countryOptions.map((country) => (
                                                                    <option key={country} value={country}>
                                                                        {country}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Province</Form.Label>
                                                            <Form.Select
                                                                name="state"
                                                                value={addresses.senderAddress.state}
                                                                required
                                                                onChange={(e) => handleInputChange(e, "senderAddress")}
                                                            >
                                                                <option value="">Select Province</option>
                                                                {(sendPickupList && sendPickupList.length>0) && sendPickupList.map((item) => (
                                                                    <option key={item.province} value={item.province}>
                                                                        {item.province}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>City/Town</Form.Label>
                                                            <Form.Select
                                                                name="city"
                                                                value={addresses.senderAddress.city}
                                                                required
                                                                onChange={(e) => handleInputChange(e, "senderAddress")}
                                                                disabled={cityDisabled}
                                                            >
                                                                <option value="">Select City</option>
                                                                {(cities && cities.length>0) && cities.map((item) => (
                                                                    <option key={item.city} value={item.city}>
                                                                        {item.city}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>            
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Zip Code</Form.Label>
                                                            <Form.Control type="text" placeholder="Zip Code" name="zip" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.zip} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Box>
                                        </>
                                    )}
                                </Box>

                                <Box className="BillingBox">
                                    <Typography align="left" className='font-22 color2 font-bold'>Billing Address</Typography>
                                    <Form.Group className={`${classes.formPadding} flex-check`}>
                                        <input type="checkbox" id="myCheck2" className='check-input' name="billingBoolean" checked={addresses.billingBoolean} onChange={handleCheckboxChange}></input>
                                        <label htmlFor='myCheck' className='checkLabel'>My billing address is same as Pickup address</label>
                                    </Form.Group>
                                    {!addresses.billingBoolean && (
                                        <>
                                            <Box id="BillingBox">
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control type="text" placeholder="First Name" name="firstName" required onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.firstName} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Last Name" name="lastName" required onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.lastName} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Form.Label>Address Line 1</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address1" required onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.address1} />
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control type="text" placeholder="Address" name="address2" onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.address2} />
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Contact No</Form.Label>
                                                            <Form.Control type="tel" placeholder="phone" name="phone" onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.phone} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" placeholder="Email" name="email" onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.email} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Select
                                                                name="country"
                                                                value={addresses.billingAddress.country}
                                                                required
                                                                onChange={(e) => handleInputChange(e, "billingAddress")}
                                                            >
                                                                <option value="">Select Country</option>
                                                                {(allCountries && allCountries.length>0) && allCountries.map((item) => (
                                                                    <option key={item} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Province</Form.Label>
                                                            <Form.Select
                                                                name="state"
                                                                value={addresses.billingAddress.state}
                                                                required
                                                                onChange={(e) => handleInputChange(e, "billingAddress")}
                                                                disabled={stateDisabled}
                                                            >
                                                                <option value="">Select Province</option>
                                                                {(states && states.length>0) && states.map((item) => (
                                                                <option key={item} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                            </Form.Select>
                                                            {/* <Form.Control type="text" placeholder="State" name="state" required onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.state} /> */}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className={classes.formPadding}>
                                                    <Row>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>City/Town</Form.Label>
                                                            <Form.Control type="text" placeholder="City" name="city" required onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.city} />
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <Form.Label>Zip Code</Form.Label>
                                                            <Form.Control type="text" placeholder="Zip Code" name="zip" required onChange={(e) => handleInputChange(e, "billingAddress")} value={addresses.billingAddress.zip} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Box>
                                        </>
                                    )}

                                </Box>
                                
                                <Box sx={{pt: 2}}>
                                    <Row className='insurance-section-select'>
                                        <Col className='insurance-section-select'>
                                        <Form.Group className={classes.formPadding}>
                                        <Form.Label>{insuranceText}</Form.Label>
                                            <Typography className='insurance-subtitle'>Select yes or no to add insurance to your shipping order.</Typography>
                                            <Form.Select
                                                name="insurance"
                                                value={insurance === null ? '' : insurance.toString()} 
                                                onChange={handleInsuranceChange}
                                                required
                                                >
                                                <option value="">Select an option</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </Form.Select>
                                         </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                </Box>
                                

                                <Box align="center" sx={{ pt: 8 }}>
                                    <Button type="submit" className='b1'>Proceed to Payment</Button>
                                </Box>
                                {checkoutError === true ? (
                                    <Typography className="font-600 font-18" style={{color: "red"}}>Server Error</Typography>
                                ):(
                                    <></>
                                )}
                            </form>
                        </Box>
                    </Grid>

                    {cartInfo && (
                        <OrderList cartInfo1={cartInfo} totalPrice={cartTotalPrice} />
                    )}


                </Grid>
            </Box>
        </Box>

    )
};


export default SendCheckout;

