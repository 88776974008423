import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import CMSFooter from '../footer/cmsFooter';
import { Col, Row, Form } from 'react-bootstrap';
import { spacing } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    list: {
    },
    listItem: {
        paddingLeft: "0px",
    },
    Avatar: {
        borderRadius: "4px",
        width: "45px",
        height: "45px",
        marginRight: "20px"
    },
    card: {
        maxWidth: 200,
        margin: spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9 aspect ratio
    },
    // grid:{
    //     gap: "20px",
    // },
    grid: {
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "15px",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },

    // For medium screens (between 600px and 960px)

    col2: {
        textAlign: "left",
        paddingLeft: "20px"
    },
    blockBackground: {
        backgroundImage: "url('./assets/img/block_bg_image.svg')",
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
    },
    blockInnerBackground: {
        backgroundColor: "var(--primary-color6) !important",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "200px 0px"
    }

}));




const Services = () => {
    const classes = useStyles();

    return (
        <>
            <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>What We Provide</Typography>
                        </Col>
                    </Row>
                    <Box sx={{ pt: 5, pb: 7 }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='service-main-box'>
                                <Grid item xs={12} sm={12} md={6} lg={11.5} className='service-main-box'>
                                    <a href="/services/buy-a-barrel">
                                        <Box className={classes.grid}>
                                            <Box className="service_block">
                                                <img src="./assets/img/service_barrel_img1.png" alt="list_img" className='' />
                                            </Box>

                                            <Box className="headerFlexBox" sx={{pt:2}}><h4 className='font-bold'>Buy a Barrel</h4></Box>

                                            <Typography textAlign="center" className='color2 font-16 pt-2'>IOF makes buying barrels more seamless than it’s ever been before.</Typography>

                                        </Box>
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='service-main-box'>
                                <Grid item xs={12} sm={12} md={6} lg={11.5} className='service-main-box'>
                                    <a href="/services/ship-a-barrel">
                                        <Box className={classes.grid}>
                                            <Box className="service_block">
                                                <img src="./assets/img/service_barrel_img2.png" alt="list_img" className='' />
                                            </Box>

                                            <Box className="headerFlexBox" sx={{pt:2}}><h4 className='font-bold'>Ship A Barrel</h4></Box>
                                            <Typography textAlign="center" className='color2 font-16 pt-2'>Isle of Freight introduces our premium barrel shipping service.</Typography>
                                        </Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='service-main-box'>
                                <Grid item xs={12} sm={12} md={6} lg={11.5} className='service-main-box'>    
                                    <a href="/services/ship-islander-box">
                                        <Box className={classes.grid}>
                                            <Box className="service_block">
                                                <img src="./assets/img/islander_img1.png" alt="list_img" className='' />
                                            </Box>

                                            <Box className="headerFlexBox" sx={{pt:2}}><h4 className='font-bold'>Ship An Islander Box</h4></Box>
                                            <Typography textAlign="center" className='color2 font-16 pt-2'>Shopping online has never been easier! Isle of Freight offers a comprehensive packing and shipping service for your convenience.</Typography>
                                        </Box>
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='service-main-box'>
                                <Grid item xs={12} sm={12} md={6} lg={11.5} className='service-main-box'>    
                                    <a href="/services/packaging-items">
                                        <Box className={classes.grid}>
                                            <Box className="service_block">
                                                <img src="./assets/img/islander_img2.png" alt="list_img" className='' />
                                            </Box>

                                            <Box className="headerFlexBox" sx={{pt:2}}><h4 className='font-bold'>Packaging Items</h4></Box>
                                            <Typography textAlign="center" className='color2 font-16 pt-2'>Isle of Freight packaging items are designed to provide optimal protection and convenience for
                                            your goods during shipping and transportation.</Typography>
                                        </Box>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>

            <CMSFooter />
            <Footer />
        </>
    );
}

export default Services;
