import { useEffect, useState } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Request from '../customUtils/Http/Request';
import $ from 'jquery';
import { Container, Typography, Box, TextField, Button, Link, FormHelperText, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Cookies from 'universal-cookie';
// import { } from 'react-bootstrap';
// import { checkLogin } from '../../commonFunctions/commonFunctions';
import Header from '../header/header';
import Footer from '../footer/footer';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Dashboard from '../dashboard/dashboard';

const cookies = new Cookies();
const CssTextField = styled(TextField)({
  // '& label':{
  //   fontSize: '15px !important',
  //   fontWeight: 'bold !important',
  //   color: '#033D1E',
  // },
  '& label.Mui-focused': {
    color: '#033D1E',
    fontSize: '15px !important',
    fontWeight: 'bold !important',
    lineHeight: '25px !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#033D1E',
  },

  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    // "&.MuiOutlinedInput-notchedOutline": {
    //   fontSize: "14px",
    // },
    '&:hover fieldset': {
      borderColor: '#FFC72C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFC72C',
      borderWidth: '1px',
    },
  },
});

function AdminPasswordReset() {
  let login = checkLogin();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const isLoggedIn = checkLogin();
    if (isLoggedIn) {
      navigate('/user/customer/dashboard')
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token'); 
    const otp = searchParams.get('otp'); 
    setToken(token)
    setOtp(otp)
}, [location.search]);

  const handleChange = (event) => {
    setPassword(event.target.value);
    setError(!isValidPassword(event.target.value));
  };

  const isValidPassword = (value) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordPattern.test(value);
  };

  const handleLoginSubmit = async (event) => {
      
    event.preventDefault();
    let inputField = event.target.getElementsByTagName('input');
    let password = inputField[0],
      confirmPassword = inputField[1];

      if (password.value !== confirmPassword.value) {
        setTimeout(() => {
          $('#errorMessage').fadeIn('slow').delay(3000).hide(0);
        }, 1000);
        return
      }

    if(password !== '' && otp !== '' && token !== ''){
        var data= {
            "password": password.value,
            "otp": otp,
            "token": token
        }
        try {
            const response = await Request.put(`user/emailResetPassword`, data);
             
            if (response) {
                if(response.status === 200){
                    setMessage("password Reset Successfully.");
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1000); 
                }else{
                    setMessage(response.message)
                    return;
                }  
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }
    else{
        alert("Something Went Wrong")
      }
  }


  return (
    <>
      {login === false ? (
        <>
          <Header />
          <Container maxWidth="xl" className='homeContainer'>
            <Box className='middle_block' textAlign="center">
              <Box className='middle_inner_block'>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                  <Typography variant='h5' className='formTitle'>Password Reset</Typography>
                </Box>

                <Box className='login_form'
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                    mt: 3,
                  }}
                  // noValidate
                  autoComplete="off"
                  onSubmit={event => handleLoginSubmit(event)}
                >
                <div error={error}>
                    <CssTextField fullWidth id="password" hinttext="Password" floatinglabeltext="Password" label="Password" type="password" className='text_field' value={password} onChange={handleChange} required></CssTextField>
                    {error && <FormHelperText className="errorMsg">Password must contain Minimum eight characters, at least one letter and one number and one Special character.</FormHelperText>}
                </div>
                <div>
                    <CssTextField fullWidth id="confPassword" hinttext="Confirm Password" floatinglabeltext="Confirm Password" label="Confirm Password" type="password" className='text_field' required></CssTextField>
                </div>
                <FormGroup className='checkBox-color'>
                    <FormControlLabel required control={<Checkbox />} label={
                        <span>
                            <Link href="/terms-and-conditions" className='color2 font-600 font-15'>
                            Read Terms and Conditions
                            </Link>
                        </span>
                    }>
                    </FormControlLabel>
                </FormGroup>
                  <div>
                    <Button variant="contained" type="submit" className='submitBtn' id="loginSubmitBtn">Password Reset</Button>
                  </div>

                    <Box className="errorMessage" id="errorMessage">Password and Confirm Password does not match.</Box>
                    <Typography className="loginErrorMessage2" id="">{message}</Typography>
                </Box>
              </Box>
            </Box>
          </Container>
          <Footer />
        </>
      ) : (
        <>
          <Dashboard />
        </>
      )}
    </>
  );
}

export default AdminPasswordReset;
