import Cookies from 'universal-cookie';
// import { encrypt,decrypt } from '../customUtils/Encryption/cryptography'
import request from '../customUtils/Http/Request'
const cookies = new Cookies();
// call protect() to check user is login or not.

let fetchRefresh = (refreshToken) => {
    // var myHeaders = new Headers();
    // myHeaders.append("Cookie", refreshToken);
    return new Promise(
        (resolve,reject) => {
                let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        credentials: 'include',
    }
    var requestOptions = {
    method: 'POST',
    headers: headers,
    credentials: 'include',
    };

    fetch("http://127.0.0.1:4000/api/user/refresh_token/", requestOptions)
    .then(response => response.text())
    .then(result => {
        resolve()
    })
    .catch(error => {
        console.log('error', error);
        reject()
    });
        }
    )

    }
let refresh = async (refreshToken)=>{
    // let refresh = await decrypt(refreshToken) //in backend refresh is in the database so save it in refresh
    // console.log(refreshToken,'refreshTokennnnnnnn');
    //   
    // let accesTokenData = await request.post("user/refresh_token",{
    //     refreshToken
    // })
    // console.log(accesTokenData);
     fetchRefresh(refreshToken)
    // if(accesTokenData.access === undefined){
    //     console.log(accesTokenData,"%c after sending refresh token than returned response error","color:brown")
    //     return false
    // }else{
    //     let accessToken = accesTokenData.access
    //     // let accessTokenEncrypt = encrypt(String(accessToken));
    //     var date = new Date();
    //     let accessDate = new Date(date.getTime() + 14*60000);
    //     cookies.set("accessToken", accessToken, { path: "/login", expires: accessDate });
    //     return accessToken
    // }
}
let hasAccess = async (accessToken,refreshToken)=>{
    if(!refreshToken) {
        return false
    }
    if(accessToken === undefined || accessToken === "undefined"){
        // generate new accestoken
        accessToken = await refresh(refreshToken)
        return accessToken
    }
    // accessToken =await decrypt(accessToken) 
    return accessToken
}
export async function protect(){
    let accesToken = cookies.get('accessToken', { path: '/' }) //we wil send this access token to authenticate 
    // access token will expire after 5 minutes form it created 
    let refreshToken = cookies.get("refreshToken", {path: "/"}) // using refresh token we can generate access token
    // refersh token will expire after 1day form it created 
    let accesToken2 = await hasAccess(accesToken,refreshToken)
    if(!accesToken2){
        // if access token is false than user is not authenticated
        return false
    }else{
        return accesToken2
    }
}