import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Form } from "react-bootstrap";
import Request from '../customUtils/Http/Request';

const BaseIslanderAddressForm = () => {
	const [id, setId] = useState('')
	const [formData, setFormData] = useState({
		name: "",
		// phone: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		country: "",
		zip: "",
		// is_default : true
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	useEffect(() => {
		fetchAddress();	
	}, []);

	const fetchAddress = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("admin/getWarehouseAddress", [], true);
            if (response !== undefined) {
                setFormData({
					name: response.name,
					// phone: response.phone,
					address1: response.address_line_1,
					address2: response.address_line_2,
					city: response.city,
					state: response.state,
					country: response.country,
					zip: response.zip,
					// is_default : true
				})
				setId(response.id)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
            // Fetch data from API
            const response = await Request.put(`admin/editWarehouseAddress/${id}`, formData , true);
            if (response.message === "Success") {
              window.location.reload()
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
	};

	return (
		<Box className="islander-address-form-container">
			<form onSubmit={handleSubmit}>
			<div className="islander-address-form-button">
				<Button
					variant="primary"
					type="submit"
					className="btn btn-secondary"
				>
					Edit
				</Button>
			</div>
			<div className="islander-address-form-section">
				<Box
					
					className="edit-order-form islander-address-form"
				>
					<div className="islander-address-form__fields">
						<Form.Group
							controlId="formName"
							className="slander-address-form__field islander-address-form__field--full"
						>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								name="name"
								value={formData.name}
								onChange={handleInputChange}
							/>
						</Form.Group>

						{/* <Form.Group
							controlId="formPhone"
							className="islander-address-form__field"
						>
							<Form.Label>Phone No.</Form.Label>
							<Form.Control
								type="text"
								name="phone"
								value={formData.phone}
								onChange={handleInputChange}
							/>
						</Form.Group> */}

						<Form.Group
							controlId="formAddressLine1"
							className="islander-address-form__field islander-address-form__field--full"
						>
							<Form.Label>Address Line 1</Form.Label>
							<Form.Control
								type="text"
								name="address1"
								value={formData.address1}
								onChange={handleInputChange}
							/>
						</Form.Group>

						<Form.Group
							controlId="formAddressLine2"
							className="islander-address-form__field islander-address-form__field--full"
						>
							<Form.Label>Address Line 2</Form.Label>
							<Form.Control
								type="text"
								name="address2"
								value={formData.address2}
								onChange={handleInputChange}
							/>
						</Form.Group>


						<Form.Group
							controlId="formCity"
							className="islander-address-form__field"
						>
							<Form.Label>City</Form.Label>
							<Form.Control
								type="text"
								name="city"
								value={formData.city}
								onChange={handleInputChange}
							/>
						</Form.Group>

						<Form.Group
							controlId="formState"
							className="islander-address-form__field"
						>
							<Form.Label>Province</Form.Label>
							<Form.Control
								type="text"
								name="state"
								value={formData.state}
								onChange={handleInputChange}
							/>
						</Form.Group>

						<Form.Group
							controlId="formCountry"
							className="islander-address-form__field"
						>
							<Form.Label>Country</Form.Label>
							<Form.Control
								type="text"
								name="country"
								value={formData.country}
								onChange={handleInputChange}
							/>
						</Form.Group>

						<Form.Group
							controlId="formZipCode"
							className="islander-address-form__field"
						>
							<Form.Label>Zip code</Form.Label>
							<Form.Control
								type="text"
								name="zip"
								value={formData.zip}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</div>

					{/* <Button variant="primary" type="submit">
					Submit
				</Button> */}
				</Box>
			</div>
			</form>
		
		</Box>
	);
};

export default BaseIslanderAddressForm;
