import { useEffect, useState, useRef } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem, List } from '@mui/material';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Login from '../user/login/login';
import Request from '../customUtils/Http/Request';
import { protect } from '../commonFunctions/isValid';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { spacing } from '@mui/system';
import DashboardHeader from '../header/dashboardHeader';
import Sidebar from '../header/sidebar';
import { makeStyles } from '@material-ui/styles';
import Support from '../commonFunctions/support';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { sideBar } from '../redux/cartSlice'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DOMPurify from 'dompurify';
import StarRating from './starRating';
import { Diversity1Outlined } from '@mui/icons-material';
// import { BootstrapInput } from 'react-bootstrap';


const useStyles = makeStyles((theme) => ({
    dialogInner: {
        padding: "30px 30px",
    },
    dialogTitle: {
        padding: "10px 0px !important",
        lineHeight: "30px !important"
    },
    dialogContent: {
        padding: "10px 0px !important",
    },
    dialogBtnBox: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "5px"
    },
    dialogClose: {
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "var(--primary-color5)",
        cursor: "pointer"
    },
    dialogBtn: {
        minWidth: "190px !important",
        padding: "10px 0px !important"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
    //   toolbar: theme.mixins.toolbar,
}));


function Dashboard() {
    let login = checkLogin()
    const classes = useStyles();
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);
    const dispatch = useDispatch();
    const minimized = useSelector(state => state.cart.minimized);
    const [open1, setOpen1] = useState(false);
    const [buyInfo, setBuyInfo] = useState(false);
    const [islanderStatus1, setIslanderStatus1] = useState(null);
    const [reviewId, setReviewId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [reviewBox, setReviewBox] = useState(false);
    const [islanderStatusCount, setIslanderStatusCount] = useState(0);
    const [rating, setRating] = useState(0);
    const [reviewMsg, setReviewMsg] = useState('');
    const [ratingError, setRatingError] = useState(false);
    const hasActionBeenPerformedRef = useRef(false);
    const [reviewData, setReviewData] = useState({
        "title": '',
        "description": '',
        "rating": '',
    });

    const DatabaseResponseComponent = ({ htmlContent }) => {
        return (
            <div className="faq" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    };
    const DatabaseResponseComponent2 = ({ htmlContent }) => {
        return (
            <div className="faqContent" dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    };

    useEffect(() => {
        (async () => {
            let response = await Request.get("user/", [], true);
            {
                if (response) {
                    setUserName(response.firstName)
                }
                else {
                    console.log("error");
                    return
                }
            }
        })();
    }, []);

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        fetchIslanderData()
    }, []);

    const fetchData = async () => {
        try {
            // Fetch data from API
            const data = await Request.get("cart", [], true);
            console.log(data)
            if (data.cartItems) {
                if (data.cartItems.length > 0) {
                    setBuyInfo(true)
                    setIslanderStatusCount(islanderStatusCount + 1);
                }
                // else{
                //     setBuyInfo(false)
                //     setIslanderStatusCount(islanderStatusCount + 0)
                // }
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    useEffect(() => {
        if (islanderStatus1 !== null) {
            setIslanderStatusCount(islanderStatusCount + 1)
        }
    }, [islanderStatus1]);

    const fetchIslanderData = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                if (response.message === "Not found" && response.recipientAddress === null) {
                    setIslanderStatus1(null)
                }
                else {
                    if (response.message === "Not found" && response.recipientAddress !== null) {
                        setIslanderStatus1(0);
                        // setIslanderStatusCount(islanderStatusCount + 1);
                    }
                    else {
                        if (response) {
                            switch (response.orderStatus) {
                                case 0:
                                    setIslanderStatus1(null);
                                    break;
                                case 1:
                                    setIslanderStatus1(null);
                                    break;
                                case 2:
                                    setIslanderStatus1(null);
                                    break;
                                case 3:
                                    setIslanderStatus1(3);
                                    // setIslanderStatusCount(islanderStatusCount + 1);
                                    break;
                                case 6:
                                    setIslanderStatus1(6);
                                    // setIslanderStatusCount(islanderStatusCount + 1);
                                    break;
                                default:
                                    setIslanderStatus1(null);
                            }
                        }
                    }


                }
                // setRows(response.items.map((row) => ({ ...row, fromAPI: true, invoiceName: '' })));
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    }

    useEffect(() => {
        fetchReviewId();
    }, []);

    const fetchReviewId = async () => {
        try {
            let response = await Request.get(`review/checkReviewPending`, [], true);
            if (response.message !== false) {
                setReviewId(response.message)
                setReviewBox(true);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    useEffect(() => {
        fetchCategoryQuestions("FAQs")
    }, []);

    const fetchCategoryQuestions = async (category) => {
        try {
            let response = await Request.get(`utils/helpCentre/category/${category}`, []);
            if (response.length !== 0) {
                // const questionsWithPlainText = response.map((item) => ({
                //     ...item,
                //     answer: stripHtmlTags(item.answer),
                //   }));
                setQuestions(response);

            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    function stripHtmlTags(htmlString) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || '';
    }



    const capitalizeFirstLetter = (str) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    };

    const handleClose = () => {
        setOpen1(false);
    };
    const handleClickOpen1 = () => {
        setOpen1(true);
    };
    const handleReviewClose = () => {
        setReviewBox(false);
    };

    const handleReviewChange = (e) => {
        let { name, value } = e.target;

        if (name === "rating") {
            setRating(value);
            setRatingError(value === 0);
        }
        setReviewData({ ...reviewData, [name]: value });
    }

    const handleReviewSubmit = async (e) => {
        e.preventDefault();

        if (rating === 0) {
            setRatingError(true);
            return;
        } else {
            setRatingError(false);
        }

        const reviewDataInfo = {
            "title": reviewData.title,
            "rate": rating,
            "review": reviewData.description
        }

        try {
            let response = await Request.post(`review/add/${reviewId}`, reviewDataInfo, true);
            if (response.message === "Success") {
                setReviewMsg("Thank you for Your review")
                setTimeout(() => {
                    setReviewBox(false)
                }, 2000);
                setReviewData({
                    "title": '',
                    "description": '',
                })
                setRating(0)
            } else {
                setReviewMsg("Review is not added, Please try again..")
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }

    };



    return (
        <>


            <Box className="dashboardInner">
                <Box className="dashboardInnerHeader">
                    <Typography variant='h5'>Hello {capitalizeFirstLetter(userName)}, Welcome Back</Typography>
                    <Link to="/contact-us"><Typography>Need Help? Contact Us</Typography></Link>
                </Box>
                <Box>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8} className="gridBoxCol">
                            <Box className="gridBox">
                                <Box className="dashboardComponent" onClick={handleClickOpen1}>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={3} xl={1}>
                                            <img src="/assets/img/dash_img1.png"></img>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={9} xl={11} className="d-flex align-items-center center-box center-box">
                                            <Typography className="gridHeader">Action Required</Typography>
                                        </Col>
                                    </Row>
                                    <Box>
                                        <Typography className="gridContent">
                                            {/* {islanderStatusCount} Pending actions. */}
                                            <Box className="flexBoxDash">
                                                <Typography className="gridContent text-underline color4 font-600">{islanderStatusCount}</Typography><Typography className="gridContent">pending actions.</Typography>
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Link to="/user/islander/islander-send">
                                    <Box className="dashboardComponent">
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={3} xl={1}>
                                                <img src="/assets/img/dash_img2.png"></img>
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={9} xl={11} className="d-flex align-items-center center-box">
                                                <Typography className="gridHeader">Island Box Status</Typography>
                                            </Col>
                                        </Row>
                                        <Box>
                                            <Typography className="gridContent">Want to check the status of your islander box? Click here!</Typography>
                                        </Box>
                                    </Box>
                                </Link>

                                <Link to="/user/customer/profile">
                                    <Box className="dashboardComponent">
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={3} xl={1}>
                                                <img src="/assets/img/dash_img4.png"></img>
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={9} xl={11} className="d-flex align-items-center center-box">
                                                <Typography className="gridHeader">My Account</Typography>
                                            </Col>
                                        </Row>
                                        <Box>
                                            <Typography className="gridContent">Keep your information up to date.</Typography>
                                        </Box>
                                    </Box>
                                </Link>

                                <Link to="/user/customer/order-history">
                                    <Box className="dashboardComponent">
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={3} xl={1}>
                                                <img src="/assets/img/dash_img6.png"></img>
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={9} xl={11} className="d-flex align-items-center center-box">
                                                <Typography className="gridHeader">My Orders</Typography>
                                            </Col>
                                        </Row>
                                        <Box>
                                            <Typography className="gridContent">View and download your pending and completed order details.</Typography>
                                        </Box>
                                    </Box>
                                </Link>

                                <Link to="/user/customer/profile?tab=address">
                                    <Box className="dashboardComponent">

                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={3} xl={1}>
                                                <img src="/assets/img/dash_img7.png"></img>
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={9} xl={11} className="d-flex align-items-center center-box">
                                                <Typography className="gridHeader">My Address</Typography>
                                            </Col>
                                        </Row>
                                        <Box>
                                            <Typography className="gridContent">Edit, Delete your saved addresses </Typography>
                                        </Box>
                                    </Box>
                                </Link>
                                <Link to="/weeklyDeals">
                                    <Box className="dashboardComponent">
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={3} xl={1}>
                                                <img src="/assets/img/dash_img5.png"></img>
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={9} xl={11} className="d-flex align-items-center center-box">
                                                <Typography className="gridHeader">Exclusive Deals</Typography>
                                            </Col>
                                        </Row>
                                        <Box>
                                            <Typography className="gridContent">Don't miss out on the current deals and promotions available to you.</Typography>
                                        </Box>
                                    </Box>
                                </Link>

                            </Box>
                        </Col>


                        <Col xs={12} sm={12} md={12} lg={4} xl={4} className="gridBoxCol">
                            <Box className="dashboardFaq">
                                <Box sx={{ p: 4, pt: 3, pb: 3 }}>
                                    <Typography className="faqHeader">FAQ</Typography>
                                </Box>
                                <Box className="faqBox">
                                    {(questions && questions.length > 0) && questions.map((item) => (
                                        <Box key={item.id}>
                                            <hr className="hrLine"></hr>
                                            <Box sx={{ p: 4, pt: 2 }}>
                                                <DatabaseResponseComponent htmlContent={item.question} />
                                                <DatabaseResponseComponent2 htmlContent={item.answer} />
                                                {/* <Typography className="faq">{item.question}</Typography>
                                                            <Typography className="faqContent">{item.answer}</Typography> */}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>

                            </Box>
                        </Col>
                    </Row>
                </Box>
            </Box>

            <Dialog
                open={open1}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                align="left"
                className={classes.alertDialog}

            >

                <Box className={classes.dialogInner}>
                    <DialogTitle className={`${classes.dialogTitle} font-25 font-bold`} id="alert-dialog-title">
                        Required Actions
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {buyInfo === true && (
                            <>
                                <Typography className='font-18 color2 font-bold'>
                                    Buy Product Actions
                                </Typography>
                                <ul className='pt-1'>
                                    <Link to='/user/buy/buy-item-cart'>
                                        <li className='font-16 color4 text-underline'>Products Available in Cart.Go to Cart.</li>
                                    </Link>
                                </ul>
                            </>
                        )}
                        {/* {islanderStatus1 === 0 && (
                                                    <>
                                                        <Typography className='font-18 color2 font-bold'>
                                                            Islander Required Actions
                                                        </Typography>
                                                        <ul className='pt-1'>
                                                            <Link to='/user/islander/islander-order'>
                                                                <li className='font-16 color4 text-underline'>Recipient Address Added. Add Items and Complete Your Order.</li>
                                                            </Link>
                                                        </ul>
                                                    </>
                                                )} */}
                        {islanderStatus1 === 0 && (
                            <>
                                <Typography className='font-18 color2 font-bold'>
                                    Islander Required Actions
                                </Typography>
                                <ul className='pt-1'>
                                    <Link to='/user/islander/islander-order'>
                                        <li className='font-16 color4 text-underline'>Please Upload Invoice and Create Order.</li>
                                    </Link>
                                </ul>
                            </>
                        )}
                        {/* {islanderStatus1 === 2 && (
                                                    <>
                                                        <Typography className='font-18 color2 font-bold' >
                                                            Islander Required Actions
                                                        </Typography>
                                                        <ul className='pt-1'>
                                                            <Link to='/user/islander/islander-order-status'>
                                                                <li className='font-16 color4 text-underline'>Ordered Items Partially Received. Please Check the Status.</li>
                                                            </Link>
                                                        </ul>
                                                    </>
                                                )} */}
                        {islanderStatus1 === 3 && (
                            <>
                                <Typography className='font-18 color2 font-bold'>
                                    Islander Required Actions
                                </Typography>
                                <ul className='pt-1'>
                                    <Link to='/user/islander/islander-order-status'>
                                        <li className='font-16 color4 text-underline'>Space available in your islander box. Fill items or Proceed next!</li>
                                    </Link>
                                </ul>
                            </>
                        )}
                        {islanderStatus1 === 6 && (
                            <>
                                <Typography className='font-18 color2 font-bold'>
                                    Islander Required Actions
                                </Typography>
                                <ul className='pt-1'>
                                    <Link to='/user/islander/islander-shipment'>
                                        <li className='font-16 color4 text-underline'>Your order is ready to ship. Please Proceed to Payment.</li>
                                    </Link>
                                </ul>
                            </>
                        )}

                        {islanderStatus1 === null && buyInfo === false && (
                            <>
                                <Typography className='font-18 color2 font-bold'>
                                    No Action Available
                                </Typography>
                            </>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Box className={classes.dialogBtnBox}>
                            {/* <Button className={`${classes.dialogBtn} b1`} onClick={handleClose}>Back</Button> */}
                            <Button className={`${classes.dialogBtn} b1 b4`} autoFocus onClick={handleClose}>Ok</Button>
                        </Box>
                    </DialogActions>
                </Box>
                <CloseIcon className={classes.dialogClose} onClick={handleClose} />
            </Dialog>

            <Dialog
                open={reviewBox}
                onClose={handleReviewClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                align="left"
                className={classes.alertDialog}

            >

                <Box className={classes.dialogInner}>
                    <DialogTitle className={`${classes.dialogTitle} font-25 font-bold line-30`} id="alert-dialog-title">
                        {/* Add Review */}
                        Welcome back! We've recently completed your order, how did we do?
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Box align="left" className="supportFormBox">
                            <form onSubmit={handleReviewSubmit}>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" name="title" value={reviewData.title} onChange={handleReviewChange} required></Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" name="description" rows={10} value={reviewData.description} onChange={handleReviewChange} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Rating</Form.Label>
                                    <StarRating
                                        value={rating}
                                        name="rating"
                                        // onChange={handleReviewChange}
                                        onChange={(e, newValue) => {
                                            setRating(newValue);
                                            setRatingError(newValue === 0);
                                        }}
                                    />
                                    {ratingError && <p className="error-text">Please select a rating.</p>}
                                </Form.Group>
                                <Button sx={{ mt: 1 }} type="submit" className='b1'>Submit</Button>
                            </form>
                            {reviewMsg !== '' &&
                                <Typography className='font-15 color4' sx={{ pt: 1 }}>{reviewMsg}</Typography>
                            }
                        </Box>

                    </DialogContent>
                    {/* <DialogActions>
                                                <Box className={classes.dialogBtnBox}>
                                                    <Button className={`${classes.dialogBtn} b1 b4`} autoFocus onClick={handleClose}>Ok</Button>
                                                </Box>
                                            </DialogActions> */}
                </Box>
                <CloseIcon className={classes.dialogClose} onClick={handleReviewClose} />
            </Dialog>
            <Support />

        </>
    )
};


// const mapStateToProps = (state) => {
//     return {
//       minimized: state.sidebar.minimized,
//     };
//   };

export default Dashboard;


