import { useEffect, useRef, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Request from '../../customUtils/Http/Request';
import $ from 'jquery';
import { Container, Typography, Box, TextField, Button, Link, FormHelperText } from '@mui/material';
import Cookies from 'universal-cookie';
// import { } from 'react-bootstrap';
// import { checkLogin } from '../../commonFunctions/commonFunctions';
// import { Dashboard } from '@mui/icons-material';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import { checkLogin } from '../../commonFunctions/commonFunctions';
import Dashboard from '../../dashboard/dashboard';
import  ReCAPTCHA  from 'react-google-recaptcha';

const cookies = new Cookies();
const CssTextField = styled(TextField)({
  // '& label':{
  //   fontSize: '15px !important',
  //   fontWeight: 'bold !important',
  //   color: '#033D1E',
  // },
  '& label.Mui-focused': {
    color: '#033D1E',
    fontSize: '15px !important',
    fontWeight: 'bold !important',
    lineHeight: '25px !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#033D1E',
  },

  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    // "&.MuiOutlinedInput-notchedOutline": {
    //   fontSize: "14px",
    // },
    '&:hover fieldset': {
      borderColor: '#FFC72C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFC72C',
      borderWidth: '1px',
    },
  },
});

function EmailVerification() {

  let login = checkLogin();
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [recaptchaSize, setRecaptchaSize] = useState(windowWidth > 450 ? 'normal' : 'compact');
  const recaptchaRef = useRef();

  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = checkLogin();
    // If the user is logged in, redirect to the dashboard
    if (isLoggedIn) {
      // return <Navigate to="/dashboard" replace />;
      navigate('/user/customer/dashboard')
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setRecaptchaSize(window.innerWidth > 450 ? 'normal' : 'compact');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (event) => {
    setPassword(event.target.value);
    setError(!isValidPassword(event.target.value));
  };

  const isValidPassword = (value) => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordPattern.test(value);
  };

  const handleLoginSubmit = async (event) => {
      
    event.preventDefault();
    let inputField = event.target.getElementsByTagName('input');
    let otp = inputField[0],
      email = inputField[1],
      password = inputField[2],
      confirmPassword = inputField[3];


      if (password.value !== confirmPassword.value) {
        setTimeout(() => {
          $('#errorMessage').fadeIn('slow').delay(3000).hide(0);
        }, 1000);
        return
      }


      
    if(otp !== '' && email !== '' && password !== ''){
        var data= {
            "otp": otp.value,
            "password": password.value,
            "email":email.value,
            "captchaValue": recaptchaValue
        }
        try {
              
            const response = await Request.post(`user/reset`, data);
             
            if (response) {
                if(response.message === "Success"){
                    setMessage("password Reset Successfully.");
                    setRecaptchaValue('')
                    if (recaptchaRef.current) {
                      recaptchaRef.current.reset();
                    }
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1000); 
                }else{
                    setMessage(response.message)
                    setRecaptchaValue('')
                    if (recaptchaRef.current) {
                      recaptchaRef.current.reset();
                    }
                    return;
                }  
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
          setRecaptchaValue('')
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
            console.error('Error fetching data from API:', error);
        }
     
    }
    else{
        alert("Something Went Wrong")
      }
  }

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  return (
    <>
      {login === false ? (

<>
          <Header />
          <Container maxWidth="xl" className='homeContainer'>
            <Box className='middle_block' textAlign="center">
              <Box className='middle_inner_block'>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                  <Typography variant='h5' className='formTitle'>Verify Email</Typography>
                </Box>

                <Box className='login_form'
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                    mt: 3,
                  }}
                  // noValidate
                  autoComplete="off"
                  onSubmit={event => handleLoginSubmit(event)}
                >
                  <div>
                    <CssTextField fullWidth id="otp" floatinglabeltext="Otp" type="text" label="Otp" variant="outlined" className='text_field' required/>
                  </div>
                  <div>
                    <CssTextField fullWidth id="email" floatinglabeltext="Email" type="text" label="Email" variant="outlined" className='text_field' required/>
                  </div>
                    
                  <div>
                      {windowWidth > 450 ? (
                          <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="normal"
                          />
                        ):(
                          <ReCAPTCHA
                            ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_CAPCHA_KEY}
                              onChange={handleRecaptchaChange}
                              className="recaptcha_block"
                              size="compact"
                          />
                        )}
                    </div>

                  <div>
                    <Button variant="contained" type="submit" className='submitBtn login_Btn' disabled={!recaptchaValue} id="loginSubmitBtn">Verify Email</Button>
                  </div>

                    {/* <Box className="errorMessage" id="errorMessage">Password and Confirm Password does not match.</Box> */}
                    <Typography className="loginErrorMessage2" id="">{message}</Typography>
                </Box>
              </Box>
            </Box>
          </Container>
          <Footer />
        </>
      ) : (
        <>
          <Dashboard />
        </>
      )}
    </>
  );
}

export default EmailVerification;