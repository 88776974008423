import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Card } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import ProgressBar from '../commonComponents/progressbar';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "var(--primary-color2)",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "var(--primary-color8) !important"
    },
    activePath: {
        color: "var(--primary-color6) !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    orderStatusTableBody: {
        '& td': {
            fontSize: "16px !important",
            color: "var(--primary-color1) !important",
        }
    },
    tableHeader: {
        backgroundColor: 'var(--primary-color14)',
        '& th': {
            border: "none !important"
        }
    },
    tableContainer: {
        boxShadow: "none !important"
    },
    statusBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px"
    },
    videoBox: {
        margin: "0 auto",
        marginTop: "30px",
        borderRadius: "10px",
        boxShadow: "0px 3px 10px 2px rgba(0, 0, 0, 16%)",
        width: "480px !important",
        height: "280px"
    },
    emptyBox:{
        padding: "40px 0px",
        width: "450px",
        height: "350px",
        // objectFit: "cover",
        '& img': {
            maxWidth: "100%",
            objectFit: "cover",
            height: "100%"
        },
    },
    flexBoxBtnBox: {
        display: "flex",
        alignItems: "center",
        gap: "20px"
    },
    '@media (max-width: 1200px)': {
        videoBox: {
            width: "60% !important"
        }
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
        videoBox: {
            width: "80% !important"
        },
        flexBoxBtnBox:{
            display: "block",
            '& button' :{
                marginTop: "10px"
            }
        },
        emptyBox: {
            width: "100%"
        }
    },
    '@media (max-width: 600px)': {
        videoBox: {
            width: "100% !important"
        }
    },
}));

function IslanderOrderStatus() {
    const classes = useStyles();
    const [statusInfo, setStatusInfo] = useState([]);
    const [boxStatusActive, setBoxStatusActive] = useState(false);
    const [itemReceive, setItemReceive] = useState([]);
    const [itemId, setItemId] = useState('');
    const navigate = useNavigate(); 
    // const [fillPercentage, setFillPercentage] = useState();
    // const [fillPercentage2, setFillPercentage2] = useState(50);
    const fillPercentage = [100, 0, 0, 0];
    const fillPercentage2 = [100, 100, 0, 0];
    const fillPercentage3 = [100, 100, 50, 0];
    // const [fillPercentage3, setFillPercentage3] = useState(50);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                 
                if (response.status === 2) {
                    setBoxStatusActive(true)
                }
                setItemReceive(response)
                setItemId(response.id)
                const data = response.iCollectUserProducts;
                setStatusInfo(data)
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    useEffect(() => {
       if(itemReceive.status === 6){
            navigate('/user/islander/islander-shipment');
       }
    }, [itemReceive]);

    const productPack = async (id) => {
        try {
            // Fetch data from API
            const packStatus = {
                "status" : 5
            }
            const response = await Request.put(`admin/islander/iStatusUpdate/${id}`, packStatus , true);
            if (response.status === 200) {
                fetchData();
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    return (
        <Box className={classes.dashboardInner}>
            <Box>
                {boxStatusActive === true ? (
                    <Typography variant='h5' className='color1 font-bold'>Consolidation And Space Management</Typography>
                ) : (
                    <Typography variant='h5' className='color1 font-bold'>Your Items Delivery Status</Typography>
                )}
                <Box>
                        <>
                        {itemReceive.status === 0 && (
                            <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                        )}
                        </>
                        <>
                        {itemReceive.status === 2 && (
                            <ProgressBar totalChevrons={4} fillPercentage={fillPercentage2}/>
                        )}
                        </>
                        <>
                        {itemReceive.status === 3 && (
                            <ProgressBar totalChevrons={4} fillPercentage={fillPercentage3}/>
                        )}
                        </>
            {/* </>
                        )
                    } */}
                </Box>
            </Box>
            <Box className='cartMainContainer'>
                <Box className="gridInnerBlockLeft">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
                            {boxStatusActive === true && (
                                <Typography className='color2 font-bold font-20' sx={{ mb: 2 }}>Your order delivery status</Typography>
                            )}

                        {(itemReceive.status === 0 || itemReceive.status === 1) && (
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className={classes.tableHeader}>
                                            <TableCell align="center" style={{ minWidth: 200 }} className='color1 font-16 font-bold'>Company Name</TableCell>
                                            <TableCell align="center" style={{ minWidth: 150 }} className='color1 font-16 font-bold'>Tracking Id</TableCell>
                                            {/* {boxStatusActive === true && ( */}
                                                <TableCell align="center" style={{ minWidth: 150 }} className='color1 font-16 font-bold'>Invoice</TableCell>
                                            {/* )} */}
                                           
                                            {boxStatusActive === false && (
                                                <TableCell align="center" style={{ minWidth: 150 }} className='color1 font-16 font-bold'>Quantity Ordered</TableCell>
                                            )}
                                            <TableCell align="center" style={{ minWidth: 150 }} className='color1 font-16 font-bold'>Quantity Received </TableCell>
                                            <TableCell align="center" style={{ minWidth: 200 }} className='color1 font-16 font-bold'>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.orderStatusTableBody}>
                                        {(statusInfo && statusInfo.length>0) && statusInfo.map((row) => (
                                            <TableRow
                                                key={row.id}
                                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell scope="row" align='center'>{row.url}</TableCell>
                                                <TableCell align="center">{row.trackingNumber}</TableCell>
                                                {/* {boxStatusActive === true && ( */}
                                                    {/* <TableCell align="center" className='color1 font-16 font-bold'>{row.invoice}</TableCell> */}
                                                    <TableCell align="center" className='color1 font-16 font-bold'>
                                                         <a href={`${process.env.REACT_APP_DatabaseStatic}${row.invoice}`} target="_blank">
                                                            <Typography className='color4 font-16 text-underline font-bold invoiceName cursor-pointer'>View Invoice
                                                            </Typography>
                                                        </a>
                                                    </TableCell>
                                                {/* )} */}
                                                {boxStatusActive === false && (
                                                    <TableCell align="center">{row.orderedQuantity}</TableCell>
                                                )}

                                                <TableCell align="center">{row.receivedQuantity}</TableCell>
                                                <TableCell align="center">
                                                    {row.status === false ? (
                                                        <Box className={classes.statusBox}>
                                                            <img src="/assets/img/orderPending.png"></img>
                                                            <Typography className='color1 font-16'>Awaiting Items</Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box className={classes.statusBox}>
                                                            <img src="/assets/img/orderReceive.png"></img>
                                                            <Typography className='color1 font-16'>Received</Typography>
                                                        </Box>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                            {(itemReceive.status === 2 || itemReceive.status === 5 || itemReceive.status === 4) && (
                                <Box sx={{ pt: 5 }}>
                                    {itemReceive.status === 4 ? (
                                        <Typography className='font-22 font-bold color3'>Your request to add more items in the islander box is received. We will let you know when your islander box is ready to ship!</Typography>
                                    ):(
                                        <Typography className='font-18 font-bold color3'>All your items have been received at our base. We have started consolidating your items in a box.</Typography>
                                    )}
                                   
                                    <Box>
                                        <CardMedia
                                            component="video"
                                            src="/assets/video/boxFillAnimation.mp4" // Replace this with your actual video URL
                                            controls // This enables video controls (play, pause, etc.)
                                            title="Box"
                                            className={classes.videoBox} // Set the desired height for the video
                                        />
                                    </Box>
                                </Box>
                            )}

                            {(itemReceive.status === 7 || itemReceive.status === 8) && (
                                <Box sx={{ pt: 5,pb: 5, maxWidth: 700, border: "1px solid lightgray", borderRadius: "20px" }} align='center'>
                                    <Typography className='font-22 font-bold color2' sx={{ pb: 3 }}>Your Order Has Been Received</Typography>
                                    <Box sx={{ pb: 3 }} align='center'>
                                        <img src="/assets/img/orderIcon.png" alt='order_icon' className='order_img'></img>
                                    </Box>
                                    <Typography className='font-22 color2' sx={{ pb: 2 }}>Thanks For Your Order!!</Typography>
                                    <Typography className='font-16 color3'>Once your Order has been Shipped, You can Create a new Islander Order.</Typography>
                                </Box>
                            )}

                            {itemReceive.status === 3 && (
                                <Box sx={{ pt: 5 }}>
                                    <Typography className='font-18 font-bold color2'>All your items have been received.</Typography>
                                    <Typography className='font-16 color3'>There is additional space available in your islander box.You have the option to add more items from our inventory. </Typography>
                                    <Box className={classes.emptyBox}>
                                       <img src={`${process.env.REACT_APP_DatabaseStatic}${itemReceive.boxImage}`} alt="UserBox"></img>
                                    </Box>
                                    <Box className={classes.flexBoxBtnBox}>
                                        <Link to={`/user/islander/islander-fill-item/${itemId}`}>
                                            <Button className='b1 mx-1' >Let’s fill the box fully</Button>
                                        </Link>
                                        <Button className='b1 b4 mx-1' onClick={() => productPack(itemId)}>No, I don’t want to add anything. Ship my box!</Button>
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default IslanderOrderStatus;
