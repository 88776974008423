import { useEffect, useState  } from 'react';
import { Routes, Route, Switch, Outlet } from 'react-router-dom';
import { Typography, Box, Container, Button, FormControl, InputLabel, Input, Grid, Select, MenuItem } from '@mui/material';
import Cookies from 'universal-cookie';
import { checkLogin, checkUserType } from '../commonFunctions/commonFunctions';
import Sidebar from '../header/sidebar';
import DashboardHeader from '../header/dashboardHeader';
import Support from '../commonFunctions/support';
import { connect } from 'react-redux';
import SendProduct from '../product/sendProduct';
import SendCheckout from '../checkout/sendCheckout';
import SendPayment from '../checkout/sendPayment';
import SendOrderDetail from '../checkout/sendOrderDetail';
import Login from '../user/login/login';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../dashboard/dashboard';
import OrderHistory from '../checkout/orderHistory';
import ShippingEstimator from '../dashboard/ShippingEstimator';
import Profile from '../dashboard/Profile';
import MyAddresses from '../dashboard/myaddresses';
import PaypalPayment from '../commonFunctions/paypalPayment';
import PageNotFound from './pageNotFound';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginLeft: 'auto',
    boxSizing: 'border-box',
    marginRight: 'auto',
    display: 'block',
    padding: '16px', // Default padding for larger screens
    '@media (max-width: 600px)': {
        paddingLeft: '5px !important',
        paddingRight: '5px !important'
  },
  },
}));

function UserStructure() {
  // const [userType, setUserType] = useState(null);
  const classes = useStyles();
  let login = checkLogin();
  let userType = checkUserType();
  const minimized2 = useSelector(state => state.cart.minimized);
  if(window.innerWidth < 992){
    var minimized = !minimized2
  }else{
    var minimized = minimized2
  }

  // useEffect(() => {
  //     
  //   let userType = cookies.get('userType', { path: '/' });
  //   const userTypeAsNumber = parseInt(userType, 10);
  //   setUserType(userTypeAsNumber)
  // }, []);


  return (
    <>
     {(login === true && userType && userType === 3) ? ( 
        <Box className="main">
            <Sidebar />
            <Box className={`orderContainer ${minimized ? 'dashContainer' : 'containerExpand'}`}>
                <DashboardHeader />
                <Container maxWidth="l" className={classes.container}>
                <Outlet />
                    <Routes>
                        <Route path="/*" element={<PageNotFound />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/order-history" element={<OrderHistory />} />
                        <Route path="/track-package" element={<OrderHistory />} />
                        <Route path="/shipping-estimator" element={<ShippingEstimator />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/my-addresses" element={<MyAddresses />} />
                        <Route path="/paypal-payment" element={<PaypalPayment />} />
                    </Routes>
                </Container>
            </Box>
            <Support />
        </Box>
     ) : (
        <Login />
     )}
    </>
  )
}


export default UserStructure;

