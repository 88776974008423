import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Avatar,
} from "@mui/material";
import DriverModal from "./DriverModal";
import Request from '../customUtils/Http/Request';

const DriverTable = ({driverInfo , type}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedDriver, setSelectedDriver] = useState(null);
	const [selectedDriverId, setSelectedDriverId] = useState(null);

	const handleOpenModal = (driver, id) => {
		setSelectedDriver(driver);
		setSelectedDriverId(id);
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedDriver(null);
		setModalOpen(false);
	};

	return (
		<div className="order-table-container driver-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Photo</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Email Address</TableCell>
							<TableCell>Phone</TableCell>
							<TableCell>Vehicle</TableCell>
							<TableCell>Vehicle No.</TableCell>
							<TableCell>License Photo</TableCell>
							<TableCell>Driver's ID</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{driverInfo && driverInfo.length>0 ? (
							<>
							{driverInfo.map((driver) => (
							<TableRow key={driver.id}>
								<TableCell>
								
									<Avatar
										alt={driver.user?.fullName}
										src={driver.user?.img === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driver.user.img}` }
										variant="rounded"
										sx={{ width: 36, height: 36 }}
										className="center-avatar"
									/>
								</TableCell>
								<TableCell>
									<span
										onClick={() => handleOpenModal(driver, driver.id)}
										className="driver-table__name cursor-pointer font-bold text-underline"
									>
										{driver.user?.fullName}
									</span>
								</TableCell>
								<TableCell>{driver.user?.email}</TableCell>
								<TableCell>{driver.user?.phone}</TableCell>
								<TableCell>{driver.vehicle}</TableCell>
								<TableCell>{driver.vehicleRegNo}</TableCell>
								<TableCell>
									<Avatar
										alt="License"
										src={driver.driverLicense === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driver.driverLicense}` }
										variant="rounded"
										sx={{ width: 42, height: 42 }}
										className="center-avatar"
									/>
								</TableCell>
								<TableCell>
									<Avatar
										alt="Driver ID"
										src={driver.driverIdentification === null ?  "/assets/img/driver-placeholder.jpg" : `${process.env.REACT_APP_DatabaseStatic}${driver.driverIdentification}` }
										variant="rounded"
										sx={{ width: 42, height: 42 }}
										className="center-avatar"
									/>
								</TableCell>
							</TableRow>
						))}
							</>
						):<></>}
						
					</TableBody>
				</Table>
			</TableContainer>

			{driverInfo && driverInfo.length !== 0 && (
				<DriverModal
				isOpen={modalOpen}
				handleClose={handleCloseModal}
				driver={selectedDriver}
				driverInfo={driverInfo} type={type} driverId={selectedDriverId}
			/>
			)}
			
		</div>
	);
};

export default DriverTable;
