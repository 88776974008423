import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, FormHelperText, } from '@mui/material';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import ProgressBar from '../commonComponents/progressbar';
import { useDispatch, useSelector } from "react-redux";
import { fetchIslanderList } from "../redux/islanderListSlice";

const cookies = new Cookies();
const countryOptions = ["Anitigua and Barbuda", "Barbados", "Jamaica","Grenada","Guyana","Saint Vincent","Trinidad and Tobago","Saint Lucia","Dominica"];

const useStyles = makeStyles((theme) => ({
    dashboardInner: {
        marginTop: "80px",
        padding: "35px",
    },
    headerFlexBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingTop: "2px"
    },
    doubleArrow: {
        color: "#FFC72C",
        fontSize: "10px"
    },
    pathName: {
        fontSize: "14px !important",
        color: "#727272 !important"
    },
    activePath: {
        color: "#033D1E !important"
    },
    formPadding: {
        marginTop: "12px"
    },
    '@media (max-width: 992px)': {
        dashboardInner: {
            padding: "10px !important",
        },
    },
}));



function IslanderSendBox() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [addresses, setAddresses] = useState({
        "shippingAddress": {
            "firstName": "",
            "lastName": "",
            "address1": "",
            "address2": "",
            "phone": "",
            "email": "",
            "city": "",
            "zip": "",
            "country": ""
        },
       
        // "senderAddress": {
        //     "firstName": "",
        //     "lastName": "",
        //     "address1": "",
        //     "address2": "",
        //     "phone": "",
        //     "email": "",
        //     "city": "",
        //     "state": "",
        //     "zip": "",
        //     "country": ""
        // },
    });
    const dispatch = useDispatch();
    const islanderShippingList = useSelector((state) => state.islanderList.islanderList);
    const [editParam, setEditParam] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const fillPercentage = [33.33, 0, 0, 0];

    useEffect(() => {
        dispatch(fetchIslanderList());
    }, [dispatch]);

    
    useEffect(() => {
        dispatch(fetchIslanderList());
    }, []);


    const handleInputChange = (e, addressType) => {
        let { name, value } = e.target;

        if(name === "phone"){
            value = value.replace(/[^0-9]/g, '');
            if(value.length === 10){
                setPhoneError(false)
            }
            else{
                setPhoneError(true)
            }
        }
        setAddresses((prevState) => ({
            ...prevState,
            [addressType]: {
                ...prevState[addressType],
                [name]: value,
            },
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(addresses.shippingAddress.phone.length !== 10){
            alert("Please enter 10 digit contact number.")
        }
        else{
            let response = await Request.post("islander/iAddShippingAddress/", addresses, true)
            {
                if(response.message == "Success"){
                    navigate('/user/islander/islander-order')
                }
            }
            if (!response) {
                console.log('something went wrong');
            }
            fetchData();
        }
       
    }

    const fetchData = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/iShipmentCreated/", [], true);
            if (response) {
                const urlParams = new URLSearchParams(window.location.search);
                const editParam = urlParams.get('edit');
                 
                if(editParam === 'true' && response.recipientAddress !== null){
                    navigate('/user/islander/islander-send?edit=true')
                        // const mappedData = response.map(address => ({
                        //     shippingAddress: {
                        //         firstName: address.first_name,
                        //         lastName: address.last_name,
                        //         address1: address.address_line_1,
                        //         address2: address.address_line_2,
                        //         phone: address.phone,
                        //         email: address.email,
                        //         city: address.city,
                        //         zip: address.zip,
                        //         country: address.country,
                        //     }
                        // }));

                        // console.log(mappedData)
                        // setAddresses(mappedData);
                }
                else {
                    if(response.message === "Not found" && response.recipientAddress === null){
                        navigate('/user/islander/islander-send')
                    }
                    else{
                        if(response.recipientAddress !== null && response.message === "Not found"){
                            navigate('/user/islander/islander-order')
                        }
                        else{
                            switch (response.status) {
                                case 0:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 1:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 2:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 3:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 4:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                case 5:
                                    return navigate('/user/islander/islander-order-status');
                                break;
                                case 6:
                                    return navigate('/user/islander/islander-shipment');
                                    break;
                                case 7:
                                    return navigate('/user/islander/islander-order-status');  
                                    break;
                                case 8:
                                    return navigate('/user/islander/islander-order-status');
                                    break;
                                default:
                                    return;
                              }       
                        }             
                    }
                }
              
                // setRows(response.items.map((row) => ({ ...row, fromAPI: true, invoiceName: '' })));
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const fetchAddressData = async () => {
        try {
            // Fetch data from API
            const response = await Request.get("islander/getAddresses/", [], true);
            if (response) {
                // const data = await response.json();
                const urlParams = new URLSearchParams(window.location.search);
                const editParam = urlParams.get('edit');
                if(response){
                    if(response.length === 0){
                        navigate('/user/islander/islander-send')
                    }
                    if(response.length === 1){
                        navigate('/user/islander/islander-order')
                    }
                    if(editParam === 'true' && response.length === 1){
                        navigate('/user/islander/islander-send?edit=true')
                    };
                    
                  
                    // if(editParam === 'true' && response.length === 1){
                    //     const mappedData = response.map(address => ({
                    //         shippingAddress: {
                    //             firstName: address.first_name,
                    //             lastName: address.last_name,
                    //             address1: address.address_line_1,
                    //             address2: address.address_line_2,
                    //             phone: address.phone,
                    //             email: address.email,
                    //             city: address.city,
                    //             zip: address.zip,
                    //             country: address.country,
                    //         }
                    //     }));

                    //     setAddresses(mappedData);
                    //     // navigate('/user/islander/islander-send?edit=true')

                    // }
                }
               else{
                    fetchData()   
                }
                // setRows(response.items.map((row) => ({ ...row, fromAPI: true, invoiceName: '' })));
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    useEffect(() => {
        //   
        // const urlParams = new URLSearchParams(window.location.search);
        // const editParam = urlParams.get('edit');

        // if (editParam === 'true') {
        //       
        //     fetchAddressData();
        // } else {
            // fetchAddressData();
            fetchData();
        // }      
    }, []);

    useEffect(() => {
        fetchDefaultAddress()
      }, []);
    
      const fetchDefaultAddress = async () => {
              
            // setIsDialogOpen(true);
                try {
            const response = await Request.get(`user/getDefaultAddresses`, [], true);
            if (Object.keys(response).length > 0) {
                
                response.recipientAddresses.forEach((address) => {
                  var dataFirst_name = address.first_name;
                  var dataLast_name = address.last_name;
                  var dataAddress_line_1 = address.address_line_1;
                  var dataAddress_line_2 = address.address_line_2;
                  var dataCity = address.city;
                  var dataState = address.state;
                  var dataCountry = address.country;
                  var dataEmail = address.email;
                  var dataPhone = address.phone;
                  var dataZip = address.zip;
               
                  
                setAddresses((prevAddresses) => ({
                  ...prevAddresses,
                  shippingAddress: {
                    firstName: dataFirst_name,
                    lastName: dataLast_name,
                    address1: dataAddress_line_1,
                    address2: dataAddress_line_2,
                    city: dataCity,
                    state: dataState,
                    zip: dataZip,
                    country: dataCountry,
                    email: dataEmail,
                    phone: dataPhone
                  },    
                }));
              });
              }
              else{
                console.log(response.message)
              }
                    }
            catch (error) {
                      console.error('Error deleting row from API:', error);
                }
        };

    return (
        <Box className={classes.dashboardInner}>
            <Box>
                <Typography variant='h5' className='color1 font-bold'>Send Islander Box</Typography>
                {/* <Box className={classes.headerFlexBox}>
                    <Typography className={`${classes.pathName} ${classes.activePath}`}>Step 1</Typography>
                    <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
                </Box> */}
                <Box>
                    <ProgressBar totalChevrons={4} fillPercentage={fillPercentage}/>
                </Box>
                
            </Box>
            <Box className='cartMainContainer'>
                <Box className="gridInnerBlockLeft">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                        <form className='checkoutForm' onSubmit={handleSubmit}>
                                <Box className="">
                                    <Typography align="left" className='font-20 color2 font-bold'>Recipient Address</Typography>

                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Country</Form.Label>
                                        {/* <Form.Control value={addresses.shippingAddress.country} readOnly/> */}
                                        <Form.Select
                                            name="country"
                                            value={addresses.shippingAddress.country || ''}
                                            required
                                            onChange={(e) => handleInputChange(e, "shippingAddress")}
                                        >
                                            <option value="">Select country</option>
                                            {(islanderShippingList && islanderShippingList.length>0) && islanderShippingList.map((country) => (
                                                <option key={country.country} value={country.country}>
                                                    {country.country}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name" name="firstName" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.firstName} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name" name="lastName" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.lastName} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address1" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.address1} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address2" onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.address2} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Contact No</Form.Label>
                                                <Form.Control type="phone" placeholder="phone" name="phone" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.phone} />
                                                {phoneError && <Typography className="errorMsg" sx={{pt : 3, mb: 0}}>Please enter 10 digit contact number</Typography>}
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="phone" placeholder="Email" name="email" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.email} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                   
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>City/Town</Form.Label>
                                                <Form.Control type="text" placeholder="City" name="city" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.city} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control type="text" placeholder="Zip Code" name="zip" required onChange={(e) => handleInputChange(e, "shippingAddress")} value={addresses.shippingAddress.zip} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    {/* <Form.Group className={classes.formPadding}>
                                          
                                    </Form.Group> */}
                                    {/* <Form.Group className={classes.formPadding}>
                                        <Row>
                                           
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                
                                            </Col>
                                        </Row>
                                    </Form.Group> */}
                                </Box>
                                {/* <Box sx={{ pt: 5 }}>
                                    <Typography align="left" className='font-22 color2 font-bold'>Sender Address</Typography>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name" name="firstName" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.firstName} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name" name="lastName" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.lastName} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 1</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address1" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.address1} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Form.Label>Address Line 2</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="address2" onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.address2} />
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Contact No</Form.Label>
                                                <Form.Control type="phone" placeholder="phone" name="phone" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.phone} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="phone" placeholder="Email" name="email" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.email} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>City/Town</Form.Label>
                                                <Form.Control type="text" placeholder="City" name="city" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.city} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control type="text" placeholder="Country" name="country" required onChange={(e) => handleInputChange(e, "senderAddress")}  value={addresses.senderAddress.country} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className={classes.formPadding}>
                                        <Row>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Province</Form.Label>
                                                <Form.Control type="text" placeholder="State" name="state" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.state} />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control type="text" placeholder="Zip Code" name="zip" required onChange={(e) => handleInputChange(e, "senderAddress")} value={addresses.senderAddress.zip} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    
                                </Box> */}
                                <Box align="left" sx={{ pt: 5 }}>
                                        <Button type="submit" className='b1'>Next</Button>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default IslanderSendBox;
