import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Request from '../customUtils/Http/Request';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const baseURL = "http://127.0.0.1:4000/api/stripe"

const stripePromise = loadStripe('pk_live_51Njq0FINevRiy9hIzn9dDVerXRCLVIViDbDMokLHdFwAin6f70eM3EiOJA0co7IOjay1kQnNbnQYIBdEpjtruPbD00jpKfgNvO');

const cookies = new Cookies();

let PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjkxYjYzMWE2LTY3ZGUtNDRmYy04ZTFmLTQ0ODQ4ODZmODgxMiIsImVtYWlsIjoiaGV4YWNvZGVydGVzdEBnbWFpbC5jb20iLCJuYW1lIjoiSGV4YSIsInBob25lIjoxMjM0LCJpYXQiOjE2ODY4MjE4MjMsImV4cCI6MTY4NjgyMzYyM30.Epabfg-wRyjS8bP0zlo25nOk3hjrrSIhPID2hH8jNyE";

  const [errorMessage, setErrorMessage] = useState(null);

  var orderId = props.setOrderIdElement.orderId;
  var orderType = props.orderType;
  var invoiceNo = props.invoiceNo;

  const handleSubmit = async (event) => {
    debugger
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (orderType === "Send") {
      var return_url = 'https://www.isleoffreight.com/user/send/send-order-detail'
    }
    if (orderType === "Purchase") {
      var return_url = `https://www.isleoffreight.com/user/buy/order-detail`
    }
    if (orderType === "Islander") {
      var return_url = 'https://www.isleoffreight.com/user/islander/islander-order-detail'
    }

    // if (orderType === "Send") {
    //   var return_url = 'http://localhost:3000/user/send/send-order-detail'
    // }
    // if (orderType === "Purchase") {
    //   var return_url = 'http://localhost:3000/user/buy/order-detail'
    // }
    // if (orderType === "Islander") {
    //   var return_url = 'http://localhost:3000/user/islander/islander-order-detail'
    // }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: "http://localhost:3000/user/orderdetail"
        return_url: `${return_url}?orderId=${orderId}&invoiceNo=${invoiceNo}`
      },
    });



    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button disabled={!stripe} type="submit" className="b1 b2">Pay Now</Button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
};

export default PaymentForm;
