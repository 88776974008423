import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { connect } from 'react-redux';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import { Col, Row } from 'react-bootstrap';
import Request from '../../customUtils/Http/Request';
import { useDispatch, useSelector } from "react-redux";
import { fetchHelpCategories, fetchPopularQuestion } from '../../redux/helpCenterSlice';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import HelpCenterCategory from './helpCenterCategory';

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: "#fff",
        borderRadius: "25px",
        boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
        padding: "50px 15px",
        textAlign: "center",
        transition: "all 0.3s ease",
        '&:hover': {
            transform: 'scale(1.03)',
            zoom: '1',
            border: "2.5px solid var(--primary-color5) !important"
        }
        // flexGrow: 1,
    },
    listItem: {
        paddingLeft: "0px !important",
        paddingBottom: "0px !important"
    },
    listIcon: {
        minWidth: "40px !important",
    },
    listText:{
        "& span" : {
        fontSize: "18px !important",
        }
    }
}));

const DatabaseResponseComponent = ({ htmlContent }) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    );
  };
  
// export default DatabaseResponseComponent;

const HelpCenterQuestions = (props) => {
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const [questions, setQuestions] = useState([]);
    const [category, setCategory] = useState('');
    const [loading, setLoading] = useState(true);


    useEffect(() => {
          
        const searchParams = new URLSearchParams(location.search);
        const categoryname = searchParams.get('category');
       
        fetchCategoryQuestions(categoryname);
    }, []);

    useEffect(() => {
          
        const searchParams = new URLSearchParams(location.search);
        const categoryname = searchParams.get('category');
        // setCategory(categoryname)   
        fetchCategoryQuestions(categoryname);
    }, [location.search]);

       const fetchCategoryQuestions = async (category) => {
        try {
              
            let response = await Request.get(`utils/helpCentre/category/${category}`,[]);
                if (response.length !== 0) {
                setQuestions(response);
                setCategory(category)  
                setLoading(false);
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
            setLoading(false);
        }
    };

    if (loading) {
        return  <div className="loader-overlay">
            <div className="loader">
            </div>
        </div>;
      }

    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
                <Box className="MuiBox-root css-pxpt32" textAlign="center">
                    <Box sx={{pt: 5, pb: 7,'@media (max-width: 767px)': {pt: 3, pb: 3},}} textAlign="left">
                        <Link to="/help-center">
                            <Typography className='font-22 color4 pb-5 text-underline'>
                                <ArrowBackOutlinedIcon sx={{mr:1}}/> Back to Help Center
                            </Typography>
                        </Link>
                       <Grid container spacing={2}>
                            <HelpCenterCategory category={category}/>
                            <Grid item xs={12} md={9} lg={10}>
                                <Paper>
                                    <Box textAlign="left" sx={{p: 4,'@media (max-width: 767px)': {p: 2},}}>
                                            <Typography className='font-30 font-bold color2 mb-2'>{category}</Typography>
                                            <List>
                                                {(questions && questions.length>0) && questions.map((question) => (
                                                    <div key={question.id}>
                                                        <Link to={`/help-center/answer?category=${question.category}&id=${question.id}`} className='color1'>
                                                            {/* <ListItem key={question.id} className={classes.listItem} onClick={() => handleQuestionClick(question.id)}> */}
                                                            <ListItem key={question.id} className={classes.listItem}>
                                                                <ListItemIcon className={classes.listIcon}>
                                                                    <DescriptionOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={question.question} className={classes.listText}/>
                                                            </ListItem> 
                                                        </Link>
                                                    </div>
                                                ))}
                                            </List>   
                                    </Box>
                                </Paper>
                            </Grid>
                       </Grid>
                    </Box>
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default HelpCenterQuestions;