import { useEffect, useState } from 'react';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';


function OrderInfoBox({change}) {
    return (
        <Grid item xs={12} sm={12} md={change} lg={change}>
            <Box className="gridInnerBlockRight">
                <Typography align="left" className='font-22 color2 font-bold'>
                    {change === 4 ? "About Your Islander Address" : ""}
                </Typography>
                    <Box className="pt-15">
                        <ul>
                            <li className="pb-10">Before placing an order, please check our list of prohibited items.</li>
                            <li className="pb-10">Ship all items to the provided address for processing by our team. You'll receive a notification once your item reaches our facility.</li>
                            <li className="pb-10">Note: A $2.50 service charge applies to packages without the client ISL#.</li>
                            <li className="pb-10">If the vendor restricts ISL# in the name field, input it on the second address line.</li>
                            <li>Transit times to Alberta vary based on vendor location.</li>
                        </ul>
                    </Box>
            </Box>
        </Grid>
    );
}

export default OrderInfoBox;
