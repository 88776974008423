import { useEffect, useState, useRef } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Col, Row, Form, Dropdown, } from 'react-bootstrap';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Login from '../user/login/login';
import { checkLogin } from '../commonFunctions/commonFunctions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import { Label } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Request from '../customUtils/Http/Request';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from "react-redux";
import { fetchPickupList } from "../redux/pickupLocationSlice";
import { FormatPrice } from '../commonFunctions/commonPriceFunction';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  productRow: {
    paddingTop: "20px",
  },
  productCol: {
    display: "flex",
    alignItems: "center"
  },
  grid: {
    paddingLeft: "100px !important"
  },
  headerFlexBox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    paddingTop: "2px"
  },
  doubleArrow: {
    color: "#FFC72C",
    fontSize: "10px"
  },
  pathName: {
    fontSize: "14px !important",
    color: "#727272 !important"
  },
  activePath: {
    color: "#033D1E !important"
  },
  totalHeader: {
    borderBottom: "1px solid #E2E2E2"
  },
  formPadding: {
    marginTop: "12px"
  }
}));

function Checkout() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [value, setValue] = useState(null);
  const [cartInfo, setCartInfo] = useState([]);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [clientSecret, setClientSecret] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [guestSignupToggle, setGuestSignupToggle] = useState(false);
  const [password, setPassword] = useState('');
  const [pError, setpError] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [signupEmail, setSignupEmail] = useState(false);
  const [emailCheckText, setEmailCheckText] = useState('');
  const [loginCheck, setLoginCheck] = useState(false);
  const [isEmailTaken, setIsEmailTaken] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(true);
  const [cities, setCities] = useState([]);
  const [stateDisabled, setStateDisabled] = useState(true);
  const [checkoutError, setCheckoutError] = useState(false);
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();
  const [allCountries, setAllCountries] = useState([
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Democratic Republic of the Congo (Congo-Kinshasa)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    ],
)

  const [addresses, setAddresses] = useState({
      shippingAddress: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      },
      billingAddress: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      },
      billingBoolean: true,
      isGuestUser: true,
      guestUser: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: ''
      },
      cartItems: null,
    });

  const pickupLocationList = useSelector((state) => state.pickupList.pickupListLocation);

  let login = checkLogin()

  // Create a ref to store the latest addresses state
  const addressesRef = useRef(addresses);

  // Update the addressesRef whenever the addresses state changes
  useEffect(() => {
    addressesRef.current = addresses;
  }, [addresses]);

  useEffect(() => {
		dispatch(fetchPickupList());
	}, []);

	useEffect(() => {
		dispatch(fetchPickupList());
	}, [dispatch]);

  useEffect(() => {
    if(login === true){
      fetchShppingDefaultAddress()
      getUserData()
    }

  }, []);

  const fetchShppingDefaultAddress = async () => {
		  
		// setIsDialogOpen(true);
			try {
				const response = await Request.get(`user/getDefaultAddresses`, [], true);
				if (Object.keys(response).length > 0) {
            
            response.shippingAddresses.forEach((address) => {
              var dataFirst_name = address.first_name;
              var dataLast_name = address.last_name;
              var dataAddress_line_1 = address.address_line_1;
              var dataAddress_line_2 = address.address_line_2;
              var dataCity = address.city;
              var dataState = address.state;
              var dataCountry = address.country;
              var dataZip = address.zip;
           
              if(dataCity){
                fetchCitiesForState(dataState);
                setCityDisabled(false)
            }
              
            setAddresses((prevAddresses) => ({
              ...prevAddresses,
              shippingAddress: {
                firstName: dataFirst_name,
                lastName: dataLast_name,
                address1: dataAddress_line_1,
                address2: dataAddress_line_2,
                city: dataCity,
                state: dataState,
                zip: dataZip,
                country: dataCountry,
              },
            }));
          });
          }
          else{
            console.log(response.message)
          }
				}
        catch (error) {
				  console.error('Error deleting row from API:', error);
			}
	};

    const getUserData = async () => {
    try {
      const response = await Request.get(`user/`, [], true);
      if (response) {
         
        setAddresses((prevAddresses) => ({
          ...prevAddresses,
          guestUser: {
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            phone: response.phone,
          },
        }));
      } else {
        console.error('Failed to fetch data from API');
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
    };


  useEffect(() => {
    (async () => {
      if (login === true) {
          
        try {
          // Fetch data from API
          const data = await Request.get("cart", [], true);
          if (data) {
            setCartInfo(data.cartItems);
            setCartTotalPrice(data.totalCartPrice);
          } else {
            console.error('Failed to fetch data from API');
          }
        } catch (error) {
          console.error('Error fetching data from API:', error);
        }
      }
      else {
        const cartItems = cookies.get('cartItems', {path: "/"});
        const cartPrice = cookies.get('totalCartPrice', {path: "/"});
        const totalCartPrice = parseFloat(cartPrice);
        setCartInfo(cartItems.cartItems);
        setCartTotalPrice(totalCartPrice);
      }
    })();
}, []);


  const handleBillingCheckboxChange = () => {
    setAddresses((prevAddresses) => ({
      ...prevAddresses,
      billingBoolean: !prevAddresses.billingBoolean,
      billingAddress: prevAddresses.billingBoolean
        ? { ...prevAddresses.shippingAddress }
        : { ...prevAddresses.billingAddress }
    }));
  };

  const handleChangeShippingAddress = (field, value) => {
    if(field === "state"){
      setAddresses((prevAddresses) => ({
        ...prevAddresses,
        shippingAddress: {
          ...prevAddresses.shippingAddress,
          [field]: value
        }
      }));
      fetchCitiesForState(value);
      setCityDisabled(false)
    }
    else{
      setAddresses((prevAddresses) => ({
        ...prevAddresses,
        shippingAddress: {
          ...prevAddresses.shippingAddress,
          [field]: value
        }
      }));
    }
    
  };

  const fetchCitiesForState = async (state) => {
      
    try {
			// Fetch data from API
			const response = await Request.get(`utils/getPickupLocationByProvince?province=${state}`, []);
			if (response.length !== 0) {
				setCities(response)
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
    // fetch(`/api/cities?state=${state}`)
    //   .then((response) => response.json())
    //   .then((data) => setCities(data))
    //   .catch((error) => console.error('Error fetching cities:', error));
  };

  const handleChangeBillingAddress = (field, value) => {
    if(field === "country"){
      setAddresses((prevAddresses) => ({
        ...prevAddresses,
        billingAddress: {
          ...prevAddresses.billingAddress,
          [field]: value
        }
      }))
      fetchStateForCountries(value);
      setStateDisabled(false)
    }else{
      setAddresses((prevAddresses) => ({
        ...prevAddresses,
        billingAddress: {
          ...prevAddresses.billingAddress,
          [field]: value
        }
      }))
    }
  };

  const fetchStateForCountries = async (country) => {
    try {
			  
			const response = await Request.get(`utils/getProvinceListByCountry?country=${country}`, []);
			if (response.provinces.length !== 0) {
				setStates(response.provinces)
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
    // fetch(`/api/cities?state=${state}`)
    //   .then((response) => response.json())
    //   .then((data) => setCities(data))
    //   .catch((error) => console.error('Error fetching cities:', error));
  };

  const guestSignup = () => {
    // Function to handle SignUp button click
    setGuestSignupToggle(!guestSignupToggle)
    const updatedAddresses = { ...addresses };
      
    if(updatedAddresses.isGuestUser === true){
      updatedAddresses.isGuestUser = false
    }
    else{
      updatedAddresses.isGuestUser = true
    }
    setAddresses(updatedAddresses)
  };

  // function emailAuthentication() {
  //   (async () => {
  //     // if (emailCheck) {
  //       
  //       await Request.get(`user/email-check/`, [{
  //         email: emailCheckText
  //       }])
  //         .then(data => {
  //           if (data.message === true) {
  //             document.getElementById('emailError').style.display = "block";
  //             // setIsEmailTaken(true);
  //           } else {
  //             // setIsEmailTaken(false);
  //             document.getElementById('emailError').style.display = "none";
  //           }
  //         })
  //         .catch(error => {
  //           console.error(error);
  //         });
  //     // }
  //   })();
  // }

  const emailAuthentication = async (newEmail) => {
    const data = {
      "email": newEmail
    }
		try {
			let response = await Request.post('user/email-check/', data);
			 
			  
			if (response.message === true) {
				setEmailError(true)
			} else {
        // document.getElementById('emailError').style.display = "none";;
        setEmailError(false)
			}
		}
		catch (error) {
			console.error('Error fetching data from API:', error);
		}
	};

  function someAsyncOperation() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const success = true; // Simulate success or failure
            if (success) {
                resolve();
            } else {
                reject(new Error('An error occurred.'));
            }
        }, 1000); // Simulate a 1-second delay
    });
}

  const handleChangeGuestUser = (field, value) => {
      
    if(field === 'email'){
      const newEmail = value;
      if(login === false){
        setEmailCheck(true);
        setEmailCheckText(newEmail);
        someAsyncOperation().then(() => {
        if(guestSignupToggle === true){
          emailAuthentication(newEmail);
          setSignupEmail(true)
        }
        else{
          emailAuthentication(newEmail);
        }
      });
      }
      else{
        setEmailCheck(true);
        setEmailCheckText(newEmail);
      }
    }
    if (field === 'phone') {
      value = value.replace(/[^0-9]/g, ''); // This will keep only numeric characters
    }

    setAddresses((prevAddresses) => ({
      ...prevAddresses,
      guestUser: {
        ...prevAddresses.guestUser,
        [field]: value
      }
    }));
  };

  // const handlePAsswordChange = (event) => {
   
  // };
  // useEffect(() => {
  //   console.log(emailCheck,'emailCheck')
  //     
    
  // }, [loginCheck]);

  const handleSubmit = async (event) => {
      
    const form = event.target;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    event.preventDefault();

    if(addresses.guestUser.phone.length === 10){

        if (login === true) {
          const updatedAddresses = { ...addresses };
          updatedAddresses.isGuestUser = false; 
          setAddresses(updatedAddresses)

          if (addresses.billingBoolean) {
            setAddresses((prevAddresses) => ({
              ...prevAddresses,
              billingAddress: { ...prevAddresses.shippingAddress },
            }));
          }

          const formData = {
            ...addresses,
          };

          try {

            // Fetch data from API
            const response = await Request.post("checkout/buyCheckout", formData, true);
            if (response) {
              if (response.orderId === undefined) {
                setCheckoutError(true)
                return;
              }
              else{
                window.location.href = "/user/buy/payment?orderId=" + response.orderId;
              }
            } else {
              console.error('Failed to fetch data from API');
            }
          } catch (error) {
            console.error('Error fetching data from API:', error);
          }
        }
        if (login === false) {
          if (addresses.billingBoolean) {
            setAddresses((prevAddresses) => ({
              ...prevAddresses,
              billingAddress: { ...prevAddresses.shippingAddress },
            }));
          }


          try {
            const cart = await cookies.get('cartItems', {path: "/"});
            if (cart && cart.cartItems) {
              setAddresses((prevAddresses) => ({
                ...prevAddresses,
                cartItems: cart.cartItems,
              }));
            }

            const formData = {
              ...addresses,
              cartItems: cart.cartItems,
            };

              
            // Fetch data from API
            const response = await Request.post("checkout/guest/guestBuyCheckout", formData);
            if (response) {
              if (response.orderId === undefined) {
                setCheckoutError(true)
                return;
              }
              else{
                window.location.href = "/user/buy/payment?orderId=" + response.orderId;
              }
            } else {
              console.error('Failed to fetch data from API');
            }
          } catch (error) {
            console.error('Error fetching data from API:', error);
          }
        }
      }else{
        alert("Please 10 digit phone number");
      }
  }


  return (
    <Box className={login === 'true' ? 'orderContainer' : 'orderContainer orderBoxContainer'}>
      <Box>
        <Typography variant='h5' className='color1 font-bold'>Checkout</Typography>
        <Box className={classes.headerFlexBox}>
          <Link to='/user/buy/buy-product'>
              <Typography className={classes.pathName}>Shop</Typography>
          </Link>
          <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
          <Link to='/user/buy/buy-item-cart'>
            <Typography className={classes.pathName}>Cart</Typography>
          </Link>
          <KeyboardDoubleArrowRightOutlinedIcon className={classes.doubleArrow} />
          <Typography className={`${classes.pathName} ${classes.activePath}`}>Checkout</Typography>
        </Box>
      </Box>
      <Box className={login === 'true' ? '' : 'cartMainContainer'}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className=''>
            <Grid item xs={12} sm={12} md={12} lg={11.7} xl={11.7} className='cartColLeft'>
              <form className='checkoutForm' onSubmit={handleSubmit}>
                <Box>
                  <Typography align="left" className='font-22 color2 font-bold'>Contact Information</Typography>
                  {login === false ? (
                    <Box className="flexBoxSignup">
                      <Box className="flexBoxSignupInner">
                        <span className='font-14 color3'>For Faster Checkout Experience, Please</span>
                      </Box>
                      <Box className="flexBoxSignupInner">
                        <span className='font-14 color2'>SIGN IN</span><span className='font-14 color2'>or</span>
                        <span className='font-14 color2 cursor-pointer' sx={{ cursor: "pointer" }} onClick={() => guestSignup()}>SIGN UP</span>
                      </Box>
                     
                    </Box>
                  ) : ( 
                    <></>
                  )}

                  <Form.Group className={classes.formPadding}>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First name" name="firstName" required value={addresses.guestUser.firstName}
                          onChange={(e) => handleChangeGuestUser('firstName', e.target.value)} />
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" name="lastName" required value={addresses.guestUser.lastName}
                          onChange={(e) => handleChangeGuestUser('lastName', e.target.value)} />
                      </Col>
                    
                    </Row>
                  </Form.Group>
                  <Form.Group className={classes.formPadding}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control type="email" placeholder="Email" name="email" id="userEmail" required value={addresses.guestUser.email}
                            onChange={(e) => handleChangeGuestUser('email', e.target.value)} />
                            {/* {isEmailTaken && <Form.Control.Feedback>Email Already Exist</Form.Control.Feedback>} */}
                            {/* <Typography className="emailError font-14 l">Email address is already registered. Sign in to keep the track of your orders or checkout as guest</Typography> */}
                            {emailError === true ? (
                              <Typography className="emailError font-13">Email address is already registered. Sign in to keep the track of your orders or checkout as guest.</Typography>
                            ):(<></>)}
                           
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <Form.Label>Contact No</Form.Label>
                          <Form.Control type="tel" placeholder="Phone" name="phone" required value={addresses.guestUser.phone}
                            onChange={(e) => handleChangeGuestUser('phone', e.target.value)} />
                        </Col>
                    </Row>
                    
                  </Form.Group>

                  {guestSignupToggle && (
                    <Form.Group className={classes.formPadding}>
                      <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <Form.Label>Password</Form.Label>
                          <Form.Control type="password" placeholder="Password" name="password" required onChange={(e) => handleChangeGuestUser('password', e.target.value)} />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control type="Password" placeholder="Confirm Password" name="confirm password" required />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}

                </Box>
                <Box className="ShippingBox">
                  <Typography align="left" className='font-22 color2 font-bold'>Shipping Address</Typography>
                  <Form.Group className={classes.formPadding}>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" name="firstName" required value={addresses.shippingAddress.firstName}
                          onChange={(e) => handleChangeShippingAddress('firstName', e.target.value)} />
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last Name" name="lastName" required value={addresses.shippingAddress.lastName}
                          onChange={(e) => handleChangeShippingAddress('lastName', e.target.value)} />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className={classes.formPadding}>
                    <Form.Label>Address Line 1</Form.Label>
                    <Form.Control type="text" placeholder="Address" name="address1" required value={addresses.shippingAddress.address1}
                      onChange={(e) => handleChangeShippingAddress('address1', e.target.value)} />
                  </Form.Group>
                  <Form.Group className={classes.formPadding}>
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control type="text" placeholder="Address" name="address2" value={addresses.shippingAddress.address2}
                      onChange={(e) => handleChangeShippingAddress('address2', e.target.value)} />
                  </Form.Group>
                  <Form.Group className={classes.formPadding}>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                                name="country"
                                value={addresses.shippingAddress.country}
                                required
                                onChange={(e) => handleChangeShippingAddress('country', e.target.value)}
                                // onChange={(e) => handleInputChange(e, "shippingAddress")}
                            >
                                <option value="">Select Country</option>
                                <option value="Canada">Canada</option>
                                {/* {allCountries && allCountries.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))} */}
                        </Form.Select>
                        {/* <Form.Control type="text" value={addresses.shippingAddress.country} placeholder="Country" name="country" required /> */}
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>Province</Form.Label>
                        <Form.Select
                            name="state"
                            value={addresses.shippingAddress.state}
                            required
                            onChange={(e) => handleChangeShippingAddress('state', e.target.value)}
                            // onChange={(e) => handleInputChange(e, "shippingAddress")}
                        >
                            <option value="">Select Province</option>
                            {(pickupLocationList && pickupLocationList.length>0) && pickupLocationList.map((item) => (
                                <option key={item.province} value={item.province}>
                                    {item.province}
                                </option>
                            ))}
                        </Form.Select>
                        {/* <Form.Control type="text" value={addresses.shippingAddress.state} placeholder="State" name="state" required readOnly /> */}
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className={classes.formPadding}>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>City/Town</Form.Label>
                        <Form.Select
                            name="city"
                            value={addresses.shippingAddress.city}
                            required
                            onChange={(e) => handleChangeShippingAddress('city', e.target.value)}
                            disabled={cityDisabled}
                        >
                            <option value="">Select City</option>
                            {(cities && cities.length>0) && cities.map((item) => (
                                <option key={item.city} value={item.city}>
                                    {item.city}
                                </option>
                            ))}
                        </Form.Select>
                        {/* <Form.Control type="text" value={addresses.shippingAddress.city} placeholder="City" name="city" required readOnly /> */}
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type="text" placeholder="Zip Code" name="zip" required value={addresses.shippingAddress.zip}
                          onChange={(e) => handleChangeShippingAddress('zip', e.target.value)} />
                      </Col>
                    </Row>
                  </Form.Group>
                </Box>

                <Box className="BillingBox">
                  <Typography align="left" className='font-22 color2 font-bold'>Billing Address</Typography>
                  <Form.Group className={`${classes.formPadding} flex-check`}>
                    <input type="checkbox" id="myCheck" className='check-input' checked={addresses.billingBoolean}
                      onChange={handleBillingCheckboxChange}></input>
                    <label htmlFor='myCheck' className='checkLabel'>My billing address is same as shipping address</label>
                  </Form.Group>
                  {!addresses.billingBoolean && (
                    <>
                      <Box id="BillingBox">
                        <Form.Group className={classes.formPadding}>
                          <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control type="text" placeholder="First Name" name="firstName" required value={addresses.billingAddress.firstName}
                                onChange={(e) => handleChangeBillingAddress('firstName', e.target.value)} />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control type="text" placeholder="Last Name" name="lastName" required value={addresses.billingAddress.lastName}
                                onChange={(e) => handleChangeBillingAddress('lastName', e.target.value)} />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group className={classes.formPadding}>
                          <Form.Label>Address Line 1</Form.Label>
                          <Form.Control type="text" placeholder="Address" name="address1" required value={addresses.billingAddress.address1}
                            onChange={(e) => handleChangeBillingAddress('address1', e.target.value)} />
                        </Form.Group>
                        <Form.Group className={classes.formPadding}>
                          <Form.Label>Address Line 2</Form.Label>
                          <Form.Control type="text" placeholder="Address" name="address2" value={addresses.billingAddress.address2}
                            onChange={(e) => handleChangeBillingAddress('address2', e.target.value)} />
                        </Form.Group>
                        <Form.Group className={classes.formPadding}>
                          <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <Form.Label>Country</Form.Label>
                              <Form.Select
                                name="country"
                                value={addresses.billingAddress.country}
                                required
                                onChange={(e) => handleChangeBillingAddress('country', e.target.value)}
                                // onChange={(e) => handleInputChange(e, "shippingAddress")}
                            >
                                <option value="">Select Country</option>
                                {(allCountries && allCountries.length>0) && allCountries.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                              </Form.Select>
                              {/* <Form.Control type="text" placeholder="Country" name="country" required value={addresses.billingAddress.country} /> */}
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <Form.Label>Province</Form.Label>
                              <Form.Select
                                name="state"
                                value={addresses.billingAddress.state}
                                required
                                onChange={(e) => handleChangeBillingAddress('state', e.target.value)}
                                disabled={stateDisabled}
                            >
                                <option value="">Select Province</option>
                                {(states && states.length>0) && states.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                              </Form.Select>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group className={classes.formPadding}>
                          <Row>
                          <Col lg={6} md={6} sm={12} xs={12}>
                              <Form.Label>City/Town</Form.Label>
                              <Form.Control type="text" placeholder="City" name="city" required value={addresses.billingAddress.city} onChange={(e) => handleChangeBillingAddress('city', e.target.value)} />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <Form.Label>Zip Code</Form.Label>
                              <Form.Control type="text" placeholder="Zip Code" name="zip" required value={addresses.billingAddress.zip}
                                onChange={(e) => handleChangeBillingAddress('zip', e.target.value)} />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Box>
                    </>
                  )}

                </Box>

                {checkoutError === true ? (
                    <Box sx={{ pt: 2 }}>
                      {(checkoutError === true && signupEmail === true) ? (
                        <Typography className="font-600 font-15" style={{color: "red"}}>User with this Email is already Registered.</Typography>
                      ):(
                        <Typography className="font-600 font-15" style={{color: "red"}}>Server Error</Typography>
                      )}
                    </Box>
                      
                  ):(
                      <></>
                  )}
                <Box align="right" sx={{ pt: 5 }}>
                  {/* <Link to=''> */}
                  <Button type="submit" className='b1'>Proceed to Payment</Button>
                  {/* </Link> */}

                </Box>
            
                {/* </Box> */}
              </form>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className=''>
            <Box className='checkoutCartInfoBox cartTotalTable'>
              <Box sx={{ p: 2 }} className={classes.totalHeader} align="center">
                <Typography className='font-20 color2 font-bold'>Your Order</Typography>
              </Box>

              <Box sx={{ p: 3, pt: 0, pb: 4 }}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table aria-label="Total table" sx={{ mt: 2 }}>
                    <TableBody>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell scope="row">
                          <Typography className='font-15 font-bold color2'>Product</Typography>
                        </TableCell>
                        <TableCell align="right">
                          {/* <Typography sx={{ fontWeight: "bold" }}>SubTotal</Typography> */}
                        </TableCell>
                      </TableRow>

                      {(cartInfo && cartInfo.length>0) && (
                        <>
                          {cartInfo.map((cart, index) =>
                            <TableRow key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell scope="row">
                                <Typography className='font-15 font-bold color1'>{cart.barrel.name}</Typography>
                                <Box sx={{ display: "flex", gap: "40px" }}>
                                  <Typography className='font-15 font-bold color1'>Size: {cart.barrel.size}</Typography>
                                  <Typography className='font-15 font-bold color1'>Qty: {cart.quantity}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                <Typography className='font-15 font-bold color1'>${FormatPrice(cart.totalPrice)}</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}

                      {/* <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell scope="row">
                          <Typography className='font-15 font-bold color2'>Delivery Cost</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className='font-15 font-bold color2'>$20</Typography>
                        </TableCell>
                      </TableRow> */}

                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell scope="row">
                          <Typography className='font-20 font-bold color2'>Total</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className='font-20 font-bold color2'>${FormatPrice(cartTotalPrice)}</Typography>
                        </TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}


export default Checkout;

