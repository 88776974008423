import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Request from '../customUtils/Http/Request';

const initialState = {
    islanderList: [],
    islanderAdminList: [],
    subscriberList: [],
    weeklyDeals: [],
    loading: false,
    error: null,
  };
  
  // Async thunk action to fetch customers
  export const fetchIslanderList = createAsyncThunk(
    "customer/islanderList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Request.get("utils/getIslandCountries", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  // Async thunk action to fetch customers
  export const fetchIslanderAdminList = createAsyncThunk(
    "customer/islanderAdminList",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("admin/rate/getIslandCountries", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  
  export const fetchSubscriberList = createAsyncThunk(
    "customer/subscriberList",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("admin/subscribers", [], true);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );

  export const fetchWeeklyDeals = createAsyncThunk(
    "customer/weeklyDeals",
    async (_, { rejectWithValue }) => {
        try {
            
            const response = await Request.get("utils/weeklyDeals", []);
            if (response.length !== 0) {
                 
                return response
            } else {
                console.error("Failed to fetch data from API");
            }
            } catch (error) {
                return rejectWithValue(error);
            }
    }
  );
  

  const islanderListSlice = createSlice({
    name: "shippingPrice",
    initialState, 
    loading: false,
    error: null,
    reducers: {
      // Your other reducer actions
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchIslanderList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchIslanderList.fulfilled, (state, action) => {
            state.loading = false;
            state.islanderList = action.payload;
          })
          .addCase(fetchIslanderList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(fetchIslanderAdminList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchIslanderAdminList.fulfilled, (state, action) => {
            state.loading = false;
            state.islanderAdminList = action.payload;
          })
          .addCase(fetchIslanderAdminList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(fetchSubscriberList.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchSubscriberList.fulfilled, (state, action) => {
            state.loading = false;
            state.subscriberList = action.payload;
          })
          .addCase(fetchSubscriberList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })

          .addCase(fetchWeeklyDeals.pending, (state) => {
            state.loading = true;
          })
          .addCase(fetchWeeklyDeals.fulfilled, (state, action) => {
            state.loading = false;
            state.weeklyDeals = action.payload;
          })
          .addCase(fetchWeeklyDeals.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
          })
        
      },
  });
  
  export default islanderListSlice.reducer;