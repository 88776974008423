import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';
import Request from '../customUtils/Http/Request';
import { checkLogin } from "../commonFunctions/commonFunctions";

const cookies = new Cookies();
let login = checkLogin()
// const initialState = {
//     cartItems: [],
//     totalPrice: 0
// };

export const cartSlice  = createSlice({
    name: 'cart',
    initialState: {
        cartItems: [],
        minimized: false,
        isOpen: false
      },
    reducers: {
        sideBar:(state,action) => {
            state.minimized = !state.minimized;
        },
        cartToggle:(state,action) => {
            state.isOpen = !state.isOpen;
        },
        setCart: (state, action) => {
            return action.payload;
        },
        addItem: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.find(item => item.id === newItem.id);
            
            if (existingItem) {
              if (existingItem.inStock) {
                if (existingItem.quantity < existingItem.inStock) {
                  existingItem.quantity += 1;
                }
              } else {
                if (existingItem.quantity < 10) {
                  existingItem.quantity += 1;
                }
              }
            } else {
              state.push({ ...newItem, quantity: 1 });
            }
          },
          incQuantity: (state, action) => {
            const cartItemId = action.payload.cartItemId;
            const item = state.cartItems.find(item => item.cartItemId === cartItemId);
            if (item && item.quantity < 10) {
              item.quantity += 1;
              item.totalPrice = item.barrel.price * item.quantity;
              state.totalCartPrice = state.cartItems.reduce((total, item) => total + item.totalPrice, 0);
            }
          },
          decQuantity: (state, action) => {
            const cartItemId = action.payload.cartItemId;
            const item = state.cartItems.find(item => item.cartItemId === cartItemId);
            if (item && item.quantity > 1) {
              item.quantity -= 1;
              item.totalPrice = item.barrel.price * item.quantity;
              state.totalCartPrice = state.cartItems.reduce((total, item) => total + item.totalPrice, 0);
            }
          },
          removeItem: (state, action) => {
            const itemId = action.payload;
            const updatedCartItems = state.cartItems.filter(item => item.cartItemId !== itemId);
            state.cartItems = updatedCartItems;
            state.totalCartPrice = state.cartItems.reduce((total, item) => total + item.totalPrice, 0);
          },
    },
})

export const fetchCartItems = () => async dispatch => {
    try {
      const response = await Request.get("cart", [], true)// Replace with your API endpoint
      dispatch(setCart(response)); 
      // Assuming your API response contains 'cartItems'
    } catch (error) {
      console.error('Error fetching cart items from API:', error);
    }
  };
  
  // Action for setting cart items from cookies
  export const setCartItemsFromCookies = () => dispatch => {
    const cartItemsFromCookies = cookies.get('cartItems', {path: '/'});
    if (cartItemsFromCookies) {
      dispatch(setCart(cartItemsFromCookies));
    }
  };

  export const removeItemAsync = cartItemId => async (dispatch, getState) => {
        if (login) {
        try {
            const response = await Request.delete(`cart/${cartItemId}`, {}, true);
            if (response.status === 204) {
                const updatedCart = getState().cart;
                if (updatedCart.cartItems.length === 1) {
                  // Dispatch an action to set a flag for redirection
                  window.location.href = '/user/buy/buy-product';
                }
                dispatch(removeItem(cartItemId)); 
            } else {
            console.error('Failed to remove item from cart.');
            }
        } catch (error) {
            console.error('Error removing item:', error);
        }
        } else {
            
          const updatedCart = getState().cart;
          if (updatedCart.cartItems.length === 1) {
            // Dispatch an action to set a flag for redirection
            window.location.href = '/user/buy/buy-product';
          }
          const existingCartItems = cookies.get('cartItems', { path: '/' }) || { cartItems: [] };
          const updatedItems = existingCartItems.cartItems.slice();
          const indexToRemove = updatedItems.findIndex(item => item.cartItemId === cartItemId);
            if (indexToRemove !== -1) {
              updatedItems.splice(indexToRemove, 1);
              const totalCartPrice = updatedItems.reduce((total, item) => total + item.totalPrice, 0);
              const updatedCartItems = { cartItems: updatedItems, totalCartPrice: totalCartPrice }; // Structure it consistently
              cookies.set('cartItems', updatedCartItems, { path: '/', secure: true });
              dispatch(removeItem(cartItemId)); // Call the synchronous action to remove the item from the store
            }
        }
  };

  export const incQuantityAsync = createAsyncThunk(
    'cart/incQuantityAsync',
    async (cartItemId, thunkAPI) => {
      const response = await updateQuantity(cartItemId, 1);
      if (response) {
        thunkAPI.dispatch(cartSlice.actions.incQuantity({ cartItemId }));
      }
    }
  );
  
  export const decQuantityAsync = createAsyncThunk(
    'cart/decQuantityAsync',
    async (cartItemId, thunkAPI) => {
      const response = await updateQuantity(cartItemId, -1);
      if (response) {
        thunkAPI.dispatch(cartSlice.actions.decQuantity({ cartItemId }));
      }
    }
  );

  const updateQuantity = async (cartItemId, change) => {
    if (login) {
        
      try {
        var items = { "cartItems": [{"cartItemId": cartItemId,"quantity": change }] }
        const response = await Request.put("cart/update", items, true);
        if (response.status === 200) {
          return true;
        } else {
          console.error('Failed to update quantity in cart.');
        }
      } catch (error) {
        console.error('Error updating quantity:', error);
      }
    } else {
        
      const existingCartItems = cookies.get('cartItems', { path: '/' }) || { cartItems: [] };
      const updatedItems = (existingCartItems.cartItems && existingCartItems.cartItems.length>0) && existingCartItems.cartItems.map(item => {
        if (item.cartItemId === cartItemId) {
          const newQuantity = item.quantity + change;
          item.quantity = Math.max(1, Math.min(10, newQuantity)); // Apply max and min limits
        }
        item.totalPrice = item.barrel.price * item.quantity;
        return item;
      });
      const totalCartPrice = updatedItems.reduce((total, item) => total + item.totalPrice, 0);
      const updatedCartItems = { cartItems: updatedItems, totalCartPrice: totalCartPrice };
      cookies.set('cartItems', updatedCartItems, { path: '/', secure: true });
      return true;
    }
    return false;
  };


export const { setCart, addItem, incrementItem, decrementItem, removeItem, sideBar, cartToggle  } = cartSlice.actions;

export default cartSlice.reducer;
// export const {} = cartSlice.actions;

// toggle cart icon