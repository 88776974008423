import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Stepper, Step, StepLabel, Button, Box, Input, Grid, } from '@mui/material';
import { connect } from 'react-redux';
import Request from '../customUtils/Http/Request';
import { Col, Row, Form } from 'react-bootstrap';
import Header from '../header/header';
import Footer from '../footer/footer';

const Careers = () => {
    const [vacancy, setVacancy] = useState([]);

    useEffect(() => {
        fetchVacancyList()
      }, []);
  
      const fetchVacancyList = async () => {
            
          try {
              const response = await Request.get(`utils/careers`, []);
              if (response) {
                setVacancy(response);
              } else {
                  console.error('Failed to fetch data from API');
              }
          } catch (error) {
              console.error('Error fetching data from API:', error);
          }
      };


    return (
        <>
        <Header />
            <Container maxWidth="xl" className='homeContainer'>
            <Box className="MuiBox-root css-pxpt32">
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className='d-flex align-items-center'>
                            <Typography className='color2 font-bold font-45 line-50'>Careers</Typography>
                        </Col>
                    </Row>
                    <Box sx={{p: 3,'@media (max-width: 600px)': {p: 1, pt: 3}}} className="vacancyBox">
                        {vacancy && vacancy.length>0 ? (
                            <>
                             <Box sx={{mb:3}}>
                                <Typography className='color2 font-bold font-30'>Vacancies</Typography>
                            </Box>
                             {vacancy.map((vacancy, index) => (
                            <div key={index} className="vacancyTitleBox">
                                <Row>
                                    <Col item xs={12} sm={12} md={7} lg={8} xl={8} className=''>
                                        <Typography className='font-24 font-600 color1'>{vacancy.title}</Typography>
                                        <Typography className='font-16 color2 pt-2'>Expires On: <span className='font-18 font-600 color2'>{vacancy.expireOn}</span></Typography>
                                    </Col>
                                    <Col item xs={12} sm={12} md={5} lg={4} xl={4} className='careerBtn'>
                                        <Link to={`/careers/vacancy?vacancy=${vacancy.id}`}>
                                            <Button className='b1 b4'>View Details</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                         ))}
                         </>
                        ):(
                            <Box textAlign="left" sx={{mt: 3, minHeight: "176px"}}>
                                <Typography className='font20 font-600 color4'>
                                    No Vacancies Available
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        <Footer />
        </>
    );
}

export default Careers;