import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Box,
	Menu,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { fetchPickupAdminList } from "../redux/pickupLocationSlice";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Request from '../customUtils/Http/Request';
import { Form } from "react-bootstrap";

const PickupLocationTable = ({ list }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedLlocation, setSelectedLocation] = useState(null);
	const dispatch = useDispatch();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductEdit, setSelectedProductEdit] = useState(null);
	const [formData, setFormData] = useState({
		city: "",
		province: "",
		status: false
	});

	const ITEM_HEIGHT = 48;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleCloseDialog = () => {
		setSelectedLocation(null);
		setIsDialogOpen(false);
	};

	const openDeleteModal = (id) => {
		setSelectedProduct(id);
		setIsDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => {
		setSelectedProduct(null);
		setIsDeleteDialogOpen(false);
	};
	const handleVisibilityChange = (e) => {
		const newVisibility = e.target.value === 'true'; // Convert the string to a boolean
		setFormData((prevData) => ({ ...prevData, status: newVisibility }));
	  };

	const handleFieldChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const productDelete = async (id) => {
			try {
				const response = await Request.delete(`admin/pickup/location/send/${id}`, [], true);
				if (response) {
					if (response.status === 204) {
						setIsDeleteDialogOpen(false)
						dispatch(fetchPickupAdminList())
					}
				}
			} catch (error) {
				console.error('Error deleting row from API:', error);
			}
		};

	const openEditModal = async (id) => {
		  
		setSelectedProductEdit(id);
		// setIsDialogOpen(true);
		try {
			const response = await Request.get(`admin/pickup/location/send/${id}`, [], true);
			if (response.length !== 0) {
				// if (response) {
					setIsDialogOpen(true);
					setFormData({
						"city": response.city,
						"province": response.province,
						"status": response.status,
					})
				// }
			}
		} catch (error) {
			console.error('Error deleting row from API:', error);
		}
	};

	const handleSubmit = async (e) => {
		  
		e.preventDefault();
		try {
			  
			const response = await Request.put(`admin/pickup/location/send/${selectedProductEdit}`, formData, true);
			if (response.status === 200) {
				handleCloseDialog();
				dispatch(fetchPickupAdminList())
				setFormData({
					city: '',
					state: '',
					province: false
				  });
			} else {
				console.error('Failed to fetch data from API');
			}
		} catch (error) {
			console.error('Error fetching data from API:', error);
		}	
	};

	return (
		<Box className="order-table-container pickup-locations-table-container">
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>City</TableCell>
							<TableCell>Province</TableCell>
							<TableCell>Visibility</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(list && list.length>0) && list.map((item) => (
							<TableRow key={item.id}>
								<TableCell>{item.city}</TableCell>
								<TableCell>{item.province}</TableCell>
								<TableCell
									className={
										item.status === false
											? "pickup-locations-table__hide"
											: " "
									}
								>
									<span className="product-categories-table__stock">
										{
											item.status === false
												? "Hide"
												: "Visible"
										}
									</span>
								</TableCell>
								<TableCell>
									<IconButton
											aria-label="Edit"
											onClick={() => openEditModal(item.id)} 
										>
											<EditIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
										<IconButton
											aria-label="Delete"
											onClick={() => openDeleteModal(item.id)}
										>
											<DeleteOutlineOutlinedIcon style={{ color: '#DB2D6A', fontSize: "25px" }}/>
										</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

				{/* delete dialog */}
				<Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete this Location?</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => productDelete(selectedProduct)} color="primary">
						OK
					</Button>
					<Button onClick={closeDeleteDialog} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
					open={isDialogOpen}
					onClose={handleCloseDialog}
					className="admin-modal admin-dialog"
				>
					<form onSubmit={handleSubmit}>
					<DialogTitle className="admin-modal__title-container">
						Edit Pick Up Location
						<Button color="primary" className="btn" type="submit">
							Save
						</Button>
					</DialogTitle>
					<DialogContent sx={{ padding: "30px" }}>
						<Box className="add-product-form__container">
							<Form className="edit-order-form add-product-form add-category-form add-location-form">
								<div className="row add-product-form__row">
									<Form.Group
										controlId="city"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>City</Form.Label>
										<Form.Control
											type="text"
											placeholder="City"
											value={formData.city}
											onChange={(e) =>
												handleFieldChange(
													"city",
													e.target.value
												)
											}
											required
										/>
									</Form.Group>

									<Form.Group
										controlId="visibility"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Visibility</Form.Label>
										<div className="add-product-form__visibility">
											<Form.Check
												type="radio"
												label="Public"
												name="visibility"
												value="true"
												checked={formData.status === true}
												onChange={
													handleVisibilityChange
												}
											/>
											<Form.Check
												type="radio"
												label="Hide"
												name="visibility"
												value="false"
												checked={formData.status === false}
												onChange={
													handleVisibilityChange
												}
											/>
										</div>
									</Form.Group>
									<Form.Group
										controlId="province"
										className="col-md-6 add-category-form__field"
									>
										<Form.Label>Province</Form.Label>
										<Form.Control
											type="text"
											placeholder="Province"
											value={formData.province}
											onChange={(e) =>
												handleFieldChange(
													"province",
													e.target.value
												)
											}
											required
										/>
									</Form.Group>
								</div>
							</Form>
						</Box>
					</DialogContent>
					</form>
				</Dialog>
		</Box>
	);
};

export default PickupLocationTable;

