import { useEffect, useRef, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Request from '../customUtils/Http/Request';
import $ from 'jquery';
import { Container, Typography, Box, TextField, Button, Link } from '@mui/material';
import Cookies from 'universal-cookie';
// import { } from 'react-bootstrap';
// import { checkLogin } from '../../commonFunctions/commonFunctions';
import { Dashboard } from '@mui/icons-material';
import Header from '../header/header';
import Footer from '../footer/footer';
import { checkLogin } from '../commonFunctions/commonFunctions';
import  ReCAPTCHA  from 'react-google-recaptcha';

const cookies = new Cookies();
const CssTextField = styled(TextField)({
  // '& label':{
  //   fontSize: '15px !important',
  //   fontWeight: 'bold !important',
  //   color: '#033D1E',
  // },
  '& label.Mui-focused': {
    color: '#033D1E',
    fontSize: '15px !important',
    fontWeight: 'bold !important',
    lineHeight: '25px !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#033D1E',
  },

  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    // "&.MuiOutlinedInput-notchedOutline": {
    //   fontSize: "14px",
    // },
    '&:hover fieldset': {
      borderColor: '#FFC72C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFC72C',
      borderWidth: '1px',
    },
  },
});

function ForgotPassword() {

  let login = checkLogin();
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [messageText, setMessageText] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [recaptchaSize, setRecaptchaSize] = useState(windowWidth > 450 ? 'normal' : 'compact');
  const recaptchaRef = useRef();

  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = checkLogin();
    // If the user is logged in, redirect to the dashboard
    if (isLoggedIn) {
      // return <Navigate to="/dashboard" replace />;
      navigate('/user/customer/dashboard')
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setRecaptchaSize(window.innerWidth > 450 ? 'normal' : 'compact');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    let email = document.getElementById('email').value;
    const data= {
      "email": email,
      "captchaValue": recaptchaValue
    }
    if (email !== '') {
        try {
              
            const response = await Request.post(`user/forgot_password`, data);
             
            if (response) {
                if(response.message === "Success"){
                    setRecaptchaValue('')
                    if (recaptchaRef.current) {
                      recaptchaRef.current.reset();
                    }
                    window.location.href = "/password-reset";
                }else{
                    setErrorMsg(response.message)
                    setRecaptchaValue('')
                    if (recaptchaRef.current) {
                      recaptchaRef.current.reset();
                    }
                    return;
                }  
            } else {
                console.error('Failed to fetch data from API');
            }
        } catch (error) {
          setRecaptchaValue('')
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
            console.error('Error fetching data from API:', error);
        }
     
    }
    else{
      alert("Something Went Wrong")
    }

  }


  return (
    <>
      {login === false ? (

<>
          <Header />
          <Container maxWidth="xl" className='homeContainer'>
            <Box className='middle_block' textAlign="center">
              <Box className='middle_inner_block'>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mb: 4, }}>
                  <Typography variant='h5' className='formTitle'>Send OTP</Typography>
                </Box>

                <Box className='login_form'
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { mb: 3, maxWidth: '400px', minWidth: '400px' },
                    mt: 3,
                  }}
                  // noValidate
                  autoComplete="off"
                  onSubmit={event => handleLoginSubmit(event)}
                >
                  <div>
                    <CssTextField fullWidth id="email" floatinglabeltext="Email" type="text" label="Email" variant="outlined" className='text_field' required/>
                  </div>
                  <div>
                    {windowWidth > 450 ? (
                        <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_CAPCHA_KEY}
                        onChange={handleRecaptchaChange}
                        className="recaptcha_block"
                        size="normal"
                    />
                    ):(
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_CAPCHA_KEY}
                          onChange={handleRecaptchaChange}
                          className="recaptcha_block"
                          size="compact"
                    />
                    )}
                  </div>
                  <div>
                    <Button variant="contained" type="submit" className='submitBtn login_Btn' disabled={!recaptchaValue} id="loginSubmitBtn">Send Otp</Button>
                  </div>
                    <Typography className="loginErrorMessage2" id="">{errorMsg}</Typography>
             
                </Box>
              </Box>
            </Box>
          </Container>
          <Footer />
        </>
      ) : (
        <>
          <Dashboard />
        </>
      )}
    </>
  );
}

export default ForgotPassword;